import React, { Component } from 'react';
import { MESSAGE, PROP, URI_PATH } from '../../../utils/constants';
import { getMiniusSecond } from '../../../utils/common';
import { connect } from 'react-redux';
import { changePoint } from '../../../actions/home/';
import { showNotification, showNotificationBonus, showNotificationAsset, showNotificationFightMessage } from '../../../utils/notification';
import Lottie from 'react-lottie';
import dino from '../../../assets/lottie/dino.json';
import win from '../../../assets/lottie/win.json';
import ResultMessage from '../../../components/question/ResultMessage';
import TextAnswer from '../../../components/question/TextAnswer';
import { Button, Modal, Tooltip } from 'antd';
import { updateCountMission } from '../../../actions/menu';
import { socket } from '../../../components/realtime/ReadSocketEvent';
import { getListQuestionFightApi, saveAnswerFightApi } from '../../../api/question/fight/fight';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Loading';
import TooltipUser from '../../../components/TooltipUser';
import ChatFight from '../../../components/question/fight/ChatFight';
import { getListGiftFightApi, leaveRoomApi, sendGiftFightApi } from '../../../api/question/fight/room';
import Icon from 'react-fontawesome';
import { formatDecimal, replaceCost, getDecimalCount } from '../../../utils/common';

class Fight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            questionListFight: [],
            roomInfo: {},
            selectAnswerKey: '',
            resultAnswer: false,
            isAnswer: false,
            textTime: '--:--',
            listPlayer: [],
            isWaitDoneFight: false,
            isDoneFight: false,
            dataDoneFight: '',
            listLook: [],
            isEnableSpeak: false,
            listGift: [],
            giftSelect: {},
            userSelect: {},
            showModal: false
        }
        this.numberQuestionFight = 0;
        this.correctAnswer = '';
        this.questionInterval = '';
        this.isLook = false;
    }

    getListQuestionFight = (userId, enemyId, questionFightId, crDateTime) => {
        getListQuestionFightApi(userId, enemyId, questionFightId, crDateTime).then(res => {
            if (res.code == 1) {
                let {
                    questionListFight,
                    numberQuestionFight,
                    timeDiffFight,
                    roomInfo,
                    listPlayer,
                    listLook,
                } = res.data;
                this.timeDiffFight = timeDiffFight;
                this.numberQuestionFight = numberQuestionFight;
                this.isLook = listLook.some(item => item.UserId == this.props.dataUser.UserId)
                this.setState({
                    isLoading: false,
                    questionListFight,
                    roomInfo,
                    listPlayer,
                    listLook,
                    resultAnswer: false,
                    selectAnswerKey: '',
                    isAnswer: false
                });
                socket.emit('join_fight_room', {
                    roomId: roomInfo.QuestionFightRoomId,
                });
                setTimeout(() => {
                    socket.emit('update_list_look_fight', {
                        roomId: roomInfo.QuestionFightRoomId,
                        listLook
                    });
                }, 1500);
                this.questionInterval = setInterval(() => {
                    this.timeDiffFight -= 1;
                    this.setState({ textTime: getMiniusSecond(this.timeDiffFight) });
                    if (this.timeDiffFight <= 0) {
                        this.timeDiffFight = roomInfo.TimeLimit;
                        this.numberQuestionFight += 1;
                        if (this.numberQuestionFight > 10) {
                            this.setState({ isWaitDoneFight: true });
                            clearInterval(this.questionInterval);
                            if (this.isLook) {
                                setTimeout(() => {
                                    this.props.history.push('/question-fight/room?id=' + roomInfo.QuestionFightRoomId)
                                }, 10000);
                            }
                        }
                        this.correctAnswer = '';
                        this.setState({
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isAnswer: false,
                            textTime: getMiniusSecond(this.timeDiffFight)
                        });
                    }
                }, 1000);
            }
            else {
                showNotification(res.message, res.code);
                this.props.history.push('/');
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
            this.props.history.push('/');
        })
    }

    changeAnswerCorrect = (userId, answerCorrect) => {
        this.setState({
            listPlayer: this.state.listPlayer.map(el => {
                if (el.UserId == userId) return Object.assign({}, el, {
                    AnswerCorrect: parseInt(answerCorrect) || parseInt(el.AnswerCorrect) + 1
                })
                return el;
            })
        })
    }

    saveAnswer = (questionTempId, answer, keySelect) => {
        if (!this.state.isAnswer) {
            let idNotification = '#notificationQuestionFight';
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            saveAnswerFightApi(questionTempId, answer).then(res => {
                this.setState({ resultAnswer: 2 });
                let timeResult = this.timeDiffFight * 1000 - 3000;
                if(this.isLook) timeResult = this.timeDiffFight * 1000 - 1000;
                if (res.code == 1 || res.code == 2) {
                    let userId = this.props.dataUser.UserId;
                    let { listPlayer } = this.state;
                    setTimeout(() => {
                        if (res.code == 1 && !this.isLook) { //tl đúng
                            showNotificationAsset(res.assetInfo);
                            this.changeAnswerCorrect(userId);
                            //send realtime
                            socket.emit('user_answer_correct_fight', {
                                userId,
                                roomId: this.state.roomInfo.QuestionFightRoomId,
                                answerCorrect: parseInt(listPlayer.find(el => el.UserId == userId).AnswerCorrect) + 1
                            });
                        }
                        this.correctAnswer = res.answer;
                        this.setState({
                            resultAnswer: res.code == 1 ? 1 : 0,
                        });
                    }, timeResult)
                }
                else {
                    showNotification(res.message, res.code, idNotification);
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    renderButtonBack = () => (
        <div className='item-center mgt-3'>
            <Button
                className='bg-success'
                onClick={() => this.props.history.push('/question-fight/room?id=' + this.state.roomInfo.QuestionFightRoomId)}>
                Quay lại phòng
            </Button>
        </div>
    )

    getAsset = (item) => {
        if (item.TypeAsset == 1) return {
            image: item.ItemImage,
            name: `${formatDecimal(parseFloat(item.BalanceItem))} đ[z]`
        }
        if (item.TypeAsset == 2) return {
            image: item.FoodImage,
            name: `${item.TypeBalance == 1 ? item.Balance : formatDecimal(parseFloat(item.Balance))} ${item.TypeBalance == 1 ? 'Gold[z]' : '$[z]'}`
        }
        return {
            image: item.AssetImage,
            name: `${formatDecimal(parseFloat(item.Balance))} $[z]`
        }
    }

    userAnswerCorrectSocket = data => {
        if (data.userId != this.props.dataUser.UserId) {
            this.changeAnswerCorrect(data.userId, data.answerCorrect);
        }
    }

    getNotificationBonus = (roomInfo, betBonus) => {
        if (roomInfo.BetType == 1) this.props.changePoint([betBonus]);
        else if (roomInfo.BetType == 2) this.props.changePoint([null, betBonus]);
        else if (roomInfo.BetType == 3) this.props.changePoint([null, null, betBonus]);
        if (roomInfo.BetValue > 0) showNotificationBonus(parseFloat(betBonus), 1, roomInfo.BetType);
    }

    doneFightSocket = data => {
        let { roomInfo, listPlayer } = this.state;
        let userId = this.props.dataUser.UserId;
        if (roomInfo.FightType == 1) {
            this.setState({
                isWaitDoneFight: false,
                isDoneFight: true,
                dataDoneFight: listPlayer.filter(item => item.UserId == data.userIdWin)[0]
            });
            if (data.userIdWin == userId) {// + tiền, + nhiệm vụ
                this.getNotificationBonus(roomInfo, data.betBonus);
                this.props.updateCountMission('FightCount');
            }
        }
        else if (roomInfo.FightType == 2) {
            let dataTeamWin = listPlayer.filter(item => item.Team == data.teamWin);
            this.setState({
                isWaitDoneFight: false,
                isDoneFight: true,
                dataDoneFight: dataTeamWin
            })
            let isUserWin = dataTeamWin.some(el => {
                return el.UserId == userId;
            });
            if (isUserWin) {// + tiền, + nhiệm vụ
                this.getNotificationBonus(roomInfo, data.betBonus);
                this.props.updateCountMission('FightCount');
            }
        }
        // new Audio(require('../../../assets/audio/votay.mp3')).play();
    }

    getListGiftFight = () => {
        getListGiftFightApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listGift: res.listGift
                })
            }
        })
    }

    sendGift = () => {
        if (!this.state.isPress) {
            let { userSelect, giftSelect } = this.state;
            sendGiftFightApi(giftSelect.QuestionFightGiftId, userSelect.UserId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    });
                    let roomId = this.state.roomInfo.QuestionFightRoomId
                    let dataUser = this.props.dataUser;
                    let dataChat = {
                        QuestionFightRoomId: roomId,
                        UserId: dataUser.UserId,
                        FullName: dataUser.FullName,
                        FacebookId: dataUser.FacebookId,
                        Message: res.name,
                        CrDateTime: 'Vừa xong'
                    }
                    this.onUpdateChat(dataChat)
                    showNotificationFightMessage(dataChat);
                    if (giftSelect.TypeBalance == 3) {
                        showNotificationBonus(giftSelect.BalanceItem, -1, giftSelect.TypeBalance)
                    }
                    else showNotificationBonus(giftSelect.Balance, -1, giftSelect.TypeBalance)
                    // this.updateChatState(dataChat);
                    //socket
                    socket.emit('user_chat_fight', {
                        roomId,
                        dataChat
                    });
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code)
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    updateListLook = (data) => {
        if (data?.length > 0) {
            this.setState({ listLook: data })
        }
    }

    leaveRoom = () => {
        if (!this.state.isPress) {
            let roomId = this.state.roomInfo.QuestionFightRoomId;
            this.setState({ isPress: true });
            leaveRoomApi(roomId).then(res => {
                if (res.code == 1) {
                    socket.emit('update_list_look_fight', {
                        roomId: this.state.roomInfo.QuestionFightRoomId,
                        listLook: this.state.listLook.filter(item => item.UserId != this.props.dataUser.UserId)
                    });
                    showNotification(res.message, res.code);
                    this.props.history.push('/question-fight');
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code);
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    componentDidMount() {
        this.getListQuestionFight();
        this.getListGiftFight();
        //read socket
        socket.on('user_answer_correct_fight', this.userAnswerCorrectSocket);
        socket.on('done_fight', this.doneFightSocket);
        socket.on('update_list_look_fight', this.updateListLook);
        //
    }

    componentWillUnmount() {
        clearInterval(this.questionInterval);
        //remove socket event
        socket.removeEventListener('user_answer_correct_fight', this.userAnswerCorrectSocket);
        socket.removeEventListener('done_fight', this.doneFightSocket);
        socket.removeEventListener('update_list_look_fight', this.updateListLook);
        //
    }

    render() {
        let { dataUser } = this.props;
        let {
            isLoading,
            questionListFight,
            resultAnswer,
            selectAnswerKey,
            textTime,
            listPlayer,
            listLook,
            roomInfo,
            isWaitDoneFight,
            isDoneFight,
            dataDoneFight,
            isEnableSpeak,
            listGift,
            showModal,
            isPress,
            userSelect,
            giftSelect
        } = this.state;
        let listPlayerTeam1;
        let listPlayerTeam2;
        if (roomInfo.FightType == 2) {
            listPlayerTeam1 = listPlayer.filter(item => item.Team == 1)
            listPlayerTeam2 = listPlayer.filter(item => item.Team == 2)
        }
        if (!isLoading) {
            return (
                <>
                    {listLook?.length > 0 &&
                        <div className='looker'>
                            <div className='looker-container'>
                                <div className='looker-list flex item-center'>
                                    {this.isLook &&
                                        <div className='circle-fight' onClick={this.leaveRoom}>
                                            <img
                                                src={require('../../../assets/image/quit.png')}
                                                className='img-quit-fight'
                                                onClick={() => this.setState({ isEnableSpeak: !isEnableSpeak })}
                                            />
                                        </div>
                                    }
                                    {/* <img
                                        src={isEnableSpeak ? require('../../../assets/image/speaker.png') : require('../../../assets/image/speaker-off.png')}
                                        className='avatar-guest-small'
                                        onClick={() => this.setState({ isEnableSpeak: !isEnableSpeak })}
                                    /> */}
                                </div>
                                {listLook.map((item, key) =>
                                    <div className='looker-list' key={key}>
                                        <TooltipUser userInfo={item} />
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {roomInfo.FightType == 1 ? // đấu đơn
                        <div className='box-primary row item-center'>
                            {listPlayer.map((item, key) =>
                                <div className='col-md-3 col-sm-6 col-xs-6 item-center' key={key}>
                                    <div className='relative'>
                                        <TooltipUser userInfo={item} />
                                        {dataUser.UserId != item.UserId &&
                                            <Icon
                                                name='gift'
                                                className='icon-gift-fight'
                                                onClick={() => this.setState({
                                                    showModal: true,
                                                    userSelect: item,
                                                })}
                                                style={{ left: 'unset' }}
                                            />
                                        }
                                    </div>
                                    <p className={'text-center text-gigantic bold mgt ' + (item.UserId == dataUser.UserId ? 'success' : 'danger')}>
                                        {item.AnswerCorrect}
                                    </p>
                                </div>
                            )}
                        </div>
                        :
                        <div className='row'>
                            <div className='col-md-6 col-sm-6 col-xs-6 item-center row box-primary'>
                                {listPlayerTeam1.map((item, key) =>
                                    <div className='col-md-6 col-sm-6 col-xs-6 item-center' key={key}>
                                        <div className='relative'>
                                            <TooltipUser userInfo={item} />
                                            {dataUser.UserId != item.UserId &&
                                                <Icon
                                                    name='gift'
                                                    className='icon-gift-fight'
                                                    onClick={() => this.setState({
                                                        showModal: true,
                                                        userSelect: item,
                                                    })}
                                                    style={{ left: 'unset' }}
                                                />
                                            }
                                        </div>
                                        <p className={'text-center text-gigantic bold mgt ' + (item.UserId == dataUser.UserId ? 'success' : 'danger')}>
                                            {item.AnswerCorrect}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-6 item-center row box-primary bg-warning'>
                                {listPlayerTeam2.map((item, key) =>
                                    <div className='col-md-6 col-sm-6 col-xs-6 item-center' key={key}>
                                        <div className='relative'>
                                            <TooltipUser userInfo={item} />
                                            {dataUser.UserId != item.UserId &&
                                                <Icon
                                                    name='gift'
                                                    className='icon-gift-fight'
                                                    onClick={() => this.setState({
                                                        showModal: true,
                                                        userSelect: item,
                                                    })}
                                                    style={{ left: 'unset' }}
                                                />
                                            }
                                        </div>
                                        <p className={'text-center text-gigantic bold mgt ' + (item.UserId == dataUser.UserId ? 'success' : 'danger')}>
                                            {item.AnswerCorrect}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {questionListFight.map((item, index) => (
                        <div key={index}>
                            {index + 1 == this.numberQuestionFight && (
                                <div className='animated fadeIn fast mgt'>
                                    <p className='title-question noselect'>Câu hỏi {this.numberQuestionFight}: {item.QuestionTitle}</p>
                                    <div className='count-down-question-container'>
                                        <p className={'count-down-question ' + (this.timeDiffFight <= 10 ? 'danger' : 'success')}>{textTime}</p>
                                    </div>
                                    {item.answers.map((value, key) => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => this.saveAnswer(item.QuestionTempId, value, key)}
                                            >
                                                <TextAnswer
                                                    answer={value}
                                                    keySelect={key}
                                                    selectAnswerKey={selectAnswerKey}
                                                    correctAnswer={this.correctAnswer}
                                                />
                                            </div>
                                        )
                                    })}
                                    <div className='mgt' id='notificationQuestionFight' />
                                    {resultAnswer !== false && (
                                        <ResultMessage resultAnswer={resultAnswer} />
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                    {isWaitDoneFight && (
                        <div className='item-center box-primary'>
                            <Lottie
                                options={{ loop: true, autoplay: true, animationData: dino }}
                                height='25vh'
                                width='25vh'
                            />
                            <p className='text-large success'>Xin chúc mừng bạn đã trả lời xong câu hỏi.</p>
                            <p className='text-large success'>Cùng chờ đợi kết quả từ hệ thống trong giây lát</p>
                        </div>
                    )}
                    {isDoneFight && (
                        <div className='item-center box-primary'>
                            <Lottie
                                options={{ loop: true, autoplay: true, animationData: win }}
                                height='25vh'
                                width='25vh'
                            />
                            {roomInfo.FightType == 1 ?
                                <>
                                    <p className='text-large success mgb-2'>Người chiến thắng:</p>
                                    <TooltipUser userInfo={dataDoneFight} avatarClass='avatar-guest-large' />
                                    <p className='text-large success bold mgt'>{dataDoneFight?.FullName}</p>
                                    {this.renderButtonBack()}
                                </>
                                :
                                <>
                                    <p className='text-large success'>Đội chiến thắng</p>
                                    <div className='row item-center box-primary mgt'>
                                        {dataDoneFight.map((item, key) =>
                                            <div className='col-md-3 col-sm-6 col-xs-6 item-center' key={key}>
                                                <TooltipUser userInfo={item} />
                                                <p className='mgt'>{item.FullName}</p>
                                            </div>
                                        )}
                                    </div>
                                    {this.renderButtonBack()}
                                </>
                            }
                        </div>
                    )}
                    <ChatFight roomId={roomInfo.QuestionFightRoomId} onUpdateChat={e => this.onUpdateChat = e} />
                    <Modal
                        {...PROP.MODAL}
                        visible={showModal}
                        onCancel={() => this.setState({ showModal: false, userSelect: {}, giftSelect: {} })}
                        centered
                        width={'140vh'}
                    >
                        <div className='mgt-2 item-center'>
                            <div className='flex item-center'>
                                <TooltipUser userInfo={dataUser} />
                                <Icon
                                    name='gift'
                                    className='success mgl-2 mgr-2'
                                    style={{ fontSize: '5vh' }}
                                />
                                <TooltipUser userInfo={userSelect} />
                            </div>
                            <div className="row mgt">
                                {listGift.map((item, key) =>
                                    <Tooltip {...PROP.TOOLTIP_HOME} mouseEnterDelay={0.5} key={key} title={
                                        <div>
                                            <Button
                                                className='popover-item bg-success'
                                                onClick={this.sendGift}
                                                loading={isPress}
                                            >
                                                Tặng
                                            </Button>
                                        </div>
                                    }>
                                        <div
                                            className={`col-md-2 col-sm-3 col-xs-4 item-center box-item-gift-fight ${giftSelect.QuestionFightGiftId == item.QuestionFightGiftId ? 'bg-success' : ""}`}
                                            key={key}
                                            onClick={() => this.setState({ giftSelect: item })}
                                        >
                                            <img
                                                className='mgb'
                                                src={`${URI_PATH}${this.getAsset(item).image}`}
                                                style={{ width: '7vh' }}
                                            />
                                            <p>{this.getAsset(item).name}</p>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </Modal>
                </>
            )
        }
        return <Loading />;
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, updateCountMission })(withRouter(Fight));