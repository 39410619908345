import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { getListGiftTicketApi } from '../../api/gift/ticket';
import { showNoificationDameBoss, showNotification, showNotificationAsset } from '../../utils/notification';
import Loading from '../../components/Loading';
import Countdown, { zeroPad } from 'react-countdown-now';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Progress } from 'antd';
import ModalBuyTicket from '../../components/gift/ModalBuyTicket';
import TooltipUser from '../../components/TooltipUser';
import { COLOR, MESSAGE } from '../../utils/constants';
import Icon from 'react-fontawesome';
import { formatDecimal, priceFormat, speakEng } from '../../utils/common';
import { getQuestionApi, getTimeBuyTicketApi, saveAnswerApi } from '../../api/question/ticket';
import { EmptyLottie, TitleSuccess } from '../../components/frame';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import { showModalEat } from '../../actions/home/modalEat';
import firework from '../../assets/lottie/firework.json';
import { socket } from '../../components/realtime/ReadSocketEvent';
import { withRouter } from 'react-router-dom';
import Lottie from 'react-lottie';
import { Subscription } from "rxjs";
import EventBus, { EventBusName } from '../../services/event-bus';
import ButtonNewbie from '../../components/newbie/ButtonNewbie';

const QUESTION_TICKET_TYPE = {
    COUNTDOWN: 1,
    START: 2,
    START_SUCCESS: 3,
    START_FAIL: 4
}

class GiftTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            endList: false,
            timeBuyTicketStart: '',
            timeBuyTicketEnd: '',
            showModalBuyTicket: false,
            listBuy: [],
            ticketData: {},
            isPress: false,
            questionCost: 0,
            bossData: {},
            type: QUESTION_TICKET_TYPE.COUNTDOWN,
            isLoadingQuestion: true,
            isLoadingHistory: true,
            timeQueue: 999999999,
            percentTime: '0%',
            resultAnswer: false,
            selectAnswerKey: '',
            isAnswer: false,
            questionList: [],
        }
        this.page = 1;
        this.timeDiff = 5;
        this.correctAnswer = '';
        this.resultColor = '';
        this.questionInterval = '';
        this.subScription = new Subscription();
    }

    onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res) => {
            if (res.type === EventBusName.CHANGE_LANGUAGE) {
                this.getTimeBuyTicket();
                clearInterval(this.questionInterval);
                this.timeDiff -= 1;
            }
        });
        this.subScription.add(sub);
    };

    getListGiftTicket = () => {
        getListGiftTicketApi(this.page).then(res => {
            if (res.code == 1) {
                this.setState({
                    listBuy: this.page == 1 ? res.listBuy : [...this.state.listBuy, ...res.listBuy],
                    isLoadingHistory: false,
                    endList: res.listBuy.length == 0,
                });
            }
            else {
                this.setState({
                    isLoadingHistory: false,
                    endList: true,
                });
                showNotification('Có lỗi xảy ra khi tải lịch sử mua Gold[z]', 0);
            }
        }).catch(() => {
            this.setState({
                isLoadingHistory: false,
                endList: true,
            });
            showNotification('Có lỗi xảy ra khi tải lịch sử mua Gold[z]', 0);
        })
    }

    getHistory = () => {
        this.page = 1;
        this.getListGiftTicket();
    }

    getTimeBuyTicket = () => {
        getTimeBuyTicketApi().then(res => {
            if (res.code == 1) {
                const { isOpenTime, isCloseTime, boss } = res;
                this.setState({
                    timeBuyTicketStart: res.timeBuyTicketStart,
                    timeBuyTicketEnd: res.timeBuyTicketEnd,
                    ticketData: res.ticketData,
                    questionCost: res.questionCost,
                    bossData: boss,
                    isLoading: false
                });
                if (boss.IsDie == 2) {
                    this.setState({
                        type: QUESTION_TICKET_TYPE.START_SUCCESS
                    })
                    this.getHistory();
                }
                else if (isCloseTime && boss.IsDie == 0) {
                    this.setState({
                        type: QUESTION_TICKET_TYPE.START_FAIL
                    })
                    this.getHistory();
                }
                else if (isOpenTime && boss.IsDie == 0) {
                    this.setState({
                        type: QUESTION_TICKET_TYPE.START
                    })
                    this.getQuestion();
                }
                else {
                    this.setState({
                        type: QUESTION_TICKET_TYPE.COUNTDOWN
                    })
                    this.getHistory();
                }
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(() => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () => {
        this.page += 1;
        this.getListGiftTicket();
    }

    getQuestion = () => {
        getQuestionApi().then(res => {
            if (res.code == 1) {
                this.questionLobby = res.questionList[0];
                this.timeDiff = res.timeDiff;
                this.setState({
                    isAnswer: false,
                    percentTime: '0%',
                    resultMessage: '',
                    selectAnswerKey: '',
                    isLoadingQuestion: false,
                });
                this.questionInterval = setInterval(() => {
                    this.timeDiff -= 1;
                    this.setState({ percentTime: `${(30 - this.timeDiff) / 30 * 100}%` });
                    if (this.timeDiff <= -1) {
                        this.setState({
                            isAnswer: false,
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isLoadingQuestion: true
                        });
                        this.questionLobby = {};
                        this.correctAnswer = '';
                        this.timeDiff = 30;
                        clearInterval(this.questionInterval);
                        this.getQuestion()
                    }
                }, 1000);
            }
            else {
                if (res.code == -5) this.props.showModalEat(res.listFood);
                else showNotification(res.message, res.code);
            }
        }).catch(() => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    renderHistory = () => {
        const {
            endList,
            listBuy,
            type,
            isLoadingHistory
        } = this.state;
        if (type !== QUESTION_TICKET_TYPE.START) {
            if (isLoadingHistory) {
                return <Loading small />
            }
            return (
                <>
                    <p className='mgt-2 text-center'>Lịch sử mua Gold[z]</p>
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={!endList}
                        loader={<Loading key={0} small />}
                        threshold={10}
                    >
                        {listBuy.map((item, key) => (
                            <div className='container-list-question' key={key}>
                                <div className='flex-row'>
                                    <div className='item-center flex-column flex-1'>
                                        <TooltipUser userInfo={item} avatarClass='avatar-guest-small mgb' />
                                        <p className='text-center bold mgl'>{item.FullName}</p>
                                    </div>
                                    <div className='item-center flex-column flex-1'>
                                        <p>Thời gian mua</p>
                                        <p className='silver'>{item.CrDateTime}</p>
                                    </div>
                                </div>
                                <div className='flex-column item-center mgt-2'>
                                    <p className='bonus-question-event'>+ {item.TicketCount} Gold[z]</p>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </>
            )
        }
        return null;
    }

    renderTicketCost = () => {
        const {
            ticketData,
            type
        } = this.state;
        return (
            <>
                {type !== QUESTION_TICKET_TYPE.START &&
                    <div className='flex-column item-center'>
                        <p className='title-event-question'>
                            <p>1 Gold[z] = {priceFormat(ticketData.ticketCost)} $[z]</p>
                            <Icon name='arrow-down' size='2vh' />
                            <p> + {priceFormat(ticketData.ticketPerDay)} $[z]/ngày</p>
                        </p>
                    </div>
                }
            </>
        )
    }

    renderQuestion = () => {
        const {
            isLoadingQuestion,
            type,
            resultAnswer,
            selectAnswerKey,
            bossData,
            percentTime,
        } = this.state;
        if (type === QUESTION_TICKET_TYPE.START) {
            return (
                <>
                    <div className='relative'>
                        <Progress
                            strokeColor={{ '0%': '#FF0000', '100%': '#2EFF2E' }}
                            percent={100 * +bossData.CurrentBlood / +bossData.Blood}
                            showInfo={false}
                            status='active'
                            strokeLinecap='square'
                            className='club-level-progress'
                        />
                        <p className='question-boss-blood'>{formatDecimal(bossData.CurrentBlood)} HP</p>
                    </div>
                    <div className='text-center mgt mgb'>
                        <p>Sức mạnh sẽ tăng khi <u className='pointer' onClick={() => this.props.history.push('/menu/brand-group')}>ghép nhóm có nhiều thành viên hoặc mua ngôi sao may mắn.</u></p>
                        <p><i>Sau khi phong ấn được phá sẽ ngẫu nhiên chọn <b>người nhanh tay nhất</b> hoặc <b>người dame nhiều nhất</b> sẽ mua được Gold[z]</i></p>
                    </div>
                    <div className='button-question-lobby-container'>
                        <div className="progress-div mgb" style={{ width: '100%' }}>
                            <div style={{ width: percentTime, background: this.timeDiff <= 3 ? COLOR.COLOR_DANGER : COLOR.COLOR_SUCCESS }} className="progress" />
                        </div>
                    </div>
                    {isLoadingQuestion ?
                        <Loading small />
                        :
                        <div className='animated fadeInUp faster'>
                            <p className='title-question'>{this.questionLobby.QuestionTitle}</p>
                            {this.questionLobby.answers.map((value, key) => {
                                return (
                                    <div
                                        key={key}
                                        onClick={() => this.saveAnswer(value, key, this.questionLobby.QuestionLobbyId)}
                                    >
                                        <TextAnswer
                                            answer={value}
                                            keySelect={key}
                                            selectAnswerKey={selectAnswerKey}
                                            correctAnswer={this.correctAnswer}
                                        />
                                    </div>
                                )
                            })}
                            {resultAnswer !== false && (
                                <ResultMessage resultAnswer={resultAnswer} />
                            )}
                            <div className='notificationQuestionGuest'></div>
                            {this.state.resultAnswer == 1 &&
                                <Lottie
                                    options={{ loop: true, autoplay: true, animationData: firework }}
                                    width='50vh'
                                    style={{ position: 'absolute', top: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0 }}
                                />
                            }
                        </div>
                    }
                </>
            )
        }
        return null
    }

    saveAnswer = (answer, keySelect, questionId) => {
        if (this.state.isAnswer === false) {
            let idNotification = '#notificationQuestionGuest';
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            saveAnswerApi(questionId, answer).then(res => {
                if (res.code == 1 || res.code == 0) {
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    setTimeout(() =>{
                        speakEng(res.answer)
                    }, 0)
                    if (res.code == 1) {
                        //assets bonus
                        showNotificationAsset(res.assetInfo);
                        showNoificationDameBoss(this.props.dataUser, res.dame, res.isCrit == 1);
                        const bossData = res.isKillBoss ? res.bossKilledData : {
                            ...this.state.bossData,
                            IsDie: res.isKillBoss ? 2 : 0,
                            CurrentBlood: +this.state.bossData.CurrentBlood - +res.dame
                        }
                        this.setState({
                            resultAnswer: res.code,
                            bossData,
                        })
                        socket.emit('dame_boss_ticket', {
                            user: this.props.dataUser,
                            bossData,
                            dame: +res.dame,
                            isCrit: res.isCrit
                        })
                        if (res.isKillBoss) {
                            this.getTimeBuyTicket();
                            clearInterval(this.questionInterval);
                        }
                    }
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(() => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
            })
        }
    }

    renderHeader = () => {
        const {
            timeBuyTicketStart,
            timeBuyTicketEnd,
            ticketData,
            type,
            showModalBuyTicket,
            bossData
        } = this.state;
        if (type == QUESTION_TICKET_TYPE.COUNTDOWN) {
            return (
                <div className='flex-column item-center'>
                    <img
                        src={require('../../assets/image/phong-an.gif')}
                        style={{ width: '50vh' }}
                    />
                    <p className='title-event-question'>
                        Hoạt động mở phong ấn sẽ bắt đầu sau
                        <Countdown
                            date={timeBuyTicketStart * 1000}
                            renderer={({ hours, minutes, seconds }) => (<span> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                            onComplete={() => {
                                setTimeout(() => this.getTimeBuyTicket(), 1000)
                            }}
                        />
                    </p>
                </div>
            )
        }
        if (type == QUESTION_TICKET_TYPE.START) {
            return (
                <div className='flex-column item-center'>
                    <img
                        src={require('../../assets/image/phong-an.gif')}
                        style={{ width: '50vh' }}
                    />
                    <p className='title-event-question'>
                        Hoạt động phá phong ấn sẽ kết thúc sau
                        <Countdown
                            date={timeBuyTicketEnd * 1000}
                            renderer={({ hours, minutes, seconds }) => (<span> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                            onComplete={() => {
                                setTimeout(() => this.getTimeBuyTicket(), 1000)
                            }}
                        />
                    </p>
                </div>
            )
        }
        if (type == QUESTION_TICKET_TYPE.START_SUCCESS) {
            return (
                <div className='flex-column item-center'>
                    <img
                        src={require('../../assets/image/phong-an.png')}
                        style={{ width: '25vh' }}
                        className='mgb'
                    />
                    <TitleSuccess>Phá phong ấn thành công, cả thành phố đã vượt qua thử thách</TitleSuccess>
                    {(bossData.IsLogic != 2 || bossData.UserId == this.props.dataUser.UserId) &&
                        <>
                            <Button
                                className='bg-success shadow-pulse'
                                onClick={() => this.setState({ showModalBuyTicket: true })}
                            >
                                Mua Gold[z]
                            </Button>
                            <ModalBuyTicket
                                onClose={() => {
                                    this.setState({ showModalBuyTicket: false });
                                    this.getHistory();
                                }}
                                showModal={showModalBuyTicket}
                                ticketData={ticketData}
                            />
                        </>
                    }
                </div>
            )
        }
        if (type == QUESTION_TICKET_TYPE.START_FAIL) {
            return (
                <div className='flex-column item-center'>
                    <img
                        src={require('../../assets/image/phong-an.gif')}
                        style={{ width: '50vh' }}
                    />
                    <TitleSuccess className='bg-danger'>Phá phong ấn thất bại, cả thành phố không vượt qua thử thách</TitleSuccess>
                </div>
            )
        }
        return null
    }

    readDameBossEvent = (data) => {
        try {
            const { bossData } = this.state
            if (bossData.IsDie == 0 && data.bossData.IsDie == 0) {
                showNoificationDameBoss(data.user, data.dame, data.isCrit == 1)
                this.setState({
                    bossData: {
                        ...data.bossData,
                        CurrentBlood: +this.state.bossData.CurrentBlood - +data.dame
                    }
                })
            }
            else if (bossData.IsDie == 0 && data.bossData.IsDie == 2) {
                this.getTimeBuyTicket();
                this.setState({ bossData: data.bossData })
            }
        } catch (error) {
            console.log({ error });
        }
    }

    componentDidMount() {
        this.getTimeBuyTicket();
        socket.on('receive_dame_boss_ticket', this.readDameBossEvent);
        this.onRegisterEventBus();
    }

    componentWillUnmount() {
        socket.removeListener('receive_dame_boss_ticket', this.readDameBossEvent);
        clearInterval(this.questionInterval);
        if (this.subScription) this.subScription.unsubscribe();
    }

    render() {
        const {
            isLoading
        } = this.state;
        if (isLoading) return (<Loading small />);
        return (
            <div>
                {this.renderHeader()}
                <div className='mgt flex item-center'>
                    <ButtonNewbie />
                </div>
                {this.renderQuestion()}
                {this.renderTicketCost()}
                {this.renderHistory()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { dataUser, countMission };
}

export default connect(mapStateToProps, { changePoint, showModalEat })(withRouter(GiftTicket));