import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import TimeSlider from "react-input-slider";
import InfiniteScroll from 'react-infinite-scroller';
import { getAudio, setAudioIndex, setAudioCurrentTime, setAudioDuration, setAudioPlay } from '../../actions/audio';
import Logo from '../../components/lobby/Logo';
import { isIOS, isMacOs } from "react-device-detect";

class Main extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getAudio();
    }

    handleLoadedData = () => {
        this.props.setAudioDuration(this.audioRef.duration);
    };

    handlePausePlayClick = (e) => {
        this.props.setAudioPlay(!this.props.isPlay);
        if (e) e.stopPropagation();
    };

    handleTimeSliderChange = ({ x }) => {
        this.props.setAudioCurrentTime(x);
        if (!this.props.isPlay) {
            this.props.setAudioPlay(true);
        }
    };

    navigateAudio = (key) => {
        this.props.setAudioIndex(key);
        if (isIOS || isMacOs) {
            if (this.props.isPlay) {
                this.handlePausePlayClick();
                setTimeout(() => {
                    this.handlePausePlayClick();
                }, 50);
            }
            else this.handlePausePlayClick();
        }
    }

    getNumberAudio = (item, key) => {
        if (item?.isVip) {
            return 'VIP'
        }
        if (item?.isNext) {
            return '➤'
        }
        return key + 1;
    }

    renderItemAudio = (item, key, isNew) => {
        const { listAudio, audioIndex, currentTime, duration, isPlay } = this.props;
        const isHidden = key == listAudio.length - 3 && typeof (isNew) !== 'boolean';
        return (
            <div
                className={`container-list-mp3 pointer ${typeof (isNew) === 'boolean' ? 'border-audio-new mgb-3' : ''} ${isHidden ? 'hidden' : ''}`}
                key={key}
                onClick={() => this.navigateAudio(key)}
            >
                <div className='flex align-center'>
                    <div className='stt-list'>
                        <p className={'mgb-0 bold ' + (item?.isVip ? 'danger' : 'text-large black')}>
                            {this.getNumberAudio(item, key)}
                        </p>
                    </div>
                    <div className='title-mp3-container'>
                        <p>{item?.AudioTitle}</p>
                        {audioIndex == key &&
                            <TimeSlider
                                axis="x"
                                xmax={duration}
                                x={currentTime}
                                // onChange={this.handleTimeSliderChange}
                                styles={{
                                    track: {
                                        backgroundColor: 'rgba(255,255,255,0.3)',
                                        height: "0.3vh",
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: "#fff",
                                        height: "0.3vh",
                                        width: '100%'
                                    },
                                    thumb: {
                                        marginLeft: '1.2vh',
                                        marginBottom: '0.2vh',
                                        width: "2vh",
                                        height: "2vh",
                                        backgroundColor: "transparent",
                                        borderRadius: "1vh",
                                    },
                                }}
                            />
                        }
                    </div>
                    {isPlay && audioIndex == key ?
                        <div
                            className='flex item-center'
                            onClick={this.handlePausePlayClick}
                        >
                            <img
                                src={require('../../assets/image/player/pause.png')}
                                className='icon-play-mp3'
                            />
                        </div>
                        :
                        <div
                            className='flex item-center'
                            onClick={() => this.navigateAudio(key)}
                        >
                            <img
                                src={require('../../assets/image/player/play.png')}
                                className='icon-play-mp3'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        const { isLoadingAudio, listAudio } = this.props;
        const newAudio = listAudio.find((_, index) => index === listAudio.length - 3);
        return (
            <>
                <div className='item-center mgt-2'>
                    <a href='https://www.facebook.com/Gemslight.Nguyen' target='_blank'>
                        <img
                            src={require('../../assets/image/avatar-principal.jpg')}
                            className='avatar-guest-huge'
                        />
                    </a>
                    <p className='mgt'>Nguyễn Minh Ngọc Podcast</p>
                </div>
                {isLoadingAudio ?
                    <Loading small />
                    :
                    <>
                        <p className='text-center mgt-2 text-large bold warning'>TẬP MỚI NHẤT</p>
                        {this.renderItemAudio(newAudio, listAudio.length - 3, true)}
                        {listAudio.map((item, key) => this.renderItemAudio(item, key))}
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { listAudio, isLoadingAudio, audioIndex, currentTime, duration, isPlay, endList, page } = state.getAudio;
    return { listAudio, isLoadingAudio, audioIndex, currentTime, duration, isPlay, endList, page };
}

export default connect(mapStateToProps, { getAudio, setAudioIndex, setAudioCurrentTime, setAudioDuration, setAudioPlay })(Main);