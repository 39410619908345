import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataAffiliateApi = () =>{
    let url = `${URI_PATH}app/charge/affiliate/getDataAffiliate`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const setUsetAffApi = userId =>{
    let url = `${URI_PATH}app/charge/affiliate/setUserAff`;
    let body = {
        UserId: userId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteAffApi = userAff =>{
    let url = `${URI_PATH}app/charge/affiliate/deleteAff`;
    let body = {
        UserAff: userAff
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const unsetUserAffApi = () =>{
    let url = `${URI_PATH}app/charge/affiliate/unsetUserAff`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}