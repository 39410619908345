const INITAL_STATE = {
    data: [],
    dataBid: [],
    listUserLikeBid: [],
    likeRemain: '-',
    isLoading: true,
    isRefreshing: false,
    endOfData: false,
    loadingFooter: false,
    page: 1,
    rankAchievement: '',
    birthAchievement: '',
    searchPhone: '',
    rankStatus: ''
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_LIST_RANK':
            if(action.isLoadMore){
                return { ...state,
                    data:[...state.data, ...action.data],
                    page: action.page,
                    loadingFooter: false,
                    endOfData: action.data.length > 0 ? false : true
                };
            }
            return { ...state,
                data: action.data,
                page: action.page,
                rankAchievement: action.rankAchievement,
                birthAchievement: action.birthAchievement,
                searchPhone: action.searchPhone,
                rankStatus: action.rankStatus,
                isLoading: false,
                endOfData: false,
                isRefreshing: false
            };
        case 'SUCCESS_GET_LIST_BID':
            return{ ...state,
                dataBid: action.listBid,
                listUserLikeBid: action.listUserIdLiked
            }
        case 'SUCCESS_GET_LIKE_REMAIN':
            return { ...state, likeRemain: action.data}
        case 'UPDATE_USER_LIKE':
            let item = state.data.find((v) => {
                return v.UserId == action.userId;
            });
            if (item && item.hasOwnProperty('IsLiked')) {
                item['IsLiked'] = '2';
            }
            state.listUserLikeBid.push(action.userId);
            return { ...state, likeRemain: state.likeRemain - 1}
        case 'INIT_LOAD_MORE':
            return { ...state, loadingFooter: true}
        case 'INIT_GET_LIST_RANK':
            return { ...state, isRefreshing: true}
        case 'FALSE_GET_LIST_RANK': case 'FALSE_GET_LIST_BID': case 'FALSE_GET_LIKE_REMAIN':
            return { ...state }
        default:
            return state;
    }
};

