export const showModalEat = listFood =>{
    return dispatch => {
        dispatch({ type: 'SHOW_MODAL_EAT_FOOD', listFood});
    }
}

export const closeModalEat = () =>{
    return dispatch => {
        dispatch({ type: 'CLOSE_MODAL_EAT_FOOD' });
    }
}