import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { MESSAGE } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { TitleSuccess } from '../../components/frame';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getContentApi, getValueInvestApi, saveContentApi, saveDiscordLinkApi, saveValueInvestApi } from '../../api/admin/zcrazy';
import ButtonGroup from 'antd/lib/button/button-group';
import { getDiscordLinkApi } from '../../api/menu';

class ZCrazy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            editorState: EditorState.createEmpty(),
            selectTab: 1,
            discordLink: '',
            inputInvestPercentUp: '',
            inputInvestPercentDown: '',
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    getContentAdmin = () => {
        getContentApi().then(res => {
            const html = res.data?.Content
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState, isLoading: false })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    saveNotif = () => {
        const content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        saveContentApi(content).then(res => {
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    saveDiscord = () =>{
        saveDiscordLinkApi(this.state.discordLink).then(res =>{
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    saveInvestValue = () =>{
        saveValueInvestApi(this.state.inputInvestPercentUp, this.state.inputInvestPercentDown).then(res =>{
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    renderTab = () => {
        switch (this.state.selectTab) {
            case 1:
                const { isLoading, editorState } = this.state;
                return (
                    <>
                        {isLoading ?
                            <Loading small />
                            :
                            <>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="editor-admin"
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                                <div className='item-center'>
                                    <Button
                                        className='bg-success mgt-3'
                                        onClick={this.saveNotif}
                                    >
                                        Lưu lại
                                    </Button>
                                </div>
                            </>
                        }
                    </>
                )
            case 2:
                const { discordLink } = this.state
                return (
                <>
                    <textarea
                        className='form-control'
                        style={{ height: '22vh', lineHeight: '3vh' }}
                        value={discordLink}
                        onChange={e => this.setState({discordLink: e.target.value})}
                    />
                    <div className='item-center'>
                        <Button
                            className='bg-success mgt-3'
                            onClick={this.saveDiscord}
                        >
                            Lưu lại
                        </Button>
                    </div>
                </>
            )
            case 3:
                return (
                    <>
                        <TitleSuccess>Phần trăm random cổ tức {'>'} 0</TitleSuccess>
                        <div className='item-center'>
                        <input
                            className='form-control col-md-4'
                            value={this.state.inputInvestPercentUp}
                            onChange={e => this.setState({inputInvestPercentUp: e.target.value})}
                            onBlur={this.saveInvestValue}
                        />
                        </div>
                        <TitleSuccess>Phần trăm random cổ tức {'<'} 0</TitleSuccess>
                        <div className='item-center'>
                        <input
                            className='form-control col-md-4'
                            value={this.state.inputInvestPercentDown}
                            onChange={e => this.setState({inputInvestPercentDown: e.target.value})}
                            onBlur={this.saveInvestValue}
                        />
                        </div>
                    </>
                )
        }
    }

    getDiscordLink = () =>{
        getDiscordLinkApi().then(res =>{
            this.setState({discordLink: res.data})
        })
    }

    getValueInvest = () =>{
        getValueInvestApi().then(res =>{
            this.setState({
                inputInvestPercentUp: parseFloat(res.data.PercentUp),
                inputInvestPercentDown: parseFloat(res.data.PercentDown)
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidMount() {
        this.getContentAdmin();
        this.getDiscordLink();
        this.getValueInvest()
    }

    render() {
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({ selectTab: 1 })} className={this.buttonGroupClass(1)}>Thông báo</Button>
                    <Button onClick={() => this.setState({ selectTab: 2 })} className={this.buttonGroupClass(2)}>Discord</Button>
                    <Button onClick={() => this.setState({ selectTab: 3 })} className={this.buttonGroupClass(3)}>Cổ phiếu</Button>
                </ButtonGroup>
                {this.renderTab()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(ZCrazy)