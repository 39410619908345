import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Guide from '../../screens/menu/Guide';
import MainMenu from '../menu/MainMenu';
import ListVestige from '../../components/home/ListVestige';
import { updateStateNavigate } from '../../actions/menu';
import guide from '../../assets/lottie/guide.json';
import { Button, Modal } from 'antd';
import HeaderLottie from '../../components/HeaderLottie';
import { PROP } from '../../utils/constants';
import { updateDataUser } from '../../actions/home';
import { videoGuides } from '../../utils/common';
import { Collapse } from 'react-collapse';
import Icon from 'react-fontawesome';
import QuestionFight from './QuestionFight';
import HomeScreen from '../../screens/home';
import { showErrorZcrazy, showNotification } from '../../utils/notification';
import { getDiscordLinkApi } from '../../api/menu';
import Target from './Target';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelelct: this.props.menuTab || 1,
            showModal: false,
            selectShowVideo: -1,
            discordLink: ''
        }
    }

    setTab = (tabSelelct) => {
        this.setState({ tabSelelct })
        this.props.updateStateNavigate({ menuTab: tabSelelct })
    }

    renderTab = () => {
        const { tabSelelct } = this.state;
        switch (tabSelelct) {
            case 1:
                return <QuestionFight.Lobby />
            case 2:
                return <Guide />
            case 3:
                return <MainMenu />
            case 4:
                return <Target />
            case 5:
                return <HomeScreen.Property />
        }
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.tabSelelct) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    handleDiscordClick = () =>{
        this.props.history.push('menu/theory')
    }

    handleTheoryClick = () =>{
        this.props.history.push('menu/theory')
    }

    getDiscordLink = () =>{
        getDiscordLinkApi().then(res =>{
            this.setState({discordLink: res.data})
        })
    }

    // componentDidMount(){
    //     this.getDiscordLink();
    // }

    render() {
        const {selectShowVideo} = this.state;
        const { dataUser } = this.props;
        return (
            <>
                <div className='relative'>
                    <HeaderLottie
                        source={guide}
                    />
                    {/* <div className='theory-button-menu' onClick={this.handleTheoryClick}>
                        <p className='mgb-0'>Lý thuyết trò chơi</p>
                    </div> */}
                    {/* <img
                        src={dataUser.IsZcrazy > 0 ? require('../../assets/image/talk_now.png') : require('../../assets/image/talk_now_disable.png')}
                        className='talk-now'
                        onClick={this.handleDiscordClick}
                    /> */}
                </div>
                {/* <p className='mgb text-center text-huge bold'>GAME PHÁT TRIỂN BẢN THÂN</p>
                <p className='mgb text-center text-huge bold'>BẢN ĐỒ TỶ PHÚ</p>
                <div className='item-center mgt'>
                    <Button
                        className='bg-warning radius-max'
                        onClick={() => this.setState({showModal: true})}
                    >
                        LUẬT CHƠI
                    </Button>
                </div> */}
                <div className="row mgt-2">
                    <ButtonGroup className='item-center flex mgb-2' style={{ width: '100%' }}>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.setTab(1)}>Đấu trường Z</Button>
                        <Button className={this.buttonGroupClass(2)} onClick={() => this.setTab(2)}>Lịch sự kiện</Button>
                        <Button className={this.buttonGroupClass(3)} onClick={() => this.setTab(3)}>Nhiệm vụ <img style={{width: '3vh', marginBottom: '0.5vh'}} src={require('../../assets/image/crest.png')} /></Button>
                        <Button className={this.buttonGroupClass(4)} onClick={() => this.setTab(4)}>Thành tích</Button>
                        <Button className={this.buttonGroupClass(5)} onClick={() => this.setTab(5)}>Tài sản</Button>
                    </ButtonGroup>
                </div>
                {this.renderTab()}
                <div style={{ height: 35 }} />
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    <p className='text-large text-center warning bold'>LUẬT CHƠI</p>
                    <p className='text-large text-center warning bold'>GAME - BẢN ĐỒ TỶ PHÚ</p>
                    <p className='success bold'>Thành Phố Z có 3 loại tiền tệ:</p>
                    <div className='mgl-2'>
                        <p>• đ[z] - tiền mệnh giá thấp</p>
                        <p>• $[z] - tiền mệnh giá cao</p>
                        <p>• Gold[z] - kim loại vàng</p>
                    </div>
                    <div style={{ height: 15 }} />
                    <p className='success bold'>Nhiệm vụ người chơi là gì?</p>
                    <div className='mgl-2'>
                        <p>• Người chơi sẽ bắt đầu lập nghiệp với số vốn 0 đồng, kiếm tiền bằng trí tuệ vượt qua những thử thách trong game.</p>
                        <p>• Mục tiêu từng bước gây dựng tài sản để trở thành một TỶ PHÚ THỰC THỤ.</p>
                    </div>
                    <div style={{ height: 15 }} />
                    <p className='success bold'>Thế nào là thắng?</p>
                    <div className='mgl-2'>
                        <p>• Người chơi thắng cuộc là người vượt qua được toàn bộ mục tiêu, và ngộ ra những bài học về cách tư duy tài chính được ẩn chứa trong trò chơi.</p>
                    </div>
                    <div className='item-center mgt-2 mgb-2'>
                        <img
                            src={require('../../assets/image/line.png')}
                            className='line-img'
                        />
                    </div>
                    <p className='text-center text-large'>HƯỚNG DẪN CHƠI DÀNH CHO NGƯỜI MỚI</p>
                    <div className='mgt mgb'>
                        <hr width='75%' />
                    </div>
                    {videoGuides.map((item, key) =>
                        <div
                            key={key}
                            className='mgt-4 pointer'
                            onClick={() => this.setState({selectShowVideo: selectShowVideo == key ? - 1 : key})}
                        >
                            <div className='flex align-center' style={{justifyContent: 'space-between'}}>
                                <p className='text-large text-center mgt-2'>{key + 1}. {item.title}</p>
                                <Icon name='' />
                                <img
                                    src={selectShowVideo === key ? require('../../assets/image/arrow-up.png') : require('../../assets/image/arrow-down.png')}
                                    style={{ width: '4vh' }}
                                />
                            </div>
                            <Collapse isOpened={selectShowVideo === key}>
                                <div className='item-center mgt mgb'>
                                    <iframe
                                        loading="lazy"
                                        src={`https://www.youtube.com/embed/${item.video}`}
                                        frameBorder="0"
                                        allow="encrypted-media"
                                        allowFullScreen="allowFullScreen"
                                        style={{ height: '40vh', borderRadius: '2vh', width: '100%' }}
                                    />
                                </div>
                            </Collapse>
                        </div>
                    )}
                    <div style={{ height: 20 }} />
                    <p className='text-large text-center warning bold'>Chúc các bạn học tập & giải trí vui vẻ, bổ ích tại Z CRAZY!</p>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { menuTab } = state.getStateNavigate;
    return { dataUser, menuTab };
}

export default connect(mapStateToProps, { updateStateNavigate, updateDataUser })(withRouter(Main));