import React, { Component } from 'react';
import { Button } from 'antd';
import { URI_PATH } from '../../../utils/constants';
import {withRouter} from 'react-router-dom';
import {Tooltip} from 'antd';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class AssetSuccess extends Component{
    getTextLink = () =>{
        const data = this.props.data;
        if(data.AssetId <= 5) return 'Xây nhà';
        else if(data.AssetId >= 71) return 'Đúc Lệnh bài'
        return 'Đúc vương miện';
    }

    render(){
        const data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p>
                            <span className='bold'>{data.FullName}
                            <Checked data={data} />
                        </span> {data.AssetId <= 5 ? ('kết hợp thành công ' + data.AssetName + '. Chúc mừng tân gia!') : ('kết hợp thành công '+ data.AssetName + '. Thật là may mắn!')}
                        </p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-column item-center'>
                    <Tooltip
                        overlayClassName='black-tooltip'
                        title={
                            <div className='item-center'>
                                <p className='bold'>{data.AssetName}</p>
                                <p>Tăng {data.AssetBonus} % thành tựu</p>
                            </div>
                        }
                    >
                        <img className='newfeed-img' src={`${URI_PATH}${data.AssetImage}`} />
                    </Tooltip>
                    {!data.Guest &&
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={() => this.props.history.push('/home/upgrade')}
                        >
                            {this.getTextLink()}
                        </Button>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(AssetSuccess);