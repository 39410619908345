const INITAL_STATE = {
    countMission: {
        SumMission: '?'
    },
    countNotify:{},
    countQueue:{},
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_COUNT_MISSION':
            return {
                ...state,
                countMission: action.countMission,
                countNotify: action.countNotify,
                countQueue: action.countQueue,
                countProperty: action.countProperty,
                questionLobby: action.questionLobby,
                questionEnglish: action.questionEnglish,
                questionBoss: action.questionBoss,
            };
        case 'UPDATE_COUNT_MISSION':
            return {
                ...state,
                countMission:{
                    ...state.countMission,
                    [action.mission]: parseInt(state.countMission[action.mission]) - 1,
                    SumMission: state.countMission.SumMission - 1
                },
            }
        case 'UPDATE_COUNT_NOTIFY':
            return {
                ...state,
                countNotify:{
                    ...state.countNotify,
                    [action.notify]: parseInt(state.countNotify[action.notify]) + action.point
                }
            }
        case 'FALSE_GET_COUNT_MISSION':
            return { ...state };
        default:
            return state;
    }
};