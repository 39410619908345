import React, { Component } from 'react';
import {connect} from 'react-redux';
import {sendApi} from '../../api/charge';
import {MESSAGE, TICKET, PROP} from '../../utils/constants';
import {formatDecimal, replaceCost, getDecimalCount} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import charge from '../../assets/lottie/charge.json';
import {changePoint} from '../../actions/home/';
import HeaderLottie from '../../components/HeaderLottie';
import {Button} from 'antd';
import SearchUser from '../../components/SearchUser';

class Charge extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectPhone: [],
            selectMoney: 0,
            isPress: false,
            itemSearch: [],
            inputPass: '',
            inputSecure: '',
            inputMoney: '',
            inputComment: ''
        };
    }

    deleteSearch = e =>{
        if(e.key == 'Backspace' && this.state.selectPhone.length > 0){
            this.refs.searchUser.getInstance().clear();
            this.setState({selectPhone: []})
        }
    }

    changeInputMoney = e =>{
        let money = e.target.value;
        if(this.state.selectMoney == 0) this.setState({inputMoney: money?.replace(/,|\./g, '')});
        else this.setState({inputMoney: formatDecimal(money?.replace('.', ''))});
    }

    changeMoneyType = e =>{
        this.setState({inputMoney: '', selectMoney: e.target.value});
    }

    send = () =>{
        if(!this.state.isPress){
            let idNotification = '#notificationCharge';
            let {inputMoney, inputPass, inputSecure, selectPhone, selectMoney, inputComment} = this.state;
            if(selectPhone.length == 0) return showNotification('Vui lòng chọn số điện thoại cần chuyển', -1, idNotification);
            if(selectMoney == 0){
                if(inputMoney == '' || inputMoney <= 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', -1, idNotification);
                if(getDecimalCount(inputMoney) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1, idNotification);
            }
            else{
                inputMoney = replaceCost(inputMoney, false);
                if(inputMoney == '' || inputMoney < 1) return showNotification('Số '+ (selectMoney == 1 ? '$[z]' : 'đ[z]') +' phải lớn hơn 0 và không được để trống', -1, idNotification);
            }
            if(inputPass == '') return showNotification('Bạn chưa nhập mật khẩu', -1, idNotification);
            //
            let userId = selectPhone[0].userId;
            this.setState({isPress: true});
            sendApi(inputMoney, userId, inputPass, inputSecure, inputComment, selectMoney).then(res =>{
                showNotification(res.message, res.code, idNotification);
                if(res.code == 1){
                    if(selectMoney == 0){
                        showNotificationBonus(res.money, -1, 1);
                        this.props.changePoint([-res.money]);
                    }
                    else if(selectMoney == 1){
                        showNotificationBonus(res.money, -1, 2);
                        this.props.changePoint([null, -res.money]);
                    }
                    else {
                        showNotificationBonus(res.money, -1, 3);
                        this.props.changePoint([null, null, -res.money]);
                    }
                    this.setState({
                        isPress: false,
                        inputPass: '',
                        inputSecure: '',
                        inputMoney: '',
                        inputComment: ''
                    });
                }
                else this.setState({isPress: false});
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({isPress: false});
            })
        }
    }

    render(){
        let {inputMoney, inputComment, inputPass, inputSecure, isPress} = this.state;
        return(
            <div>
                <HeaderLottie source={charge}/>
                <div className='row item-center'>
                    <div className='col-sm-8 col-xs-12'>
                        <SearchUser
                            ref='searchUser'
                            onChange={selectPhone => this.setState({selectPhone})}
                            onKeyDown={this.deleteSearch}
                            placeholder='Đến tài khoản (số điện thoại)'
                        />
                        <select
                            className='form-control mgt'
                            onChange={this.changeMoneyType}
                            value={this.state.selectMoney}
                        >
                            <option value='0'>Gold[z]</option>
                            <option value='1'>$[z]</option>
                            <option value='2'>đ[z]</option>
                        </select>
                        <input
                            value={inputMoney}
                            onChange={this.changeInputMoney}
                            className='form-control text-center mgt'
                            placeholder='Nhập số lượng'
                        />
                        <input
                            value={inputComment}
                            onChange={e => this.setState({inputComment: e.target.value})}
                            className='form-control text-center mgt'
                            placeholder='Nhập lý do chuyển'
                        />
                        <input
                            value={inputPass}
                            onChange={e => this.setState({inputPass: e.target.value})}
                            className='form-control text-center mgt'
                            type='password'
                            placeholder='Nhập mật khẩu hiện tại'
                        />
                        <input
                            value={inputSecure}
                            onChange={e => this.setState({inputSecure: e.target.value})}
                            className='form-control text-center mgt'
                            placeholder='Mã bảo mật Authenticator (nếu bật)'
                        />
                        <div className='mgt' id='notificationCharge'/>
                        <Button
                            className='bg-success mgt-2'
                            onClick={this.send}
                            loading={isPress}
                        >
                            Xác nhận
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint})(Charge);