import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateDataUser, getUserBar, changePoint } from '../../actions/home';
import { Button, Modal } from 'antd';
import { KEY, MESSAGE, PROP } from '../../utils/constants';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { getCrestApi } from '../../api/menu';
import ItemMenu from '../../components/menu/ItemMenu';
import { withRouter } from 'react-router';
const medals = [
    { name: 'Ghép nhóm thành tựu', image: require('../../assets/image/menu/brand-group.png'), url: '/menu/brand-group', mission: 'GroupCount' },
    { name: 'Giao lưu', image: require('../../assets/image/menu/club-feast.png'), url: '/menu/club-feast', mission: 'FeastClubCount' },
    { name: 'Thăng hạng', image: require('../../assets/image/menu/club-level.png'), url: '/menu/club-level', mission: 'ContributeClubCount' },
    { name: 'Quà Thử thách', image: require('../../assets/image/progress-gift-day.png'), url: '/menu/gift-day', mission: 'GiftDayCount' },
    { name: 'Quà tăng trưởng', image: require('../../assets/image/menu/gift-effort.png'), url: '/menu/gift-effort', mission: 'GiftEffortCount' },
    { name: 'Sự kiện ngoại ngữ', image: require('../../assets/image/menu/question-event.png'), url: '/menu/gift-event', mission: 'GiftEventCount' },
    { name: 'Toán khởi nghiệp', image: require('../../assets/image/menu/question-train.png'), url: '/menu/gift-train', mission: 'GiftTrainCount' },
    { name: 'Thị trường chứng khoán', image: require('../../assets/image/menu/charge-invest.png'), url: '/menu/charge-invest', mission: 'BuyInvestCount' },
    { name: 'Quà thần bí', image: require('../../assets/image/menu/gift-spin.png'), url: '/menu/gift-spin', mission: 'GiftSpinCount' },
    { name: 'Phá phong ấn', image: require('../../assets/image/menu/gift-ticket.png'), url: '/menu/gift-ticket', mission: 'GiftTicketCount' },
    { name: 'Thả tym', image: require('../../assets/image/like.png'), url: '/menu/brand-online', mission: 'LikeCount' },
    { name: 'Đấu trường', image: require('../../assets/image/menu/question-fight.png'), url: '/question-fight', mission: 'FightCount' },
    { name: 'Giải cứu linh thú', image: require('../../assets/image/knight.png'), url: '/menu/question-boss', mission: 'QuestionBossCount' },
]

class Medal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isPress: false
        }
    }

    getCrest = () => {
        if(this.props.countMission.SumMission > 0){
            return showNotification('Hãy hoàn thành nhiệm vụ, cố gắng lên!', 0);
        }
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            getCrestApi().then(res => {
                if (res.code == 1) {
                    let achievement = res.achievement;
                    if (achievement > 0) {
                        showNotificationBonus(achievement, 1, 4);
                        this.props.changePoint([null, null, null, achievement]);
                    }
                    this.props.getUserBar();
                }
                showNotification(res.message, res.code);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    render() {
        const isEnough = this.props.countMission.SumMission == 0;
        return (
            <>
                {isEnough ?
                    <div>
                        <p className='bold text-center' style={{ marginBottom: '0.5vh', 'borderRadius': 0 }}>
                            Bạn đã hoàn thành thử thách ngày hôm nay
                        </p>
                    </div>
                    :
                    <>
                        <div className='flex-row item-center'>
                            <img className='bonus-crest-img' key={1} src={require('../../assets/image/crest.png')} />
                        </div>
                        <p className='bold text-center' style={{ marginBottom: '0.5vh', 'borderRadius': 0 }}>
                            Vượt qua {this.props.countMission.SumMission} thử thách để sở hữu huy chương Z và 1000 thành tựu
                        </p>
                    </>
                }
                <div className='item-center mgt-2' style={{ marginBottom: '0.5vh' }}>
                    <Button
                        className={`${isEnough ? 'bg-success' : 'bg-placeholder'} shadow-pulse`}
                        onClick={this.getCrest}
                        loading={this.state.isPress}
                    >
                        Nhận thưởng
                    </Button>
                </div>
                <div className='row mgt-3'>
                    {medals.map((dataMenu, k) =>
                        <div className='col-md-2 col-sm-3 col-xs-4' key={k}>
                            <ItemMenu
                                countMission={this.props.countMission}
                                countNotify={this.props.countNotify}
                                dataMenu={dataMenu}
                            />
                        </div>
                    )}
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={700}
                >
                    <p className='bold title-success text-center mgt'>
                        PHẦN THƯỞNG HUY CHƯƠNG Z
                    </p>
                    <div className='bonus-crest-container'>
                        <div className='flex-row item-center'>
                            <img className='bonus-crest-img' key={1} src={require('../../assets/image/crest.png')} />
                        </div>
                        <p className='text-large text-center mgt'>Nhận được thêm 1000 thành tựu</p>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission, countNotify } = state.getCountMission;
    return { dataUser, countMission, countNotify };
}

export default connect(mapStateToProps, { updateDataUser, getUserBar, changePoint })(withRouter(Medal));