import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getCategory, getSubCategory} from '../../actions/category/';
import {changePoint} from '../../actions/home/';
import {updateCountMission, updateCountNotify} from '../../actions/menu';
import {publishPostApi} from '../../api/post/publish';
import {MESSAGE, PROP} from '../../utils/constants';
import Icon from 'react-fontawesome';
import {showNotification, showNotificationBonus, showNotificationAsset} from '../../utils/notification';
import {getImage64} from '../../utils/common';
import {Button, Modal} from 'antd';
import {withRouter} from 'react-router-dom';
import {showModalEat} from '../../actions/home/modalEat';
import ReactQuill from 'react-quill';

class PostPublish extends Component{
    constructor(props){
        super(props);
        this.state = {
            postTitle: '',
            postReason: '',
            postContent: '',
            postImage: '',
            categoryId: '',
            subCategoryId: '',
            isPublish: false,
        };
    }

    changeCategory = e =>{
        let categoryId = e.target.value;
        this.setState({categoryId});
    }

    publishPost = () =>{
        let {categoryId, postReason, postTitle, postContent, postImage, isPublish} = this.state;
        let idNotification = '#notificationPublishPost';
        if(!isPublish){
            if(categoryId > 0 && postTitle && postContent && postReason){
                this.setState({isPublish: true});
                publishPostApi(categoryId, postReason, postTitle, postContent, postImage).then(res => {
                    if(res.code == 1){
                        let bonus = res.bonus;
                        // showNotificationBonus(bonus.Achievement, 1, 4);
                        // showNotificationAsset(res.assetInfo);
                        // showNotificationBonus(bonus.BalanceZ, 1, 3);
                        // this.props.changePoint([null, null, bonus.BalanceZ, bonus.Achievement]);
                        // this.props.updateCountMission('PostPublishCount');
                        // this.props.updateCountNotify('PostVoteCount', 1);
                        showNotification(res.message, res.code);
                        this.props.history.push('/menu/post');
                    }
                    else{
                        if(res.code == -5) this.props.showModalEat(res.listFood);
                        else showNotification(res.message, res.code, idNotification);
                        this.setState({isPublish: false});
                    }
                })
                .catch(error => {
                    this.setState({isPublish: false, showModalConfirm: false});
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                })
            }
            else{
                this.setState({showModalConfirm: false});
                if(!categoryId) showNotification('Bạn chưa chọn thể loại.', -1, idNotification);
                else if(!postReason) showNotification('Bạn chưa nhập lý do chia sẻ', -1, idNotification);
                else if(!postTitle) showNotification('Bạn chưa nhập tiêu đề.', -1, idNotification);
                else if(!postContent) showNotification('Bạn chưa nhập nội dung.', -1, idNotification);
            }
        }
    }

    uploadImage = event =>{
        let file = event.target.files[0];
        getImage64(file, postImage =>{
            this.setState({postImage});
        })
    }

    componentDidMount(){
        this.props.getCategory(1);
    }

    render(){
        let {categoryPost, subCategory} = this.props;
        let {showModalPublish, postReason, postContent, postTitle, postImage, isPublish} = this.state;
        return(
            <div className='publish-post-container'>
                <div className='item-center'>
                    <p className='bold text-gigantic'>KIẾN THỨC HAY CHIA SẺ LIỀN TAY </p>
                    <i className='mgt'>Sưu tầm hoặc viết ra một bài viết chất lượng là bạn đã giúp bao người không phải đổ máu trên thương trường</i>
                    <div className='row mgt item-center'>
                        <div className='col-md-6 col-sm-8 col-xs-12'>
                            <select
                                className='form-control'
                                onChange={this.changeCategory}
                            >
                                <option value=''>Chọn thể loại</option>
                                {categoryPost.map((item, key) =>(
                                    <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='mgt'>Tiêu đề:</p>
                    <div className='flex-row item-center'>
                        <input
                            className='form-control'
                            placeholder='Nhập tiêu đề bài viết'
                            value={postTitle}
                            onChange={e => this.setState({postTitle: e.target.value})}
                        />
                        <input
                            type='file'
                            hidden='hidden'
                            ref='inputImage'
                            onChange={this.uploadImage}
                            accept="image/*"
                        />
                        <Icon
                            name='camera'
                            className='publish-camera'
                            onClick={() => this.refs.inputImage.click()}
                        />
                    </div>
                    <p className='mgt'>Lý do tôi chia sẻ bài viết này vì:</p>
                    <div className='flex-row item-center'>
                        <input
                            className='form-control'
                            placeholder='Nhập lý do'
                            value={postReason}
                            onChange={e => this.setState({postReason: e.target.value})}
                        />
                    </div>
                    {postImage &&
                        <div className='flex-column item-center mgt'>
                            <img src={postImage} style={{width: '30vh'}}/>
                            <img
                                src={require('../../assets/image/delete.png')}
                                className='delete-image mgt'
                                onClick={() =>{
                                    this.refs.inputImage.value = '';
                                    this.setState({postImage: ''})}
                                }
                            />
                        </div>
                    }
                    <p className='mgt'>Nội dung:</p>
                    <div
                        className='mgt'
                        style={{cursor: 'text'}}
                        onClick={() => this.refs.postContent.focus()}
                    >
                        <ReactQuill
                            ref='postContent'
                            value={postContent}
                            onChange={postContent => this.setState({postContent})}
                            modules={{toolbar: [['bold', 'italic', 'underline']]}}
                            placeholder='Nhập nội dung'
                        />
                    </div>
                    <div className='item-center'>
                        <Button
                            className='mgt-4 bg-success'
                            style={{width: '20vh'}}
                            onClick={() => this.setState({showModalPublish: true})}
                        >
                            Đăng
                        </Button>
                    </div>
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalPublish}
                    onCancel={() => this.setState({showModalPublish: false})}
                    bodyStyle={{background: '#FCF150', borderRadius: '2vh'}}
                    closable={false}
                >
                    <div className='flex-column'>
                        <div className='item-center'>
                            <img
                                src={require('../../assets/image/warning.png')}
                                style={{width: '8vh'}}
                            />
                        </div>
                        <p className='black bold text-large'>Bạn sẽ không thể chỉnh sửa nội dung sau khi đăng tải!</p>
                        <p className='black'>Bạn vui lòng kiểm tra kĩ một lần nữa:</p>
                        <p className='black'>1- Nội dung đăng tải có ích với cộng đồng khởi nghiệp.</p>
                        <p className='black'>2- Hãy kiểm tra kỹ chính tả, dấu câu để bài viết chất lượng nhất.</p>
                        <p className='black'>3- Nội dung cần truyền đạt không vi phạm điều khoản, vi phạm pháp luật.</p>
                        <p className='black'>4- Ngôn từ sử dụng không xúc phạm, gây mất uy tín danh dự người khác.</p>
                        <p className='black'>5- Các bài viết sưu tầm hãy ghi tên tác giả để thể hiện sự tôn trọng.</p>
                        <div className='mgt' id='notificationPublishPost'></div>
                        <div className='flex-row mgt-3'>
                            <div className='flex-1 item-center'>
                                <Button
                                    className='bg-success'
                                    onClick={this.publishPost}
                                    loading={isPublish}
                                >
                                    Tôi đồng ý
                                </Button>
                            </div>
                            <div className='flex-1 item-center'>
                                <Button
                                    className='bg-danger'
                                    onClick={() => this.setState({showModalPublish: false})}
                                >
                                    Kiểm tra lại
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { categoryPost, subCategory } = state.getCategory;
    const { dataUser } = state.getUserData;
    return { categoryPost, subCategory, dataUser };
}

export default connect(mapStateToProps, {getCategory, getSubCategory, changePoint, updateCountMission, showModalEat, updateCountNotify})(withRouter(PostPublish));