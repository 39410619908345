import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListGiftDayApi = () =>{
    let url = `${URI_PATH}app/gift/day/getListGiftDay`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const openGiftApi = (giftdayId) =>{
    let url = `${URI_PATH}app/gift/day/openGift`;
    let body = {
        GiftdayId: giftdayId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkOpenGiftApi = (giftdayId) =>{
    let url = `${URI_PATH}app/gift/day/checkOpenGift`;
    let body = {
        GiftdayId: giftdayId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}