import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListChatAllApi = () =>{
    let url = `${URI_PATH}app/chat/chatAll/getListChatAll`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sendChatAllApi = (message, isImage = '', replyChatId = '') =>{
    let url = `${URI_PATH}app/chat/chatAll/saveChatAll`;
    let body = {
        Message: message,
        IsImage: isImage,
        ReplyChatId: replyChatId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}