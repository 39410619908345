const INITAL_STATE = {
    voice: {}
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_VOICE':
            return { ...state, voice: action.voice };
        default:
            return state;
    }
};