import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListPostApi = (num, categoryId, subCategoryId, searchUser, directPost) =>{
    let url = `${URI_PATH}app/post/vote/getListPost`;
    let body = {
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        SearchUser: searchUser,
        Num: num,
        DirectPost: directPost
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getVoteRemainApi = () =>{
    let url = `${URI_PATH}app/post/vote/getVoteRemain`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionVoteApi =(postqueueId, isVote) =>{
    let url = `${URI_PATH}app/post/vote/actionVote`;
    let body = {
        PostqueueId: postqueueId,
        IsVote: isVote
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}