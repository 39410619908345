import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListTargetApi = () =>{
    let url = `${URI_PATH}app/menu/target/getListTarget`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListTargetLobbyApi = () =>{
    let url = `${URI_PATH}app/menu/target/getListTargetLobby`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}