import React from 'react';

const ButtonStatus = ({item}) =>{
    if (item.Status == 1 || +item.LogTypeId > 1) {
        return (
            <div className='button-money-aff bg-success border-white' style={{ width: '20vh' }}>
                <p className='text-large bold mgb-0'>Thành công</p>
            </div>
        )
    }
    else if (item.Status == 2) {
        return (
            <div className='button-money-aff bg-danger border-white' style={{ width: '20vh' }}>
                <p className='text-large bold mgb-0'>Thất bại</p>
            </div>
        )
    }
    return (
        <div className='button-money-aff bg-warning border-white' style={{ width: '20vh' }}>
            <p className='text-large bold mgb-0'>Đang xử lý</p>
        </div>
    )
}

export default ButtonStatus;