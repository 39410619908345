import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataLevelApi = () =>{
    let url = `${URI_PATH}app/club/level/getDataLevel`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const contributeApi = () =>{
    let url = `${URI_PATH}app/club/level/contribute`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListContributeApi = (pageId) =>{
    let url = `${URI_PATH}app/club/level/getListContribute`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}