import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {Button, Modal} from 'antd';
import {getAvatarFb} from '../../utils/common';
import Loading from '../../components/Loading';
import {getListInviteApi, receiveApi} from '../../api/club/feast';
import { showNotification, showNotificationBonus, showNotificationProperty } from '../../utils/notification';
import {MESSAGE, PROP, URI_PATH} from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';
import {showModalEat} from '../../actions/home/modalEat';

class ListInviteFeast extends Component{
    constructor(props){
        super(props);
        this.state={
            listInvite: [],
            endList: false,
            feastData: {},
            achievementBonus: 0
        }
        this.page = 1;
    }

    getListInvite = () =>{
        getListInviteApi(this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listInvite: [...this.state.listInvite, ...res.listInvite],
                    endList: res.listInvite.length == 0
                })
            }
            else{
                this.setState({endList: true})
                showNotification(res.message, res.code);
            }
        }).catch(e =>{
            this.setState({endList: true});
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    loadMore = () =>{
        this.page += 1;
        this.getListInvite();
    }

    receive = item =>{
        let {listInvite, isPress} = this.state;
        let clubfeastId = item.ClubfeastId;
        if(!isPress){
            this.setState({isPress: clubfeastId});
            let idNotification = '#notificationShowModalReceive' + item.ClubfeastId;
            receiveApi(clubfeastId).then(res =>{
                if(res.code == 1){
                    if(res.isReceive == 1){
                        showNotificationProperty(res.property);
                        showNotificationBonus(res.achievement, 1, 4);
                        this.props.changePoint([null, null, null , res.achievement]);
                    }
                    this.setState({
                        showModal: true,
                        isPress: false,
                        feastData: item,
                        achievementBonus: res.achievement,
                        listInvite: listInvite.map(el => {
                            if(el.ClubfeastId == clubfeastId) return Object.assign({}, el, {
                                IsReceive: 2
                            })
                            return el;
                        })
                    });
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            });
        }
    }

    renderModalReceive = () =>{
        let {feastData, achievementBonus} = this.state;
        let dataUser = this.props.dataUser;
        return(
            <div className='item-center'>
                <p className='text-large'>{feastData.FullName} đã mời bạn món này cùng lời nhắn:</p>
                <p className='text-large mgt'>{feastData.Message ? feastData.Message : 'Chúc bạn ngon miệng'}</p>
                <div className='flex-row mgt-3 item-center'>
                    <div className='flex-column flex-1 item-center'>
                        <img src={getAvatarFb(feastData.FacebookId)} className='avatar-guest-large' />
                        <p className='text-large bold mgt'>VIP {feastData.UserVip}</p>
                    </div>
                    <div className='flex-column flex-1 item-center'>
                        <img className='item-img' alt='Quà tiệc' src={`${URI_PATH}${feastData.FoodImage}`}/>
                    </div>
                    <div className='flex-column flex-1 item-center'>
                        <img className='item-img' alt='Quà tiệc' src={`${URI_PATH}${feastData.FoodImage}`} style={{transform: 'scaleX(-1)'}}/>
                    </div>
                    <div className='flex-column flex-1 item-center'>
                        <img src={getAvatarFb(dataUser.FacebookId)} className='avatar-guest-large' />
                        <p className='text-large bold mgt'>VIP {dataUser.UserVip}</p>
                    </div>
                </div>
                <p className='text-large mgt-2'>Bạn nhận được thưởng:</p>
                <p className='text-large mgt-2'>{achievementBonus} điểm thành tựu</p>
                <img src={require('../../assets/image/chef.png')} className='club-fest-chef-icon' />
            </div>
        )
    }

    componentDidMount(){
        this.getListInvite();
    }

    render(){
        let {endList, listInvite, isPress, showModal} = this.state;
        return(
            <div>
                <div className='flex-column item-center'>
                    <p className='bold text-title-gift mg'>Danh sách lời mời</p>
                </div>
                {listInvite.length > 0 ?
                    <div>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small/>}
                            threshold={100}
                            className='row'
                        >
                            {listInvite.map((item, key) =>
                                <div className='col-md-3 col-sm-4 col-xs-6 item-block item-center' key={key}>
                                    <div className='flex-column mg item-center'>
                                        <img 
                                            src={getAvatarFb(item.FacebookId)} 
                                            className='avatar-guest'
                                            key={item.UserId} 
                                        />
                                        <p className='mgt'>{item.FullName}</p>
                                        <p className='text-small silver'>{item.InviteDateTime}</p>
                                        <img className='item-img' alt='Quà tiệc' src={`${URI_PATH}${item.FoodImage}`}/>
                                        <div className='mgt' id={'notificationShowModalReceive' + item.ClubfeastId} />
                                        <Button
                                            className={item.IsReceive == 1 ? 'bg-success' : 'bg-primary'}
                                            loading={isPress == item.ClubfeastId}
                                            onClick={() => this.receive(item)}
                                        >
                                            Xem chi tiết
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                        <Modal
                            {...PROP.MODAL}
                            visible={showModal}
                            onCancel={() => this.setState({showModal: false})}
                            centered
                        >
                            {this.renderModalReceive()}
                        </Modal>
                    </div>
                :
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large mgt'>Không có lời mời gần đây</p>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, showModalEat})(ListInviteFeast);