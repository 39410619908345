import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListRankApi = (rankAchievement = '', birthAchievement = '') =>{
    let url = `${URI_PATH}app/brand/top/getListRank`;
    let body = {
        RankAchievement: rankAchievement,
        BirthAchievement: birthAchievement
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getTopRankApi = (Type, PageId) =>{
    let url = `${URI_PATH}app/brand/top/getTopRank`;
    let body = {
        Type,
        PageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getLikeRemainApi = () =>{
    let url = `${URI_PATH}app/brand/top/getLikeRemain`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListBidApi = () =>{
    let url = `${URI_PATH}app/brand/top/getListBid`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const likeApi = (userId) =>{
    let url = `${URI_PATH}app/brand/top/like`;
    let body = {
        UserIdLike: userId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getLikeTimeRemainApi = (userId)=>{
    let url = `${URI_PATH}app/brand/top/getTimeLikeRemain`;
    let body = {
        UserIdLike: userId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const bidApi = BidValue =>{
    let url = `${URI_PATH}app/brand/top/bid`;
    let body = {
        BidValue
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getTimeResetApi = () =>{
    let url = `${URI_PATH}app/brand/top/getTimeReset`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}