import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionApi = () =>{
    let url = `${URI_PATH}app/question/lobby/action/getQuestion`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getQuestionGuestApi = () =>{
    let url = `${URI_PATH}app/question/lobby/action/getQuestionGuest`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerApi = (QuestionLobbyId, Answer) =>{
    let url = `${URI_PATH}app/question/lobby/action/saveAnswer`;
    let body = {
        QuestionLobbyId,
        Answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerGuestApi = (QuestionLobbyId, Answer) =>{
    let url = `${URI_PATH}app/question/lobby/action/saveAnswerGuest`;
    let body = {
        QuestionLobbyId,
        Answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}