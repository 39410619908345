import React from 'react';

export default props =>{
    return(
        <div className='flex item-center'>
            <div className={'ready-fight-container '+ (props.isActive ? 'bg-success' : 'bg-placeholder')}>
                <p className='mgb-0'>{props.title}</p>
            </div>
        </div>
    )
}