import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListStudentApi = (PageId, SearchText) =>{
    let url = `${URI_PATH}app/admin/study/support/getListStudent`;
    let body = {
        PageId,
        SearchText
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleSupportStudentApi  = (ListManage, UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/toggleSupportStudent`;
    let body = {
        ListManage,
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleVirtualStudentApi  = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/toggleVirtualStudent`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleManageStudentApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/toogleManageStudent`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListManageApi = () =>{
    let url = `${URI_PATH}app/admin/study/support/getListManage`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListStudentSupportApi  = (PageId, SearchText, Level, StudyStudent, TimeSupport, IsFilterOnline) =>{
    let url = `${URI_PATH}app/admin/study/support/getListStudentSupport`;
    let body = {
        PageId,
        SearchText,
        Level,
        StudyStudent,
        TimeSupport,
        IsFilterOnline: IsFilterOnline ? 2 : 0
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateSupportApi = (Content, IsFavorite, TimeAlert, RepeatType, UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/updateSupport`;
    let body = {
        Content,
        IsFavorite,
        TimeAlert,
        RepeatType,
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListSupportFromStudentApi = (PageId, Order, UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/getListSupportFromStudent`;
    let body = {
        PageId,
        UserId,
        Order
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleFavoriteSupportApi = (StudySupportId) =>{
    let url = `${URI_PATH}app/admin/study/support/toggleFavoriteSupport`;
    let body = {
        StudySupportId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateAlertSupportApi = (TimeAlert, RepeatType, StudySupportId) =>{
    let url = `${URI_PATH}app/admin/study/support/updateAlertSupport`;
    let body = {
        TimeAlert,
        RepeatType,
        StudySupportId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleSecureSupportApi = (StudySupportId) =>{
    let url = `${URI_PATH}app/admin/study/support/toggleSecureSupport`;
    let body = {
        StudySupportId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleAlertSupportApi = (StudySupportId) =>{
    let url = `${URI_PATH}app/admin/study/support/toggleAlertSupport`;
    let body = {
        StudySupportId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkManageSupportApi = () =>{
    let url = `${URI_PATH}app/admin/study/support/checkManageSupport`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeUrlSupportApi = (FacebookUrl, ZaloUrl, YoutubeUrl, TiktokUrl, StudySupportUserId) =>{
    let url = `${URI_PATH}app/admin/study/support/changeUrlSupport`;
    let body = {
        FacebookUrl,
        ZaloUrl,
        YoutubeUrl,
        TiktokUrl,
        StudySupportUserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeLevelSupportApi = (Level, StudySupportUserId) =>{
    let url = `${URI_PATH}app/admin/study/support/changeLevelSupport`;
    let body = {
        Level,
        StudySupportUserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addUserSupportByManageApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/addUserSupportByManage`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListRankSupportApi = () =>{
    let url = `${URI_PATH}app/admin/study/support/getListRankSupport`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleCheckedUser = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/support/toggleCheckedUser`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getCountManageSupportApi = () =>{
    let url = `${URI_PATH}app/admin/study/support/getCountManageSupport`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getCountAllApi = () =>{
    let url = `${URI_PATH}app/admin/study/support/getCountAll`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}