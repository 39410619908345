import React from 'react';
import { URI_PATH } from '../../utils/constants';

const Youtube = () => (
    <video width="100%" controls style={{borderRadius: '1vh'}}>
        <source src={`${URI_PATH}assets/retro.mp4#t=0.001`} type="video/mp4" />
    </video>
)

export default () => (
    <>
        {window.screen.width >= 768 ?
            <div className='row align-center mgb'>
                <div className='col-md-6 col-sm-6 col-xs-4 flex logo-resposive'>
                    <Youtube />
                </div>
                <div className='col-md-6 col-sm-6 col-xs-8 flex flex-column item-center'>
                    <h2 className='title-zcity'>HỌC VIỆN Z ONLINE</h2>
                    <p>biến Tương lai của bạn trở nên đắt giá!</p>
                    <div className='row flex item-center mgt'>
                        <a href='https://www.facebook.com/Gemslight.Nguyen' target='_blank'>
                            <img
                                src={require('../../assets/image/avatar-principal.jpg')}
                                className='avatar-guest-large'
                            />
                        </a>
                        <div style={{ marginLeft: '2vh' }}>
                            <p className='bold'>Sáng lập & Đào tạo</p>
                            <p className='bold'>Thầy Nguyễn Minh Ngọc</p>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div style={{marginTop: '1vh'}}>
                <Youtube />
            </div>
            
        }
    </>
)