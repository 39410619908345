import React, { Component } from 'react';
import { Modal, Button, Switch, Radio } from 'antd';
import { COLOR, MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { deleteDocumentLinkApi, getDataSubjectApi, getDocumentLinkApi, updateDocumentLinkApi, updateSubjectApi } from '../../api/admin/study/subject';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Icon from 'react-fontawesome';
const ButtonGroup = Button.Group;

class Subject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSubject: {},
            inputSubject: '',
            inputInfo: '',
            isPress: false,
            selectTab: 1,
            showModal: false,
            editorState: EditorState.createEmpty(),
            title: '',
            listDocumentLink: [],
            studyDocumentLinkId: '',
            isZticket: false,
            baseBalance: 0,
            stepBalance: 0
        }
    }

    getDataSubject = () => {
        getDataSubjectApi().then(res => {
            if (res.code == 1) {
                const dataSubject = res.data;
                this.setState({
                    isLoading: false,
                    dataSubject,
                    inputSubject: dataSubject.Subject,
                    inputInfo: dataSubject.Info,
                    isZticket: dataSubject.IsZticket == '1',
                    baseBalance: parseFloat(dataSubject.BaseBalance),
                    stepBalance: parseFloat(dataSubject.StepBalance),
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    save = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            let { inputInfo, inputSubject, dataSubject, isZticket, baseBalance, stepBalance } = this.state;
            if (inputInfo == '<p><br></p>') inputInfo = '';
            updateSubjectApi(
                inputSubject,
                inputInfo,
                isZticket ? '1' : '0',
                baseBalance,
                stepBalance,
                dataSubject.StudySubjectId
            ).then(res => {
                showNotification(res.message, res.code);
                this.setState({ isPress: false });
            }).catch(e => {
                this.setState({ isPress: false })
                showNotification(MESSAGE.ERROR, 0)
            });
        }
    }

    componentDidMount() {
        this.getDataSubject();
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    renderSubject = () => {
        const { inputSubject, inputInfo, isPress, isLoading, isZticket, baseBalance, stepBalance, dataSubject } = this.state;
        return (
            <>
                {isLoading ? <Loading small />
                    :
                    <>
                        <p>Tên môn học:</p>
                        <input
                            className='form-control'
                            placeholder='Nhập tên môn học'
                            value={inputSubject}
                            onChange={e => this.setState({ inputSubject: e.target.value })}
                        />
                        <p className='mgt'>Gia hạn bài học</p>
                            <div className='box-primary'>
                                <div className='row'>
                                    <div className='col-md-1 col-sm-1 col-xs-12 flex align-center'>
                                        <Radio
                                            checked={!isZticket}
                                            onClick={() => {
                                                this.setState({
                                                    isZticket: false,
                                                    baseBalance: dataSubject.IsZticket == '0' ? dataSubject.BaseBalance : 0,
                                                    stepBalance: dataSubject.IsZticket == '0' ? dataSubject.StepBalance : 0,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-5 col-sm-4 col-xs-12 flex align-center'>
                                        <p className='mgb-0'>Bắt đầu</p>
                                        <div className='mgl mgr'>
                                            <input
                                                className='form-control'
                                                value={!isZticket ? Number(baseBalance) : ''}
                                                onChange={({target}) => this.setState({baseBalance: target.value})}
                                                disabled={isZticket}
                                            />
                                        </div>
                                        <p className='mgb-0'>$[z]</p>
                                    </div>
                                    <div className='col-md-5 col-sm-4 col-xs-12 flex align-center'>
                                        <p className='mgb-0'>Bước tăng</p>
                                        <div className='mgl mgr'>
                                            <input
                                                className='form-control'
                                                value={!isZticket ? Number(stepBalance) : ''}
                                                onChange={({target}) => this.setState({stepBalance: target.value})}
                                                disabled={isZticket}
                                            />
                                        </div>
                                        <p className='mgb-0'>$[z]</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-1 col-sm-1 col-xs-12 flex align-center'>
                                        <Radio
                                            checked={isZticket}
                                            onClick={() => {
                                                this.setState({
                                                    isZticket: true,
                                                    baseBalance: dataSubject.IsZticket == '1' ? dataSubject.BaseBalance : 0,
                                                    stepBalance: dataSubject.IsZticket == '1' ? dataSubject.StepBalance : 0,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-5 col-sm-4 col-xs-12 flex align-center'>
                                        <p className='mgb-0'>Bắt đầu</p>
                                        <div className='mgl mgr'>
                                            <input
                                                className='form-control'
                                                value={isZticket ? baseBalance : ''}
                                                onChange={({target}) => this.setState({baseBalance: target.value?.replace(/,/, '.')})}
                                                disabled={!isZticket}
                                            />
                                        </div>
                                        <p className='mgb-0'>Gold[z]</p>
                                    </div>
                                    <div className='col-md-5 col-sm-4 col-xs-12 flex align-center'>
                                        <p className='mgb-0'>Bước tăng</p>
                                        <div className='mgl mgr'>
                                            <input
                                                className='form-control'
                                                value={isZticket ? stepBalance : ''}
                                                onChange={({target}) => this.setState({stepBalance: target.value?.replace(/,/, '.')})}
                                                disabled={!isZticket}
                                            />
                                        </div>
                                        <p className='mgb-0'>Gold[z]</p>
                                    </div>
                                </div>
                            </div>
                        <div>
                        </div>
                        <p className='mgt'>Thông tin chi tiết:</p>
                        <div
                            style={{ cursor: 'text' }}
                            onClick={() => this.inputInfoRef.focus()}
                        >
                            <ReactQuill
                                ref={e => this.inputInfoRef = e}
                                value={inputInfo}
                                onChange={inputInfo => this.setState({ inputInfo })}
                                modules={{ toolbar: [['bold', 'italic', 'underline']] }}
                                placeholder='Nhập nội dung'
                            />
                        </div>
                        <div className='item-center mgt-3'>
                            <Button
                                className='bg-success'
                                onClick={this.save}
                                loading={isPress}
                            >
                                Cập nhật
                            </Button>
                        </div>
                    </>
                }
            </>
        )
    }

    copyLink = (link) => {
        var textArea;
        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
        function selectText() {
            var range, selection;
            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }
        function copyTo() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        createTextArea(link);
        selectText();
        copyTo();
        showNotification('Sao chép link thành công', 1);
    }

    showModalAddDocument = () => {
        this.setState({
            showModal: true,
            title: '',
            editorState: EditorState.createEmpty(),
            studyDocumentLinkId: ''
        })
    }

    deleteDocumentLink = (studyDocumentLinkId) => {
        if (window.confirm('Xác nhận xóa tài liệu này')) {
            deleteDocumentLinkApi(studyDocumentLinkId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.getDocumentLink()
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0)
            });
        }
    }

    renderDocument = () => {
        const { listDocumentLink } = this.state;
        return (
            <>
                <div className='item-center'>
                    <Button
                        className='bg-success'
                        onClick={this.showModalAddDocument}
                    >
                        Thêm tài liệu
                    </Button>
                </div>
                {listDocumentLink.map((item, key) => {
                    const link = `https://zcity.vn/baigiang${item.StudyDocumentLinkId}`
                    return (
                        <div className='row box-primary mgt' key={key}>
                            <div className='col-md-4 col-sm-4'>
                                <p>{item.Title}</p>
                            </div>
                            <div className='col-md-4 col-sm-4 flex'>
                                <p>Link: <u>{link}</u></p>
                                <Icon
                                    name='files-o'
                                    className='pointer mgl'
                                    style={{ fontSize: '2vh', margin: '0.8vh' }}
                                    onClick={() => this.copyLink(link)}
                                />
                            </div>
                            <div className='col-md-4 col-sm-4 flex align-center' style={{ justifyContent: 'flex-end' }}>
                                <div className='flex-row'>
                                    <img
                                        src={require('../../assets/image/pen.png')}
                                        style={{ width: '3vh' }}
                                        className='pointer mgr'
                                        onClick={() => {
                                            this.setState({
                                                showModal: true,
                                                title: item.Title,
                                                studyDocumentLinkId: item.StudyDocumentLinkId
                                            })
                                            const html = item.Content
                                            const contentBlock = htmlToDraft(html);
                                            if (contentBlock) {
                                                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                                                const editorState = EditorState.createWithContent(contentState);
                                                this.setState({ editorState })
                                            }
                                        }}
                                    />
                                    <img
                                        src={require('../../assets/image/delete-red.png')}
                                        style={{ width: '3vh' }}
                                        className='pointer mgl'
                                        onClick={() => this.deleteDocumentLink(item.StudyDocumentLinkId)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    updateDocument = () => {
        let content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        //fix center image
        content = content.replace(/<div style="text-align:none;">/g, '<div style="text-align:center;">');
        updateDocumentLinkApi(this.state.title, content, this.state.studyDocumentLinkId).then(res => {
            showNotification(res.message, res.code)
            if (res.code == 1) {
                this.setState({ showModal: false })
                this.getDocumentLink()
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getDocumentLink = () => {
        getDocumentLinkApi().then(res => {
            if (res.code == 1) {
                this.setState({ listDocumentLink: res.data })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    render() {
        const { selectTab, showModal, editorState, title } = this.state;
        return (
            <>
                <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                    <Button
                        onClick={() => this.setState({ selectTab: 1 })}
                        className={this.buttonGroupClass(1)}
                    >
                        Thông tin môn học
                    </Button>
                    <Button
                        onClick={() => {
                            this.setState({ selectTab: 2 })
                            this.getDocumentLink()
                        }}
                        className={this.buttonGroupClass(2)}
                    >
                        Tài liệu đào tạo
                    </Button>
                </ButtonGroup>
                {selectTab == 1 ? this.renderSubject() : this.renderDocument()}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                    width={'90%'}
                >
                    <input
                        className='form-control mgt'
                        placeholder='Tiêu đề'
                        onChange={e => this.setState({ title: e.target.value })}
                        value={title}
                    />
                    <div className='box-primary mgt-3'>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="editor-admin"
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    </div>
                    <div className='item-center mgt-2'>
                        <Button
                            className='bg-success'
                            onClick={this.updateDocument}
                        >
                            Xác nhận
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Subject;