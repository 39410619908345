import React, { Component } from 'react';
import { MESSAGE, PROP} from '../../utils/constants';
import {showNotification} from '../../utils/notification';
import Loading from '../../components/Loading';
import gift from '../../assets/lottie/gift.json';
import {Modal} from 'antd';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import { getListQuestionApi, checkAnswerApi } from '../../api/present';
import Lottie from 'react-lottie';
import {showModalEat} from '../../actions/home/modalEat';
import {connect} from 'react-redux';
import { speakEng } from '../../utils/common';

class QuestionGift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionList: [],
            numberCorrect: 0,
            textTime: '00:30',
            selectAnswerKey: '',
            resultAnswer: false,
            isAnswer: false,
            showModal: false
        }
        this.timeDiff = 99999;
        this.numberQuestion = 0;
        this.countDown = 99999;
        this.correctAnswer = '';
        this.questionInterval = '';
    }

    getListQuestion = () =>{
        getListQuestionApi().then(res =>{
            this.numberQuestion = res.numberQuestion;
            this.timeDiff = res.timeDiff;
            this.countDown = res.countDown;
            this.setState({
                isLoading: false,
                questionList: res.questionList,
                resultAnswer: false,
                selectAnswerKey: '',
                isAnswer: false,
                showModal: true
            });
            this.questionInterval = setInterval(() =>{
                this.timeDiff -= 1;
                if(this.timeDiff < 10) this.setState({textTime: '00:0'+this.timeDiff});
                else this.setState({textTime: '00:'+this.timeDiff});
                if(this.timeDiff <= 0){
                    let {isAnswer, resultAnswer} = this.state;
                    if(isAnswer == true && resultAnswer == 1){
                        this.timeDiff = 30;
                        this.numberQuestion += 1;
                        if(this.numberQuestion > 4){
                            clearInterval(this.questionInterval);
                            this.props.onSuccess();
                        }
                        this.correctAnswer = '';
                        this.setState({
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isAnswer: false,
                            textTime: '00:30'
                        });
                    }
                    else {
                        this.setState({
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isAnswer: false,
                            showModal: false,
                            textTime: '00:30'
                        });
                        clearInterval(this.questionInterval);
                        setTimeout(() => {
                            this.props.onClose();
                        }, 200);
                    }
                }
            }, 1000);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    saveAnswer = (answer, keySelect, questionId) =>{
        if(!this.state.isAnswer){
            this.setState({selectAnswerKey: keySelect, isAnswer: true});
            let idNotification = '#notificationQuestionCheck';
            checkAnswerApi(questionId, answer).then(res =>{
                if(res.code == 1 || res.code == 0){
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    this.setState({
                        resultAnswer: res.code,
                    });
                    setTimeout(() =>{
                        speakEng(res.answer)
                    }, 0)
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.correctAnswer = '';
                    this.setState({resultAnswer: false, isAnswer: false, selectAnswerKey: ''});
                }
            }).catch(error =>{
                this.setState({resultAnswer: false, isAnswer: false, selectAnswerKey: ''});
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    hideModal = () =>{
        this.setState({
            showModal: false,
            resultAnswer: false,
            selectAnswerKey: '',
            isAnswer: false,
            textTime: '00:30'
        });
        clearInterval(this.questionInterval);
        setTimeout(() => {
            this.props.onClose();
        }, 200);
    }

    componentWillUnmount(){
        clearInterval(this.questionInterval);
    }

    renderContent = () =>{
        let {questionList, selectAnswerKey, resultAnswer, textTime} = this.state;
        let {showOpenGift, children} = this.props;
        if(showOpenGift){
            return children;
        }
        else if(this.timeDiff >= 0){
            return(
                <div>
                    <Lottie options={{loop: false, autoplay: true, animationData: gift}}
                        height='16vh'
                        width='16vh'
                    />
                    {questionList.map((item, index) =>(
                        <div key={index}>
                            {index + 1 == this.numberQuestion &&(
                                <div className='animated fadeIn fast'>
                                    <p className='text-large text-center mgb'>Vượt qua 4 câu hỏi để mở quà</p>
                                    <p className='title-question'>Câu hỏi {this.numberQuestion}: {item.QuestionTitle}</p>
                                    <div className='count-down-question-container'>
                                        <p className={'count-down-question ' + (this.timeDiff <= 10 ? 'danger' : 'success')}>{textTime}</p>
                                    </div>
                                    {item.answers.map((value, key) =>{
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => this.saveAnswer(value, key, item.QuestionId)}
                                            >
                                                <TextAnswer 
                                                    answer={value}
                                                    keySelect={key}
                                                    selectAnswerKey={selectAnswerKey}
                                                    correctAnswer={this.correctAnswer}
                                                />
                                            </div>
                                        )
                                    })}
                                    {resultAnswer !== false && (
                                        <ResultMessage resultAnswer={resultAnswer} />
                                    )}
                                    <div className='notificationQuestionCheck'></div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )
        }
        return(
            <Loading small />
        )
    }

    componentDidMount(){
        this.getListQuestion();
    }

    render() {
        return(
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModal}
                onCancel={this.hideModal}
                afterClose={this.hideModal}
                destroyOnClose={true}
                width='750px'
                centered
            >
                {this.renderContent()}
            </Modal>
        )
    }
}

export default connect(null, {showModalEat})(QuestionGift);