import React, { Component } from 'react';
import { Button } from 'antd';
import SupportStudent from '../../components/admin/study/SupportStudent';
import SupportListStudent from '../../components/admin/study/SupportListStudent';
import SupportWait from '../../components/admin/study/SupportWait';
import { getCountAllApi, getCountManageSupportApi } from '../../api/admin/study/support';
const ButtonGroup = Button.Group;

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTab: 1,
            listCountSupport: {},
            countAll: ''
        }
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    renderTab = () =>{
        switch (this.state.selectTab) {
            case 1:
                return <SupportWait />
            case 2:
                return <SupportStudent />
            case 3:
                return <SupportListStudent />
        }
    }

    getCountManageSupport = () =>{
        getCountManageSupportApi().then(res =>{
            if(res.code == 1) this.setState({listCountSupport: res.data})
        })
    }

    componentDidMount() {
        this.getCountManageSupport();
    }

    render() {
        const { listCountSupport } = this.state;
        const countAll = parseInt(listCountSupport.WaitCount) + parseInt(listCountSupport.CurrentCount)
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({selectTab: 1})} className={this.buttonGroupClass(1)}>Chờ chăm sóc{(listCountSupport.WaitCount && `(${listCountSupport.WaitCount})`)}</Button>
                    <Button onClick={() => this.setState({selectTab: 2})} className={this.buttonGroupClass(2)}>Đang chăm sóc{(listCountSupport.CurrentCount && `(${listCountSupport.CurrentCount})`)}</Button>
                    <Button onClick={() => this.setState({selectTab: 3})} className={this.buttonGroupClass(3)}>Danh sách HV{(countAll > 0 && `(${countAll})`)}</Button>
                </ButtonGroup>
                {this.renderTab()}
            </>
        )
    }
}

export default Support;