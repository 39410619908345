import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionEventApi = () =>{
    let url = `${URI_PATH}app/question/event/getQuestionEvent`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerApi = (questionId, questionNum, languageId, answer) =>{
    let url = `${URI_PATH}app/question/event/saveAnswer`;
    let body = {
        QuestionId: questionId,
        QuestionNum: questionNum,
        LanguageId: languageId,
        Answer: answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListUserQuestionApi = (pageId = 1) =>{
    let url = `${URI_PATH}app/question/event/getListUserQuestion`;
    let body = {
        PageId: pageId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}