import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListAskQuestionApi = (Num, CategoryId, SubCategoryId, SearchUser, DirectAskQuestion) =>{
    let url = `${URI_PATH}app/askquestion/vote/getListAskQuestion`;
    let body = {
        CategoryId,
        SubCategoryId,
        SearchUser,
        Num,
        DirectAskQuestion
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getVoteRemainApi = () =>{
    let url = `${URI_PATH}app/askquestion/vote/getVoteRemain`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionVoteApi = (AskQuestionQueueId, IsVote) =>{
    let url = `${URI_PATH}app/askquestion/vote/actionVote`;
    let body = {
        AskQuestionQueueId,
        IsVote
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}