import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserData } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import { getAvatarFb } from '../../utils/common';
import { showHideWidgetNotification, getListNotification } from '../../actions/notification';
import InfiniteScroll from 'react-infinite-scroller';
import { showNotification, showNotificationFight } from '../../utils/notification';
import { getListChatPeople, showHideChat } from '../../actions/chat';
import TooltipUser from '../TooltipUser';
import { Modal } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import LessonChat from '../study/componentChildren/LessonChat';
import { getListLessonDoingOrPassApi } from '../../api/study/lesson';
import LessonShow from '../study/componentChildren/LessonShow';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoadingLesson: false,
            listLesson: []
        }
    }

    redirect = (notificationType, content, facebookId) => {
        let contentArr;
        let url;
        switch (notificationType) {
            case '1': // Kết nạp affiliate,
                url = '/charge-affiliate';
                break;
            case '2': // Thành viên xin gia nhập club
                url = '/menu/club-member';
                break;
            case '3': // Mời thách đấu trắc nghiệm
                content = JSON.parse(content);
                showNotificationFight(content, this.props.history);
                break;
            case '4': // Mua/bán Gold[z] chợ đen
                url = '/menu/charge-blackmarket';
                break;
            case '5': // Bình chọn review sách
                url = '/menu/book-review';
                break;
            case '6': // Gửi tin nhắn message
                contentArr = content.split('|');
                // url = '/home/guest?id=' + contentArr[0];
                this.props.showHideWidgetNotification(false);
                this.props.getListChatPeople(contentArr[0]);
                this.props.showHideChat(true, false, {
                    UserId: contentArr[0],
                    FacebookId: facebookId,
                    FullName: contentArr[1],
                });
                return;
            case '7': // Mời ăn tiệc Club
                url = '/menu/club-feast';
                break;
            case '8': // Huỷ ghi danh khoá học
                url = 'menu/mine';
                break;
            case '9': // Yêu thích thương hiệu
                contentArr = content.split('|');
                url = '/home/guest?id=' + contentArr[0];
                break;
            case '10': // Reply comment newfeed
                contentArr = content.split('|');
                url = `/new-feed?id=${contentArr[0]}&comment=${contentArr[1]}`;
                break;
            case '11': // Comment newfeed
                contentArr = content.split('|');
                url = `/new-feed?id=${contentArr[0]}&comment=${contentArr[1]}`;
                break;
            case '12': //donate tâm trạng ở newfeed
                url = `/new-feed?id=${content}`;
                break;
            case '13': //bình luận bài viết, bình chọn bài viết, donate bài viết
                url = '/menu/post-search?id=' + content;
                break;
            case '14': // Cộng tiền affiliate khi thành viên nạp tiền
                url = '/charge-affiliate';
                break;
            case '15': //Mua vật dụng market
                url = '/menu/charge-asset';
                break;
            case '16': //Mua đồ ăn market
                url = '/menu/charge-food';
                break;
            case '17': //Tặng quà gift
                url = '/menu/gift-offer';
                break;
            case '18': //bình luận video, bình chọn video, donate video
                url = '/menu/video-search?id=' + content;
                break;
            case '19': // Chuyển tiền cho nhau
                contentArr = content.split('|');
                url = '/home/guest?id=' + contentArr[0];
                break;
            case '20': // Admin nạp tiền cho thành viên
                break;
            case '25': //bình luận câu hỏi, bình chọn câu hỏi, donate câu hỏi
                url = '/menu/ask-question-search?id=' + content;
            case '30': //bình luận bài học
                contentArr = content.split('|');
                this.showModalLesson(contentArr[3])
                this.props.showHideWidgetNotification(false);
                break;
        }
        if (url) {
            if (url.includes(window.location.pathname) && (window.location.pathname + window.location.search) != url) { // check đang cùng 1 router
                this.props.history.push('/blank');
                setTimeout(() => {
                    this.props.history.push(url);
                });
            }
            else this.props.history.push(url);
        }
    }

    loadMore = () => {
        this.props.getListNotification(this.props.page + 1)
    }

    renderNotification = () => {
        let { showWidgetNotification, listNotification, isLoading, endList } = this.props;
        if (showWidgetNotification) {
            if (isLoading) return (<Loading />);
            else if (listNotification.length == 0) {
                return (
                    <div className='notification-widget-container animated fadeInDown fastest'>
                        <div className='mgt-6'>
                            <HeaderLottie source={empty} disableLoop />
                            <p className='text-large text-center mgt'>Không có thông báo gần đây</p>
                        </div>
                    </div>
                )
            }
            return (
                <>
                    <div
                        className='notification-widget-container animated fadeInDown fastest'
                    >
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={10}
                            useWindow={false}
                        >
                            {listNotification.map((item, key) =>
                                <div
                                    key={key}
                                    className='notification-widget-list'
                                    onClick={() => this.redirect(item.NotificationType, item.Content, item.FacebookId)}
                                >
                                    <div className='flex-1 item-center'>
                                        <TooltipUser userInfo={item} />
                                    </div>
                                    <div className='flex-3 flex-column'>
                                        <p>{item.Message}</p>
                                        <p className='silver text-small'>{item.CrDateTime}</p>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                </>
            )
        }
        return null
    }

    showModalLesson = (studyStudentId) => {
        this.setState({
            showModal: true,
            isLoadingLesson: true,
            studyStudentId,
        })
        getListLessonDoingOrPassApi(2, studyStudentId, this.props.dataUser.UserId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLesson: res.lessons,
                    isLoadingLesson: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    render() {
        const {listLesson, showModal, isLoadingLesson } = this.state
            return (
                <>
                    {this.renderNotification()}
                    <Modal
                        {...PROP.MODAL}
                        visible={showModal}
                        onCancel={() => this.setState({ showModal: false })}
                        width='120vh'
                    >
                        <div className='mgt-2'>
                            <LessonShow
                                listLesson={listLesson}
                                isLoading={isLoadingLesson}
                                onRefresh={() => this.showModalLesson(this.state.studyStudentId)}
                                type={2}
                            />
                        </div>
                    </Modal>
                </>
            )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNotification, page, isLoading, endList, showWidgetNotification } = state.getListNotification;
    return { dataUser, listNotification, page, isLoading, endList, showWidgetNotification };
}

export default connect(mapStateToProps, { getUserData, showHideWidgetNotification, getListNotification, getListChatPeople, showHideChat })(withRouter(Main));