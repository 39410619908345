import React, { Component } from 'react';
import { connect } from 'react-redux';
import {WALLPAPER, KEY, COLOR} from '../../utils/constants';
import Cookies from 'js-cookie';
import {changeWallpaper} from '../../actions/config';
import Icon from 'react-fontawesome';

class ConfigWallpaper extends Component {
    constructor(props){
        super(props);
        this.state = {
            configWallpaper: Cookies.get(KEY.WALLPAPER) || 1,
        }
    }

    chooseWallpaper = (configWallpaper) =>{
        if(configWallpaper != this.state.configWallpaper){
            this.props.changeWallpaper(configWallpaper);
            this.setState({configWallpaper});
        }
    }

    render(){
        return(
            <div
                className='box-primary'
                style={{background: 'rgba(200, 200, 200, 0.6)', padding: '1vh 3vh'}}
            >
                {new Array(Object.keys(WALLPAPER).length).fill(undefined).map((item, key) =>
                    <div
                        key={key}
                        className='config-wallpaper-box'
                        style={{background: WALLPAPER[key + 1]}}
                        onClick={() => this.chooseWallpaper((key + 1).toString())}
                    >
                        {key + 1 == this.state.configWallpaper &&
                            <Icon name='check' className='check-wallpaper'/>
                        }
                    </div>
                )}
            </div>
        )
    }
}

export default connect(null, { changeWallpaper })(ConfigWallpaper);