import React, { Component } from 'react';
import {formatDecimal} from '../../utils/common';
import {URI_PATH, MESSAGE, TICKET, PROP} from '../../utils/constants';
import{ getItemInCart, updateItemInCart, toggleCartAction } from '../../actions/charge/sell';
import{ getUserItem } from '../../actions/home';
import { deleteFoodInCartApi, deleteAssetInCartApi, openSellAssetApi, openSellFoodApi } from '../../api/charge/sell';
import { connect } from 'react-redux';
import { Modal, Button, Switch } from 'antd';
import {showNotification} from '../../utils/notification';
import ListCart from './ListCart';
const ButtonGroup = Button.Group;

class ShowModalCart extends Component {
    constructor(props){
        super(props);
        this.isPress = false;
        this.isToggleCart = false;
    }

    deleteFoodInCart = (userfoodId) =>{
        if(!this.isPress){
            this.isPress = true;
            let idNotification = '#notificationDeleteItemInCart';
            deleteFoodInCartApi(userfoodId).then((res) => {
                this.isPress = false;
                if(res.code == 1){
                    this.props.updateItemInCart(1, res.listFoodInCart);
                    this.props.getUserItem(0);
                    showNotification(res.message, res.code, idNotification);
                }
                else showNotification(MESSAGE.ERROR, 0, idNotification);
            }).catch(error =>{
                this.isPress = false;
                showNotification(MESSAGE.ERROR, 0, idNotification);
            });
        }
    }

    deleteAssetInCart = (userassetId) =>{
        if(!this.isPress){
            this.isPress = true;
            let idNotification = '#notificationDeleteItemInCart';
            deleteAssetInCartApi(userassetId).then((res) => {
                this.isPress = false;
                if(res.code == 1){
                    this.props.updateItemInCart(2, res.listAssetInCart);
                    this.props.getUserItem(1);
                    showNotification(res.message, res.code, idNotification);
                }
                else showNotification(MESSAGE.ERROR, 0, idNotification);
            }).catch(error =>{
                this.isPress = false;
                showNotification(MESSAGE.ERROR, 0, idNotification);
            });
        }
    }

    buttonGroupClass = selectItem =>{
        if(selectItem === this.props.selectItem) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    selectItem = type =>{
        if(type != this.props.selectItem) this.props.getItemInCart(type);
    }

    toggleCart = value => {
        if(!this.isToggleCart){
            let isOpen = (value == true) ? 2 : 1;
            let idNotification = '#notificationToggleCart';
            let {selectCart, selectItem} = this.props;
            this.props.toggleCartAction(value, selectItem);
            if(selectCart == false){
                showNotification('Bạn chưa chọn cửa hàng', 0, idNotification);
                this.props.toggleCartAction(!value, selectItem);
                return;
            }
            this.isToggleCart = true;
            if(selectItem == 1){
                openSellFoodApi(selectCart.FoodcartId, isOpen).then(res => {
                    this.isToggleCart = false;
                    if(res.code == 1) showNotification(res.message, res.code, idNotification);
                    else{
                        this.props.toggleCartAction(!value, selectItem);
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch(e => {
                    this.isToggleCart = false;
                    this.props.toggleCartAction(!value, selectItem);
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
            else {
                openSellAssetApi(selectCart.FoodcartId, isOpen).then(res => {
                    this.isToggleCart = false;
                    if(res.code == 1) showNotification(res.message, res.code, idNotification);
                    else{
                        this.props.toggleCartAction(!value, selectItem);
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch(error => {
                    this.isToggleCart = false;
                    this.props.toggleCartAction(!value, selectItem);
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
        }
    };

    renderItemInCart = () =>{
        let {selectItem, onCartFood, onCartAsset} = this.props;
        return(
            <div>
                <ButtonGroup className='item-center' style={{display: 'flex', width: '100%'}}>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.selectItem(1)}>Giỏ ẩm thực</Button>
                    <Button className={this.buttonGroupClass(2)} onClick={() => this.selectItem(2)}>Giỏ đồ dùng</Button>
                </ButtonGroup>
                <div>
                    {selectItem == 1 ?
                        <div className='row flex-row mgt-3 item-center'>
                            {this.props.listFoodInCart.map((item, key) =>
                                <div key={key} className="col-md-2 col-sm-3 col-xs-4 item-block item-center">
                                    <img className='item-in-cart' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                    <p>{item.Amount}</p>
                                    <p>{item.FoodName}</p>
                                    <p>{item.Hour} giờ</p>
                                    <p>{formatDecimal(`${item.Balance} ${item.BalanceName}`)}</p>
                                    <img
                                        className='delete-image'
                                        alt='delete-food-in-cart'
                                        src={require('../../assets/image/delete.png')}
                                        onClick={() => this.deleteFoodInCart(item.UserfoodId)}
                                    />
                                </div>
                            )}
                        </div>
                    :
                        <div className='row flex-row mgt-3 item-center'>
                            {this.props.listAssetInCart.map((item, key) =>
                                <div key={key} className="col-md-2 col-sm-3 col-xs-4 item-block item-center">
                                    <img className='item-in-cart' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                                    <p>{item.Amount}</p>
                                    <p>{item.AssetName}</p>
                                    <p>{formatDecimal(`${item.Balance} ${item.BalanceName}`)}</p>
                                    <img
                                        className='delete-image'
                                        alt='delete-food-in-cart'
                                        src={require('../../assets/image/delete.png')}
                                        onClick={() => this.deleteAssetInCart(item.UserassetId)}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div id="notificationDeleteItemInCart"></div>
                <div className='row flex-row item-center mgt-2 mgb'>
                    <p className='text-center text-large' style={{marginRight: '2vh'}}>Mở bán:</p>
                    <Switch
                        checked={selectItem == 1 ? onCartFood : onCartAsset}
                        onChange={this.toggleCart}
                    />
                </div>
            </div>
        )
    }

    render(){
        return(
            <Modal
                {...PROP.MODAL}
                width={'90%'}
                visible={this.props.isVisible}
                onCancel={this.props.closeDisplay}
                centered
            >
                <div>
                    {this.renderItemInCart()}
                    <ListCart onRenewCart= {cartImage => this.props.onRenewCart(cartImage)} />
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const { listFoodInCart, listAssetInCart, selectItem, selectCart, onCartFood, onCartAsset } = state.getItemCartSell;
	const { dataUser } = state.getUserData;
    return { listFoodInCart, listAssetInCart, selectItem, selectCart, onCartFood, onCartAsset, dataUser };
}

export default connect(mapStateToProps, {getItemInCart, updateItemInCart, getUserItem, toggleCartAction })(ShowModalCart);