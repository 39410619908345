import React, { Component } from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {getListPostApi} from '../../api/post/mine';
import {URI_PATH, MESSAGE} from '../../utils/constants';
import {randomNumber} from '../../utils/common';
import {showNotification} from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import {getCategory, getSubCategory} from '../../actions/category/';
import { withRouter } from 'react-router'
import FsLightbox from 'fslightbox-react';
import TooltipUser from '../../components/TooltipUser';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import BarSelect from '../../components/frame/BarSelect';

class PostMine extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isEmpty: false,
            data: {},
            isPress: false,
            showPreviewImage: false,
            imagePreview: ''
        };
        this.num = 0;
        this.categoryId = '';
        this.subCategoryId = '';
    }

    getPost = () =>{
        let {num, categoryId, subCategoryId} = this;
        getListPostApi(num, categoryId, subCategoryId).then((res) => {
            if(res.code == 1){
                this.setState({
                    data: res.data,
                    isLoading: false,
                    isEmpty: false
                });
            }
            else this.setState({isEmpty: true, isLoading: false});
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMorePost = (type) =>{
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        let nextMax = parseInt(this.state.data.countPost);
        if(this.directPost > 0) this.directPost = '';
        switch(type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() =>{
            this.getPost();
        }, 200)
    }

    changeCategory = (value) =>{
        if(this.directPost > 0) this.directPost = '';
        this.num = 0;
        this.categoryId = value;
        if(!this.categoryId) this.subCategoryId = '';
        this.props.getSubCategory(this.categoryId);
        this.getPost();
    }

    renderNavigate = () =>(
        <BarSelect style={{width: '25vh'}}>
            <Icon
                name='caret-left'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMorePost('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{fontSize: '4vh', margin: '0vh 1vh'}}
                onClick={() => this.loadMorePost('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMorePost('next')}
            />
        </BarSelect>
    )

    componentDidMount(){
        this.getPost();
        if(this.props.categoryPost.length == 0) this.props.getCategory(1);
    }

    render(){
        let {isLoading, data, isEmpty, showPreviewImage, imagePreview} = this.state;
        let {categoryPost, subCategory} = this.props;
        let dataPost = data.dataPost;
        if(isLoading) return <Loading />;
        return(
            <div style={{paddingBottom: '8vh'}}>
                <div className='flex item-center'>
                    <p className='bold title-success'>
                        {this.props.countMission.PostVerifyCount > 0 ?
                            'Bài viết hôm nay được xét duyệt bạn sẽ vượt qua thử thách'
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div>
                <div className='pv-search-container row'>
                    <div className={'col-md-12 col-sm-12 col-xs-12'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryPost.map((item, key) =>(
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {isEmpty ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không có bài viết cần tìm</p>
                    </div>
                :
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        <div className='flex-row mgt-2'>
                            <div className='flex-row flex-3 align-center'>
                                <TooltipUser userInfo={dataPost} avatarClass='avatar-guest' key={dataPost.UserId}/>
                                <div className='pv-user-container'>
                                    <p className='bold'>{dataPost.FullName}</p>
                                    <p className='placeholder black'>{dataPost.CrDateTime}</p>
                                </div>
                            </div>
                            <div className='flex-row flex-2 align-center' style={{justifyContent: 'flex-end'}}>
                                {dataPost.Status == 1 ?
                                    <p className='pv-check-p bg-danger'>Không duyệt</p>
                                :
                                    <>
                                        {dataPost.PostStatusId > 0 ?
                                            <p className='pv-check-p bg-success'>Đã xét duyệt</p>
                                        :
                                            <p className='pv-check-p bg-warning'>Đang xét duyệt</p>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className='flex-column'>
                            <p className='text-large bold text-center'>{dataPost.CategoryName}</p>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <p className='text-large text-center bold mgt mgb'>{dataPost.PostTitle}</p>
                            <div className='flex-row flex-3 item-center mgt'>
                                <p className='pv-check-p mgb-2' style={{background: 'rgba(0,0,0,0.85)'}}>Tôi chia sẻ bài viết này vì: {dataPost.PostReason}</p>
                            </div>
                            <div>
                                {dataPost.PostImage &&
                                    <img
                                        src={`${URI_PATH}${dataPost.PostImage}`}
                                        className='pv-post-image'
                                        onClick={() => this.setState({
                                            showPreviewImage: true,
                                            imagePreview: `${URI_PATH}${dataPost.PostImage}`
                                        })}
                                    />
                                }
                                <div className='text-left' dangerouslySetInnerHTML={{__html: dataPost.PostContent}} />
                            </div>
                            {dataPost.PostDesc &&
                                <div>
                                    <hr width="95%" align="center" className="hr-default"></hr>
                                    <p className='text-center text-large mgt bold'>Nội dung tham khảo trích dẫn</p>
                                    <div className='text-left mgt' dangerouslySetInnerHTML={{__html: dataPost.PostDesc}} />
                                </div>
                            }
                        </div>
                    </div>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost, subCategory } = state.getCategory;
    const { countMission } = state.getCountMission;
    return { dataUser, categoryPost, subCategory, countMission };
}

export default connect(mapStateToProps, { changePoint, getCategory, getSubCategory })(withRouter(PostMine));