import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { getCountManageSupportApi } from '../../api/admin/study/support';
import SupportStudent from '../../components/admin/study/SupportStudent';
import SupportAdd from '../../components/admin/study/SupportAdd';
import SupportRank from '../../components/admin/study/SupportRank';
import SupportWait from '../../components/admin/study/SupportWait';

const ButtonGroup = Button.Group;

class CSHV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            loading: true,
            isManageSupport: false,
            listCountSupport: {}
        }
    }

    renderStep = () => {
        const { step } = this.state;
        switch (step) {
            case 1:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportAdd />
                    </>
                )
            case 2:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportWait isManage={true} />
                    </>
                )
            case 3:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportStudent isManage={true} />
                    </>
                )
            case 4:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportRank />
                    </>
                )
        }
        return null
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.step) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    setStep = (step) => {
        this.setState({ step })
    }

    getCountManageSupport = () => {
        getCountManageSupportApi().then(res => {
            if (res.code == 1) this.setState({ listCountSupport: res.data })
        })
    }

    componentDidMount() {
        this.getCountManageSupport();
    }

    render() {
        const { listCountSupport } = this.state;
        return (
            <>
                <ButtonGroup className='item-center flex' style={{ width: '100%' }}>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.setStep(1)}>Thêm HV</Button>
                    <Button className={this.buttonGroupClass(2)} onClick={() => this.setStep(2)}>Chờ CS{(listCountSupport.WaitCount && `(${listCountSupport.WaitCount})`)}</Button>
                    <Button className={this.buttonGroupClass(3)} onClick={() => this.setStep(3)}>Đang CS{(listCountSupport.CurrentCount && `(${listCountSupport.CurrentCount})`)}</Button>
                    <Button className={this.buttonGroupClass(4)} onClick={() => this.setStep(4)}>BXH CSHV</Button>
                </ButtonGroup>
                {this.renderStep()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(CSHV))