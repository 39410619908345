import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListCourseApi = () =>{
    let url = `${URI_PATH}app/course/promotion/getListCourse`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListPromotionApi = pageId =>{
    let url = `${URI_PATH}app/course/promotion/getListPromotion`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const createPromotionApi = (CourseId, Quantity, DiscountCost, BeginDate, EndDate) =>{
    let url = `${URI_PATH}app/course/promotion/create`;
    let body = {
        CourseId, Quantity, DiscountCost, BeginDate, EndDate
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deletePromotionApi = coursePromotionId =>{
    let url = `${URI_PATH}app/course/promotion/delete`;
    let body = {
        CoursePromotionId: coursePromotionId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const viewPromotionApi = coursePromotionId =>{
    let url = `${URI_PATH}app/course/promotion/view`;
    let body = {
        CoursePromotionId: coursePromotionId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}