import React, { Component } from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {getListAskQuestionApi} from '../../api/askquestion/mine';
import {URI_PATH, MESSAGE} from '../../utils/constants';
import {randomNumber} from '../../utils/common';
import {showNotification} from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import {getCategory, getSubCategory} from '../../actions/category/';
import { withRouter } from 'react-router'
import FsLightbox from 'fslightbox-react';
import TooltipUser from '../../components/TooltipUser';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import BarSelect from '../../components/frame/BarSelect';

class AskQuestionMine extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isEmpty: false,
            data: {},
            isPress: false,
            showPreviewImage: false,
            imagePreview: ''
        };
        this.num = 0;
        this.categoryId = '';
        this.subCategoryId = '';
    }

    getAskQuestion = () =>{
        let {num, categoryId, subCategoryId} = this;
        getListAskQuestionApi(num, categoryId, subCategoryId).then((res) => {
            if(res.code == 1){
                this.setState({
                    data: res.data,
                    isLoading: false,
                    isEmpty: false
                });
            }
            else this.setState({isEmpty: true, isLoading: false});
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMoreAskQuestion = (type) =>{
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        let nextMax = parseInt(this.state.data.countAskQuestion);
        if(this.directAskQuestion > 0) this.directAskQuestion = '';
        switch(type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() =>{
            this.getAskQuestion();
        }, 200)
    }

    changeCategory = (value, action) =>{
        if(this.directAskQuestion > 0) this.directAskQuestion = '';
        this.num = 0;
        if(action == 1){
            this.categoryId = value;
            if(!this.categoryId) this.subCategoryId = '';
            this.props.getSubCategory(this.categoryId);
            this.getAskQuestion();
        }
        else {
            this.subCategoryId = value;
            this.getAskQuestion();
        }
    }

    renderNavigate = () =>(
        <BarSelect
            style={{width: '25vh'}}
        >
            <Icon
                name='caret-left'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMoreAskQuestion('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{fontSize: '4vh', margin: '0vh 1vh'}}
                onClick={() => this.loadMoreAskQuestion('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMoreAskQuestion('next')}
            />
        </BarSelect>
    )

    componentDidMount(){
        this.getAskQuestion();
        if(this.props.categoryAskQuestion.length == 0) this.props.getCategory(4);
    }

    render(){
        let {isLoading, data, isEmpty, showPreviewImage, imagePreview} = this.state;
        let {categoryAskQuestion, subCategory} = this.props;
        let dataAskQuestion = data.dataAskQuestion;
        if(isLoading) return <Loading />;
        return(
            <div style={{paddingBottom: '8vh'}}>
                <div className='pv-search-container row'>
                    <div className={(this.categoryId > 0 && subCategory.length > 0) ? 'col-md-6 col-sm-6 col-xs-12' : 'col-md-12 col-sm-12 col-xs-12'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value, 1)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryAskQuestion.map((item, key) =>(
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                    {this.categoryId > 0 && subCategory.length > 0 &&
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                            <select
                                className='form-control'
                                onChange={e => this.changeCategory(e.target.value, 2)}
                            >
                                <option value=''>Tất cả chủ đề</option>
                                {subCategory.map((item, key) =>{
                                    return <option key={key} value={item.SubCategoryId}>{item.SubCategoryName}</option>
                                })}
                            </select>
                        </div>
                    }
                </div>
                {isEmpty ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không có câu hỏi cần tìm</p>
                    </div>
                :
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        <div className='flex-row mgt-2'>
                            <div className='flex-row flex-3 align-center'>
                                <TooltipUser userInfo={dataAskQuestion} avatarClass='avatar-guest' key={dataAskQuestion.UserId}/>
                                <div className='pv-user-container'>
                                    <p className='bold'>{dataAskQuestion.FullName}</p>
                                    <p className='placeholder black'>{dataAskQuestion.CrDateTime}</p>
                                </div>
                            </div>
                            <div className='flex-row flex-2 align-center' style={{justifyContent: 'flex-end'}}>
                                {dataAskQuestion.Status == 1 ?
                                    <p className='pv-check-p bg-danger'>Không duyệt</p>
                                :
                                    <>
                                        {dataAskQuestion.AskQuestionStatusId > 0 ?
                                            <p className='pv-check-p bg-success'>Đã xét duyệt</p>
                                        :
                                            <p className='pv-check-p bg-warning'>Đang xét duyệt</p>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className='flex-column'>
                            <p className='text-large bold text-center'>{dataAskQuestion.CategoryName}</p>
                            <p className='text-large bold text-center'>{dataAskQuestion.SubCategoryName}</p>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <p className='text-large text-center bold mgt mgb'>{dataAskQuestion.AskQuestionTitle}</p>
                            <div>
                                {dataAskQuestion.AskQuestionImage &&
                                    <img
                                        src={`${URI_PATH}${dataAskQuestion.AskQuestionImage}`}
                                        className='pv-post-image'
                                        onClick={() => this.setState({
                                            showPreviewImage: true,
                                            imagePreview: `${URI_PATH}${dataAskQuestion.AskQuestionImage}`
                                        })}
                                    />
                                }
                                <div className='text-left' dangerouslySetInnerHTML={{__html: dataAskQuestion.AskQuestionContent}} />
                            </div>
                            {dataAskQuestion.PostDesc &&
                                <div>
                                    <hr width="95%" align="center" className="hr-default"></hr>
                                    <p className='text-center text-large mgt bold'>Nội dung tham khảo trích dẫn</p>
                                    <div className='text-left mgt' dangerouslySetInnerHTML={{__html: dataAskQuestion.AskQuestionDesc}} />
                                </div>
                            }
                        </div>
                    </div>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryAskQuestion, subCategory } = state.getCategory;
    return { dataUser, categoryAskQuestion, subCategory };
}

export default connect(mapStateToProps, { changePoint, getCategory, getSubCategory })(withRouter(AskQuestionMine));