import { getCategoryApi, getSubCategoryApi } from '../../api/category';

export const getCategory = (categoryType) =>{
    return dispatch =>{
        getCategoryApi(categoryType).then((res) => {
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_CATEGORY', listCategory: res.listCategory, categoryType: categoryType });
            else dispatch({ type: 'FALSE_GET_CATEGORY' });
        })
        .catch(error => dispatch({ type: 'FALSE_GET_CATEGORY' }));
    }
}

export const getSubCategory = (categoryId, userId = 1) =>{
    return dispatch =>{
        getSubCategoryApi(categoryId, userId).then((res) => {
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_SUB_CATEGORY', listSubCategory: res.listSubCategory });
            else dispatch({ type: 'FALSE_GET_SUB_CATEGORY' });
        })
        .catch(error => dispatch({ type: 'FALSE_GET_SUB_CATEGORY' }));
    }
}