import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Switch, Checkbox } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { updateLessonApi, getListLessonApi, getListPropertyAndCategoryApi } from '../../../api/admin/study';
import { showNotification } from '../../../utils/notification';
import ReactQuill from 'react-quill';
import ModalAddLesson from '../../../components/admin/study/ModalAddLesson';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../components/Loading';
import HeaderLottie from '../../../components/HeaderLottie';
import empty from '../../../assets/lottie/empty.json';
import { getListTeacherApi, getListUserApi, toogleMasterApi, toogleStudentApi, toogleTeacherApi } from '../../../api/admin/study/user';
import { getAvatarFb } from '../../../utils/common';
import TooltipUser from '../../../components/TooltipUser';
import { TitleSuccess } from '../../../components/frame';
import Countdown, { zeroPad } from 'react-countdown-now';
import DatePicker from "react-datepicker";
import moment from 'moment';
import User from '../../../components/admin/study/User';
import { getListLogApi } from '../../../api/admin/study/log';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageId: 1,
            isLoading: true,
            listLog: [],
            endList: false,
        }
    }

    getListLog = (pageId = 1) =>{
        getListLogApi(pageId).then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listLog: pageId == 1 ? res.data : [...this.state.listLog, ...res.data],
                    endList: res.data.length < 20,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListLog(this.state.pageId + 1);
        }
    }

    componentDidMount(){
        this.getListLog()
    }

    render() {
        const { listLog, endList, isLoading } = this.state;
        return (
            <>
                {isLoading ?
                    <Loading small />
                :
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                            className='mgt-2'
                        >
                            {listLog.map((item, key) =>
                                <div className={`box-primary ${(item.IsEnable ? 'bg-success' : 'bg-danger')}`} key={key}>
                                    <div className='history-content'>
                                        <p>{item.Message}</p>
                                        <p>{moment(item.CrDateTime, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm")}</p>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)