import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import { MESSAGE, URI_PATH, PROP } from '../../utils/constants';
import {showNotification, showNotificationBonus, showNotificationAsset} from '../../utils/notification';
import Loading from '../../components/Loading';
import {Button, Modal} from 'antd';
import Lottie from 'react-lottie';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import confetti from '../../assets/lottie/confetti.json';
import confetti2 from '../../assets/lottie/confetti2.json';
import {getListOfferApi, openApi} from '../../api/gift/offer';
import {getAvatarFb, formatDecimal} from '../../utils/common';
import InfiniteScroll from 'react-infinite-scroller';

class ListGiftOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            isLoading: true,
            listOffer: [],
            endList: false,
            offerInfo: {},
            userOffer: {},
            foodAsset: [],
            showModal: false
        }
        this.page = 1;
    }

    getListOffer = () =>{
        getListOfferApi(this.page).then(res =>{
            if(res.code == 1) this.setState({
                listOffer: [...this.state.listOffer, ...res.listOffer],
                isLoading: false,
                endList: res.listOffer == 0
            });
            else{
                this.setState({
                    listOffer: [],
                    isLoading: false,
                    endList: true
                });
                showNotification('Có lỗi xảy ra khi tải danh sách quà tặng', 0);
            }
        }).catch(e =>{
            this.setState({
                listOffer: [],
                isLoading: false,
                endList: true
            });
            showNotification('Có lỗi xảy ra khi tải danh sách quà tặng', 0);
        })
    }

    loadMore = () =>{
        this.page += 1;
        this.getListOffer();
    }

    open = (usergiftofferId) =>{
        if(!this.state.isPress){
            this.setState({
                isPress: usergiftofferId,
            });
            let idNotification = '#notificationGiftOffer' + usergiftofferId;
            openApi(usergiftofferId).then(res =>{
                if(res.code == 1){
                    let {offerInfo, foodAsset, userOffer} = res;
                    this.setState({
                        listOffer: this.state.listOffer.filter(el => {
                            return el.UsergiftofferId !== usergiftofferId;
                        }),
                        offerInfo,
                        foodAsset,
                        userOffer,
                        isPress: false,
                        showModal: true
                    });
                    showNotificationBonus(offerInfo.Balance, 1, offerInfo.BalanceType);
					if(offerInfo.BalanceType == 1) this.props.changePoint([offerInfo.Balance]);
					else if(offerInfo.BalanceType == 2) this.props.changePoint([null, offerInfo.Balance]);
					else if(offerInfo.BalanceType == 3) this.props.changePoint([null, null, offerInfo.Balance]);
                    foodAsset.map(item =>{
                        if(item.AssetName) showNotificationAsset(item);
                    })
                }
                else{
                    this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    balanceName = {
        1: 'Gold[z]',
        2: '$[z]',
        3: 'đ[z]'
    }

    hideModal = () =>{
        this.setState({
            showModal: false,
            offerInfo: {},
            userOffer: {},
            foodAsset: [],
        })
    }

    renderGiftOpen = () =>{
        let {offerInfo, foodAsset, userOffer} = this.state;
        return(
            <div className='animated fadeIn slow'>
                <div className='text-center'>
                    <img
                        src={require('../../assets/image/gift'+ (offerInfo.UsergiftofferId % 5 + 1) +'.png')}
                        className='gift-img'
                    />
                </div>
                <div className='row item-center'>   
                    {foodAsset.map((item, key) =>(
                        <div key={key} className='col-md-3 col-sm-3 col-xs-6 item-center'>
                            <img className='item-img mgt-4' alt='Quà tặng' src={`${URI_PATH}${item.FoodImage ? item.FoodImage : item.AssetImage}`}/>
                        </div>
                    ))}
                </div>
                <div className='item-center'>
                    <p className='mgt-3 text-huge bold'>{formatDecimal(parseFloat(offerInfo.Balance))} {this.balanceName[offerInfo.BalanceType]}</p>
                    <p className='mgt-3 text-huge bold'>{offerInfo.Message}</p>
                    <img
                        src={getAvatarFb(userOffer.FacebookId)}
                        className='avatar-guest-huge mgt-3'
                    />
                    <p className='mgt-2 text-large'>{userOffer.FullName}</p>
                </div>
                <Lottie
                    options={{loop: true, autoplay: true, animationData: offerInfo.UsergiftofferId % 2 ? confetti : confetti2}}
                    style={{position: 'absolute', top: 0}}
                />
            </div>
        )
    }

    componentDidMount(){
        this.getListOffer();
    }

    render() {
        let {isLoading, listOffer, endList, showModal} = this.state;
        if(isLoading) return (<Loading small />);
        return (
            <div className='mgt'>
                {listOffer.length > 0 ?
                    <>
                        <div className='flex-column item-center'>
                            <p className='bold text-title-gift'>Quà được tặng</p>
                        </div>
                        <div>
                            <InfiniteScroll
                                loadMore={this.loadMore}
                                hasMore={!endList}
                                loader={<Loading key={0} small/>}
                                threshold={10}
                            >
                                {listOffer.map((item, key) =>(
                                    <div key={key} className='container-list-gift'>
                                        <div id={'notificationGiftOffer' + item.UsergiftofferId} />
                                        <div className='flex-row'>
                                            <div className='flex-3 flex-column item-center mgt'>
                                                <img
                                                    src={require('../../assets/image/gift'+ (item.UsergiftofferId % 5 + 1) +'.png')}
                                                    className='gift-img'
                                                />
                                                <p className='silver'>{item.CrDateTime}</p>
                                                <img
                                                    src={getAvatarFb(item.FacebookId)}
                                                    className='avatar-guest-small'
                                                />
                                                <p className='mgt'>{item.FullName}</p>
                                            </div>
                                            <div className='flex-2 flex-column item-center mgt'>
                                                <Button
                                                    className='button-open-gift'
                                                    onClick={() => this.open(item.UsergiftofferId)}
                                                    loading={this.state.isPress == item.UsergiftofferId}
                                                >
                                                    Nhận
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </>
                :
                    <div />
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={this.hideModal}
                    afterClose={this.hideModal}
                    destroyOnClose={true}
                    width='750px'
                    centered
                >
                    {showModal &&
                        this.renderGiftOpen()
                    }
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint})(ListGiftOffer);