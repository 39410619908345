const INITAL_STATE = {
    dataItem0: [],
    dataItem1: [],
    dataItem2: [],
    dataItem3: [],
    loadingItem0: true,
    loadingItem1: true,
    loadingItem2: true,
    loadingItem3: true,
    selectItem: 0
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_GET_ITEM_MARKET':
            return { ...state, selectItem: action.selectItem};
        case 'SUCCESS_GET_ITEM_MARKET':
            var {selectItem, listItem} = action;
            if(selectItem == 0) return { ...state, dataItem0: listItem, selectItem, loadingItem0: false};
            else if(selectItem == 1) return { ...state, dataItem1: listItem, selectItem, loadingItem1: false };
            else if(selectItem == 2) return { ...state, dataItem2: listItem, selectItem, loadingItem2: false };
            else if(selectItem == 3) return { ...state, dataItem3: listItem, selectItem, loadingItem3: false };
        case 'FALSE_GET_ITEM_MARKET':
            return { ...state, selectItem: action.selectItem };
        case 'ACTION_ITEM_MARKET':
            let item = [];
            var selectItem = action.selectItem;
            switch (selectItem) {
                case 0:
                    item = state.dataItem0.find((v) => {
                        return v.ChargemarketId == action.chargemarketId;
                    });
                    break;
                case 1:
                    item = state.dataItem1.find((v) => {
                        return v.ChargemarketId == action.chargemarketId;
                    });
                    break;
                case 2:
                    var {chargemarketId, amount, balanceLast, useritemId} = action;
                    return{
                        ...state,
                        dataItem2: state.dataItem2.map((item) =>{
                            let amountState = item.Amount > 0 ? parseInt(item.Amount) : 0;
                            if(item.ChargemarketId == chargemarketId) return Object.assign({}, item, {
                                Amount: amountState + parseInt(amount),
                                UserId: amountState + parseInt(amount) > 0 ? 1 : 0,
                                BalanceReal: balanceLast || item.BalanceReal,
                                UserItemId: useritemId
                            })
                            return item;
                        })
                    }
                case 3:
                    var {chargemarketId, amount, balanceLast, useritemId} = action;
                    return{
                        ...state,
                        dataItem3: state.dataItem3.map((item) =>{
                            let amountState = item.Amount > 0 ? parseInt(item.Amount) : 0;
                            if(item.ChargemarketId == chargemarketId) return Object.assign({}, item, {
                                Amount: amountState + parseInt(amount),
                                UserId: amountState + parseInt(amount) > 0 ? 1 : 0,
                                BalanceReal: balanceLast || item.BalanceReal,
                                UserItemId: useritemId
                            })
                            return item;
                        })
                    }
            }
            if (item && item.hasOwnProperty('UserId')) {
                item['UserId'] = '1';
            }
        default:
            return state;
    }
};