import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataBuffApi = () =>{
    let url = `${URI_PATH}app/newbie/buff/getDataBuff`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyBuffApi = () =>{
    let url = `${URI_PATH}app/newbie/buff/buyBuff`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
