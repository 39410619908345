import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListRankApi = (isGuest = false, PageId = 1) =>{
    let url = `${URI_PATH}app/question/lobby/rank/getList`;
    let body = {
        guest: isGuest,
        PageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}