import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {getListGiftDayApi, openGiftApi, checkOpenGiftApi} from '../../api/gift/day';
import { MESSAGE, URI_PATH} from '../../utils/constants';
import {showNotification, showNotificationBonus, showNotificationAsset, showNotificationProperty} from '../../utils/notification';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import gift from '../../assets/lottie/gift.json';
import giftopen from '../../assets/lottie/giftopen.json';
import {Button} from 'antd';
import Lottie from 'react-lottie';
import CountUp from 'react-countup';
import {getUserPresent} from '../../actions/home';
import {updateCountMission} from '../../actions/menu';
import Present from '../../components/home/Present';
import QuestionGift from '../../components/gift/QuestionGift';
import { checkEnegy } from '../../utils/common';

class GiftDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            isLoading: true,
            cost: '',
            giftData: {},
            giftInfo: {},
            showGiftQuestion: false,
            showOpenGift: false
        }
    }

    checkQuestion = (giftInfo) =>{
        if(!this.state.isPress){
            checkEnegy().then(res =>{
                let giftdayId = giftInfo.GiftdayId;
                this.setState({
                    isPress: giftdayId,
                });
                let idNotificationGift = '#notificationGiftDay' + giftdayId;
                checkOpenGiftApi(giftdayId).then(res =>{
                    if(res.code == 1){
                        this.setState({
                            showGiftQuestion: true,
                            giftInfo: giftInfo
                        });
                    }
                    else showNotification(res.message, res.code, idNotificationGift);
                    this.setState({isPress: false});
                }).catch(e =>{
                    showNotification(MESSAGE.ERROR, 0, idNotificationGift);
                    this.setState({isPress: false});
                })
            })
        }
    }

    openGift = () =>{
        let giftdayId = this.state.giftInfo.GiftdayId;
        openGiftApi(giftdayId).then(res =>{
            if(res.code == 1){
                this.setState({
                    cost: res.data,
                    isLoading: false,
                    showOpenGift: true,
                });
                setTimeout(() =>{
                    showNotificationAsset(res.assetInfo);
                    showNotificationProperty(res.property);
                    showNotificationBonus(res.data, 1, 3);
                    this.props.changePoint([null, null, res.data]);
                    this.props.updateCountMission('GiftDayCount');
                }, 3200);
            }
            else showNotification(res.message, res.code);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    renderGiftNewbie = () =>(
        <div className='mgt'>
            <div className='flex-column item-center'>
                <p className='text-title-gift'>Quà tân thủ đến {this.state.giftData.dateExpireNewbie}</p>
            </div>
            {this.state.giftData.listGiftNewbie.map((item, key) =>(
                <div key={key} className='container-list-gift'>
                    <div id={'notificationGiftDay' + item.GiftdayId} />
                    <div className='flex-row'>
                        <div className='flex-3 flex-column item-center mgt'>
                            <img
                                src={`${URI_PATH}${item.GiftImage}`}
                                className='gift-img'
                            />
                            <p>{item.GiftName}</p>
                            <p className='silver'>{item.GiftDesc}</p>
                        </div>
                        <div className='flex-2 flex-column item-center mgt'>
                            <Button
                                className='button-open-gift'
                                onClick={() => this.checkQuestion(item)}
                                loading={this.state.isPress == item.GiftdayId}
                            >
                                Nhận
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

    renderGiftSponsor = () =>(
        <div className='mgt'>
            <div className='flex-column item-center'>
                <p className='bold text-title-gift'>Quà được tài trợ</p>
            </div>
            {this.state.giftData.listGiftSponsor.map((item, key) =>(
                <div key={key} className='container-list-gift'>
                    <div id={'notificationGiftDay' + item.GiftdayId} />
                    <div className='flex-row'>
                        <div className='flex-3 flex-column item-center mgt'>
                            <img
                                src={`${URI_PATH}${item.GiftImage}`}
                                className='gift-img'
                            />
                            <p>{item.GiftName}</p>
                            <p className='silver'>{item.GiftDesc}</p>
                            {item.GiftdayId == 2 &&
                                <p className='silver'>Từ 07:45 - 08:00</p>
                            }
                        </div>
                        <div className='flex-2 flex-column item-center mgt'>
                            <Button
                                className='button-open-gift'
                                onClick={() => this.checkQuestion(item)}
                                loading={this.state.isPress == item.GiftdayId}
                            >
                                Nhận
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

    getListGiftDay = () =>{
        getListGiftDayApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    giftData: res.data
                })
            }
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    renderQuestionGift = () =>{
        let {showGiftQuestion, giftInfo, cost, showOpenGift} = this.state;
        return(
            <div>
                {showGiftQuestion &&
                    <QuestionGift
                        onClose={() => this.setState({
                            showGiftQuestion: false,
                            showOpenGift: false
                        })}
                        onSuccess={this.openGift}
                        showOpenGift={showOpenGift}
                    >
                        {showOpenGift &&
                            <div>
                                <p className='text-center text-huge bold'>{giftInfo.GiftName}</p>
                                <div className='row mgt-3'>
                                    <Lottie options={{loop: false, autoplay: true, animationData: giftopen}}
                                        width='40vh'
                                    />
                                </div>
                                <div className='mgt-4 text-center bold text-large'>
                                    Bạn nhận được món quà là <CountUp end={cost} duration={4} /> đ[z]
                                </div>
                            </div>
                        }
                    </QuestionGift>
                }
            </div>
        )
    }

    componentDidMount(){
        this.getListGiftDay();
        this.props.getUserPresent();
    }

    render() {
        let {isLoading, giftData} = this.state;
        if(isLoading) return (<Loading />);
        return (
            <div>
                <HeaderLottie source={gift} disableLoop/>
                <Present />
                {giftData.listGiftNewbie && this.renderGiftNewbie()}
                {giftData.listGiftSponsor.length > 0 && this.renderGiftSponsor()}
                {this.renderQuestionGift()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, getUserPresent, updateCountMission})(GiftDay);