import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListLessonTeacherApi } from '../../api/study/lesson';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import LessonShow from './componentChildren/LessonShow';

class LessonTeacher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listLesson: [],
            isLoading: true,
            dataTeacher: {},
            endList: false,
            pageId: 1
        }
    }

    getListLessonTeacher = (pageId = 1) => {
        getListLessonTeacherApi(pageId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLesson: pageId == 1 ? res.lessons : [...this.state.listLesson, ...res.lessons],
                    endList: res.lessons.length < 10,
                    dataTeacher:res.teacher,
                    isLoading: false,
                    pageId,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () =>{
        if(!this.state.isLoading && !this.state.endList){
            this.getListLessonTeacher(this.state.pageId + 1)
        }
    }

    seenChat = (StudyStudentId) =>{
        this.setState({
            listLesson: this.state.listLesson.map(item =>{
                if(item.StudyStudentId == StudyStudentId){
                    const ListUserIdReadChat = item.ListUserIdReadChat || [];
                    ListUserIdReadChat.push(this.props.dataUser.UserId);
                    return {
                        ...item,
                        ListUserIdReadChat
                    }
                }
                return item;
            })
        })
    }

    componentDidMount() {
        this.getListLessonTeacher()
    }

    render() {
        const { listLesson, isLoading, dataTeacher, endList } = this.state;
        return (
            <LessonShow
                listLesson={listLesson}
                isLoading={isLoading}
                onRefresh={this.getListLessonTeacher}
                dataTeacher={dataTeacher}
                type={3}
                loadMore={this.loadMore}
                endList={endList}
                onSeenChat={this.seenChat}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(LessonTeacher)