import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ changePoint } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getDataCreateApi, getListDistrictApi, updateCourseApi, getListCourseEditApi, editActionApi} from '../../api/course/create';
import { formatDecimal, getDecimalCount, replaceCost } from '../../utils/common';
import { showNotification } from '../../utils/notification';
import { MESSAGE, TICKET } from '../../utils/constants';
import { Button, Checkbox } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const curDay = new Date().getDate();
const curMonth = new Date().getMonth() + 1;
const curYear = new Date().getFullYear();

class CourseCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            listCourseType: [],
            listProvince: [],
            listDistrict: [],
            courseName: '',
            teacherName: this.props.dataUser.FullName,
            beginDay: curDay,
            beginMonth: curMonth,
            beginYear: curYear,
            endDay: curDay,
            endMonth: curMonth,
            endYear: curYear,
            stopRegisterDay: curDay,
            stopRegisterMonth: curMonth,
            stopRegisterYear: curYear,
            courseNo: '',
            courseDesc: '',
            payType: 1,
            feeLess26: '',
            feeGreate26: '',
            courseTypeId: 1,
            studentLimit: '',
            requireVIP: 0,
            provinceId: '',
            districtId: '',
            address: '',
            mapLink: '',
            courseStatusId: '',
            courseId: '',
            isAgree: false,
            listCourseEdit: [],
            endList: false,
            isPressEdit: false
        }
        this.page = 1;
    }

    getDataCreate = () =>{
        getDataCreateApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listCourseType: res.listCourseType,
                    listProvince: res.listProvince
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    changeProvince = e =>{
        let provinceId = e.target.value;
        this.setState({provinceId});
        if(provinceId > 0){
            getListDistrictApi(provinceId).then(res =>{
                if(res.code == 1) this.setState({listDistrict: res.listDistrict});
            }).catch(e => this.setState({listDistrict: []}));
        }
        else this.setState({
            listDistrict: [],
            districtId: ''
        });
    }

    changeInputMoney = (e, action) =>{
        let money = e.target.value;
        if(this.state.payType == 1){
            if(action == 1) this.setState({feeLess26: money});
            else this.setState({feeGreate26: money});
        }
        else{
            if(action == 1) this.setState({feeLess26: formatDecimal(money)});
            else this.setState({feeGreate26: formatDecimal(money)});
        }
    }

    updateCourse = () =>{
        if(!this.state.isPress){
            let {
                courseName,
                teacherName,
                beginDay,
                beginMonth,
                beginYear,
                endDay,
                endMonth,
                endYear,
                stopRegisterDay,
                stopRegisterMonth,
                stopRegisterYear,
                courseNo,
                courseDesc,
                payType,
                feeLess26,
                feeGreate26,
                courseTypeId,
                studentLimit,
                requireVIP,
                provinceId,
                districtId,
                address,
                mapLink,
                isAgree,
                courseStatusId,
                courseId
            } = this.state;
            let idNotification = '#notificationUpdateCourse';
            if(payType == 0){
                if(feeLess26 == '' || feeLess26 < 0 || feeGreate26 == '' || feeGreate26 < 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', -1, idNotification);
                if(getDecimalCount(feeLess26) > TICKET.DECIMAL_COUNT || getDecimalCount(feeGreate26) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1, idNotification);
            }
            else {
                feeLess26 = replaceCost(feeLess26, false);
                feeGreate26 = replaceCost(feeGreate26, false);
                if(feeLess26 == '' || feeLess26 < 0 || feeGreate26 == '' || feeGreate26 < 0) return showNotification('Số $[z] phải lớn hơn 0 và không được để trống', -1, idNotification);
            }
            if(
                courseName &&
                teacherName &&
                courseNo > 0 &&
                courseDesc &&
                studentLimit > 0 &&
                requireVIP >= 0 &&
                provinceId > 0 &&
                districtId > 0 &&
                address &&
                isAgree
            ){
                let beginDate = beginYear + '-' + beginMonth + '-' + beginDay;
                let endDate = endYear + '-' + endMonth + '-' + endDay;
                let stopRegisterDate = stopRegisterYear + '-' + stopRegisterMonth + '-' + stopRegisterDay;
                this.setState({isPress: true});
                updateCourseApi(
                    courseName,
                    teacherName,
                    beginDate,
                    endDate,
                    stopRegisterDate,
                    courseNo,
                    courseDesc,
                    payType,
                    feeLess26,
                    feeGreate26,
                    courseTypeId,
                    studentLimit,
                    requireVIP,
                    provinceId,
                    districtId,
                    address,
                    mapLink,
                    courseStatusId,
                    courseId
                ).then(res =>{
                    if(res.code == 1){
                        this.setState({
                            courseName: '',
                            teacherName: this.props.dataUser.FullName,
                            beginDay: curDay,
                            beginMonth: curMonth,
                            beginYear: curYear,
                            endDay: curDay,
                            endMonth: curMonth,
                            endYear: curYear,
                            stopRegisterDay: curDay,
                            stopRegisterMonth: curMonth,
                            stopRegisterYear: curYear,
                            courseNo: '',
                            courseDesc: '',
                            payType: 1,
                            feeLess26: '',
                            feeGreate26: '',
                            courseTypeId: 1,
                            studentLimit: '',
                            requireVIP: 0,
                            provinceId: '',
                            districtId: '',
                            address: '',
                            mapLink: '',
                            courseStatusId: '',
                            courseId: '',
                            isAgree: false,
                            isPress: false
                        });
                        this.page = 1;
                        this.getListCourseEdit();
                    }
                    else this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                })
            }
            else if(!courseName) return showNotification('Tên khoá học không được bỏ trống', 0, idNotification);
            else if(!teacherName) return showNotification('Tên giảng viên không được bỏ trống', 0, idNotification);
            else if(courseNo == '' || courseNo <= 0) return showNotification('Số lần tổ chức khoá học không nhỏ hơn 0', 0, idNotification);
            else if(!courseDesc) return showNotification('Nội dung khoá học không được để trống', 0, idNotification);
            else if(studentLimit == '' || studentLimit <= 0) return showNotification('Số lượng học viên không nhỏ hơn 0', 0, idNotification);
            else if(requireVIP == '' || requireVIP < 0) return showNotification('Số VIP yêu cầu không được để trống', 0, idNotification);
            else if(!provinceId) return showNotification('Bạn chưa chọn tỉnh/thành phố', 0, idNotification);
            else if(!districtId) return showNotification('Bạn chưa chọn quận/huyện', 0, idNotification);
            else if(!address) return showNotification('Bạn chưa nhập địa chỉ', 0, idNotification);
        }
    }

    getListCourseEdit = () =>{
        getListCourseEditApi(this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listCourseEdit: this.page == 1 ? res.listCourse : [...this.state.listCourseEdit, ...res.listCourse],
                    endList: res.listCourse.length == 0
                })
            }
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () =>{
        this.page += 1;
        this.getListCourseEdit();
    }

    editAction = courseId =>{
        if(!this.state.isPressEdit){
            this.setState({isPressEdit: courseId})
            let idNotification = '#notificationEditCourse' + courseId;
            editActionApi(courseId).then(res =>{
                if(res.code == 1){
                    let courseInfo = res.courseInfo;
                    getListDistrictApi(courseInfo.ProvinceId).then(res =>{
                        if(res.code == 1) this.setState({listDistrict: res.listDistrict});
                    }).catch(e => this.setState({listDistrict: []}));
                    window.scroll({top: 0, left: 0, behavior: 'smooth' });
                    this.setState({
                        courseName: courseInfo.CourseName,
                        teacherName: courseInfo.TeacherName,
                        beginDay: courseInfo.BeginDate.substring(8, 10),
                        beginMonth: courseInfo.BeginDate.substring(5, 7),
                        beginYear: courseInfo.BeginDate.substring(0, 4),
                        endDay: courseInfo.EndDate.substring(8, 10),
                        endMonth: courseInfo.EndDate.substring(5, 7),
                        endYear: courseInfo.EndDate.substring(0, 4),
                        stopRegisterDay: courseInfo.StopRegisterDate.substring(8, 10),
                        stopRegisterMonth: courseInfo.StopRegisterDate.substring(5, 7),
                        stopRegisterYear: courseInfo.StopRegisterDate.substring(0, 4),
                        courseNo: courseInfo.CourseNo,
                        courseDesc: courseInfo.CourseDesc,
                        payType: courseInfo.PayType,
                        feeLess26: courseInfo.FeeLess26,
                        feeGreate26: courseInfo.FeeGreate26,
                        courseTypeId: courseInfo.CourseTypeId,
                        studentLimit: courseInfo.StudentLimit,
                        requireVIP: courseInfo.RequireVIP,
                        provinceId: courseInfo.ProvinceId,
                        districtId: courseInfo.DistrictId,
                        address: courseInfo.Address,
                        mapLink: courseInfo.MapLink,
                        courseStatusId: courseInfo.CourseStatusId,
                        courseId: courseInfo.CourseId,
                        isAgree: true,
                        isPressEdit: false
                    });
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPressEdit: false});
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPressEdit: false});
            })
        }
    }

    componentDidMount(){
        this.getDataCreate();
        this.getListCourseEdit();
    }

    render(){
        let {
            isLoading,
            listCourseType,
            listProvince,
            listDistrict,
            courseName,
            teacherName,
            beginDay,
            beginMonth,
            beginYear,
            endDay,
            endMonth,
            endYear,
            stopRegisterDay,
            stopRegisterMonth,
            stopRegisterYear,
            courseNo,
            courseDesc,
            payType,
            feeLess26,
            feeGreate26,
            courseTypeId,
            studentLimit,
            requireVIP,
            provinceId,
            districtId,
            address,
            mapLink,
            isAgree,
            courseStatusId,
            isPress,
            listCourseEdit,
            endList,
            isPressEdit
        } = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                <div className='flex-column item-center mgb'>
                    <p className='bold title-success'>Tạo khoá học</p>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-sm-12 col-xs-12 box-primary'>
                        <p className='mgt'>Tên chương trình:</p>
                        <input 
                            className='form-control'
                            placeholder='Nhập tên chương trình'
                            value={courseName}
                            onChange={e => this.setState({courseName: e.target.value})}
                        />
                        <p className='mgt'>Tổ chức lần thứ:</p>
                        <input 
                            className='form-control'
                            placeholder='Nhập số lần tổ chức'
                            value={courseNo}
                            onChange={e => this.setState({courseNo: e.target.value})}
                        />
                        <p className='mgt'>Số lượng học viên</p>
                        <input 
                            className='form-control'
                            placeholder='Nhập số lượng giảng viên'
                            value={studentLimit}
                            onChange={e => this.setState({studentLimit: e.target.value})}
                        />
                        <p className='mgt'>Giảng viên:</p>
                        <input 
                            className='form-control'
                            placeholder='Nhập tên giảng viên'
                            value={teacherName}
                            onChange={e => this.setState({teacherName: e.target.value})}
                        />
                        <p className='mgt'>Ngày bắt đầu học:</p>
                        <div className='row'>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={beginDay}
                                    onChange={e => this.setState({beginDay: e.target.value})}
                                >
                                    {new Array(31).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Ngày {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={beginMonth}
                                    onChange={e => this.setState({beginMonth: e.target.value})}
                                >
                                    {new Array(12).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Tháng {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={beginYear}
                                    onChange={e => this.setState({beginYear: e.target.value})}
                                >
                                    <option value={curYear}>Năm {curYear}</option>
                                    <option value={curYear + 1}>Năm {curYear + 1}</option>
                                </select>
                            </div>
                        </div>
                        <p className='mgt'>Ngày kết thúc:</p>
                        <div className='row'>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={endDay}
                                    onChange={e => this.setState({endDay: e.target.value})}
                                >
                                    {new Array(31).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Ngày {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={endMonth}
                                    onChange={e => this.setState({endMonth: e.target.value})}
                                >
                                    {new Array(12).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Tháng {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={endYear}
                                    onChange={e => this.setState({endYear: e.target.value})}
                                >
                                    <option value={curYear}>Năm {curYear}</option>
                                    <option value={curYear + 1}>Năm {curYear + 1}</option>
                                </select>
                            </div>
                        </div>
                        <p className='mgt'>Ngày ngừng nhận ghi danh:</p>
                        <div className='row'>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={stopRegisterDay}
                                    onChange={e => this.setState({stopRegisterDay: e.target.value})}
                                >
                                    {new Array(31).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Ngày {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={stopRegisterMonth}
                                    onChange={e => this.setState({stopRegisterMonth: e.target.value})}
                                >
                                    {new Array(12).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Tháng {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    value={stopRegisterYear}
                                    onChange={e => this.setState({stopRegisterYear: e.target.value})}
                                >
                                    <option value={curYear}>Năm {curYear}</option>
                                    <option value={curYear + 1}>Năm {curYear + 1}</option>
                                </select>
                            </div>
                        </div>
                        <p className='mgt'>Ghi danh bằng:</p>
                        <select
                            className='form-control select-left'
                            value={payType}
                            onChange={e => this.setState({payType: e.target.value, feeLess26: '', feeGreate26: ''})}
                        >
                            <option value={1}>Gold[z]</option>
                            <option value={2}>$[z]</option>
                        </select>
                        <p className='mgt'>Học phí dưới 26 tuổi:</p>
                        <input 
                            className='form-control'
                            value={feeLess26}
                            onChange={e => this.changeInputMoney(e, 1)}
                            placeholder='Nhập số học phí dưới 26 tuổi'
                        />
                        <p className='mgt'>Học phí trên 26 tuổi:</p>
                        <input 
                            className='form-control'
                            value={feeGreate26}
                            onChange={e => this.changeInputMoney(e, 2)}
                            placeholder='Nhập số học phí trên 26 tuổi'
                        />
                        <p className='mgt'>Loại khóa học:</p>
                        <select
                            className='form-control select-left'
                            value={courseTypeId}
                            onChange={e => this.setState({courseTypeId: e.target.value})}
                        >
                            {listCourseType.map((item, key) =>
                                <option value={item.CourseTypeId} key={key}>{item.CourseTypeName}</option>
                            )}
                        </select>
                        <p className='mgt'>Yêu cầu từ VIP:</p>
                        <input 
                            className='form-control'
                            value={requireVIP}
                            onChange={e => this.setState({requireVIP: e.target.value})}
                            placeholder='Nhập số VIP yêu cầu'
                        />
                        <p className='mgt'>Tỉnh/thành phố:</p>
                        <select
                            className='form-control select-left'
                            onChange={this.changeProvince}
                            value={provinceId}
                        >
                            <option value=''>Chọn tỉnh/thành phố</option>
                            {listProvince.map((item, key) =>
                                <option value={item.ProvinceId} key={key}>{item.ProvinceName}</option>
                            )}
                        </select>
                        {listDistrict.length > 0 &&
                            <div>
                                <p className='mgt'>Quận/huyện:</p>
                                <select
                                    className='form-control select-left'
                                    onChange={e => this.setState({districtId: e.target.value})}
                                    value={districtId}
                                >
                                    <option value=''>Chọn quận/huyện</option>
                                    {listDistrict.map((item, key) =>
                                        <option value={item.DistrictId} key={key}>{item.DistrictName}</option>
                                    )}
                                </select>
                            </div>
                        }
                        <p className='mgt'>Địa chỉ:</p>
                        <input 
                            className='form-control'
                            placeholder='Nhập địa chỉ'
                            value={address}
                            onChange={e => this.setState({address: e.target.value})}
                        />
                        <p className='mgt'>Link bản đồ:</p>
                        <input 
                            className='form-control'
                            placeholder='Nhập link bản đồ'
                            value={mapLink}
                            onChange={e => this.setState({mapLink: e.target.value})}
                        />
                        {(courseStatusId == 2 || courseStatusId == 1) &&
                            <>
                                <p className='mgt'>Trạng thái:</p>
                                <select
                                    class="form-control"
                                    value={courseStatusId}
                                    onChange={e => this.setState({courseStatusId: e.target.value})}
                                >
                                    <option value="1">Ngừng kinh doanh</option>
                                    <option value="2">Đang kinh doanh</option>
                                    <option value="3">Chờ xét duyệt</option>
                                </select>
                            </>
                        }
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12 box-primary'>
                        <p>Giới thiệu khóa học:</p>
                        <div
                            className='mgt'
                            style={{cursor: 'text'}}
                            onClick={() => this.refs.postContent.focus()}
                        >
                            <ReactQuill
                                ref='postContent'
                                value={courseDesc}
                                onChange={courseDesc => this.setState({courseDesc})}
                                modules={{toolbar: [['bold', 'italic', 'underline']]}}
                                placeholder='Nhập nội dung khoá học'
                            />
                        </div>
                    </div>
                </div>
                <div className='mgt flex-column item-center'>
                    <Checkbox
                        checked={isAgree}
                        onChange={e => this.setState({isAgree: e.target.checked})}
                    >
                        Tôi đã xem và đồng ý với điều khoản đào tạo
                    </Checkbox>
                    <div id='notificationUpdateCourse'></div>
                    <Button
                        className='mgt bg-success'
                        loading={isPress}
                        onClick={this.updateCourse}
                    >
                        Cập nhật khoá học
                    </Button>
                </div>
                <div className='mgt-3'>
                    <div className='flex-column item-center mgb'>
                        <p className='bold title-success'>Khoá học đã tạo</p>
                    </div>
                    {listCourseEdit.length > 0 ?
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small/>}
                            threshold={50}
                        >
                            {listCourseEdit.map((item, key) =>
                                <div className='box-primary' key={key}>
                                    <p className='text-large mgt'>{item.CourseName}</p>
                                    <p className='mgt'>Thời gian: {item.CrDateTime}</p>
                                    <p className='mgt'>Trạng thái: {item.CourseStatusName}</p>
                                    <div className='mgt' id={'notificationEditCourse' + item.CourseId} />
                                    <Button
                                        className='bg-success'
                                        loading={isPressEdit == item.courseId}
                                        onClick={() => this.editAction(item.CourseId)}
                                    >
                                        Chỉnh sửa
                                    </Button>
                                </div>
                            )}
                        </InfiniteScroll>
                    :
                        <div className='item-center'>
                            <HeaderLottie source={empty} disableLoop />
                            <p className='text-large mgt'>Bạn chưa tạo khoá học nào</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(CourseCreate);