import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { MESSAGE } from '../../utils/constants';
import { getListLessonApi } from '../../api/admin/study';
import { showNotification } from '../../utils/notification';
import ModalAddLesson from '../../components/admin/study/ModalAddLesson';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getCategory} from '../../actions/category';
import { Collapse } from 'react-collapse';
import Icon from 'react-fontawesome';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            selectCategory: 0,
            listLesson: [],
            isLoading: true,
            dataEdit: {},
            selectSubCategoryLesson: ''
        }
    }

    buttonGroupClass = (selectCategory) => {
        if (selectCategory == this.state.selectCategory) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    getListLesson = (selectCategory = 0) => {
        if (selectCategory != this.state.selectCategory) {
            this.setState({ isLoading: true })
        }
        getListLessonApi(selectCategory).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLesson: res.data,
                    selectCategory,
                    isLoading: false
                })
            }
            else {
                showNotification(MESSAGE.ERROR, 0)
                this.setState({ isPress: false })
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
            this.setState({ isPress: false })
        })
    }

    refreshOnCreate = (selectCategory) => {
        if (selectCategory == this.state.selectCategory || this.state.selectCategory == 0) {
            this.getListLesson(selectCategory == this.state.selectCategory ? selectCategory : 0);
        }
    }

    componentDidMount() {
        this.props.getCategory(1);
        this.getListLesson();
    }

    render() {
        const { listLesson, isLoading, dataEdit, selectSubCategoryLesson } = this.state;
        const {categoryPost} = this.props;
        return (
            <>
                <ModalAddLesson
                    onCreate={this.refreshOnCreate}
                    dataEdit={dataEdit}
                    onShowModal={() => this.setState({dataEdit: {}})}
                />
                <div className="row mgt">
                    <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                        <Button className={this.buttonGroupClass(0)} onClick={() => this.getListLesson(0)}>Tất cả</Button>
                        {categoryPost.map((item, key) =>
                            <>
                                <Button
                                    key={key}
                                    className={this.buttonGroupClass(item.CategoryId)}
                                    onClick={() => this.getListLesson(item.CategoryId)}
                                >
                                    {item.CategoryName}
                                </Button>
                            </>
                        )}
                    </ButtonGroup>
                </div>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        {listLesson.length > 0 ?
                            <>
                            {listLesson.map((lessonCategory, key) => 
                                <div
                                    className='box-primary pointer relative'
                                    key={key}
                                    onClick={() => this.setState({
                                        selectSubCategoryLesson: selectSubCategoryLesson == lessonCategory[0].SubCategoryId ? 0 : lessonCategory[0].SubCategoryId
                                    })}
                                >
                                    <p className='text-center mgb'>
                                        {lessonCategory[0].SubCategoryName}
                                    </p>
                                    <Icon name='chevron-down' className='icon-dropdown-study-lesson' />
                                    <Collapse isOpened={selectSubCategoryLesson == lessonCategory[0].SubCategoryId}>
                                        {lessonCategory.map((item, key) =>
                                            <div className='row box-primary item-center relative' key={key}>
                                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                                    <p className='mgb-0'>{item.CategoryName} - {item.SubCategoryName}</p>
                                                </div>
                                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                                    <p className='mgb-0'>{item.LessonTitle}</p>
                                                </div>
                                                <img
                                                    src={require('../../assets/image/pen.png')}
                                                    className='edit-lesson-admin'
                                                    onClick={(e) =>{
                                                        e.stopPropagation();
                                                        this.setState({
                                                            dataEdit: {
                                                                ...item,
                                                                dateCreate: Date.now()
                                                        }})
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Collapse>
                                </div>
                            )}
                            </>
                            :
                            <div className='item-center'>
                                <HeaderLottie source={empty} disableLoop />
                                <p className='text-large mgt'>Không có bài học nào</p>
                            </div>
                        }
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost, subCategory } = state.getCategory;
    return { dataUser, categoryPost, subCategory }
}

export default connect(mapStateToProps, {getCategory})(Main)