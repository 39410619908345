import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListFortuneApi = (pageId) =>{
    let url = `${URI_PATH}app/club/fortune/getListFortune`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListFortuneOpenApi = (clubfortuneId) =>{
    let url = `${URI_PATH}app/club/fortune/getListFortuneOpen`;
    let body = {
        ClubfortuneId: clubfortuneId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const openFortuneApi = (clubfortuneopenId) =>{
    let url = `${URI_PATH}app/club/fortune/openFortune`;
    let body = {
        ClubfortuneopenId: clubfortuneopenId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}