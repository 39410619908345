import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const publishVideoApi = (CategoryId, VideoDesc, VideoUrl, VideoTitle, IsTikTok) =>{
    let url = `${URI_PATH}app/video/publish/publishVideo`;
    let body = {
        CategoryId,
        VideoDesc,
        VideoUrl,
        VideoTitle,
        VideoType: IsTikTok ? 2 : 1
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}