import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Switch, Checkbox } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { updateLessonApi, getListLessonApi, getListPropertyAndCategoryApi } from '../../../api/admin/study';
import { showNotification } from '../../../utils/notification';
import ReactQuill from 'react-quill';
import ModalAddLesson from '../../../components/admin/study/ModalAddLesson';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../components/Loading';
import HeaderLottie from '../../../components/HeaderLottie';
import empty from '../../../assets/lottie/empty.json';
import { changeMaxStudentApi, editStudentApi, getListMasterApi, getlistStudentApi, toggleZcrazyApi, toogleMasterApi, toogleStudentApi, toogleTeacherApi } from '../../../api/admin/study/user';
import { getAvatarFb } from '../../../utils/common';
import TooltipUser from '../../../components/TooltipUser';
import { EmptyLottie, TitleSuccess } from '../../../components/frame';
import Countdown, { zeroPad } from 'react-countdown-now';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getListManageApi, getListStudentApi, toggleManageStudentApi, toggleSupportStudentApi, toggleVirtualStudentApi, toggleCheckedUser } from '../../../api/admin/study/support';

class SupportListStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            pageId: 1,
            isLoading: true,
            listStudent: [],
            endList: false,
            inputSearch: '',
            showModal: false,
            loadingModal: true,
            listManageSelect: [],
            userSelect: {}
        }
    }

    getListStudent = (pageId = 1) => {
        getListStudentApi(pageId, this.state.inputSearch).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listStudent: pageId == 1 ? res.data : [...this.state.listStudent, ...res.data],
                    endList: res.data.length < 20,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListStudent(this.state.pageId + 1);
        }
    }

    changeInputSearch = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.timeoutSearch = setTimeout(() => {
            this.getListStudent(1);
        }, 500);
    }

    toggleSupport = (listManageSelect, userId) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toggleSupportStudentApi(listManageSelect, userId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false,
                        listManageSelect: []
                    });
                    this.getListStudent(this.state.pageId)
                }
                else {
                    this.setState({ isPress: false })
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    toggleVirtual = (studentData) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toggleVirtualStudentApi(studentData.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listStudent: this.state.listStudent.map(item => {
                            if (item.UserId == studentData.UserId) {
                                return {
                                    ...item,
                                    StudyVirtualId: res.studyVirtualId
                                }
                            }
                            return item
                        }),
                    })
                }
                else {
                    this.setState({ isPress: false })
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    toggleChecked = (studentData) =>{
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toggleCheckedUser(studentData.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listStudent: this.state.listStudent.map(item => {
                            if (item.UserId == studentData.UserId) {
                                return {
                                    ...item,
                                    IsChecked: res.isChecked
                                }
                            }
                            return item
                        }),
                    })
                }
                else {
                    this.setState({ isPress: false })
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    toggleManage = (studentData) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toggleManageStudentApi(studentData.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listStudent: this.state.listStudent.map(item => {
                            if (item.UserId == studentData.UserId) {
                                return {
                                    ...item,
                                    StudySupportManageId: res.studySupportManageId
                                }
                            }
                            return item
                        }),
                    })
                }
                else {
                    this.setState({ isPress: false })
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    getListManage = () => {
        getListManageApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    loadingModal: false,
                    listManageModal: res.listManage
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    showModalChooseManage = (userData, listManageSelect) => {
        this.setState({
            showModal: true,
            userSelect: userData,
            loadingModal: true,
            listManageSelect: listManageSelect || []
        })
        this.getListManage()
    }

    selectManage = (userId) => {
        let listManageSelect = this.state.listManageSelect;
        this.setState({
            listManageSelect: listManageSelect.includes(userId) ? listManageSelect.filter(i => i != userId) : [...listManageSelect, userId]
        })
    }

    toggleZcrazy = (userSelect) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toggleZcrazyApi(userSelect.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listStudent: this.state.listStudent.map(item => {
                            if (item.UserId == userSelect.UserId) {
                                return {
                                    ...item,
                                    IsZcrazy: res.isZcrazy,
                                }
                            }
                            return item
                        }),
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    componentDidMount() {
        this.getListStudent()
    }

    render() {
        const { isLoading, listStudent, inputSearch, endList, loadingModal, listManageModal, showModal, isPress, listManageSelect, userSelect } = this.state;
        return (
            <>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <input
                            type='text'
                            className='form-control'
                            value={inputSearch}
                            onChange={this.changeInputSearch}
                            placeholder='Nhập họ tên hoặc số điện thoại'
                        />
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                            className='mgt-2'
                        >
                            {listStudent.map((item, key) =>
                                <div className='row box-primary relative' key={key}>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <TooltipUser userInfo={item} avatarClass='avatar-guest-small mgr' />
                                        <div>
                                            <p className='mgb-0'>{item.FullName} - {item.PhoneNumber}</p>
                                            {item.EndStudentTime && item.EndStudentTime != '0000-00-00 00:00:00' &&
                                                <p>
                                                    <Countdown
                                                        date={moment(item.EndStudentTime, "YYYY-MM-DD HH:mm").unix() * 1000}
                                                        renderer={({ days, hours, minutes, seconds }) => (<span>{days}d {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                                    />
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex'>
                                        <div className='row-user-admin-switch'>
                                            {!item.StudyVirtualId &&
                                                <>
                                                    {item.StudySupportUserId > 0 ?
                                                        <u onClick={() => {
                                                            this.showModalChooseManage(item, item.ListManage);
                                                        }}>
                                                            <p className='pointer'>Khách hàng</p>
                                                        </u>
                                                        :
                                                        <p>Khách hàng</p>
                                                    }
                                                    <Switch
                                                        checked={item.StudySupportUserId > 0}
                                                        onChange={(state) => {
                                                            state ? this.showModalChooseManage(item) : this.toggleSupport([], item.UserId)
                                                        }}
                                                    />
                                                </>
                                            }
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            <p>CSKH</p>
                                            <Switch
                                                checked={item.StudySupportManageId > 0}
                                                onChange={() => this.toggleManage(item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            <p>TK Vip</p>
                                            <Switch
                                                checked={item.IsChecked > 0}
                                                onChange={() => this.toggleChecked(item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            <p>TK Ảo</p>
                                            <Switch
                                                checked={item.StudyVirtualId > 0}
                                                onChange={() => this.toggleVirtual(item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            <p>Zcrazy</p>
                                            <Switch
                                                checked={item.IsZcrazy > 0}
                                                onChange={() => this.toggleZcrazy(item)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    <div className='item-center mgt'>
                        <img src={getAvatarFb(userSelect.FacebookId)} className='avatar-guest-large' />
                        <p className='mgt'>{userSelect.FullName}</p>
                    </div>
                    <div className='mgt mgb'>
                        <TitleSuccess>Chọn người CS</TitleSuccess>
                    </div>
                    {loadingModal ?
                        <Loading small />
                        :
                        <>
                            {listManageModal.map((item, key) =>
                                <div
                                    className='box-primary flex align-center pointer'
                                    key={key}
                                    onClick={() => this.selectManage(item.UserId)}
                                >
                                    <div className='flex-row flex-1 align-center'>
                                        <TooltipUser userInfo={item} />
                                        <p className='mgl-2'>{item.FullName}</p>
                                    </div>
                                    <Checkbox
                                        checked={listManageSelect.includes(item.UserId)}
                                    />
                                </div>
                            )}
                            {listManageModal.length > 0 ?
                                <div className='item-center mgt-2'>
                                    <Button
                                        className='bg-success mgt'
                                        onClick={() => listManageSelect.length && this.toggleSupport(listManageSelect, userSelect.UserId)}
                                        loading={isPress}
                                    >
                                        Xác nhận
                                    </Button>
                                </div>
                                :
                                <EmptyLottie title='Không có CSKH được bật' />
                            }
                        </>
                    }
                </Modal>
            </>
        )
    }
}

export default SupportListStudent