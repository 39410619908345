const INITAL_STATE = {
    listNotification: [],
    page: 1,
    isLoading: true,
    endList: false,
    showWidgetNotification: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_HIDE_WIDGET_NOTIFICATION':
            return { ...state, showWidgetNotification: !state.showWidgetNotification, page: 1 };
        case 'SUCCESS_GET_LIST_NOTIFICATION':
            return { ...state,
                listNotification: action.page == 1 ? action.listNotification : [ ...state.listNotification, ...action.listNotification],
                endList: action.listNotification.length == 0,
                page: action.page,
                isLoading: false
            }
        case 'SHOW_HIDE_CHAT':
            return{
                ...state,
                showWidgetNotification: false
            }
        case 'FALSE_GET_LIST_NOTIFICATION':
            return { ...state };
        default:
            return state;
    }
};