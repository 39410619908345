import React, { Component } from 'react';
import {PROP, URI_PATH, MESSAGE} from '../utils/constants';
import {Modal, Tooltip, Button} from 'antd';
import {connect} from 'react-redux';
import {closeModalEat} from '../actions/home/modalEat';
import {eatFoodApi} from '../api/home';
import {showNotification} from '../utils/notification';
import Cook from './home/Cook';
import { getResourceCook, getUserItem } from '../actions/home/';
import moment from 'moment';

class ModalEatFood extends Component{
    constructor(props){
        super(props);
        this.state={
            isPress: false,
            showModalCook: false
        }
    }

    eatFood = (userfoodId) =>{
        if(this.state.isPress === false){
            this.setState({isPress: true});
            eatFoodApi(userfoodId).then(res => {
                this.setState({isPress: false});
                showNotification(res.message, res.code);
                if(res.code == 1){
                    this.props.getUserItem(0);
                    this.props.closeModalEat();
                }
            }).catch(error =>{
                showNotification(MESSAGE.ERROR, -1);
                this.setState({isPress: false});
            })
        }
    }

    openCook = () =>{
        if(this.props.listResource.length == 0) this.props.getResourceCook();
        this.setState({
            showModalCook: true
        });
    }

    render(){
        const {dataUser} = this.props
        return(
            <>
            <Modal
                {...PROP.MODAL}
                visible={this.props.showModalEat}
                onCancel={this.props.closeModalEat}
                centered
            >
                <div className='mgt-2'>
                    {moment(dataUser.LifeExpire).isBefore(moment()) &&
                        <p className='title-success'>Bạn đã hết năng lượng. Hãy sử dụng đồ ăn để tiếp tục</p>
                    }
                    <div className='item-center'>
                        <Button
                            className='bg-success mgt'
                            title='Nấu ăn'
                            onClick={this.openCook}
                        >
                            Nấu ăn
                        </Button>
                    </div>
                    <div className="row mgt-2">
                        {this.props.listFood.map((item, key) =>
                            <Tooltip {...PROP.TOOLTIP_HOME} key={key} overlayStyle={{zIndex: 99999}} title={
                                <div>
                                    <Button
                                        className='popover-item bg-success'
                                        onClick={() => this.eatFood(item.UserfoodId)}
                                        loading={this.state.isPress}
                                    >
                                        Ăn ngay
                                    </Button>
                                </div>
                            }>
                                <div className='col-md-4 col-sm-4 col-xs-6 item-block item-center'>
                                    <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                    <p className='mgt'>{item.Amount}</p>
                                    <p>{item.FoodName}</p>
                                    <p>{item.Hour} giờ</p>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalCook}
                onCancel={() => this.setState({showModalCook: false})}
                destroyOnClose={true}
                centered
            >
                <Cook />
            </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const {showModalEat, listFood} = state.eatModal;
    const { listResource } = state.getResourceCook;
    return { showModalEat, listFood, listResource, dataUser };
}

export default connect(mapStateToProps, {closeModalEat, getResourceCook, getUserItem })(ModalEatFood);
