import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PROP, MESSAGE } from '../../utils/constants';
import Loading from '../../components/Loading';
import { getAvatarFb, priceFormat } from '../../utils/common';
import { Button, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import ClubVipLevel from '../../components/club/ClubVipLevel';
import TooltipUser from '../../components/TooltipUser';
import { updateDataUser } from '../../actions/home';
import { getClubInfoApi, updateClubInfoApi, leaveClubApi, getListMemberClubApi, selectDeputyClubApi } from '../../api/club/info';
import { showNotification } from '../../utils/notification';

class ClubInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubInfo: {},
            userInfo: {},
            isLoadingInfo: true,
            showModal: false,
            modalType: '',
            inputEdit: '',
            listMemberClub: [],
            isPress: false,
        }
    }

    getClubInfo = () => {
        getClubInfoApi().then(res => {
            if (res.code == 1) {
                let { clubInfo, userInfo } = res.data;
                this.setState({
                    clubInfo,
                    userInfo,
                    isLoadingInfo: false
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    imageModal = () => {
        let img;
        switch (this.state.modalType) {
            case 1:
                img = require('../../assets/image/club-note.png');
                break;
            case 2:
                img = require('../../assets/image/bell.png');
                break;
            case 3:
                img = require('../../assets/image/location.png');
                break;
            case 4:
                img = require('../../assets/image/deputy.png');
                break;
            case 5:
                img = require('../../assets/image/quit.png');
                break;
        }
        return img;
    }

    updateInfoClub = () => {
        let { isPress, inputEdit, modalType, clubInfo } = this.state;
        if (!isPress) {
            this.setState({ isPress: true });
            updateClubInfoApi(inputEdit, modalType).then(res => {
                if (res.code == 1) {
                    switch (modalType) {
                        case 1:
                            this.setState({
                                clubInfo: { ...clubInfo, ClubDesc: inputEdit },
                                isPress: false,
                                showModal: false
                            });
                            showNotification(res.message, res.code, '#notificationClubDesc');
                            break;
                        case 2:
                            this.setState({
                                clubInfo: { ...clubInfo, ClubMessage: inputEdit },
                                isPress: false,
                                showModal: false
                            });
                            showNotification(res.message, res.code, '#notificationClubMessage');
                            break;
                        case 3:
                            this.setState({
                                clubInfo: { ...clubInfo, Address: inputEdit },
                                isPress: false,
                                showModal: false
                            });
                            showNotification(res.message, res.code, '#notificationClubAddress');
                            break;
                    }
                }
                else {
                    showNotification(res.message, res.code, '#notificationErrorClubInfo');
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, '#notificationErrorClubInfo');
                this.setState({ isPress: false });
            })
        }
    }

    leaveClub = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            let idNotification = '#notificationErrorClubInfo';
            leaveClubApi().then(res => {
                if (res.code == 1) {
                    this.props.updateDataUser('ClubId', '');
                    setTimeout(() => {
                        this.props.history.push('/menu/club-list');
                    }, 2000);
                }
                else this.setState({ isPress: false });
                showNotification(res.message, res.code, idNotification);
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isPress: false });
            })
        }
    }

    showModalSelectMember = () => {
        this.setState({
            showModal: true,
            modalType: 4
        });
        getListMemberClubApi().then(res => {
            if (res.code == 1) this.setState({ listMemberClub: res.listMember });
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    selectDeputy = deputyInfo => {
        let { clubInfo, isPress } = this.state;
        if (!isPress) {
            this.setState({ isPress: true });
            selectDeputyClubApi(deputyInfo.UserId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        showModal: false,
                        isPress: false,
                        clubInfo: {
                            ...clubInfo,
                            DeputyInfo: {
                                FullName: deputyInfo.FullName,
                                FacebookId: deputyInfo.FacebookId,
                                UserId: deputyInfo.UserId,
                            }
                        }
                    })
                    showNotification(res.message, res.code, '#notificationClubDeputy');
                }
                else {
                    showNotification(res.message, res.code);
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    componentDidMount(){
        this.getClubInfo();
    }

    render() {
        let { clubInfo, userInfo, showModal, modalType, inputEdit, isPress, listMemberClub, isLoadingInfo } = this.state;
        if (isLoadingInfo) return <Loading small />;
        return (
            <>
                <div className='flex item-center mgb-2'>
                    <p className='bold text-title-gift'>{clubInfo.ClubName}</p>
                    <img
                        src={require('../../assets/image/quit.png')}
                        className='club-info-icon-leave'
                        onClick={() => this.setState({
                            showModal: true,
                            modalType: 5
                        })}
                    />
                </div>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 club-info-notify'>
                        <div>
                            <div className='flex-row item-center'>
                                <p className='text-large text-center bold'>Thông tin nhóm</p>
                                {['1', '2'].includes(userInfo.ClubRoleId) &&
                                    <img
                                        src={require('../../assets/image/pen.png')}
                                        className='club-info-icon-edit'
                                        onClick={() => this.setState({
                                            showModal: true,
                                            modalType: 1,
                                            inputEdit: clubInfo.ClubDesc
                                        })}
                                    />
                                }
                            </div>
                            <p className='text-large'>{clubInfo.ClubDesc}</p>
                            <div id='notificationClubDesc' />
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 club-info-notify'>
                        <div className='flex-row item-center'>
                            <p className='bold text-large text-center'>Thông báo từ nhóm</p>
                            {['1', '2'].includes(userInfo.ClubRoleId) &&
                                <img
                                    src={require('../../assets/image/pen.png')}
                                    className='club-info-icon-edit'
                                    onClick={() => this.setState({
                                        showModal: true,
                                        modalType: 2,
                                        inputEdit: clubInfo.ClubMessage
                                    })}
                                />
                            }
                        </div>
                        <p className='text-large'>{clubInfo.ClubMessage}</p>
                        <div id='notificationClubMessage' />
                    </div>
                </div>
                <div className='container-list-club-info mgt-0'>
                    <div className='flex-1 flex-end'>
                        <TooltipUser userInfo={clubInfo} avatarClass='avatar-guest' />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Chủ nhiệm</p>
                        <p className='text-large'>{clubInfo.FullName}</p>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <TooltipUser userInfo={clubInfo.DeputyInfo} avatarClass='avatar-guest' />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <div className='flex-row item-center'>
                            <p className='text-large bold'>Phó chủ nhiệm</p>
                            {userInfo.ClubRoleId == 1 &&
                                <img
                                    src={require('../../assets/image/pen.png')}
                                    className='club-info-icon-edit'
                                    onClick={this.showModalSelectMember}
                                />
                            }
                        </div>
                        <p className='text-large'>{clubInfo.DeputyInfo.FullName}</p>
                    </div>
                </div>
                <div id='notificationClubDeputy' />
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/star.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Cấp nhóm</p>
                        <div className='level-rank'>
                            <ClubVipLevel vipLevel={clubInfo.ClubVipLevel} className='star-club' />
                        </div>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/club-member.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Thành viên nhóm</p>
                        <p className='text-large'>{clubInfo.CountMember}/50</p>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/achievement.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Tổng thành tựu</p>
                        <p className='text-large'>{clubInfo.SumAchievement}</p>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/club-rank.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Hạng nhóm</p>
                        <p className='text-large'>{clubInfo.Rank}</p>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/club-user-cash.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Cống hiến cá nhân</p>
                        <p className='text-large'>{priceFormat(userInfo.UserFund)}</p>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/money.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <p className='text-large bold'>Quỹ nhóm</p>
                        <p className='text-large'>{priceFormat(clubInfo.ClubBalance)}</p>
                    </div>
                </div>
                <div className='container-list-club-info'>
                    <div className='flex-1 flex-end'>
                        <img
                            src={require('../../assets/image/location.png')}
                            className='club-info-image'
                        />
                    </div>
                    <div className='flex-3 flex-column item-center'>
                        <div className='flex-row item-center'>
                            <p className='text-large bold'>Địa điểm Offline</p>
                            {['1', '2'].includes(userInfo.ClubRoleId) &&
                                <img
                                    src={require('../../assets/image/pen.png')}
                                    className='club-info-icon-edit'
                                    onClick={() => this.setState({
                                        showModal: true,
                                        modalType: 3,
                                        inputEdit: clubInfo.Address
                                    })}
                                />
                            }
                        </div>
                        <p className='text-large'>{clubInfo.Address}</p>
                    </div>
                </div>
                <div id='notificationClubAddress' />
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                >
                    <div className='mgt item-center'>
                        <img
                            src={this.imageModal()}
                            className='club-info-image'
                        />
                    </div>
                    {[1, 2, 3, 4].includes(modalType) ?
                        <div className='mgt item-center'>
                            {modalType == 4 ? //chọn phó
                                <div>
                                    <p className='text-large text-center'>Chọn phó chủ nhiệm</p>
                                    {listMemberClub.map((item, key) =>
                                        <div
                                            key={key}
                                            className='club-info-list-member'
                                            onClick={() => this.selectDeputy(item)}
                                        >
                                            <div className='flex-1 item-center'>
                                                <img src={getAvatarFb(item.FacebookId)} className='avatar-guest' />
                                            </div>
                                            <div className='flex-4 item-center'>
                                                <p className='mgl-2 bold text-large'>{item.FullName}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                <div>
                                    <textarea
                                        value={inputEdit}
                                        onChange={e => this.setState({ inputEdit: e.target.value })}
                                        className='club-info-input-edit'
                                        placeholder='Nhập nội dung'
                                    />
                                    <Button
                                        className='bg-success mgt-2'
                                        onClick={this.updateInfoClub}
                                        loading={isPress}
                                    >
                                        Cập nhật
                                    </Button>
                                </div>
                            }
                        </div>
                        :
                        <div className='mgt item-center'>
                            <p className='text-huge danger bold'>Bạn muốn rời khỏi nhóm</p>
                            <p className='text-huge danger bold mgt-3'>Lưu ý: Sau 48h bạn mới có thể xin gia nhập một Club khác.</p>
                            <Button
                                className='bg-danger mgt-4'
                                onClick={this.leaveClub}
                                loading={isPress}
                            >
                                Rời khỏi
                            </Button>
                        </div>
                    }
                    <div className='mgt' id='notificationErrorClubInfo'></div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { updateDataUser })(withRouter(ClubInfo));