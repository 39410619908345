import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint, getCountMission} from '../../actions/home/';
import {getListItem, actionItem} from '../../actions/charge/market';
import {buyApi} from '../../api/charge/market';
import {MESSAGE, URI_PATH, PROP} from '../../utils/constants';
import Icon from 'react-fontawesome';
import {formatDecimal, replaceCost} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import bag from '../../assets/lottie/bag.json';
import HeaderLottie from '../../components/HeaderLottie';
import { Button, Tooltip, Modal } from 'antd';
import {withRouter} from 'react-router-dom';
import {showModalEat} from '../../actions/home/modalEat';
const ButtonGroup = Button.Group;

class ChargeMarket extends Component{
    constructor(props){
        super(props);
        this.state = {
            amountBuy: '',
            calculateBalance: '',
            showModal: false,
            itemInfo: {},
            isPress: false,
            isRefreshing: false
        };
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.props.selectItem) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    selectItem = (selectItem) =>{
        if(selectItem != this.props.selectItem) this.props.getListItem(selectItem);
    }

    changeAmount = e =>{
        let amountBuy = e.target.value?.replace(/,|\./g, '');
        this.setState({
            amountBuy: amountBuy,
            calculateBalance:  amountBuy > 0 ? formatDecimal(`${amountBuy * (replaceCost(this.state.itemInfo.Balance))}`) : '',
        });
    }

    buy = () =>{
        if(!this.state.isPress){
            let idNotification = '#notificationModalMarket';
            let chargemarketId = this.state.itemInfo.ChargemarketId;
            let amountBuy = this.state.amountBuy;
            if(amountBuy == '' || amountBuy <= 0) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            buyApi(chargemarketId, amountBuy).then(res =>{
                if(res.code == 1){
                    this.props.actionItem(chargemarketId, this.props.selectItem);
                    showNotificationBonus(res.balance, -1, 2);
                    showNotificationBonus(res.balanceZ, -1, 3);
                    this.props.changePoint([null, -res.balance, -res.balanceZ]);
                    this.setState({
                        showModal: false,
                        isPress: false,
                        amountBuy: '',
                        calculateBalance: ''
                    });
                    showNotification(res.message, res.code);
                    this.props.getCountMission();
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    closeModal = () =>{
        this.setState({
            showModal: false,
            calculateBalance: '',
            amountBuy: '',
            isPress: false
        })
    }

    renderItem = ()=>{
        let {selectItem, dataItem0, dataItem1, loadingItem0, loadingItem1} = this.props;
        let listItem = selectItem == 0 ? dataItem0 : dataItem1;
        if(selectItem == 0 && loadingItem0 == true || selectItem == 1 && loadingItem1 == true){
            return(
                <Loading small />
            )
        }
        return(
            <div className="row mgt-2">
                {listItem.map((item, key) =>
                    <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                        <div>
                            <Button
                                className='popover-item bg-success'
                                onClick={() => this.setState({ showModal: true, itemInfo: item })}
                            >
                                Mua
                            </Button>
                        </div>
                    }>
                        <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                            <div className='flex-column'>
                                {item.UserId > 0 && (
                                    <Icon name='tag' className='icon-own-item' />
                                )}
                                <div className='item-center'>
                                    <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                </div>
                                <p>{item.ItemName}</p>
                                <p>{item.Balance} đ[z]</p>
                            </div>
                        </div>
                    </Tooltip>
                )}
            </div>
        )
    }

    componentDidMount(){
        if(this.props.location.state){
            const { selectItem } = this.props.location.state;
            this.props.getListItem(selectItem);
        }
        else this.props.getListItem();
    }

    render(){
        let {showModal, itemInfo, amountBuy, calculateBalance, isPress} = this.state;
        return(
            <div>
                <HeaderLottie source={bag} />
                <ButtonGroup className='item-center' style={{display: 'flex', width: '100%'}}>
                    <Button className={this.buttonGroupClass(0)} onClick={() => this.selectItem(0)}>Đồ dùng thiết yếu</Button>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.selectItem(1)}>Đồ dùng cao cấp</Button>
                </ButtonGroup>
                {this.renderItem()}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={this.closeModal}
                >
                    <div>
                        <p className='success bold text-center text-huge'>Mua sắm</p>
                        <div className="row item-center flex-column">
                            <p className='success text-center text-large mgt'>{itemInfo.ItemName}</p>
                            <img className='item-img mgt' alt={itemInfo.ItemName} src={`${URI_PATH}${itemInfo.ItemImage}`} />
                            <p className='success bold text-center text-large mgt'>{formatDecimal(`${itemInfo.Balance} đ[z]`)}</p>
                            <div className="row item-center">
                                <div className='col-xs-6'>
                                    <p className='success text-center text-large mgt'>Số lượng</p>
                                    <input
                                        className='form-control border-success text-center mgt'
                                        value={`${amountBuy}`}
                                        placeholder="Nhập số lượng"
                                        onChange={this.changeAmount}
                                        onKeyDown={e => e.key === 'Enter' && this.buy()}
                                    />
                                </div>
                                <div className='col-xs-6'>
                                    <p className='success text-center text-large mgt'>Thành tiền</p>
                                    <input
                                        className='form-control border-success text-center mgt'
                                        value={calculateBalance ? `${calculateBalance} đ[z]` : ''}
                                        disabled
                                    />
                                </div>
                            </div>
                            {calculateBalance ? 
                                <p className='success text-center text-large bold mgt-2'>Hệ thống sẽ trừ: {calculateBalance} đ[z]</p>
                            : null }
                            <div className='mgt' id='notificationModalMarket'></div>
                            <Button
                                className='bg-success mgt-2'
                                loading={isPress}
                                onClick={this.buy}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { selectItem, dataItem0, dataItem1, loadingItem0, loadingItem1 } = state.getItemMarket;
    const { dataUser } = state.getUserData;
    return { selectItem, dataItem0, dataItem1, loadingItem0, loadingItem1, dataUser };
}

export default connect(mapStateToProps, {getListItem, changePoint, actionItem, showModalEat, getCountMission})(withRouter(ChargeMarket));