import React, { Component } from 'react';
import {MESSAGE, PROP} from '../../utils/constants';
import {connect} from 'react-redux';
import {getQuestionOwnApi, checkAddQuestionApi, getCategoryApi, addQuestionApi} from '../../api/question/own';
import { changePoint } from '../../actions/home/';
import {updateCountMission} from '../../actions/menu';
import {priceFormat} from '../../utils/common';
import {showNotification, showNotificationAsset} from '../../utils/notification';
import Loading from '../../components/Loading';
import { Button, Modal, Radio } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import {showModalEat} from '../../actions/home/modalEat';

class QuestionOwn extends Component {
    constructor(props){
        super(props);
        this.state = {
            questionList: [],
            createQuestionBonus: 0,
            isLoading: true,
            loadingFirstList: true,
            endList: false,
            isPress: false,
            showModal: false,
            listCategory: [],
            questionTitle: '',
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: '',
        };
        this.pageList = 1;
    }

    getQuestionOwn = (page = 1) =>{
        getQuestionOwnApi(page).then(res =>{
            this.setState({
                questionList: res.data.questionList,
                createQuestionBonus:  res.data.createQuestionBonus,
                isLoading: false,
                loadingFirstList: false,
            })
        }).catch(e => showNotification(MESSAGE.ERROR, -1));
    }

    checkAddQuestion = () =>{
        let {isPress, listCategory} = this.state;
        if(!isPress){
            let idNotification = '#notificationQuestionAdd';
            this.setState({isPress: true});
            checkAddQuestionApi().then(res=>{
                if(res.code == 1){
                    if(listCategory.length == 0){
                        getCategoryApi().then(res =>{
                            this.setState({
                                listCategory: res.listCategory,
                                showModal: true,
                                isPress: false
                            });
                        });
                    }
                    this.setState({
                        showModal: true,
                        isPress: false
                    });
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    addQuestion = () =>{
        if(!this.state.isPress){
            let {questionList, questionTitle, answer1, answer2, answer3, answer4} = this.state;
            let {dataUser} = this.props;
            let idNotification = '#notificationQuestionAddQueue';
            if(questionTitle && answer1 && answer2 && answer3 && answer4){
                this.setState({isPress: true});
                addQuestionApi(questionTitle, answer1, answer2, answer3, answer4).then(res =>{
                    if(res.code == 1){
                        //assets bonus
						showNotificationAsset(res.assetInfo);
                        //
                        showNotification(res.message, res.code);
                        this.props.updateCountMission('QuestionAddCount');
                        this.setState({
                            isPress: false,
                            showModal: false,
                            questionList: [
                                {
                                    QuestionId: 'Đang cập nhật',
                                    QuestionTitle: questionTitle,
                                    QuestionStatusId: 1,
                                    Answear1: answer1,
                                    Answear2: answer2,
                                    Answear3: answer3,
                                    Answear4: answer4,
                                    CrDateTime: 'Vừa xong',
                                    UserId: dataUser.UserId
                                },
                                ...questionList
                            ],
                        });
                    }
                    else{
                        if(res.code == -5) this.props.showModalEat(res.listFood);
                        else showNotification(res.message, res.code, idNotification);
                        this.setState({isPress: false});
                    }
                }).catch(e =>{
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({isPress: false});
                })
            }
            else if(!questionTitle) showNotification('Nội dung câu hỏi không được bỏ trống', -1, idNotification);
            else if(!answer1 || !answer2 || !answer3 || !answer4) showNotification('Đáp án không được bỏ trống', -1, idNotification);
            else showNotification(MESSAGE.ERROR, -1);
        }
    }

    loadMoreList = () => {
        let {endList, loadingFirstList} = this.state;
        if(!loadingFirstList && !endList){
            this.pageList += 1;
            getQuestionOwnApi(this.pageList).then(res =>{
                if(res.data.questionList.length > 0){
                    this.setState({
                        questionList: [...this.state.questionList, ...res.data.questionList],
                    });
                }
                else this.setState({endList: true})
            }).catch(error => showNotification(MESSAGE.ERROR, -1));
        }
    }

    hideModal = () => this.setState({showModal: false})

    componentDidMount(){
        this.getQuestionOwn();
    }

    render(){
        let {isLoading, questionList, endList, answer1, answer2, answer3, answer4} = this.state;
        if(isLoading) return (<Loading />);
        return(
            <div>
                <div className='item-center mgt'>
                    <img
                        src={require('../../assets/image/question-own.gif')}
                        style={{width: '15vh'}}
                    />
                </div>
                <div className='flex-column item-center'>
                    <p className='bold title-success'>
                        {this.props.countMission.QuestionAddCount > 0 ?
                            'Đóng góp 1 câu hỏi tiếng anh bạn sẽ vượt qua thử thách'
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                    {/* <p className='bold title-success mgt'>Mỗi câu hỏi được chấp thuận bạn được thưởng {priceFormat(createQuestionBonus)} đ[z].</p> */}
                    <div id='notificationQuestionAdd'></div>
                    <Button
                        className='bg-primary flash mgt'
                        onClick={this.checkAddQuestion}
                    >
                        Đóng góp đề
                    </Button>
                </div>
                <InfiniteScroll
                    loadMore={this.loadMoreList}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {questionList.map((item, key) =>(
                        <div className='container-list-question mgt' key={key}>
                            <div className='flex-row'>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Mã câu hỏi</p>
                                    <p className='text-center'>{item.QuestionId}</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Thời gian</p>
                                    <p className='text-center'>{item.CrDateTime}</p>
                                </div>
                            </div>
                            <p className='text-center success bold text-large mgt'>{item.QuestionTitle}</p>
                            <div className='flex-row mgt'>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote bg-success'>{item.Answear1}</p>
                                </div>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote'>{item.Answear2}</p>
                                </div>
                            </div>
                            <div className='flex-row mgt'>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote'>{item.Answear3}</p>
                                </div>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote'>{item.Answear4}</p>
                                </div>
                            </div>
                            <div className='flex-column item-center'>
                                <p className={'answer-vote ' + (item.QuestionStatusId == 2 ? 'bg-success' : 'bg-warning')}>
                                    {item.QuestionStatusId == 2 ? 'Đã chấp nhận' : 'Đang chờ duyệt'}
                                </p>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={this.hideModal}
                    centered
                    width='750px'
                >
                    <div className='row item-center mgt-2'>
                        <textarea
                            className='textarea-question-add'
                            placeholder='Nội dung câu hỏi'
                            value={this.state.questionTitle}
                            onChange={e => this.setState({questionTitle: e.target.value})}
                        />
                        <input className='form-control mgt flash border-success' placeholder='Đáp án đúng' value={answer1} onChange={e => this.setState({answer1: e.target.value})}/>
                        <input className='form-control mgt' placeholder='Đáp án sai 1' value={answer2} onChange={e => this.setState({answer2: e.target.value})}/>
                        <input className='form-control mgt' placeholder='Đáp án sai 2' value={answer3} onChange={e => this.setState({answer3: e.target.value})}/>
                        <input className='form-control mgt' placeholder='Đáp án sai 3' value={answer4} onChange={e => this.setState({answer4: e.target.value})}/>
                        <div id='notificationQuestionAddQueue'></div>
                        <Button
                            className='bg-success mgt'
                            loading={this.state.isPress}
                            onClick={this.addQuestion}
                            style={{width: '15vh'}}
                        >
                            Gửi
                        </Button>
                    </div>
            </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { dataUser, countMission };
}

export default connect(mapStateToProps, {changePoint, updateCountMission, showModalEat})(QuestionOwn);