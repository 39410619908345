import React, { Component } from 'react';
import { Modal, Button, Switch } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../../../components/Loading';
import { formatDecimal, getAvatarFb, priceFormat, replaceCost } from '../../../utils/common';
import { getListStudentSupportApi } from '../../../api/admin/study/support';
import StudySummary from '../../../screens/study/StudySummary';
import SupportDetail from './SupportDetail';
import Checked from '../../frame/Checked';
import { changePriceApi, getPricePayApi } from '../../../api/admin/study/ticket';
import { TitleSuccess } from '../../frame';
const TYPE_ZTICKET = {
    TICKET_VND: 0,
    TICKET_DZ: 1,
    TICKET_PER_DAY: 2
}

class TicketConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            ticketVnd: 0,
            ticketDz: 0,
            ticketPerday: 0,
            typeChange: TYPE_ZTICKET.TICKET_VND,
            isLoading: true
        }
    }

    getPriceTicket = () => {
        getPricePayApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    ticketVnd: formatDecimal(res.ticketVnd),
                    ticketDz: formatDecimal(res.ticketDz),
                    ticketPerday: formatDecimal(res.ticketPerday),
                    isLoading: false
                })
            }
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    changeBalancePay = (e) => {
        const value = e.target.value;
        const {typeChange} = this.state;
        if(typeChange === TYPE_ZTICKET.TICKET_VND){
            this.setState({ ticketVnd: formatDecimal(value) });
        }
        if(typeChange === TYPE_ZTICKET.TICKET_DZ){
            this.setState({ ticketDz: formatDecimal(value) });
        }
        if(typeChange === TYPE_ZTICKET.TICKET_PER_DAY){
            this.setState({ ticketPerday: formatDecimal(value) });
        }
    }

    changePrice = () => {
        if (!this.state.isPress) {
            const {typeChange, ticketDz, ticketPerday, ticketVnd} = this.state;
            let ticketValue;
            if(typeChange === TYPE_ZTICKET.TICKET_VND){
                ticketValue = replaceCost(ticketVnd, false);
            }
            if(typeChange === TYPE_ZTICKET.TICKET_DZ){
                ticketValue = replaceCost(ticketDz, false);
            }
            if(typeChange === TYPE_ZTICKET.TICKET_PER_DAY){
                ticketValue = replaceCost(ticketPerday, false);
            }
            changePriceApi(ticketValue, typeChange).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0)
                this.setState({ isPress: false })
            });
        }
    }

    componentDidMount() {
        this.getPriceTicket()
    }

    render() {
        const { isLoading, ticketVnd, ticketDz, ticketPerday } = this.state;
        return (
            <>
                {isLoading ? <Loading small />
                    :
                    <div className='item-center mgt-2'>
                        <p>Gía mua: (vnđ/ Zitcket)</p>
                        <input
                            className='form-control col-md-4 col-sm-6 col-xs-12'
                            value={ticketVnd}
                            onChange={this.changeBalancePay}
                            onBlur={this.changePrice}
                            onFocus={() => this.setState({typeChange: TYPE_ZTICKET.TICKET_VND})}
                        />
                        <p>Gía mua: (đz/ Zitcket)</p>
                        <input
                            className='form-control col-md-4 col-sm-6 col-xs-12'
                            value={ticketDz}
                            onChange={this.changeBalancePay}
                            onBlur={this.changePrice}
                            onFocus={() => this.setState({typeChange: TYPE_ZTICKET.TICKET_DZ})}
                        />
                        <p>Giá Gold[z] tăng mỗi ngày</p>
                        <input
                            className='form-control col-md-4 col-sm-6 col-xs-12'
                            value={ticketPerday}
                            onChange={this.changeBalancePay}
                            onBlur={this.changePrice}
                            onFocus={() => this.setState({typeChange: TYPE_ZTICKET.TICKET_PER_DAY})}
                        />
                    </div>
                }
            </>
        )
    }
}

export default TicketConfig