import React from 'react';

export default (props) =>(
    <div className="intercom-composer-popover animated fadeIn faster" onMouseLeave={props.onEndPick} style={props.style}>
        <div className="intercom-emoji-picker">
            <div className="intercom-composer-popover-body-container">
                <div className="intercom-composer-popover-body">
                    <div className="intercom-emoji-picker-groups" onClick={props.onPick}>
                        <div className="intercom-emoji-picker-group">
                            <div className="intercom-emoji-picker-group-title">Biểu cảm</div>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😭</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👿</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😿</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">😾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">✨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">✊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">✋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🙇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👣</span>
                        </div>
                        <div className="intercom-emoji-picker-group">
                            <div className="intercom-emoji-picker-group-title">Thiên nhiên</div>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐭</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🐾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌿</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌊</span>
                        </div>
                        <div className="intercom-emoji-picker-group">
                            <div className="intercom-emoji-picker-group-title">Đối tượng</div>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💿</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⏳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⌛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⏰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⌚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🛀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📋</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">👾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🃏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🀄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⚽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⚾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎿</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎂</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍭</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍊</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🍅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌽</span>
                        </div>
                        <div className="intercom-emoji-picker-group">
                            <div className="intercom-emoji-picker-group-title">Nơi ở</div>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌆</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏭</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🗼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🗾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🗻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🗽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🌉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚀</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚉</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚄</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚇</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚨</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💈</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚏</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚥</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🗿</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎭</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📍</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚩</span>
                        </div>
                        <div className="intercom-emoji-picker-group">
                            <div className="intercom-emoji-picker-group-title">Biểu tượng</div>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔢</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔣</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔡</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔤</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔽</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⏪</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⏩</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⏫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⏬</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🎦</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈁</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🉐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚼</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚾</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚭</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🈸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🉑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🚫</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔞</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">❎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">✅</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💟</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">📴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🆎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💠</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⛎</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🏧</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💹</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">❌</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">❗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">❓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">❕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">❔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⭕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔝</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔃</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕐</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕑</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕒</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕓</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕔</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕙</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🕚</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">➕</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">➖</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">➗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💮</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">💯</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔘</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔗</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">➰</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔱</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔺</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔲</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔳</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔴</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔵</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔻</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⬜</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">⬛</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔶</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔷</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔸</span>
                            <span role='img' aria-label='' className="intercom-emoji-picker-emoji">🔹</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)