import React from 'react';
import {Tooltip} from 'antd';
import {isTouch} from '../../utils/common';

export default (props) =>(
    <Tooltip
        overlayClassName='black-tooltip'
        trigger={isTouch() ? 'click' : 'hover'}
        title={
            <p className='text-center'>{props.titleTooltip}</p>
        }
    >
        {props.children}
    </Tooltip>
)