import {WALLPAPER} from '../../utils/constants';
import Cookies from 'js-cookie';
import {KEY} from '../../utils/constants';

const INITAL_STATE = {
    wallpaperData: WALLPAPER[Cookies.get(KEY.WALLPAPER) || 1],
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'CHANGE_WALLPAPER':
            if(action.wallpaperType > 0){
                Cookies.set(KEY.WALLPAPER, action.wallpaperType, { expires: 999999 });
                return{
                    wallpaperData: WALLPAPER[action.wallpaperType],
                }
            }
            return {
                ...state
            };
        default:
            return state;
    }
};