import React, {Component} from 'react';
import {connect} from 'react-redux';
import{ getListCart } from '../../actions/charge/sell';
import delivery from '../../assets/lottie/delivery.json';
import HeaderLottie from '../../components/HeaderLottie';
import ListCart from '../../components/charge/ListCart';
import Loading from '../../components/Loading';
import {URI_PATH, MESSAGE, PROP} from '../../utils/constants';
import {getCartImageApi} from '../../api/charge/sell';
import {Button, Tooltip} from 'antd';
import { showNotification } from '../../utils/notification';
import { getItemInCart } from '../../actions/charge/sell';
import { getUserItem } from '../../actions/home';
import ShowModalCart from '../../components/charge/ShowModalCart';
import ShowModalAddItem from '../../components/charge/ShowModalAddItem';
const ButtonGroup = Button.Group;

class ChargeSell extends Component{
    constructor(props){
        super(props);
        this.state = {
            cartImage: false,
            isLoading: true,
            itemInfo: {},
            modalAddType: '',
            showModalCart: false,
            showModalAddItem: false
        }
    }

    getCartImage = () =>{
        getCartImageApi().then(res =>{
            if(res.code == 1){
                let cartImage = res.cartImage;
                this.setState({
                    cartImage,
                    isLoading: false
                });
                if(cartImage) this.props.getUserItem();
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.props.selectItem) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    getUserItem = (selectItem) =>{
        if(selectItem !== this.props.selectItem) this.props.getUserItem(selectItem);
    }

    renderListItem = () =>{
        let {selectItem, dataItem0, dataItem1, loadingItem0, loadingItem1} = this.props;
        if(
            selectItem == 0 && loadingItem0 == true ||
            selectItem == 1 && loadingItem1 == true
        ) return (<Loading small />)
        if(selectItem == 0 && dataItem0 && dataItem0.length > 0){
            return (
                <div className="row mgt">
                    {dataItem0.map((item, key) =>
                        <div
                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                            key={key}
                            onClick={() => this.setState({ showModalAddItem: true, modalAddType: 1, itemInfo: item })}
                        >
                            <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                            <p className='mgt'>{item.Amount}</p>
                            <p>{item.FoodName}</p>
                            <p>{item.Hour} giờ</p>
                            <div id={`notificationEat${item.UserfoodId}`}/>
                        </div>
                    )}
                </div>
            )
        }
        else if(selectItem == 1 && dataItem1 && dataItem1.length > 0){
            return(
                <div className="row mgt">
                    {dataItem1.map((item, key) =>
                        <div
                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                            key={key}
                            onClick={() => this.setState({ showModalAddItem: true, modalAddType: 2, itemInfo: item })}
                        >
                            <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                            <p className='mgt'>{item.Amount}</p>
                            <p>{item.AssetName}</p>
                        </div>
                    )}
                </div>
            )
        }
        return null;
    }

    renderOwnCart = () =>{
        let {cartImage, showModalCart, showModalAddItem, modalAddType, itemInfo} = this.state;
        return(
            <div>
                <div className='charge-sell-container'>
                    <p className='text-large'>Cửa hàng của tôi</p>
                    <Tooltip trigger='hover' title={
                        <div style={{width: '100%'}}>
                            <Button
                                className='popover-cart bg-success'
                                onClick={() => this.openCart(1)}
                            >
                                Giỏ ẩm thực
                            </Button>
                            <Button
                                className='popover-cart bg-warning'
                                onClick={() => this.openCart(2)}
                            >
                                Giỏ đồ dùng
                            </Button>
                        </div>
                    }>
                        <img className="cart-image mgt" src={`${URI_PATH}${cartImage}`}/>
                    </Tooltip>
                    <ButtonGroup className='item-center mgt-2' style={{width: '100%'}}>
                        <Button className={this.buttonGroupClass(0)} onClick={() => this.getUserItem(0)}>Nhà bếp</Button>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.getUserItem(1)}>Nhà kho</Button>
                    </ButtonGroup>
                    {this.renderListItem()}
                </div>
                <ShowModalCart
                    isVisible={showModalCart} 
                    onRenewCart= {cartImage => this.setState({cartImage})}
                    closeDisplay={() => this.setState({showModalCart: false})}
                />
                <ShowModalAddItem
                    isVisible={showModalAddItem} 
                    modalType={modalAddType}
                    itemInfo={itemInfo}
                    hideModal={() => this.setState({showModalAddItem: false})}
                />
            </div>
        )
    }

    renderNoCart = () =>{
        this.props.getListCart();
        return(
            <div className='charge-sell-container'>
                <p className='text-large'>Cửa hàng</p>
                <ListCart onRenewCart= {cartImage => this.setState({cartImage})} />
            </div>
        )
    }

    openCart = selectCart =>{
        this.props.getListCart();
        this.props.getItemInCart(selectCart);
        this.setState({showModalCart: true});
    }

    componentDidMount(){
        this.getCartImage();
    }

    render(){
        let {cartImage, isLoading} = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                <HeaderLottie
                    source={delivery}
                />
                {cartImage ? this.renderOwnCart() : this.renderNoCart()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { selectItem, dataItem0, dataItem1, loadingItem0, loadingItem1 } = state.getUserItem;
    return { selectItem, dataItem0, dataItem1, loadingItem0, loadingItem1 };
}

export default connect(mapStateToProps, { getListCart, getUserItem, getItemInCart })(ChargeSell);