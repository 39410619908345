import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeWallpaper } from '../actions/config';

export const STYLE_WALLPAPER = {
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}

class WallpaperRoot extends Component {

    render() {
        const isWallpaperImage = (/url/).test(this.props.wallpaperData);
        const style = isWallpaperImage ? {
            backgroundImage: this.props.wallpaperData,
            ...STYLE_WALLPAPER
        }
            :
            { background: this.props.wallpaperData }
        return (
            <div
                className='flex-1'
                style={style}
            >
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { wallpaperData } = state.getWallpaper;
    return { wallpaperData };
}

export default connect(mapStateToProps, { changeWallpaper })(WallpaperRoot);