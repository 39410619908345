import React, { Component } from 'react';
import { connect } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown-now';
import { Button, Tooltip, Switch } from 'antd';
import {showNotification} from '../../utils/notification';
import {eatFoodApi, deleteFoodPartyApi, getBuySellRemainApi, getChartInvestApi, useAssetApi} from '../../api/home';
import { getUserItem, getResourceCook, getUserBar, changePoint, updateDataUser } from '../../actions/home/';
import {MESSAGE, URI_PATH, PROP} from '../../utils/constants';
import Loading from '../Loading';
import ShowModalItem from './ShowModalItem';
import {Link, withRouter} from 'react-router-dom';
import { formatDecimal } from '../../utils/common';
import Lottie from 'react-lottie';
import money from '../../assets/lottie/money.json';
import bag from '../../assets/lottie/bag.json';
import asset from '../../assets/lottie/asset.json';
import food from '../../assets/lottie/food.json';
import {getListItem} from '../../actions/charge/market';
import queryString from 'query-string';
const ButtonGroup = Button.Group;

class ListItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            modalType: false,
            itemInfo: false,
            isPress: false,
            sellRemain: 0,
            investType: 1
        };
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem == this.props.selectItem || (this.props.selectItem == 5 && selectItem == 2)) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    eatFood = (userfoodId) =>{
        if(this.state.isPress === false){
            this.setState({isPress: true});
            let idNotification = `#notificationEat${userfoodId}`;
            eatFoodApi(userfoodId).then(res => {
                this.setState({isPress: false});
                if(res.code == 1) {
                    this.props.getUserItem(0);
                    showNotification(res.message, res.code, idNotification);
                }
                else showNotification(res.message, res.code, idNotification);
            }).catch(error =>{
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({isPress: false});
            })
        }
    }

    deleteFoodParty = (userfoodpartyId) => {
        if(this.state.isPress === false){
            this.setState({isPress: true});
            let idNotification = '#notificationParty';
            deleteFoodPartyApi(userfoodpartyId).then(res => {
                this.setState({isPress: false});
                if(res.code == 1) {
                    this.props.getUserItem(0, true);
                    showNotification(res.message, res.code, idNotification);
                }
                else showNotification(res.message, res.code, idNotification);
            }).catch(error => showNotification(MESSAGE.ERROR, -1, idNotification));
        }
    }

    openSellInvest = (item) =>{
        if(this.state.isPress === false){
            let idNotification = `#notificationInvest${item.ChargemarketId}`;
            getBuySellRemainApi(item.ChargemarketId, 2).then(res => {
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        itemInfo: item,
                        sellRemain: res.data,
                        showModal: true,
                        modalType: 4
                    });
                }
                else{
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({isPress: false});
                }
                
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    openChartInvest = (item) =>{
        let idNotification = `#notificationInvest${item.ChargemarketId}`;
        if(!this.state.isPress){
            this.setState({isPress: true});
            getChartInvestApi(item.ChargemarketId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        showModal: true,
                        modalType: 6,
                        balanceData: [{
                            name: item.ItemName,
                            data: res.balanceData
                        }],
                        timeData: {
                            xaxis: {
                                categories: res.timeData
                            }
                        },
                        itemInfo: item
                    })
                }
                else{
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({isPress: false});
                }
                this.setState({isPress: false});
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    openCook = () =>{
        if(this.props.listResource.length == 0) this.props.getResourceCook();
        this.setState({
            showModal: true,
            modalType: 7
        });
    }

    useAsset = (assetId) =>{
        if(!this.state.isPress){
            this.setState({isPress: true})
            useAssetApi(assetId).then(res =>{
                if(res.code == 1){
                    let dataUser = this.props.dataUser;
                    this.props.changePoint([null, null, null, res.achievement - dataUser.Achievement]);
                    this.props.getUserBar();
                    this.props.getUserItem(1);
                    this.props.updateDataUser('UserVip', res.userVip)
                }
                else showNotification(res.message, res.code);
                this.setState({isPress: false});
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false})
            });
        }
    }
    
    componentListItem = () =>{
        let {
            selectItem,
            loadingItem0,
            loadingItem1,
            loadingItem2,
            loadingItem3,
            loadingItem4,
            loadingItem5,
            lifeExpire,
            dataUser,
            dataItem0,
            dataItem1,
            dataItem2,
            dataItem3,
            dataItem4,
            dataItem5,
            balanceAll
        } = this.props;
        if(
            selectItem == 0 && loadingItem0 == true ||
            selectItem == 1 && loadingItem1 == true ||
            selectItem == 2 && loadingItem2 == true ||
            selectItem == 3 && loadingItem3 == true ||
            selectItem == 4 && loadingItem4 == true ||
            selectItem == 5 && loadingItem5 == true
        ) return (<Loading small />);
        if (selectItem == 0) {
            return (
                <div>
                    {lifeExpire &&(
                        <div className="row mgt item-center">
                            <div className='div-life'>
                                <img src={require('../../assets/image/food.png')} className='enegy-life-icon' />
                                <Countdown 
                                    date={lifeExpire * 1000}
                                    renderer={({ days, hours, minutes, seconds}) =>(<span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                />
                            </div>
                        </div>
                    )}
                    {dataUser &&( 
                        <div className="row mgt item-center">
                            <Button
                                className='button-cook flash'
                                type="primary"
                                onClick={this.openCook}
                                loading={this.state.isPress}
                            >
                                Nấu ăn & giải cứu linh thú
                            </Button>
                        </div>
                    )}
                    {dataItem0 && dataItem0.length > 0 && (
                        <div className="row mgt">
                            {dataItem0.map((item, key) =>
                                <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                                    <div>
                                        <Button
                                            className='popover-item bg-success'
                                            onClick={() => this.eatFood(item.UserfoodId)}
                                        >
                                            Ăn ngay
                                        </Button>
                                        <Button
                                            className='popover-item bg-black'
                                            onClick={() => this.setState({ showModal: true, modalType: 2, itemInfo: item })}
                                        >
                                            Mời tiệc
                                        </Button>
                                    </div>
                                }>
                                    <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                        <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                        <p className='mgt'>{item.Amount}</p>
                                        <p>{item.FoodName}</p>
                                        <p>{item.Hour} giờ</p>
                                        <div id={`notificationEat${item.UserfoodId}`}/>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <div
                        className='pointer item-center flex-column'
                        onClick={() => this.props.history.push('/menu/charge-food')}
                    >
                        <Lottie
                            options={{loop: true, autoplay: true, animationData: food}}
                            style={{width: '25vh'}}
                        />
                        <Button
                            className='button-cook flash'
                            type="primary"
                        >
                            Đi chợ
                        </Button>
                    </div>
                </div>
            );
        }
        else if (selectItem == 1) {
            return (
                <div>
                    {dataItem1 && dataItem1.length > 0 && (
                        <div className="row mgt">
                            {dataItem1.map((item, key) =>
                                <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                                    <div>
                                        <Link to="/home/upgrade">
                                            <Button className='popover-item bg-success'>Nâng cấp</Button>
                                        </Link>
                                        {(item.AssetId <= 60 || [71, 72, 73, 74, 75].includes(+item.AssetId)) &&
                                            <Button
                                                className='popover-item bg-warning'
                                                onClick={() => this.useAsset(item.AssetId)}
                                            >
                                                Sử dụng
                                            </Button>
                                        }
                                    </div>
                                }>
                                    <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                        <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                                        <p className='mgt'>{item.Amount}</p>
                                        <p>{item.AssetName}</p>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <div
                        className='pointer item-center flex-column'
                        onClick={() => this.props.history.push('/menu/charge-asset')}
                    >
                        <Lottie
                            options={{loop: false, autoplay: true, animationData: asset}}
                            style={{width: '25vh'}}
                        />
                        <Button
                            className='button-cook flash'
                            type="primary"
                        >
                            Sắm thêm
                        </Button>
                    </div>
                </div>
            );
        }
        else if (selectItem == 2 || selectItem == 5) {
            let listItem = selectItem == 2 ? dataItem2 : dataItem5;
            let balanceName = selectItem == 2 ? 'đ[z]' : '$[z]';
            return (
                <div>
                    <div className='item-center'>
                        <select
                            className='form-control col-md-4 col-sm-4 col-xs-6 mgt'
                            onChange={e => this.getUserItem(e.target.value)}
                            value={selectItem}
                        >
                            <option value='2'>Chứng khoán đ[z]</option>
                            <option value='5'>Chứng khoán $[z]</option>
                        </select>
                    </div>
                    {balanceAll > 0 && (
                        <div className="row mgt item-center">
                            <div className='div-balance-all bg-success'>
                                <p>{formatDecimal(balanceAll)} {balanceName}</p>
                            </div>
                        </div>
                    )}
                    {listItem && listItem.length > 0 && (
                        <div className="row mgt">
                            {listItem.map((item, key) =>
                                <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                                    <div>
                                        <Button
                                            className='popover-item bg-warning'
                                            onClick={() => this.openSellInvest(item)}
                                            loading={this.state.isPress}
                                        >
                                            Bán ngay
                                        </Button>
                                        <Button
                                            className='popover-item bg-success'
                                            onClick={() => this.openChartInvest(item)}
                                            loading={this.state.isPress}
                                        >
                                            Biểu đồ
                                        </Button>
                                    </div>
                                }>
                                    <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                        <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                        <p className='mgt'>{item.Amount}</p>
                                        <p>{item.ItemName}</p>
                                        <p className={item.PercentColor == 1 ? 'success' : 'danger'}>{item.PercentBonusNew} %</p>
                                        <p className={item.BalanceColor == 1 ? 'success' : 'danger'}>{item.BalanceChange} {balanceName}</p>
                                        <div id={`notificationInvest${item.ChargemarketId}`}/>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <div
                        className='pointer item-center flex-column'
                        onClick={() => this.props.history.push('/menu/charge-invest')}
                    >
                        <Lottie
                            options={{loop: true, autoplay: true, animationData: money}}
                            style={{width: '25vh'}}
                        />
                        <Button
                            className='button-cook flash'
                            type="primary"
                        >
                            Đầu tư thêm
                        </Button>
                    </div>
                </div>
            );
        }
        else if (selectItem == 3) {
            return (
                <div>
                    {balanceAll > 0 && (
                        <div className="row mgt item-center">
                            <div className='div-balance-all bg-success'>
                                <p>+{balanceAll} thành tựu / ngày</p>
                            </div>
                        </div>
                    )}
                    {dataItem3 && dataItem3.length > 0 && (
                        <div className="row mgt">
                            {dataItem3.map((item, key) =>
                                <div key={key} className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                    <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                    <p className='mgt'>{item.ItemName}</p>
                                    <p>{item.DayRemain} ngày</p>
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className='pointer item-center flex-column'
                        onClick={() => this.props.history.push('/menu/charge-market')}
                    >
                        <Lottie
                            options={{loop: true, autoplay: true, animationData: bag}}
                            style={{width: '25vh'}}
                        />
                        <Button
                            className='button-cook flash'
                            type="primary"
                        >
                            Sắm thêm
                        </Button>
                    </div>
                </div>
            );
        }
        else if (selectItem == 4) {
            return (
                <div>
                    {balanceAll > 0 && (
                        <div className="row mgt item-center">
                            <div className='div-balance-all bg-success'>
                                <p>{formatDecimal(balanceAll)} đ[z]</p>
                                <p>+{Math.round(balanceAll / (1000000 + Math.floor(balanceAll / 1000000000) * 100000) * 100) / 100} thành tựu / ngày</p>
                            </div>
                        </div>
                    )}
                    {dataItem4 && dataItem4.length > 0 && (
                        <div className="row mgt">
                            {dataItem4.map((item, key) =>
                                <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                                    <div>
                                        <Button
                                            className='popover-item bg-warning'
                                            onClick={() => this.setState({ showModal: true, modalType: 5, itemInfo: item })}
                                        >
                                            Bán
                                        </Button>
                                    </div>
                                }>
                                    <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                        <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                        <p>{item.ItemName}</p>
                                        <p>{item.BalanceReal} đ[z]</p>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <div
                        className='pointer item-center flex-column'
                        onClick={() =>{
                            this.props.history.push('/menu/charge-market', { selectItem: 1 });
                        }}
                    >
                        <Lottie
                            options={{loop: true, autoplay: true, animationData: bag}}
                            style={{width: '25vh'}}
                        />
                        <Button
                            className='button-cook flash'
                            type="primary"
                        >
                            Sắm thêm
                        </Button>
                    </div>
                </div>
            );
        }
    }

    getUserItem = (selectItem) =>{
        if(selectItem !== this.props.selectItem) this.props.getUserItem(selectItem);
    }

    componentDidMount(){
        const tab = queryString.parse(this.props.location.search)?.tab;
        if(tab){
            this.getUserItem(tab)
        }
    }

    render(){
        let {listFoodParty} = this.props;
        return(
            <div>
                {listFoodParty && listFoodParty.length > 0 && (
                    <div>
                        <div className='flex-column item-center mgt'>
                            <p className='bold text-title-gift'>Tiệc mời ăn</p>
                        </div>
                        <div className="row mgt">
                            {listFoodParty.map((item, key) =>
                                <div key={key} className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                    <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                    <p>{item.Amount}</p>
                                    <p>{item.FoodName}</p>
                                    <p>{item.Hour} giờ</p>
                                    <img onClick={() => this.deleteFoodParty(item.UserfoodpartyId)} className='delete-image' alt='delete-food-party' src={require('../../assets/image/delete.png')} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div id='notificationParty'/>
                <div className="row mgt">
                    <ButtonGroup className='item-center flex' style={{width: '100%'}}>
                        <Button className={this.buttonGroupClass(0)} onClick={() => this.getUserItem(0)}>Nhà bếp</Button>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.getUserItem(1)}>Nhà kho</Button>
                        <Button className={this.buttonGroupClass(2)} onClick={() => this.getUserItem(2)}>Két sắt</Button>
                        <Button className={this.buttonGroupClass(3)} onClick={() => this.getUserItem(3)}>Phòng ngủ</Button>
                        <Button className={this.buttonGroupClass(4)} onClick={() => this.getUserItem(4)}>Phòng khách</Button>
                    </ButtonGroup>
                </div>
                {this.componentListItem()}
                <ShowModalItem
                    isVisible={this.state.showModal} 
                    modalType={this.state.modalType} 
                    itemInfo={this.state.itemInfo}
                    sellRemain={this.state.sellRemain}
                    balanceData={this.state.balanceData}
                    timeData={this.state.timeData}
                    hideModal={() => this.setState({showModal: false})}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { selectItem, dataItem0, dataItem1, dataItem2, dataItem3, dataItem4, dataItem5, loadingItem0, loadingItem1, loadingItem2, loadingItem3, loadingItem4, loadingItem5, listFoodParty, balanceAll, lifeExpire } = state.getUserItem;
    const { dataUser } = state.getUserData;
    const { listResource } = state.getResourceCook;
    return { selectItem, dataItem0, dataItem1, dataItem2, dataItem3, dataItem4, dataItem5, loadingItem0, loadingItem1, loadingItem2, loadingItem3, loadingItem4, loadingItem5, listFoodParty, balanceAll, lifeExpire, dataUser, listResource };
}

export default connect(mapStateToProps, { getUserItem, getResourceCook, getUserBar, changePoint, getListItem, updateDataUser })(withRouter(ListItem));