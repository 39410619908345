import Authencation from './Authencation';
import Rank from './Rank';
import Privacy from './Privacy';
import Newfeed from './Newfeed';
import Question from './Question';
import Guide from './Guide';
import AffLobby from './AffLobby';

export default {
    Authencation,
    Rank,
    Privacy,
    Newfeed,
    Question,
    AffLobby,
    Guide
}