import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { Button, Modal } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import { showModalEat } from '../../actions/home/modalEat';
import { withRouter } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown-now';
import moment from 'moment';
import { buyBuffApi, getDataBuffApi } from '../../api/newbie';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { formatDecimal } from '../../utils/common';

class ButtonNewbie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            dataBuff: {},
        }
    }

    getDataBuff = () =>{
        getDataBuffApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    dataBuff: res.data
                })
            }
        })
    }

    componentDidMount(){
        this.getDataBuff();
    }

    buyBuff = () =>{
        if (!this.state.isPress){
            this.setState({ isPress: true });
            buyBuffApi().then(res =>{
                if(res.code == 1){
                    this.setState({
                        showModal: false,
                        dataBuff: {
                            ...this.state.dataBuff,
                            Expire: res.data
                        }
                    })
                    showNotificationBonus(res.cost, -1, 3);
                    this.props.changePoint([null, null, -res.cost]);
                }
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            });
        }
    }

    renderModal = () =>{
        const { dataUser } = this.props;
        const { dataBuff } = this.state;
        return(
            <div className='flex-column item-center'>
                <img
                    src={require('../../assets/image/star.gif')}
                    className='newbie-modal-star'
                />
                <p className='text-huge bold'>Ngôi sao may mắn</p>
                <p className='mgt-2'>Tăng sức mạnh SOLO</p>
                <p>khi giải cứu linh thú & phá phong ấn trong 24h</p>
                <p className='mgt-2'>Giá VIP {dataUser.UserVip}: {formatDecimal(dataBuff?.cost || 9999999999)} đ[z]</p>
                <Button
                    className='bg-success button-buy-newbie mgt-3'
                    onClick={this.buyBuff}
                    loading={this.state.isPress}
                >Mua</Button>
            </div>
        )
    }

    render() {
        const { dataBuff } = this.state;
        return (
            <>
                <div className='button-newbie flex item-center' onClick={() => this.setState({showModal: true})}>
                    <img
                        src={require('../../assets/image/star.gif')}
                        className='newbie-star'
                    />
                    <Countdown
                        date={moment(dataBuff?.Expire).toDate()}
                        renderer={({ days, hours, minutes, seconds }) => (
                        <span className='bold mgl' style={{top: '1vh'}}>
                            {+days > 0 ? `${days}d ` : ''}{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                        </span>)}
                    />
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    destroyOnClose={true}
                    centered
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, showModalEat, })(withRouter(ButtonNewbie));