import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {updateCountMission} from '../../actions/menu';
import {getUserItemApi} from '../../api/home';
import {getDataSpinApi, getHintApi, actionSpinApi} from '../../api/gift/spin';
import { MESSAGE, URI_PATH} from '../../utils/constants';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Countdown, { zeroPad } from 'react-countdown-now';
import Loading from '../../components/Loading';
import gift3 from '../../assets/lottie/gift3.json';
import play from '../../assets/lottie/play.json';
import machine from '../../assets/lottie/machine.json';
import HeaderLottie from '../../components/HeaderLottie';
import {Button} from 'antd';
import {formatDecimal } from '../../utils/common';
import { getUserItem } from '../../actions/home/';
import Lottie from 'react-lottie';
import {socket} from '../../components/realtime/ReadSocketEvent';
import {showModalEat} from '../../actions/home/modalEat';
const ButtonGroup = Button.Group;

class GiftSpin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSpinAble: false,
            maxCash: '',
            minCash: '',
            spinCash: '',
            isOpen: '',
            countPercent: '',
            balanceRandom: false,
            selectItemSpin: [],
            listItemFood: [],
            listItemAsset: [],
            loadingItemFood: true,
            loadingItemAsset: true,
            selectItem: '',
            isSpinClick: false,
            isOpenSpin: new Date().getHours() < 21,
            timeRemain: 0
        }
        this.listFoodSelect = [];
        this.listAssetSelect = [];
    }

    getDataSpin = () =>{
        getDataSpinApi().then(res =>{
            let {isSpin, maxCash, minCash, spinCash, isOpen, countPercent, timeRemain} = res.data;
            this.setState({
                isLoading: false,
                isSpinAble: isSpin,
                maxCash,
                minCash,
                spinCash,
                isOpen,
                countPercent,
                timeRemain
            })
        })
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.state.selectItem) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    getUserItem = (selectItem) =>{
        let{loadingItemFood, loadingItemAsset} = this.state;
        if(
            (selectItem == 0 && loadingItemFood) ||
            (selectItem == 1  && loadingItemAsset)
        ){
            getUserItemApi(selectItem).then(res =>{
                if(res.code == 1){
                    if(selectItem == 0){
                        this.setState({
                            listItemFood: res.listItem,
                            loadingItemFood: false,
                            selectItem: selectItem
                        })
                    }
                    else{
                        this.setState({
                            listItemAsset: res.listItem,
                            loadingItemAsset: false,
                            selectItem: selectItem
                        })
                    }
                }
                else showNotification(res.message, res.code)
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0)
            })
        }
        else this.setState({selectItem})
    }

    actionItemSpin = (item, action) =>{
        let {selectItemSpin, listItemAsset, listItemFood} = this.state;
        if(action == 1){ // pick
            if(item.AssetId){
                this.listAssetSelect.push({
                    AssetId: item.AssetId,
                    Amount: item.Amount
                });
                this.setState({
                    selectItemSpin: [...selectItemSpin, item],
                    listItemAsset: listItemAsset.filter(el => {
                        return el.AssetId !== item.AssetId;
                    })
                })
            }
            else{
                this.listFoodSelect.push({
                    FoodId: item.FoodId,
                    Amount: item.Amount
                });
                this.setState({
                    selectItemSpin: [...selectItemSpin, item],
                    listItemFood: listItemFood.filter(el => {
                        return el.FoodId !== item.FoodId;
                    })
                })
            }
        }
        else{ // remove
            if(item.AssetId){
                this.listAssetSelect = this.listAssetSelect.filter(el => { return el.AssetId != item.AssetId; }); 
                this.setState({
                    listItemAsset: [item, ...listItemAsset],
                    selectItemSpin: selectItemSpin.filter(el => {
                        return el.AssetId !== item.AssetId;
                    })
                })
            }
            else{
                this.listFoodSelect = this.listFoodSelect.filter(el => { return el.FoodId != item.FoodId; }); 
                this.setState({
                    listItemFood: [item, ...listItemFood],
                    selectItemSpin: selectItemSpin.filter(el => {
                        return el.FoodId !== item.FoodId;
                    })
                })
            }
        }
        getHintApi(this.listFoodSelect, this.listAssetSelect).then(res =>{
            this.setState({
                countPercent: res.countPercent,
                maxCash: res.maxCash
            })
        });
    }

    renderListItem = () =>{
        let {selectItem, listItemFood, listItemAsset, loadingItemFood, loadingItemAsset} = this.state;
        if(
            selectItem == 0 && loadingItemFood == true ||
            selectItem == 1 && loadingItemAsset == true
        ) return <Loading small />;
        if(selectItem == 0){
            return(
                <div className="row mgt">
                    {listItemFood.map((item, key) =>
                        <div
                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                            key={key}
                            onClick={() => this.actionItemSpin(item, 1)}
                        >
                            <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                            <p className='mgt'>{item.Amount}</p>
                            <p>{item.FoodName}</p>
                        </div>
                    )}
                </div>
            )
        }
        else if(selectItem == 1){
            return(
                <div className="row mgt">
                    {listItemAsset.map((item, key) =>
                        <div
                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                            key={key}
                            onClick={() => this.actionItemSpin(item, 1)}
                        >
                            <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                            <p className='mgt'>{item.Amount}</p>
                            <p>{item.AssetName}</p>
                        </div>
                    )}
                </div>
            )
        }
    }

    spin = () =>{
        if(!this.state.isSpinClick){
            this.setState({isSpinClick: true});
            actionSpinApi(this.listFoodSelect, this.listAssetSelect).then(res =>{
                if(res.code == 1){
                    let{balanceRandom, spinCash} = res;
                    this.props.changePoint([null, null, -spinCash]);
                    showNotificationBonus(spinCash, -1, 3);
                    new Audio(require('../../assets/audio/robot.mp3')).play();
                    setTimeout(() => {
                        this.setState({
                            isSpinClick: false,
                            balanceRandom: balanceRandom
                        });
                        if(balanceRandom > 0) {
                            showNotificationBonus(balanceRandom, 1, 2);
                            this.props.changePoint([null, balanceRandom]);
                            this.props.updateCountMission('GiftSpinCount');
                            //realtime
                                if(res.notify != ''){
                                    socket.emit('fetch_gift_spin', res.notify);
                                }
                            //
                        }
                    }, 5600);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code);
                    this.setState({isSpinClick: false});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isSpinClick: false});
            })
        }
    }

    resetSpin = () =>{
        this.setState({
            isLoading: true,
            isSpinAble: false,
            maxCash: '',
            minCash: '',
            spinCash: '',
            isOpen: '',
            countPercent: '',
            balanceRandom: false,
            selectItemSpin: [],
            isSpinClick: false,
            isOpenSpin: new Date().getHours() < 21,
            timeRemain: 0
        })
        this.listFoodSelect = [];
        this.listAssetSelect = [];
        this.getDataSpin();
    }

    renderResult = () =>{
        let {isSpinClick, balanceRandom} = this.state;
        if(isSpinClick){
            return(
                <div className='flex item-center' style={{minHeight: '32vh'}}>
                    <img
                        src={require('../../assets/image/create.gif')}
                        style={{width: '30vh'}}
                    />
                </div>
            )
        }
        else if(balanceRandom > 0){
            return(
                <div className='flex-column item-center' style={{minHeight: '32vh'}}>
                    <p className='text-cash-gift-spin'>{formatDecimal(balanceRandom.toString())}</p>
                    <p className='text-cash-gift-spin'>$[z]</p>
                </div>
            )
        }
        else return(
            <>
                <div className='flex item-center' style={{minHeight: '32vh'}}>
                    <p className='text-cash-gift-spin'>Chúc bạn may mắn lần sau!</p>
                </div>
                <div className='item-center'>
                    <Button
                        className='bg-success mgt-3'
                        onClick={this.resetSpin}
                    >
                        Mở lại quà thần bí
                    </Button>
                </div>
            </>
        )
    }

    componentDidMount(){
        this.getDataSpin();
        this.getUserItem(0);
    }

    render() {
        let {isLoading, minCash, maxCash, spinCash, isOpen, isSpinAble, isSpinClick, balanceRandom, countPercent, selectItemSpin, isOpenSpin, timeRemain} = this.state;
        if(isLoading) return(<Loading />);
        return (
            <div>
                <HeaderLottie source={machine} size='40vh' />
                <div className='flex-column item-center'>
                    <p className='bold title-success mgb'>
                        {this.props.countMission.GiftSpinCount > 0 ?
                            `Kết hợp thành công 1 gói Quà thần bí bạn sẽ vượt qua thử thách`
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                    {isOpenSpin ?
                        <>
                            {timeRemain == 0 ? 
                                <p className='title-event-question mgb'>Quà thần bí đang diễn ra</p>
                            :
                                <p className='title-event-question mgb'>
                                    Quà thần bí sẽ bắt đầu sau
                                    <Countdown 
                                        date={timeRemain * 1000}
                                        renderer={({ days, hours, minutes, seconds}) =>(<span> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                        onComplete={() => this.setState({isOpenSpin: false})}
                                    />
                                </p>
                            }
                        </>
                    :
                        <p className='title-event-question mgb'>
                            Quà thần bí đã kết thúc
                        </p>
                    }
                </div>
                <div className='box-gift-spin'>
                    {!isSpinClick && balanceRandom === false ?
                        <div>
                            <p className='text-center text-huge'>Kết hợp</p>
                            <div className='row mgt-2'>
                                {selectItemSpin.map((item, key) =>{
                                    if(item.AssetId) return(
                                        <div
                                            key={key}
                                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                                            onClick={() => this.actionItemSpin(item, 2)}
                                        >
                                            <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                                            <p className='mgt'>{item.Amount}</p>
                                            <p>{item.AssetName}</p>
                                        </div>
                                    )
                                    return(
                                        <div
                                            key={key}
                                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                                            onClick={() => this.actionItemSpin(item, 2)}
                                        >
                                            <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                            <p className='mgt'>{item.Amount}</p>
                                            <p>{item.FoodName}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <hr width="95%" align="center" className='hr-default mgt' />
                            <Lottie options={{loop: true, autoplay: true, animationData: gift3}}
                                height='22vh'
                                width='22vh'
                            />
                            <p className='text-center text-large'>Giá trị {formatDecimal(minCash)} $[z] ~ {formatDecimal(maxCash)} $[z]</p>
                            <p className='text-center text-large mgt'>Tỉ lệ thành công {countPercent}%</p>
                            {isOpen ?
                                <p className='text-center success text-gigantic mgt-3'>Hẹn gặp lại vào ngày mai!</p>
                            :
                                <p
                                    className={'text-center text-large mgt-3 ' + (isSpinAble == 0 && 'danger')}
                                >
                                    Yêu cầu: {formatDecimal(spinCash)} đ[z] và VIP 1 trở lên
                                </p>
                            }
                            {isSpinAble == 1 && !isOpen &&
                                <div className='flex item-center'>
                                    <div
                                        className='mgt-2 pointer'
                                        onClick={this.spin}
                                    >
                                        <Lottie options={{loop: true, autoplay: true, animationData: play}}
                                            height='10vh'
                                            width='10vh'
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    :
                        <div>
                            {this.renderResult()}
                        </div>
                    }
                </div>
                <div className='mgt'>
                    <ButtonGroup className='item-center' style={{display: 'flex', width: '100%'}}>
                        <Button className={this.buttonGroupClass(0)} onClick={() => this.getUserItem(0)}>Nhà bếp</Button>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.getUserItem(1)}>Nhà kho</Button>
                    </ButtonGroup>
                    {this.renderListItem()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { dataUser, countMission };
}

export default connect(mapStateToProps, {changePoint, getUserItem, updateCountMission, showModalEat})(GiftSpin);