import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { updateCountNotify } from '../../actions/menu';
import { getListVideoApi, actionVoteApi, getVoteRemainApi } from '../../api/video/vote';
import { MESSAGE } from '../../utils/constants';
import { randomNumber, getYoutubeId, isTouch, getTiktokId } from '../../utils/common';
import { showNotification, showNotificationAsset, showNotificationBonus } from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import SearchUser from '../../components/SearchUser';
import { getCategory, getSubCategory } from '../../actions/category/';
import { Button, Tooltip } from 'antd';
import Countdown, { zeroPad } from 'react-countdown-now';
import { withRouter } from 'react-router'
import queryString from 'query-string';
import TooltipUser from '../../components/TooltipUser';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import { showModalEat } from '../../actions/home/modalEat';
import BarSelect from '../../components/frame/BarSelect';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: {},
            isEmpty: false,
            voteRemain: 0,
            isEndIntervalVote: false,
            isPress: false,
        };
        //check url parameter
        this.directVideo = '';
        let videoId = queryString.parse(this.props.location.search).id;
        if (videoId > 0) this.directVideo = videoId;
        //
        this.num = 0;
        this.categoryId = '';
        this.subCategoryId = '';
        this.searchUser = '';
        this.timeIntervalVote = '';
    }

    getVideo = () => {
        let { num, categoryId, subCategoryId, searchUser, videoType, directVideo } = this;
        getListVideoApi(num, categoryId, subCategoryId, searchUser, videoType, directVideo).then((res) => {
            if (res.code == 1) {
                //interval Diamond
                this.timeIntervalVote = Date.now() + 15000;
                //
                this.setState({
                    data: res.data,
                    isLoading: false,
                    isEndIntervalVote: false,
                    isEmpty: false
                });
                window.history.replaceState({}, '', '/menu/video-vote?id=' + res.data.dataVideo.VideoqueueId);
            }
            else this.setState({ isEmpty: true, isLoading: false })
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    getVoteRemain = () => {
        getVoteRemainApi().then(res => {
            if (res.code == 1) {
                this.setState({ voteRemain: res.voteRemain })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMoreVideo = (type) => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        let nextMax = parseInt(this.state.data.countVideo);
        if (this.directVideo > 0) this.directVideo = '';
        switch (type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() => {
            this.getVideo();
        }, 200)
    }

    renderVote = () => {
        let { data, isEndIntervalVote, voteRemain, isPress } = this.state;
        if (!data.isVote && voteRemain > 0) {
            if (isEndIntervalVote) {
                return (
                    <div>
                        <Tooltip
                            overlayClassName='pv-tooltip-diamond'
                            trigger={isTouch() ? 'click' : 'hover'}
                            title={
                                <div className='flex-column'>
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/check.png')}
                                            className='pv-vote-img'
                                        />
                                    </div>
                                    <div className='mgl'>
                                        <div className='flex-row align-center mgt'>
                                            <Icon name='check' className='mgr' />
                                            <p>Nội dung đăng tải có ích với cộng đồng khởi nghiệp.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Nội dung trong video không vi phạm pháp luật nhà nước Việt Nam.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Video không xúc phạm, gây mất uy tín danh dự người khác.</p>
                                        </div>
                                    </div>
                                    <div className='item-center'>
                                        <Button
                                            className='bg-success mgt-2'
                                            onClick={() => this.actionVote(data.dataVideo.VideoqueueId, 2)}
                                            loading={isPress}
                                        >
                                            Bỏ phiếu
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <img
                                src={require('../../assets/image/check.png')}
                                className='pv-vote-img'
                            />
                        </Tooltip>
                        <Tooltip
                            overlayClassName='pv-tooltip-diamond'
                            trigger={isTouch() ? 'click' : 'hover'}
                            title={
                                <div className='flex-column'>
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/uncheck.png')}
                                            className='pv-vote-img'
                                        />
                                    </div>
                                    <div className='mgl'>
                                        <div className='flex-row align-center mgt'>
                                            <Icon name='times' className='mgr' />
                                            <p>Nội dung đăng tải không có ích với cộng đồng khởi nghiệp.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='times' className='mgr' />
                                            <p>Nội dung trong video vi phạm pháp luật nhà nước Việt Nam.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='times' className='mgr' />
                                            <p>Video xúc phạm, gây mất uy tín danh dự người khác.</p>
                                        </div>
                                    </div>
                                    <div className='item-center'>
                                        <Button
                                            className='bg-danger mgt-2'
                                            onClick={() => this.actionVote(data.dataVideo.VideoqueueId, 1)}
                                            loading={isPress}
                                        >
                                            Bỏ phiếu
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <img
                                src={require('../../assets/image/uncheck.png')}
                                className='pv-vote-img'
                            />
                        </Tooltip>
                    </div>
                )
            }
            return (
                <div>
                    <Countdown
                        date={this.timeIntervalVote}
                        renderer={({ days, hours, minutes, seconds }) => (
                            <span className='text-large bold mgl-2 color-white'>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
                        )}
                        onComplete={() => this.setState({ isEndIntervalVote: true })}
                    />
                </div>
            )
        }
        return null;
    }

    renderNavigate = () => (
        <BarSelect>
            <Icon
                name='caret-left'
                className='pv-navigate'
                style={{ margin: '0vh 1vh' }}
                onClick={() => this.loadMoreVideo('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{ fontSize: '4vh', margin: '0vh 1vh' }}
                onClick={() => this.loadMoreVideo('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                style={{ margin: '0vh 1vh' }}
                onClick={() => this.loadMoreVideo('next')}
            />
            {this.renderVote()}
        </BarSelect>
    )

    actionVote = (videoqueueId, isVote) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            actionVoteApi(videoqueueId, isVote).then(res => {
                if (res.code == 1) {
                    showNotification(res.message, res.code);
                    showNotificationAsset(res.assetInfo);
                    showNotificationBonus(res.balance, 1, 2);
                    this.props.changePoint([null, res.balance]);
                    this.props.updateCountNotify('VideoVoteCount');
                    this.setState({ voteRemain: this.state.voteRemain - 1 });
                    this.num = this.num > 0 ? this.num - 1 : 0;
                    this.directVideo = '';
                    this.getVideo();
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code);
                }
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    changeCategory = (value) => {
        if (this.directVideo > 0) this.directVideo = '';
        this.num = 0;
        this.categoryId = value;
        if (this.categoryId) this.props.getSubCategory(this.categoryId);
        else this.subCategoryId = '';
        this.getVideo();
    }

    deleteSearch = e => {
        if (e.key == 'Backspace' && this.searchUser) {
            this.refs.searchUser.getInstance().clear();
            this.searchUser = '';
            this.num = 0;
            this.getVideo();
        }
    }

    searchUserVideo = data => {
        if (this.directVideo > 0) this.directVideo = '';
        this.searchUser = data[0].name;
        this.num = 0;
        this.getVideo();
    }

    componentDidMount() {
        this.getVoteRemain();
        this.getVideo();
        if (this.props.categoryPost.length == 0) this.props.getCategory(1);
    }

    render() {
        let { isLoading, data, isEmpty } = this.state;
        let { categoryPost, subCategory } = this.props;
        let dataVideo = data.dataVideo;
        if (isLoading) return <Loading />;
        return (
            <div style={{ paddingBottom: '8vh' }}>
                {/* <div className='flex item-center'>
                    <p className='bold title-success'>
                        {this.state.voteRemain > 0 ?
                            `Bỏ phiếu ${this.state.voteRemain} video bạn sẽ vượt qua thử thách`
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div> */}
                <div className='pv-search-container row'>
                    <div className='col-md-4 col-sm-4 col-xs-12'>
                        <SearchUser
                            ref='searchUser'
                            onChange={this.searchUserVideo}
                            onKeyDown={this.deleteSearch}
                            placeholder='🔎 Họ tên / Số điện thoại'
                        />
                    </div>
                    <div className={'col-md-8 col-sm-8 col-xs-6'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryPost.map((item, key) => (
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {isEmpty ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không còn video cần phê duyệt</p>
                    </div>
                    :
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        <div className='flex-row mgt-2'>
                            <div className='flex-row align-center'>
                                <TooltipUser userInfo={dataVideo} avatarClass='avatar-guest' key={dataVideo.UserId} />
                                <div className='pv-user-container'>
                                    <p className='bold'>{dataVideo.FullName}</p>
                                    <p className='placeholder black'>{dataVideo.CrDateTime}</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex-column'>
                            <p className='text-large bold text-center'>{dataVideo.CategoryName}</p>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <p className='text-large text-center bold mgt'>{dataVideo.VideoTitle}</p>
                            <div className='flex-row flex-3 item-center mgb mgt'>
                                <p className='pv-check-p mgb-0' style={{ background: 'rgba(0,0,0,0.85)' }}>Tôi chia sẻ video này vì: {dataVideo.VideoDesc}</p>
                            </div>
                            <div className='item-center mgt'>
                            {dataVideo.VideoType == 2 ?
                                <>
                                    <div className='iframe-tiktok'>
                                        <iframe
                                            scrolling="no"
                                            name="__tt_embed__v3802385945065390"
                                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                                            src={`https://www.tiktok.com/embed/v2/${getTiktokId(dataVideo.VideoUrl)}?lang=vi&amp;`}
                                        />
                                    </div>
                                </>
                                :
                                <iframe
                                    key={dataVideo.VideoqueueId}
                                    className='iframe-video'
                                    src={"https://www.youtube.com/embed/" + getYoutubeId(dataVideo.VideoUrl)}
                                    frameBorder="0"
                                    allow="encrypted-media"
                                    allowFullScreen="allowFullScreen"
                                />
                            }

                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost } = state.getCategory;
    return { dataUser, categoryPost };
}

export default connect(mapStateToProps, { changePoint, getCategory, getSubCategory, updateCountNotify, showModalEat })(withRouter(Video));