import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListStudyPeopleChatApi } from '../../api/study/chat';
import { getAvatarFb, isMaster } from '../../utils/common';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import { actionChatStudy } from '../../actions/chat';
import LessonChat from '../study/componentChildren/LessonChat';
import { Modal } from 'antd';
import { getListLessonDoingOrPassApi, getListLessonMasterApi } from '../../api/study/lesson';
import LessonShow from '../study/componentChildren/LessonShow';
import Icon from 'react-fontawesome';
import Checked from '../frame/Checked';
import Loading from '../Loading';
import TooltipUser from '../TooltipUser';

class StudyChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listPeople: [],
            isLoading: true,
            showModal: false,
            modalType: '',
            studyStudentId: '',
            isLoadingLesson: true,
            listLessonShow: [],
            isFromMaster: true
        }
    }

    getListStudyPeopleChat() {
        getListStudyPeopleChatApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listPeople: res.listPeople
                })
            }
            else showNotification(MESSAGE.ERROR, 0)
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    showChat = (studyStudentId) => {
        this.setState({
            showModal: true,
            modalType: 1,
            studyStudentId,
            listPeople: this.state.listPeople.map(item =>{
                if(item.StudyStudentId == studyStudentId){
                    const ListUserIdReadChat = item.ListUserIdReadChat || [];
                    ListUserIdReadChat.push(this.props.dataUser.UserId);
                    return {
                        ...item,
                        ListUserIdReadChat
                    }
                }
                return item;
            })
        })
    }

    renderModal(){
        const {modalType, studyStudentId, listLessonShow, isLoadingLesson} = this.state;
        switch (modalType) {
            case 1:
                return(
                    <>
                        <p className='mgt-2 text-center text-large'>Hỗ trợ bài làm mã số {studyStudentId}</p>
                        <LessonChat dataLesson={{StudyStudentId: studyStudentId}} />
                    </>
                )
            case 2:
                return(
                    <div className='mgt-2'>
                        <LessonShow
                            listLesson={listLessonShow}
                            isLoading={isLoadingLesson}
                            onRefresh={() => this.showModalLesson(this.state.studyStudentId)}
                            type={isMaster ? 3 : 2}
                        />
                    </div>
                )
        }
        return null;
    }

    showModalLesson = (studyStudentId, isFromMaster) => {
        this.setState({
            showModal: true,
            isLoadingLesson: true,
            studyStudentId,
            modalType: 2,
            isFromMaster
        })
        if (isFromMaster) {
            getListLessonMasterApi(1, 1, studyStudentId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isLoadingLesson: false,
                        listLessonShow: res.lessons
                    })
                }
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(e => showNotification(MESSAGE.ERROR, 0));
        }
        else {
            getListLessonDoingOrPassApi(2, studyStudentId, this.props.dataUser.UserId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        listLessonShow: res.lessons,
                        isLoadingLesson: false
                    })
                }
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(e => showNotification(MESSAGE.ERROR, 0));
        }
    }

    componentDidMount() {
        this.getListStudyPeopleChat();
    }

    render() {
        const {dataUser} = this.props;
        const { listPeople, showModal, modalType, isLoading } = this.state;
        return (
            <>
                {isLoading &&
                    <Loading small />
                }
                {listPeople.map((item, key) => {
                    const isUnRead = item.ListUserIdReadChat && !item.ListUserIdReadChat.includes(dataUser.UserId);
                    return (
                        <div
                            key={key}
                            className={`flex-row chat-people-list ${(isUnRead && 'bg-success')}`}
                            onClick={() => this.showChat(item.StudyStudentId)}
                        >
                            <TooltipUser
                                userInfo={item}
                                
                            />
                            <div className='flex-column chat-people-info'>
                                <span>{item.FullName}<Checked data={item} /></span>
                                <span><u>{item.LessonTitle}</u></span>
                                <span className='placeholder'>{item.CrDateTime}</span>
                            </div>
                            <Icon
                                name='file-text-o'
                                className='pointer'
                                style={{fontSize: '4vh'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.showModalLesson(item.StudyStudentId, dataUser.UserId != item.UserId )
                                }}
                            />
                        </div>
                    )
                })}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={modalType == 2 ? '120vh' : PROP.MODAL.width}
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { selectItem } = state.getListChat;
    const { dataUser } = state.getUserData;
    return { selectItem, dataUser };
}

export default connect(mapStateToProps, {
    actionChatStudy
})(StudyChat);