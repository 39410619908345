import Main from './Main';
import MainMenu from './MainMenu';
import StudySummary from './StudySummary';
import StudyLearn from './StudyLearn';
import StudySubject from './StudySubject';
import StudyClass from './StudyClass';

export default {
    Main,
    MainMenu,
    StudySummary,
    StudyLearn,
    StudySubject,
    StudyClass
}