import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ changePoint } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getListCourseApi, getListPromotionApi, createPromotionApi, deletePromotionApi, viewPromotionApi} from '../../api/course/promotion';
import { showNotification } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import { Button, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { priceFormat, formatDecimal, replaceCost } from '../../utils/common';
const curDay = new Date().getDate();
const curMonth = new Date().getMonth() + 1;
const curYear = new Date().getFullYear();

class CourseList extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            isPressView: false,
            isPressDelete: false,
            listCourse: [],
            listPromotion: [],
            listPromotionCode: [],
            showModal: false,
            endList: false,
            courseId: '',
            inputBalance: '',
            inputAmount: 1,
            beginDay: curDay,
            beginMonth: curMonth,
            beginYear: curYear,
            endDay: curDay,
            endMonth: curMonth,
            endYear: curYear,
        }
        this.page = 1;
    }

    getListCourse = () =>{
        getListCourseApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    listCourse: res.listCourse,
                    isLoading: false
                });
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    getListPromotion = () =>{
        getListPromotionApi(this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listPromotion: this.page == 1 ? res.listPromotion : [...this.state.listPromotion, ...res.listPromotion],
                    endList: res.listPromotion == 0
                });
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () =>{
        this.page += 1;
        this.getListPromotion();
    }

    changeBalance = e => {
        let balance = e.target.value;
        this.setState({inputBalance: formatDecimal(balance)});
    }

    createPromotion = () =>{
        if(!this.state.isPress){
            let{
                courseId,
                inputBalance,
                inputAmount,
                beginDay,
                beginMonth,
                beginYear,
                endDay,
                endMonth,
                endYear,
            } = this.state;
            let idNotification = '#notificationCreatePromotion';
            inputBalance = replaceCost(inputBalance, false);
            if(courseId > 0 && inputBalance > 0 && inputAmount > 0){
                let beginDate = beginYear + '-' + beginMonth + '-' + beginDay;
                let endDate = endYear + '-' + endMonth + '-' + endDay;
                this.setState({isPress: true});
                createPromotionApi(courseId, inputAmount, inputBalance, beginDate, endDate).then(res =>{
                    if(res.code == 1){
                        this.setState({
                            courseId: '',
                            inputBalance: '',
                            inputAmount: 1,
                            beginDay: curDay,
                            beginMonth: curMonth,
                            beginYear: curYear,
                            endDay: curDay,
                            endMonth: curMonth,
                            endYear: curYear,
                            isPress: false
                        });
                        this.page = 1;
                        this.getListPromotion();
                    }
                    else this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                })
            }
            else if(!courseId) return showNotification('Vui lòng chọn khoá học', 0, idNotification);
            else if(!inputBalance || inputBalance <= 0) return showNotification('Số $[z] giảm giá không nhỏ hơn 0 và không để trống', 0, idNotification);
            else if(!inputAmount || inputAmount <= 0) return showNotification('Số lượng không nhỏ hơn 0 và không để trống', 0, idNotification);
        }
    }

    deletePromotion = coursePromotionId =>{
        if(!this.state.isPressDelete){
            this.setState({isPressDelete: coursePromotionId});
            let idNotification = '#notificationPromotionAction' + coursePromotionId;
            deletePromotionApi(coursePromotionId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPressDelete: false,
                        listPromotion: this.state.listPromotion.filter(el =>
                            { return el.CoursePromotionId != coursePromotionId; }
                        )
                    })
                    showNotification(res.message, res.code);
                }
                else{
                    this.setState({isPressDelete: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                this.setState({isPressDelete: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    viewPromotion = coursePromotionId =>{
        if(!this.state.isPressView){
            this.setState({isPressView: coursePromotionId});
            viewPromotionApi(coursePromotionId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPressView: false,
                        listPromotionCode: res.listPromotionCode,
                        showModal: true
                    });
                }
                else{
                    this.setState({isPressView: false});
                    showNotification(res.message, res.code);
                }
            }).catch(e =>{
                this.setState({isPressView: false});
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    componentDidMount(){
        this.getListCourse();
        this.getListPromotion();
    }

    render(){
        let {
            isLoading,
            listCourse,
            listPromotion,
            listPromotionCode,
            courseId,
            inputBalance,
            inputAmount,
            beginDay,
            beginMonth,
            beginYear,
            endDay,
            endMonth,
            endYear,
            isPress,
            isPressDelete,
            isPressView,
            endList,
            showModal
        } = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                {listCourse.length == 0 ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large mgt'>Bạn chưa tạo khoá học nào</p>
                    </div>
                :
                    <div>
                        <div className='box-primary item-center'>
                            <div className='flex-column item-center'>
                                <p className='bold title-success'>Tạo mã ưu đãi</p>
                            </div>
                            <p className='mgt'>Khoá học:</p>
                            <div className='row item-center'>
                                <div className='col-md-6 col-sm-8 col-xs-12'>
                                    <select
                                        className='form-control'
                                        value={courseId}
                                        onChange={e => this.setState({courseId: e.target.value})}
                                    >
                                        <option value=''>Chọn khoá học</option>
                                        {listCourse.map((item, key) =>
                                            <option value={item.CourseId} key={key}>{item.CourseName}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <p className='mgt'>Giảm giá ($[z]):</p>
                            <div className='row item-center'>
                                <div className='col-md-6 col-sm-8 col-xs-12'>
                                    <input
                                        className='form-control'
                                        value={inputBalance}
                                        onChange={this.changeBalance}
                                        placeholder='Nhập số $[z] giảm giá'
                                    />
                                </div>
                            </div>
                            <p className='mgt'>Số lượng:</p>
                            <div className='row item-center'>
                                <div className='col-md-6 col-sm-8 col-xs-12'>
                                    <input
                                        className='form-control'
                                        value={inputAmount}
                                        onChange={e => this.setState({inputAmount: e.target.value})}
                                        placeholder='Nhập số lượng'
                                    />
                                </div>
                            </div>
                            <p className='mgt'>Hiệu lực từ:</p>
                            <div className='row item-center'>
                                <div className='col-md-6 col-sm-8 col-xs-12'>
                                    <div className='row'>
                                        <div className='col-xs-4'>
                                            <select
                                                className='form-control'
                                                value={beginDay}
                                                onChange={e => this.setState({beginDay: e.target.value})}
                                            >
                                                {new Array(31).fill(undefined).map((item, key) =>(
                                                    <option value={key+1} key={key+1}>Ngày {key+1}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-xs-4'>
                                            <select
                                                className='form-control'
                                                value={beginMonth}
                                                onChange={e => this.setState({beginMonth: e.target.value})}
                                            >
                                                {new Array(12).fill(undefined).map((item, key) =>(
                                                    <option value={key+1} key={key+1}>Tháng {key+1}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-xs-4'>
                                            <select
                                                className='form-control'
                                                value={beginYear}
                                                onChange={e => this.setState({beginYear: e.target.value})}
                                            >
                                                <option value={curYear}>Năm {curYear}</option>
                                                <option value={curYear + 1}>Năm {curYear + 1}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className='mgt'>đến:</p>
                            <div className='row item-center'>
                                <div className='col-md-6 col-sm-8 col-xs-12'>
                                    <div className='row'>
                                        <div className='col-xs-4'>
                                            <select
                                                className='form-control'
                                                value={endDay}
                                                onChange={e => this.setState({endDay: e.target.value})}
                                            >
                                                {new Array(31).fill(undefined).map((item, key) =>(
                                                    <option value={key+1} key={key+1}>Ngày {key+1}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-xs-4'>
                                            <select
                                                className='form-control'
                                                value={endMonth}
                                                onChange={e => this.setState({endMonth: e.target.value})}
                                            >
                                                {new Array(12).fill(undefined).map((item, key) =>(
                                                    <option value={key+1} key={key+1}>Tháng {key+1}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-xs-4'>
                                            <select
                                                className='form-control'
                                                value={endYear}
                                                onChange={e => this.setState({endYear: e.target.value})}
                                            >
                                                <option value={curYear}>Năm {curYear}</option>
                                                <option value={curYear + 1}>Năm {curYear + 1}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='item-center mgt'>
                                <div className='mgt' id='notificationCreatePromotion' />
                                <Button
                                    className='bg-success'
                                    loading={isPress}
                                    onClick={this.createPromotion}
                                >
                                    Xác nhận
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {listPromotion.length > 0 &&
                    <div className='box-primary mgt'>
                        <div className='flex-column item-center'>
                            <p className='bold title-success'>Mã ưu đãi đã tạo</p>
                        </div>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small/>}
                            threshold={50}
                        >
                        {listPromotion.map((item, key) =>
                            <div className='box-primary mgt' key={key}>
                                <p className='text-large mgt'>{item.CourseName}</p>
                                <p className='mgt'>Số lượng: {item.Quantity}</p>
                                <p className='mgt'>Giảm giá: {priceFormat(item.DiscountCost)} $[z]</p>
                                <p className='mgt'>
                                    Hiệu lực từ {item.BeginDate} đến {item.EndDate}
                                </p>
                                <div className='flex row mgt'>
                                    <Button
                                        className='bg-success'
                                        loading={isPressView == item.CoursePromotionId}
                                        onClick={() => this.viewPromotion(item.CoursePromotionId)}
                                    >
                                        Xem mã ưu đãi
                                    </Button>
                                    <Button
                                        className='bg-danger mgl'
                                        loading={isPressDelete == item.CoursePromotionId}
                                        onClick={() => this.deletePromotion(item.CoursePromotionId)}
                                    >
                                        Xoá
                                    </Button>
                                </div>
                            </div>
                        )}
                        </InfiniteScroll>
                    </div>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({showModal: false})}
                >
                    <div className='mgt-2'>
                        <div className='flex-column item-center mgb'>
                            <p className='bold title-success'>Mã ưu đãi</p>
                        </div>
                        {listPromotionCode.map((item, key) =>
                            <div className='box-primary' key={key}>
                                <p className='mgt'>Mã ưu đãi: {item.PromotionCode}</p>
                                <p className='mgt'>Trạng thái: {item.StatusName}</p>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(CourseList);