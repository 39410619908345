import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectFacebookApi } from '../../../api/facebook'
import { updateDataUser, getUserBar, changePoint } from '../../../actions/home';
import { Button, Modal } from 'antd';
import { KEY, MESSAGE, PROP } from '../../../utils/constants';
import { showNotification, showNotificationBonus } from '../../../utils/notification';
import facebook from '../../../assets/lottie/facebook.json';
import HeaderLottie from '../../../components/HeaderLottie';
import { getCrestApi } from '../../../api/menu';
import ItemMenu from '../../../components/menu/ItemMenu';
import city from '../../../assets/lottie/city.json';
import { TitleSuccess } from '../../../components/frame';
import { withRouter } from 'react-router';
import { isTeacher, isMaster } from '../../../utils/common';

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isPress: false
        }
    }

    post = [
        { name: 'Đăng bài viết', image: require('../../../assets/image/menu/publish-post.png'), url: '/study/post-publish', mission: 'PostPublishCount' },
        { name: 'Bài viết của tôi', image: require('../../../assets/image/menu/post-mine.png'), url: '/study/post-mine', mission: 'PostVerifyCount' },
        { name: 'Xét duyệt', image: require('../../../assets/image/menu/post-vote.png'), url: '/study/post-vote', notify: 'PostVoteCount' },
        { name: 'Thư viện', image: require('../../../assets/image/menu/post.png'), url: '/study/post', mission: 'PostDiamondCount' },
        { name: 'Vinh danh', image: require('../../../assets/image/menu/post-bonus.png'), url: '/study/post-bonus' },
        { name: 'Tìm kiếm', image: require('../../../assets/image/menu/brand-find.png'), url: '/study/post-search' },
    ]

    video = [
        { name: 'Đăng video', image: require('../../../assets/image/menu/publish-video.png'), url: '/study/video-publish', mission: 'VideoPublishCount' },
        { name: 'Video của tôi', image: require('../../../assets/image/menu/video-mine.png'), url: '/study/video-mine', mission: 'VideoVerifyCount' },
        { name: 'Xét duyệt', image: require('../../../assets/image/menu/video-vote.png'), url: '/study/video-vote', notify: 'VideoVoteCount' },
        { name: 'Thư viện', image: require('../../../assets/image/menu/video.png'), url: '/study/video', mission: 'VideoDiamondCount' },
        { name: 'Vinh danh', image: require('../../../assets/image/menu/video-bonus.png'), url: '/study/video-bonus' },
        { name: 'Tìm kiếm', image: require('../../../assets/image/menu/brand-find.png'), url: '/study/video-search' },
    ]

    clubMember = [
        { name: 'Bảng điểm', image: require('../../../assets/image/menu/study-summary.png'), url: '/study/study-summary' },
        { name: 'Đào tạo', image: require('../../../assets/image/menu/club-info.png'), url: '/study/club-info' },
        { name: 'Học viên', image: require('../../../assets/image/menu/club-member.png'), url: '/study/club-member' },
        { name: 'Giao lưu', image: require('../../../assets/image/menu/club-feast.png'), url: '/study/club-feast', mission: 'FeastClubCount' },
        { name: 'Phát lộc', image: require('../../../assets/image/menu/club-fortune.png'), url: '/study/club-fortune', notify: 'FortuneClubCount' },
        { name: 'Thăng hạng', image: require('../../../assets/image/menu/club-level.png'), url: '/study/club-level', mission: 'ContributeClubCount' },
        // { name: 'Bảng xếp hạng', image: require('../../../assets/image/menu/club-list.png'), url: '/menu/club-list' },
        { name: 'Phá phong ấn', image: require('../../../assets/image/menu/gift-ticket.png'), url: '/study/gift-ticket', mission: 'GiftTicketCount' }
    ]

    clubTeacherInit = [
        { name: 'Tạo lớp học', image: require('../../../assets/image/menu/club-info.png'), url: '/study/club-create' },
    ]

    clubStudentInit = [
        { name: 'DS lớp học', image: require('../../../assets/image/menu/club-list.png'), url: '/study/club-list' },
    ]

    // book = [
    //     { name: 'Đăng review sách', image: require('../../../assets/image/menu/book-review-publish.png'), url: '/menu/book-review-publish', mission: 'ReviewBookCount' },
    // ]

    getCrest = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            getCrestApi().then(res => {
                if (res.code == 1) {
                    let achievement = res.achievement;
                    if (achievement > 0) {
                        showNotificationBonus(achievement, 1, 4);
                        this.props.changePoint([null, null, null, achievement]);
                    }
                    this.props.getUserBar();
                }
                showNotification(res.message, res.code);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    render() {
        let listScreen = [
            { title: 'Lớp học', data: this.props.dataUser.ClubId > 0 ? this.clubMember : (isTeacher() ? this.clubTeacherInit : this.clubStudentInit) },
        ]
        if (isTeacher()) listScreen.unshift(
            { title: 'Bài viết hay', data: this.post },
            { title: 'Video hay', data: this.video },
        );
        return (
            <div>
                <HeaderLottie
                    source={city}
                />
                {(isTeacher() || isMaster()) &&
                    <>
                        {this.props.countMission.SumMission == 0 ?
                            <div>
                                <p className='bold text-center' style={{ marginBottom: '0.5vh', 'borderRadius': 0 }}>
                                    Bạn đã hoàn thành thử thách ngày hôm nay
                                </p>
                                <div className='item-center' style={{ marginBottom: '0.5vh' }}>
                                    <Button
                                        className='bg-success shadow-pulse'
                                        onClick={this.getCrest}
                                        loading={this.state.isPress}
                                    >
                                        Nhận thưởng
                                    </Button>
                                </div>
                            </div>
                            :
                            <p className='bold text-center' style={{ marginBottom: '0.5vh', 'borderRadius': 0 }}>
                                Vượt qua {this.props.countMission.SumMission} thử thách để sở hữu huy chương Z và 1000 thành tựu
                            </p>
                        }
                    </>
                }
                {listScreen.map((item, key) =>
                    <div key={key}>
                        <div className='title-menu'>{item.title}</div>
                        <div className='row'>
                            {item.data.map((dataMenu, k) =>
                                <div className='col-md-2 col-sm-3 col-xs-4' key={k}>
                                    <ItemMenu
                                        countMission={this.props.countMission}
                                        countNotify={this.props.countNotify}
                                        dataMenu={dataMenu}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={700}
                >
                    <p className='bold title-success text-center mgt'>
                        PHẦN THƯỞNG HUY CHƯƠNG Z
                    </p>
                    <div className='bonus-crest-container'>
                        <div className='flex-row item-center'>
                            <img className='bonus-crest-img' key={1} src={require('../../../assets/image/crest.png')} />
                        </div>
                        <p className='text-large text-center mgt'>Nhận được thêm 1000 thành tựu</p>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission, countNotify } = state.getCountMission;
    return { dataUser, countMission, countNotify };
}

export default connect(mapStateToProps, { updateDataUser, getUserBar, changePoint })(withRouter(MainMenu));