import React from 'react';
import {TitleSuccess} from '../../components/frame';

export default () =>(
    <div>
        <TitleSuccess>ĐIỀU KHOẢN SỬ DỤNG</TitleSuccess>
        <div className='box-primary'>
            <p className='text-huge text-center bold'>THOẢ THUẬN CUNG CẤP VÀ SỬ DỤNG WEBSITE ZCITY.VN</p>
            <p className='mgt'>Xin chào mừng đến với website Zcity.vn - sản phẩm dịch vụ trực tuyến của Công ty TNHH Tổ hợp giáo dục Kiến Thức Nền. Để sử dụng website này, vui lòng đọc kỹ bản Điều khoản sử dụng này một cách cẩn thận. Quý vị chỉ có thể sử dụng website này khi đã đồng ý và chấp thuận với các Điều khoản mà không có bất cứ sự giới hạn hay bảo lưu nào.</p>
            <p class="text-center text-large success bold">Điều khoản cập nhật ngày 28-06-2019</p>
            <p className='text-large bold'>Điều 1: Giải thích từ ngữ</p>
            <ul>
                <li>Thỏa thuận: là thỏa thuận sử dụng dịch vụ Zcity.vn, cùng với tất cả các bản sửa lỗi, bổ sung, cập nhật.</li>
                <li>Zcity.vn: là Mạng xã hội trực tuyến, được xây dựng nhằm mục tiêu đồng hành cùng các bạn trong quá trình học tập, trau dồi kiến thức, kỹ năng do Công ty TNHH Tổ hợp giáo dục Kiến Thức Nền phát triển và làm chủ quản.</li>
                <li>Thông Tin Cá Nhân: là những thông tin góp phần định danh một cá nhân thông tin gắn liền với việc xác định danh tính, nhân thân của cá nhân bao gồm tên, tuổi, địa chỉ, số chứng minh nhân dân, số điện thoại, địa chỉ thư điện tử, tài khoản ngân hàng của Người Sử Dụng và một số thông tin khác theo quy định của pháp luật.</li>
                <li>Người Sử Dụng: là chủ tài khoản, sở hữu hợp pháp tài khoản Zcity.vn bao gồm nhưng không giới hạn: Người tạo nội dung học liệu (sau đây gọi là Người Tạo), Người dùng nội dung học liệu (sau đây gọi là Người Dùng), Người giới thiệu các nội dung của học liệu cho Người dùng khác (sau đây gọi là Người Giới Thiệu. Người Giới Thiệu có thể là Người Tạo hoặc Người Dùng).</li>
                <li>Sở Hữu Trí Tuệ: là những sáng chế, cải tiến, thiết kế, quy trình, công thức, phương pháp, cơ sở dữ liệu, thông tin, bản vẽ, mã, chương trình máy tính, tác phẩm có bản quyền (hiện tại và tương lai), thiết kế mạch tích hợp bán dẫn, thương hiệu, nhãn hiệu (dù đã đăng ký hay chưa đăng ký) tên thương mại và (thiết kế) bao bì thương phẩm.</li>
            </ul>
            <p className='text-large bold'>Điều 2: Tài khoản</p>
            <ul>
                <li>Tài khoản của Người Sử Dụng được tạo ra bằng cách đồng bộ từ các hệ thống các hệ thống khác sang.</li>
                <li>Sau lần đầu tiên đăng nhập vào tài khoản, Người Sử Dụng tự chịu trách nhiệm về năng lực hành vi trong việc thay đổi thông tin cá nhân và sử dụng Zcity.vn. Người Sử Dụng nên cập nhật, hoàn thiện thông tin tài khoản Zcity.vn để nhận được sự hỗ trợ tốt nhất.</li>
                <li>Người Sử Dụng có trách nhiệm tự bảo mật thông tin tài khoản của mình, nếu những thông tin trên bị tiết lộ dưới bất kỳ hình thức nào thì Người Sử Dụng phải chấp nhận những rủi ro phát sinh. Zcity.vn sẽ căn cứ vào những thông tin hiện có trong tài khoản để làm căn cứ quyết định chủ sở hữu tài khoản nếu có tranh chấp và Zcity sẽ không chịu trách nhiệm về mọi tổn thất phát sinh cho Người Sử Dụng.</li>
                <li>Nếu Người Sử Dụng biết hoặc nghi ngờ rằng mật khẩu hoặc tài khoản của mình đã bị xâm nhập, hãy báo ngay cho Zcity.vn thông qua mail: zcity.vn@gmail.com.</li>
                <li>Một số tính năng của Zcity.vn yêu cầu Người Sử Dụng phải có tài khoản, đăng nhập để sử dụng. Nếu Người Sử Dụng không có tài khoản, không đăng nhập thì chỉ sử dụng Zcity.vn với các tính năng thông thường. Chúng tôi khuyến khích Người Sử Dụng có tài khoản để sử dụng Zcity.vn được tốt nhất.</li>
            </ul>
            <p className='text-large bold'>Điều 3: Nội dung dịch vụ</p>
            <ul>
                <li>Dịch vụ dạy trực tuyến: Zcity.vn cung cấp nền tảng công nghệ để Người Sử Dụng có thể tự xây dựng, tổ chức khóa học trực tuyến trên Zcity.</li>
                <li>Dịch vụ học trực tuyến: Người Sử Dụng có thể tham gia vào khóa học để học trực tuyến như xem video bài giảng, xem tài liệu, làm bài tập, kiểm tra trắc nghiệm.</li>
                <li>Dịch vụ học tương tác trực tuyến: Người Sử Dụng có thể tham gia vào các bài giảng tương tác trực tuyến với Người Sử Dụng khác.</li>
                <li>Dịch vụ đọc tài liệu : Zcity.vn cung cấp thư viện trực tuyến để Người Sử dụng có thể tra cứu/ đọc tài liệu hoặc đóng góp tài liệu. Người Dùng hoàn toàn chịu trách nhiệm về nội dung đóng góp trên Zcity.vn.</li>
                <li>Dịch vụ Giao lưu Mạng xã hội học tập: Zcity.vn cung cấp dịch vụ mạng xã hội học tập để Người Sử dụng có thể giao lưu, chia sẻ các học liệu; chat với nhau bằng ký tự, hình ảnh; kết nhóm với nhau để cùng học và chia sẻ tài liệu.</li>
                <li>vn cung cấp nền tảng để bên thứ 3 chủ động sản xuất nội dung cung cấp tới Người Sử Dụng. Bên thứ 3 phải tuân thủ các điều kiện, quy định trong Thỏa Thuận này và Thỏa Thuận Hợp Tác hai bên.</li>
                <li>vn cho phép Người Sử Dụng truy cập và sử dụng sản phẩm trên website hoặc/và trên các ứng dụng Zcity.vn phát triển trong tương lai.</li>
            </ul>
            <p className='text-large bold'>Điều 4: Trao giải thưởng cuộc thi, rút ​​thăm trúng thưởng</p>
            <ul>
            <li>Giải thưởng được tài trợ bởi nhà phát hành Zcity</li>
            <li>Apple, Google và các bên thứ ba không phải là nhà tài trợ, tham gia vào hoạt động dưới bất kỳ hình thức nào</li>
            </ul>
            <p className='text-large bold'>Điều 5: Các nội dung cấm trao đổi và chia sẻ trên mạng xã hội</p>
            <ul>
                <li>Lợi dụng việc cung cấp, trao đổi, sử dụng thông tin trên Zcity.vn nhằm mục đích:</li>
                <li>Chống lại Nhà nước Cộng hoà Xã hội chủ nghĩa Việt Nam; gây phương hại đến an ninh quốc gia, trật tự, an toàn xã hội; phá hoại khối đại đoàn kết toàn dân; tuyên truyền chiến tranh xâm lược, khủng bố; gây hận thù, mâu thuẫn giữa các dân tộc, sắc tộc, chủng tộc, tôn giáo.</li>
                <li>Tuyên truyền, kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong, mỹ tục của dân tộc.</li>
                <li>Tuyệt đối không bàn luận, đăng tải các nội dung về các vấn đề chính trị.</li>
                <li>Người Sử Dụng lợi dụng việc sử dụng Zcity.vn nhằm tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối ngoại và những bí mật khác do pháp luật quy định bằng bất cứ hình thức nào trên Zcity.vn.</li>
                <li>Quảng cáo, tuyên truyền, mua bán hàng hoá, dịch vụ bị cấm hoặc truyền bá tác phẩm báo chí, văn học, nghệ thuật, xuất bản phẩm bị cấm trên Zcity.vn.</li>
                <li>Khi giao tiếp với Người Sử Dụng khác, Người Sử Dụng quấy rối, chửi bới, làm phiền hay có bất kỳ hành vi thiếu văn hoá.</li>
                <li>Người Sử Dụng có quyền sử dụng đối với hình ảnh của mình. Khi sử dụng hình ảnh của cá nhân khác, Người Sử Dụng phải được sự đồng ý của cá nhân đó. Nghiêm cấm việc sử dụng hình ảnh của người khác mà xâm phạm danh dự, nhân phẩm, uy tín của người có hình ảnh.</li>
                <li>Lợi dụng mạng xã hội Zcity.vn để thu thập thông tin của Người Sử Dụng, công bố thông tin, tư liệu về đời tư của Người Sử Dụng khác.</li>
                <li>Đặt thành viên theo tên của danh nhân, tên các vị lãnh đạo của Đảng và Nhà nước, tên của cá nhân, tổ chức tội phạm, phản động, khủng bố hoặc tài khoản có ý nghĩa không lành mạnh, trái với thuần phong mỹ tục.</li>
                <li>Đưa thông tin xuyên tạc, vu khống, nhạo báng, xúc phạm uy tín tới tổ chức, cá nhân dưới bất kỳ hình thức nào (nhạo báng, chê bai, kỳ thị tôn giáo, giới tính, sắc tộc... ).</li>
                <li>Hành vi, thái độ làm tổn hại đến uy tín Zcity.vn hoặc Công ty TNHH Tổ hợp giáo dục Kiến Thức Nền dưới bất kỳ hình thức hoặc phương thức nào.</li>
                <li>Nghiêm cấm quảng bá bất kỳ sản phẩm dưới bất kỳ hình thức nào, bao gồm nhưng không giới hạn việc gửi, truyền bất kỳ thông điệp nào mang tính quảng cáo, mời gọi, thư dây truyền, cơ hội đầu tư trên Zcity.vn mà không có sự đồng ý bằng văn bản của Zcity.</li>
                <li>Lợi dụng Zcity.vn để tổ chức các hình thức cá cược, cờ bạc hoặc các thỏa thuận liên quan đến tiền, hiện kim, hiện vật.</li>
                <li>Cản trở trái pháp luật, gây rối, phá hoại hệ thống máy chủ; cản trở việc truy cập thông tin và sử dụng các dịch vụ hợp pháp trên Zcity.vn.</li>
                <li>Sử dụng trái phép mật khẩu, kho mật mã của các tổ chức, cá nhân, thông tin riêng, thông tin cá nhân và tài nguyên Internet.</li>
                <li>Trực tiếp hoặc gián tiếp sử dụng bất kỳ thiết bị, phần mềm, trang web internet, dịch vụ dựa trên web, hoặc các phương tiện khác để gỡ bỏ, thay đổi, bỏ qua, lẩn tránh, cản trở, hoặc phá hoại bất kỳ bản quyền, thương hiệu, hoặc các dấu hiệu về quyền sở hữu khác được đánh dấu trên Nội dung (như logo) hoặc bất kỳ hệ thống kiểm soát dữ liệu, thiết bị, biện pháp bảo vệ nội dung khác cũng như các biện pháp hạn chế truy cập từ các vùng địa lí khác nhau.</li>
                <li>Trực tiếp hoặc gián tiếp thông qua bất kỳ thiết bị, phần mềm, trang web internet, dịch vụ dựa trên web, hoặc các phương tiện khác để sao chép, tải về, chụp lại, sản xuất lại, nhân bản, lưu trữ, phân phối, tải lên, xuất bản, sửa đổi, dịch thuật, phát sóng, trình chiếu, hiển thị, bán, truyền tải hoặc truyền lại nội dung trừ khi được sự cho phép của Zcity.vn bằng văn bản.</li>
                <li>Tạo ra, tái tạo, phân phối hay quảng cáo một chi tiết của bất kỳ nội dung trừ khi được phép của Zcity.vn. Bạn không được phép xây dựng mô hình kinh doanh sử dụng các Nội dung cho dù là có hoặc không vì lợi nhuận. Nội dung được đề cập tại Zcity.vn bao gồm nhưng không giới hạn bất kỳ văn bản, đồ họa, hình ảnh, bố trí, giao diện, biểu tượng, hình ảnh, tài liệu âm thanh và video, và ảnh tĩnh. Ngoài ra, chúng tôi nghiêm cấm việc tạo ra các sản phẩm phát sinh hoặc vật liệu có nguồn gốc từ hoặc dựa trên bất kì Nội dung nào bao gồm dựng phim, làm video tương tự, hình nền, chủ đề máy tính, thiệp chúc mừng, và hàng hóa, trừ khi nó được sự cho phép của Zcity.vn bằng văn bản.</li>
                <li>Giả mạo tổ chức, cá nhân và phát tán thông tin giả mạo, thông tin sai sự thật trên Zcity.vn xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân.</li>
                <li>Tạo đường dẫn trái phép tới tên miền hợp pháp của tổ chức, cá nhân. Tạo, cài đặt, phát tán các phần mềm độc hại, vi rút máy tính; xâm nhập trái phép, chiếm quyền điều khiển hệ thống thông tin, tạo lập công cụ tấn công trên Internet.</li>
                <li>Tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào Zcity.vn.</li>
                <li>Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của sản phẩm cung cấp hoặc hệ thống máy chủ Zcity.vn.</li>
                <li>Không được có bất kỳ hành vi nào nhằm đăng nhập trái phép hoặc tìm cách đăng nhập trái phép hoặc gây thiệt hại cho hệ thống máy chủ Zcity.vn.</li>
                <li>Nghiêm cấm mọi hành vi xâm phạm khác dưới mọi hình thức tới sản phẩm, tài sản và uy tín của Zcity.vn.</li>
            </ul>
            <p className='text-large bold'>Điều 6: Quy tắc quản lí xử phạt vi phạm Người Sử Dụng</p>
            <ul>
                <li>Người Sử Dụng vi phạm thỏa thuận cung cấp và sử dụng mạng xã hội thì tùy theo mức độ nghiêm trọng của hành vi vi phạm sẽ bị xử phạt tương ứng.</li>
                <li>Trường hợp hành vi vi phạm của Người Sử Dụng chưa được quy định trong thỏa thuận này thì tùy vào tính chất, mức độ của hành vi vi phạm, Zcity.vn sẽ đơn phương, toàn quyền quyết định mức xử phạt hợp lý.</li>
                <li>Hình thức xử phạt khóa tài khoản Zcity.vn có thời hạn hoặc vĩnh viễn, điều đó đồng nghĩa với việc Người Sử Dụng cũng không thể sử dụng các sản phẩm khác khi truy cập từ tài khoản Zcity.vn.</li>
                <li>Nếu tài khoản Zcity.vn bị khóa vĩnh viễn, thì toàn bộ những quyền lợi của chủ tài khoản cũng sẽ khóa vĩnh viễn.</li>
                <li>Các hành vi vi phạm của Người Sử Dụng không được liệt kê ở dưới, tùy từng trường hợp cụ thể, Zcity.vn có toàn quyền đưa ra mức phạt phù hợp theo các hình thức xử phạt theo quy định tại Thỏa Thuận này.</li>
                Các hình thức xử phạt:
                <li>Hình thức xử phạt 1: khóa tài khoản 7 ngày.</li>
                <li>Hình thức xử phạt 2: khóa tài khoản 30 ngày.</li>
                <li>Hình thức xử phạt 3: khóa tài khoản 120 ngày hoặc khóa tài khoản vĩnh viễn.</li>
                <li>Hình thức xử phạt 3: khóa tài khoản 120 ngày hoặc khóa tài khoản vĩnh viễn.</li>
                <li>Người Sử Dụng có hành vi lợi dụng Zcity.vn nhằm chống phá nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam. Hành vi này bao gồm nhưng không giới hạn việc Người Sử Dụng đặt tên thành viên trùng tên với các vĩ nhân, các vị anh hùng của dân tộc, các vị lãnh đạo của đảng và nhà nước, hoặc Người Sử Dụng có sử dụng hình ảnh, video, phát ngôn, chát... có chứa thông tin bàn luận về vấn đề chính trị hoặc tiết lộ bí mật nhà nước Cộng Hòa Xã hội Chủ nghĩa Việt Nam.</li>
                <li>Thông tin, hình ảnh, video khiêu dâm: Người Sử Dụng đăng tải hình ảnh, âm thanh, video khiêu dâm, chat sex hoặc tuyên truyền các các nội dung khiêu dâm.</li>
                <li>Thông tin cá cược, cờ bạc: Lợi dụng Zcity.vn, Người Sử Dụng đăng tải nội dung thông tin, hình ảnh, âm thanh, video chứa thông tin để tổ chức các hình thức cá cược, cờ bạc hoặc các thỏa thuận liên quan đến tiền, hiện kim, hiện vật.</li>
                <li>Lan truyền thông tin lừa đảo: Sử dụng văn bản, hình ảnh, âm thanh hoặc video có chứa thông tin lừa đảo: giả làm chính thức hoặc các tổ chức, cá nhân; gian lận, lừa đảo tài sản của người khác hoặc tài khoản Zcity.vn, điểm tích lũy .</li>
                <li>Phá hoại hệ thống mạng xã hội trực tuyến Zcity.vn: Người Sử Dụng lợi dụng việc sử dụng sản phẩm để xâm nhập vào hệ thống máy chủ nhằm phá hoại sản phẩm hoặc cản trở việc truy cập thông tin. Người Sử Dụng sử dụng công cụ kỹ thuật nhằm tăng điểm hoạt động, vật phẩm hoặc nhằm treo máy, spam chat.</li>
                <li>Sử dụng diễn đàn hỏi đáp để lôi kéo tổ chức hội họp thực tế ở bên ngoài thực hiện các hành vi vi phạm pháp luật.</li>
                <strong><em>Hình thức xử phạt 2, khóa tài khoản 30 ngày theo khoản 1 điều này được áp dụng bao gồm nhưng không giới hạn đối với các hành vi sau:</em></strong>
                <li>Hành vi giao tiếp: Chat khiêu dâm ở mức độ nhẹ, spam chat, kích động các thành viên khác đến các phòng khác của Zcity.vn để gây rối hoặc tuyên truyền những thông tin vi phạm.</li>
                <li>Quảng cáo: Trong tên thành viên, trạng thái, giải thích về bản thân có chứa thông tin quảng cáo hoặc sử dụng các văn bản, hình ảnh, âm thanh, hoặc video và thông tin quảng cáo mà không có sự đồng ý bằng văn bản của Zcity.vn Ngoài ra thành viên sử dụng sản phẩm với mục đích quảng cáo, tuyên truyền, mua bán và truyền bá hàng hoá, dịch vụ bị cấm, trong trường hợp này tùy thuộc vào mức độ nghiêm trọng sẽ áp dụng hình phạt 2 hoặc hình phạt 3.</li>
                <li>Xâm phạm riêng tư: Sử dụng hình ảnh cá nhân của người khác, công khai những tư liệu cá nhân và những thông tin khác như danh tính, địa chỉ, số điện thoại mà chưa được sự đồng ý và tiến hành gọi điện quấy nhiễu hoặc khích động người khác quấy nhiễu.</li>
                <li>Công kích người khác: Sử dụng hình ảnh, thông tin, âm thanh hoặc video, xúc phạm, đưa thông tin xuyên tạc, vu khống, nhạo báng, xúc phạm uy tín tới tổ chức, cá nhân.</li>
                <li>Vi phạm bản quyền: Ăn cắp các nội dung, sao chép hoặc trích dẫn mà không được phép sử dụng bản quyền của người khác.</li>
                <strong><em>Hình thức xử phạt 1, khóa tài khoản 7 ngày theo khoản 1 điều này được áp dụng bao gồm nhưng không giới hạn đối với các hành vi sau.</em></strong>
                <li>Công kích, xuyên tạc, xúc phạm nhân phẩm các thành viên khác.</li>
                <li>Đăng tải video, âm thanh, truyền bá văn hóa phẩm đồi trụy.</li>
                <li>Các hình ảnh video được streaming, đăng tải có tính chất nhạy cảm, khiêu gợi, dung tục.</li>
            </ul>
            <p className='text-large bold'>Điều 7: Nội dung cung cấp, trao đổi thông tin</p>
            Zcity.vn hiện tại cung cấp nhiều dịch vụ Internet bao gồm nhưng không giới hạn các dịch vụ như : trắc nghiệm, đăng bài viết trực tuyến, dạy và học tương tác trực tuyến, hỏi đáp và thảo luận trực tuyến, chat chữ, chat hình ảnh.
            <ul>
                <li>Zcity.vn là mạng xã hội với mục tiêu tạo ra môi trường giao lưu, kết nối, xây dựng thương hiệu cá nhân cho Người Sử Dụng. Chính vì mục đích tốt đẹp đó, Người Sử Dụng khi đăng tải video, clip chia sẻ, trao đổi thông tin về kiến thức trên Zcity.vn phải nghiêm túc và có nền tảng kiến thức vững về chuyên đề chia sẻ. Đối với Người Sử Dụng sử dụng Zcity.vn để học tập, trau dồi kiến thức thì có thể lựa chọn các dịch vụ theo nhu cầu để trải nghiệm.</li>
                <li>Nội dung đăng tải ở các bài viết, khoá học phải tương thích, phù hợp với chủ đề mà Zcity.vn định hướng bao gồm nhưng không giới hạn như: kiến thức về lãnh đạo, quản trị, quan hệ, tài chính, ngoại ngữ, chuyên môn…</li>
                <li>vn không chứng thực nội dung nào được đăng tải, sử dụng trên Zcity.vn bởi bất kỳ Người Sử Dụng nào. Zcity.vn không cho phép các hoạt động vi phạm bản quyền và xâm phạm quyền sở hữu trí tuệ trên Dịch vụ, và Zcity.vn sẽ loại bỏ tất cả các nội dung phát hành nếu được thông báo rằng những nội dung vi phạm quyền sở hữu trí tuệ mà không cần thông báo trước.</li>
                <li>Đây là website và phần mềm mạng xã hội Zcity.vn thuộc quyền sở hữu của công ty TNHH Tổ hợp giáo dục Kiến Thức Nền, Zcity cho phép Người Sử Dụng đăng tải, cung cấp, trao đổi các thông tin, hình ảnh hoặc các nội dung khác lên trên website và phần mềm trừ những nội dung cấm được quy định trong bản Quy chế này và các văn bản pháp luật liên quan.</li>
                <li>Người Sử Dụng là người dạy học, chia sẻ kinh nghiệm dạy học, dạy các chuyên đề, khóa học, phát clip, video dạy học, dạy học trực tuyến (Trainer)… và/hoặc những nội dung video khác phù hợp với tiêu chí của Zcity.vn. Người Sử Dụng đồng ý rằng sẽ không đăng tải lên Zcity.vn các nội dung đã có bản quyền, các bí mật thương mại hoặc các nội dung khác liên quan tới các quyền sở hữu trí tuệ của bên thứ ba, trừ trường hợp bạn là chủ sở hữu hợp pháp của các nội dung này hoặc có sự chấp thuận từ chủ sở hữu.</li>
                <li>Người Sử Dụng đồng ý để chúng tôi tự do kinh doanh, sử dụng nội dung của bạn mà bạn cung cấp, trao đổi, chia sẻ thông qua việc sử dụng Zcity.vn một cách hoàn toàn miễn phí. Bạn đồng ý từ bỏ bất kỳ quyền và yêu cầu với bất kỳ khoản tiền thưởng, phí, nhuận bút, lệ phí hoặc các chi trả khác liên quan đến việc chúng tôi kinh doanh, sử dụng, phát tán, trình chiếu, chỉnh sửa, biên soạn bất kỳ hoặc tất cả những thông tin, hình ảnh mà bạn cung cấp, chia sẻ, trao đổi của bạn.</li>
                <li>Bạn cũng cho phép Người Sử Dụng được quyền sử dụng lại thông tin đăng tải của bạn bao gồm nhưng không giới hạn việc chỉnh sửa lại, biên soạn, phát tán, trình chiếu nội dung đăng tải đó vì mục đích cá nhân hoặc phi thương mại.</li>
                <li>Tất cả quyền sở hữu trí tuệ tồn tại trong Zcity.vn đều thuộc về Công ty TNHH Tổ hợp giáo dục Kiến Thức Nền hoặc được cấp phép hợp pháp cho Zcity.vn sử dụng, theo đó, tất cả các quyền hợp pháp đều được đảm bảo. Trừ khi được sự đồng ý bằng văn bản của Zcity.vn, bạn không được phép xuất bản, tài sản xuất, truyền bá hoặc xâm phạm bằng các hình thức khác tới quyền sở hữu trí tuệ sản phẩm Zcity.vn.</li>
                <li>Tại các khu vực được phép truyền phát hình ảnh, đăng tải bài viết, bạn có thể chia sẻ thông tin, hình ảnh được phép dưới các định dạng chúng tôi mặc định. Bạn đồng ý cam kết thực hiện trách nhiệm đảm bảo sử dụng hợp pháp nội dung thông tin số đưa lên đăng tải trên hệ thống mạng internet và mạng viễn thông.</li>
                <li>Trong mọi trường hợp, Zcity.vn được quyền xử lý các thông tin đăng tải cho phù hợp với thuần phong mỹ tục, các quy tắc đạo đức và các quy tắc đảm bảo an ninh quốc gia, và chúng tôi có toàn quyền cho phép hoặc không cho phép bài viết, thông tin, hình ảnh của bạn xuất hiện hay tồn tại trên website và phần mềm Zcity.vn.</li>
                <li>Người Sử Dụng hiểu và đồng ý rằng, khi sử dụng website và phần mềm mạng xã hội này, Người Sử Dụng sẽ tiếp nhận nhiều nội dung thông tin, hình ảnh được đăng tải từ nhiều nguồn khác nhau. Zcity.vn không chịu trách nhiệm về mức độ chính xác, tính hữu ích, độ an toàn, hoặc các quyền sở hữu trí tuệ liên quan tới những thông tin mà Người Sử Dụng khác đăng tải. Khi sử dụng sản phẩm có thể bạn thấy một vài thông tin, bình luận do Người Sử Dụng đăng tải không đúng sự thật, hoặc gây phản cảm, trong trường hợp này, bạn có thể thôi không sử dụng sản phẩm và cam kết không có những hành động xâm phạm tới Zcity.vn và Công ty TNHH Tổ hợp giáo dục Kiến Thức Nền.</li>
                <li>Khi Người Sử Dụng truy cập vào sản phẩm khác, ứng dụng hoặc website liên kết với mạng xã hội này, Người Sử Dụng phải hiểu và tuân thủ những quy định về sản phẩm mà Người Sử Dụng sử dụng. Có thể có những sản phẩm thuộc quyền sở hữu của Zcity hoặc không, và do vậy Người Sử Dụng phải tự chịu trách nhiệm những rủi ro phát sinh khi sử dụng sản phẩm này.</li>
                <li>Bài viết đánh giá ý kiến của bạn là một phần Dịch vụ Zcity.vn. Người Sử Dụng có thể xuất bản, truyền tải, gửi đi những đánh giá, ý kiến, hoặc những tài liệu khác lên Zcity.vn. Bạn phải đảm bảo bài viết, đánh giá của bạn phù hợp với giới hạn ngôn từ và nội dung.</li>
                <li>vn cho phép Người Sử Dụng truy cập và sử dụng sản phẩm trên website hoặc/và trên các ứng dụng Zcity.vn phát triển trong tương lai. Khi sử dụng sản phẩm Zcity.vn trên các ứng dụng mà Zcity.vn phát triển, Người Sử Dụng cũng phải đảm bảo tuân thủ các điều khoản trong thỏa thuận sử dụng này.</li>
                <li>Ngoài các sản phẩm của Zcity.vn có thể cho phép hoặc liên kết với đối tác thứ ba để cung cấp các sản phẩm, dịch vụ của họ trên Zcity.vn, do đó, khi sử dụng một sản phẩm như vậy, Người Sử Dụng thừa nhận rằng (i) Người Sử Dụng đã tìm hiểu và chấp thuận các điều khoản sử dụng dịch vụ của bên thứ ba (ii) Mọi trách nhiệm phát sinh liên quan đến dịch vụ là của bên thứ ba, Zcity.vn sẽ hỗ trợ Người Sử Dụng nhưng không chịu trách nhiệm về các dịch vụ này.</li>
                <li>Người Sử Dụng đồng ý cấp quyền cho Zcity.vn sử dụng nội dung trao đổi thông tin của bạn cho mục đích quảng bá, phát triển dịch vụ, bao gồm nhưng không giới hạn các dịch vụ mới mà chúng tôi có thể cung cấp trong tương lai.</li>
                <li>vn có thể giữ lại hoặc tiết lộ nội dung của bạn, bao gồm cả thông tin Cá Nhân cho cơ quan có thẩm quyền theo quy định pháp luật, hoặc theo quá trình hợp pháp khác. Trong quá trình hoạt động, cho các mục đch duy trì dịch vụ và đảm bảo lợi cho Người Sử Dụng Zcity có thể sẽ chuyển giao các thông tin của bạn cho bên thứ ba phù hợp với các quy định của pháp luật mà không yêu cầu phải có sự đồng trước của bạn.</li>
                <li>Người Sử Dụng hiểu rằng khi muốn tìm cách xóa nội dung của mình từ các dịch vụ của Zcity.vn, việc này phải mất một khoảng thời gian nhất định. Vì vấn đề kỹ thuật và quy trình nên có thể không theo muốn của Người Sử Dụng. Người Sử Dụng chấp nhận những rủi ro phát sinh và Zcity.vn được loại trừ trách nhiệm này. Chúng tôi khuyên bạn nên lưu giữ một bản trước khi đăng tải lên Zcity.vn.</li>
                <li>Trong quá trình sử dụng Zcity.vn, nếu bạn vi phạm bất kỳ quy định nào trong Thỏa Thuận này, chúng tôi có toàn quyền, ngay lập tức và không cần thông báo trước cho bạn, chấm dứt, xóa bỏ tài khoản của bạn mà không phải chịu bất cứ trách nhiệm nào đối với bạn, đồng thời tùy theo mức độ vi phạm, chúng tôi có thể từ chối việc bạn đăng kí để sử dụng các dịch vụ khác của Zcity.</li>
                <li>Trên website, phần mềm Zcity.vn xuất hiện link website, hoặc biểu tượng website khác, bạn không nên suy luận rằng Zcity.vn kiểm soát hoặc sở hữu với những website này. Việc truy cập tới các trang khác này hoàn toàn có thể gặp rủi ro, nguy hiểm. Người Sử Dụng hoàn toàn chịu trách nhiệm rủi ro khi sử dụng website liên kết này. Zcity.vn không chịu trách nhiệm về nội dung của bất kì website hoặc điểm đến nào ngoài trang Zcity.vn.</li>
                <li>Mọi vi phạm của chủ tài khoản trong quá trình sử dụng sản phẩm Zcity.vn, Zcity có quyền tước bỏ mọi quyền lợi của chủ tài khoản đối với việc sử dụng sản phẩm cũng như sẽ yêu cầu cơ quan chức năng truy tố bạn trước pháp luật nếu cần thiết.</li>
            </ul>
            <p className='text-large bold'>Điều 8: Quyền và nghĩa vụ của người sử dụng Zcity.vn</p>
            <ul>
                <li>Khi có tài khoản trên Zcity.vn, Người Sử Dụng được sử dụng một phần hoặc tất cả các dịch vụ trong sản phẩm Zcity.vn.</li>
                <li>Trong quá trình sử dụng sản phẩm, Người Sử Dụng được sử dụng các dịch vụ hỗ trợ của Zcity quy định tại website https://Zcity.vn</li>
                <li>Người Sử Dụng có trách nhiệm bảo mật thông tin tài khoản, nếu những thông tin trên bị tiết lộ dưới bất kỳ hình thức nào thì Người Sử Dụng phải chấp nhận những rủi ro phát sinh. Zcity.vn sẽ căn cứ vào những thông tin hiện có trong tài khoản để làm căn cứ quyết định chủ sở hữu tài khoản nếu có tranh chấp và Zcity.vn sẽ không chịu trách nhiệm về mọi tổn thất phát sinh.</li>
                <li>Người Sử Dụng có trách nhiệm tháo gỡ những nội dung vi phạm do mình đăng tải ngay lập tức đồng thời phải chịu trách nhiệm trước pháp luật và các bên liên quan về nội dung đăng tải đó.</li>
                <li>Người Sử Dụng đồng ý sẽ thông báo ngay cho Zcity về bất kỳ trường hợp nào sử dụng trái phép tài khoản và mật khẩu của bạn hoặc bất kỳ các hành động phá vỡ hệ thống bảo mật nào. Bạn cũng bảo đảm rằng, bạn luôn thoát tài khoản của mình sau mỗi lần sử dụng.</li>
                <li>Khi phát hiện ra lỗi của Zcity.vn, bạn hãy thông báo cho chúng tôi qua email zcity.vn@gmail.com</li>
                <li>Người Sử Dụng có thể sẽ bị xử phạt vi phạm hành chính, bị truy tố trách nhiệm hình sự nếu bạn vi phạm về quyền tác giả, quyền liên quan khi bạn sử dụng sản phẩm mạng xã hội Zcity.vn này.</li>
                <li>Người Sử Dụng phải tuân thủ tuyệt đối quy định về các hành vi cấm, các nội dung trao đổi cung cấp thông tin được quy định trong quy chế này. Nếu vi phạm một hoặc nhiều hành vi, tùy thuộc vào mức độ vi phạm Zcity sẽ khóa tài khoản vĩnh viễn, tước bỏ mọi quyền lợi của bạn đối các sản phẩm của Zcity và sẽ yêu cầu cơ quan chức năng truy tố bạn trước pháp luật nếu cần thiết.</li>
                <li>Khi bạn tạo khóa học trên Zcity.vn, tài khoản của bạn sẽ trở thành loại tài khoản giáo viên (Trainer), bạn được phép đăng tải nội dung do mình sở hữu lên Zcity.vn và tự chịu toàn bộ các trách nhiệm pháp lý liên quan đến toàn bộ nội dung đăng tải, bao gồm nhưng không giới hạn tính hợp pháp của nội dung, quyền sở hữu trí tuệ và các chấp thuận cần thiết khác từ cơ quan có thẩm quyền.</li>
                <li>Bất kỳ nội dung, clip, video, thông tin, bình luận, hình ảnh hoặc nội dung khác được đăng tải bởi Người Sử Dụng trên Zcity.vn, Người Sử Dụng đồng ý rằng (i) tự chịu trách nhiệm về nội dung do mình đăng tải; và (ii) Zcity.vn và Công ty TNHH Tổ hợp giáo dục Kiến Thức Nền được miễn trừ mọi trách nhiệm liên quan.</li>
                <li>Thực hiện quyền và trách nhiệm khác theo quy định pháp luật.</li>
            </ul>
            <p className='text-large bold'>Điều 9: Quyền và trách nhiệm của Zcity.vn</p>
            <ul>
                <li>Trong quá trình sử dụng sản phẩm, nếu bạn vi phạm bất cứ điều khoản nào trong Thỏa thuận cung cấp dịch vụ mạng xã hội này, chúng tôi có toàn quyền chấm dứt, xóa bỏ tài khoản của bạn mà không cần sự đồng ý của bạn và không phải chịu bất cứ trách nhiệm nào đối với bạn.</li>
                <li>Mọi vi phạm của chủ tài khoản trong quá trình sử dụng sản phẩm Zcity.vn, Zcity có quyền tước bỏ mọi quyền lợi của chủ tài khoản đối với việc sử dụng sản phẩm cũng như sẽ yêu cầu cơ quan chức năng truy tố bạn trước pháp luật nếu cần thiết.</li>
                <li>Khi phát hiện những vi phạm như sử dụng cheats, hacks, truyền bá nội dung cấm hoặc những lỗi khác, Zcity.vn có quyền sử dụng những thông tin mà bạn cung cấp khi đăng kí tài khoản để chuyển cho Cơ quan chức năng giải quyết theo quy định của pháp luật.</li>
                <li>Khi phát hiện những vi phạm về nội dung cấm được quy định tại Thỏa thuận này, Zcity.vn có quyền tắt ngay lập tức các video đang được phát sinh bởi Người Sử Dụng và/hoặc cảnh cáo, khóa, tạm dừng chat của Người Sử Dụng vi phạm. Trong trường hợp nhận được tố cáo của Người Sử Dụng khác, Zcity.vn sẽ tiến hành giám sát kiểm tra và log data cùng những chứng cứ liên quan, nếu vi phạm Zcity.vn có quyền tắt ngay lập tức các video đang được phát sóng bởi Người Sử Dụng và/hoặc cảnh cáo, khóa, tạm dừng chat của Người Sử Dụng vi phạm. Zcity.vn có toàn quyền quyết định các hình thức xử lý đối với các trường hợp vi phạm. Tùy vào tính chất sự việc, mức độ ảnh hưởng và nghiêm trọng, Zcity.vn sẽ đưa ra hình thức xử lý phù hợp. Quyết định của Zcity.vn là quyết định cuối cùng và Người Sử Dụng đồng ý chấp hành.</li>
                <li>Có trách nhiệm hỗ trợ chủ tài khoản trong quá trình sử dụng sản phẩm của Zcity.vn.</li>
                <li>Nhận và giải quyết khiếu nại của khách hàng các trường hợp phát sinh trong quá trình sử dụng Zcity.vn, tuy nhiên Zcity.vn chỉ hỗ trợ, nhận và giải quyết đối với tài khoản đăng kí đầy đủ thông tin, trung thực và chính xác.</li>
                <li>Có trách nhiệm bảo mật thông tin cá nhân của chủ tài khoản, Zcity.vn không bán hoặc trao đổi những thông tin này với bên thứ 3, trừ trường hợp theo quy định pháp luật hoặc được chủ tài khoản chấp nhận.</li>
            </ul>
            <p className='text-large bold'>Điều 10: Giới hạn trách nhiệm và từ chối đảm bảo</p>
            <ul>
                <li>vn sẽ không chịu trách nhiệm đối với bất cứ vấn đề gì về hệ thống trong quá trình bạn sử dụng sản phẩm Zcity.vn.</li>
                <li>Nếu phát sinh rủi ro, thiệt hại trong trường hợp bất khả kháng bao gồm nhưng không giới hạn như chập điện, hư hỏng phần cứng, phần mềm, sự cố đường truyền internet hoặc do thiên tai v.v... Người Sử Dụng phải chấp nhận những rủi ro, thiệt hại nếu có. Zcity cam kết sẽ nỗ lực giảm thiểu những rủi ro, thiệt hại phát sinh tuy nhiên Zcity sẽ không chịu bất cứ trách nhiệm nào phát sinh trong các trường hợp này.</li>
                <li>Zcity hoàn toàn không chịu trách nhiệm rủi ro về mọi giao dịch của bạn với bên thứ 3 trong quá trình sử dụng sản phẩm Zcity.vn. Khi bạn sử dụng sản phẩm và/hoặc giao dịch với bên thứ 3, bạn đã hiểu và đồng ý tự chịu trách nhiệm những rủi ro phát sinh.</li>
                <li>Bài giảng, sách, tài liệu, clip, video của Người Sử Dụng có thể có những hạn chế, có thể gây phản đối, bất hợp pháp, không chính xác, hoặc không phù hợp. Zcity.vn không có trách nhiệm cho bất kỳ bài giảng, sách, tài liệu, clip, video, hình ảnh nào. Nội dung được đăng không phản ánh quan điểm hay chính sách của Zcity.vn. Chúng tôi có quyền, nhưng không có nghĩa vụ, giám sát và hạn chế hoặc loại bỏ các nội dung đăng tải trên trừ khi chúng tôi có cơ sở cho rằng, nội dung được đăng tải là vi phạm thỏa thuận này cũng như vi phạm pháp luật.</li>
            </ul>
            <p className='text-large bold'>Điều 11: Nguyên tắc xử lý vi phạm và giải quyết tranh chấp, khiếu nại</p>
            Người Sử Dụng đồng ý và tuân thủ rằng, Khi Người Sử Dụng đăng kí tạo tài khoản và được Zcity cung cấp tài khoản để sử dụng một phần hoặc toàn bộ sản phẩm Zcity.vn, thì bất kỳ tranh chấp phát sinh trong quá trình sử dụng của Người Sử Dụng sẽ được giải quyết theo pháp luật hiện hành của nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam.
            <ul>
                <li>Bất kỳ khiếu nại nào phát sinh trong quá trình thanh toán và sử dụng Zcity.vn phải được gửi đến bộ phận hỗ trợ Zcity.vn ngay sau khi xảy ra sự kiện phát sinh khiếu nại. Zcity sẽ là nơi trung gian giải quyết tranh chấp giữa Người Sử Dụng với nhau. Zcity.vn sẽ căn cứ từng trường hợp cụ thể để có phương án giải quyết cho phù hợp. Khi thực hiện quyền khiếu nại, người khiếu nại có nghĩa vụ cung cấp các giấy tờ, bằng chứng, căn cứ có liên quan đến việc khiếu nại và phải chịu trách nhiệm về nội dung khiếu nại, giấy tờ, bằng chứng, căn cứ do mình cung cấp theo quy định Pháp luật.</li>
                <li>Quy trình giải quyết khiếu nại được đăng tải trên website sản phẩm.</li>
                <li>Zcity chỉ hỗ trợ, giải quyết khiếu nại, tố cáo của bạn trong trường hợp bạn đã ghi đầy đủ, trung thực và chính xác thông tin khi đăng kí tài khoản.</li>
                <li>Đối với tranh chấp giữa Người Sử Dụng Zcity.vn với nhau hoặc giữa Người Sử Dụng với Zcity, Zcity sẽ căn cứ log hệ thống để giải quyết. Theo đó, Zcity sẽ bảo vệ quyền lợi tối đa có thể cho Người Sử Dụng đăng kí đầy đủ thông tin theo quy định. Quyết định của Zcity là quyết định cuối cùng.</li>
                <li>Bạn đồng ý bảo vệ, bồi hoàn và loại trừ Zcity khỏi những nghĩa vụ pháp lý, tố tụng, tổn thất, chi phí bao gồm nhưng không giới hạn án phí, chi phí luật sư, chuyên gia tư vấn có liên quan đến việc giải quyết hoặc phát sinh từ sự vi phạm của bạn trong quá trình sử dụng Zcity.vn.</li>
            </ul>
            <p className='text-large bold'>Điều 12: Bản quyền và quy trình báo cáo vi phạm bản quyền</p>
            <ul>
                <li>Tất cả quyền sở hữu trí tuệ tồn tại trong Zcity.vn đều thuộc về Zcity hoặc được cấp phép hợp pháp cho Zcity sử dụng, theo đó, tất cả các quyền hợp pháp đều được đảm bảo. Trừ khi được sự đồng ý của Zcity, Người Sử Dụng không được phép sử dụng, sao chép, xuất bản, tài sản xuất, truyền hoặc phân phát bằng bất cứ hình thức nào, bất cứ thành phần nào các quyền sở hữu trí tuệ đối với sản phẩm Zcity.vn.</li>
                <li>Tại các khu vực được phép đăng tải nội dung, bạn có thể chia sẻ thông tin được phép dưới các định dạng chúng tôi mặc định và bạn phải tự chịu trách nhiệm đối với các nội dung, thông tin, hình ảnh và bất kỳ sự chia sẻ nào khác cũng như tính hợp pháp, các trách nhiệm pháp lý đối với nội dung, thông tin, chia sẻ của bạn với cá nhân Người Sử Dụng hoặc nhóm Người Sử Dụng, tổ chức sử dụng. Tuy nhiên, trong mọi trường hợp, chúng tôi vẫn được bảo lưu quyền xử lý các thông tin đăng tải cho phù hợp với thuần phong mỹ tục, các quy tắc đạo đức và các quy tắc đảm bảo an ninh quốc gia, và chúng tôi có toàn quyền cho phép hoặc không cho phép bài viết của bạn xuất hiện hay tồn tại trên diễn đàn hoặc tại các khu vực được phép chia sẻ thông tin.</li>
                <li>Chúng tôi có toàn quyền, bao gồm nhưng không giới hạn trong các quyền tác giả, thương hiệu, bí mật kinh doanh, nhãn hiệu và các quyền sở hữu trí tuệ khác trong sản phẩm Zcity.vn của Zcity. Việc sử dụng quyền và sở hữu của chúng tôi cần phải được chúng tôi cho phép trước bằng văn bản. Ngoài việc được cấp phép bằng văn bản, chúng tôi không cấp phép dưới bất kỳ hình thức nào khác cho dù đó là hình thức công bố hay hàm ý để bạn thực hiện các quyền trên. Và do vậy, Người Sử Dụng không có quyền sử dụng sản phẩm của chúng tôi vào mục đích thương mại mà không có sự cho phép bằng văn bản của chúng tôi trước đó.</li>
                <li>Người Sử Dụng đồng ý để chúng tôi tự do sử dụng, tiết lộ, áp dụng và sửa đổi bất kỳ ý tưởng, khái niệm, cách thức, đề xuất, gợi ý, bình luận hoặc hình thức thông báo nào khác mà Người Sử Dụng cung cấp cho chúng tôi thông qua việc sử dụng sản phẩm Zcity.vn một cách hoàn toàn miễn phí. Bạn từ bỏ và đồng ý từ bỏ bất kỳ quyền và yêu cầu với bất kỳ khoản tiền thưởng, phí, nhuận bút, lệ phí hoặc các kiểu chi trả khác liên quan đến việc chúng tôi sử dụng, tiết lộ, áp dụng, chỉnh sửa bất kỳ hoặc tất cả phản hồi của bạn.</li>
                <li>Quy trình báo cáo vi phạm bản quyền:</li>
                <li>Nếu Người Sử Dụng tin rằng bất kỳ nội dung, tài liệu, hình ảnh, video hoặc các tài liệu khác của mình được cung cấp thông qua Dịch vụ Zcity.vn đang bị vi phạm quyền sở hữu trí tuệ, vui lòng thông báo cho chúng tôi về việc vi phạm bản quyền theo quy trình cụ thể được quy định dưới đây.</li>
                <li>Chúng tôi sẽ xử lý từng thông báo vi phạm bản quyền mà chúng tôi nhận được và xử lý theo quy định của pháp luật sở hữu trí tuệ.</li>
                <li>Để giúp chúng tôi có đủ cơ sở đáp ứng các yêu cầu của bạn, vui lòng gửi thông báo bằng văn bản với các thông tin sau:</li>
                <li>+ Mô tả tác phẩm có bản quyền mà bạn cho là bị vi phạm và các giấy tờ pháp lý kèm theo.</li>
                <li>+ Mô tả về nơi mà các tài liệu bạn cho là vi phạm nằm trên các Dịch vụ Zcity.vn đủ để cho phép Zcity.vn xác định vị trí tài liệu đó.</li>
                <li>+ Thông tin liên hệ của bạn như địa chỉ, số điện thoại, email để Zcity.vn có thể liên hệ với bạn.</li>
                <li>+ Bạn tuyên bố rằng bạn tin tưởng việc sử dụng nội dung đó là không được phép của chủ sở hữu quyền tác giả, đại lý độc quyền hoặc pháp luật.</li>
                <li>Bạn tuyên bố rằng các thông tin trong thông báo của bạn là chính xác và bạn chấp nhận hình phạt về tội khai man mà bạn được ủy quyền hành động thay mặt cho chủ sở hữu bản quyền.</li>
                <li>Nếu tài khoản Zcity.vn vi phạm về sở hữu trí tuệ thông qua việc Người Sử Dụng gửi thông báo, nếu kết quả cho thấy tài khoản có sự vi phạm thì tùy vào mức độ nghiêm trọng của sự việc, Zcity có toàn quyền quyết định xóa tài khoản vi phạm đó.</li>
            </ul>
            <p className='text-large bold'>Điều 13. Quy trình giao dịch</p>
            <ul>
                <li>Mỗi Người Sử dụng đều có một tài khoản gọi là Ví $[z].</li>
                <li>Người Sử dụng có thể nạp tiền vào Ví $[z] vào Zcity.vn chuyển khoản hoặc thanh toán tự động qua hệ thống thanh toán trực tuyến.</li>
                <li>Người Sử dụng có thể dùng tiền trong Ví $[z] để tham gia các hoạt động học tập trong hệ thống Zcity.vn.</li>
            </ul>
            <p className='text-large bold'>Điều 14: Điều khoản về dung lượng sử dụng cho phép</p>
            <ul>
                <li>Mỗi Người Tạo có quyền đăng tải tối đa 2Gb nội dung, học liệu lên hệ thống tại một thời điểm.</li>
                <li>Hệ thống sẽ lưu trữ lại các nội dung, học liệu có tương tác thường xuyên của những Người Sử dụng khác.</li>
                <li>Với các nội dung, học liệu không có tương tác trong vòng 90 ngày kể từ ngày tạo thì nội dung, học liệu đó sẽ mặc định bị xóa khỏi hệ thống. Zcity.vn sẽ gửi thông báo cho Người Tạo 15 ngày trước khi nội dung, học liệu bị xóa khỏi hệ thống.</li>
            </ul>
            <p className='text-large bold'>Điều 15: Hiệu lực của thỏa thuận</p>
            <ul>
                <li>Bằng việc truy cập sử dụng website Zcity.vn, Người Sử Dụng đã đồng ý và tuân theo các điều khoản được quy định tại Thỏa thuận này.</li>
                <li>Khi truy cập, sử dụng Zcity.vn bằng bất cứ phương tiện (máy tính, điện thoại, tivi thiết bị kết nối internet) hoặc sử dụng ứng dụng Zcity.vn mà Zcity phát triển thì Người Sử Dụng cũng phải tuân theo Quy chế này.</li>
                <li>Để đáp ứng nhu cầu sử dụng của Người Sử Dụng, Zcity.vn không ngừng hoàn thiện và phát triển, vì vậy các điều khoản quy định tại Thỏa thuận cung cấp và sử dụng dịch vụ mạng xã hội Zcity.vn có thể được cập nhật, chỉnh sửa bất cứ lúc nào mà không cần phải thông báo trước tới Người Sử Dụng. Zcity.vn sẽ công bố rõ trên Website, diễn đàn về những thay đổi, bổ sung đó.</li>
                <li>Trong trường hợp một hoặc một số điều khoản Thỏa Thuận cung cấp và sử dụng dịch vụ mạng xã hội Zcity.vn này xung đột với các quy định của luật pháp Việt Nam, điều khoản đó sẽ được chỉnh sửa cho phù hợp với quy định pháp luật hiện hành, phần còn lại của Thỏa Thuận vẫn giữ nguyên giá trị.</li>
            </ul>
            <p className='text-large bold'>Địa chỉ liên lạc:</p>
            <p>CÔNG TY TNNH TỔ HỢP GIÁO DỤC KIẾN THỨC NỀN</p>
            <p>Địa chỉ: Số 125 Trần Phú, phường Văn Quán, quận Hà Đông, Hà Nội</p>
            <p>Điện thoại: 0978737871</p>
            <p>E-mail: zcity.vn@gmail.com</p>
        </div>
    </div>
)