import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import ListNewfeed from '../../components/newfeed';
import Icon from 'react-fontawesome';
import {getListNewfeedLobby, clearNewfeedData} from '../../actions/newfeed';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import BarSelect from '../../components/frame/BarSelect';

class Main extends Component {
    selectNewfeed = typeSelect =>{
        if(this.props.typeNewfeed != typeSelect || this.newfeedIdDirect > 0){
            window.scrollTo(0, 0);
            this.props.getListNewfeedLobby(1, typeSelect);
            window.history.replaceState({}, '', '/');
            this.newfeedIdDirect = '';
        }
    }

    renderNavigate = () =>(
        <BarSelect>
            <Icon
                name='child'
                className={'pv-navigate ' + (this.props.typeNewfeed == 1 && !this.newfeedIdDirect && 'success')}
                style={{fontSize: '6vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh'}}
                onClick={() => this.selectNewfeed(1)}
            />
            <Icon
                name='bar-chart'
                className={'pv-navigate ' + (this.props.typeNewfeed == 2 && !this.newfeedIdDirect && 'success')}
                style={{fontSize: '6vh', margin: '0vh 1.5vh'}}
                onClick={() => this.selectNewfeed(2)}
            />
            <Icon
                name='television'
                className={'pv-navigate ' + (this.props.typeNewfeed == 3 && !this.newfeedIdDirect && 'success')}
                style={{fontSize: '5.5vh', margin: '0vh 1.5vh', position: 'relative', top: '0.2vh'}}
                onClick={() => this.selectNewfeed(3)}
            />
        </BarSelect>
    )

    componentDidMount(){
        this.props.getListNewfeedLobby(1, 1);
    }

    componentWillUnmount(){
        this.props.clearNewfeedData();
    }

    render(){
        let {isLoading} = this.props;
        if(isLoading) return(
            <div>
                <Loading />
                {/* {this.renderNavigate()} */}
            </div>
        ) 
        return(
            <div>
                <ListNewfeed guest />
                <div style={{height: 35}} />
                {/* {this.renderNavigate()} */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { isLoading, typeNewfeed } = state.getListNewfeed;
    return { dataUser, isLoading, typeNewfeed };
}

export default connect(mapStateToProps, {getListNewfeedLobby, clearNewfeedData})(withRouter(Main));