import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionApi = (LanguageIncrementId) =>{
    let url = `${URI_PATH}app/question/english/action/getQuestion`;
    let body = {
        LanguageIncrementId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerApi = (QuestionId, Answer) =>{
    let url = `${URI_PATH}app/question/english/action/saveAnswer`;
    let body = {
        QuestionId,
        Answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
