import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { getAvatarFb, isTouch } from '../../utils/common';
import { URI_PATH, MESSAGE } from '../../utils/constants';
import Countdown, { zeroPad } from 'react-countdown-now';
import ClubDeputy from '../../components/club/ClubDeputy';
import { showHideChat, getListChatPeople } from '../../actions/chat';
import { withRouter } from 'react-router-dom';
import { inviteMemberApi } from '../../api/brand/group';
import { socket } from '../../components/realtime/ReadSocketEvent';
import { showNotification } from '../../utils/notification';
import Checked from '../frame/Checked';
import { followApi, getDataTooltipUserApi } from '../../api/user';
import moment from 'moment';

class TooltipUser extends Component {
    constructor(props) {
        super(props);
        this.isPress = false;
        this.state = {
            userInfo: this.props.userInfo,
            dataToolTip: {},
            isLoading: true,
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userInfo.IsLiked != this.props.userInfo.IsLiked){
            this.setState({
                userInfo: {
                    ...this.state.userInfo,
                    IsLiked: this.props.userInfo.IsLiked
                }
            })
        }
    }

    inviteMember = userId => {
        if (!this.isPress) {
            this.isPress = true;
            inviteMemberApi(userId).then(res => {
                showNotification(res.message, res.code);
                this.isPress = false;
                if (res.code == 1) {
                    //socket
                    let dataUser = this.props.dataUser;
                    let dataSent = {
                        userName: dataUser.FullName,
                        userAvatar: getAvatarFb(dataUser.FacebookId),
                        userId: dataUser.UserId,
                        userInivite: res.toUserId
                    };
                    socket.emit('invite_property', dataSent);
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.isPress = false;
            })
        }
    }

    loadData = () => {
        if (this.state.isLoading) {
            let guestId = this.props.userInfo.UserId;
            getDataTooltipUserApi(guestId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isLoading: false,
                        dataToolTip: res.data
                    })
                }
                else {
                    this.setState({ isLoading: false });
                    showNotification(MESSAGE.ERROR, 0);
                }
            }).catch(e => {
                this.setState({ isLoading: false });
                showNotification(MESSAGE.ERROR, 0);
            });
        }
    }

    follow = () => {
        followApi(this.props.userInfo.UserId).then(res => {
            if (res.code == 1) {
                let countFollow = parseInt(this.state.userInfo.CountFollow);
                this.setState({
                    userInfo: {
                        ...this.state.userInfo,
                        IsFollow: res.action,
                        CountFollow: res.action == 1 ? countFollow + 1 : countFollow - 1
                    }
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
            console.log(e);
        })
    }

    render() {
        let { likeTimeRemain, avatarClass, dataUser } = this.props;
        const {userInfo, dataToolTip} = this.state
        if (dataUser.UserId == userInfo.UserId) return (
            <img
                className={(avatarClass ? avatarClass : 'avatar-guest')}
                key={userInfo.UserId}
                alt='avatar-user'
                src={getAvatarFb(userInfo.FacebookId)}
            />
        )
        return (
            <Tooltip
                overlayClassName='tooltip-user'
                onVisibleChange={() => {
                    this.loadData()
                }}
                placement="right"
                trigger={isTouch() ? 'click' : 'hover'}
                title={
                    <div className='flex-column item-center mgt'>
                        <div className='flex-column item-center mgb'>
                            <img
                                src={require('../../assets/image/' + (userInfo.IsLiked == 0 ? 'like.png' : 'like-disable.png'))}
                                className='tooltip-user-icon'
                                onClick={() => userInfo.IsLiked == 0 && this.props.onLike(userInfo)}
                            />
                            {userInfo.IsLiked == 2 &&
                                <p className='text-small'>
                                    <Countdown
                                        date={moment(moment().add(1, "days").format("YYYY-MM-DD 20:30:00")).unix() * 1000}
                                        renderer={({ days, hours, minutes, seconds }) => (<span>{days}d {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                    />
                                </p>
                            }
                        </div>
                        <div className='col-rank-name'>
                            <div className='row item-center'>
                                <ClubDeputy clubRoleId={userInfo.ClubRoleId} />
                                <p>{userInfo.ClubName}</p>
                            </div>
                            <p className='bold'>{userInfo.FullName}<Checked data={userInfo} /></p>
                            <p>{userInfo.ClubRank}</p>
                        </div>
                        <div className='item-center mg'>
                            <p>{userInfo.Status}</p>
                        </div>
                        <div className={`flex-row item-center ${((dataToolTip.HouseUse || dataToolTip.CrownUse || dataToolTip.ListCrest?.length > 0) && 'hr-tooltip')}`}>
                            {dataToolTip.HouseUse &&
                                <div style={{ margin: '0 1vh' }}>
                                    <img className='house-crown' alt='Nhà' src={`${URI_PATH}${dataToolTip.HouseUse.AssetImage}`} />
                                    <p>{dataToolTip.HouseUse.AssetName}</p>
                                </div>
                            }
                            {dataToolTip.CrownUse &&
                                <div style={{ margin: '0 1vh' }}>
                                    <img className='house-crown' alt='Vương miện' src={`${URI_PATH}${dataToolTip.CrownUse.AssetImage}`} />
                                    <p>{dataToolTip.CrownUse.AssetName}</p>
                                </div>
                            }
                            {dataToolTip.ListCrest?.length > 0 &&
                                <div style={{ margin: '0 1vh' }}>
                                    <img className='bonus-crest-img' alt='Huy chương' src={require('../../assets/image/crest.png')} />
                                    <p>Huy chương Z {`${(dataToolTip.ListCrest.length > 1 ? ('x' + dataToolTip.ListCrest.length) : "")}`}</p>
                                </div>
                            }
                        </div>
                        <div className='flex-row item-center mgt-2'>
                            <img
                                src={require('../../assets/image/home.png')}
                                className='tooltip-user-icon'
                                onClick={() => this.props.history.push('/home/guest?id=' + userInfo.UserId)}
                            />
                            {/* <img src={require('../../assets/image/follow-on.png')} className='tooltip-user-icon' /> */}
                            <img
                                src={require('../../assets/image/message.png')}
                                className='tooltip-user-icon'
                                onClick={() => {
                                    this.props.getListChatPeople(userInfo.UserId);
                                    this.props.showHideChat(true, false, userInfo);
                                }}
                            />
                            <div style={{ position: 'relative' }}>
                                <img
                                    src={require('../../assets/image/' + (userInfo.IsFollow > 0 ? 'follow-on.png' : 'follow-off.png'))}
                                    className='tooltip-user-icon'
                                    onClick={this.follow}
                                />
                                <span className='count-follow'>{userInfo.CountFollow}</span>
                            </div>
                            <img
                                src={require('../../assets/image/create-group.png')}
                                className='tooltip-user-icon'
                                onClick={() => this.inviteMember(userInfo.UserId)}
                            />
                        </div>
                        <div className='mgt' id={'notificationLike' + userInfo.UserId}></div>
                    </div>
                }
            >
                <img
                    className={(avatarClass ? avatarClass : 'avatar-rank')}
                    key={userInfo.UserId}
                    alt='avatar-user'
                    src={getAvatarFb(userInfo.FacebookId)}
                />
            </Tooltip>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { showHideChat, getListChatPeople })(withRouter(TooltipUser));