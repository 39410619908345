import React, { Component } from 'react';
import {getAllPostApi} from '../../api/post';
import { MESSAGE } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import Loading from '../../components/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import Comment from '../../components/newfeed/comment';
import ListComponent from '../../components/newfeed/item';
import Icon from 'react-fontawesome';
import {getCategory, getSubCategory} from '../../actions/category/';
import {connect} from 'react-redux';
import SearchUser from '../../components/SearchUser';
import {appendListNewfeed, clearNewfeedData} from '../../actions/newfeed';
import BarSelect from '../../components/frame/BarSelect';

class Post extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            listPost: [],
            endList: false
        }
        this.pageId = 1;
        this.orderType = 'time';
        this.categoryId = '';
        this.subCategoryId = '';
        this.searchUser = '';
    }

    getPost = () =>{
        getAllPostApi(this.pageId, this.orderType, this.categoryId, this.subCategoryId, this.searchUser).then(res => {
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    endList: res.listResult.length < 10
                })
                let listPost = this.pageId == 1 ? res.listResult : [...this.props.listNewfeed, ...res.listResult]
                this.props.appendListNewfeed(listPost);
            }
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMore = () =>{
        this.pageId += 1;
        this.getPost();
    }
    
    selectTypePost = (orderType) =>{
        if(orderType != this.orderType){
            window.scrollTo(0, 0);
            this.pageId = 1;
            this.orderType = orderType;
            this.getPost();
        }
    }

    renderNavigate = () =>(
        <BarSelect>
            <Icon
                name='clock-o'
                className={'pv-navigate ' + (this.orderType == 'time' && 'success')}
                style={{fontSize: '6vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh'}}
                onClick={() => this.selectTypePost('time')}
            />
            <Icon
                name='diamond'
                className={'pv-navigate ' + (this.orderType == 'diamond' && 'success')}
                style={{fontSize: '5vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.3vh'}}
                onClick={() => this.selectTypePost('diamond')}
            />
            <Icon
                name='money'
                className={'pv-navigate ' + (this.orderType == 'donate' && 'success')}
                style={{fontSize: '6vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh'}}
                onClick={() => this.selectTypePost('donate')}
            />
        </BarSelect>
    )

    changeCategory = (value, action) =>{
        this.pageId = 1;
        window.scrollTo(0, 0);
        this.categoryId = value;
        if(!this.categoryId) this.subCategoryId = '';
        this.props.getSubCategory(this.categoryId);
        this.getPost();
    }

    deleteSearch = e =>{
        if(e.key == 'Backspace' && this.searchUser){
            this.searchUserRef.getInstance().clear();
            this.searchUser = '';
            this.pageId = 1;
            this.getPost();
        }
    }

    searchUserPost = data =>{
        this.searchUser = data[0].name;
        this.pageId = 1;
        this.getPost();
    }

    componentDidMount(){
        if(this.props.categoryPost.length == 0) this.props.getCategory(1);
        this.getPost();
    }

    componentWillUnmount(){
        this.props.clearNewfeedData();
    }

    render(){
        let {isLoading, endList} = this.state;
        let {categoryPost, subCategory, listNewfeed} = this.props;
        return(
            <>
                <div className='pv-search-container row'>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <SearchUser
                            ref={e => this.searchUserRef = e}
                            onChange={this.searchUserPost}
                            onKeyDown={this.deleteSearch}
                            placeholder='🔎 Họ tên / Số điện thoại'
                        />
                    </div>
                    <div className={'col-md-12 col-sm-12 col-xs-12'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryPost.map((item, key) =>(
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {isLoading ?
                    <Loading />
                :
                    <>
                        {listNewfeed.length == 0 ?
                            <div className='item-center'>
                                <HeaderLottie source={empty} disableLoop />
                                <p className='text-large text-center mgt'>Không có bài viết gần đây</p>
                            </div>
                        :
                            <>
                                <InfiniteScroll
                                    loadMore={this.loadMore}
                                    hasMore={!endList}
                                    loader={<Loading key={0} small/>}
                                    threshold={20}
                                >
                                    {listNewfeed.map((item, key) =>
                                        <div className='box-primary mgb' key={key}>
                                            <ListComponent.Post data={item} />
                                            <Comment data={item} />
                                        </div>
                                    )}
                                </InfiniteScroll>
                            </>
                        }
                    </>
                }
                <div style={{height: 35}} />
                {this.renderNavigate()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost, subCategory } = state.getCategory;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, categoryPost, subCategory, listNewfeed };
}

export default connect(mapStateToProps, {getCategory, getSubCategory, appendListNewfeed, clearNewfeedData})(Post);