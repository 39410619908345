import React, { Component } from 'react';
import { COLOR, MESSAGE, URI_PATH } from '../../utils/constants';
import { connect } from 'react-redux';
import { getQuestionApi, saveAnswerApi } from '../../api/question/lobby';
import { getListRankApi } from '../../api/question/lobby/rank';
import { changePoint } from '../../actions/home/';
import { getMiniusSecond, getAvatarFb, formatDecimal } from '../../utils/common';
import { showNotification, showNotificationBonus, showNotificationAsset } from '../../utils/notification';
import Loading from '../../components/Loading';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import Lottie from 'react-lottie';
import firework from '../../assets/lottie/firework.json';
import { showModalEat } from '../../actions/home/modalEat';
import questionLobbyLottie from '../../assets/lottie/questionLobby';
import HeaderLottie from '../../components/HeaderLottie';
import { Button } from 'antd';
import TooltipUser from '../../components/TooltipUser';
import ClubVipLevel from '../../components/club/ClubVipLevel';
import ClubDeputy from '../../components/club/ClubDeputy';
import Icon from 'react-fontawesome';
import InfiniteScroll from 'react-infinite-scroller';
import country from './country.json'
import * as questionStepApi from '../../api/question/step';

const ButtonGroup = Button.Group;
const images = [
    { title: '', image: require('../../assets/image/lsvn.png') },
    { title: '', image: require('../../assets/image/nh1.png') },
    { title: '', image: require('../../assets/image/nh2.png') },
    { title: '', image: require('../../assets/image/nh3.png') },
    { title: '', image: require('../../assets/image/nh4.png') },

    { title: '', image: require('../../assets/image/nh5.png') },
    { title: '', image: require('../../assets/image/vitamin.png') },
    { title: '', image: require('../../assets/image/bth.png') },

]

class QuestionTrain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percentTime: '0%',
            resultAnswer: false,
            selectAnswerKey: '',
            isPress: false,
            isAnswer: false,
            questionList: [],
            isLoadingQuestion: true,
            isLoadingRank: true,
            countCorrect: 0,
            selectNavigate: 0,
            listRank: [],
            userCorrectMax: 0,
            endList: false,
            pageId: 1,
            userBonus: {},
            bonus: [], 
            levelData: {},
            questionStepBonus: {},
        };
        this.timeDiff = 5;
        this.correctAnswer = '';
        this.resultColor = '';
        this.questionInterval = '';
    }

    getQuestionStep = () => {
        let idNotification = '#notificationStartQuestionLobby';
        this.setState({ isPress: true });
        questionStepApi.getQuestionApi(2).then(res => {
            if (res.code == 1) {
                this.questionLobby = res.questionList[0];
                this.timeDiff = res.timeDiff;
                this.setState({
                    isAnswer: false,
                    percentTime: '0%',
                    resultMessage: '',
                    selectAnswerKey: '',
                    isLoadingQuestion: false,
                    levelData: res.levelData,
                    questionStepBonus: res.bonus
                });
                this.questionInterval = setInterval(() => {
                    this.timeDiff -= 1;
                    this.setState({ percentTime: `${(30 - this.timeDiff) / 30 * 100}%` });
                    if (this.timeDiff <= -1) {
                        this.setState({
                            isAnswer: false,
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isLoadingQuestion: true
                        });
                        this.questionLobby = {};
                        this.correctAnswer = '';
                        this.timeDiff = 30;
                        clearInterval(this.questionInterval);
                        this.getQuestionStep();
                    }
                }, 1000);
            }
            else {
                if (res.code == -5) this.props.showModalEat(res.listFood);
                else showNotification(res.message, res.code, idNotification);
                this.setState({
                    isPress: false,
                });
            }
        }).catch(() => {
            showNotification(MESSAGE.ERROR, -1, idNotification);
            this.setState({
                isPress: false,
            });
        })
    }

    getQuestion = () => {
        let idNotification = '#notificationStartQuestionLobby';
        this.setState({ isPress: true });
        getQuestionApi().then(res => {
            if (res.code == 1) {
                this.questionLobby = res.questionList[0];
                this.timeDiff = res.timeDiff;
                this.setState({
                    isAnswer: false,
                    percentTime: '0%',
                    resultMessage: '',
                    selectAnswerKey: '',
                    isLoadingQuestion: false,
                    countCorrect: res.countCorrect,
                    userCorrectMax: res.userCorrectMax,
                });
                this.questionInterval = setInterval(() => {
                    this.timeDiff -= 1;
                    this.setState({ percentTime: `${(30 - this.timeDiff) / 30 * 100}%` });
                    if (this.timeDiff <= -1) {
                        this.setState({
                            isAnswer: false,
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isLoadingQuestion: true
                        });
                        this.questionLobby = {};
                        this.correctAnswer = '';
                        this.timeDiff = 30;
                        clearInterval(this.questionInterval);
                        this.getQuestion()
                    }
                }, 1000);
            }
            else {
                if (res.code == -5) this.props.showModalEat(res.listFood);
                else showNotification(res.message, res.code, idNotification);
                this.setState({
                    isPress: false,
                });
            }
        }).catch(() => {
            showNotification(MESSAGE.ERROR, -1, idNotification);
            this.setState({
                isPress: false,
            });
        })
    }

    saveAnswer = (answer, keySelect, questionId) => {
        const {selectNavigate, isAnswer} = this.state;
        if (isAnswer === false) {
            let idNotification = '#notificationQuestionGuest';
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            const isQuestionStep = selectNavigate == 0;
            const api = isQuestionStep ? questionStepApi.saveAnswerApi : saveAnswerApi;
            api(questionId, answer, 2).then(res => {
                if (res.code == 1 || res.code == 0) {
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    if (res.code == 1) {
                        if(selectNavigate == 0){
                            this.setState({
                                resultAnswer: res.code,
                                levelData: {
                                    ...this.state.levelData,
                                    MaxCorrect: +this.state.levelData.MaxCorrect + 1
                                }
                            })
                            if(res.bonus.BalanceZ || res.bonus.Balance){
                                showNotificationBonus(res.bonus.BalanceZ, 1, 3);
                                showNotificationBonus(res.bonus.Balance, 1, 2);
                                this.props.changePoint([null, res.bonus.Balance, res.bonus.BalanceZ]);
                            }
                            else{
                                showNotificationBonus(res.bonus, 1, 3);
                                this.props.changePoint([null, null, res.bonus]);
                                 //assets bonus
                                showNotificationAsset(res.assetInfo);
                            }
                        }
                        else{
                            //assets bonus
                            showNotificationAsset(res.assetInfo);
                            //
                            showNotificationBonus(res.bonus, 1, 3);
                            this.props.changePoint([null, null, res.bonus]);
                            const countCorrect = parseInt(this.state.countCorrect) + 1;
                            this.setState({
                                countCorrect,
                                userCorrectMax: countCorrect > +this.state.userCorrectMax ? countCorrect : this.state.userCorrectMax,
                                resultAnswer: res.code
                            })
                        }
                    }
                    else {
                        this.setState({ countCorrect: 0 })
                    }
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(() => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
            })
        }
    }

    getListRank = (pageId = 1) => {
        if (this.state.listRank.length == 0) this.setState({ isLoadingRank: true })
        getListRankApi('', pageId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listRank: pageId == 1 ? res.listRank : [...this.state.listRank, ...res.listRank],
                    isLoadingRank: false,
                    endList: res.listRank.length < 20,
                    pageId,
                    userBonus: res.userBonus,
                    bonus: res.bonus
                })
            }
        }).catch(() => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    selectNavigate = (selectNavigate) => {
        if (selectNavigate != this.state.selectNavigate) {
            clearInterval(this.questionInterval);
            this.timeDiff -= 1;
            this.setState({ selectNavigate });
            if (selectNavigate == 0){
                this.getQuestionStep()
            }
            if (selectNavigate == 1){
                this.getQuestion()
            }
            if (selectNavigate == 2) this.getListRank(1);
        }
    }

    buttonGroupClass = (selectNavigate) => {
        if (selectNavigate === this.state.selectNavigate) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    fontSizeRank = (rank) => {
        if (0 < rank && rank <= 9) return '6vh';
        else if (9 < rank && rank <= 99) return '5vh';
        else if (100 < rank && rank <= 999) return '4vh';
        else return '20';
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListRank(this.state.pageId + 1);
        }
    }

    renderQuestion = () => {
        const { isLoadingQuestion, selectAnswerKey, resultAnswer, percentTime, selectNavigate} = this.state;
        return (
            <>
                <div className='button-question-lobby-container'>
                    <div className="progress-div mgb" style={{ width: '100%' }}>
                        <div style={{ width: percentTime, background: this.timeDiff <= 3 ? COLOR.COLOR_DANGER : COLOR.COLOR_SUCCESS }} className="progress" />
                    </div>
                </div>
                {isLoadingQuestion ?
                    <Loading small />
                    :
                    <div className='animated fadeInUp faster'>
                        <p className='title-question'>{this.questionLobby.QuestionTitle}</p>
                        {this.questionLobby.answers.map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    onClick={() => this.saveAnswer(value, key, this.questionLobby[selectNavigate == 0 ? 'QuestionId' : 'QuestionLobbyId'])}
                                >
                                    <TextAnswer
                                        answer={value}
                                        keySelect={key}
                                        selectAnswerKey={selectAnswerKey}
                                        correctAnswer={this.correctAnswer}
                                    />
                                </div>
                            )
                        })}
                        {resultAnswer !== false && (
                            <ResultMessage resultAnswer={resultAnswer} />
                        )}
                        <div className='notificationQuestionGuest'></div>
                        {this.state.resultAnswer == 1 &&
                            <Lottie
                                options={{ loop: true, autoplay: true, animationData: firework }}
                                width='50vh'
                                style={{ position: 'absolute', top: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0 }}
                            />
                        }
                    </div>
                }
            </>
        )
    }

    renderTab = () => {
        const {
            isLoadingRank,
            countCorrect,
            listRank,
            selectNavigate,
            userCorrectMax,
            endList,
            userBonus,
            bonus,
            questionStepBonus
        } = this.state;
        switch (selectNavigate) {
            case 0:
                if(!questionStepBonus?.BalanceZ) return null;
                return (
                    <>
                        <div className='button-question-lobby-container'>
                            <p className='bold title-success full-width bg-warning'>
                                Đúng liên tiếp 100 câu để nhận thưởng {formatDecimal(questionStepBonus.BalanceZ)} đ[z] và {formatDecimal(questionStepBonus.Balance)} $[z]
                            </p>
                        </div>
                        {this.renderQuestion()}
                    </>
                )
            case 1:
                return (
                    <>
                        <div className='row'>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                                <div className='button-question-lobby-container'>
                                    <p className='bold title-success full-width'>
                                        Đúng liên tiếp: {countCorrect}
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                                <div className='button-question-lobby-container'>
                                    <p className='bold title-success full-width bg-warning'>
                                        Kỉ lục của bạn: {userCorrectMax}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {this.renderQuestion()}
                    </>
                )
            case 2:
                return (
                    <>
                        {isLoadingRank ?
                            <Loading small />
                            :
                            <>
                                <InfiniteScroll
                                    loadMore={this.loadMore}
                                    hasMore={!endList}
                                    loader={<Loading key={0} small />}
                                    threshold={100}
                                >
                                    {userBonus?.UserId && (
                                        <div className='question-lobby-bonus-container row'>
                                            <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                                <p className='bold text-huge'>TOP {userBonus?.UserQuestionLobbyBonusId}</p>
                                                <p className='text-large'>{userBonus.CrDateTime}</p>
                                                {+userBonus.MaxCorrect > 0 && (
                                                    <div className='row-info-rank item-center'>
                                                        <Icon name='flag-checkered' style={{ fontSize: '3vh' }} />
                                                        <span className='info-rank-question' style={{ marginBottom: '0.7vh', marginTop: 0 }}>{userBonus.MaxCorrect}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-md-4 col-sm-12 col-xs-12 flex item-center bonus-rank-container'>
                                                <p className='text-user-bonus-rank'> {userBonus?.UserQuestionLobbyBonusId > 10 ? "Trắng tay" : `$Z ${formatDecimal(userBonus.Bonus)}`}</p>
                                            </div>
                                            <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                                <TooltipUser userInfo={userBonus} />
                                                <p className=''>{userBonus.FullName}</p>
                                            </div>
                                        </div>
                                    )}
                                    {listRank.map((item, key) =>
                                        <div key={key} className='rank-container item-center'>
                                            <div className='col-rank-stt'>
                                                <p style={{ fontSize: this.fontSizeRank(key + 1) }}>{key + 1}</p>
                                                <div>
                                                    <img
                                                        style={{ width: '2vh', position: 'relative', bottom: '2vh' }}
                                                        src={require('../../assets/image/rank.png')}
                                                        alt='img-rank'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-rank-avatar'>
                                                <div style={{ position: 'relative' }}>
                                                    <TooltipUser
                                                        userInfo={item}
                                                    />
                                                    {item.AssetImage && (
                                                        <img
                                                            className='avatar-asset-rank'
                                                            alt='asset-user'
                                                            src={`${URI_PATH}${item.AssetImage}`}
                                                            style={{ marginLeft: '-5.5vh' }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-rank-name'>
                                                <div className='row item-center'>
                                                    <ClubDeputy clubRoleId={item.ClubRoleId} />
                                                    <p>{item.ClubName}</p>
                                                </div>
                                                <div className='level-rank'>
                                                    <ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
                                                </div>
                                                <p className='bold'>{item.FullName}</p>
                                                <p>{item.ClubRank}</p>
                                            </div>
                                            <div className='col-rank-info flex-column item-center'>
                                                <div className='row-info-rank item-center'>
                                                    <Icon name='flag-checkered' style={{ fontSize: '3vh', marginTop: '1vh' }} />
                                                    <span className='info-rank-question'>{item.MaxCorrect}</span>
                                                </div>
                                                <div className='bonus-rank-container'>
                                                    <span className='bonus-rank'>{bonus[key] ? `$Z ${formatDecimal(bonus[key])}` : '$Z 0'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </InfiniteScroll>
                            </>
                        }
                    </>
                )
            case 3:
                return (
                    <>
                        {images.map((item, key) =>
                            <img
                                src={item.image}
                                key={key}
                                className='image-theory-question-lobby'
                            />
                        )}
                        <div className='table-question-lobby-container mgt-2'>
                            <table className='table-question-lobby'>
                                <tr className='table-question-lobby-tilte'>
                                    <th>
                                        <p className='text-large'>Châu lục</p>
                                    </th>
                                    <th>
                                        <p className='text-large'>Quốc gia</p>
                                    </th>
                                    <th>
                                        <p className='text-large'>Thủ đô</p>
                                    </th>
                                </tr>
                                {country.map((item, key) =>
                                    <tr key={key}>
                                        <td width='33%'>
                                            <p>{item.continents}</p>
                                        </td>
                                        <td width='33%'>
                                            <p>
                                                {item.nation}
                                            </p>
                                        </td>
                                        <td width='34%'>
                                            <p>
                                                {item.capital}
                                            </p>
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </>
                )
        }
        return null
    }

    componentDidMount() {
        this.getQuestionStep();
    }

    componentWillUnmount() {
        clearInterval(this.questionInterval);
    }

    render() {
        const {levelData} = this.state;
        return (
            <>
                <HeaderLottie
                    source={questionLobbyLottie}
                />
                <ButtonGroup className='item-center' style={{ display: 'flex', width: '100%' }}>
                    <Button className={this.buttonGroupClass(0)} onClick={() => this.selectNavigate(0)}>Luyện tập cấp độ {levelData?.Step || 1} {`(${levelData?.MaxCorrect || '0'}/100)`}</Button>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.selectNavigate(1)}>Thi đấu</Button>
                    <Button className={this.buttonGroupClass(3)} onClick={() => this.selectNavigate(3)}>Lý thuyết</Button>
                    <Button className={this.buttonGroupClass(2)} onClick={() => this.selectNavigate(2)}>Xếp hạng ngày</Button>
                </ButtonGroup>
                {this.renderTab()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, showModalEat })(QuestionTrain);