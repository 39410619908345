import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListApi = (type, page = 1) =>{
    let url = `${URI_PATH}app/charge/blackmarket/getList`;
    let body = {
        Type: type,
        PageId: page
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getTicketCostApi = () =>{
    let url = `${URI_PATH}app/charge/blackmarket/getTicketCost`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addQueueApi = (ticketAmount, balancePerTicket, type) =>{
    let url = `${URI_PATH}app/charge/blackmarket/addQueue`;
    let body = {
        TicketAmount: ticketAmount,
        BalancePerTicket: balancePerTicket,
        Type: type
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListUserTransactionApi = (page) =>{
    let url = `${URI_PATH}app/charge/blackmarket/getListUserTransaction`;
    let body = {
        PageId: page
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const cancelTransactionApi = (blackmarketId) =>{
    let url = `${URI_PATH}app/charge/blackmarket/cancelTransaction`;
    let body = {
        BlackmarketId: blackmarketId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionTransactionApi = (blackmarketId, ticketAmount) =>{
    let url = `${URI_PATH}app/charge/blackmarket/actionTransaction`;
    let body = {
        BlackmarketId: blackmarketId,
        TicketAmount: ticketAmount
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}