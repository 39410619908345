import React, {useState} from 'react';
import {PROP} from '../../utils/constants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {getListSearchPostApi} from '../../api/post/search';
import {getListSearchVideoApi} from '../../api/video/search';
import {getListSearchAskQuestionApi} from '../../api/askquestion/search';
import { getAvatarFb } from '../../utils/common';

export default React.forwardRef((props, ref) =>{
    const [itemSearch, setItemSearch] = useState([]);

    const getType = () =>{
        switch (props.type) {
            case 'post':
                return 'bài viết';
            case 'video':
                return 'video';
            case 'ask-question':
                return 'câu hỏi';
        }
    }

    const searchPostVideo = async (id) =>{
        let dataResult
        switch (props.type) {
            case 'post':
                dataResult = await getListSearchPostApi(id);
                break;
            case 'video':
                dataResult = await getListSearchVideoApi(id);
                break;
            case 'ask-question':
                dataResult = await getListSearchAskQuestionApi(id);
                break;
        }
        if(Array.isArray(dataResult)) setItemSearch(dataResult)
        else setItemSearch([])
    }

    return(
        <AsyncTypeahead
            {...props}
            {...PROP.TYPE_AHEAD}
            minLength={1}
            ref={ref}
            onSearch={searchPostVideo}
            options={itemSearch}
            renderMenuItemChildren= {option => 
                <div className='flex-row item-center'>
                    <div className='flex-row search-user'>
                        <img alt={option.name} className='avatar-guest-small' src={getAvatarFb(option.facebookId)} />
                        <p className='search-user-text'>{option.name}</p>
                    </div>
                </div>
            }
            emptyLabel={
                <div className='flex item-center'>
                    <div className='flex-row search-user'>
                        <p className='search-user-text'>Không tìm thấy {getType()}</p>
                    </div>
                </div>
            }
        />
    )
})