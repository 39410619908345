import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListApi = () =>{
    let url = `${URI_PATH}app/charge/chargeassetmarket/getList`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyApi = (AssetId, AmountBuy) =>{
    let url = `${URI_PATH}app/charge/chargeassetmarket/buy`;
    let body = {
        AssetId,
        AmountBuy
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}