import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import { MESSAGE } from '../../../utils/constants';
import { commentNewfeedApi, replyCommentNewfeedApi, loadMoreCommentApi, loadMoreReplyCommentApi } from '../../../api/newfeed';
import Icon from 'react-fontawesome';
import TooltipUser from '../../TooltipUser';
import { getListNewfeed, getListNewfeedUser, changeDataNewfeed, clearNewfeedData } from '../../../actions/newfeed';
import InputComment from '../../newfeed/comment/InputComment';
import { withRouter } from 'react-router';
import { showNotification } from '../../../utils/notification';
import Checked from '../../frame/Checked';

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            replyNewfeedCommentId: 0,
            loadMoreCommentId: 0,
            loadMoreReplyCommentId: 0
        }
    }

    commentNewfeed = (newfeedId, comment) => {
        if (!this.state.isPress && comment.length > 0) {
            this.setState({ isPress: 1 });
            let idNotification = '#notificationCommentNewfeed' + newfeedId;
            commentNewfeedApi(newfeedId, comment).then(res => {
                if (res.code == 1) {
                    let dataUser = this.props.dataUser;
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if (el.NewfeedId == newfeedId) return Object.assign({}, el, {
                            CountComment: parseInt(el.CountComment) + 1,
                            ListComment: [
                                ...el.ListComment,
                                {
                                    NewfeedCommentId: res.newfeedCommentId,
                                    NewfeedId: newfeedId,
                                    UserId: dataUser.UserId,
                                    FacebookId: dataUser.FacebookId,
                                    FullName: dataUser.FullName,
                                    Comment: comment,
                                    ListReplyComment: [],
                                    CrDateTime: 'Vừa xong',
                                    IsChecked: dataUser.IsChecked
                                }
                            ]
                        })
                        return el;
                    }));
                }
                else showNotification(res.message, res.code, idNotification);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isPress: false })
            });
        }
    }

    replyCommentNewfeed = (newfeedCommentId, comment, newfeedId) => {
        if (!this.state.isPress && comment.length > 0) {
            this.setState({ isPress: 1 });
            let idNotification = '#notificationReplyCommentNewfeed' + newfeedCommentId;
            replyCommentNewfeedApi(newfeedCommentId, comment).then(res => {
                if (res.code == 1) {
                    let dataUser = this.props.dataUser;
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(itemNewfeed => {
                        if (itemNewfeed.NewfeedId == newfeedId) return Object.assign({}, itemNewfeed, {
                            ListComment: itemNewfeed.ListComment.map(itemComment => {
                                if (itemComment.NewfeedCommentId == newfeedCommentId) return Object.assign({}, itemComment, {
                                    CountReplyComment: parseInt(itemComment.CountReplyComment) + 1,
                                    ListReplyComment: [
                                        ...itemComment.ListReplyComment,
                                        {
                                            NewfeedCommentReplyId: res.newfeedCommentReplyId,
                                            NewfeedCommentId: newfeedCommentId,
                                            UserId: dataUser.UserId,
                                            FacebookId: dataUser.FacebookId,
                                            FullName: dataUser.FullName,
                                            Comment: comment,
                                            CrDateTime: 'Vừa xong',
                                            IsChecked: dataUser.IsChecked
                                        }
                                    ]
                                })
                                return itemComment;
                            })
                        })
                        return itemNewfeed;
                    }));
                }
                else showNotification(res.message, res.code, idNotification);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isPress: false })
            });
        }
    }

    loadMoreComment = (newfeedId) => {
        if (this.state.loadMoreCommentId != newfeedId) {
            this.setState({ loadMoreCommentId: newfeedId });
            let listCommentShow = [];
            this.props.listNewfeed.map(el => {
                if (el.NewfeedId == newfeedId) {
                    for (const [key, itemComment] of Object.entries(el.ListComment)) {
                        listCommentShow.push(itemComment.NewfeedCommentId);
                    }
                }
            });
            listCommentShow = btoa(listCommentShow.toString()); // convert to base 64
            let idNotification = '#notificationLoadMoreCommentNewfeed' + newfeedId;
            loadMoreCommentApi(newfeedId, listCommentShow).then(res => {
                if (res.code == 1) {
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if (el.NewfeedId == newfeedId) return Object.assign({}, el, {
                            ListComment: [
                                ...res.listComment,
                                ...el.ListComment
                            ]
                        })
                        return el;
                    }));
                }
                else showNotification(res.message, res.code, idNotification);
                this.setState({ loadMoreCommentId: 0 });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ loadMoreCommentId: 0 });
            });
        }
    }

    loadMoreReplyComment(newfeedCommentId, newfeedId) {
        if (this.state.loadMoreReplyCommentId != newfeedCommentId) {
            this.setState({ loadMoreReplyCommentId: newfeedCommentId });
            let listReplyCommentShow = [];
            this.props.listNewfeed.map(itemNewfeed => {
                if (itemNewfeed.NewfeedId == newfeedId) {
                    itemNewfeed.ListComment.map(itemComment => {
                        if (itemComment.NewfeedCommentId == newfeedCommentId) {
                            for (const [index, itemReply] of Object.entries(itemComment.ListReplyComment)) {
                                listReplyCommentShow.push(itemReply.NewfeedCommentReplyId);
                            }
                        }
                    })
                }
            });
            listReplyCommentShow = btoa(listReplyCommentShow.toString()); // convert to base 64
            let idNotification = '#notificationLoadMoreReplyCommentNewfeed' + newfeedCommentId;
            loadMoreReplyCommentApi(newfeedCommentId, listReplyCommentShow).then(res => {
                if (res.code == 1) {
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(itemNewfeed => {
                        if (itemNewfeed.NewfeedId == newfeedId) return Object.assign({}, itemNewfeed, {
                            ListComment: itemNewfeed.ListComment.map(itemComment => {
                                if (itemComment.NewfeedCommentId == newfeedCommentId) return Object.assign({}, itemComment, {
                                    ListReplyComment: [
                                        ...res.listReplyComment,
                                        ...itemComment.ListReplyComment,
                                    ]
                                })
                                return itemComment;
                            })
                        })
                        return itemNewfeed;
                    }));
                }
                else showNotification(res.message, res.code, idNotification);
                this.setState({ loadMoreReplyCommentId: 0 });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ loadMoreReplyCommentId: 0 });
            });
        }
    }

    targetReply = (replyNewfeedCommentId) => {
        this.setState({ replyNewfeedCommentId });
    }

    render() {
        let { replyNewfeedCommentId, loadMoreCommentId, loadMoreReplyCommentId } = this.state;
        let item = this.props.data;
        return (
            <div className='newfeed-comment-box'>
                {item.CountComment > item.ListComment.length &&
                    <div className='flex-row'>
                        {loadMoreCommentId == item.NewfeedId ?
                            <Loading tiny />
                            :
                            <div
                                onClick={() => this.loadMoreComment(item.NewfeedId)}
                            >
                                <u className='silver'><Icon name='angle-double-right' className='silver' /> Xem bình luận trước đó</u>
                                <div id={'notificationLoadMoreCommentNewfeed' + item.NewfeedId} />
                            </div>
                        }
                        <p className='ml-auto'>{item.ListComment.length}/{item.CountComment}</p>
                    </div>
                }
                {item.ListComment.length > 0 &&
                    <div>
                        {item.ListComment.map(commentData =>
                            <div className='newfeed-comment-container' key={commentData.NewfeedCommentId}>
                                <div>
                                    <TooltipUser userInfo={commentData} avatarClass='avatar-guest-small' />
                                </div>
                                <div className='newfeed-comment-body'>
                                    <span className='bold white'>{commentData.FullName}<Checked data={commentData} />: </span>
                                    <span className='pv-text-comment'>{commentData.Comment}</span>
                                    <p className='placeholder mgb-0'>
                                        <u
                                            className='color-white pointer'
                                            onClick={() => this.targetReply(commentData.NewfeedCommentId)}
                                        >
                                            Trả lời
                                        </u> • {commentData.CrDateTime}
                                    </p>
                                    {commentData.ListReplyComment.length > 0 &&
                                        <div>
                                            {commentData.CountReply > commentData.ListReplyComment.length &&
                                                <div className='mgb mgt pointer'>
                                                    {loadMoreReplyCommentId == commentData.NewfeedCommentId ?
                                                        <Loading tiny />
                                                        :
                                                        <div
                                                            onClick={() => this.loadMoreReplyComment(commentData.NewfeedCommentId, item.NewfeedId)}
                                                        >
                                                            <u className='silver'><Icon name='angle-double-right' className='silver' /> Xem phản hồi trước đó</u>
                                                            <div id={'notificationLoadMoreReplyCommentNewfeed' + commentData.NewfeedCommentId} />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {commentData.ListReplyComment.map(replyCommentData =>
                                                <div className='newfeed-comment-container mgt' key={replyCommentData.NewfeedCommentReplyId}>
                                                    <div>
                                                        <TooltipUser userInfo={replyCommentData} avatarClass='avatar-guest-small' />
                                                    </div>
                                                    <div className='newfeed-comment-body'>
                                                        <span className='bold white'>{replyCommentData.FullName}<Checked data={replyCommentData} />: </span>
                                                        <span className='pv-text-comment'>{replyCommentData.Comment}</span>
                                                        <p className='placeholder mgb-0'>
                                                            <u
                                                                className='color-white pointer'
                                                                onClick={() => this.targetReply(commentData.NewfeedCommentId)}
                                                            >
                                                                Trả lời
                                                            </u> • {replyCommentData.CrDateTime}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    }
                                    {commentData.NewfeedCommentId == replyNewfeedCommentId &&
                                        <div>
                                            <div id={'notificationReplyCommentNewfeed' + commentData.NewfeedCommentId} />
                                            <InputComment
                                                placeholder='Viết phản hồi...'
                                                onComment={inputComment => this.replyCommentNewfeed(commentData.NewfeedCommentId, inputComment, item.NewfeedId)}
                                                replyComment
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                }
                <div id={'notificationCommentNewfeed' + item.NewfeedId} />
                <InputComment
                    onComment={inputComment => this.commentNewfeed(item.NewfeedId, inputComment)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { isLoading, listNewfeed, page, typeNewfeed, endList } = state.getListNewfeed;
    return { dataUser, isLoading, listNewfeed, page, typeNewfeed, endList };
}

export default connect(mapStateToProps, { getListNewfeed, getListNewfeedUser, changeDataNewfeed, clearNewfeedData })(withRouter(Comment));