import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import {getAvatarFb, formatDecimal, priceFormat} from '../../../utils/common';
import {showNotification} from '../../../utils/notification';
import {MESSAGE, URI_PATH, KEY, PROP, COLOR} from '../../../utils/constants';
import {updateStatusApi, removeAssetApi} from '../../../api/home';
import {updateDataUser, changePoint, getUserItem, getUserBar} from '../../../actions/home';
import {updateStatusNewfeed} from '../../../actions/newfeed';
import {Button, Tooltip} from 'antd';
import ClubVipLevel from '../../club/ClubVipLevel';
import ClubDeputy from '../../club/ClubDeputy';
import {toogleUserBar} from '../../../actions/userbar';
import VipProgress from '../../home/VipProgress';
import Cookies from 'js-cookie';
import { getTimeBuyTicketApi } from '../../../api/gift/ticket';
import Loading from '../../Loading';
const axios = require('axios');

class UserBar extends Component{
    constructor(props){
        super(props);
        this.state = {
            showInfo: false,
            inputStatus: '',
            isPress: false,
            ticketData: {}
        }
        this.lastStatus = false
    }

    updateStatus = () =>{
        if(!this.state.isPress){
            let inputStatus = this.state.inputStatus.trim().replace(/(?:\r\n|\r|\n)/g, '');
            this.setState({isPress: true});
            updateStatusApi(inputStatus).then((res) => {
                showNotification(res.message, res.code, '#notificationUserStatus');
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        inputStatus: ''
                    });
                    this.props.updateStatusNewfeed(res.newfeedId, this.props.dataUser, inputStatus);
                }
                else this.setState({isPress: false});
            }).catch((e) => {
                showNotification(MESSAGE.ERROR, -1,'#notificationUserStatus');
                this.setState({isPress: false,});
            });
        }
    }

    removeAsset = assetId =>{
        if(!this.state.isPress){
            this.setState({isPress: true})
            removeAssetApi(assetId).then(res =>{
                if(res.code == 1){
                    let {dataUser, selectItem} = this.props;
                    this.props.changePoint([null, null, null, -(dataUser.Achievement - res.achievement)]);
                    this.props.getUserBar();
                    if(selectItem == 1) this.props.getUserItem(1); // reload asset item
                }
                else showNotification(res.message, res.code);
                this.setState({isPress: false});
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false})
            })
        }
    }

    navigate = (link) =>{
        this.props.history.push(link);
        this.props.toogleUserBar();
    }

    logOut = () =>{
        Cookies.remove(KEY.TOKEN);
        axios.defaults.headers.common['Auth'] = '';
        window.location = '/';
    }

    getTicketData = () =>{
        if(Object.keys(this.state.ticketData).length == 0){
            getTimeBuyTicketApi().then(res =>{
                if(res.code == 1) this.setState({
                    ticketData: res.ticketData
                });
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(e => showNotification(MESSAGE.ERROR, 0));
        }
    }

    componentDidMount(){
        this.props.getUserBar();
    }

    render(){
        let {inputStatus, isPress, ticketData} = this.state;
        let {dataUser, houseUse, crownUse, listCrest} = this.props;
        return(
            <div
                className='user-bar'
                style={{background: this.props.wallpaperData}}
            >
                <div className='row'>
                    <div className='col-xs-3 flex-column item-center'>
                        <img
                            src={getAvatarFb(dataUser.FacebookId)}
                            className='avatar-guest-huge'
                        />
                        <Button
                            className='button-logout'
                            onClick={this.logOut}
                        >
                            Đăng xuất
                        </Button>
                    </div>
                    <div className='col-xs-6 flex-column item-center'>
                        <div className='flex-row item-center'>
                            <ClubDeputy clubRoleId={dataUser.ClubRoleId} />
                            <p className='text-large mgt'>{dataUser.ClubName}</p>
                        </div>
                        <div className='level-rank mgt'>
							<ClubVipLevel className='star-rank' vipLevel={dataUser.ClubVipLevel} />
						</div>
                        <p className='text-large mgt'>{dataUser.FullName}</p>
                        <p className='text-large'>{dataUser.ClubRoleRank}</p>
                    </div>
                    <div className='col-xs-3 item-center'>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/vip.png')} alt='vip-level' />
                            <span className='info-rank'>{dataUser.UserVip}</span>
                        </div>
                        {/* <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/follow-on.png')} alt='achievement-level' />
                            <span className='info-rank'>{dataUser.CountFollow}</span>
                        </div> */}
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/rank.png')} alt='rank' />
                            <span className='info-rank'>{dataUser.Rank}</span>
                        </div>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/like.png')} alt='like-level' />
                            <span className='info-rank'>{dataUser.UserLike}</span>
                        </div>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/achievement.png')} alt='achievement-level' />
                            <span className='info-rank'>{dataUser.Achievement}</span>
                        </div>
                    </div>
                </div>
                <VipProgress dataUser={dataUser} />
                <div className='row'>
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <div className='item-center'>
                                <p>Đz là tiền thưởng, được sử dụng nội bộ, trong các hoạt động của HỌC VIỆN Z ONLINE.</p>
                                <p>
                                    Săn đ[z] tại
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/question-lobby')}
                                    >
                                        [Siêu trí nhớ],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/charge-invest')}
                                    >
                                        [Chứng khoán],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/gift-day')}
                                    >
                                        [Newday Gift],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/gift-event')}
                                    >
                                        [Event Gift],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/gift-train')}
                                    >
                                        [Tu luyện],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/newfeed')}
                                    >
                                        [Đính kim cương],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/newfeed')}
                                    >
                                        [Thả tim]
                                    </span>
                                </p>
                            </div>
                        }
                    >
                        <div className='col-xs-4 bar-cost'>{formatDecimal(dataUser.BalanceZ)} đ[z]</div>
                    </Tooltip>
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <div className='item-center'>
                                <p>Vnđ[z] là tiền thưởng, được sử dụng nội bộ, trong các hoạt động của HỌC VIỆN Z ONLINE.</p>
                                <p>Săn $[z] tại hoạt động 
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/gift-spin')}
                                    >
                                        [Quà thần bí],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/gift-effort')}
                                    >
                                        [Grown Gift],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/gift-day')}
                                    >
                                        [New day Gifts],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/charge-invest')}
                                    >
                                        [Chứng khoán],
                                    </span>
                                    {/* <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/post-publish')}
                                    >
                                        [Đăng bài viết],
                                    </span>
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/video-publish')}
                                    >
                                        [Đăng video],
                                    </span> */}
                                    <span
                                        className='link-desc-balance'
                                        onClick={() => this.navigate('/menu/charge-affiliate')}
                                    >
                                        [Vòng tròn học tập]
                                    </span>
                                </p>
                            </div>
                        }
                    >
                        <div className='col-xs-4 bar-cost'>{formatDecimal(dataUser.Balance)} $[z]</div>
                    </Tooltip>
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        onVisibleChange={this.getTicketData}
                        title={Object.keys(ticketData).length > 0 ?
                            <div className='item-center'>
                                <p>1 Gold[z] = {priceFormat(ticketData.TicketCost)} $[z]</p>
                                <p>Sử dụng thanh toán học phí tại Học Viện Z</p>
                                <p>Gold[z] tăng +{priceFormat(ticketData.TicketPerDay)} $[z]/ngày</p>
                                <p>Săn Gold[z] tại hoạt động
                                    <span
                                        style={{color: COLOR.COLOR_SUCCESS, marginLeft: 5}}
                                        onClick={() => this.navigate('/menu/gift-ticket')}
                                    >
                                        [Phá phong ấn]
                                    </span>
                                </p>
                            </div>
                        :
                            <Loading small />
                        }
                    >
                        <div className='col-xs-4 bar-cost'>{parseFloat(dataUser.TicketCount)} Gold[z]</div>
                    </Tooltip>
                </div>
                <div id='notificationUserStatus'/>
                <div className="row">
                    <div className='col-md-12 col-sm-12 col-xs-12 item-center'>
                        <div
                            className='user-bar-status bg-primary pointer'
                            onClick={() => this.refs.inputStatus.focus()}
                        >
                            <p>Hôm nay bạn học được điều gì? 😇</p>
                            <textarea
                                className='text-center input-status'
                                placeholder='Nhập nội dung'
                                value={inputStatus}
                                onChange={e => this.setState({inputStatus: e.target.value})}
                                onKeyDown={e => e.key == 'Enter' && e.preventDefault()}
                                ref='inputStatus'
                            />
                            {inputStatus.length > 0 &&
                                <div className='item-center'>
                                    <Button
                                        className='bg-success mgb'
                                        onClick={this.updateStatus}
                                        loading={isPress}
                                    >
                                        Đăng tâm trạng
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {houseUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{houseUse.AssetName}</p>
                                            <p>Tăng {houseUse.AssetBonus} % thành tựu</p>
                                            <div className='flex-row item-center'>
                                                <Button
                                                    className='btn-remove-house-crown'
                                                    onClick={() => this.removeAsset(houseUse.AssetId)}
                                                    loading={isPress}
                                                >
                                                    Tháo
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Nhà' src={`${URI_PATH}${houseUse.AssetImage}`}/>
                                </Tooltip>
                            :
                                <p className='silver'>Xây nhà</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {crownUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{crownUse.AssetName}</p>
                                            <p>Tăng {crownUse.AssetBonus} % thành tựu</p>
                                            <div className='flex-row item-center'>
                                                <Button
                                                    className='btn-remove-house-crown'
                                                    onClick={() => this.removeAsset(crownUse.AssetId)}
                                                    loading={isPress}
                                                >
                                                    Tháo
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Vương miện' src={`${URI_PATH}${crownUse.AssetImage}`}/>
                                </Tooltip>
                            :
                                <p className='silver'>Đúc vương miện</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {listCrest.length > 0 ?
                                <div className='flex-row'>
                                    {listCrest.map((item, key) =>
                                        <Tooltip
                                            {...PROP.TOOLTIP_HOME}
                                            key={key}
                                            overlayClassName='black-tooltip'
                                            title={
                                                <div className='item-center'>
                                                    <p>Đã đạt được huy chương ngày: {item.CrDateTime}</p>
                                                </div>
                                            }
                                        >
                                            <img className='bonus-crest-img' src={require('../../../assets/image/crest.png')} />
                                        </Tooltip>
                                    )}
                                </div>
                            :
                                <p className='silver'>Huy chương Z</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser, houseUse, crownUse, listCrest } = state.getUserData;
    const { selectItem } = state.getUserItem;
    const { wallpaperData } = state.getWallpaper;
    return { dataUser, houseUse, crownUse, listCrest, selectItem, wallpaperData };
}

export default connect(mapStateToProps, {changePoint, updateDataUser, getUserItem, getUserBar, updateStatusNewfeed, toogleUserBar})(withRouter(UserBar));