import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionOwnApi = (page = 1) =>{
    let url = `${URI_PATH}app/question/own/getQuestionOwn`;
    let body = {
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkAddQuestionApi = () =>{
    let url = `${URI_PATH}app/question/own/checkAddQuestion`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getCategoryApi = () =>{
    let url = `${URI_PATH}app/question/own/getCategory`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addQuestionApi = (questionTitle, answer1, answer2, answer3, answer4) =>{
    let url = `${URI_PATH}app/question/own/update`;
    let body = {
        QuestionTitle: questionTitle,
        Answear1: answer1,
        Answear2: answer2,
        Answear3: answer3,
        Answear4: answer4,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}