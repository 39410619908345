import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {getListCart, deleteCartAsset} from '../../actions/charge/asset';
import {getListAssetApi, buyAssetApi} from '../../api/charge/asset';
import {MESSAGE, URI_PATH, PROP} from '../../utils/constants';
import {getAvatarFb, formatDecimal, getDataNotify} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import asset from '../../assets/lottie/asset.json';
import HeaderLottie from '../../components/HeaderLottie';
import ListSell from '../../components/charge/ListSell';
import {Button, Modal} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import {socket} from '../../components/realtime/ReadSocketEvent';
import TooltipUser from '../../components/TooltipUser';

class ChargeAsset extends Component{
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            isPressBuy: false,
            errMessage: '',
            amountBuy: '',
            calculateBalance: '',
            selectCart: [],
            selectAsset: false,
            balanceSelect: ''
        };
        this.isPressOpen = false;
    }

    loadMore = () => !this.props.endList && this.props.getListCart(this.props.page + 1);

    showModal = (item) =>{
        if(this.isPressOpen === false){
            getListAssetApi(item.UserId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        showModal: true,
                        selectCart: item,
                        listAsset: res.listAsset
                    });
                    this.isPressOpen = false;
                }
                else{
                    showNotification(res.message, res.code);
                    this.isPressOpen = false;
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, -1);
                this.isPressOpen = false;
            })
        }
    }

    selectAsset = (userassetmarketId, balance, balanceName) =>{
        if(this.state.selectAsset != userassetmarketId){
            this.setState({
                selectAsset: userassetmarketId,
                balanceSelect: balance,
                balanceNameSelect: balanceName,
                amountBuy: '',
                calculateBalance: ''
            });
        }
    }

    changeAmount = e =>{
        let amountBuy = e.target.value.replace(/,|\./g, '');;
        if(!this.state.selectAsset) return showNotification('Vui lòng chọn vật phẩm cần mua', 0, '#notificationChargeAsset');
        this.setState({
            amountBuy: amountBuy,
            calculateBalance:  amountBuy > 0 ? formatDecimal(`${(amountBuy * this.state.balanceSelect).toString()}`) : '',
        });
    }

    hideModal = () =>{
        this.setState({
            isPressBuy: false,
            amountBuy: '',
            calculateBalance: '',
            balanceSelect: '',
            balanceNameSelect: '',
            selectAsset: false,
            showModal: false
        })
    }

    buy = () =>{
        if(this.state.isPressBuy === false){
            let userassetmarketId = this.state.selectAsset;
            let amountBuy = this.state.amountBuy;
            let idNotification = '#notificationChargeAsset';
            if(userassetmarketId == '' || userassetmarketId <= 0) return showNotification('Vui lòng chọn vật phẩm cần mua', 0, idNotification);
            if(amountBuy == '' || amountBuy < 1) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPressBuy: true});
            buyAssetApi(amountBuy, userassetmarketId).then(res =>{
                if(res.code == 1){
                    if(this.state.listAsset.length == 1 && amountBuy == this.state.listAsset[0].Amount){
                        this.props.deleteCartAsset(this.state.selectCart.FoodcartId);
                    }
                    this.hideModal();
                    showNotification(res.message, res.code);
                    let balanceType = parseInt(res.balanceType);
                    let datas;
                    switch(balanceType) {
                        case 1:
                            showNotificationBonus(-res.balance, -1, 1);
                            this.props.changePoint([-res.balance]);
                            datas = getDataNotify(res.toUserId, res.balance);
                            break;
                        case 2:
                            showNotificationBonus(-res.balance, -1, 2);
                            this.props.changePoint([null, -res.balance]);
                            datas = getDataNotify(res.toUserId, 0, parseInt(res.balance));
                            break;
                        case 3:
                            showNotificationBonus(-res.rest, -1, 2);
                            showNotificationBonus(-res.balance, -1, 3);
                            this.props.changePoint([null, -res.rest, -res.balance]);
                            datas = getDataNotify(res.toUserId, 0, 0, parseInt(res.balance) + parseInt(10 * res.rest));
                            break;
                    }
                    //socket
                    datas.message = {
                        fullName: res.fullName,
                        facebookId: res.facebookId,
                        message: 'mua ' + res.amount + ' ' + res.assetName + ' từ bạn',
                        isNotify : 2
                    }
                    socket.emit('send_notify', datas);
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPressBuy: false});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({
                    isPressBuy: false
                });
            })
        }
    }

    componentDidMount(){
        this.props.getListCart();
    }

    render(){
        let {loading, listCart, endList} = this.props;
        let {showModal, selectCart, listAsset, selectAsset, amountBuy, calculateBalance, balanceNameSelect, isPressBuy} = this.state;
        if(loading) return (<Loading />);
        return(
            <div>
                <HeaderLottie source={asset} disableLoop />
                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    <div className="row mgt">
                        {listCart.map((item, key) =>(
                            <ListSell
                                item={item}
                                key={key} 
                                onClick={() => this.showModal(item)}
                            />
                        ))}
                    </div>
                </InfiniteScroll>
                {showModal &&
                    <Modal
                        {...PROP.MODAL}
                        width={'90%'}
                        visible={showModal}
                        onCancel={this.hideModal}
                        centered
                    >
                        <div className='item-center'>
							<TooltipUser userInfo={selectCart} avatarClass='avatar-guest-large mgt'/>
                            <p className='text-center mgt text-large'>{selectCart.FullName}</p>
                            <div className='row flex-row mgt-2 item-center'>
                                {listAsset.map((item, key) =>
                                    <div
                                        key={key}
                                        className={`col-md-2 col-sm-3 col-xs-4 item-block item-center ${(selectAsset == item.UserassetmarketId) && 'bg-success'}`}
                                        onClick={() => this.selectAsset(item.UserassetmarketId, item.Balance, item.BalanceName)}
                                    >
                                        <img className='item-in-cart' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                                        <p>{item.Amount}</p>
                                        <p>{item.AssetName}</p>
                                        <p>{formatDecimal(`${item.Balance} ${item.BalanceName}`)}</p>
                                    </div>
                                )}
                            </div>
                            <div className='row mgt item-center'>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div id='notificationRenewCart'></div>
                                    <input
                                        className='form-control text-center mgt'
                                        placeholder='Nhập số lượng'
                                        value={`${amountBuy}`}
                                        onChange={this.changeAmount}
                                    />
                                    {this.state.calculateBalance &&
                                        <p className='text-center mgt'>Hệ thống sẽ trừ: {calculateBalance} {balanceNameSelect}</p>
                                    }
                                    <div className='mgt' id='notificationChargeAsset' />
                                    <Button
                                        className='bg-success mgt'
                                        loading={isPressBuy}
                                        onClick={this.buy}
                                    >
                                        Xác nhận
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { listCart, loading, endList, page } = state.getListCartAsset;
    const { dataUser } = state.getUserData;
    return { listCart, loading, endList, page, dataUser };
}

export default connect(mapStateToProps, {changePoint, getListCart, deleteCartAsset})(ChargeAsset);