import React from 'react';

export default (props) =>{
    if(props.clubRoleId == 1) return (
        <img src={require('../../assets/image/crown.png')} className='club-deputy-image' />
    )
    else if(props.clubRoleId == 2) return (
        <img src={require('../../assets/image/crown-silver.png')} className='club-deputy-image' />
    )
    return null;
}