import { render } from '@testing-library/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { showErrorZcrazy } from '../../utils/notification';
import CheckDownloadFlatform from './CheckDownloadFlatform';

class ItemMenu extends Component{
    checkZcrazy = (event) =>{
        if(this.props.dataMenu.isCheckZcrazy){
            if(this.props.dataUser.IsZcrazy != 2) {
                event.preventDefault();
                showErrorZcrazy()
            }
        }
    }

    render(){
        const {countMission, countNotify, dataMenu} = this.props;
        if(dataMenu.url){
            return(
                <Link to={dataMenu.url} className='grid-menu item-center' onClick={this.checkZcrazy}>
                    <img
                        className='icon-menu'
                        src={dataMenu.image}
                        alt={dataMenu.name}
                    />
                    <p className='text-center mgt-2'>{dataMenu.name}</p>
                    {dataMenu.mission && parseInt(countMission[dataMenu.mission]) > 0 &&
                        <div className='menu-mission-contaner shadow-pulse'>
                            <p className='mgb-0'>{countMission[dataMenu.mission]}</p>
                        </div>
                    }
                    {dataMenu.notify && parseInt(countNotify[dataMenu.notify]) > 0 &&
                        <div className='menu-mission-contaner bg-danger'>
                            <p className='mgb-0'>{countNotify[dataMenu.notify]}</p>
                        </div>
                    }
                </Link>
            )
        }
        return(
            <CheckDownloadFlatform />
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(ItemMenu)