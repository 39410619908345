import React from 'react';
import Icon from 'react-fontawesome';

const iconName = {
    2: 'clock-o',
    1: 'check',
    0: 'times',
}

const message = {
    2: ' Cùng chờ xem đáp án ...',
    1: ' Chính xác!',
    0: ' Không chính xác!',
}

const classResult = {
    2: 'answer-question bg-primary mgt-3 text-center',
    1: 'answer-question bg-success mgt-3 text-center',
    0: 'answer-question bg-danger mgt-3 text-center',
}

export default (props) =>{
    let {resultAnswer} = props;
    return(
        <p className={classResult[resultAnswer]}>
            <Icon name={iconName[resultAnswer]}/>
            {message[resultAnswer]}
        </p>
    )
};