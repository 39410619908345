import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { getAvatarFb } from '../../utils/common';
import { TitleSuccess } from '../../components/frame';
import { addAdminApi, deleteAdminApi, getListAdminApi } from '../../api/admin/study/admin';
const ButtonGroup = Button.Group;

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            isLoading: true,
            listAdmin: [],
            showModal: false,
            inputFullName: '',
            inputPhoneNumber: '',
            inputPassword: '',
            inputRepassword: '',
            inputSubject: '',
            inputHotline: '',
            adminInfo: {},
            isEdit: false
        }
    }

    getListAdmin = () => {
        getListAdminApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listAdmin: res.listAdmin
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    renderModal = () => {
        const {
            modalType,
            inputSubject,
            inputFullName,
            inputPassword,
            inputPhoneNumber,
            inputRepassword,
            adminInfo,
            isPress,
            inputHotline,
            isEdit
        } = this.state;
        switch (modalType) {
            case 1:
                return (
                    <>
                        <TitleSuccess>{isEdit ? 'Sửa Admin' : 'Thêm Admin'}</TitleSuccess>
                        <input
                            className='form-control mgt'
                            value={inputSubject}
                            onChange={e => this.setState({ inputSubject: e.target.value })}
                            placeholder='Nhập tên môn học'
                        />
                        <input
                            className='form-control mgt'
                            value={inputHotline}
                            onChange={e => this.setState({ inputHotline: e.target.value })}
                            placeholder='Nhập Hottine môn học'
                        />
                        <input
                            className='form-control mgt'
                            value={inputFullName}
                            onChange={e => this.setState({ inputFullName: e.target.value })}
                            placeholder='Nhập họ tên'
                        />
                        <input
                            className='form-control mgt'
                            value={inputPhoneNumber}
                            onChange={e => this.setState({ inputPhoneNumber: e.target.value })}
                            placeholder='Nhập số điện thoại'
                        />
                        <input
                            className='form-control mgt'
                            value={inputPassword}
                            onChange={e => this.setState({ inputPassword: e.target.value })}
                            placeholder='Nhập mật khẩu'
                            type='password'
                        />
                        <input
                            className='form-control mgt'
                            value={inputRepassword}
                            onChange={e => this.setState({ inputRepassword: e.target.value })}
                            placeholder='Nhập lại mật khẩu'
                            type='password'
                        />
                        <div className='text-center'>
                            <Button
                                className='bg-success mgt-2'
                                onClick={this.addAdmin}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </>
                )
            case 2:
                return(
                    <div className='item-center'>
                        <p className='text-center text-large mgt-3'>Xác nhận xóa ?</p>
                        <p className='text-center mgt-2'>{adminInfo.FullName} - {adminInfo.PhoneNumber}</p>
                        <Button
                            className='bg-danger mgt-4'
                            loading={isPress}
                            onClick={this.deleteAdmin}
                        >
                            Xác nhận
                        </Button>
                    </div>
                )
        }
        return null;
    }

    addAdmin = () => {
        if (!this.state.isPress) {
            const { inputFullName, inputPassword, inputPhoneNumber, inputRepassword, inputSubject, inputHotline, adminInfo } = this.state;
            if (inputSubject && inputHotline && inputFullName && inputPassword && inputPhoneNumber && inputPassword == inputRepassword) {
                this.setState({ isPress: true })
                addAdminApi(inputSubject, inputHotline, inputFullName, inputPhoneNumber, inputPassword, adminInfo.UserId).then(res => {
                    showNotification(res.message, res.code);
                    if (res.code == 1) {
                        this.getListAdmin();
                        this.setState({isPress: false, showModal: false})
                    }
                    else this.setState({isPress: false})
                }).catch(error => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({isPress: false})
                })
            }
            else if (!inputSubject) showNotification('Tên môn học đang để trống', 0);
            else if (!inputHotline) showNotification('Hotline đang để trống', 0);
            else if (!inputFullName) showNotification('Họ tên đang để trống', 0);
            else if (!inputPhoneNumber) showNotification('Số điện thoại đang để trống', 0);
            else if (!inputPassword) showNotification('Mật khẩu đang để trống', 0);
            else if (inputPassword != inputRepassword) showNotification('Mật khẩu không trùng nhau', 0);
        }
    }

    deleteAdmin = () =>{
        if (!this.state.isPress) {
            this.setState({ isPress: true })
            deleteAdminApi(this.state.adminInfo.UserId).then(res =>{
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.getListAdmin();
                    this.setState({isPress: false, showModal: false})
                }
                else this.setState({isPress: false})
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false})
            })
        }
    }

    componentDidMount() {
        this.getListAdmin();
    }

    render() {
        const { listAdmin, isLoading, isPress, showModal } = this.state;
        return (
            <>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <div className='item-center mgt-2 mgb-2'>
                            <Button
                                className='bg-success'
                                onClick={() => {
                                    this.setState({
                                        showModal: true,
                                        modalType: 1,
                                        adminInfo: {},
                                        inputFullName: '',
                                        inputPhoneNumber: '',
                                        inputPassword: '',
                                        inputRepassword: '',
                                        inputHotline: '',
                                        inputSubject: '',
                                        isEdit: false
                                    })
                                }}
                            >
                                Thêm admin
                            </Button>
                        </div>
                        {listAdmin.map((item, key) =>
                            <div className='row box-primary relative align-center' key={key} style={{ justifyContent: 'space-between' }}>
                                <div className='flex-row align-center'>
                                    <img className='avatar-guest-small mgr' src={getAvatarFb(item.FacebookId)} />
                                    <div>
                                        <p className='mgb-0'>{item.FullName} - {item.PhoneNumber}</p>
                                        <p className='mgb-0'>{item.Subject}</p>
                                    </div>
                                </div>
                                <div className='flex-row'>
                                    <img
                                        src={require('../../assets/image/pen.png')}
                                        style={{ width: '3vh' }}
                                        className='pointer mgr'
                                        onClick={() => this.setState({
                                            adminInfo: item,
                                            showModal: true,
                                            modalType: 1,
                                            inputFullName: item.FullName,
                                            inputPhoneNumber: item.PhoneNumber,
                                            inputPassword: '',
                                            inputRepassword: '',
                                            inputSubject: item.Subject,
                                            inputHotline: item.Hotline,
                                            isEdit: true
                                        })}
                                    />
                                    <img
                                        src={require('../../assets/image/delete-red.png')}
                                        style={{ width: '3vh' }}
                                        className='pointer mgl'
                                        onClick={() => this.setState({
                                            adminInfo: item,
                                            showModal: true,
                                            modalType: 2
                                        })}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Account)