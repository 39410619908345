import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { toogleUserBar } from '../../actions/userbar';
import { connect } from 'react-redux';
import { showNotification } from '../../utils/notification';
import HeaderLottie from '../../components/HeaderLottie';
import guide from '../../assets/lottie/guide.json'
import { Modal } from 'antd';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import Loading from '../../components/Loading';
import { getListTargetLobbyApi } from '../../api/menu/target';
import { videoGuides } from '../../utils/common';
import {Collapse} from 'react-collapse';
import Icon from 'react-fontawesome';
import { getContentApi } from '../../api/admin/zcrazy';

class Guide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            content: ''
        }
    }
    getContent = () => {
        getContentApi().then(res =>{
            const html = res.data?.Content
            this.setState({
                content: html,
                isLoading: false
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidMount() {
        this.getContent();
    }

    render() {
        const { isLoading, content } = this.state;
        if (isLoading) return <Loading small />
        return (
           <div className='contenter-content-zcrazy' dangerouslySetInnerHTML={{__html: content}} />
        )
    }
}

export default connect(null, { toogleUserBar })(withRouter(Guide));