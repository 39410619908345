import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import ListNewfeed from '../../components/newfeed';
import Icon from 'react-fontawesome';
import { getListNewfeed, clearNewfeedData, getListNewfeedUser, updateStatusNewfeed } from '../../actions/newfeed';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import BarSelect from '../../components/frame/BarSelect';
import HeaderLottie from '../../components/HeaderLottie';
import teamwork from '../../assets/lottie/teamwork.json';
import { Button } from 'antd';
import { updateStatusApi } from '../../api/home';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.newfeedIdDirect = queryString.parse(this.props.location.search).id;
        this.commentIdDirect = queryString.parse(this.props.location.search).comment;
        this.userId = this.props.dataUser.UserId;
        this.state = {
            typeNewfeed: 1,
            inputStatus: '',
            isPress: false
        }
    }

    selectNewfeed = typeSelect => {
        if (this.state.typeNewfeed != typeSelect || this.newfeedIdDirect > 0) {
            window.scrollTo(0, 0);
            if ([1, 2, 3, 4].includes(typeSelect)) this.props.getListNewfeed(1, typeSelect);
            else this.props.getListNewfeedUser(1, this.userId);
            this.setState({ typeNewfeed: typeSelect })
            window.history.replaceState({}, '', '/new-feed');
            this.newfeedIdDirect = '';
        }
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.typeNewfeed) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    updateStatus = () => {
        if (!this.state.isPress) {
            let inputStatus = this.state.inputStatus.trim().replace(/(?:\r\n|\r|\n)/g, '');
            this.setState({ isPress: true });
            updateStatusApi(inputStatus).then((res) => {
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        inputStatus: ''
                    });
                    if(this.state.typeNewfeed != 4){
                        this.selectNewfeed(4)
                    }
                    else this.props.updateStatusNewfeed(res.newfeedId, this.props.dataUser, inputStatus);
                }
                else this.setState({ isPress: false });
            }).catch((e) => {
                showNotification(MESSAGE.ERROR, -1);
                this.setState({ isPress: false, });
            });
        }
    }

    componentDidMount() {
        this.props.getListNewfeed(1, 1, this.newfeedIdDirect, this.commentIdDirect);
        if (!this.newfeedIdDirect) window.history.replaceState({}, '', '/new-feed');
    }


    componentWillUnmount() {
        this.props.clearNewfeedData();
    }

    render() {
        const { isLoading } = this.props;
        const { typeNewfeed, inputStatus, isPress } = this.state;
        return (
            <div>
                {/* <HeaderLottie
                    source={teamwork}
                    size={'25vh'}
                /> */}
                <div className="row">
                    <div className='col-md-12 col-sm-12 col-xs-12 item-center'>
                        <div
                            className='user-bar-status bg-primary pointer'
                            onClick={() => this.refs.inputStatus.focus()}
                        >
                            <p className='mgt'>Hôm nay bạn học được điều gì? 😇</p>
                            <textarea
                                className='text-center input-status'
                                placeholder='Nhập nội dung'
                                value={inputStatus}
                                onChange={e => this.setState({ inputStatus: e.target.value })}
                                onKeyDown={e => e.key == 'Enter' && e.preventDefault()}
                                ref='inputStatus'
                            />
                            {inputStatus.length > 0 &&
                                <div className='item-center'>
                                    <Button
                                        className='bg-success mgb'
                                        onClick={this.updateStatus}
                                        loading={isPress}
                                    >
                                        Đăng tâm trạng
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.selectNewfeed(1)}>Sự kiện</Button>
                        <Button className={this.buttonGroupClass(4)} onClick={() => this.selectNewfeed(4)}>Diễn đàn</Button>
                        {/* <Button className={this.buttonGroupClass(2)} onClick={() => this.selectNewfeed(2)}>Bài viết hay</Button>
                        <Button className={this.buttonGroupClass(3)} onClick={() => this.selectNewfeed(3)}>Video hay</Button> */}
                        <Button className={this.buttonGroupClass(5)} onClick={() => this.selectNewfeed(5)}>Cá nhân</Button>
                    </ButtonGroup>
                </div>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        {/* <div className='item-center'>
                            {typeNewfeed == 2 &&
                                <Button
                                    className='newfeed-button mgb'
                                    onClick={() => this.props.history.push('/menu/post-publish')}
                                >
                                    Đăng bài viết
                                </Button>
                            }
                            {typeNewfeed == 3 &&
                                <Button
                                    className='newfeed-button mgb'
                                    onClick={() => this.props.history.push('/menu/video-publish')}
                                >
                                    Đăng video
                                </Button>
                            }
                        </div> */}
                        <ListNewfeed userId={typeNewfeed == 5 ? this.userId : null} />
                        <div style={{ height: 35 }} />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { isLoading, typeNewfeed } = state.getListNewfeed;
    return { dataUser, isLoading, typeNewfeed };
}

export default connect(mapStateToProps, { getListNewfeed, clearNewfeedData, getListNewfeedUser, updateStatusNewfeed })(withRouter(Main));