import React, { Component } from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {getListVideoApi} from '../../api/video/mine';
import {MESSAGE} from '../../utils/constants';
import {randomNumber, getYoutubeId, getTiktokId} from '../../utils/common';
import {showNotification} from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import {getCategory, getSubCategory} from '../../actions/category/';
import { withRouter } from 'react-router'
import TooltipUser from '../../components/TooltipUser';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import BarSelect from '../../components/frame/BarSelect';

class VideoMine extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            data: {},
            isEmpty: false,
            isPress: false,
        };
        this.num = 0;
        this.categoryId = '';
        this.subCategoryId = '';
    }

    getVideo = () =>{
        let {num, categoryId, subCategoryId} = this;
        getListVideoApi(num, categoryId, subCategoryId).then((res) => {
            if(res.code == 1){
                this.setState({
                    data: res.data,
                    isLoading: false,
                    isEmpty: false
                });
            }
            else this.setState({isEmpty: true, isLoading: false});
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    loadMoreVideo = (type) =>{
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        let nextMax = parseInt(this.state.data.countVideo);
        if(this.directVideo > 0) this.directVideo = '';
        switch(type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() =>{
            this.getVideo();
        }, 200)
    }

    renderNavigate = () =>(
        <BarSelect style={{width: '25vh'}}>
            <Icon
                name='caret-left'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMoreVideo('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{fontSize: '4vh', margin: '0vh 1vh'}}
                onClick={() => this.loadMoreVideo('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMoreVideo('next')}
            />
        </BarSelect>
    )

    changeCategory = (value, action) =>{
        if(this.directVideo > 0) this.directVideo = '';
        this.num = 0;
        this.categoryId = value;
        if(!this.categoryId) this.subCategoryId = '';
        this.props.getSubCategory(this.categoryId);
        this.getVideo();
    }

    componentDidMount(){
        this.getVideo();
        if(this.props.categoryPost.length == 0) this.props.getCategory(1);
    }

    render(){
        let {isLoading, isEmpty, data} = this.state;
        let {categoryPost, subCategory} = this.props;
        let dataVideo = data.dataVideo;
        if(isLoading){
            return (
                <Loading />
            );
        }
        return(
            <div style={{paddingBottom: '8vh'}}>
                <div className='flex item-center'>
                    <p className='bold title-success'>
                        {this.props.countMission.VideoVerifyCount > 0 ?
                            'Video hôm nay được xét duyệt bạn sẽ vượt qua thử thách'
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div>
                <div className='pv-search-container row'>
                    <div className={'col-md-12 col-sm-12 col-xs-12'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryPost.map((item, key) =>(
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {isEmpty ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không có video cần tìm</p>
                    </div>
                :
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        <div className='flex-row mgt-2'>
                            <div className='flex-row flex-3 align-center'>
                                <TooltipUser userInfo={dataVideo} avatarClass='avatar-guest' key={dataVideo.UserId}/>
                                <div className='pv-user-container'>
                                    <p className='bold'>{dataVideo.FullName}</p>
                                    <p className='placeholder black'>{dataVideo.CrDateTime}</p>
                                </div>
                            </div>
                            <div className='flex-row flex-2 align-center' style={{justifyContent: 'flex-end'}}>
                                {dataVideo.Status == 1 ?
                                    <p className='pv-check-p bg-danger'>Không duyệt</p>
                                :
                                    <>
                                        {dataVideo.VideoStatusId > 0 ?
                                            <p className='pv-check-p bg-success'>Đã xét duyệt</p>
                                        :
                                            <p className='pv-check-p bg-warning'>Đang xét duyệt</p>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className='flex-column'>
                            <p className='text-large bold text-center'>{dataVideo.CategoryName}</p>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <p className='text-large text-center bold mgt mgb'>{dataVideo.VideoTitle}</p>
                            <div className='flex-row flex-3 item-center mgb mgt'>
                                <p className='pv-check-p mgb-0' style={{ background: 'rgba(0,0,0,0.85)' }}>Tôi chia sẻ video này vì: {dataVideo.VideoDesc}</p>
                            </div>
                            <div className='item-center mgt'>
                                {dataVideo.VideoType == 2 ?
                                    <div className='iframe-tiktok'>
                                        <iframe
                                            scrolling="no"
                                            name="__tt_embed__v3802385945065390"
                                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                                            src={`https://www.tiktok.com/embed/v2/${getTiktokId(dataVideo.VideoUrl)}?lang=vi&amp;`}
                                        />
                                    </div>
                                :
                                <iframe
                                    key={dataVideo.CrDateTime}
                                    className='iframe-video'
                                    src={"https://www.youtube.com/embed/" + getYoutubeId(dataVideo.VideoUrl)}
                                    frameBorder="0"
                                    allow="encrypted-media"
                                    allowFullScreen="allowFullScreen"
                                />
                                }
                                
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost } = state.getCategory;
    const { countMission } = state.getCountMission;
    return { dataUser, categoryPost, countMission };
}

export default connect(mapStateToProps, { changePoint, getCategory, getSubCategory })(withRouter(VideoMine));