import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MESSAGE, TICKET, PROP, BALANCE_TYPE } from '../../../utils/constants';
import { formatDecimal, replaceCost, getDecimalCount } from '../../../utils/common';
import { showNotification, showNotificationBonus, showNotificationFightMessage } from '../../../utils/notification';
import Loading from '../../../components/Loading';
import { Button, Modal, Checkbox } from 'antd';
import { TitleSuccess, EmptyLottie } from '../../../components/frame';
import { getRoomInfoApi, leaveRoomApi, editRoomApi, readyApi, changeTeamApi, kickUserApi, chatApi, getListInviteApi, sendInviteNotificationApi, toggleLookApi, getListRoomChatApi } from '../../../api/question/fight/room';
import { getCategory } from '../../../actions/category';
import TooltipUser from '../../../components/TooltipUser';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Icon from 'react-fontawesome';
import Emoji from '../../../components/chat/Emoji';
import { ButtonActionFight, ReadyTitle } from '../../../components/question/fight';
import { socket } from '../../../components/realtime/ReadSocketEvent';
import { changePoint } from '../../../actions/home';
import SearchUser from '../../../components/SearchUser';
import { getQuestionLevelSelectApi } from '../../../api/question/fight/fight';
import { joinRoomApi } from '../../../api/question/fight/lobby';

class ChatFight extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            listChat: [],
            isLoading: true,
            showEmoji: false,
            inputChat: '',
            isSendChat: false,
        };
    }

    sendChat = () => {
        let { inputChat, isSendChat } = this.state
        if (!isSendChat && inputChat.trim()) {
            this.setState({
                isSendChat: true,
                inputChat: ''
            });
            chatApi(inputChat).then(res => {
                if (res.code == 1) {
                    let roomId = res.roomId;
                    let dataUser = this.props.dataUser;
                    let dataChat = {
                        QuestionFightRoomId: roomId,
                        UserId: dataUser.UserId,
                        FullName: dataUser.FullName,
                        FacebookId: dataUser.FacebookId,
                        Message: inputChat,
                        CrDateTime: 'Vừa xong'
                    }
                    this.updateChatState(dataChat);
                    //socket
                    socket.emit('user_chat_fight', {
                        roomId,
                        dataChat
                    });
                }
                else showNotification(res.message, res.code);
                this.setState({ isSendChat: false });
            }).catch(e => {
                this.setState({ isSendChat: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    updateChatState = (dataChat) => {
        this.setState({
            listChat: [
                dataChat,
                ...this.state.listChat
            ]
        });
    }

    pickEmoji = e => {
        let emoji = e.target.firstChild.nodeValue;
        this.setState({ inputChat: this.state.inputChat + emoji })
    }

    getListChat = () =>{
        getListRoomChatApi(this.props.roomId).then(res =>{
            if(res.code == 1){
                this.setState({
                    listChat: res.listChat
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    userChatSocket = data => {
        this.updateChatState(data);
        showNotificationFightMessage(data)
    }

    componentDidMount(){
        this.getListChat();
        this.props.onUpdateChat(this.updateChatState);
        //read socket
        socket.on('user_chat_fight', this.userChatSocket);
    }

    componentWillUnmount() {
        //remove socket event
        socket.removeListener('user_chat_fight', this.userChatSocket);
        //
    }

    render(){
        let {
            listChat,
            showEmoji,
            inputChat,
        } = this.state;
        return(
            <>
                <div className='chat-fight-container'>
                    <div className='input-chat-fight-container'>
                        <div className='flex item-center'>
                            <input
                                className='input-chat-fight'
                                placeholder='Nhập nôi dung'
                                value={inputChat}
                                onChange={e => this.setState({ inputChat: e.target.value })}
                                onFocus={() => this.setState({ showEmoji: false })}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        this.sendChat();
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <Icon
                                name='smile-o'
                                className={'emoji-chat-fight-select ' + (showEmoji ? 'success' : null)}
                                onClick={() => this.setState({ showEmoji: !showEmoji })}
                                onMouseEnter={() => this.setState({ showEmoji: true })}
                            />
                            <Icon
                                name='paper-plane'
                                className='emoji-chat-fight-select'
                                onClick={this.sendChat}
                            />
                        </div>
                        {showEmoji &&
                            <Emoji onPick={this.pickEmoji} onEndPick={() => this.setState({ showEmoji: false })} />
                        }
                    </div>
                    <div className='chat-fight-message-container'>
                        {listChat.map((item, key) =>
                            <div className='chat-fight-message' key={key}>
                                <TooltipUser userInfo={item} avatarClass='avatar-message' />
                                <div className='chat-fight-message-item'>
                                    <p className='mgb-0'>{item.Message}</p>
                                    <p className='mgb-0 mgl placeholder'>{item.CrDateTime}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(ChatFight));