import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getCategory, getSubCategory} from '../../actions/category/';
import {changePoint} from '../../actions/home/';
import {updateCountMission, updateCountNotify} from '../../actions/menu';
import {publishAskQuestionApi} from '../../api/askquestion/publish';
import {MESSAGE, PROP} from '../../utils/constants';
import Icon from 'react-fontawesome';
import {showNotification, showNotificationBonus, showNotificationAsset} from '../../utils/notification';
import {getImage64} from '../../utils/common';
import {Button, Modal} from 'antd';
import {withRouter} from 'react-router-dom';
import {showModalEat} from '../../actions/home/modalEat';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class PostPublish extends Component{
    constructor(props){
        super(props);
        this.state = {
            askQuestionTitle: '',
            askQuestionContent: '',
            askQuestionImage: '',
            categoryId: '',
            subCategoryId: '',
            isPublish: false,
        };
    }

    changeCategory = e =>{
        let categoryId = e.target.value;
        this.setState({categoryId});
        this.props.getSubCategory(categoryId);
    }

    publishAskQuestion = () =>{
        let {categoryId, subCategoryId, askQuestionTitle, askQuestionContent, askQuestionImage, isPublish} = this.state;
        let idNotification = '#notificationPublishAskQuestion';
        if(!isPublish){
            if(categoryId > 0 && subCategoryId > 0 && askQuestionTitle && askQuestionContent){
                this.setState({isPublish: true});
                publishAskQuestionApi(categoryId, subCategoryId, askQuestionTitle, askQuestionContent, askQuestionImage).then(res => {
                    if(res.code == 1){
                        let bonus = res.bonus;
                        showNotificationBonus(bonus.Achievement, 1, 4);
                        showNotificationAsset(res.assetInfo);
                        showNotificationBonus(bonus.BalanceZ, 1, 3);
                        this.props.changePoint([null, null, bonus.BalanceZ, bonus.Achievement]);
                        this.props.updateCountNotify('AskQuestionVoteCount', 1);
                        showNotification(res.message, res.code);
                        this.props.history.push('/menu/ask-question-vote?id=' + res.askQuestionId);
                    }
                    else{
                        if(res.code == -5) this.props.showModalEat(res.listFood);
                        else showNotification(res.message, res.code, idNotification);
                        this.setState({isPublish: false});
                    }
                })
                .catch(error => {
                    this.setState({isPublish: false, isPublish: false});
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({showModalConfirm: false});
                })
            }
            else{
                this.setState({showModalConfirm: false});
                if(!categoryId) showNotification('Bạn chưa chọn thể loại.', -1, idNotification);
                else if(!subCategoryId) showNotification('Bạn chưa chọn thể loại con.', -1, idNotification);
                else if(!askQuestionTitle) showNotification('Bạn chưa nhập tiêu đề.', -1, idNotification);
                else if(!askQuestionContent) showNotification('Bạn chưa nhập nội dung.', -1, idNotification);
            }
        }
    }

    uploadImage = event =>{
        let file = event.target.files[0];
        getImage64(file, askQuestionImage =>{
            this.setState({askQuestionImage});
        })
    }

    componentDidMount(){
        this.props.getCategory(4);
    }

    render(){
        let {categoryAskQuestion, subCategory} = this.props;
        let {showModalPublish, askQuestionContent, askQuestionTitle, askQuestionImage, isPublish} = this.state;
        return(
            <div className='publish-post-container'>
                <div className='item-center'>
                    <p className='bold text-gigantic'>NAY HỎI - MAI GIỎI.</p>
                    <i className='mgt'>Hãy đăng câu hỏi nếu chưa có ai hỏi giống bạn.</i>
                    <div className='row mgt item-center'>
                        <div className='col-md-6 col-sm-8 col-xs-12'>
                            <select
                                className='form-control'
                                onChange={this.changeCategory}
                            >
                                <option value=''>Chọn thể loại</option>
                                {categoryAskQuestion.map((item, key) =>(
                                    <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {subCategory.length > 0 &&
                        <div className='row mgt item-center'>
                            <div className='col-md-6 col-sm-8 col-xs-12'>
                                <select
                                    className='form-control'
                                    onChange={e => this.setState({subCategoryId: e.target.value})}
                                >
                                    <option value=''>Chọn thể loại con</option>
                                    {subCategory.map((item, key) =>{
                                        return <option key={key} value={item.SubCategoryId}>{item.SubCategoryName}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <p>Câu hỏi:</p>
                    <div className='flex-row item-center'>
                        <input
                            className='form-control'
                            placeholder='Ví dụ: Tôi muốn hỏi về cách phân chia cổ phần.'
                            value={askQuestionTitle}
                            onChange={e => this.setState({askQuestionTitle: e.target.value})}
                        />
                        <input
                            type='file'
                            hidden='hidden'
                            ref='inputImage'
                            onChange={this.uploadImage}
                        />
                        <Icon
                            name='camera'
                            className='publish-camera'
                            onClick={() => this.refs.inputImage.click()}
                        />
                    </div>
                    {askQuestionImage &&
                        <div className='flex-column item-center mgt'>
                            <img src={askQuestionImage} style={{width: '30vh'}}/>
                            <img
                                src={require('../../assets/image/delete.png')}
                                className='delete-image mgt'
                                onClick={() =>{
                                    this.refs.inputImage.value = '';
                                    this.setState({askQuestionImage: ''})}
                                }
                            />
                        </div>
                    }
                    <p className='mgt'>Mô tả chi tiết:</p>
                    <div
                        className='mgt'
                        style={{cursor: 'text'}}
                        onClick={() => this.refs.askQuestionContent.focus()}
                    >
                        <ReactQuill
                            ref='askQuestionContent'
                            value={askQuestionContent}
                            onChange={askQuestionContent => this.setState({askQuestionContent})}
                            modules={{toolbar: [['bold', 'italic', 'underline']]}}
                            placeholder='Mô tả chi tiết tình huống, thông tin, người có liên quan, thời gian, địa điểm, sản phẩm,…'
                        />
                    </div>
                    <div className='item-center'>
                        <Button
                            className='mgt-4 bg-success'
                            style={{width: '20vh'}}
                            onClick={() => this.setState({showModalPublish: true})}
                        >
                            Đăng
                        </Button>
                    </div>
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalPublish}
                    onCancel={() => this.setState({showModalPublish: false})}
                    bodyStyle={{background: '#FCF150', borderRadius: '2vh'}}
                    closable={false}
                >
                    <div className='flex-column'>
                        <div className='item-center'>
                            <img
                                src={require('../../assets/image/warning.png')}
                                style={{width: '8vh'}}
                            />
                        </div>
                        <p className='black bold text-large'>Bạn sẽ không thể chỉnh sửa nội dung sau khi đăng tải!</p>
                        <p className='black'>Bạn vui lòng kiểm tra kĩ một lần nữa:</p>
                        <p className='black'>1- Nội dung câu hỏi phù hợp cộng đồng khởi nghiệp.</p>
                        <p className='black'>2- Hãy kiểm tra kỹ chính tả trong câu hỏi.</p>
                        <p className='black'>3- Câu hỏi không vi phạm điều khoản, vi phạm pháp luật.</p>
                        <p className='black'>4- Ngôn từ sử dụng không xúc phạm, gây mất uy tín danh dự người khác.</p>
                        <div className='mgt' id='notificationPublishAskQuestion'></div>
                        <div className='flex-row mgt-3'>
                            <div className='flex-1 item-center'>
                                <Button
                                    className='bg-success'
                                    onClick={this.publishAskQuestion}
                                    loading={isPublish}
                                >
                                    Tôi đồng ý
                                </Button>
                            </div>
                            <div className='flex-1 item-center'>
                                <Button
                                    className='bg-danger'
                                    onClick={() => this.setState({showModalPublish: false})}
                                >
                                    Kiểm tra lại
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { categoryAskQuestion, subCategory } = state.getCategory;
    const { dataUser } = state.getUserData;
    return { categoryAskQuestion, subCategory, dataUser };
}

export default connect(mapStateToProps, {getCategory, getSubCategory, changePoint, updateCountMission, showModalEat, updateCountNotify})(withRouter(PostPublish));