import { getListCartApi, getFoodInCartApi, getAssetInCartApi} from '../../api/charge/sell';

export const updateItemInCart = (type, listItem) =>{
    return dispatch => {
        dispatch({ type: 'UPDATE_ITEM_IN_CART', listItem: listItem, typeUpdate: type });
    };
}

export const getItemInCart = (selectItem) =>{
    return dispatch => {
        if(selectItem == 1){
            getFoodInCartApi().then((res) => {
                dispatch({ type: 'SUCCESS_GET_FOOD_IN_CART', listFoodInCart: res.listFoodInCart, sellData: res.sellData, selectItem: selectItem });
            })
            .catch(error => dispatch({ type: 'FALSE_GET_FOOD_IN_CART' }));
        }
        else if(selectItem == 2){
            getAssetInCartApi().then((res) => {
                dispatch({ type: 'SUCCESS_GET_ASSET_IN_CART', listAssetInCart: res.listAssetInCart, sellData: res.sellData, selectItem: selectItem });
            })
            .catch(error => dispatch({ type: 'FALSE_GET_ASSET_IN_CART' }));
        }
    }
}

export const getListCart = () =>{
    return dispatch => {
        getListCartApi().then((res) => {
            dispatch({ type: 'SUCCESS_GET_LIST_CART', listCart: res.listCart });
        })
    }
}

export const toggleCartAction = (value, type) =>{
    return dispatch => {
        dispatch({ type: 'TOGGLE_CART', value: value, typeCart: type });
    };
}

export const selectCartAction = (item) =>{
    return dispatch => {
        dispatch({ type: 'SELECT_CART', selectCart: item });
    };
}

export const updateListCart = (type) =>{
    return dispatch => {
        getListCartApi(type).then((res) => {
            dispatch({ type: 'UPDATE_LIST_CART', listCart: res.listCart, sellData: res.sellData });
        });
    }
}