const INITAL_STATE = {
    studyTab: null,
    menuTab: null
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'UPDATE_STATE_NAVIGATE':
            return {
                ...state,
                studyTab: action.payload?.studyTab || state.studyTab,
                menuTab: action.payload?.menuTab || state.menuTab
            };
        default:
            return state;
    }
};