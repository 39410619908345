import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchUser from '../../../components/SearchUser';
import { withRouter } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { showNotification } from '../../../utils/notification';
import { addUserSupportByManageApi, getListRankSupportApi } from '../../../api/admin/study/support';
import { MESSAGE, URI_PATH } from '../../../utils/constants';
import Loading from '../../Loading';
import TooltipUser from '../../TooltipUser';
import ClubDeputy from '../../club/ClubDeputy';
import ClubVipLevel from '../../club/ClubVipLevel';
import { getAvatarFb, isTouch, priceFormat } from '../../../utils/common';

class SupportRank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listRank: [],
            isPress: false,
            isLoading: true
        }
    }

    getListRank = () =>{
        getListRankSupportApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listRank: res.listRank
                })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    fontSizeRank = (rank) => {
        if (0 < rank && rank <= 9) return '6vh';
        else if (9 < rank && rank <= 99) return '5vh';
        else if (100 < rank && rank <= 999) return '4vh';
        else return '20';
    }

    componentDidMount(){
        this.getListRank()
    }

    render() {
        const { listRank, isLoading } = this.state;
        const {dataUser} = this.props;
        return (
            <>
                {isLoading ?
                    <Loading small /> :
                    <div>
                        {listRank.map((item, key) =>
                            <div key={key} className='rank-container item-center'>
                                <div className='col-rank-stt'>
                                    <div>
                                        <p style={{ fontSize: this.fontSizeRank(key + 1) }}>{key + 1}</p>
                                    </div>
                                    <div>
                                        <img
                                            style={{ width: '2vh', position: 'relative', bottom: '2vh' }}
                                            src={require('../../../assets/image/rank.png')}
                                            alt='img-rank'
                                        />
                                    </div>
                                </div>
                                <div className='col-rank-avatar'>
                                    <div style={{ position: 'relative' }}>
                                        {item.UserId != dataUser.UserId ?
                                            <TooltipUser
                                                userInfo={item}
                                            />
                                            :
                                            <img className='avatar-rank' key={item.UserId} alt='avatar-user' src={getAvatarFb(item.FacebookId)} />
                                        }
                                        {item.AssetImage && (
                                            <img className='avatar-asset-rank' alt='asset-user' src={`${URI_PATH}${item.AssetImage}`} />
                                        )}
                                    </div>
                                </div>
                                <div className='col-rank-name'>
                                    <div className='row item-center'>
                                        <ClubDeputy clubRoleId={item.ClubRoleId} />
                                        <p>{item.ClubName}</p>
                                    </div>
                                    <div className='level-rank'>
                                        <ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
                                    </div>
                                    <p className='bold'>{item.FullName}</p>
                                    <p>{item.ClubRank}</p>
                                </div>
                                <div className='col-support-info'>
                                    <Tooltip
                                        overlayClassName='tooltip-user'
                                        placement="top"
                                        trigger={isTouch() ? 'click' : 'hover'}
                                        title={
                                            <p>Tổng số lượng học viên đang phụ trách</p>
                                        }
                                    >
                                        <div className='round-count-support'>
                                            <img src={require('../../../assets/image/help.png')} className='icon-support-rank'/>
                                            <p className='mgb-0 text-large'>{item.CountSupport}</p>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='col-support-info'>
                                    <Tooltip
                                        overlayClassName='tooltip-user'
                                        placement="top"
                                        trigger={isTouch() ? 'click' : 'hover'}
                                        title={
                                            <p>Tổng số lượng học viên online hôm nay</p>
                                        }
                                    >
                                    <div className='round-count-support'>
                                        <img src={require('../../../assets/image/calendar.png')} className='icon-support-rank'/>
                                        <p className='mgb-0 text-large'>{item.CountOnline}</p>
                                    </div>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(SupportRank));