import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getCategory, getSubCategory} from '../../actions/category/';
import {changePoint} from '../../actions/home/';
import {updateCountMission, updateCountNotify} from '../../actions/menu';
import {publishVideoApi} from '../../api/video/publish';
import {MESSAGE, PROP} from '../../utils/constants';
import {showNotification, showNotificationBonus, showNotificationAsset} from '../../utils/notification';
import {getYoutubeId, getTiktokId} from '../../utils/common';
import {Button, Modal} from 'antd';
import {withRouter} from 'react-router-dom';
import {showModalEat} from '../../actions/home/modalEat';

class VideoPublish extends Component{
    constructor(props){
        super(props);
        this.state = {
            videoUrl: '',
            videoDesc: '',
            categoryId: '',
            subCategoryId: '',
            showPreviewVideo: false,
            isPublish: false,
            videoTitle: '',
            isTiktok: false
        };
    }

    changeCategory = e =>{
        let categoryId = e.target.value;
        this.setState({categoryId});
        this.props.getSubCategory(categoryId);
    }

    checkYoutubeUrl = e =>{
        let url = e.target.value;
        if(getYoutubeId(url) || getTiktokId(url)){
            this.setState({
                videoUrl: url,
                showPreviewVideo: true,
                isTiktok: !getYoutubeId(url)
            });
        }
        else{
            this.setState({
                videoUrl: url,
                showPreviewVideo: false
            });
        }
    }

    publishVideo = () =>{
        let {categoryId, videoUrl, videoDesc, videoTitle, isPublish, isTiktok} = this.state;
        let idNotification = '#notificationPublishVideo';
        if(!isPublish){
            if(videoTitle && categoryId > 0 && (getYoutubeId(videoUrl) || getTiktokId(videoUrl))){
                this.setState({isPublish: true});
                publishVideoApi(categoryId, videoDesc, videoUrl, videoTitle, isTiktok).then(res => {
                    if(res.code == 1){
                        // let bonus = res.bonus;
                        // showNotificationBonus(bonus.Achievement, 1, 4);
                        // showNotificationAsset(res.assetInfo);
                        // showNotificationBonus(bonus.BalanceZ, 1, 3);
                        // this.props.changePoint([null, null, bonus.BalanceZ, bonus.Achievement]);
                        // this.props.updateCountMission('VideoPublishCount');
                        // this.props.updateCountNotify('VideoVoteCount', 1);
                        showNotification(res.message, res.code);
                        this.props.history.push('/menu/video');
                    }
                    else{
                        if(res.code == -5) this.props.showModalEat(res.listFood);
                        else showNotification(res.message, res.code, idNotification);
                        this.setState({isPublish: false});
                    }
                })
                .catch(error => {
                    this.setState({isPublish: false, isPublish: false});
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({showModalPublish: false});
                })
            }
            else{
                this.setState({showModalPublish: false});
                if(!videoTitle) showNotification('Bạn chưa nhập tiêu đề của video.', -1, idNotification);
                else if(!categoryId) showNotification('Bạn chưa chọn thể loại.', -1, idNotification);
                else if(!getYoutubeId(videoUrl)) showNotification('Link video từ youtube không đúng.', -1, idNotification);
                else if(!videoDesc) showNotification('Bạn chưa nhập lý do đăng video', -1, idNotification);
            }
        }
    }

    componentDidMount(){
        this.props.getCategory(1);
    }

    render(){
        let {categoryPost} = this.props;
        let {showModalPublish, videoDesc, videoUrl, isPublish, showPreviewVideo, videoTitle, isTiktok} = this.state;
        return(
            <div className='publish-post-container'>
                <div className='item-center'>
                    <p className='bold text-gigantic'>KIẾN THỨC HAY CHIA SẺ LIỀN TAY.</p>
                    <i className='mgt'>Sưu tầm hoặc làm ra một video chất lượng là bạn đã giúp bao người không phải đổ máu trên thương trường.</i>
                    <div className='row mgt item-center'>
                        <div className='col-md-6 col-sm-8 col-xs-12'>
                            <select
                                className='form-control'
                                onChange={this.changeCategory}
                            >
                                <option value=''>Chọn thể loại</option>
                                {categoryPost.map((item, key) =>(
                                    <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <p>Tiêu đề:</p>
                    <div className='flex-row item-center'>
                        <input
                            className='form-control'
                            placeholder='Nhập tiêu đề video'
                            value={videoTitle}
                            onChange={e => this.setState({videoTitle: e.target.value})}
                        />
                    </div>
                    <p className='mgt'>Link video từ youtube hoặc tiktok:</p>
                    <div className='flex-row item-center'>
                        <input
                            className='form-control'
                            placeholder='Nhập link từ youtube hoặc tiktok'
                            value={videoUrl}
                            onChange={this.checkYoutubeUrl}
                        />
                    </div>
                    {showPreviewVideo === true && (
                        <>
                            {isTiktok ?
                                <div className='iframe-tiktok'>
                                    <iframe
                                        scrolling="no"
                                        name="__tt_embed__v3802385945065390"
                                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                                        src={`https://www.tiktok.com/embed/v2/${getTiktokId(videoUrl)}?lang=vi&amp;`}
                                    />
                                </div>
                            :
                            <div className='item-center mgt'>
                                <iframe
                                    src={"https://www.youtube.com/embed/" + getYoutubeId(videoUrl)}
                                    frameBorder="0"
                                    allow="encrypted-media"
                                    allowFullScreen="allowFullScreen"
                                    style={{width: '100%', height: '60vh', borderRadius: '2vh'}}
                                />
                            </div>
                            }
                        </>
                    )}
                    {/* <TikTok url='https://www.tiktok.com/@datdo0803/video/7149523719226952987?is_from_webapp=1&sender_device=pc' /> */}
                    <p className='mgt'>Tại sao bạn muốn chia sẻ video này tới mọi người? Hoặc mọi người sẽ học được gì từ video này?</p>
                    <textarea
                        className='form-control'
                        placeholder='Lý do'
                        style={{minHeight: '20vh'}}
                        value={videoDesc}
                        onChange={e => this.setState({videoDesc: e.target.value})}
                    />
                    <div className='mgt' id='notificationPublishVideo'></div>
                    <div className='item-center'>
                        <Button
                            className='mgt-4 bg-success'
                            style={{width: '20vh'}}
                            onClick={() => this.setState({showModalPublish: true})}
                        >
                            Đăng
                        </Button>
                    </div>
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalPublish}
                    onCancel={() => this.setState({showModalPublish: false})}
                    bodyStyle={{background: '#FCF150', borderRadius: '2vh'}}
                    closable={false}
                >
                    <div className='flex-column'>
                        <div className='item-center'>
                            <img
                                src={require('../../assets/image/warning.png')}
                                style={{width: '8vh'}}
                            />
                        </div>
                        <p className='black text-large bold mgt'>Bạn sẽ chịu trách nhiệm với nội dung bạn đăng tải!</p>
                        <p className='black'>Lưu ý:</p>
                        <p className='black'>1- Nội dung đăng tải có ích với cộng đồng khởi nghiệp.</p>
                        <p className='black'>2- Nội dung trong video không vi phạm pháp luật nhà nước Việt Nam.</p>
                        <p className='black'>3- Video không xúc phạm, gây mất uy tín danh dự người khác.</p>
                        <div className='flex-row mgt-2'>
                            <div className='flex-1 item-center'>
                                <Button
                                    className='bg-success'
                                    onClick={this.publishVideo}
                                    loading={isPublish}
                                >
                                    Đăng video
                                </Button>
                            </div>
                            <div className='flex-1 item-center'>
                                <Button
                                    className='bg-danger'
                                    onClick={() => this.setState({showModalPublish: false})}
                                >
                                    Kiểm tra lại
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { categoryPost, subCategory } = state.getCategory;
    const { dataUser } = state.getUserData;
    return { categoryPost, subCategory, dataUser };
}

export default connect(mapStateToProps, {getCategory, getSubCategory, changePoint, updateCountMission, showModalEat, updateCountNotify})(withRouter(VideoPublish));