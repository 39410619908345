import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListVideoApi = (num, categoryId, subCategoryId, searchUser, directVideo) =>{
    let url = `${URI_PATH}app/video/vote/getListVideo`;
    let body = {
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        SearchUser: searchUser,
        Num: num,
        DirectVideo: directVideo
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getVoteRemainApi = () =>{
    let url = `${URI_PATH}app/video/vote/getVoteRemain`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionVoteApi =(videoqueueId, isVote) =>{
    let url = `${URI_PATH}app/video/vote/actionVote`;
    let body = {
        VideoqueueId: videoqueueId,
        IsVote: isVote
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}