import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Switch, Checkbox } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { updateLessonApi, getListLessonApi, getListPropertyAndCategoryApi } from '../../../api/admin/study';
import { showNotification } from '../../../utils/notification';
import ReactQuill from 'react-quill';
import ModalAddLesson from '../../../components/admin/study/ModalAddLesson';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../components/Loading';
import HeaderLottie from '../../../components/HeaderLottie';
import empty from '../../../assets/lottie/empty.json';
import { getAvatarFb } from '../../../utils/common';
import TooltipUser from '../../../components/TooltipUser';
import { EmptyLottie, TitleSuccess } from '../../../components/frame';
import Countdown, { zeroPad } from 'react-countdown-now';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getListSupportFromStudentApi, toggleFavoriteSupportApi, toggleSecureSupportApi,  updateAlertSupportApi, updateSupportApi, toggleAlertSupportApi, changeUrlSupportApi, changeLevelSupportApi } from '../../../api/admin/study/support';
import StudySummary from '../../../screens/study/StudySummary';

const NAME_REPEAT = ['Không lặp lại', 'Hàng ngày', 'Hàng tuần', 'Hàng tháng', 'Hàng năm'];

class SupportDetail extends Component {
    constructor(props) {
        super(props);
        this.userSelect = props.userInfo;
        this.state = {
            isPress: false,
            pageId: 1,
            isLoading: true,
            listSupport: [],
            endList: false,
            inputContent: '',
            inputTime: '',
            inputRepeat: 0,
            isFavorite: false,
            showModal: false,
            modalType: 1,
            orderSelect: [],
            supportSelect: {},
            inputFacebookUrl: this.userSelect.FacebookUrl,
            inputZaloUrl: this.userSelect.ZaloUrl,
            inputYoutubeUrl: this.userSelect.YoutubeUrl,
            inputTiktokUrl: this.userSelect.TiktokUrl,
            inputLevel: this.userSelect.Level
        }
        this.isManage = props.isManage;
        this.inputContentRef = ''
    }

    updateSupport = () => {
        let { inputContent, isFavorite, inputTime, inputRepeat, isPress } = this.state;
        if (inputContent == '<p><br></p>') inputContent = '';
        if (!isPress && inputContent) {
            isFavorite = isFavorite ? 2 : 0;
            inputTime = moment(inputTime).format("YYYY-MM-DD HH:mm");
            inputTime = inputTime == 'Invalid date' ? null : inputTime;
            updateSupportApi(inputContent, isFavorite, inputTime, inputRepeat, this.userSelect.UserId,).then(res => {
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        inputContent: '',
                    })
                    this.getListSupportFromStudent()
                }
                else {
                    this.setState({ isPress: false })
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
        else if(!inputContent) showNotification('Nội dung không được để trống', 0)
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListSupportFromStudent(this.state.pageId + 1, this.state.orderSelect);
        }
    }

    getListSupportFromStudent = (pageId = 1, orderSelect = []) => {
        getListSupportFromStudentApi(this.state.pageId, orderSelect, this.userSelect.UserId).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listSupport: pageId == 1 ? res.data : [...this.state.listSupport, ...res.data],
                    endList: res.data.length < 20,
                    pageId,
                    orderSelect
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    orderList = (type) => {
        let { orderSelect } = this.state;
        orderSelect = orderSelect.includes(type) ? orderSelect.filter(i => i != type) : [...orderSelect, type]
        this.getListSupportFromStudent(1, orderSelect);
    }

    editSupport = (type, data) => {
        if (!this.state.isPress) {
            switch (type) {
                case 'Favorite':
                    this.setState({ isPress: true })
                    toggleFavoriteSupportApi(data.StudySupportId).then(res => {
                        showNotification(res.message, res.code);
                        if (res.code == 1) {
                            this.resetInput();
                            this.setState({
                                isPress: false,
                                listSupport: this.state.listSupport.map(item => {
                                    if (item.StudySupportId == data.StudySupportId) {
                                        return {
                                            ...item,
                                            IsFavorite: res.isFavorite
                                        }
                                    }
                                    return item
                                })
                            })
                        }
                        else this.setState({ isPress: false })
                    }).catch(error => {
                        showNotification(MESSAGE.ERROR, 0);
                        this.setState({ isPress: false })
                    })
                    break;
                case 'Alert':
                    this.setState({
                        showModal: true,
                        modalType: 2,
                        supportSelect: data,
                        inputTime: data.TimeAlert ? moment(data.TimeAlert, "YYYY-MM-DD HH:mm").unix() * 1000 : '',
                        inputRepeat: data.RepeatType
                    });
                    break;
                case 'Secure':
                    this.setState({ isPress: true })
                    toggleSecureSupportApi(data.StudySupportId).then(res => {
                        showNotification(res.message, res.code);
                        if (res.code == 1) {
                            this.resetInput();
                            this.setState({
                                isPress: false,
                                listSupport: this.state.listSupport.map(item => {
                                    if (item.StudySupportId == data.StudySupportId) {
                                        return {
                                            ...item,
                                            IsSecure: res.isSecure
                                        }
                                    }
                                    return item
                                })
                            })
                        }
                        else this.setState({ isPress: false })
                    }).catch(error => {
                        showNotification(MESSAGE.ERROR, 0);
                        this.setState({ isPress: false })
                    })
                    break;
            }
        }
    }

    updateAlert = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true })
            let { inputTime, inputRepeat, supportSelect } = this.state;
            inputTime = moment(inputTime).format("YYYY-MM-DD HH:mm");
            inputTime = inputTime == 'Invalid date' ? '0000-00-00 00:00:00' : inputTime;
            updateAlertSupportApi(inputTime, inputRepeat, supportSelect.StudySupportId).then(res => {
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.resetInput();
                    this.setState({
                        isPress: false,
                        showModal: false,
                        listSupport: this.state.listSupport.map(item => {
                            if (item.StudySupportId == supportSelect.StudySupportId) {
                                return {
                                    ...item,
                                    TimeAlert: inputTime,
                                    TimeAlertFormat: moment(inputTime, "YYYY-MM-DD HH:mm").format('D/M/YYYY HH:mm'),
                                    RepeatType: inputRepeat,
                                }
                            }
                            return item
                        })
                    })
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                console.log(error)
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    renderModal = () => {
        const { inputTime, inputRepeat, modalType, isPress } = this.state;
        const isEdit = modalType == 2;
        return (
            <>
                <p className='mgt'>Chọn ngày nhắc hẹn: </p>
                <div className='flex align-center date-study-picker'>
                    <DatePicker
                        selected={inputTime}
                        onChange={inputTime => this.setState({ inputTime })}
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        timeCaption="time"
                        dateFormat="d-M-yyyy H:mm"
                        showTimeSelect
                        minDate={Date.now()}
                        placeholderText='Chưa chọn'
                    />
                </div>
                {(inputTime || inputTime == '0000-00-00 00:00:00') &&
                    <p className='text-center pointer mgt' onClick={() => this.setState({ inputTime: '' })}>
                        <u>Hủy chọn ngày</u>
                    </p>
                }
                <p className='mgt'>Chọn kiểu lặp lại: </p>
                <select
                    className='form-control'
                    value={inputRepeat}
                    onChange={e => this.setState({ inputRepeat: e.target.value })}
                >
                    <option>Không lặp lại</option>
                    <option value={1}>Hàng ngày</option>
                    <option value={2}>Hàng tuần</option>
                    <option value={3}>Hàng tháng</option>
                    <option value={4}>Hàng năm</option>
                </select>
                <div className='row mgt-4 item-center'>
                    <div className='col-md-6 col-sm-6 col-xs-12 item-center'>
                        <Button
                            loading={isPress}
                            className='bg-success'
                            onClick={() => isEdit ? this.updateAlert() : this.setState({ showModal: false })}
                        >
                            {isEdit ? 'Cập nhật' : 'Xác nhận'}
                        </Button>
                    </div>
                    {isEdit &&
                        <div className='col-md-6 col-sm-6 col-xs-12 item-center'>
                            <Button
                                loading={isPress}
                                className={'bg-danger'}
                                onClick={this.toggleAlert}
                            >
                                Bật/tắt nhắc nhở
                            </Button>
                        </div>
                    }
                </div>
            </>
        )
    }

    toggleAlert = () => {
        if (!this.state.isPress) {
            const { supportSelect } = this.state;
            this.setState({ isPress: true })
            toggleAlertSupportApi(supportSelect.StudySupportId).then(res => {
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.resetInput();
                    this.props.onUpdate();
                    this.setState({
                        isPress: false,
                        showModal: false,
                        listSupport: this.state.listSupport.map(item => {
                            if (item.StudySupportId == supportSelect.StudySupportId) {
                                return {
                                    ...item,
                                    IsAlert: res.isAlert
                                }
                            }
                            return item
                        })
                    })
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    resetInput = () => {
        this.setState({
            inputContent: '',
            inputTime: '',
            inputRepeat: 0,
            isFavorite: false,
        })
    }

    changeUrlSupport = () =>{
        const {inputFacebookUrl, inputZaloUrl, inputYoutubeUrl, inputTiktokUrl} = this.state;
        changeUrlSupportApi(inputFacebookUrl, inputZaloUrl, inputYoutubeUrl, inputTiktokUrl, this.userSelect.StudySupportUserId).then(res =>{
            showNotification(res.message, res.code);
            this.props.onUpdate();
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    updateLevelSupport = (e) =>{
        const inputLevel = e.target.value;
        this.setState({inputLevel})
        changeLevelSupportApi(inputLevel, this.userSelect.StudySupportUserId).then(res =>{
            showNotification(res.message, res.code);
            this.props.onUpdate();
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidMount() {
        this.getListSupportFromStudent()
    }

    render() {
        const {
            isLoading,
            endList,
            inputContent,
            isFavorite,
            isPress,
            showModal,
            listSupport,
            inputTime,
            orderSelect,
            inputFacebookUrl,
            inputTiktokUrl,
            inputYoutubeUrl,
            inputZaloUrl,
            inputLevel
        } = this.state;
        return (
            <>
                <div className='item-center mgt'>
                    <TooltipUser userInfo={this.userSelect} avatarClass='avatar-guest-large' />
                    <p className='mgt'>{this.userSelect.FullName}</p>
                    <p className='mgt'>{`${this.userSelect.Gender == 1 ? 'Nữ' : 'Nam'} ${this.userSelect.BirthDay} (${this.userSelect.Age} tuổi)`}</p>
                </div>
                <select
                    className='form-control mgt mgb'
                    onChange={this.updateLevelSupport}
                    value={inputLevel}
                >
                    <option value="0">Chọn cấp độ</option>
                    <option value="99">Tài khoản ảo</option>
                    <option value="1">Cấp độ 1: Chưa nhận thức được vấn đề - chưa biết về giải pháp</option>
                    <option value="2">Cấp độ 2: Đã nhận thức được vấn đề - đang tìm kiếm giải pháp</option>
                    <option value="3">Cấp độ 3: Đang phân vân giữa những giải pháp</option>
                    <option value="4">Cấp độ 4: Đã tin tưởng giải pháp nhưng chưa hành động</option>
                    <option value="5">Cấp độ 5: Đã mua hàng, cần cảm xúc để mua tiếp</option>
                </select>
                <div className='row item-center'>
                    <div className='col-md-1 col-sm-1 col-xs-12'>
                        <p className='text-center'>Facebook</p>
                    </div>
                    <div className='col-md-9 col-sm-9 col-xs-12'>
                        <input
                            className='form-control'
                            value={inputFacebookUrl}
                            onChange={e => this.setState({inputFacebookUrl: e.target.value})}
                            onBlur={this.changeUrlSupport}
                        />
                    </div>
                    <div className='col-md-2 col-sm-2 col-xs-12 item-center'>
                        <Button
                            className='bg-primary'
                            onClick={() => window.open(inputFacebookUrl,'_blank')}
                        >
                            Truy cập
                        </Button>
                    </div>
                </div>
                <div className='row item-center'>
                    <div className='col-md-1 col-sm-1 col-xs-12'>
                        <p className='text-center'>Zalo</p>
                    </div>
                    <div className='col-md-9 col-sm-9 col-xs-12'>
                        <input
                            className='form-control'
                            value={inputZaloUrl}
                            onChange={e => this.setState({inputZaloUrl: e.target.value})}
                            onBlur={this.changeUrlSupport}
                        />
                    </div>
                    <div className='col-md-2 col-sm-2 col-xs-12 item-center'>
                        <Button
                            className='bg-primary'
                            onClick={() => window.open(inputZaloUrl,'_blank')}
                        >
                            Truy cập
                        </Button>
                    </div>
                </div>
                <div className='row item-center'>
                    <div className='col-md-1 col-sm-1 col-xs-12'>
                        <p className='text-center'>Youtube</p>
                    </div>
                    <div className='col-md-9 col-sm-9 col-xs-12'>
                        <input
                            className='form-control'
                            value={inputYoutubeUrl}
                            onChange={e => this.setState({inputYoutubeUrl: e.target.value})}
                            onBlur={this.changeUrlSupport}
                        />
                    </div>
                    <div className='col-md-2 col-sm-2 col-xs-12 item-center'>
                        <Button
                            className='bg-primary'
                            onClick={() => window.open(inputYoutubeUrl,'_blank')}
                        >
                            Truy cập
                        </Button>
                    </div>
                </div>
                <div className='row item-center'>
                    <div className='col-md-1 col-sm-1 col-xs-12'>
                        <p className='text-center'>Tiktok</p>
                    </div>
                    <div className='col-md-9 col-sm-9 col-xs-12'>
                        <input
                            className='form-control'
                            value={inputTiktokUrl}
                            onChange={e => this.setState({inputTiktokUrl: e.target.value})}
                            onBlur={this.changeUrlSupport}
                        />
                    </div>
                    <div className='col-md-2 col-sm-2 col-xs-12 item-center'>
                        <Button
                            className='bg-primary'
                            onClick={() => window.open(inputTiktokUrl,'_blank')}
                        >
                            Truy cập
                        </Button>
                    </div>
                </div>
                <div className='row align-center'>
                    <div className='col-md-10 col-sm-10 col-xs-12'>
                        <ReactQuill
                            ref={e => this.inputContentRef = e}
                            value={inputContent}
                            onChange={inputContent => this.setState({ inputContent })}
                            modules={{ toolbar: [['italic', 'underline']] }}
                            placeholder='Nhập thông tin Chăm sóc mới…'
                        />
                    </div>
                    <div className='col-md-2 col-sm-2 col-xs-12 item-center'>
                        <div className='flex item-center mgt mgb'>
                            <img
                                src={inputTime ? require('../../../assets/image/watch-clock-active.png') : require('../../../assets/image/watch-clock.png')}
                                className='watch-clock pointer mgr'
                                onClick={() => this.setState({ showModal: true, modalType: 1 })}
                            />
                            <img
                                src={isFavorite ? require('../../../assets/image/star.png') : require('../../../assets/image/club-level.png')}
                                className='watch-clock pointer mgl'
                                onClick={() => this.setState({ isFavorite: !isFavorite })}
                            />
                        </div>
                        <Button
                            className='bg-success mgt'
                            loading={isPress}
                            onClick={this.updateSupport}
                        >
                            Cập nhật
                        </Button>
                    </div>
                </div>
                <div className='flex item-center mgt mgb'>
                    <img
                        src={orderSelect.includes('Alert') ? require('../../../assets/image/watch-clock-active.png') : require('../../../assets/image/watch-clock.png')}
                        className='watch-clock pointer mgr-2'
                        onClick={() => this.orderList('Alert')}
                    />
                    <img
                        src={orderSelect.includes('Favorite') ? require('../../../assets/image/star.png') : require('../../../assets/image/club-level.png')}
                        className='watch-clock pointer mgr-2'
                        onClick={() => this.orderList('Favorite')}
                    />
                    {!this.isManage &&
                        <img
                            src={orderSelect.includes('Secure') ? require('../../../assets/image/check.png') : require('../../../assets/image/nocheck.png')}
                            className='watch-clock pointer'
                            onClick={() => this.orderList('Secure')}
                        />
                    }
                </div>
                {isLoading ?
                    <Loading small />
                    :
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={!endList}
                        loader={<Loading key={0} small />}
                        threshold={100}
                        className='mgt-2'
                    >
                        {listSupport.map((item, key) =>
                            <div className='list-support-box' key={key}>
                                <div>
                                    <div className='flex'>
                                        <img
                                            src={item.IsAlert == 2 ? require('../../../assets/image/watch-clock-active.png') : require('../../../assets/image/watch-clock-gray.png')}
                                            className='watch-clock pointer mgr-2'
                                            onClick={() => this.editSupport('Alert', item)}
                                        />
                                        <img
                                            src={item.IsFavorite == 2 ? require('../../../assets/image/star.png') : require('../../../assets/image/star-gray.png')}
                                            className='watch-clock pointer mgr-2'
                                            onClick={() => this.editSupport('Favorite', item)}
                                        />
                                        {!this.isManage &&
                                            <img
                                                src={item.IsSecure == 2 ? require('../../../assets/image/check.png') : require('../../../assets/image/nocheck.png')}
                                                className='watch-clock pointer'
                                                onClick={() => this.editSupport('Secure', item)}
                                            />
                                        }
                                    </div>
                                    {item.TimeAlert != '0000-00-00 00:00:00' &&
                                        <>
                                            <p className='placeholder mgt mgb-0 text-center black'>{item.TimeAlertFormat}</p>
                                            <p className='placeholder mgb-0 text-center'>{NAME_REPEAT[item.RepeatType]}</p>
                                        </>
                                    }
                                </div>
                                <div className='box-content-support'>
                                <p
                                    className='text-content-support'
                                    dangerouslySetInnerHTML={{ __html: item.Content }}
                                />
                                    <p className='placeholder mgb-0'>{item.FullName} - {item.CrDateTime}</p>
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                }
                <Modal
                    {...PROP.MODAL}
                    bodyStyle={{ ...PROP.MODAL.bodyStyle, background: '#8d6738' }}
                    visible={showModal}
                    onCancel={() => this.setState({
                        showModal: false,
                        inputTime: '',
                        inputRepeat: 0,
                    })}
                    destroyOnClose={true}
                >
                    <TitleSuccess>Thời gian</TitleSuccess>
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

export default SupportDetail