import React, {useEffect} from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import AuthenticationScreen from '../screens/authentication';
import Audio from '../screens/audio/Main';
import TemplateLogin from '../components/layout/login';
import { useHistory } from 'react-router-dom';
import iziToast from 'izitoast';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { KEY } from '../utils/constants';

const Lobby = (props) => {
    const { path } = useRouteMatch();

    useEffect(() =>{
        const userInvite = queryString.parse(props.location.search)?.aff;
        if(userInvite){
            Cookies.set(KEY.AFF_INVITE, userInvite, { expires: 999999 });
        }
    }, []);

    // useEffect(() =>{
    //     iziToast.show({
	// 		timeout: 5000,
	// 		backgroundColor: '#4b5056',
    //         position: 'bottomCenter',
	// 		zindex: 999999,
	// 		messageColor: 'white',
	// 		message: '<p class="text-center">Đăng nhập để sử dụng các tính năng nâng cao</p>',
	// 		buttons: [
	// 			['<button style="color: white; background: #06A65A; border-radius: 15px;">Đăng nhập</button>', function (instance, toast) {
    //                 instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
    //                 history.push('/lobby/sign-in')
	// 			}],
	// 			['<button style="color: white; background: #FF9300; border-radius: 15px;">Đóng</button>', function (instance, toast) {
	// 				instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
	// 			}]
	// 		],
	// 	});
    // }, []);

    return(
        <TemplateLogin>
            <Switch>
                <Route exact path={`${path}`}>
                    <AuthenticationScreen.Authencation />
                </Route>
                <Route path={`${path}audio`}>
                    <Audio />
                </Route>
                <Route path={`${path}guide`}>
                    <AuthenticationScreen.Guide />
                </Route>
                <Route path={`${path}aff`}>
                    <AuthenticationScreen.AffLobby />
                </Route>
                <Route path={`${path}question-lobby`}>
                    <AuthenticationScreen.Question />
                </Route>
                <Route path={`${path}newfeed`}>
                    <AuthenticationScreen.Newfeed />
                </Route>
                <Route path={`${path}sign-in`}>
                    <AuthenticationScreen.Authencation />
                </Route>
                <Route path={`${path}top`}>
                    <AuthenticationScreen.Rank />
                </Route>
                <Route path={`${path}privacy`}>
                    <AuthenticationScreen.Privacy />
                </Route>
                <Route path={"/:id"}>
                    <AuthenticationScreen.Authencation />
                </Route>
                <Route render={() => <Redirect to={`${path}`}/>}/>
            </Switch>
        </TemplateLogin>
    )
}

export default withRouter(Lobby);