import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderLottie from '../../components/HeaderLottie';
import club from '../../assets/lottie/club.json';
import { withRouter } from 'react-router-dom';
import { updateDataUser } from '../../actions/home';
import BarSelect from '../../components/frame/BarSelect';
import Icon from 'react-fontawesome';
import {appendListNewfeed, clearNewfeedData} from '../../actions/newfeed';
import ClubInfoDetail from '../../components/club/ClubInfoDetail';
import ClubInfoProgram from '../../components/club/ClubInfoProgram';

class ClubInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderType: 'info'
        }
    }

    selectType = (orderType) => {
        if (orderType != this.state.orderType) {
            this.setState({ orderType });
        }
    }

    renderNavigate = () => (
        <>
            <div style={{ height: '8vh' }} />
            <BarSelect>
                <Icon
                    name='calendar'
                    className={'pv-navigate ' + (this.state.orderType == 'info' && 'success')}
                    style={{ fontSize: '5vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.3vh' }}
                    onClick={() => this.selectType('info')}
                />
                <Icon
                    name='sitemap'
                    className={'pv-navigate ' + (this.state.orderType == 'program' && 'success')}
                    style={{ fontSize: '6vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh' }}
                    onClick={() => this.selectType('program')}
                />
            </BarSelect>
        </>
    )

    render() {
        let { orderType } = this.state;
        return (
            <>
                <HeaderLottie source={club} disableLoop />
                {orderType == 'info'?
                    <ClubInfoDetail  />
                :
                    <ClubInfoProgram />
                }
                {this.renderNavigate()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, { updateDataUser, appendListNewfeed, clearNewfeedData })(withRouter(ClubInfo));