import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListPostApi = (num, categoryId, subCategoryId) =>{
    let url = `${URI_PATH}app/post/mine/getListPost`;
    let body = {
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        Num: num,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}