const INITAL_STATE = {
    listPackage: [],
    showModal: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_LIST_PACKAGE':
            return {
                ...state,
                listPackage: action.payload,
                showModal: action.showModal
            }
        case 'SHOW_HIDE_MODAL_PACKAGE':
            return {
                ...state,
                showModal: action.payload,
            }
        default:
            return state;
    }
};
