import React, {Component} from 'react';
import {getAvatarFb, isTouch} from '../../../utils/common';
import Icon from 'react-fontawesome';
import {connect} from 'react-redux';
import Emoji from '../../chat/Emoji';

class InputComment extends Component{
    constructor(props){
        super(props);
        this.state = {
            inputComment: '',
            showEmoji: false
        }
    }

    componentDidMount(){
        this.props.replyComment && this.refs.refInput.focus();
    }

    pickEmoji = e =>{
        let emoji = e.target.firstChild.nodeValue;
        this.setState({inputComment: this.state.inputComment + emoji})
    }

    render(){
        let dataUser = this.props.dataUser;
        let {inputComment, showEmoji} = this.state;
        return(
            <div className='flex-row mgt'>
                <div>
                    <img src={getAvatarFb(dataUser.FacebookId)} key={dataUser.UserId} className='avatar-guest-small' />
                </div>
                <div className='newfeed-comment-input-container'>
                    <input
                        ref='refInput'
                        className='newfeed-comment-input'
                        placeholder={this.props.placeholder ? this.props.placeholder : 'Viết bình luận...'}
                        style={{fontSize: isTouch() ? '16px' : '2vh'}}
                        value={inputComment}
                        onChange={e => this.setState({inputComment: e.target.value})}
                        onFocus={() => this.setState({showEmoji: false})}
                        onKeyDown={e => {
                            if(e.keyCode === 13){
                                this.props.onComment(inputComment);
                                this.setState({
                                    showEmoji: false,
                                    inputComment: ''
                                });
                                e.preventDefault();
                            }
                        }}
                    />
                    <div className='item-center relative'>
                        <Icon
                            name='smile-o'
                            className='newfeed-comment-input-picker'
                            onClick={() => this.setState({showEmoji: !showEmoji})}
                        />
                        {showEmoji &&
                            <Emoji
                                onPick={this.pickEmoji}
                                onEndPick={() => this.setState({showEmoji: false})}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(InputComment);