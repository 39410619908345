import { getListNotificationApi } from '../../api/notification';

export const getListNotification = (page = 1) => {
    return dispatch => {
        getListNotificationApi(page).then(res =>{
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_LIST_NOTIFICATION', listNotification: res.listNotification, page: page });
            else dispatch({ type: 'FALSE_GET_LIST_NOTIFICATION' });
        })
        .catch(error => dispatch({ type: 'FALSE_GET_LIST_NOTIFICATION' }));
    };
};

export const showHideWidgetNotification = () =>{
    return dispatch => dispatch({ type: 'SHOW_HIDE_WIDGET_NOTIFICATION' });
}