import {getListItemApi} from '../../api/charge/market';

export const getListItem = (selectItem = 0) =>{
    let itemType = selectItem + 1;
    return dispatch =>{
        dispatch({ type: 'INIT_GET_ITEM_MARKET', selectItem });
        getListItemApi(itemType).then((res) => {
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_ITEM_MARKET', listItem: res.listItem, selectItem: selectItem });
            else dispatch({ type: 'FALSE_GET_ITEM_MARKET' });
        })
        .catch(error => dispatch({ type: 'FALSE_GET_ITEM_MARKET' }));
    }
}

export const actionItem = (chargemarketId, selectItem, amount = 0, balanceLast, useritemId) =>{
    return dispatch =>{
        dispatch({ type: 'ACTION_ITEM_MARKET', chargemarketId, selectItem, amount, balanceLast, useritemId });
    }
}