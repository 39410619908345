import { URI_PATH } from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListUpgradeApi = () => {
    let url = `${URI_PATH}app/admin/zvip/action/getListUpgrade`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const confirmUpgradeApi = (ZvipRequestId) => {
    let url = `${URI_PATH}app/admin/zvip/action/confirmUpgrade`;
    let body = {
        ZvipRequestId
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListTransactionApi = () => {
    let url = `${URI_PATH}app/admin/zvip/action/getListTransaction`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const confirmTransactionApi = (ZvipTransactionId) =>{
    let url = `${URI_PATH}app/admin/zvip/action/confirmTransaction`;
    let body = {
        ZvipTransactionId
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const rejectTransactionApi = (ZvipTransactionId) =>{
    let url = `${URI_PATH}app/admin/zvip/action/rejectTransaction`;
    let body = {
        ZvipTransactionId
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListLogAdminApi = () =>{
    let url = `${URI_PATH}app/admin/zvip/action/getListLogAdmin`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleZvipApi = (UserId, Expire, IsEnable) =>{
    let url = `${URI_PATH}app/admin/zvip/action/toogleZvip`;
    let body = {
        UserId,
        Expire,
        IsEnable
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}