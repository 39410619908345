import {getListNewfeedApi, getListNewfeedUserApi, getListNewfeedLobbyApi} from '../../api/newfeed';

export const getListNewfeed = (page, typeNewfeed, newfeedIdDirect, commentIdDirect) =>{
    return dispatch =>{
        if(page == 1) dispatch({ type: 'INIT_GET_LIST_NEWFEED', typeNewfeed});
        getListNewfeedApi(page, typeNewfeed, newfeedIdDirect, commentIdDirect).then(res =>{
            if(res.code == 1){
                dispatch({
                    type: 'SUCCESS_GET_LIST_NEWFEED',
                    listNewfeed: res.listNewfeed,
                    page,
                    typeNewfeed
                });
            }
            else dispatch({ type: 'FALSE_GET_LIST_NEWFEED'});
        }).catch(e => dispatch({ type: 'FALSE_GET_LIST_NEWFEED'}));
    }
}

export const getListNewfeedLobby = (page, typeNewfeed) =>{
    return dispatch =>{
        if(page == 1) dispatch({ type: 'INIT_GET_LIST_NEWFEED', typeNewfeed});
        getListNewfeedLobbyApi(page, typeNewfeed).then(res =>{
            if(res.code == 1){
                dispatch({
                    type: 'SUCCESS_GET_LIST_NEWFEED',
                    listNewfeed: res.listNewfeed,
                    page,
                    typeNewfeed
                });
            }
            else dispatch({ type: 'FALSE_GET_LIST_NEWFEED'});
        }).catch(e => dispatch({ type: 'FALSE_GET_LIST_NEWFEED'}));
    }
}

export const getListNewfeedUser = (page, userId) =>{
    return dispatch =>{
        if(page == 1) dispatch({ type: 'INIT_GET_LIST_NEWFEED' });
        getListNewfeedUserApi(page, userId).then(res =>{
            if(res.code == 1){
                dispatch({
                    type: 'SUCCESS_GET_LIST_NEWFEED',
                    listNewfeed: res.listNewfeed,
                    userId,
                    page
                });
            }
            else dispatch({ type: 'FALSE_GET_LIST_NEWFEED'});
        }).catch(e => dispatch({ type: 'FALSE_GET_LIST_NEWFEED'}));
    }
}

export const changeDataNewfeed = data =>{
    return dispatch => dispatch({ type: 'CHANGE_DATA_NEWFEED', data})
}

export const clearNewfeedData = () =>{
    return dispatch => dispatch({ type: 'CLEAR_NEWFEED_DATA'})
}

export const updateStatusNewfeed = (newfeedId, dataUser, status) =>{
    return dispatch => dispatch({ type: 'NEWFEED_UPDATE_STATUS', newfeedId, dataUser, status})
}

export const appendListNewfeed = (listNewfeed) =>{
    return dispatch => dispatch({ type: 'STORE_LIST_NEWFEED', listNewfeed})
}