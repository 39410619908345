import React from 'react';
import Lottie from 'react-lottie';
import loading from '../assets/lottie/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loading
};

export default (props) =>{
    if(props.tiny){
        return(
            <div className="item-center">
                <Lottie options={defaultOptions}
                    height='7vh'
                    width='7vh'
                    style={{margin: 0}}
                />
            </div>
        )
    }
    if(props.small){
        return(
            <div className="item-center">
                <Lottie options={defaultOptions}
                    height='10vh'
                    width='10vh'
                />
            </div>
        )
    }
    return(
        <div className="loading-big">
            <Lottie options={defaultOptions}
                height='30vh'
                width='30vh'
            />
        </div>
    )
}