import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectFacebookApi } from '../../api/facebook'
import { updateDataUser, getUserBar, changePoint } from '../../actions/home';
import { Button, Modal } from 'antd';
import { KEY, MESSAGE, PROP } from '../../utils/constants';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import facebook from '../../assets/lottie/facebook.json';
import HeaderLottie from '../../components/HeaderLottie';
import { getCrestApi } from '../../api/menu';
import ItemMenu from '../../components/menu/ItemMenu';
import city from '../../assets/lottie/city.json';
import { TitleSuccess } from '../../components/frame';
import { withRouter } from 'react-router';
import { isTeacher, isMaster } from '../../utils/common';
import queryString from 'query-string'

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isPress: false
        }
    }

    study = [
        { name: 'Chọn lớp', image: require('../../assets/image/menu/study-subject.png'), url: '/study/study-subject' },
        { name: 'Học viên', image: require('../../assets/image/menu/study-class.png'), url: '/study/study-class' },
        { name: 'Bài học', image: require('../../assets/image/menu/study-learn.png'), url: '/study/study-learn' },
        { name: 'Bảng điểm', image: require('../../assets/image/menu/study-summary.png'), url: '/study/study-summary' },
    ]

    // book = [
    //     { name: 'Đăng review sách', image: require('../../assets/image/menu/book-review-publish.png'), url: '/menu/book-review-publish', mission: 'ReviewBookCount' },
    // ]

    listScreen = [
        { title: 'Lớp học', data: this.study },
    ]

    getCrest = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            getCrestApi().then(res => {
                if (res.code == 1) {
                    let achievement = res.achievement;
                    if (achievement > 0) {
                        showNotificationBonus(achievement, 1, 4);
                        this.props.changePoint([null, null, null, achievement]);
                    }
                    this.props.getUserBar();
                }
                showNotification(res.message, res.code);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    componentDidMount(){
        const queryUrl = queryString.parse(window.location.search);
        if(queryUrl.menu) return;
        const dataUser = this.props.dataUser;
        if(dataUser.IsMaster || dataUser.IsStudent || dataUser.IsMaster){
            this.props.history.push('/study/study-learn');
        }
    }

    render() {
        return (
            <div>
                <HeaderLottie
                    source={city}
                />
                {(isTeacher() || isMaster()) &&
                    <>
                        {this.props.countMission.SumMission == 0 ?
                            <div>
                                <p className='bold text-center' style={{ marginBottom: '0.5vh', 'borderRadius': 0 }}>
                                    Bạn đã hoàn thành thử thách ngày hôm nay
                                </p>
                                <div className='item-center' style={{ marginBottom: '0.5vh' }}>
                                    <Button
                                        className='bg-success shadow-pulse'
                                        onClick={this.getCrest}
                                        loading={this.state.isPress}
                                    >
                                        Nhận thưởng
                                    </Button>
                                </div>
                            </div>
                            :
                            <p className='bold text-center' style={{ marginBottom: '0.5vh', 'borderRadius': 0 }}>
                                Vượt qua {this.props.countMission.SumMission} thử thách để sở hữu huy chương Z và 1000 thành tựu
                            </p>
                        }
                    </>
                }
                {this.listScreen.map((item, key) =>
                    <div key={key}>
                        <div className='title-menu'>{item.title}</div>
                        <div className='row'>
                            {item.data.map((dataMenu, k) =>
                                <div className='col-md-2 col-sm-3 col-xs-4' key={k}>
                                    <ItemMenu
                                        countMission={this.props.countMission}
                                        countNotify={this.props.countNotify}
                                        dataMenu={dataMenu}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={700}
                >
                    <p className='bold title-success text-center mgt'>
                        PHẦN THƯỞNG HUY CHƯƠNG Z
                    </p>
                    <div className='bonus-crest-container'>
                        <div className='flex-row item-center'>
                            <img className='bonus-crest-img' key={1} src={require('../../assets/image/crest.png')} />
                        </div>
                        <p className='text-large text-center mgt'>Nhận được thêm 1000 thành tựu</p>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission, countNotify } = state.getCountMission;
    return { dataUser, countMission, countNotify };
}

export default connect(mapStateToProps, { updateDataUser, getUserBar, changePoint })(withRouter(MainMenu));