import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { COLOR, MESSAGE, PROP } from '../../../utils/constants';
import { updateLessonApi, getListLessonApi, getListPropertyAndCategoryApi } from '../../../api/admin/study';
import { showNotification } from '../../../utils/notification';
import ReactQuill from 'react-quill';
import { getCategory, getSubCategory } from '../../../actions/category';
import 'react-quill/dist/quill.snow.css';
import { withRouter } from 'react-router-dom';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            categoryId: '',
            subCategoryId: '',
            lesson: '',
            lessonTitle: '',
            lessonDeadline: 3,
            listDocument: [],
            showModalAddDocument: false,
            documentTitle: '',
            documentLink: '',
            showModal: false,
            isEditDocument: false
        }
    }

    showModalAdd = () => {
        if (!this.state.isPress) {
            this.setState({
                lesson: '',
                lessonTitle: '',
                showModal: true,
                documentLink: '',
                documentTitle: '',
                listDocument: []
            })
        }
    }

    updateLesson = () => {
        if (!this.state.isPress) {
            const { categoryId, subCategoryId, lesson, lessonTitle, lessonDeadline, listDocument } = this.state;
            let idNotification = '#notificationAddLesson';
            if (!categoryId) return showNotification('Thể loại không được để trống', 0, idNotification);
            if (!subCategoryId) return showNotification('Chủ đề không được để trống', 0, idNotification);
            if (!lessonTitle) return showNotification('Tiêu đề không được để trống', 0, idNotification);
            if (lessonDeadline <= 0) return showNotification('Số giờ Deadline phải lớn hơn 0', 0, idNotification);
            this.setState({ isPress: true })
            const studyAdminId = this.props.dataEdit.StudyAdminId;
            updateLessonApi(lesson, lessonTitle, lessonDeadline, categoryId, subCategoryId, listDocument, studyAdminId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    })
                    showNotification(res.message, res.code);
                    this.props.onCreate(categoryId)
                }
                else {
                    showNotification(MESSAGE.ERROR, 0, idNotification)
                    this.setState({ isPress: false })
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isPress: false })
            })
        }
    }

    changeCategory = e => {
        let categoryId = e.target.value;
        this.setState({ categoryId });
        this.props.getSubCategory(categoryId, this.props.dataUser.UserId);
    }

    addDocument = () => {
        const { documentLink, documentTitle, listDocument, isEditDocument } = this.state;
        if (documentTitle && documentLink) {
            let listDocument;
            if(isEditDocument !== false){
                listDocument = this.state.listDocument.map((item, key) =>{
                    if(key == isEditDocument){
                        return {
                            Title: documentTitle,
                            Link: documentLink
                        }
                    }
                    return item;
                })
            }
            else listDocument = [
                ...this.state.listDocument,
                {
                    Title: documentTitle,
                    Link: documentLink
                }
            ]
            this.setState({
                listDocument,
                showModalAddDocument: false,
                documentLink: '',
                documentTitle: '',
                isEditDocument: false
            })
        }
        else if (!documentTitle) showNotification('Tiêu đề không được để trống', 0)
        else if (!documentLink) showNotification('Đường dẫn không được để trống', 0)
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataEdit != prevProps.dataEdit && Object.keys(this.props.dataEdit).length > 0) {
            this.showModalAdd();
            this.props.getSubCategory(this.props.dataEdit.CategoryId, this.props.dataUser.UserId);
            this.setState({
                categoryId: this.props.dataEdit.CategoryId,
                subCategoryId: this.props.dataEdit.SubCategoryId,
                lesson: this.props.dataEdit.Lesson,
                lessonTitle: this.props.dataEdit.LessonTitle,
                lessonDeadline: this.props.dataEdit.LessonDeadline,
                listDocument: this.props.dataEdit.ListDocument,
                showModal: true
            })
        }
    }

    render() {
        const { isPress, showModal, categoryId, subCategoryId, lesson, lessonTitle, lessonDeadline, showModalAddDocument, documentLink, documentTitle, listDocument } = this.state;
        const { categoryPost, subCategory } = this.props;
        const isEdit = Object.keys(this.props.dataEdit).length > 0;
        return (
            <>
                <div className='item-center mgt-2'>
                    <Button
                        className='bg-success'
                        onClick={() => {
                            this.props.onShowModal()
                            this.showModalAdd()
                        }}
                        loading={isPress}
                    >
                        Thêm bài học
                    </Button>
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width='750px'
                    centered
                >
                    <div className='mgt-2'>
                        <p className={`text-center ${(isEdit ? 'title-warning' : 'title-success')}`}>
                            {isEdit ? 'Sửa bài học' : 'Thêm bài học'}
                        </p>
                        <div className='row mgt-2 item-center'>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                                <select
                                    className='form-control'
                                    onChange={this.changeCategory}
                                    value={categoryId}
                                >
                                    <option value=''>Chọn thể loại</option>
                                    {categoryPost.map((item, key) => (
                                        <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                                    ))}
                                </select>
                            </div>
                            {subCategory.length > 0 ?
                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                    <select
                                        className='form-control'
                                        onChange={e => this.setState({ subCategoryId: e.target.value })}
                                        value={subCategoryId}
                                    >
                                        <option value=''>Chọn chủ đề</option>
                                        {subCategory.map((item, key) => (
                                            <option key={key} value={item.SubCategoryId}>{item.SubCategoryName}</option>
                                        ))}
                                    </select>
                                </div>
                                :
                                <>
                                    {categoryId > 0 &&
                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                            <select className='form-control'>
                                                <option value=''>Chọn chủ đề</option>
                                            </select>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <p
                            className='text-center mgt pointer'
                            onClick={() => this.props.history.push('sub-category')}
                        >
                            <u>Thêm chủ đề</u>
                        </p>
                        <p className='mgt'>Tiêu đề:</p>
                        <input
                            className='form-control'
                            placeholder='Nhập tiêu đề'
                            value={lessonTitle}
                            onChange={e => this.setState({ lessonTitle: e.target.value })}
                        />
                        <p className='mgt'>Keyword:</p>
                        <textarea
                            className='form-control'
                            placeholder='Nhập keyword'
                            value={lesson}
                            onChange={e => this.setState({ lesson: e.target.value })}
                            style={{ height: '22vh', lineHeight: '3vh' }}
                        />
                        <p className='mgt'>Deadline: (giờ)</p>
                        <input
                            className='form-control'
                            type='number'
                            placeholder='Nhập số giờ'
                            value={lessonDeadline}
                            onChange={e => this.setState({ lessonDeadline: e.target.value })}
                        />
                        {listDocument.length > 0 &&
                            <div className='mgt-2 box-primary'>
                                {listDocument.map((item, key) =>
                                    <div className='box-primary mgt-2 relative' key={key}>
                                        {isEdit &&
                                            <img
                                                src={require('../../../assets/image/edit.png')}
                                                className='edit-document-lesson-admin'
                                                onClick={() => {
                                                    this.setState({
                                                        showModalAddDocument: true,
                                                        isEditDocument: key,
                                                        documentTitle: item.Title,
                                                        documentLink: item.Link
                                                    })
                                                }}
                                            />
                                        }
                                        <img
                                            src={require('../../../assets/image/delete.png')}
                                            className='delete-document-lesson-admin'
                                            onClick={() => this.setState({listDocument: listDocument.filter((_, k) => key != k)})}
                                        />
                                        <p>{item.Title}</p>
                                        <p>{item.Link}</p>
                                    </div>
                                )}
                            </div>
                        }
                        <Button
                            style={{ width: '100%', backgroundColor: COLOR.COLOR_PLACEHOLDER }}
                            onClick={() => this.setState({ showModalAddDocument: true })}
                            className='mgt-2'
                        >
                            + Thêm đính kèm
                        </Button>
                        <div className='mgt' id='notificationAddLesson'></div>
                        <div className='item-center mgt-3'>
                            <Button
                                className={isEdit ? 'bg-warning' : 'bg-success'}
                                onClick={this.updateLesson}
                                loading={isPress}
                            >
                                {isEdit ? 'Cập nhật' : 'Tạo mới'}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalAddDocument}
                    onCancel={() => this.setState({ showModalAddDocument: false, isEditDocument: false })}
                    centered
                >
                    <p className='mgt'>Tiêu đề:</p>
                    <input
                        className='form-control'
                        placeholder='Nhập tiêu đề'
                        value={documentTitle}
                        onChange={e => this.setState({ documentTitle: e.target.value })}
                    />
                    <p className='mgt'>Link:</p>
                    <input
                        className='form-control'
                        placeholder='Nhập đường dẫn'
                        value={documentLink}
                        onChange={e => this.setState({ documentLink: e.target.value })}
                    />
                    <div className='item-center mgt-3'>
                        <Button
                            className={'bg-success'}
                            onClick={this.addDocument}
                            loading={isPress}
                        >
                            Xác nhận
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost, subCategory } = state.getCategory;
    return { dataUser, categoryPost, subCategory }
}

export default connect(mapStateToProps, { getCategory, getSubCategory })(withRouter(Main))