const INITAL_STATE = {
    listAudio: [],
    page: 1,
    isLoadingAudio: true,
    endList: false,
    audioIndex: 0,
    currentTime: 0,
    duration: 0,
    isPlay: false,
    isRepeat: false,
    showAudio: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_LIST_AUDIO':
            return {
                ...state,
                isLoadingAudio: false,
                listAudio: [
                    ...action.listAudio,
                    {
                        AudioId: `${action.listAudio.length + 1}`,
                        AudioLink: "https://zcity.vn/backend/assets/uploads/audio/queue.mp3",
                        AudioTitle: "Bài học tiếp theo",
                        CategoryId: "1",
                        CrDateTime: "2021-02-05 23:36:19",
                        SubCategoryId: "284",
                        isNext: true
                    },
                    {
                        AudioId: `${action.listAudio.length + 2}`,
                        AudioLink: "https://zcity.vn/backend/assets/uploads/audio/vip.mp3",
                        AudioTitle: "Hệ thống quản trị doanh nghiệp theo thời gian thực",
                        CategoryId: "1",
                        CrDateTime: "2021-02-05 23:36:19",
                        SubCategoryId: "284",
                        isVip: true
                    }
                ],
                endList: action.listAudio.length < 10,
                page: parseInt(action.page) || state.page,
            }
        case 'CHANGE_AUDIO_INDEX':
            return {
                ...state,
                audioIndex: action.audioIndex,
                isPlay: true,
            }
        case 'CHANGE_AUDIO_CURRENT_TIME':
            return {
                ...state,
                currentTime: action.currentTime,
                showAudio: true
            }
        case 'CHANGE_AUDIO_DURATION':
            return {
                ...state,
                duration: action.duration,
            }
        case 'CHANGE_AUDIO_PLAY':
            return {
                ...state,
                isPlay: action.isPlay,
            }
        case 'CHANGE_AUDIO_REPEAT':
            return {
                ...state,
                isRepeat: action.isRepeat,
            }
        case 'HIDE_AUDIO':
            return{
                ...state,
                isPlay: false,
                showAudio: false,
            }
        case 'FALSE_GET_LIST_AUDIO':
            return { ...state }
        default:
            return state;
    }
};