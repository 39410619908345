import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListLogApi = (PageId) =>{
    let url = `${URI_PATH}app/admin/study/log/getListLog`;
    let body = {
        PageId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAllLogApi = (PageId, SearchText) =>{
    let url = `${URI_PATH}app/admin/study/log/getAllLog`;
    let body = {
        PageId,
        SearchText
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
