import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListAskQuestionApi = (num, categoryId, subCategoryId) =>{
    let url = `${URI_PATH}app/askquestion/mine/getListAskQuestion`;
    let body = {
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        Num: num,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}