import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {updateCountNotify} from '../../actions/menu';
import HeaderLottie from '../../components/HeaderLottie';
import fortune from '../../assets/lottie/fortune.json';
import {Button, Modal} from 'antd';
import {getAvatarFb, priceFormat} from '../../utils/common';
import Loading from '../../components/Loading';
import {getListFortuneApi, getListFortuneOpenApi, openFortuneApi} from '../../api/club/fortune'
import { showNotification, showNotificationBonus } from '../../utils/notification';
import {MESSAGE, PROP} from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';
import TooltipUser from '../../components/TooltipUser';
import {showModalEat} from '../../actions/home/modalEat';

class ClubFortune extends Component{
    constructor(props){
        super(props);
        this.state={
            listFortune: [],
            listFortuneOpen: [],
            fortuneInfo: {},
            isLoading: true,
            isPress: false,
            endList: false,
            showModal: false
        }
        this.page = 1;
    }

    getListFortune = () =>{
        getListFortuneApi(this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listFortune: [...this.state.listFortune, ...res.listFortune],
                    endList: res.listFortune.length == 0,
                    isLoading: false
                });
            }
            else{
                this.setState({endList: true})
                showNotification(res.message, res.code);
            }
        }).catch(e =>{
            this.setState({endList: true});
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    loadMore = () =>{
        this.page += 1;
        this.getListFortune();
    }

    showModalFortune = (fortuneInfo) =>{
        if(!this.state.isPress){
            let clubfortuneId = fortuneInfo.ClubfortuneId;
            this.setState({isPress: clubfortuneId});
            let idNotification = '#notificationModal' + clubfortuneId;
            getListFortuneOpenApi(clubfortuneId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        showModal: true,
                        fortuneInfo: fortuneInfo,
                        listFortuneOpen: res.listFortuneOpen,
                        isPress: false
                    })
                }
                else{
                    this.setState({isPress: false})
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            });
        }
    }

    openFortune = (clubfortuneopenId) =>{
        let {isPress, listFortuneOpen} = this.state;
        if(!isPress){
            this.setState({isPress: clubfortuneopenId});
            let dataUser = this.props.dataUser;
            let idNotification = '#notificationOpenFortune' + clubfortuneopenId;
            openFortuneApi(clubfortuneopenId).then(res =>{
                if(res.code == 1){
                    if(res.isBalance == 2){
                        showNotificationBonus(res.data, 1, 2);
                        this.props.changePoint([null, res.data]);
                    }
                    else{
                        showNotificationBonus(res.data, 1, 3);
                        this.props.changePoint([null, null, res.data]);
                    }
                    this.props.updateCountNotify('FortuneClubCount');
                    this.setState({
                        isPress: false,
                        listFortuneOpen: listFortuneOpen.map(el => {
                            if(el.ClubfortuneopenId == clubfortuneopenId) return Object.assign({}, el, {
                                UserOpen: dataUser.UserId,
                                FullName: dataUser.FullName,
                                BalanceZOpen: res.data,
                                FacebookId: dataUser.FacebookId
                            })
                            return el;
                        })
                    })
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            });
        }
    }

    componentDidMount(){
        this.getListFortune();
    }

    render(){
        let {isLoading, listFortune, endList, isPress, showModal, fortuneInfo, listFortuneOpen} = this.state;
        if(isLoading) return(<Loading />);
        return(
            <div>
                <HeaderLottie source={fortune}/>
                <div className='flex-column item-center'>
                    <p className='bold text-title-gift mg'>Nhật ký phát lộc</p>
                </div>
                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                    className='row'
                >
                    {listFortune.map((item, key) =>
                        <div className='col-md-6 col-sm-6 col-xs-12 club-fortune-container' key={key}>
                            <div className='item-center'>
								<TooltipUser userInfo={item} avatarClass='avatar-guest'/>
                                <p className='mgt text-large'>{item.FullName}</p>
                                <p>phát lộc 12 gói quà tổng {priceFormat(item.BalanceZ)} đ[z]</p>
                                <p>Trạng thái: {item.CountOpen == 12 ? 'Đã hết': 'Đang còn'}</p>
                                <div id={'notificationModal' + item.ClubfortuneId} />
                                <Button
                                    className={'mgt ' + ((item.CountOpen == 12 || item.ClubfortuneopenId) ? 'bg-primary' : 'bg-success')}
                                    loading={isPress == item.ClubfortuneId}
                                    onClick={() => this.showModalFortune(item)}
                                >
                                    Xem chi tiết
                                </Button>
                            </div>
                        </div>
                    )}
                </InfiniteScroll>
                <Modal
                    {...PROP.MODAL}
                    width={'90%'}
                    visible={showModal}
                    onCancel={() => this.setState({showModal: false})}
                >
                    <div className='item-center mgt-2'>
                        <TooltipUser userInfo={fortuneInfo} avatarClass='avatar-guest'/>
                        <p className='text-large mgt'>{fortuneInfo.FullName}</p>
                        <p className='text-large mgt'>Phát lộc {priceFormat(fortuneInfo.BalanceZ)} đ[z]</p>
                        <div className='row mgt-3'>
                            {listFortuneOpen.map((item, key) =>
                                <div className='col-md-3 col-sm-6 col-xs-12' key={key}>
                                    {item.UserOpen > 0 ?
                                        <div className='flex-column item-center mgb-3'>
                                            <img src={require('../../assets/image/pocket-open.png')} className='fortune-img' />
                                            <p className='text-large bold' style={{position: 'absolute'}}>
                                                {priceFormat(item.BalanceZOpen)} {item.IsBalance == 2 ? '$[z]' : 'đ[z]'}
                                            </p>
                                            <img src={getAvatarFb(item.FacebookId)} className='avatar-guest-small mgt' key={item.UserOpen} />
                                            <p>{item.FullName}</p>
                                        </div>
                                    :
                                        <div className='flex-column item-center mgb-3'>
                                            <img
                                                src={require('../../assets/image/pocket.png')}
                                                className='fortune-img'
                                                onClick={() => this.openFortune(item.ClubfortuneopenId)}
                                            />
                                            <div className='mgt' id={'notificationOpenFortune' + item.ClubfortuneopenId}></div>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, updateCountNotify, showModalEat})(ClubFortune);