import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification } from '../../../utils/notification';
import { MESSAGE, PROP, URI_PATH } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { getImage64 } from '../../../utils/common';
import { Tooltip } from 'antd';
import TooltipUser from '../../../components/TooltipUser';
import Icon from 'react-fontawesome';
import FsLightbox from 'fslightbox-react';
import Emoji from '../../chat/Emoji';
import { getListChatApi, sendChatApi } from '../../../api/study/chat';
import {socket} from '../../realtime/ReadSocketEvent';

class LessonChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            listChat: [],
            showEmoji: false,
            inputChat: '',
            isSendChat: false,
            isLoading: true,
            isSend: false,
            showPreviewImage: false,
            imagePreview: ''
        }
        this.inputImageRef = '';
        this.dataLesson = this.props.dataLesson;
    }

    pickEmoji = e => {
        let emoji = e.target.firstChild.nodeValue;
        this.setState({ inputChat: this.state.inputChat + emoji })
    }

    uploadImage = event => {
        let file = event.target.files[0];
        getImage64(file, image64 => {
            this.sendRequest('', image64);
        });
        this.inputImageRef.value = '';
    }

    sendRequest = (message, isImage = '') => {
        sendChatApi(message, isImage, this.dataLesson.StudyStudentId).then(res => {
            if (res.code == 1) {
                // this.refs.chatList.scrollTo(0, document.querySelector(".chat-list").scrollHeight);
                const { dataUser } = this.props;
                const dataSend = {
                    CrDateTime: 'Vừa xong',
                    FacebookId: dataUser.FacebookId,
                    FullName: dataUser.FullName,
                    Message: isImage ? res.imageUrl : message,
                    PhoneNumber: dataUser.PhoneNumber,
                    StudyChatId: res.studyChatId,
                    StudyStudentId: this.dataLesson.StudyStudentId,
                    Type: isImage ? 1 : 0,
                    UserId: dataUser.UserId
                };
                this.setState({
                    isSend: false,
                    showEmoji: false,
                    inputChat: '',
                    listChat: [
                        dataSend,
                        ...this.state.listChat,
                    ]
                });
                socket.emit('send_chat_study', dataSend);
            }
            else {
                showNotification(res.message, res.code);
                this.setState({
                    isSend: false,
                    showEmoji: false,
                });
            }
        }).catch(e => {
            console.log(e)
            showNotification(MESSAGE.ERROR, -1);
            this.setState({
                isSend: false,
                showEmoji: false,
            });
        });
    }

    send = () => {
        let inputChat = this.state.inputChat.trim();
        if (!this.state.isSend && inputChat != '') {
            this.setState({ isSend: true });
            this.sendRequest(inputChat);
        }
    }

    getListChat = () => {
        getListChatApi(this.dataLesson.StudyStudentId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listChat: res.listChat,
                    isLoading: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    receiveChatRealtime = (data) =>{
        if(data.StudyStudentId == this.dataLesson.StudyStudentId){
            this.setState({
                listChat: [
                    data,
                    ...this.state.listChat,
                ]
            })
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.dataLesson != prevProps.dataLesson){
            this.dataLesson = this.props.dataLesson;
            this.getListChat();
        }
    }

    componentDidMount() {
        this.getListChat()
        socket.on('receive_chat_study', this.receiveChatRealtime);
    }

    componentWillUnmount(){
        socket.removeListener('receive_chat_study', this.receiveChatRealtime);
    }

    render() {
        let { showEmoji, inputChat, isLoading, listChat, showPreviewImage, imagePreview } = this.state;
        let { dataUser } = this.props;
        return (
            <>
                <div className='box-chat-study'>
                    {isLoading ?
                        <Loading small />
                        :
                        <>
                            {listChat.map((item, key) => {
                                let isUserChat = item.UserId == dataUser.UserId;
                                return (
                                    <React.Fragment key={key}>
                                        <div className={isUserChat ? "chat-study-message-user" : "chat-study-message-guest"}>
                                            <TooltipUser avatarClass="avatar-message" userInfo={item}  />
                                            <div className={isUserChat ? "chat-study-message-item-user" : "chat-study-message-item-guest"}>
                                                <Tooltip
                                                    {...PROP.TOOLTIP_HOME}
                                                    overlayClassName='black-tooltip'
                                                    title={
                                                        <div className='item-center'>
                                                            <p className='bold'>{item.CrDateTime}</p>
                                                        </div>
                                                    }
                                                    key={key}
                                                >
                                                    {item.Type == 1 ?
                                                        <div className='item-center'>
                                                            <img
                                                                src={`${URI_PATH}${item.Message}`}
                                                                className='image-chat pointer'
                                                                onClick={() => this.setState({
                                                                    showPreviewImage: true,
                                                                    imagePreview: `${URI_PATH}${item.Message}`
                                                                })}
                                                            />
                                                        </div>
                                                        :
                                                        <p className="mgb-0">{item.Message}</p>
                                                    }
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </>
                    }
                </div>
                <div className='input-chat-study-container'>
                    <input
                        className='input-chat-study bg-transparent'
                        placeholder='Nhập nôi dung'
                        value={inputChat}
                        onChange={e => this.setState({ inputChat: e.target.value })}
                        onFocus={() => this.setState({ showEmoji: false })}
                        onKeyDown={e => {
                            if(e.keyCode === 13){
                                this.send();
                                e.preventDefault();
                            }
                        }}
                    />
                    <Icon
                        name='smile-o'
                        className={'emoji-chat-fight-select ' + (showEmoji ? 'success' : null)}
                        onClick={() => this.setState({ showEmoji: !showEmoji })}
                        onMouseEnter={() => this.setState({ showEmoji: true })}
                    />
                    <Icon
                        name='picture-o'
                        className='emoji-chat-fight-select'
                        onClick={() => this.inputImageRef.click()}
                    />
                    <Icon
                        name='paper-plane'
                        className='emoji-chat-fight-select'
                        style={{marginRight: '2vh'}}
                        onClick={this.send}
                    />
                    {showEmoji &&
                        <Emoji onPick={this.pickEmoji} onEndPick={() => this.setState({ showEmoji: false })} />
                    }
                </div>
                <input
                    type='file'
                    accept='image/*'
                    hidden='hidden'
                    ref={e => this.inputImageRef = e}
                    onChange={this.uploadImage}
                />
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(LessonChat)