import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePrivateApi, deleteDocumentLessonApi, editDocumentLessonApi, extendLessonApi, getDataExtendLessonApi, getLessonByStudentApi, markLessonApi, publishAnswerLessonApi, publishDocumentLessonApi, submitLessonApi } from '../../../api/study/lesson';
import { showNotification, showNotificationAsset, showNotificationBonus } from '../../../utils/notification';
import { MESSAGE, PROP, URI_PATH, KEY } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { getAvatarFb, getImage64, getTiktokId, getTimeAgo, getUrlGoogleSlide, getYoutubeId, isStudent, validURL } from '../../../utils/common';
import { Button, Modal, Switch, Tooltip } from 'antd';
import Countdown, { zeroPad } from 'react-countdown-now';
import TooltipUser from '../../../components/TooltipUser';
import Icon from 'react-fontawesome';
import { Collapse } from 'react-collapse';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import ReactQuill from 'react-quill';
import FsLightbox from 'fslightbox-react';
import LessonChat from '../componentChildren/LessonChat';
import InfiniteScroll from 'react-infinite-scroller';
import { changePoint } from '../../../actions/home/';
import HeaderLottie from '../../HeaderLottie';
import relax from '../../../assets/lottie/relax.json'
import { TitleSuccess } from '../../frame';
import { getListClassSelectApi } from '../../../api/study/class';
import SupportDetail from '../../admin/study/SupportDetail';
import Checked from '../../frame/Checked';
import GetLesson from './GetLesson';
import { EventBusName, onPushEventBus } from '../../../services/event-bus';

class LessonStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalType: 1,
            dataLesson: {},
            inputLessonContent: '',
            isPress: false,
            inputDocumnetLink: '',
            inputDocumnetContent: '',
            inputDocumnetImage: '',
            isDocumnetYoutube: false,
            isDocumnetTiktok: false,
            dataDocument: {},
            showDocumentId: 0,
            starMark: 0,
            dataExtendLesson: {},
            loadingExtendLesson: true,
            isEditDocument: false,
            isEditContent: false,
            showModalDocumentView: false,
            documentView: {},
            isLoadingDocumentModal: true,
            listLessonGet: [],
            studyClassIdGetLesson: ''
        }
        this.inputLessonContentRef = '';
        this.inputImageRef = '';
    }

    uploadImage = event => {
        let file = event.target.files[0];
        getImage64(file, inputDocumnetImage => {
            this.setState({ inputDocumnetImage });
            if (!this.state.isEditContent) {
                localStorage.setItem(`${KEY.STUDY_DOCUMENT_IMAGE}${this.state.dataLesson.StudyStudentId}`, inputDocumnetImage)
            }
        })
    }

    changeInputContent = (inputLessonContent) => {
        this.setState({ inputLessonContent })
        if (!this.state.isEditContent) {
            localStorage.setItem(`${KEY.STUDY_ANSWER}${this.state.dataLesson.StudyStudentId}`, inputLessonContent)
        }
    }

    changeInputDocumentContent = (inputDocumnetContent) => {
        this.setState({ inputDocumnetContent })
        if (!this.state.isEditContent) {
            localStorage.setItem(`${KEY.STUDY_DOCUMENT_CONTENT}${this.state.dataLesson.StudyStudentId}`, inputDocumnetContent)
        }
    }

    renderModal = () => {
        const {
            dataLesson,
            inputLessonContent,
            isPress,
            inputDocumnetContent,
            inputDocumnetLink,
            inputDocumnetImage,
            isDocumnetYoutube,
            starMark,
            loadingExtendLesson,
            dataExtendLesson,
            isEditDocument,
            listLessonGet,
            studyClassIdGetLesson,
            isDocumnetTiktok
        } = this.state;
        const { type } = this.props;
        const isPass = type == 2;
        switch (this.state.modalType) {
            case 1:
                return (
                    <div className='item-center'>
                        <p className='text-large mgt bold'>Trả lời bài: {dataLesson.LessonTitle}</p>
                        <div
                            className='mgt-2'
                            style={{ cursor: 'text' }}
                            onClick={() => this.inputLessonContentRef.focus()}
                        >
                            <ReactQuill
                                ref={e => this.inputLessonContentRef = e}
                                value={inputLessonContent}
                                onChange={this.changeInputContent}
                                modules={{ toolbar: [['bold', 'italic', 'underline']] }}
                                placeholder='Nhập nội dung trả lời'
                            />
                        </div>
                        <div className='mgt-3 item-center'>
                            <Button
                                className='bg-success'
                                onClick={this.publishAnswerLesson}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className='item-center'>
                        {isEditDocument ?
                            <p className='text-large mgt bold'>Sửa tài liệu tham khảo: {dataLesson.LessonTitle}</p>
                            :
                            <p className='text-large mgt bold'>Thêm tài liệu tham khảo: {dataLesson.LessonTitle}</p>
                        }
                        <input
                            className='form-control mgt-2'
                            placeholder='Link tham khảo'
                            value={inputDocumnetLink}
                            onChange={this.changeInputDocumentLink}
                        />
                        {isDocumnetYoutube ?
                            <div className='item-center mgt'>
                                <iframe
                                    src={"https://www.youtube.com/embed/" + getYoutubeId(inputDocumnetLink)}
                                    frameBorder="0"
                                    allow="encrypted-media"
                                    allowFullScreen="allowFullScreen"
                                    style={{ height: '50vh', borderRadius: '2vh' }}
                                />
                            </div>
                            :
                            <>
                                {isDocumnetTiktok ?
                                    <div className='iframe-tiktok'>
                                        <iframe
                                            scrolling="no"
                                            name="__tt_embed__v3802385945065390"
                                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                                            src={`https://www.tiktok.com/embed/v2/${getTiktokId(inputDocumnetLink)}?lang=vi&amp;`}
                                        />
                                    </div>
                                    :
                                    <>
                                        {inputDocumnetImage ?
                                            <div className='flex-column item-center mgt'>
                                                {inputDocumnetImage.match(/assets\/uploads\/study/) ?
                                                    <img src={`${URI_PATH}${inputDocumnetImage}`} style={{ width: '30vh' }} />
                                                    :
                                                    <img src={inputDocumnetImage} style={{ width: '30vh' }} />
                                                }
                                                <img
                                                    src={require('../../../assets/image/delete.png')}
                                                    className='delete-image mgt'
                                                    onClick={() => {
                                                        this.inputImageRef.value = '';
                                                        this.setState({ inputDocumnetImage: '' })
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div
                                                className='pointer'
                                                onClick={() => this.inputImageRef.click()}
                                            >
                                                <Icon name='camera' className='mgt-2' style={{ fontSize: '4vh' }} />
                                                <p>Hình tham khảo</p>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                        <div
                            className='mgt-2'
                            style={{ cursor: 'text' }}
                            onClick={() => this.inputDocumnetContentRef.focus()}
                        >
                            <ReactQuill
                                ref={e => this.inputDocumnetContentRef = e}
                                value={inputDocumnetContent}
                                onChange={this.changeInputDocumentContent}
                                modules={{ toolbar: [['bold', 'italic', 'underline']] }}
                                placeholder={`Trích dẫn tham khảo / quan điểm cá nhân
• Nếu link tham khảo là Youtube, ghi rõ key tham khảo phút nào?
• Nếu tham khảo bài viết quá dài, hoặc trong một cuốn sách, trích key chính tham khảo là 1 phần của bài viết, của cuốn sách nói về chủ đề này.`}
                            />
                        </div>
                        <div className='mgt-3 item-center'>
                            <Button
                                className='bg-success'
                                onClick={this.publishDocumentLesson}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                        <input
                            type='file'
                            hidden='hidden'
                            ref={e => this.inputImageRef = e}
                            onChange={this.uploadImage}
                            accept="image/*"
                        />
                    </div>
                )
            case 3:
                return (
                    <div className='item-center'>
                        <p className='text-large mgt-2 bold danger'>Xác nhận xóa tài liệu tham khảo ?</p>
                        <div className='mgt-3 item-center'>
                            <Button
                                className='bg-danger'
                                onClick={this.deleteDocumentLesson}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className='item-center'>
                        <p className='text-large mgt bold'>Xác nhận nộp bài ?</p>
                        <div className='mgt-3 item-center'>
                            <Button
                                className='bg-success mgt-3'
                                onClick={this.submitLesson}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 5:
                return (
                    <>
                        <p className='mgt-2 text-center text-large'>Hỗ trợ bài làm mã số {dataLesson.StudyStudentId}</p>
                        <LessonChat dataLesson={dataLesson} />
                    </>
                )
            case 6:
                return (
                    <div className='item-center'>
                        <img src={getAvatarFb(dataLesson.FacebookId)} className='avatar-guest mgt-2' />
                        <p className='text-large mgt bold'>Xác nhận chấm {starMark} điểm cho bài của {dataLesson.FullName} ?</p>
                        <p className='text-center mgt'>"{dataLesson.LessonTitle}"</p>
                        <div className='mgt-3 item-center'>
                            <Button
                                className='bg-success mgt-3'
                                onClick={this.markLesson}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 7:
                return (
                    <>
                        {loadingExtendLesson ?
                            <Loading small />
                            :
                            <>
                                {isPass ?
                                    <p className='mgt-2 text-large text-center bold'>Yêu cầu gia hạn thêm Deadline 1 ngày</p>
                                    :
                                    <p className='mgt-2 text-large text-center bold'>Gia hạn thêm Deadline 1 ngày</p>
                                }
                                <p className='text-center mgt'>"{dataLesson.LessonTitle}"</p>
                                <p className='mgt text-center'>Bạn đã gia hạn {dataExtendLesson.count} lần</p>
                                <p className='mgt text-center'>Phí gia hạn lần {parseInt(dataExtendLesson.count) + 1} là: {dataExtendLesson.cost} {dataLesson.IsZticket == '0' ? '$[z]':'Gold[z]'}</p>
                                <div className='item-center mgt mgb'>
                                    <video width="100%" controls style={{ borderRadius: '1vh' }} autoPlay={true}>
                                        <source src={`${URI_PATH}assets/hd.mp4#t=0.001`} type="video/mp4" />
                                    </video>
                                </div>
                                {dataLesson.IsZticket == '0' ?
                                <div className='mgt item-center'>
                                    <Button
                                        className='bg-success mgt-3'
                                        onClick={this.extendLesson}
                                        loading={isPress}
                                    >
                                        Xác nhận Gia hạn
                                    </Button>
                                </div>
                                :
                                <div className='flex item-center mgt'>
                                    <Button
                                        className='bg-blue mgt-3 mgr'
                                        onClick={() => onPushEventBus(EventBusName.BUY_ZTICKET)}
                                        loading={isPress}
                                    >
                                        Nạp Gold[z]
                                    </Button>
                                    <Button
                                        className='bg-success mgt-3 mgl'
                                        onClick={this.extendLesson}
                                        loading={isPress}
                                    >
                                        Xác nhận Gia hạn
                                    </Button>
                                </div>
                                }
                            </>
                        }
                    </>
                )
            case 8:
                return (
                    <>
                        <p className='text-center text-large mgt-2'>Chọn môn học</p>
                        <select
                            className='form-control mgt-2'
                            value={studyClassIdGetLesson}
                            onChange={e => this.setState({ studyClassIdGetLesson: e.target.value })}
                        >
                            <option>Chọn môn học</option>
                            {listLessonGet.map((item, key) =>
                                <option key={key} value={item.StudyClassId}>{item.Subject}</option>
                            )}
                        </select>
                        <div className='mgt-3 item-center'>
                            <Button
                                className='bg-success mgt-3'
                                onClick={this.getLessonByStudent}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                            {/* <p className='mgt-3' onClick={this.props.onNavigateStudySubject}>
                                <u>Nhấn vào đây nếu bạn không thấy môn học</u>
                            </p> */}
                        </div>
                    </>
                )
            case 9:
                return <SupportDetail userInfo={dataLesson} isManage={true} onUpdate={() => null} />
        }
        return null;
    }

    getLessonByStudent = () => {
        if (!this.state.isPress) {
            const { studyClassIdGetLesson } = this.state;
            if (studyClassIdGetLesson > 0) {
                this.setState({ isPress: true })
                getLessonByStudentApi(studyClassIdGetLesson).then(res => {
                    showNotification(res.message, res.code);
                    if (res.code == 1) {
                        this.props.onRefresh();
                        this.setState({
                            isPress: false,
                            showModal: false
                        });
                    }
                    else this.setState({ isPress: false });
                }).catch(error => {
                    this.setState({ isPress: false });
                    showNotification(MESSAGE.ERROR, 0);
                })
            }
            else showNotification('Bạn chưa chọn môn học', 0)
        }
    }

    changeInputDocumentLink = (e) => {
        const inputDocumnetLink = e.target.value;
        this.setState({
            inputDocumnetLink,
            isDocumnetYoutube: getYoutubeId(inputDocumnetLink) ? true : false,
            isDocumnetTiktok: getTiktokId(inputDocumnetLink) ? true : false,
        })
        if (!this.state.isEditDocument) {
            localStorage.setItem(`${KEY.STUDY_DOCUMENT_LINK}${this.state.dataLesson.StudyStudentId}`, inputDocumnetLink)
            localStorage.setItem(`${KEY.STUDY_IS_DOCUMENT_YOUTUBE}${this.state.dataLesson.StudyStudentId}`, getYoutubeId(inputDocumnetLink) ? true : false)
            localStorage.setItem(`${KEY.STUDY_IS_DOCUMENT_TIKTOK}${this.state.dataLesson.StudyStudentId}`, getTiktokId(inputDocumnetLink) ? true : false)
        }
    }

    publishAnswerLesson = () => {
        if (!this.state.isPress) {
            let { inputLessonContent, dataLesson } = this.state;
            if (inputLessonContent == '<p><br></p>') inputLessonContent = '';
            this.setState({ isPress: true });
            publishAnswerLessonApi(dataLesson.StudyStudentId, inputLessonContent).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false,
                        inputLessonContent: '',
                    });
                    this.props.onRefresh()
                    localStorage.removeItem(`${KEY.STUDY_ANSWER}${dataLesson.StudyStudentId}`);
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    publishDocumentLesson = async () => {
        if (!this.state.isPress) {
            let { inputDocumnetContent, inputDocumnetLink, inputDocumnetImage, dataLesson, isDocumnetYoutube, isEditDocument, dataDocument, isDocumnetTiktok } = this.state;
            if (inputDocumnetContent == '<p><br></p>') inputDocumnetContent = '';
            if (inputDocumnetContent && inputDocumnetLink) {
                this.setState({ isPress: true });
                if (isDocumnetYoutube) inputDocumnetImage = '';
                let res;
                try {
                    if (isEditDocument) res = await editDocumentLessonApi(dataLesson.StudyStudentId, inputDocumnetContent, inputDocumnetLink, inputDocumnetImage, isDocumnetYoutube, dataDocument.StudyDocumentId, isDocumnetTiktok);
                    else res = await publishDocumentLessonApi(dataLesson.StudyStudentId, inputDocumnetContent, inputDocumnetLink, inputDocumnetImage, isDocumnetYoutube, isDocumnetTiktok)
                    showNotification(res.message, res.code)
                    if (res.code == 1) {
                        this.setState({
                            isPress: false,
                            showModal: false,
                            inputDocumnetLink: '',
                            inputDocumnetContent: '',
                            inputDocumnetImage: '',
                            showDocumentId: dataLesson.StudyStudentId
                        });
                        this.props.onRefresh()
                        localStorage.removeItem(`${KEY.STUDY_DOCUMENT_LINK}${dataLesson.StudyStudentId}`);
                        localStorage.removeItem(`${KEY.STUDY_DOCUMENT_CONTENT}${dataLesson.StudyStudentId}`);
                        localStorage.removeItem(`${KEY.STUDY_DOCUMENT_IMAGE}${dataLesson.StudyStudentId}`);
                        localStorage.removeItem(`${KEY.STUDY_IS_DOCUMENT_YOUTUBE}${dataLesson.StudyStudentId}`);
                        localStorage.removeItem(`${KEY.STUDY_IS_DOCUMENT_TIKTOK}${dataLesson.StudyStudentId}`);
                    }
                    else this.setState({ isPress: false })
                } catch (error) {
                    this.setState({ isPress: false });
                    showNotification(MESSAGE.ERROR, 0);
                }
            }
            else showNotification('Bạn chưa điền nội dung', 0)
        }
    }

    deleteDocumentLesson = () => {
        if (!this.state.isPress) {
            let { dataLesson, dataDocument } = this.state;
            this.setState({ isPress: true });
            deleteDocumentLessonApi(dataLesson.StudyStudentId, dataDocument.StudyDocumentId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    });
                    this.props.onRefresh()
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    submitLesson = () => {
        if (!this.state.isPress) {
            let { dataLesson } = this.state;
            this.setState({ isPress: true });
            submitLessonApi(dataLesson.StudyStudentId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    if(res?.isKTN){
                        showNotificationBonus(res.balanceBonus, 1, 2);
                        this.props.changePoint([null, res.balanceBonus]);
                        showNotificationAsset(res.assetInfo);
                    }
                    this.setState({
                        isPress: false,
                        showModal: false
                    });
                    this.props.onRefresh();
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    markLesson = () => {
        if (!this.state.isPress) {
            let { dataLesson } = this.state;
            this.setState({ isPress: true });
            markLessonApi(dataLesson.StudyStudentId, this.state.starMark).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    });
                    this.props.onRefresh();
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    renderDocument = (item, document, key = 0) => {
        const isDoing = this.props.type == 1;
        const isPast = this.props.type == 2;
        return (
            <div className='pv-title-container relative' key={key}>
                {(isDoing || (isPast && item.Status == 2)) &&
                    <>
                        <div
                            className='delete-document-study'
                            onClick={() => {
                                item.IsEndDeadline && !(isPast && item.Status == 2) ?
                                    this.getDataExtendLesson(item)
                                    :
                                    this.setState({
                                        showModal: true,
                                        modalType: 3,
                                        dataLesson: item,
                                        dataDocument: document
                                    })
                            }}
                        >
                            <img
                                src={require('../../../assets/image/x.png')}
                                style={{ width: '1.8vh' }}
                            />
                        </div>
                        <div
                            className='edit-document-study'
                            onClick={() => {
                                item.IsEndDeadline && !(isPast && item.Status == 2) ?
                                    this.getDataExtendLesson(item)
                                    :
                                    this.setState({
                                        showModal: true,
                                        modalType: 2,
                                        dataLesson: item,
                                        dataDocument: document,
                                        isEditDocument: true,
                                        inputDocumnetContent: document.Content,
                                        inputDocumnetLink: document.Link,
                                        inputDocumnetImage: document.Image,
                                        isDocumnetYoutube: document.Type == 2,
                                        isDocumnetTiktok: document.Type == 3
                                    })
                            }}
                        >
                            <img
                                src={require('../../../assets/image/edit.png')}
                                style={{ width: '2vh' }}
                            />
                        </div>
                    </>
                }
                {document.Type == 2 ?
                    <>
                        <div
                            className='text-center'
                            dangerouslySetInnerHTML={{ __html: document.Content }}
                        />
                        <iframe
                            className='mgt'
                            style={{ width: '100%', height: '60vh', borderRadius: '2vh' }}
                            src={"https://www.youtube.com/embed/" + getYoutubeId(document.Link)}
                            frameBorder="0"
                            allow="encrypted-media"
                            allowFullScreen="allowFullScreen"
                        />
                    </>
                    :
                    <>
                    {document.Type == 3 ?
                        <>
                        <div
                            className='text-center'
                            dangerouslySetInnerHTML={{ __html: document.Content }}
                        />
                        <div className='iframe-tiktok'>
                            <iframe
                                scrolling="no"
                                name="__tt_embed__v3802385945065390"
                                sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                                src={`https://www.tiktok.com/embed/v2/${getTiktokId(document.Link)}?lang=vi&amp;`}
                            />
                        </div>
                        </>
                    :
                    <>
                        {document.Link &&
                            <div
                                className='document-link-lesson'
                                onClick={() => validURL(document.Link) && window.open(document.Link, '_blank')}
                            >
                                <p className='mgb-0 text-center' style={{ color: 'white' }}>
                                    <u style={{ color: 'white' }}>{document.Link}</u>
                                </p>
                            </div>
                        }
                        <div className='mgt-2'>
                            {document.Image &&
                                <img
                                    src={`${URI_PATH}${document.Image}`}
                                    className='pv-post-image'
                                    onClick={() => this.setState({
                                        showPreviewImage: true,
                                        imagePreview: `${URI_PATH}${document.Image}`
                                    })}
                                />
                            }
                            <div
                                className='text-left'
                                dangerouslySetInnerHTML={{ __html: document.Content }}
                            />
                        </div>
                    </>
                }
                    </>
                }
            </div>
        )
    }

    getDataExtendLesson = (dataLesson) => {
        this.setState({
            showModal: true,
            modalType: 7,
            dataLesson,
        });
        getDataExtendLessonApi(dataLesson.StudySubjectId).then(res => {
            if (res.code == 1) {
                this.setState({
                    loadingExtendLesson: false,
                    dataExtendLesson: res.data,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    extendLesson = () => {
        if (!this.state.isPress) {
            let { dataLesson } = this.state;
            this.setState({ isPress: true });
            extendLessonApi(dataLesson.StudyStudentId, dataLesson.StudySubjectId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    });
                    if(dataLesson.IsZticket == '1'){
                        showNotificationBonus(res.cost, -1, 1);
                        this.props.changePoint([-res.cost]);
                    }
                    else{
                        showNotificationBonus(res.cost, -1, 2);
                        this.props.changePoint([null, -res.cost]);
                    }
                    this.props.onRefresh();
                }
                else this.setState({ isPress: false })
            }).catch(error => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    changePrivate = (studyStudentId) => {
        changePrivateApi(studyStudentId).then(res => {
            if (res.code == 1) {
                this.props.onRefresh();
            }
            else showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    showModalGetLesson = () => {
        this.setState({
            showModal: true,
            modalType: 8
        })
        getListClassSelectApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listLessonGet: res.data,
                    studyClassIdGetLesson: res.data?.[0]?.StudyClassId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    renderEmpty = () => {
        const { type, dayExipre } = this.props;
        switch (type) {
            case 1:
                return (
                    <>
                        {/* <p className='text-large mgt'>Bạn đã hoàn thành những bài học được giao, hãy bấm “Nhận bài học mới”</p> */}

                        <GetLesson showModalGetLesson={this.showModalGetLesson} />
                        {dayExipre > 0 && dayExipre <= 10 &&
                            <p className='mgt-3 text-large'>Thời gian học của bạn còn <span className='danger'>{dayExipre} ngày</span>, gia hạn sớm bạn nhé!</p>
                        }
                        {dayExipre < 0 && isStudent() &&
                            <p className='mgt-3 text-large'>Thời gian học của bạn còn <span className='danger'>0 ngày</span>, gia hạn sớm bạn nhé!</p>
                        }
                    </>
                )
            case 2:
                return (
                    <p className='text-large mgt'>Bạn chưa hoàn thành bài tập nào!</p>
                )
            case 3:
                return (
                    <>
                        <HeaderLottie source={relax} />
                        <p className='text-large mgt'>Học viên đã nộp hết bài tập và đang chờ giao bài mới.</p>
                    </>
                )
            case 4:
                return (
                    <>
                        <HeaderLottie source={relax} />
                        <p className='text-large mgt'>Bạn đã chấm xong những bài tập hiện có</p>
                    </>
                )
        }
        return null;
    }

    openModalChat = (dataLesson) => {
        this.setState({
            showModal: true,
            modalType: 5,
            dataLesson,
        })
        if (dataLesson.ListUserIdReadChat && !dataLesson.ListUserIdReadChat.includes(this.props.dataUser.UserId)) {
            this.props.onSeenChat && this.props.onSeenChat(dataLesson.StudyStudentId)
        }
    }

    renderModalViewDocument = () => {
        const { documentView } = this.state;
        if (documentView) {
            switch (parseInt(documentView.Type)) {
                case 0:
                    return (
                        <>
                            <p
                                className='mgt text-center'
                                onClick={() => window.open(documentView.Link, '_blank')}
                            >
                                <u>Nhấn vào đây nếu nội dung bị lỗi hoặc không hiển thị</u>
                            </p>
                            <iframe
                                className='mgt'
                                style={{ width: '100%', height: '70vh', borderRadius: '2vh' }}
                                src={documentView.Link}
                                frameBorder="0"
                                onLoad={() => this.setState({ isLoadingDocumentModal: false })}
                            />
                        </>
                    )
                case 1: // youtube
                    return (
                        <div className='relative'>
                            <div className='blur-video' />
                            <iframe
                                className='mgt yt-custom'
                                style={{ width: '100%', height: '70vh', borderRadius: '2vh' }}
                                src={"https://www.youtube.com/embed/" + getYoutubeId(documentView.Link) + '?autoplay=1'}
                                frameBorder="0"
                                allow="encrypted-media"
                                allowFullScreen="allowFullScreen"
                                onLoad={() => this.setState({ isLoadingDocumentModal: false })}
                            />
                        </div>
                    )
                case 2: //gg document
                case 3:
                    return (
                        <iframe
                            className='mgt'
                            style={{ width: '100%', height: '70vh' }}
                            src={documentView.Link + "?embedded=true"}
                            frameBorder="0"
                            allow="encrypted-media"
                            allowFullScreen="allowFullScreen"
                            onLoad={() => this.setState({ isLoadingDocumentModal: false })}
                        />
                    )
                case 4:
                    return (
                        <iframe
                            className='mgt'
                            style={{ width: '100%', height: '70vh' }}
                            src={getUrlGoogleSlide(documentView.Link) + '/embed?start=false&loop=false&delayms=3000'}
                            frameBorder="0"
                            allow="encrypted-media"
                            allowFullScreen="allowFullScreen"
                            onLoad={() => this.setState({ isLoadingDocumentModal: false })}
                        />
                    )
                case 5: //tiktok
                    return(
                        <div className='iframe-tiktok'>
                            <iframe
                                scrolling="no"
                                name="__tt_embed__v3802385945065390"
                                sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                                src={`https://www.tiktok.com/embed/v2/${getTiktokId(documentView.Link)}?lang=vi&amp;`}
                                onLoad={() => this.setState({ isLoadingDocumentModal: false })}
                            />
                        </div>
                    )
                case 6:
                    //study document link
                    return(
                        <div className='study-document-link-container' dangerouslySetInnerHTML={{ __html: documentView?.DocumentLink?.Content || '<p></p>' }}>
                        </div>
                    )
            }
        }
        return null;
    }

    render() {
        const { showModal, showPreviewImage, imagePreview, modalType, showDocumentId, showModalDocumentView, isLoadingDocumentModal } = this.state;
        const { listLesson, isLoading, type, dataUser, isPreview, isSupport } = this.props;
        const isDoing = type == 1;
        const isPast = type == 2;
        const isTeacher = type == 3;
        const isMaster = type == 4;
        if (isLoading) return <Loading small />
        return (
            <>
                {listLesson.length == 0 ?
                    <div className='item-center'>
                        {this.renderEmpty()}
                    </div>
                    :
                    <InfiniteScroll
                        loadMore={this.props.loadMore}
                        hasMore={this.props.endList !== undefined ? !this.props.endList : false}
                        loader={<Loading key={0} small />}
                        threshold={100}
                    >
                        {listLesson.map((item, key) =>
                            <React.Fragment key={key}>
                                <div className='box-primary mgt'>
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-6 col-xs-12 flex-row'>
                                            <div>
                                                <TooltipUser userInfo={item} avatarClass='avatar-guest' />
                                            </div>
                                            <div className='flex-column mgl-2'>
                                                <p>
                                                    <span className='bold'>{item.FullName}
                                                        <Checked data={item} />
                                                    </span>
                                                    <span> [
                                                        <StarRatings
                                                            rating={parseFloat(item.StudentData?.AvgPoint || 0)}
                                                            starRatedColor='white'
                                                            starEmptyColor='rgba(255,255,255,0.2)'
                                                            numberOfStars={5}
                                                            name='rating'
                                                            starDimension='2vh'
                                                            starSpacing='0.2px'
                                                        />
                                                        ] [{item.StudentData?.AllLesson || 0}] {(isTeacher || isMaster) && (`[${item.IsOverEndDate ? 0 : item.DateRemainAll}]`)}</span>
                                                </p>
                                                <p className='silver'>{getTimeAgo(item.CrDateTime)} - {moment(item.CrDateTime).format("HH:mm")}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-xs-12 flex-end'>
                                            <div className='action-study-lesson'>
                                                {item.IsSupport && item.Status == 1 &&
                                                    <div
                                                        className='button-action-study-lesson bg-warning relative'
                                                        onClick={() => this.setState({
                                                            showModal: true,
                                                            modalType: 9,
                                                            dataLesson: item,
                                                        })}
                                                    >
                                                        <p className='mgb-0' style={{ marginTop: '0.5vh' }}>CSHV</p>
                                                    </div>
                                                }
                                                <div
                                                    className='button-action-study-lesson bg-warning relative'
                                                    onClick={() => this.openModalChat(item)}
                                                >
                                                    {item.ListUserIdReadChat && !item.ListUserIdReadChat.includes(dataUser.UserId) &&
                                                        <div className='dot-notify-study-chat' />
                                                    }
                                                    <p className='mgb-0' style={{ marginTop: '0.5vh' }}>Hỗ trợ</p>
                                                </div>
                                                {isDoing ?
                                                    <div
                                                        className='button-action-study-lesson'
                                                        onClick={() => item.IsEndDeadline && !(isPast && item.Status == 2) ?
                                                            this.getDataExtendLesson(item)
                                                            :
                                                            this.setState({
                                                                showModal: true,
                                                                modalType: 4,
                                                                dataLesson: item,
                                                            })
                                                        }
                                                    >
                                                        <p className='mgb-0' style={{ marginTop: '0.5vh' }}>Nộp bài</p>
                                                    </div>
                                                    :
                                                    <>
                                                        {/* {item.Status == 0 &&
                                                            <div className='button-action-study-lesson bg-warning' >
                                                                <p className='mgb-0' style={{ marginTop: '0.5vh' }}><Icon name='clock-o' /> Đang học</p>
                                                            </div>
                                                        } */}
                                                        {item.Status == 1 &&
                                                            <div className='button-action-study-lesson bg-warning' style={{padding: '0.5vh 0vh' }} >
                                                                <p className='mgb-0' style={{ marginTop: '0.5vh'}}>Đang chấm</p>
                                                            </div>
                                                        }
                                                        {item.Status == 2 &&
                                                            <div className='button-action-study-lesson' style={{padding: '0.5vh 0vh' }}>
                                                                <p className='mgb-0' style={{ marginTop: '0.5vh' }}><Icon name='check' /> Đã chấm</p>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                <TooltipUser userInfo={item.TeacherData} avatarClass='avatar-guest-small' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pv-title-container'>
                                        <div className='flex-row mgt-2' style={{flexWrap: 'wrap'}}>
                                            <div className='flex-row flex-3 align-center'>
                                                <p className='study-border-status mgb-0'>Mã số: {item.StudyStudentId}</p>
                                            </div>
                                            <div className='flex-row flex-2 align-center' style={{ justifyContent: 'flex-end' }}>
                                                {item.UserId == dataUser.UserId &&
                                                    <div
                                                        className='study-border-status item-center pointer flex mgr'
                                                        style={{ width: '20vh' }}
                                                    >
                                                        <Switch
                                                            checked={item.IsPrivate == 2}
                                                            onChange={() => this.changePrivate(item.StudyStudentId)}
                                                        />
                                                        <p className='mgb-0 mgl'>Riêng tư</p>
                                                    </div>
                                                }
                                                {(((isDoing && !item.IsEndDeadline) || isTeacher) && !isPreview && !isSupport) ?
                                                    <p className='study-border-status-warning text-center mgb-0' style={{ width: '28vh' }}>Hạn nộp bài:
                                                        <Countdown
                                                            date={moment(item.Deadline, "YYYY-MM-DD HH:mm").unix() * 1000}
                                                            renderer={({ days, hours, minutes, seconds }) => (<span style={{ color: 'black' }}> {days > 0 && `${days} ngày `}{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                                        />
                                                    </p>
                                                    :
                                                    <>
                                                        {((isDoing && item.IsEndDeadline) || (isPast && item.Status == 1)) &&
                                                            <div
                                                                className='study-border-status item-center pointer flex'
                                                                style={{ width: '18vh' }}
                                                                onClick={() => this.getDataExtendLesson(item)}
                                                            >
                                                                <img src={require('../../../assets/image/plus-study.png')} style={{ width: '3.2vh', marginRight: '1vh' }} />
                                                                <p className='mgb-0'>Deadline</p>
                                                            </div>
                                                        }
                                                        {isPast && item.Status == 2 &&
                                                            <div
                                                                className='study-border-status item-center pointer flex'
                                                                style={{ width: '18vh' }}
                                                                onClick={() => this.getDataExtendLesson(item)}
                                                            >
                                                                <img src={require('../../../assets/image/plus-study.png')} style={{ width: '3.2vh', marginRight: '1vh' }} />
                                                                <p className='mgb-0'>Chấm lại</p>
                                                            </div>
                                                        }
                                                        {(isPreview || isMaster || isSupport) &&
                                                            <p className='study-border-status-warning mgb-0 text-center' style={{ width: '28vh' }}>
                                                                {getTimeAgo(item.PublishDateTime != '0000-00-00 00:00:00' ? item.PublishDateTime : item.CrDateTime)} - {moment(item.PublishDateTime != '0000-00-00 00:00:00' ? item.PublishDateTime : item.CrDateTime).format("HH:mm")}
                                                            </p>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <p className='text-large text-center bold mgt-2'>{item.Subject}</p>
                                        <p className='text-center bold mgt'>{item.CategoryName} - {item.SubCategoryName}</p>
                                        <p className='text-center bold mgt mgb-2'>{item.LessonTitle}</p>
                                        <div className='item-center flex-column'>
                                            {isMaster ?
                                                <StarRatings
                                                    rating={0}
                                                    starRatedColor='black'
                                                    starEmptyColor='rgba(0,0,0,0.1)'
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension='2.5vh'
                                                    starSpacing='1px'
                                                    changeRating={starMark => this.setState({
                                                        starMark,
                                                        showModal: true,
                                                        modalType: 6,
                                                        dataLesson: item,
                                                    })}
                                                />
                                                :
                                                <div className='flex'>
                                                    <StarRatings
                                                        rating={parseInt(item.Point)}
                                                        starRatedColor={item.Point > 3 ? '#00A65A' : '#dd4b39'}
                                                        starEmptyColor='rgba(0,0,0,0.1)'
                                                        numberOfStars={5}
                                                        name='rating'
                                                        starDimension='2.5vh'
                                                        starSpacing='1px'
                                                    />
                                                    <Tooltip
                                                        overlayClassName='tooltip-user'
                                                        overlayStyle={{ zIndex: 99999 }}
                                                        title={
                                                            <>
                                                                <p className='mgt text-large text-center bold'>Khung điểm</p>
                                                                <p className='mgt'>• Điểm trách nhiệm: 1 sao</p>
                                                                <p className='mgt'>• Điểm trình bày: 1 sao</p>
                                                                <p className='mgt'>• Điểm căn cứ: 1 sao</p>
                                                                <p className='mgt'>• Điểm liên hệ: 1 sao</p>
                                                                <p className='mgt'>• Điểm ứng dụng: 1 sao</p>
                                                            </>
                                                        }
                                                    >
                                                        <Icon
                                                            name='question-circle'
                                                            style={{ fontSize: '2.5vh', color: 'rgba(0,0,0,0.5)', marginLeft: '1vh' }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>
                                        <p className='text-center bold mgt'>{item.Lesson}</p>
                                        <div className='item-center'>
                                            {item.LessonDocument.map((document, key) =>
                                                <div
                                                    className='button-answer-lesson'
                                                    onClick={() => (!isPreview && item.IsEndDeadline && !(isPast && item.Status == 2)) ?
                                                        this.getDataExtendLesson(item)
                                                        :
                                                        this.setState({
                                                            showModalDocumentView: true,
                                                            documentView: document,
                                                            isLoadingDocumentModal: document.Type != 6
                                                        })}
                                                    key={key}
                                                >
                                                    <p className='mgb-0 color-white'>{document.Title}</p>
                                                </div>
                                            )}
                                            <img
                                                src={require('../../../assets/image/line.png')}
                                                className='line-img mgt mgb'
                                            />
                                        </div>
                                    </div>
                                    <div className='box-primary mgt'>
                                        <div className='flex item-center'>
                                            <p className='bold'>Trả lời & trích dẫn Nội dung tham khảo</p>
                                        </div>
                                        <div className='pv-title-container'>
                                            {(isDoing || (isPast && item.Status == 2)) ?
                                                <>
                                                    {item.Content ?
                                                        <div className='relative'>
                                                            <div
                                                                className='text-left'
                                                                dangerouslySetInnerHTML={{ __html: item.Content }}
                                                            />
                                                            <Icon
                                                                name='pencil'
                                                                className='icon-edit-content-lesson'
                                                                onClick={() => {
                                                                    item.IsEndDeadline && !(isPast && item.Status == 2) ?
                                                                        this.getDataExtendLesson(item)
                                                                        :
                                                                        this.setState({
                                                                            showModal: true,
                                                                            modalType: 1,
                                                                            dataLesson: item,
                                                                            inputLessonContent: item.Content,
                                                                            isEditContent: true
                                                                        })
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div
                                                            className='button-answer-lesson'
                                                            onClick={() => {
                                                                item.IsEndDeadline && !(isPast && item.Status == 2) ?
                                                                    this.getDataExtendLesson(item)
                                                                    :
                                                                    this.setState({
                                                                        showModal: true,
                                                                        modalType: 1,
                                                                        dataLesson: item,
                                                                        isEditContent: false,
                                                                        inputLessonContent: localStorage.getItem(`${KEY.STUDY_ANSWER}${item.StudyStudentId}`) || ''
                                                                    })
                                                            }}
                                                        >
                                                            <p className='mgb-0 color-white'>Trả lời</p>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <div
                                                    className='text-left'
                                                    dangerouslySetInnerHTML={{ __html: item.Content }}
                                                />
                                            }
                                        </div>
                                        {item.Documents.length > 0 &&
                                            <>
                                                {this.renderDocument(item, item.Documents[0])}
                                                <Collapse isOpened={showDocumentId == item.StudyStudentId}>
                                                    {item.Documents.slice(1).map((document, key) =>
                                                        this.renderDocument(item, document, key)
                                                    )}
                                                </Collapse>
                                            </>
                                        }
                                        {(isDoing || (isPast && item.Status == 2)) &&
                                            <div
                                                className='button-answer-lesson'
                                                onClick={() => {
                                                    item.IsEndDeadline && !(isPast && item.Status == 2) ?
                                                        this.getDataExtendLesson(item)
                                                        :
                                                        this.setState({
                                                            showModal: true,
                                                            modalType: 2,
                                                            dataLesson: item,
                                                            inputDocumnetLink: localStorage.getItem(`${KEY.STUDY_DOCUMENT_LINK}${item.StudyStudentId}`) || '',
                                                            inputDocumnetContent: localStorage.getItem(`${KEY.STUDY_DOCUMENT_CONTENT}${item.StudyStudentId}`) || '',
                                                            inputDocumnetImage: localStorage.getItem(`${KEY.STUDY_DOCUMENT_IMAGE}${item.StudyStudentId}`) || '',
                                                            isDocumnetYoutube: eval(localStorage.getItem(`${KEY.STUDY_IS_DOCUMENT_YOUTUBE}${item.StudyStudentId}`)) || false,
                                                            isDocumnetTiktok: eval(localStorage.getItem(`${KEY.STUDY_IS_DOCUMENT_TIKTOK}${item.StudyStudentId}`)) || false,
                                                            isEditDocument: false
                                                        })
                                                }}
                                            >
                                                <p className='mgb-0 color-white'>+ Thêm tài liệu tham khảo</p>
                                            </div>
                                        }
                                        {item.Documents.length > 1 &&
                                            <div
                                                className='item-center mgt'
                                                onClick={() => this.setState({
                                                    showDocumentId: showDocumentId == item.StudyStudentId ? 0 : item.StudyStudentId
                                                })}
                                            >
                                                <img
                                                    src={showDocumentId == item.StudyStudentId ? require('../../../assets/image/up.png') : require('../../../assets/image/down.png')}
                                                    style={{ width: '4vh' }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </InfiniteScroll>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={[1, 2, 9].includes(modalType) ? '120vh' : PROP.MODAL.width}
                >
                    {this.renderModal()}
                </Modal>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalDocumentView}
                    onCancel={() => this.setState({ showModalDocumentView: false, documentView: {}, isLoadingDocumentModal: true })}
                    width={'120vh'}
                >
                    <div className='mgt'>
                        {isLoadingDocumentModal &&
                            <Loading small />
                        }
                        {this.renderModalViewDocument()}
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(LessonStudent)
