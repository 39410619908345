import Main from './Main';
import User from './User';
import SubCategory from './SubCategory';
import Admin from './Admin';
import Log from './Log';
import Subject from './Subject'
import Support from './Support';
import Ticket from './Ticket';
import ZCrazy from './ZCrazy';
import Zvip from './Zvip';
import Audio from './Audio';

export default {
    Main,
    User,
    SubCategory,
    Admin,
    Log,
    Subject,
    Support,
    Ticket,
    ZCrazy,
    Zvip,
    Audio
}