const INITAL_STATE = {
    listNewfeed: [],
    isLoading: true,
    endlist: false,
    page: 1,
    typeNewfeed: 1,
    userId: ''
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_GET_LIST_NEWFEED':
            return {
                ...state,
                typeNewfeed: action.typeNewfeed,
                listNewfeed: [],
                isLoading: true,
                endlist: false,
            }
        case 'SUCCESS_GET_LIST_NEWFEED':
            return {
                ...state,
                isLoading: false,
                listNewfeed: action.page == 1 ? action.listNewfeed : [...state.listNewfeed, ...action.listNewfeed],
                endList: action.listNewfeed.length < 10,
                page: action.page || state.page,
                typeNewfeed: action.typeNewfeed || state.typeNewfeed,
                userId: action.userId || state.userId,
            };
        case 'CHANGE_DATA_NEWFEED':
            return {
                ...state,
                listNewfeed: action.data,
            };
        case 'CLEAR_NEWFEED_DATA':
            return{
                ...INITAL_STATE
            }
        case 'NEWFEED_UPDATE_STATUS':{
            return {
                ...state,
                listNewfeed: [
                    {
                        NewfeedId: action.newfeedId,
                        NewfeedType: "9",
                        CountComment: '0',
                        CrDateTime: 'Vừa xong',
                        FacebookId: action.dataUser.FacebookId,
                        FullName: action.dataUser.FullName,
                        ListComment: [],
                        Status: action.status,
                        TargetId: "0",
                        UserId: action.dataUser.UserId
                    },
                    ...state.listNewfeed
                ]
            };
        }
        case 'STORE_LIST_NEWFEED':
            return {
                ...state,
                listNewfeed: action.listNewfeed,
            };
        case 'FALSE_GET_LIST_NEWFEED':
            return { ...state }
        default:
            return state;
    }
};