import React from 'react';
import Lottie from 'react-lottie';

export default (props) =>(
    <div className='item-center mgt'>
        <Lottie
            options={{loop: props.disableLoop ? false : true, autoplay: true, animationData: props.source}}
            width={props.size ? props.size : '30vh'}
        />
    </div>
)