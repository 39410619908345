import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showHideSearch } from '../../actions/search'
import Icon from 'react-fontawesome';
import {
    getListChatPeople,
    showHideChat
} from '../../actions/chat';
import { getTimeGuideAction } from '../../actions/menu'
import Countdown, { zeroPad } from 'react-countdown-now';
import Loading from '../../components/Loading'
import { withRouter } from 'react-router';
import { searchTextApi } from '../../api/search';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import TooltipUser from '../TooltipUser';
import { getAvatarFb, removeUnicode } from '../../utils/common';
import SearchListContainer from './SearchListContainer';
import { getUserItem } from '../../actions/home/';
import ButtonLanguage from './ButtonLanguage';

class Widget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSearch: '',
            listUser: [],
            listPost: [],
            listVideo: [],
            listGuideFilter: [],
            isLoadingSearch: false
        }
        this.timeoutSearch = ''
    }

    openSearch = () => {
        this.props.getTimeGuideAction();
        this.props.showHideSearch(true)
    }

    changeSearchText = (e) => {
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.inputSearchRef.focus();
        if (inputSearch != '') {
            this.setState({ isLoadingSearch: true })
            if (this.timeoutSearch) clearTimeout(this.timeoutSearch)
            this.timeoutSearch = setTimeout(() => {
                searchTextApi(inputSearch).then(res => {
                    if (res.code == 1) {
                        this.setState({
                            listGuideFilter: this.props.listGuide.filter(item => {
                                const titleGuide = removeUnicode(item.title).toLowerCase();
                                const textSearch = removeUnicode(inputSearch).toLowerCase();
                                return titleGuide.indexOf(textSearch) >= 0
                            }),
                            listUser: res.listUser,
                            listVideo: res.listVideo,
                            listPost: res.listPost,
                            isLoadingSearch: false
                        })
                    }
                    else showNotification(MESSAGE.ERROR, 0)
                }).catch(e => {
                    console.log(e);
                    showNotification(MESSAGE.ERROR, 0)
                })
            }, 200)
        }
    }

    navigateToScreen = (link, otherAction) => {
        this.props.history.push(link);
        switch (otherAction) {
            case 'kitchen':
                setTimeout(() => {
                    this.props.getUserItem();
                }, 100);
                break;
            case 'storehouse':
                setTimeout(() => {
                    this.props.getUserItem(1);
                }, 100);
                break;
            case 'stock':
                setTimeout(() => {
                    this.props.getUserItem(2);
                }, 100);
                break;
            case 'bedroom':
                setTimeout(() => {
                    this.props.getUserItem(3);
                }, 100);
                break;
            case 'livingRoom':
                setTimeout(() => {
                    this.props.getUserItem(4);
                }, 100);
                break;
            default:
                break;
        }
        this.props.showHideSearch(false)
    }

    render() {
        const { inputSearch, listUser, listPost, listVideo, listGuideFilter, isLoadingSearch } = this.state;
        const { showSearch } = this.props;
        if (showSearch) {
            return (
                <div style={{ display: 'contents' }}>
                    <div className='container-search animated zoomIn fastest'>
                        <div className='search-container'>
                            <div className='search-content'>
                                <div
                                    className='pointer'
                                    onClick={() => this.props.showHideSearch(false)}
                                >
                                    <img
                                        src={require('../../assets/image/arrow-down.png')}
                                        className='icon-close-chat animated infinite pulse'
                                    />
                                </div>
                                <ButtonLanguage widget />
                                <div className='input-search-container'>
                                    <input
                                        className='input-search-nav'
                                        placeholder='Tìm kiếm'
                                        onChange={this.changeSearchText}
                                        ref={e => this.inputSearchRef = e}
                                        value={inputSearch}
                                    />
                                    {inputSearch.length > 0 &&
                                        <img
                                            onClick={() => this.setState({ inputSearch: '' })}
                                            className='pointer'
                                            src={require('../../assets/image/delete.png')}
                                            style={{ width: '3vh', height: '3vh', position: 'absolute', right: 15, top: '1vh' }}
                                        />
                                    }
                                    
                                </div>
                                <div className='search-list-container'>
                                    {inputSearch ?
                                        <>
                                            {isLoadingSearch ? <Loading small />
                                                :
                                                <>
                                                    {listUser.length > 0 &&
                                                        <>
                                                            <p className='text-large mgb'>Thành viên</p>
                                                            {listUser.map((item, key) =>
                                                                <div
                                                                    className='item-search-container'
                                                                    style={{ justifyContent: 'space-between' }}
                                                                    key={key}
                                                                    onClick={() => {
                                                                        this.props.showHideSearch(false);
                                                                        this.props.history.push('/blank');
                                                                        setTimeout(() => {
                                                                            this.props.history.push('/home/guest?id=' + item.UserId)
                                                                        });
                                                                    }}
                                                                >
                                                                    <div className='align-center flex'>
                                                                        <TooltipUser userInfo={item} />
                                                                        <div className='text-item-search'>
                                                                            <p className='mgb-0'>{item.FullName}</p>
                                                                            <p className='text-time' style={{ color: 'white' }}>VIP {item.UserVip} - {item.PhoneNumber.replace(item.PhoneNumber.substring(3,6), '***')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex'>
                                                                        <div
                                                                            className='row-call-user'
                                                                        >
                                                                            <a href={`tel:${item.PhoneNumber}`}>
                                                                                <Icon name='phone' style={{ fontSize: '3vh' }} />
                                                                            </a>
                                                                        </div>
                                                                        <div
                                                                            className='row-call-user'
                                                                            onClick={() => {
                                                                                this.props.getListChatPeople(item.UserId);
                                                                                this.props.showHideChat(true, false, {
                                                                                    UserId: item.UserId,
                                                                                    FacebookId: item.FacebookId,
                                                                                    Fulltitle: item.FullName,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <Icon name='comments' style={{ fontSize: '3vh' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                    {listGuideFilter.length > 0 &&
                                                        <>
                                                            <p className='text-large mgb'>Sự kiện</p>
                                                            {listGuideFilter.map((item, key) =>
                                                                <div
                                                                    key={key}
                                                                    className='item-search-container'
                                                                    onClick={() => this.navigateToScreen(item.link)}
                                                                >
                                                                    <div className='icon-item-search-container'>
                                                                        <img
                                                                            src={item.image}
                                                                            className='icon-item-search'
                                                                        />
                                                                    </div>
                                                                    {item.timeQueue > 0 ?
                                                                        <div className='text-item-search'>
                                                                            <p className='mgb-0'>{item.title}</p>
                                                                            <p className='text-time'>Diễn ra sau <Countdown
                                                                                date={item.timeQueue * 1000}
                                                                                renderer={({ days, hours, minutes, seconds }) => (<span className='text-time'>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                                                            /></p>
                                                                        </div>
                                                                        :
                                                                        <div className='text-item-search'>
                                                                            <p className='mgb-0'>{item.title}</p>
                                                                            <p className='text-time'>Đang diễn ra</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                    {/* {listPost.length > 0 &&
                                                        <>
                                                            <p className='text-large mgb'>Bài viết</p>
                                                            {listPost.map((item, key) =>
                                                                <div
                                                                    className='item-search-container'
                                                                    key={key}
                                                                    onClick={() => this.navigateToScreen(`/menu/post-search?id=${item.PostId}`)}
                                                                >
                                                                    <img
                                                                        src={getAvatarFb(item.FacebookId)}
                                                                        className='avatar-guest'
                                                                    />
                                                                    <div className='text-item-search'>
                                                                        <p className='mgb-0'>Bài viết mã số {item.PostId}: {item.PostTitle}</p>
                                                                        <p className='text-time'>{item.FullName}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                    {listVideo.length > 0 &&
                                                        <>
                                                            <p className='text-large mgb'>Video</p>
                                                            {listVideo.map((item, key) =>
                                                                <div
                                                                    key={key}
                                                                    className='item-search-container'
                                                                    onClick={() => this.navigateToScreen(`/menu/video-search?id=${item.VideoId}`)}
                                                                >
                                                                    <img
                                                                        src={getAvatarFb(item.FacebookId)}
                                                                        className='avatar-guest'
                                                                    />
                                                                    <div className='text-item-search'>
                                                                        <p className='mgb-0'>Video mã số {item.VideoId}: {item.VideoTitle}</p>
                                                                        <p className='text-time'>{item.FullName}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    } */}
                                                </>
                                            }
                                        </>
                                        :
                                        <SearchListContainer navigateToScreen={this.navigateToScreen} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className='container-icon-search animated fadeInUp faster'>
                <img
                    className='pointer'
                    src={require('../../assets/image/search-icon.png')}
                    style={{ width: '7vh' }}
                    onClick={this.openSearch}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { listGuide } = state.getListGuide;
    const { showSearch } = state.getListSearch;
    const { dataUser } = state.getUserData;
    return { showSearch, dataUser, listGuide }
}

export default connect(mapStateToProps, {
    showHideSearch,
    getListChatPeople,
    showHideChat,
    getTimeGuideAction,
    getUserItem,
})(withRouter(Widget))