import React from 'react';

export default (props) =>{
    let retVal = [];
    for(let z = 0;z < props.vipLevel; z++){
        retVal.push (
            <img key={z} className={props.className} src={require("../../assets/image/club-level.png")}/>
        )
    }
    return (
        <div className='flex-row'>{retVal}</div>
    )
}