import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getClubCreateCostApi= () =>{
    let url = `${URI_PATH}app/club/create/getClubCreateCost`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const createClubApi = (clubName, clubDesc = '') =>{
    let url = `${URI_PATH}app/club/create/createClub`;
    let body = {
        ClubName: clubName,
        ClubDesc: clubDesc
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
