import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { MESSAGE } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { TitleSuccess } from '../../components/frame';
import moment from 'moment';
import { getAllLogApi } from '../../api/admin/study/log';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageId: 1,
            isLoading: true,
            listLog: [],
            endList: false,
            inputSearch: ''
        }
    }

    getListLog = (pageId = 1) => {
        getAllLogApi(pageId, this.state.inputSearch).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listLog: pageId == 1 ? res.data : [...this.state.listLog, ...res.data],
                    endList: res.data.length < 20,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    changeInputSearch = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.timeoutSearch = setTimeout(() => {
            this.getListLog(1);
        }, 500);
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListLog(this.state.pageId + 1);
        }
    }

    componentDidMount() {
        this.getListLog()
    }

    render() {
        const { listLog, endList, isLoading, inputSearch} = this.state;
        return (
            <>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <TitleSuccess>Nhật ký</TitleSuccess>
                        <input
                            type='text'
                            className='form-control'
                            value={inputSearch}
                            onChange={this.changeInputSearch}
                            placeholder='Nhập họ tên hoặc số điện thoại'
                        />
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                            className='mgt-2'
                        >
                            {listLog.map((item, key) =>
                                <div className={`box-primary ${(item.IsEnable ? 'bg-success' : 'bg-danger')}`} key={key}>
                                    <div className='history-content'>
                                        <p>{item.Message}</p>
                                        <p>{moment(item.CrDateTime, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm")}</p>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)