import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getItemUpgradeApi = () =>{
    let url = `${URI_PATH}app/property/upgrade/getListUpgrade`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAssetHintApi = (listAssetSelect, listFoodSelect) =>{
    let url = `${URI_PATH}app/property/upgrade/getAssetHint`;
    let body = {
        ListAssetSelect: listAssetSelect,
        ListFoodSelect: listFoodSelect
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const upgradeAssetApi = (assetTarget, listAssetSelect, listFoodSelect) =>{
    let url = `${URI_PATH}app/property/upgrade/upgradeAsset`;
    let body = {
        AssetId: assetTarget,
        ListAssetSelect: listAssetSelect,
        ListFoodSelect: listFoodSelect
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}