import React, { Component } from 'react';
import {MESSAGE, PROP} from '../../utils/constants';
import {connect} from 'react-redux';
import {getQuestionListApi, saveAppealQuestionApi} from '../../api/question/list';
import { changePoint } from '../../actions/home/';
import {showNotification} from '../../utils/notification';
import Loading from '../../components/Loading';
import {Button, Modal} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import Icon from 'react-fontawesome';

class QuestionList extends Component {
    constructor(props){
        super(props);
        this.state = {
            questionList: [],
            questionId: '',
            isRandom: '',
            questionIdAppeal: '',
            questionTitleAppeal: '',
            answerAppeal: '',
            inputAppeal: '',
            isLoading: true,
            isPress: false,
            loadingFirstList: true,
            endList: false,
        };
        this.pageList = 1;
    }

    getQuestionList = (page = 1, questionId = '', isRandom = '') =>{
        getQuestionListApi(page, questionId, isRandom).then(res =>{
            this.setState({
                questionList: res.questionList,
                isLoading: false,
                loadingFirstList: false
            })
        }).catch(e => showNotification(MESSAGE.ERROR, -1));
    }

    searchQuestion = e =>{
        let value = parseInt(e.target.value);
        if(Number.isInteger(value)){
            this.pageList = 1;
            this.setState({
                questionId: value,
                endList: value ? true : false,
                isRandom: ''
            });
            this.getQuestionList(1, value);
        }
        else if(e.target.value == ''){
            this.setState({
                questionId: '',
            });
            this.getQuestionList();
        }
    }

    randomQuestion = () =>{
        this.pageList = 1;
        let isRandom = this.state.isRandom ? '' : true;
        this.setState({
            questionId: '',
            isRandom: isRandom
        });
        this.getQuestionList(1, '', isRandom);
    }

    loadMoreList = () => {
        let {endList, loadingFirstList, questionId, isRandom, questionList} = this.state;
        if(!loadingFirstList && !endList){
            this.pageList += 1;
            getQuestionListApi(this.pageList, questionId, isRandom).then(res =>{
                if(res.questionList.length > 0){
                    this.setState({
                        questionList: [...questionList, ...res.questionList],
                    });
                }
                else this.setState({endList: true});
            }).catch(error => showNotification(MESSAGE.ERROR, -1));
        }
    }

    appealQuestion = (questionId, questionTitle, answer) =>{
        this.setState({
            showModal: true,
            questionIdAppeal: questionId,
            questionTitleAppeal: questionTitle,
            answerAppeal: answer
        })
    }

    saveAppealQuestion = ()=>{
        let {isPress, inputAppeal, questionIdAppeal} = this.state;
        let idNotification = '#notificationAppealQuestion'
        if(isPress === false){
            if(inputAppeal){
                this.setState({isPress: true});
                saveAppealQuestionApi(inputAppeal, questionIdAppeal).then(res =>{
                    if(res.code == 1) {
                        this.setState({
                            inputAppeal: '',
                            showModal: false,
                            isPress: false
                        });
                        showNotification(res.message, res.code);
                    }
                    else{
                        showNotification(res.message, res.code, idNotification);
                        this.setState({isPress: false});
                    }
                }).catch(e =>{
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({isPress: false});
                })
            }
            else showNotification('Vui lòng điền nội dung kháng nghị', -1, idNotification);
        }
    }

    hideModal = () => this.setState({showModal: false});

    componentDidMount(){
        this.getQuestionList();
    }

    render(){
        let {isLoading, isPress, questionList, endList, questionId, isRandom, questionTitleAppeal, inputAppeal, answerAppeal} = this.state;
        if(isLoading) return (<Loading />);
        return(
            <div>
                <div className='item-center'>
                    <img
                        src={require('../../assets/image/menu/question-list.png')}
                        style={{width: '20vh'}}
                    />
                </div>
                <div className='row item-center'>
                    <div className='col-sm-6 col-xs-12'>
                        <input
                            className='form-control text-center'
                            placeholder='🔎 Mã câu hỏi'
                            value={questionId}
                            onChange={this.searchQuestion}
                        />
                    </div>
                </div>
                <div className='item-center mgt'>
                    <Button
                        className={isRandom ? 'bg-success' : 'bg-primary'}
                        onClick={this.randomQuestion}
                    >
                        Random
                    </Button>
                </div>
                <InfiniteScroll
                    loadMore={this.loadMoreList}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {questionList.map((item, key) =>(
                        <div className='container-list-question mgt' key={key}>
                            <div className='flex-row'>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Mã câu hỏi</p>
                                    <p className='text-center'>{item.QuestionId}</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Đóng góp bởi</p>
                                    <p className='text-center'>{item.FullName}</p>
                                </div>
                            </div>
                            <p className='text-center text-large success bold'>{item.QuestionTitle}</p>
                            <div className='flex-row item-center mgt'>
                                <p className='answer-vote bg-success'>
                                    {item.Answear1}
                                </p>
                                <Icon
                                    name='exclamation-triangle'
                                    className='appeal-icon'
                                    onClick={() => this.appealQuestion(item.QuestionId, item.QuestionTitle, item.Answear1)}
                                />
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={this.hideModal}
                    centered
                >
                    <div>
                        <p className='text-center text-large danger bold  mgt'>Kháng nghị</p>
                        <p className='text-center text-large success bold'>Câu hỏi: {questionTitleAppeal}</p>
                        <p className='text-center text-large success bold'>Đáp án hiện tại: {answerAppeal}</p>
                        <textarea
                            className='textarea-question-add mgt'
                            placeholder='Nội dung kháng nghị'
                            value={inputAppeal}
                            onChange={e => this.setState({inputAppeal: e.target.value})}
                        />
                        <div id='notificationAppealQuestion'></div>
                        <div className='item-center mgt'>
                            <Button
                                className='bg-danger'
                                loading={isPress}
                                onClick={this.saveAppealQuestion}
                            >
                                Gửi kháng nghị
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint})(QuestionList);