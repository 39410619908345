import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderLottie from '../../components/HeaderLottie';
import find from '../../assets/lottie/find.json';
import SearchUser from '../../components/SearchUser';
import {withRouter} from 'react-router-dom';

class BrandFind extends Component {
    constructor(props){
        super(props);
    }

    searchUser = userSelect =>{
        if(userSelect[0]){
            let userId = userSelect[0].userId;
            this.props.history.push('/home/guest?id=' + userId);
        }
    }

    render(){
        return(
            <div>
                <HeaderLottie source={find} />
                <div className='row item-center'>
                    <div className='col-md-6 col-sm-8 col-xs-12'>
                    <SearchUser
                        ref='searchUser'
                        onChange={this.searchUser}
                        placeholder='Nhập họ tên hoặc số điện thoại 🔎'
                    />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(BrandFind));