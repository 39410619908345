import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getUserProfileApi = () =>{
    let url = `${URI_PATH}app/setting/profile/getUserProfile`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateProfilePublicApi = (fullName, birthDay) =>{
    let url = `${URI_PATH}app/setting/profile/updateProfilePublic`;
    let body = {
        FullName: fullName,
        BirthDay: birthDay
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateProfilePrivateApi = (gender, idCard, isPrivate) =>{
    let url = `${URI_PATH}app/setting/profile/updateProfilePrivate`;
    let body = {
        Gender: gender,
        IDCardNumber: idCard,
        IsPrivate: isPrivate
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changePasswordApi = (passwordOld, passwordNew) =>{
    let url = `${URI_PATH}app/setting/profile/changePassword`;
    let body = {
        UserPass: passwordOld,
        NewPass: passwordNew
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}