const INITAL_STATE = {
    listResource: [],
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_RESOURCE_COOK':
            return { ...state, listResource: action.listResource };
        case 'FALSE_GET_RESOURCE_COOK':
            return { ...state };
        default:
            return state;
    }
};