import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListHistoryApi = (logTypeId, page) =>{
    let url = `${URI_PATH}app/setting/history/getListHistory`;
    let body = {
        LogTypeId: logTypeId,
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}