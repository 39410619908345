import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getInfoUserAffApi = userAff =>{
    let url = `${URI_PATH}app/authentication/register/getInfoUserAff`;
    let body = {
        UserAff: userAff
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkExistApi = (phoneNumber, email) =>{
    let url = `${URI_PATH}app/authentication/register/checkExist`;
    let body = {
        PhoneNumber: phoneNumber,
        Email: email
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addUserQueueApi = (fullName, phoneNumber, email, birthDay, userPass) =>{
    let url = `${URI_PATH}app/authentication/register/addUserQueue`;
    let body = {
        FullName: fullName,
        PhoneNumber: phoneNumber,
        Email: email,
        BirthDay: birthDay,
        UserPass: userPass
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const verifyPhoneApi = (phoneNumber, userqueueId) =>{
    let url = `${URI_PATH}app/authentication/register/verifyPhone`;
    let body = {
        PhoneNumber: phoneNumber,
        UserqueueId: userqueueId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const verifyEmailApi = (tokenEmail, userqueueId) =>{
    let url = `${URI_PATH}app/authentication/register/verifyEmail`;
    let body = {
        TokenEmail: tokenEmail,
        UserqueueId: userqueueId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sendEmailAuthenApi = (userqueueId) =>{
    let url = `${URI_PATH}app/authentication/register/sendEmailAuthen`;
    let body = {
        UserqueueId: userqueueId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const doneRegisterApi = (userqueueId, userAff, userInvite) =>{
    let url = `${URI_PATH}app/authentication/register/doneRegister`;
    let body = {
        UserqueueId: userqueueId,
        AffUserId: userAff,
        UserInviteId: userInvite
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}