import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataSubjectApi = () =>{
    let url = `${URI_PATH}app/admin/study/subject/getDataSubject`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateSubjectApi = (Subject, Info, IsZticket, BaseBalance, StepBalance, StudySubjectId) =>{
    let url = `${URI_PATH}app/admin/study/subject/updateSubject`;
    let body = {
        Subject,
        Info,
        IsZticket,
        BaseBalance,
        StepBalance,
        StudySubjectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateDocumentLinkApi = (Title, Content, StudyDocumentLinkId) =>{
    let url = `${URI_PATH}app/admin/study/subject/updateDocumentLink`;
    let body = {
        Title,
        Content,
        StudyDocumentLinkId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDocumentLinkApi = () =>{
    let url = `${URI_PATH}app/admin/study/subject/getDocumentLink`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteDocumentLinkApi = (StudyDocumentLinkId) =>{
    let url = `${URI_PATH}app/admin/study/subject/deleteDocumentLink`;
    let body = {
        StudyDocumentLinkId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
