import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataSecureApi = () =>{
    let url = `${URI_PATH}app/setting/secure/getDataSecure`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionSecureApi = (isEnabled2FA, userCode, secretCode) =>{
    let url = `${URI_PATH}app/setting/secure/actionSecure`;
    let body = {
        IsEnabled2FA: isEnabled2FA,
        UserCode: userCode,
        SecretCode: secretCode
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}