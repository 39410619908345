import React, { Component } from 'react';
import { getUserData, updateDataUser } from '../../actions/home';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import { formatDecimal, replaceCost } from '../../utils/common';
import { getDataUserAffApi, getListBankApi, removeRequestDepositApi, requestDepositWithdrawApi } from '../../api/aff';
import { showNotification } from '../../utils/notification';
import Icon from 'react-fontawesome';

const MODAL_TYPE = {
    DEPOSIT: 1,
    DEPOSIT_CONFIRM: 2,
    WITHDRAW: 3,
    WITHDRAW_CONFIRM: 4
}

class UserInfoAff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTab: 1,
            showModal: false,
            modalType: MODAL_TYPE.DEPOSIT,
            inputMoney: '',
            inputBank: '',
            inputStk: '',
            inputBranch: '',
            isPress: false,
            banks: [],
            balanceEarn: 0,
            expire: '--/--/----'
        }
    }

    changeInputMoney = e => {
        let money = e.target.value?.replace(/,|\./g, '');
        this.setState({ inputMoney: formatDecimal(money) });
    }

    getListBank = () => {
        getListBankApi().then(res => {
            this.setState({ banks: res.data })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getDataUserAff = () => {
        getDataUserAffApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    balanceEarn: res.data?.balanceEarn,
                    expire: res.data?.expire
                })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    requestDepositWithdraw = () => {
        if (!this.state.isPress) {
            const { inputBank, inputBranch, inputStk, modalType, inputMoney } = this.state;
            if (!inputMoney) {
                showNotification('Vui lòng nhập số tiền');
                return;
            }
            if (modalType === MODAL_TYPE.WITHDRAW_CONFIRM) {
                if (!inputBank) return showNotification('Bạn chưa chọn Tên ngân hàng', 0);
                if (!inputStk) return showNotification('Bạn chưa nhập Số tài khoản', 0);
            }
            this.setState({ isPress: true });
            const money = replaceCost(inputMoney)
            requestDepositWithdrawApi({
                Balance: money,
                BankId: inputBank,
                BankStk: inputStk,
                BankBranch: inputBranch,
                Type: modalType === MODAL_TYPE.WITHDRAW_CONFIRM ? 2 : 1
            }).then(res => {
                this.setState({ isPress: false })
                if (res.code == 1) {
                    if (modalType === MODAL_TYPE.WITHDRAW_CONFIRM) {
                        this.setState({ showModal: false })
                        this.props.updateDataUser('BalanceAff', +this.props.dataUser.BalanceAff - money)
                        showNotification(res.message, res.code)
                    }
                    else{
                        this.setState({modalType: MODAL_TYPE.DEPOSIT_CONFIRM})
                    }
                }
                else showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    removeRequestDeposit = () =>{
        if (!this.state.isPress) {
            this.setState({ isPress: true })
            removeRequestDepositApi().then(res =>{
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({
                        modalType: MODAL_TYPE.DEPOSIT
                    })
                }
                else showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    checkMoney = (modalType) => {
        if (!this.state.inputMoney) {
            showNotification('Vui lòng nhập số tiền');
            return;
        }
        if(modalType == MODAL_TYPE.WITHDRAW_CONFIRM && (replaceCost(this.state.inputMoney) || 0) < 200000){
            showNotification('Số tiền tối thiểu được rút ra là 200.000 đ ');
            return;
        }
        this.setState({ modalType })
    }

    copyLink = (link) => {
        var textArea;
        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
        function selectText() {
            var range, selection;
            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }
        function copyTo() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        createTextArea(link);
        selectText();
        copyTo();
        showNotification('Sao chép Link affiliate thành công', 1);
    }

    componentDidUpdate(_, prevState) {
        if (prevState.showModal !== this.state.showModal) {
            if (!this.state.showModal) {
                this.setState({
                    inputMoney: '',
                    inputBank: '',
                    inputBranch: '',
                    inputStk: '',
                })
            }
        }
    }

    componentDidMount() {
        this.getDataUserAff();
    }

    renderModal = () => {
        const { modalType, inputMoney, inputBank, inputBranch, inputStk, banks, isPress } = this.state;
        const { dataUser } = this.props;
        switch (modalType) {
            case MODAL_TYPE.DEPOSIT:
                return (
                    <>
                        <div className='flex item-center mgt-2'>
                            <p className='mgb-0 text-huge'>Nạp tiền</p>
                        </div>
                        <div className='row mgt-3'>
                            <div className='col-md-3 col-sm-2 col-xs-12'></div>
                            <div className='col-md-6 col-sm-8 col-xs-12 flex align-center'>
                                <input
                                    className='form-control text-center border-white'
                                    placeholder='Nhập số tiền'
                                    value={inputMoney}
                                    onChange={this.changeInputMoney}
                                />
                                <p className='text-large mgb-0 mgl-2'>đ</p>
                            </div>
                            <div className='col-md-3 col-sm-2 col-xs-12'></div>
                        </div>
                        <div className='flex item-center mgt-3'>
                            <Button
                                className='button-money-aff bg-success border-white pointer'
                                style={{ width: '20vh' }}
                                // onClick={() => this.checkMoney(MODAL_TYPE.DEPOSIT_CONFIRM)}
                                onClick={this.requestDepositWithdraw}
                                loading={isPress}
                            >
                                <p className='text-large bold mgb-0'>Nạp</p>
                            </Button>
                        </div>
                    </>
                )
            case MODAL_TYPE.DEPOSIT_CONFIRM:
                return (
                    <>
                        <p
                            className='mgb-0 back-modal-aff'
                            onClick={this.removeRequestDeposit}
                            // onClick={() => this.setState({ modalType: MODAL_TYPE.DEPOSIT })}
                        >
                            ← <u>Chọn nạp số khác</u>
                        </p>
                        <div className='box-modal-aff mgt-2'>
                            <div className='flex-column item-center'>
                                <p className='mgb-0 text-huge'>Nạp tiền</p>
                                <div className='button-money-aff mgt-2'>
                                    <p className='text-large bold mgb-0'>{inputMoney} đ</p>
                                </div>
                            </div>
                        </div>
                        <div className='box-modal-aff mgt'>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge text-center'>Chuyển khoản hoặc quét QR</p>
                            </div>
                            <div className='row align-center mgt-2'>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <p>Ngân hàng: Techcombank</p>
                                    <p>Chủ Tài khoản: Nguyễn Minh Ngọc</p>
                                    <p>Số Tài khoản: 7871888888</p>
                                    <p>Chi Nhánh: Hoàng Gia</p>
                                    <p>Nội dung chuyển khoản: “{dataUser.FullName} {dataUser.PhoneNumber.slice(-4)}“</p>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12 item-center'>
                                    <img
                                        src={`https://img.vietqr.io/image/970407-7871888888-compact2.png?amount=${replaceCost(inputMoney)}&addInfo=${`${dataUser.FullName} ${dataUser.PhoneNumber.slice(-4)}`}&accountName=NGUYEN%20MINH%20NGOC`}
                                        className='aff-qr-img'
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex item-center mgt-3'>
                            <div
                                className='button-confirm-aff shadow-pulse pointer'
                                onClick={this.requestDepositWithdraw}
                            >
                                <p className='text-large bold mgb-0'>Xác nhận đã chuyển khoản</p>
                            </div>
                        </div> */}
                        <p className='mgt-3 text-center'>Quá trình nâng cấp tài khoản sẽ được phê duyệt trong 24h từ lúc đăng ký!</p>
                    </>
                )
            case MODAL_TYPE.WITHDRAW:
                return (
                    <>
                        <div className='flex item-center mgt-2'>
                            <p className='mgb-0 text-huge'>Rút tiền</p>
                        </div>
                        <div className='row mgt-3'>
                            <div className='col-md-3 col-sm-2 col-xs-12'></div>
                            <div className='col-md-6 col-sm-8 col-xs-12 flex align-center'>
                                <input
                                    className='form-control text-center border-white'
                                    placeholder='Nhập số tiền'
                                    value={inputMoney}
                                    onChange={this.changeInputMoney}
                                />
                                <p className='text-large mgb-0 mgl-2'>đ</p>
                            </div>
                            <div className='col-md-3 col-sm-2 col-xs-12'></div>
                        </div>
                        <p className='text-center mgt-2'>Số tiền rút tối thiểu: 200.000 đ</p>
                        <div className='flex item-center mgt-3'>
                            <div
                                className='button-money-aff bg-success border-white pointer'
                                style={{ width: '20vh' }}
                                onClick={() => {
                                    this.checkMoney(MODAL_TYPE.WITHDRAW_CONFIRM)
                                    this.getListBank();
                                }}
                            >
                                <p className='text-large bold mgb-0'>Rút</p>
                            </div>
                        </div>
                    </>
                )
            case MODAL_TYPE.WITHDRAW_CONFIRM:
                return (
                    <>
                        <div className='box-modal-aff mgt-2'>
                            <div className='flex-column item-center'>
                                <p className='mgb-0 text-huge'>Rút tiền</p>
                                <div className='button-money-aff mgt-2'>
                                    <p className='text-large bold mgb-0'>{formatDecimal(inputMoney)} đ</p>
                                </div>
                            </div>
                        </div>
                        <div className='box-modal-aff mgt-2'>
                            <div className='row'>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex align-center'>
                                    <p className='text-large bold'>Tên ngân hàng</p>
                                </div>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <select className='form-control border-white' value={inputBank} onChange={e => this.setState({ inputBank: e.target.value })}>
                                        <option>Chọn ngân hàng</option>
                                        {banks.map(item =>
                                            <option key={item.BankId} value={item.BankId}>{item.BankName}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='row mgt'>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex align-center'>
                                    <p className='text-large bold'>Chủ tài khoản</p>
                                </div>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <input
                                        className='form-control text-center border-white'
                                        value={dataUser.FullName}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='row mgt'>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex align-center'>
                                    <p className='text-large bold'>Số tài khoản</p>
                                </div>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <input
                                        className='form-control text-center border-white'
                                        value={inputStk}
                                        onChange={e => this.setState({ inputStk: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='row mgt'>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex align-center'>
                                    <p className='text-large bold'>Chi nhánh</p>
                                </div>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <input
                                        className='form-control text-center border-white'
                                        value={inputBranch}
                                        onChange={e => this.setState({ inputBranch: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex item-center mgt-3'>
                            <Button
                                className='button-money-aff bg-success pointer'
                                style={{ width: '20vh' }}
                                onClick={this.requestDepositWithdraw}
                                loading={isPress}
                            >
                                <p className='text-large bold mgb-0'>Rút</p>
                            </Button>
                        </div>
                        <p className='mgt-3 text-center'>Để đảm bảo an toàn thu nhập tránh kẻ gian rút tiền, tài khoản ngân hàng
                            nhận tiền bắt buộc có tên giống với tên tài khoản trên Zcity
                        </p>
                        <p className='text-center'>Quá trình rút sẽ được phê duyệt trong 24h từ lúc tạo lệnh!</p>
                    </>
                )
        }
        return null
    }

    renderButtonStatus = (item) => {
        if (item.Status == 1 || item.LogTypeId == 2 || item.LogTypeId == 3) {
            return (
                <div className='button-money-aff bg-success border-white' style={{ width: '20vh' }}>
                    <p className='text-large bold mgb-0'>Thành công</p>
                </div>
            )
        }
        else if (item.Status == 2) {
            return (
                <div className='button-money-aff bg-danger border-white' style={{ width: '20vh' }}>
                    <p className='text-large bold mgb-0'>Thất bại</p>
                </div>
            )
        }
        return (
            <div className='button-money-aff bg-warning border-white' style={{ width: '20vh' }}>
                <p className='text-large bold mgb-0'>Đang xử lý</p>
            </div>
        )
    }

    render() {
        const { dataUser, hideInfo } = this.props;
        const { balanceEarn, expire } = this.state;
        const link = `${window.location.protocol + "//" + window.location.host}/${dataUser.UserId}`
        return (
            <>
                <div className='box-affiliate row'>
                    <div className='col-md-6 col-sm-12 col-xs-12 flex-column align-start item-center'>
                        {!hideInfo && (
                            <div className='flex align-center mgb-2'>
                                <p className='mgb-0' style={{ width: '15vh' }}>Tổng thu nhập:</p>
                                <div className='button-money-aff bg-success'>
                                    <p className='text-large bold mgb-0'>{formatDecimal(balanceEarn)} đ</p>
                                </div>
                            </div>
                        )}
                        <div className='flex align-center'>
                            <p className='mgb-0' style={{ width: '15vh' }}>Số dư hiện tại:</p>
                            <div className='button-money-aff'>
                                <p className='text-large bold mgb-0'>{formatDecimal(dataUser.BalanceAff)} đ</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12 flex-column'>
                        <div className='flex-column row-aff-button-withdraw'>
                            <div
                                className='button-money-aff bg-success mgt pointer'
                                style={{ width: '20vh' }}
                                onClick={() => this.setState({ showModal: true, modalType: MODAL_TYPE.DEPOSIT })}
                            >
                                <p className='text-large bold mgb-0'>Nạp tiền</p>
                            </div>
                            <div
                                className='button-money-aff mgt pointer'
                                style={{ width: '20vh' }}
                                onClick={() => this.setState({ showModal: true, modalType: MODAL_TYPE.WITHDRAW })}
                            >
                                <p className='text-large bold mgb-0'>Rút tiền</p>
                            </div>
                        </div>
                    </div>
                </div>
                {!hideInfo &&
                    <div className='box-affiliate mgt'>
                        <p className='text-large bold text-center'>THÔNG TIN TÀI KHOẢN AFFILIATE CỦA BẠN</p>
                        <div className='flex mgt-2'>
                            <p>• Link affiliate của bạn là: <span className='bold'>{link}</span></p>
                            <div className='flex mgl-2 align-center pointer' onClick={() => this.copyLink(link)}>
                                <Icon
                                    name='files-o'
                                    className='mgl'
                                    style={{ fontSize: '2.5vh',}}
                                />
                                <p><u className='text-time mgl'>Sao chép</u></p>
                            </div>
                        </div>
                        <p className='mgt'>• Mã giảm giá của bạn là: <span className='bold'>{dataUser.PhoneNumber}</span></p>
                        <p className='mgt'>• Thưởng VIP {dataUser.UserVip}: +{dataUser.UserVip}%</p>
                        <p className='mgt'>• Tổng hưởng lợi nhuận Affiliate: 45% +{dataUser.UserVip}%</p>
                        <p className='mgt'>• Mỗi tài khoản nâng cấp Z-VIP bạn nhận được: {formatDecimal(Math.round(1818000 / 100 * (45 + +dataUser.UserVip)))} đ</p>
                        <p className='mgt'>• Mỗi tài khoản nâng cấp Z-Affiliate bạn nhận được: {formatDecimal(Math.round(50000 / 100 * (45 + +dataUser.UserVip)))} đ/tháng</p>
                        <p className='mgt'>• Hiệu lực tài khoản affliate đến: <span className='bold'>{expire}</span></p>
                        <p className='mgt'>• Hệ thống tự động gia hạn phí hợp tác tài khoản Z-Affiliate 50.000 đ/tháng từ số dư hiện tại.</p>
                        <p className='mgt'>• Quá hạn <span className='danger bold'>30</span> ngày kể từ {expire} nếu tài khoản không đủ tiền gia hạn chức năng Affiliate sẽ bị vô hiệu.</p>
                        {/* <video width="100%" className='mgt-2' controls style={{ borderRadius: '1vh' }} autoPlay>
                            <source src={`${URI_PATH}assets/zcity-aff.mp4#t=0.001`} type="video/mp4" />
                        </video> */}
                    </div>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    destroyOnClose={true}
                    centered
                    width='90vh'
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { getUserData, updateDataUser })(UserInfoAff);