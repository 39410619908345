import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListNewfeedApi = (pageId = 1, type = 1, newfeedIdDirect = '', commentIdDirect = '') =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeed`;
    let body = {
        PageId: pageId,
        Type: type,
        NewfeedIdDirect: newfeedIdDirect,
        CommentIdDirect: commentIdDirect
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListNewfeedLobbyApi = (pageId = 1, type = 1) =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeedLobby`;
    let body = {
        PageId: pageId,
        Type: type,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListNewfeedUserApi = (pageId = 1, userId, commentIdDirect = '') =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeedUser`;
    let body = {
        PageId: pageId,
        UserId: userId,
        CommentIdDirect: commentIdDirect
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const commentNewfeedApi = (newfeedId, comment) =>{
    let url = `${URI_PATH}app/newfeed/main/commentNewfeed`;
    let body = {
        NewfeedId: newfeedId,
        Comment: comment
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const replyCommentNewfeedApi = (newfeedCommentId, comment) =>{
    let url = `${URI_PATH}app/newfeed/main/replyCommentNewfeed`;
    let body = {
        NewfeedCommentId: newfeedCommentId,
        Comment: comment
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const loadMoreCommentApi = (newfeedId, listCommentShow) =>{
    let url = `${URI_PATH}app/newfeed/main/loadMoreComment`;
    let body = {
        NewfeedId: newfeedId,
        ListCommentShow: listCommentShow
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const loadMoreReplyCommentApi = (newfeedCommentId, listReplyCommentShow) =>{
    let url = `${URI_PATH}app/newfeed/main/loadMoreReplyComment`;
    let body = {
        NewfeedCommentId: newfeedCommentId,
        ListReplyCommentShow: listReplyCommentShow
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}