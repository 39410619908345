const INITAL_STATE = {
    listTransactionlog: [],
    page: 1,
    isLoading: true,
    endList: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_LIST_TRANSACTIONLOG':
            return { ...state,
                listTransactionlog: action.page == 1 ? action.listTransactionlog : [ ...state.listTransactionlog, ...action.listTransactionlog],
                endList: action.listTransactionlog.length > 0 ? false : true,
                isLoading: false,
                page: action.page,
            }
        case 'FALSE_GET_LIST_TRANSACTIONLOG':
            return { ...state };
        default:
            return state;
    }
};