import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListQuestionFightApi = () =>{
    let url = `${URI_PATH}app/question/fight/fight/getListQuestionFight`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerFightApi = (QuestionTempId, Answer) =>{
    let url = `${URI_PATH}app/question/fight/fight/saveAnswerFight`;
    let body = {
        QuestionTempId,
        Answer
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getQuestionLevelSelectApi = () =>{
    let url = `${URI_PATH}app/question/fight/fight/getQuestionLevelSelect`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}