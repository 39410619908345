import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import Icon from 'react-fontawesome';
import UserBar from './UserBar';
import { getAvatarFb } from '../../../utils/common';
import { Affix } from 'antd';
import { Collapse } from 'react-collapse';
import { showHideWidgetNotification, getListNotification } from '../../../actions/notification';
import { toogleUserBar } from '../../../actions/userbar';

class NavBar extends Component {
    constructor(props) {
        super(props);
    };

    actionWidgetNotification = () => {
        if (!this.props.showWidgetNotification) this.props.getListNotification();
        this.props.showHideWidgetNotification();
    }

    render() {
        let { dataUser, showUserBar } = this.props;
        return (
            <Affix>
                <div>
                    <div className='header-nav'>
                        <NavLink className='col-nav' to="/" exact activeClassName='selected-bar'>
                            <Icon
                                name='graduation-cap'
                                size='2x'
                            />
                        </NavLink>
                        <NavLink className='col-nav' to="/user" activeClassName='selected-bar'>
                            <Icon
                                name='user'
                                size='2x'
                            />
                        </NavLink>
                        <NavLink className='col-nav' to="/subject" activeClassName='selected-bar'>
                            <Icon
                                name='leanpub'
                                size='2x'
                            />
                        </NavLink>
                        <NavLink className='col-nav' to="/sub-category" activeClassName='selected-bar'>
                            <Icon
                                name='list-alt'
                                size='2x'
                            />
                        </NavLink>
                        <NavLink className='col-nav' to="/support" activeClassName='selected-bar'>
                            <Icon
                                name='life-ring'
                                size='2x'
                            />
                        </NavLink>
                        {dataUser.UserId == 1 &&
                            <>
                                <NavLink className='col-nav' to="/admin-account" activeClassName='selected-bar'>
                                    <Icon
                                        name='key'
                                        size='2x'
                                    />
                                </NavLink>
                                <NavLink className='col-nav' to="/log" activeClassName='selected-bar'>
                                    <Icon
                                        name='file-text-o'
                                        size='2x'
                                    />
                                </NavLink>
                                <NavLink className='col-nav' to="/ticket" activeClassName='selected-bar'>
                                    <Icon
                                        name='bank'
                                        size='2x'
                                    />
                                </NavLink>
                                <NavLink className='col-nav' to="/z-vip" activeClassName='selected-bar'>
                                    <Icon
                                        name='money '
                                        size='2x'
                                    />
                                </NavLink>
                            </>
                        }
                        <NavLink className='col-nav' to="/z-crazy" activeClassName='selected-bar'>
                            <Icon
                                name='gamepad'
                                size='2x'
                            />
                        </NavLink>
                        <NavLink className='col-nav' to="/audio" activeClassName='selected-bar'>
                            <Icon
                                name='microphone'
                                size='2x'
                            />
                        </NavLink>
                        <div
                            className='col-nav'
                            onClick={() => this.props.toogleUserBar()}
                        >
                            <img src={getAvatarFb(this.props.dataUser.FacebookId)} className='avatar-guest-small' />
                            {showUserBar && <Icon className='mgl' name='angle-up' />}
                        </div>
                    </div>
                    <Collapse isOpened={showUserBar}>
                        <UserBar />
                    </Collapse>
                </div>
            </Affix>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { showWidgetNotification } = state.getListNotification;
    const { showUserBar } = state.getStateUserBar;
    return { dataUser, showWidgetNotification, showUserBar };
}

export default connect(mapStateToProps, { showHideWidgetNotification, getListNotification, toogleUserBar })(NavBar);