import React, { Component } from 'react';
import { connect } from 'react-redux';
import { URI_PATH, LIST_DONATE, MESSAGE } from '../../../utils/constants';
import { getDataNotify, formatDecimal, isTouch } from '../../../utils/common';
import { withRouter } from 'react-router-dom';
import { changePoint } from '../../../actions/home/';
import { Button, Tooltip } from 'antd';
import FsLightbox from 'fslightbox-react';
import TooltipUser from '../../../components/TooltipUser';
import Loading from '../../../components/Loading';
import { diamondPostApi, donatePostApi, getRankPostApi } from '../../../api/post';
import { socket } from '../../../components/realtime/ReadSocketEvent';
import { showNotification, showNotificationBonus, showNotificationProperty, showNotificationAsset } from '../../../utils/notification';
import { updateCountMission } from '../../../actions/menu';
import { showModalEat } from '../../../actions/home/modalEat';
import { changeDataNewfeed } from '../../../actions/newfeed';
import Checked from '../../frame/Checked';

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postContent: this.props.data.PostContent.slice(0, 450) + ' <b class="pointer"><u> xem thêm...</u></b>',
            isReadMore: false,
            balanceDonate: LIST_DONATE[0],
            isPress: false,
            isLoadingRank: false,
            dataRank: {}
        }
    }

    readMore = () => {
        if (!this.state.isReadMore) {
            this.setState({
                postContent: this.props.data.PostContent,
                isReadMore: true
            })
        }
    }

    renderDonate = () => {
        let { data } = this.props;
        let { balanceDonate, isPress } = this.state;
        return (
            <Tooltip
                overlayClassName='pv-tooltip-diamond'
                trigger={isTouch() ? 'click' : 'hover'}
                title={
                    <div className='flex-column item-center'>
                        <img
                            src={require('../../../assets/image/donate.png')}
                            className='avatar-guest'
                        />
                        <select
                            className='form-control mgt'
                            style={{ width: '75%' }}
                            defaultValue={balanceDonate}
                            onChange={e => this.setState({ balanceDonate: e.target.value })}
                        >
                            {LIST_DONATE.map((item, key) => (
                                <option key={key} value={item}>Tặng {formatDecimal(item)} $[z]</option>
                            ))}
                        </select>
                        <Button
                            className='pv-confirm-diamond'
                            style={{ marginTop: '10vh' }}
                            onClick={() => this.donatePost(data.TargetId, balanceDonate, data.UserId, data.PostTitle, data.NewfeedId)}
                            loading={isPress}
                        >
                            Tặng
                        </Button>
                        <div className='mgt' id={'notificationDonatePost' + data.NewfeedId} />
                    </div>
                }
            >
                <img
                    src={require('../../../assets/image/donate.png')}
                    className='pv-donate-small'
                />
            </Tooltip>
        )
    }

    renderDiamond = () => {
        let { data, dataUser } = this.props;
        let { isPress } = this.state;
        if (!data.IsDiamond && data.DiamondQueue > 0) {
            return (
                <Tooltip
                    overlayClassName='pv-tooltip-diamond'
                    trigger={isTouch() ? 'click' : 'hover'}
                    title={
                        <div className='flex-column item-center'>
                            <img
                                src={require('../../../assets/image/diamond-white.gif')}
                                style={{ width: '8vh' }}
                            />
                            <Button
                                className='pv-confirm-diamond'
                                onClick={() => this.diamondPost(data.TargetId, data.UserId, data.PostTitle, data.NewfeedId)}
                                loading={isPress}
                            >
                                Bình chọn
                            </Button>
                            <div className='mgt' id={'notificationDiamondPost' + data.NewfeedId} />
                        </div>
                    }
                >
                    <div className='flex-column item-center'>
                        <img
                            src={require('../../../assets/image/diamond-white.gif')}
                            className='pv-diamond'
                        />
                        <div className='count-diamond-remain'>
                            <p class="mgb-0 text-huge">{data.Diamond}</p>
                        </div>
                    </div>
                </Tooltip>
            )
        }
        return (
            <div className='flex-column item-center'>
                <img
                    src={require('../../../assets/image/diamond-disable.png')}
                    className='pv-diamond-disable'
                />
                <div className='count-diamond-remain'>
                    <p class="mgb-0 text-huge">{data.Diamond}</p>
                </div>
            </div>
        );
    }

    renderRank = () => {
        let { dataRank, isLoadingRank } = this.state;
        if (isLoadingRank) return (<Loading small />);
        return (
            <div className='row mgt-3'>
                <div className='col-md-6 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Hôm nay</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataRank.diamondToday}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondToday}/{dataRank.allPostToday}</p>
                        </div>
                    </div>
                </div>
                {/* <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Tuần</Button>
                        <div className='flex-row item-center mgt-3'>
                        <p>{dataRank.diamondWeek}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondWeek}/{dataRank.allPostWeek}</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Tháng</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataRank.diamondMonth}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondMonth}/{dataRank.allPostMonth}</p>
                        </div>
                    </div>
                </div> */}
                <div className='col-md-6 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Trọn đời</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataRank.diamondAll}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondAll}/{dataRank.allPost}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    donatePost = (postId, balanceDonate, userPost, postTitle, newfeedId) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            let idNotification = '#notificationDonatePost' + newfeedId;
            balanceDonate = parseInt(balanceDonate);
            donatePostApi(postId, balanceDonate).then(res => {
                if (res.code == 1) {
                    let dataUser = this.props.dataUser;
                    //socket
                    if (userPost != dataUser.UserId) {
                        let datas = getDataNotify(userPost, 0, res.balanceBonus);
                        datas.message = {
                            fullName: dataUser.FullName,
                            facebookId: dataUser.FacebookId,
                            message: 'tặng ' + formatDecimal(balanceDonate) + ' $[z] cho bài viết "' + postTitle + '" của bạn',
                            isNotify: 2
                        }
                        socket.emit('send_notify', datas);
                    }
                    showNotificationBonus(balanceDonate, -1, 2);
                    showNotificationBonus(balanceDonate / 1000, 1, 5);
                    this.props.changePoint([null, -balanceDonate, null, null, balanceDonate / 1000]);
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if (el.NewfeedId == newfeedId) return Object.assign({}, el, {
                            Donate: parseInt(el.Donate) + balanceDonate
                        })
                        return el;
                    }));
                }
                showNotification(res.message, res.code, idNotification);
                this.setState({ isPress: false });
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    diamondPost = (postId, userPost, postTitle, newfeedId) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            let idNotification = '#notificationDiamondPost' + newfeedId;
            diamondPostApi(postId, userPost).then((res) => {
                if (res.code == 1) {
                    showNotificationProperty(res.property);
                    showNotificationAsset(res.assetInfo);
                    showNotificationBonus(res.bonus.BalanceZ, 1, 3);
                    showNotificationBonus(res.bonus.Achievement, 1, 4);
                    this.props.changePoint([null, null, res.bonus.BalanceZ, res.bonus.Achievement, null, -res.diamondCount]);
                    this.props.updateCountMission('PostDiamondCount');
                    //socket
                    let dataUser = this.props.dataUser;
                    let dataSent = getDataNotify(userPost, 0, 0, 0, res.bonus.Achievement, 0, res.propertyPa);
                    dataSent.DiamondCount = res.diamondCount;
                    dataSent.message = {
                        fullName: dataUser.FullName,
                        facebookId: dataUser.FacebookId,
                        message: 'bình chọn bài viết "' + postTitle + '" của bạn',
                        isNotify: 2
                    }
                    socket.emit('send_notify', dataSent);
                    //play sound
                    new Audio(require('../../../assets/audio/diamond.wav')).play();
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if (el.NewfeedType == 11) {
                            if (el.NewfeedId == newfeedId) {
                                return Object.assign({}, el, {
                                    IsDiamond: postId,
                                    DiamondQueue: 0,
                                    Diamond: +el.Diamond + 1
                                });
                            }
                            return el;
                            // return Object.assign({}, el, {
                            //     DiamondQueue: 0
                            // });
                        }
                        return el;
                    }));
                    showNotification(res.message, res.code);
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                }
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ isPress: false });
            });
        }
    }

    getRankPost = (postId, userPost) => {
        let { dataRank, isLoadingRank } = this.state;
        if (Object.keys(dataRank).length === 0 && !isLoadingRank) {
            this.setState({ isLoadingRank: true });
            getRankPostApi(postId, userPost).then(res => {
                this.setState({
                    dataRank: res.data,
                    isLoadingRank: false
                });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({
                    dataRank: {}
                });
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.PostContent != this.props.data.PostContent) {
            this.setState({ postContent: this.props.data.PostContent.slice(0, 450) + ' <b class="pointer"><u> xem thêm...</u></b>' })
        }
    }

    render() {
        let { showPreviewImage, postContent, imagePreview } = this.state;
        let data = this.props.data;
        return (
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest' />
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                            <Checked data={data} />
                        </span> chia sẻ nội dung chủ đề <span className='bold'>{data.CategoryName}</span></p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='pv-title-container ReactCollapse--collapse'>
                    {data.IsDiamond ?
                        <img
                            src={require('../../../assets/image/diamond-white.gif')}
                            className='pv-diamond-case'
                        />
                        :
                        <img
                            src={require('../../../assets/image/diamond-case.png')}
                            className='pv-diamond-case'
                        />
                    }
                    <div className='flex-row mgt-2'>
                        <div className='flex-row flex-3 align-center'>
                            <p className='pv-check-p bg-success mgb-0'>Mã số: {data.TargetId}</p>
                        </div>
                        <div className='flex-row flex-2 align-center' style={{ justifyContent: 'flex-end' }}>
                            <div>
                                <img
                                    src={require('../../../assets/image/donate.png')}
                                    className='avatar-guest'
                                />
                            </div>
                            <div className='pv-donate-container'>
                                <p className='text-large bold'>{formatDecimal(data.Donate)} $[z]</p>
                            </div>
                        </div>
                    </div>
                    <p className='text-large text-center bold mgt-2 mgb-2'>{data.PostTitle}</p>
                    <div className='item-center'>
                        <img
                            src={require('../../../assets/image/line.png')}
                            className='line-img'
                        />
                    </div>
                    <div className='flex-row flex-3 item-center mgb mgt'>
                        <p className='pv-check-p mgb-0' style={{ background: 'rgba(0,0,0,0.85)' }}>Tôi chia sẻ bài viết này vì: {data.PostReason}</p>
                    </div>
                    <div>
                        {data.PostImage &&
                            <img
                                src={`${URI_PATH}${data.PostImage}`}
                                className='pv-post-image'
                                onClick={() => this.setState({
                                    showPreviewImage: true,
                                    imagePreview: `${URI_PATH}${data.PostImage}`
                                })}
                            />
                        }
                        <div
                            className='text-left'
                            dangerouslySetInnerHTML={{ __html: postContent }}
                            onClick={this.readMore}
                        />
                    </div>
                </div>
                {!data.Guest &&
                    <div className='flex-row mgt-4'>
                        <div className='flex-1 item-center'>
                            {this.renderDonate()}
                        </div>
                        <div className='flex-1 item-center'>
                            {this.renderDiamond()}
                        </div>
                        <div className='flex-1 item-center'>
                            <div className='flex-column item-center'>
                                <img
                                    src={require('../../../assets/image/flag-green.png')}
                                    className='pv-diamond'
                                />
                                <div className='count-diamond-remain'>
                                    <p class="mgb-0 text-huge">{data.Rank}/{data.AllPost}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, { changePoint, updateCountMission, showModalEat, changeDataNewfeed })(withRouter(Post));