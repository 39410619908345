import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const sendApi = (money, userId, userPass, userCode = '', comment = '', type) =>{
    let url = `${URI_PATH}app/charge/action/insert`;
    let body;
    if(type == 0){
        body = {
            TicketCount: money,
            UserId: userId,
            UserPass: userPass,
            UserCode: userCode,
            Comment: comment,
            ChargeTypeId: 1
        };
    }
    else if(type == 1){
        body = {
            PaidVN: money,
            UserId: userId,
            UserPass: userPass,
            UserCode: userCode,
            Comment: comment,
            ChargeTypeId: 4
        };
    }
    else{
        body = {
            PaidVndZ: money,
            UserId: userId,
            UserPass: userPass,
            UserCode: userCode,
            Comment: comment,
            ChargeTypeId: 5
        };
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}