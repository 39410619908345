import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getCartImageApi = () =>{
    let url = `${URI_PATH}app/charge/sell/getCartImage`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getFoodInCartApi = () =>{
    let url = `${URI_PATH}app/charge/sell/getFoodInCart`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteFoodInCartApi = (userfoodId) =>{
    let url = `${URI_PATH}app/charge/sell/deleteFoodInCart`;
    let body = {
        UserfoodId: userfoodId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAssetInCartApi = () =>{
    let url = `${URI_PATH}app/charge/sell/getAssetInCart`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteAssetInCartApi = (userassetId) =>{
    let url = `${URI_PATH}app/charge/sell/deleteAssetInCart`;
    let body = {
        UserassetId: userassetId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListCartApi = () =>{
    let url = `${URI_PATH}app/charge/sell/getListCart`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const renewCartApi = (foodcartId, dayRenew) =>{
    let url = `${URI_PATH}app/charge/sell/renewCart`;
    let body = {
        FoodcartId: foodcartId,
        DayRenew: dayRenew
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const openSellFoodApi = (foodcartId, isOpen) =>{
    let url = `${URI_PATH}app/charge/sell/openSellFood`;
    let body = {
        FoodcartId: foodcartId,
        IsOpen: isOpen
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const openSellAssetApi = (foodcartId, isOpen) =>{
    let url = `${URI_PATH}app/charge/sell/openSellAsset`;
    let body = {
        FoodcartId: foodcartId,
        IsOpen: isOpen
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addFoodToCartApi = (userfoodId, amount, balance, balanceType) =>{
    let url = `${URI_PATH}app/charge/sell/addFoodToCart`;
    let body = {
        UserfoodId: userfoodId,
        Amount: amount,
        Balance: balance,
        BalanceType: balanceType
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addAssetToCartApi = (userassetId, amount, balance, balanceType) =>{
    let url = `${URI_PATH}app/charge/sell/addAssetToCart`;
    let body = {
        UserassetId: userassetId,
        Amount: amount,
        Balance: balance,
        BalanceType: balanceType
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}