import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BarSelect from '../../components/frame/BarSelect';
import Icon from 'react-fontawesome';
import Menu from '../../components/study/menu/Main';
import { getAvatarFb, getTimeAgo, isMaster, isStudent, isTeacher } from '../../utils/common';
import { getListSubjectApi, getListTeacherApi, selectTeacherSubjectApi } from '../../api/study/subject';
import { showNotification } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../../components/Loading';
import { Modal, Button, Switch, Checkbox } from 'antd';
import HeaderLottie from '../../components/HeaderLottie';
import hotline from '../../assets/lottie/hotline.json'
import { EmptyLottie, TitleSuccess } from '../../components/frame';
import TooltipUser from '../../components/TooltipUser';
import { getDeadlineSubjectApi, getListClassSelectApi, getListMemberApi } from '../../api/study/class';
import moment from 'moment';
import { assignLessonApi, deleteAssignApi, getCountLessonApi, getListLessonAssignApi } from '../../api/study/assign';
import { Collapse } from 'react-collapse';
import DatePicker from "react-datepicker";
import { getCategory } from '../../actions/category';
import StarRatings from 'react-star-ratings';
import Countdown, { zeroPad } from 'react-countdown-now';
const ButtonGroup = Button.Group;

class StudyClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSelect: [],
            listMember: [],
            endList: false,
            pageId: 1,
            isLoadingSelect: true,
            isLoadingList: true,
            teacherData: {},
            selectCategoryLesson: 1,
            loadingLesson: true,
            loadingStudentInfo: true,
            listLesson: [],
            selectSubCategoryLesson: '',
            showModalConfirmLesson: false,
            modalTypeConfirmLesson: 1,
            lessonSelect: {},
            deadline: Date.now() + 10800 * 1000,
            dataCountLesson: {},
            dataStudent: {},
            studyClassId: '',
            isLoadingDeadlineSubject: true
        }
        this.studyClassId = '';
    }

    getListClassSelect = () => {
        getListClassSelectApi().then(res => {
            if (res.code == 1) {
                const listSelect = res.data;
                this.setState({
                    listSelect,
                    isLoadingSelect: false,
                    studyClassId: listSelect?.[0]?.StudyClassId
                }, () =>{
                    if (listSelect.length > 0) {
                        this.getListMember()
                    }
                })
            }
            else showNotification(MESSAGE.ERROR, 0)
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    getListMember = (pageId = 1) => {
        const {studyClassId} = this.state;
        getListMemberApi(studyClassId, pageId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listMember: pageId == 1 ? res.listMember : [...this.state.listMember, ...res.listMember],
                    teacherData: res.teacherData,
                    isLoadingList: false,
                    endList: res.listMember.length < 10,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0)
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }


    getCountLesson = () => {
        const { userModalInfo, studyClassId } = this.state;
        getCountLessonApi(userModalInfo.UserId, studyClassId).then(res => {
            if (res.code == 1) {
                this.setState({ dataCountLesson: res.data })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getListLesson = (selectCategoryLesson = 1) => {
        this.setState({
            selectCategoryLesson,
            loadingLesson: true
        })
        const { userModalInfo, studyClassId } = this.state;
        getListLessonAssignApi(selectCategoryLesson, userModalInfo.UserId, studyClassId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLesson: res.data,
                    dataStudent: res.student,
                    loadingLesson: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    showModalLesson = (userInfo) => {
        this.setState({
            showModal: true,
            userModalInfo: userInfo,
            modalType: 4,
            loadingLesson: true,
            loadingStudentInfo: false
        }, () => {
            if (this.props.categoryPost.length == 0) this.props.getCategory(1)
            this.getCountLesson();
            this.getListLesson();
        })
    }

    buttonGroupClass = (selectCategoryLesson) => {
        if (selectCategoryLesson == this.state.selectCategoryLesson) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    getCountByCategory = (categoryId) => {
        const { dataCountLesson } = this.state;
        switch (parseInt(categoryId)) {
            case 1:
                return `(${dataCountLesson.CountDoneLeader}/${dataCountLesson.CountLeader})`
            case 2:
                return `(${dataCountLesson.CountDoneManage}/${dataCountLesson.CountManage})`
            case 3:
                return `(${dataCountLesson.CountDoneRelation}/${dataCountLesson.CountRelation})`
            case 4:
                return `(${dataCountLesson.CountDoneFund}/${dataCountLesson.CountFund})`
            case 5:
                return `(${dataCountLesson.CountDoneLanguage}/${dataCountLesson.CountLanguage})`
            case 6:
                return `(${dataCountLesson.CountDoneExpertise}/${dataCountLesson.CountExpertise})`
        }
        return null;
    }

    renderTimeLessonAssign = (data) => {
        if (data.Status > 0) {
            return <div className='round-deadline-study-lesson'>{data.Deadline}</div>
        }
        else if (data.Status == 0) {
            return <div className='round-deadline-study-lesson bg-warning'>{data.Deadline}</div>
        }
        return null;
    }

    renderActionLessonAssign = (data) => {
        if (data.Status > 0) {
            return null;
        }
        else if (data.Status == 0) {
            return (
                <Icon
                    name='undo'
                    className='text-large pointer'
                    onClick={() => this.setState({
                        showModalConfirmLesson: true,
                        modalTypeConfirmLesson: 2,
                        lessonSelect: data
                    })}
                />
            )
        }
        return (
            <Icon
                name='paper-plane'
                className='text-large pointer'
                onClick={() => this.getDeadlineSubject(data)}
            />
        )
    }

    getDeadlineSubject = (data) => {
        this.setState({
            showModalConfirmLesson: true,
            isLoadingDeadlineSubject: true,
            modalTypeConfirmLesson: 1,
            lessonSelect: data
        })
        getDeadlineSubjectApi(data.StudyAdminId).then(res => {
            if (res.code == 1) {
                this.setState({
                    deadline: Date.now() + parseInt(res.deadline) * 3600 * 1000,
                    isLoadingDeadlineSubject: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    renderModalConfirmLesson = () => {
        let { modalTypeConfirmLesson, userModalInfo, isPress, lessonSelect, deadline, isLoadingDeadlineSubject } = this.state;
        if (modalTypeConfirmLesson == 1) {
            if (isLoadingDeadlineSubject) return <Loading small />
            return (
                <>
                    <div className='item-center'>
                        <img
                            src={getAvatarFb(userModalInfo.FacebookId)}
                            key={userModalInfo.UserId}
                            className='avatar-guest mgb'
                        />
                        <p>Giao bài cho: {userModalInfo.FullName}</p>
                        <p className='text-large text-center mgt-2 mgb-2'>Bài học: {lessonSelect.LessonTitle}</p>
                        <p>Chọn deadline: </p>
                        <div className='flex align-center date-study-picker'>
                            <DatePicker
                                selected={deadline}
                                onChange={deadline => this.setState({ deadline })}
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="time"
                                dateFormat="d-M-yyyy H:mm"
                                showTimeSelect
                                minDate={Date.now()}
                            />
                        </div>
                        <Button
                            className='bg-success mgt-3'
                            loading={isPress}
                            onClick={this.assignLesson}
                        >
                            Xác nhận giao bài
                        </Button>
                    </div>
                </>
            )
        }
        else if (modalTypeConfirmLesson == 2) {
            return (
                <>
                    <div className='item-center'>
                        <img
                            src={getAvatarFb(userModalInfo.FacebookId)}
                            key={userModalInfo.UserId}
                            className='avatar-guest mgb'
                        />
                        <p>{userModalInfo.FullName}</p>
                        <p className='text-large text-center mgt-2 mgb-2'>Thu hồi bài: {lessonSelect.Lesson}</p>
                        <Button
                            className='bg-warning mgt-3'
                            loading={isPress}
                            onClick={this.deleteAssignLesson}
                        >
                            Xác nhận thu hồi
                        </Button>
                    </div>
                </>
            )
        }
        return null;
    }

    assignLesson = () => {
        if (!this.state.isPress) {
            const { userModalInfo, lessonSelect, studyClassId } = this.state;
            const deadline = moment(this.state.deadline).format("YYYY-MM-DD HH:mm");
            this.setState({ isPress: true })
            assignLessonApi(userModalInfo.UserId, lessonSelect.StudyAdminId, deadline, studyClassId).then(res => {
                if (res.code == 1) {
                    showNotification(res.message, res.code);
                    this.setState({
                        isPress: false,
                        showModalConfirmLesson: false
                    })
                    this.getListLesson(this.state.selectCategoryLesson)
                    this.getCountLesson();
                }
                else {
                    this.setState({ isPress: false })
                    showNotification(res.message, 0);
                }
                this.setState({ isPress: false })
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    deleteAssignLesson = () => {
        if (!this.state.isPress) {
            const { userModalInfo, lessonSelect, studyClassId } = this.state
            this.setState({ isPress: true })
            deleteAssignApi(userModalInfo.UserId, lessonSelect.StudyStudentId, studyClassId).then(res => {
                if (res.code == 1) {
                    showNotification(res.message, res.code);
                    this.setState({
                        isPress: false,
                        showModalConfirmLesson: false
                    })
                    this.getListLesson(this.state.selectCategoryLesson)
                    this.getCountLesson();
                }
                else {
                    this.setState({ isPress: false })
                    showNotification(res.message, 0);
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListMember(this.state.pageId + 1);
        }
    }

    componentDidMount() {
        this.getListClassSelect();
    }

    render() {
        const {
            isLoadingSelect,
            listSelect,
            isLoadingList,
            listMember,
            teacherData,
            showModal,
            userModalInfo,
            isPress,
            showModalConfirmLesson,
            listLesson,
            selectSubCategoryLesson,
            loadingLesson,
            loadingStudentInfo,
            dataCountLesson,
            dataStudent,
            studyClassId,
            endList
        } = this.state;
        let { dataUser, categoryPost } = this.props;
        const isTeacher = teacherData.UserId == dataUser.UserId
        if (isLoadingSelect) return <Loading />
        return (
            <>
                {listSelect.length == 0 ?
                    <EmptyLottie title="Bạn chưa tham gia lớp học nào" />
                    :
                    <>
                        <select
                            className='form-control'
                            value={studyClassId}
                            onChange={e => {
                                this.setState({isLoadingList: true, studyClassId: e.target.value}, () =>this.getListMember(1));
                            }}
                        >
                            {listSelect.map((item, key) =>
                                <option value={item.StudyClassId} key={key}>{item.Subject}</option>
                            )}
                        </select>
                        {isLoadingList ?
                            <Loading small />
                            :
                            <>
                                <TitleSuccess>Giáo viên</TitleSuccess>
                                <div className='box-primary item-center'>
                                    <TooltipUser userInfo={teacherData} avatarClass='avatar-guest' />
                                    <div className='item-center mgt'>
                                        <p className='text-center text-large mgl'>{teacherData.FullName}</p>
                                        <p className='text-center text-large mgl'>{teacherData.PhoneNumber}</p>
                                    </div>
                                </div>
                                <TitleSuccess>Học viên</TitleSuccess>
                                <InfiniteScroll
                                    loadMore={this.loadMore}
                                    hasMore={!endList}
                                    loader={<Loading key={0} small />}
                                    threshold={100}
                                >
                                    {listMember.map((item, key) =>
                                        <div
                                            className='club-list-container'
                                            key={key}
                                        >
                                            <div className='flex-row item-center'>
                                                <div className='flex-column item-center'>
                                                    <TooltipUser userInfo={item} avatarClass='avatar-guest' />
                                                    <div className='flex-row item-center mgt'>
                                                        <p className='mgb-2 text-center text-large mgl'>{item.FullName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 col-sm-6 col-xs-12 flex-column'>
                                                    <p className='mgb-0 text-left'>Năm sinh: {moment(item.BirthDay, "YYYY-MM-DD").format("YYYY")}</p>
                                                    <p className='mgb-0 text-left'>Số điện thoại: {item.PhoneNumber}</p>
                                                </div>
                                                <div className='col-md-6 col-sm-6 col-xs-12 flex-column'>
                                                    <p className='mgb-0 text-left'>Bài chưa nộp: {item.LessonUnPublish}</p>
                                                    <p className='mgb-0 text-left'>Tham gia: {getTimeAgo(item.CrDateTime)}</p>
                                                </div>
                                            </div>
                                            {isTeacher && item.DayExipre > 0 &&
                                                <div className='item-center mgt'>
                                                    <Button
                                                        className='bg-success'
                                                        onClick={() => this.showModalLesson(item)}
                                                    >
                                                        Giao bài
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </InfiniteScroll>
                            </>
                        }
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    width={'1050px'}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false, selectCategoryLesson: 0 })}
                >
                    {showModal &&
                        <div className='item-center'>
                            <img
                                src={getAvatarFb(userModalInfo.FacebookId)}
                                key={userModalInfo.UserId}
                                className='avatar-guest'
                            />
                        </div>
                    }
                    {loadingStudentInfo ?
                        <Loading small />
                        :
                        <>
                            <div className='item-center'>
                                <p className='mgt'>{userModalInfo.FullName}</p>
                                <div className='flex item-center'>
                                    <StarRatings
                                        rating={parseFloat(dataStudent?.AvgPoint || 0)}
                                        starRatedColor='white'
                                        starEmptyColor='rgba(255,255,255,0.2)'
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension='2vh'
                                        starSpacing='0.2px'
                                    />
                                    <p className='mgb-0 mgl text-small'>({dataStudent.AllLesson || 0})</p>
                                </div>
                                {dataStudent?.EndStudentTime && dataStudent?.EndStudentTime != '0000-00-00 00:00:00' &&
                                    <p>
                                        <Countdown
                                            date={moment(dataStudent.EndStudentTime, "YYYY-MM-DD HH:mm").unix() * 1000}
                                            renderer={({ days, hours, minutes, seconds }) => (<span>{days}d {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                        />
                                    </p>
                                }
                                <div className='flex item-center row'>
                                    <div className='col-md-2 col-sm-2 col-xs-12'>
                                        <p>Giao bài tự động</p>
                                    </div>
                                    <div className='col-md-2 col-sm-3 col-xs-12'>
                                        <select className='form-deadline-study'>
                                            <option value=''>Tắt</option>
                                            <option value={1}>Deadline 1 ngày</option>
                                            <option value={2}>Deadline 2 ngày</option>
                                            <option value={3}>Deadline 3 ngày</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mgt">
                                    <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                                        {categoryPost.map((item, key) =>
                                            <Button
                                                className={this.buttonGroupClass(item.CategoryId)}
                                                onClick={() => this.getListLesson(item.CategoryId)}
                                                key={key}
                                            >
                                                {item.CategoryName} {this.getCountByCategory(item.CategoryId)}
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </div>
                            </div>
                            {loadingLesson ?
                                <Loading small />
                                :
                                <>
                                    {listLesson.map((lessonCategory, key) => {
                                        const countDone = lessonCategory.filter(item => item.Status).length
                                        return (
                                            <div
                                                className='box-primary pointer relative'
                                                key={key}
                                                onClick={() => this.setState({
                                                    selectSubCategoryLesson: selectSubCategoryLesson == lessonCategory[0].SubCategoryId ? 0 : lessonCategory[0].SubCategoryId
                                                })}
                                            >
                                                <p className='text-center mgb'>
                                                    {lessonCategory[0].SubCategoryName} ({countDone}/{lessonCategory.length})
                                                </p>
                                                <Icon name='chevron-down' className='icon-dropdown-study-lesson' />
                                                <Collapse isOpened={selectSubCategoryLesson == lessonCategory[0].SubCategoryId}>
                                                    {lessonCategory.map((item, key) =>
                                                        <div
                                                            className='box-primary mgb row align-center'
                                                            style={{ cursor: 'default' }}
                                                            onClick={e => e.stopPropagation()}
                                                            key={key}
                                                        >
                                                            <div className='col-md-8 com-sm-8 col-xs-12 item-center'>
                                                                <p className='mgb-0 text-center'>{item.LessonTitle}</p>
                                                            </div>
                                                            <div className='col-md-3 com-sm-3 col-xs-12 item-center flex'>
                                                                {this.renderTimeLessonAssign(item)}
                                                            </div>
                                                            <div className='col-md-1 com-sm-1 col-xs-12 item-center'>
                                                                {this.renderActionLessonAssign(item)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Collapse>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </>
                    }
                </Modal>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalConfirmLesson}
                    onCancel={() => this.setState({ showModalConfirmLesson: false })}
                >
                    {showModalConfirmLesson && this.renderModalConfirmLesson()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost } = state.getCategory;
    return { dataUser, categoryPost };
}

export default connect(mapStateToProps, { getCategory })(withRouter(StudyClass));