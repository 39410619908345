import React, { Component } from 'react';
import Chart from 'react-apexcharts';

const optionChart = {
    chart: {
        type: 'radar',
    },
    fill: {
        opacity: 0.6
    },
    markers: {
        size: 0
    },
    legend:{
        labels: {
            colors: '#fff'
        }
    },
    xaxis: {
        categories: ['L.đạo', 'Q.trị', 'Q.hệ', 'T.chính', 'C.môn', 'N.ngữ'],
        labels: {
            style: {
                colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
            }
        }
    }
}

class PointProperty extends Component{
    render(){
        const {userProperty, chartData} = this.props;
        return(
            <>
                <div className='brand-group-container mgt'>
                    <p className='text-large'>Điểm thuộc tính cá nhân</p>
                    <div className='row mgt-2'>
                        <div className='col-md-4 col-sm-4 col-xs-6 brand-group-container'>
                            <p>Lãnh đạo: {userProperty.LeaderPoint}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 brand-group-container'>
                            <p>Quản trị: {userProperty.ManagePoint}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 brand-group-container'>
                            <p>Quan hệ: {userProperty.RelationPoint}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 brand-group-container'>
                            <p>Tài chính: {userProperty.FundPoint}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 brand-group-container'>
                            <p>Chuyên môn: {userProperty.ExpertisePoint}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 brand-group-container'>
                            <p>Ngoại ngữ: {userProperty.LanguagePoint}</p>
                        </div>
                    </div>
                </div>
                <Chart
                    options={optionChart}
                    series={chartData}
                    type="radar" 
                    height={400} 
                />
            </>
        )
    }
}

export default PointProperty