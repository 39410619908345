import React from 'react';
import {URI_PATH} from '../../utils/constants';
import {getAvatarFb, priceFormat} from '../../utils/common';
import ClubVipLevel from '../club/ClubVipLevel';
import ClubDeputy from '../club/ClubDeputy';
import Checked from '../frame/Checked';

const fontSizeRank = (rank) =>{
    if(0 < rank && rank <= 9) return '6vh';
    else if(9 < rank && rank <= 99) return '5vh';
    else if(100 < rank && rank <= 999) return '4vh';
    else return '20';
}

export default (props) =>(
    <div>
        {props.dataRank.map((item, key) =>
            <div key={key} className='rank-container item-center'>
                <div className='col-rank-stt'>
                    <div>
                        <p style={{fontSize: fontSizeRank(item.Rank)}}>{key + 1}</p>
                    </div>
                    <div>
                        <img
                            style={{width: '2vh', position: 'relative', bottom: '2vh'}}
                            src={require('../../assets/image/rank.png')}
                            alt='img-rank'
                        />
                    </div>
                </div>
                <div className='col-rank-avatar'>
                    <div>
                        <img className={'avatar-rank'} alt='avatar-user' src={getAvatarFb(item.FacebookId)} />
                        {item.AssetImage && (
                            <img className='avatar-asset-rank' alt='asset-user' src={`${URI_PATH}${item.AssetImage}`} />
                        )}
                    </div>
                </div>
                <div className='col-rank-name'>
                    <div className='row item-center'>
                        <ClubDeputy clubRoleId={item.ClubRoleId} />
                        <p>{item.ClubName}</p>
                    </div>
                    <div className='level-rank'>
						<ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
					</div>
                    <p className='bold'>{item.FullName}<Checked data={item} /></p>
                    <p>{item.ClubRank}</p>
                </div>
                <div className='col-rank-info'>
                    <div className='row-info-rank'>
                        <img className='img-info-rank' src={require('../../assets/image/vip.png')} alt='vip-level' />
                        <span className='info-rank'>{item.UserVip}</span>
                    </div>
                    <div className='row-info-rank'>
                        <img className='img-info-rank' src={require('../../assets/image/like.png')} alt='like-level' />
                        <span className='info-rank'>{item.UserLike}</span>
                    </div>
                    <div className='row-info-rank'>
                        <img className='img-info-rank' src={require('../../assets/image/achievement.png')} alt='achievement-level' />
                        <span className='info-rank'>{item.Achievement}</span>
                    </div>
                    <div className='row-info-rank'>
                        <img className='img-info-rank' src={require('../../assets/image/money.png')} alt='achievement-level' />
                        <span className='info-rank'>{priceFormat(item.Balance)}</span>
                    </div>
                </div>
            </div>
        )}
    </div>
)