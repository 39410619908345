import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListPostApi = (num, categoryId, subCategoryId, searchUser, postType, directPost) =>{
    let url = `${URI_PATH}app/post/action/getListPost`;
    let body = {
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        SearchUser: searchUser,
        PostType: postType,
        Num: num,
        DirectPost: directPost
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListCommentApi = (postId, pageId = 1) =>{
    let url = `${URI_PATH}app/post/action/getComment`;
    let body = {
        PostId: postId,
        PageId: pageId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const commentPostApi = (postId, comment) =>{
    let url = `${URI_PATH}app/post/postcomment/update`;
    let body = {
        PostId: postId,
        Comment: comment
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const diamondPostApi = (postId, userPost) =>{
    let url = `${URI_PATH}app/post/action/diamond`;
    let body = {
        PostIdDiamond: postId,
        UserPost: userPost
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkActionApi = (postId, isRead, isFavorite) =>{
    let url = `${URI_PATH}app/post/postcomment/checkAciton`;
    let body = {
        PostId: postId,
        IsRead: isRead,
        IsFavorite: isFavorite
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getRankPostApi = (postId, userPost, categoryId, subCategoryId) =>{
    let url = `${URI_PATH}app/post/action/getRankPost`;
    let body = {
        PostId: postId,
        UserPost: userPost,
        CategoryId: categoryId,
        SubCategoryId: subCategoryId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const donatePostApi = (postId, balance) =>{
    let url = `${URI_PATH}app/post/action/donate`;
    let body = {
        PostId: postId,
        Balance: balance
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListBonusApi = (type, page) =>{
    let url = `${URI_PATH}app/post/action/getListBonus`;
    let body = {
        Type: type,
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getPostSearchApi = (PostId) =>{
    let url = `${URI_PATH}app/post/action/getPostSearch`;
    let body = {
        PostId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAllPostApi = (PageId, OrderType, CategoryId, SubCategoryId, SearchUser, DirectId) =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeedByType`;
    let body = {
        Type: 11,
        OrderType,
        PageId,
        CategoryId,
        SubCategoryId,
        SearchUser,
        DirectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}