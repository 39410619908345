import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {getListApi, buyApi} from '../../api/charge/assetmarket';
import {MESSAGE, URI_PATH, PROP} from '../../utils/constants';
import {formatDecimal} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import assetmarket from '../../assets/lottie/assetmarket.json';
import HeaderLottie from '../../components/HeaderLottie';
import Icon from 'react-fontawesome';
import { Button, Tooltip, Modal } from 'antd';
import {showModalEat} from '../../actions/home/modalEat';

class ChargeAssetMarket extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            listAsset: [],
            showModal: false,
            itemInfo: {},
            amountBuy: '',
            isPress: false,
            calculateBalance: ''
        };
    }

    getList = () =>{
        getListApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listAsset: res.listAsset
                });
            }
            else showNotification(MESSAGE.ERROR, 0)
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    hideModal = () =>{
        this.setState({
            isPressBuy: false,
            amountBuy: '',
            calculateBalance: '',
            balanceSelect: '',
            balanceNameSelect: '',
            selectAsset: false,
            showModal: false
        })
    }

    buy = () =>{
        if(!this.state.isPress){
            let idNotification = '#notificationModalMarket';
            let assetId = this.state.itemInfo.AssetId;
            let amountBuy = this.state.amountBuy;
            if(amountBuy == '' || amountBuy <= 0) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            buyApi(assetId, amountBuy).then(res =>{
                if(res.code == 1){
                    showNotificationBonus(res.balance, -1, 2);
                    showNotificationBonus(res.balanceZ, -1, 3);
                    this.props.changePoint([null, -res.balance, -res.balanceZ]);
                    this.setState({
                        showModal: false,
                        isPress: false,
                        amountBuy: '',
                        calculateBalance: ''
                    });
                    showNotification(res.message, res.code);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    changeAmount = e =>{
        let amountBuy = e.target.value.replace(/,|\./g, '');
        let balance = this.state.itemInfo.Balance > 0 ? `${formatDecimal(`${amountBuy * this.state.itemInfo.Balance}`)} $[z]` : '';
        let balanceZ = this.state.itemInfo.Balance > 0 ? `${formatDecimal(`${amountBuy * this.state.itemInfo.BalanceZ}`)} đ[z]` : '';
        this.setState({
            amountBuy: amountBuy,
            calculateBalance:  amountBuy > 0 ? `${balance} và ${balanceZ}` : '',
        });
    }

    closeModal = () =>{
        this.setState({
            showModal: false,
            calculateBalance: '',
            amountBuy: '',
            isPress: false
        })
    }

    componentDidMount(){
        this.getList();
    }

    render(){
        let {isLoading, listAsset, showModal, itemInfo, amountBuy, isPress, calculateBalance} = this.state;
        if(isLoading) return (<Loading />);
        return(
            <div>
                <HeaderLottie source={assetmarket} disableLoop />
                <div className="row mgt-2">
                        {listAsset.map((item, key) =>
                            <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                                <div>
                                    <Button
                                        className='popover-item bg-success'
                                        onClick={() => this.setState({ showModal: true, itemInfo: item })}
                                    >
                                        Mua
                                    </Button>
                                </div>
                            }>
                                <div className="col-md-4 col-sm-6 col-xs-6 item-block item-center" key={key}>
                                    <div className='flex-column'>
                                        <div className='item-center'>
                                            <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                                        </div>
                                        <p>{item.AssetName}</p>
                                        <p>{formatDecimal(item.Balance)} $[z] & {formatDecimal(item.BalanceZ)} đ[z]</p>
                                    </div>
                                </div>
                            </Tooltip>
                        )}
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={this.closeModal}
                >
                    <div>
                        <div className="row item-center flex-column">
                            <p className='success bold text-center text-huge'>Mua</p>
                            <p className='success text-center text-large mgt'>{itemInfo.AssetName}</p>
                            <img className='item-img mgt' alt={itemInfo.AssetName} src={`${URI_PATH}${itemInfo.AssetImage}`} />
                            {showModal &&
                                <p className='success bold text-center text-large mgt'>{formatDecimal(itemInfo.Balance)} $[z] & {formatDecimal(itemInfo.BalanceZ)} đ[z]</p>
                            }
                            <div className="row item-center">
                                <div className='col-md-12'>
                                    <p className='success text-center text-large mgt'>Số lượng</p>
                                    <input
                                        className='form-control border-success text-center mgt'
                                        value={`${amountBuy}`}
                                        placeholder="Nhập số lượng"
                                        onChange={this.changeAmount}
                                        onKeyDown={e => e.key === 'Enter' && this.buy()}
                                    />
                                </div>
                            </div>
                            {calculateBalance ? 
                                <p className='danger text-center text-large bold mgt-2'>Hệ thống sẽ trừ: {calculateBalance}</p>
                            : null }
                            <Button
                                className='bg-success mgt-2'
                                loading={isPress}
                                onClick={this.buy}
                            >
                                Xác nhận
                            </Button>
                            <div className='mgt' id='notificationModalMarket'></div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps, {showModalEat, changePoint})(ChargeAssetMarket);