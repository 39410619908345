import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import {getUserPrestigeApi, getUserItemApi} from '../../api/home';
import {getGuestDataApi, eatFoodPartyApi} from '../../api/home/guest';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import {changePoint, getUserItem} from '../../actions/home/';
import { MESSAGE, URI_PATH, PROP } from '../../utils/constants';
import { getAvatarFb, formatDecimal, getDataNotify } from '../../utils/common';
import ClubVipLevel from '../../components/club/ClubVipLevel';
import ClubDeputy from '../../components/club/ClubDeputy';
import {Button, Tooltip} from 'antd';
import Countdown, { zeroPad } from 'react-countdown-now';
import iziToast from 'izitoast';
import {socket} from '../../components/realtime/ReadSocketEvent';
import ListNewfeed from '../../components/newfeed';
import {getListNewfeedUser, clearNewfeedData} from '../../actions/newfeed';
import {TitleSuccess} from '../../components/frame';
import ListVestige from '../../components/home/ListVestige';
import BarSelect from '../../components/frame/BarSelect';
import Icon from 'react-fontawesome';
import {
    getListChatPeople,
    showHideChat
} from '../../actions/chat';
import TooltipUser from '../../components/TooltipUser';
const ButtonGroup = Button.Group;

class Guest extends Component {
    constructor(props){
        super(props);
        this.state = {
            guestData: {},
            houseUse: {},
            crownUse: {},
            listFoodParty: [],
            selectItem: 0,
            dataItem0: [],
            dataItem1: [],
            dataItem2: [],
            dataItem3: [],
            dataItem4: [],
            dataItem5: [],
            loadingItem0: true,
            loadingItem1: true,
            loadingItem2: true,
            loadingItem3: true,
            loadingItem4: true,
            loadingItem5: true,
            balanceAll: 0,
            lifeExpire: '',
            userVestige: {},
            isLoadingUserVestige: true,
            isLoading: true,
            isPress: false
        }
        this.typeNavigate = 1;
        this.guestId = queryString.parse(this.props.location.search).id;
    }

    showMessageParty = (facebookId, message) =>{
        iziToast.show({
            timeout: 5000,
            message: message,
            backgroundColor: '#4b5056',
            image: getAvatarFb(facebookId),
            imageWidth: 50,
            target: '#notificationGoParty',
            messageColor: 'white',
            progressBar: false,
        });
    }

    getGuestData = guestId =>{
        getGuestDataApi(guestId).then(res =>{
            if(res.code == 1){
                this.props.getListNewfeedUser(1, this.guestId);
                let {guestData, houseUse, crownUse, listCrest, listFoodParty} = res;
                this.setState({
                    guestData,
                    houseUse,
                    crownUse,
                    listCrest,
                    listFoodParty,
                    isLoading: false
                });
                if(res.listFoodParty && res.listFoodParty.length > 0){ // notification tiệc
                    this.showMessageParty(guestData.FacebookId, 'Chào mừng bạn ghé thăm nhà tôi, mời bạn dùng bữa.');
                }
            }
            else showNotification(res.message, res.code);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getUserItem = (selectItem, guestId) =>{
        getUserItemApi(selectItem, guestId).then(res =>{
            if(res.code == 1){
                switch (parseInt(selectItem)) {
                    case 0: //food
                        this.setState({
                            selectItem: selectItem,
                            dataItem0: res.listItem,
                            lifeExpire: res.lifeExpire,
                            loadingItem0: false
                        });
                        break;
                    case 1: //asset
                        this.setState({
                            selectItem: selectItem,
                            dataItem1: res.listItem,
                            loadingItem1: false
                        });
                        break;
                    case 2: // cổ phiếu đ[z]
                        this.setState({
                            selectItem: selectItem,
                            dataItem2: res.listItem,
                            balanceAll: res.balanceAll,
                            loadingItem2: false
                        });
                        break;
                    case 3: // đồ thiết yếu
                        this.setState({
                            selectItem: selectItem,
                            dataItem3: res.listItem,
                            balanceAll: res.balanceAll,
                            loadingItem3: false
                        });
                        break;
                    case 4: // đồ cao cấp
                        this.setState({
                            selectItem: selectItem,
                            dataItem4: res.listItem,
                            balanceAll: res.balanceAll,
                            loadingItem4: false
                        });
                        break;
                    case 5: // cổ phiếu $[z]
                    this.setState({
                        selectItem: selectItem,
                        dataItem5: res.listItem,
                        balanceAll: res.balanceAll,
                        loadingItem5: false
                    });
                    break;
                }
            }
            else showNotification(res.message, res.code);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getUserVestige = () =>{
        getUserPrestigeApi(this.guestId).then(res =>{
            if(res.code == 1){
                this.setState({
                    userVestige: res.userVestige,
                    isLoadingUserVestige: false
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem == this.state.selectItem || (this.state.selectItem == 5 && selectItem == 2)) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    buttonGroupClassMain = (selectItem) =>{
        if(selectItem == this.typeNavigate) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    renderUserInfo = () =>{
        let {guestData, houseUse, crownUse, listCrest} = this.state;
        return(
            <div>
                <div className='row'>
                    <div className='col-xs-3 flex-column item-center'>
                        <TooltipUser
                            userInfo={guestData}
                            avatarClass='avatar-guest-huge'
                        />
                        <div className='flex mgt'>
                            <div
                                className='row-call-user'
                            >
                                <a href={`tel:${guestData.PhoneNumber}`}>
                                    <Icon name='phone' style={{fontSize: '3vh'}} />
                                </a>
                            </div>
                            <div
                                className='row-call-user'
                                onClick={() =>{
                                    this.props.getListChatPeople(guestData.UserId);
                                    this.props.showHideChat(true, false, {
                                        UserId: guestData.UserId,
                                        FacebookId: guestData.FacebookId,
                                        FullName: guestData.FullName,
                                    });
                                }}
                            >
                                <Icon name='comments' style={{fontSize: '3vh'}} />
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-6 flex-column item-center'>
                        <div className='flex-row item-center'>
                            <ClubDeputy clubRoleId={guestData.ClubRoleId} />
                            <p className='text-large mgt'>{guestData.ClubName}</p>
                        </div>
                        <div className='level-rank mgt'>
                            <ClubVipLevel className='star-rank' vipLevel={guestData.ClubVipLevel} />
                        </div>
                        <p className='text-large mgt'>{guestData.FullName}</p>
                        <p className='text-large'>{guestData.ClubRoleRank}</p>
                    </div>
                    <div className='col-xs-3 item-center'>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../assets/image/vip.png')} alt='vip-level' />
                            <span className='info-rank'>{guestData.UserVip}</span>
                        </div>
                        {/* <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../assets/image/follow-on.png')} alt='achievement-level' />
                            <span className='info-rank'>{guestData.CountFollow}</span>
                        </div> */}
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../assets/image/rank.png')} alt='rank' />
                            <span className='info-rank'>{guestData.Rank}</span>
                        </div>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../assets/image/like.png')} alt='like-level' />
                            <span className='info-rank'>{guestData.UserLike}</span>
                        </div>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../assets/image/achievement.png')} alt='achievement-level' />
                            <span className='info-rank'>{guestData.Achievement}</span>
                        </div>
                    </div>
                </div>
                <div className='row mgt'>
                    <div className='col-xs-4 bar-cost'>{formatDecimal(guestData.BalanceZ)} đ[z]</div>
                    <div className='col-xs-4 bar-cost'>{formatDecimal(guestData.Balance)} $[z]</div>
                    <div className='col-xs-4 bar-cost'>{parseFloat(guestData.TicketCount)} Gold[z]</div>
                </div>
                <div className="row">
                    <div className='col-xs-12 col-sm-12 col-md-12 item-center'>
                        <div className='user-bar-item bg-primary'>
                            <p className='silver'>Tâm trạng của {guestData.FullName}: 😇</p>
                            <div
                                className='text-center input-status'
                            >
                                <p>{guestData.Status}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {houseUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{houseUse.AssetName}</p>
                                            <p>Tăng {houseUse.AssetBonus} % thành tựu</p>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Nhà' src={`${URI_PATH}${houseUse.AssetImage}`}/>
                                    <p>{houseUse.AssetName}</p>
                                </Tooltip>
                            :
                                <p className='silver'>Nhà</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {crownUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{crownUse.AssetName}</p>
                                            <p>Tăng {crownUse.AssetBonus} % thành tựu</p>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Vương miện' src={`${URI_PATH}${crownUse.AssetImage}`}/>
                                    <p>{crownUse.AssetName}</p>
                                </Tooltip>
                            :
                                <p className='silver'>Vương miện</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {listCrest.length > 0 ?
                                <div className='relative'>
                                    <img className='bonus-crest-img' src={require('../../assets/image/crest.png')} />
                                    <p>Huy chương Z {`${(listCrest.length > 1 ? ('x' + listCrest.length) : "")}`}</p>
                                </div>
                            :
                                <p className='silver'>Huy chương Z</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFoodParty = () =>{
        let {listFoodParty} = this.state;
        if(listFoodParty && listFoodParty.length > 0){
            return(
                <div>
                    <TitleSuccess>Tiệc mời ăn</TitleSuccess>
                    <div className="row">
                        {listFoodParty.map((item, key) =>
                            <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                                <div>
                                    <Button
                                        className='popover-item bg-success'
                                        onClick={() => this.eatFoodParty(item)}
                                    >
                                        Dùng ngay
                                    </Button>
                                </div>
                            }>
                                <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                    <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                    <p>{item.Amount}</p>
                                    <p>{item.FoodName}</p>
                                    <p>{item.Hour} giờ</p>
                                    <div id={'notificationParty' + item.UserfoodpartyId}></div>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            )
        }
        return null;
    }

    renderListItem = () =>{
        let {
            selectItem,
            loadingItem0,
            loadingItem1,
            loadingItem2,
            loadingItem3,
            loadingItem4,
            loadingItem5,
            lifeExpire,
            dataItem0,
            dataItem1,
            dataItem2,
            dataItem3,
            dataItem4,
            dataItem5,
            balanceAll,
        } = this.state;
        if(
            selectItem == 0 && loadingItem0 == true ||
            selectItem == 1 && loadingItem1 == true ||
            selectItem == 2 && loadingItem2 == true ||
            selectItem == 3 && loadingItem3 == true ||
            selectItem == 4 && loadingItem4 == true ||
            selectItem == 5 && loadingItem5 == true
        ) return <Loading small />;
        if (selectItem == 0) {
            return (
                <div>
                    {lifeExpire &&(
                        <div className="row mgt item-center">
                            <div className='div-life'>
                                <img src={require('../../assets/image/food.png')} className='enegy-life-icon' />
                                <Countdown 
                                    date={lifeExpire * 1000}
                                    renderer={({ days, hours, minutes, seconds}) =>(<span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                />
                            </div>
                        </div>
                    )}
                    {dataItem0 && dataItem0.length > 0 && (
                        <div className="row mgt">
                            {dataItem0.map((item, key) =>
                                <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center" key={key}>
                                    <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                    <p className='mgt'>{item.Amount}</p>
                                    <p>{item.FoodName}</p>
                                    <p>{item.Hour} giờ</p>
                                    <div id={`notificationEat${item.UserfoodId}`}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
        else if (selectItem == 1) {
            return (
                <div>
                    {dataItem1 && dataItem1.length > 0 && (
                        <div className="row mgt">
                            {dataItem1.map((item, key) =>
                                <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center" key={key}>
                                    <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                                    <p className='mgt'>{item.Amount}</p>
                                    <p>{item.AssetName}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
        else if (selectItem == 2 || selectItem == 5) {
            let listItem = selectItem == 2 ? dataItem2 : dataItem5;
            let balanceName = selectItem == 2 ? 'đ[z]' : '$[z]';
            return (
                <>
                    <div className='item-center'>
                        <select
                            className='form-control col-md-4 col-sm-4 col-xs-6 mgt'
                            onChange={e => this.getUserItem(e.target.value, this.guestId)}
                            value={selectItem}
                        >
                            <option value='2'>Chứng khoán đ[z]</option>
                            <option value='5'>Chứng khoán $[z]</option>
                        </select>
                    </div>
                    {balanceAll > 0 && (
                        <div className="row mgt item-center">
                            <div className='div-balance-all bg-success'>
                                <p>{formatDecimal(balanceAll)} {balanceName}</p>
                            </div>
                        </div>
                    )}
                    {listItem && listItem.length > 0 && (
                        <div className="row mgt">
                            {listItem.map((item, key) =>
                                <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center" key={key}>
                                    <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                    <p className='mgt'>{item.Amount}</p>
                                    <p>{item.ItemName}</p>
                                    <p className={item.PercentColor == 1 ? 'success' : 'danger'}>{item.PercentBonusNew} %</p>
                                    <p className={item.BalanceColor == 1 ? 'success' : 'danger'}>{item.BalanceChange} {balanceName}</p>
                                    <div id={`notificationInvest${item.ChargemarketId}`}/>
                                </div>
                            )}
                        </div>
                    )}
                </>
            );
        }
        else if (selectItem == 3) {
            return (
                <div>
                    {balanceAll > 0 && (
                        <div className="row mgt item-center">
                            <div className='div-balance-all bg-success'>
                                <p>+{balanceAll} thành tựu / ngày</p>
                            </div>
                        </div>
                    )}
                    {dataItem3 && dataItem3.length > 0 && (
                        <div className="row mgt">
                            {dataItem3.map((item, key) =>
                                <div key={key} className="col-md-3 col-sm-4 col-xs-6 item-block item-center">
                                    <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                    <p className='mgt'>{item.ItemName}</p>
                                    <p>{item.DayRemain} ngày</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
        else if (selectItem == 4) {
            return (
                <div>
                    {balanceAll > 0 && (
                        <div className="row mgt item-center">
                            <div className='div-balance-all bg-success'>
                                <p>{formatDecimal(balanceAll)} đ[z]</p>
                                <p>+{Math.round(balanceAll / (1000000 + Math.floor(balanceAll / 1000000000) * 100000) * 100) / 100} thành tựu / ngày</p>
                            </div>
                        </div>
                    )}
                    {dataItem4 && dataItem4.length > 0 && (
                        <div className="row mgt">
                            {dataItem4.map((item, key) =>
                                <div className="col-md-3 col-sm-4 col-xs-6 item-block item-center" key={key}>
                                    <img className='item-img mgb' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                    <p>{item.ItemName}</p>
                                    <p>{item.BalanceReal} đ[z]</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    }

    eatFoodParty = dataParty =>{
        let {isPress, guestData, listFoodParty} = this.state;
        if(!isPress){
            let idNotification = '#notificationParty' + dataParty.UserfoodpartyId;
            eatFoodPartyApi(dataParty.UserfoodpartyId).then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code, idNotification);
                    showNotificationBonus(dataParty.Hour, 1, 5);
                    this.props.changePoint([null, null, null, null, dataParty.Hour]);
                    this.showMessageParty(guestData.FacebookId, 'Chúc bạn ngon miệng 😅');
                    this.setState({
                        isPress: false,
                        listFoodParty: listFoodParty.map(el => {
                            if(el.UserfoodpartyId == dataParty.UserfoodpartyId) return Object.assign({}, el, {
                                Amount: parseInt(el.Amount) - 1
                            })
                            return el;
                        })
                    });
                    //socket
                    let dataUser = this.props.dataUser;
					let datas = getDataNotify(guestData.UserId, 0, 0, 0, 0, 0, 0);
					datas.message = {
						fullName: dataUser.FullName,
						facebookId: dataUser.FacebookId,
						message: 'ghé thăm nhà bạn và dùng bữa với món ' + dataParty.FoodName
					}
					socket.emit('send_notify', datas);
                    this.props.getUserItem(0);
                    //
                }
                else{
                    if(res.code == 2) this.showMessageParty(guestData.FacebookId, res.message);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    selectNavigate = type =>{
        if(this.typeNavigate != type){
            this.typeNavigate = type;
            if(type == 2 && this.state.loadingItem0){ // check chưa load list item home
                this.getUserItem(0, this.guestId);
            }
            // else if(type == 3 && this.state.isLoadingUserVestige){
            //     this.getUserVestige();
            // }
            else this.setState({});
            window.scrollTo({ top: 300, behavior: 'smooth' });
        }
    }

    renderMainNavigate = ()=>{
        switch (this.typeNavigate) {
            case 1:
                return(
                    <>
                        <TitleSuccess>Hoạt động gần đây</TitleSuccess>
                        <ListNewfeed userId={this.guestId} />
                    </>
                )
            case 2:
                return(
                    <>
                        <ButtonGroup className='item-center mgt flex' style={{width: '100%'}}>
                            <Button className={this.buttonGroupClass(0)} onClick={() => this.getUserItem(0, this.guestId)}>Nhà bếp</Button>
                            <Button className={this.buttonGroupClass(1)} onClick={() => this.getUserItem(1, this.guestId)}>Nhà kho</Button>
                            <Button className={this.buttonGroupClass(2)} onClick={() => this.getUserItem(2, this.guestId)}>Két sắt</Button>
                            <Button className={this.buttonGroupClass(3)} onClick={() => this.getUserItem(3, this.guestId)}>Phòng ngủ</Button>
                            <Button className={this.buttonGroupClass(4)} onClick={() => this.getUserItem(4, this.guestId)}>Phòng khách</Button>
                        </ButtonGroup>
                        {this.renderListItem()}
                    </>
                )
            case 3:
                let {userVestige, isLoadingUserVestige} = this.state;
                return(
                    <ListVestige userId={this.guestId} />
                )
        }
    }

    componentDidMount(){
        let dataUser = this.props.dataUser;
        if(this.guestId > 0 && dataUser.UserId != this.guestId) this.getGuestData(this.guestId);
        else if(dataUser.UserId == this.guestId) this.props.history.push('/');
        else this.props.history.push('/menu');
    }

    componentWillUnmount(){
        this.props.clearNewfeedData();
    }

    render(){
        let {isLoading} = this.state;
        if(isLoading) return(<Loading />);
        return(
            <div>
                {this.renderUserInfo()}
                <div id='notificationGoParty' />
                <ButtonGroup className='item-center mgt flex'>
                    <Button className={this.buttonGroupClassMain(1)} onClick={() => this.selectNavigate(1)}>Hoạt động</Button>
                    <Button className={this.buttonGroupClassMain(2)} onClick={() => this.selectNavigate(2)}>Tài sản</Button>
                    <Button className={this.buttonGroupClassMain(3)} onClick={() => this.selectNavigate(3)}>Thành tích</Button>
                </ButtonGroup>
                {this.renderFoodParty()}
                {this.renderMainNavigate()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {
    getListNewfeedUser,
    clearNewfeedData,
    changePoint,
    getListChatPeople,
    showHideChat,
    getUserItem
})(withRouter(Guest));