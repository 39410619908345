import React, { Component } from 'react';
import { Modal, Button, Switch } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../../../components/Loading';
import { getAvatarFb } from '../../../utils/common';
import Checked from '../../frame/Checked';
import { confirmOrderApi, getListOrderApi } from '../../../api/admin/study/ticket';
import { TitleSuccess } from '../../../components/frame';

class TicketConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            listOrder: [],
            isLoading: true,
            pageId: 1,
            endList: false,
            status: 0,
            inputSearch: '',
            selectOrder: {}
        }
        this.timeoutSearch = ''
    }

    getListOrder = (pageId = 1) => {
        const { status, inputSearch } = this.state;
        getListOrderApi(pageId, inputSearch, status).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listOrder: pageId == 1 ? res.data : [...this.state.listOrder, ...res.data],
                    endList: res.data.length < 20,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    changeInputSearch = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.timeoutSearch = setTimeout(() => {
            this.getListOrder(1);
        }, 500);
    }

    changeStatus = (e) => {
        this.setState({ status: e.target.value }, this.getListOrder);
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListOrder(this.state.pageId + 1);
        }
    }

    confirmOrder = () =>{
        if(!this.state.isPress){
            const {selectOrder, listOrder} = this.state;
            this.setState({isPress: true});
            confirmOrderApi(selectOrder.OrderTicketId).then(res =>{
                showNotification(res.message, res.code);
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        listOrder: listOrder.map(item =>{
                            if(item.OrderTicketId == selectOrder.OrderTicketId){
                                return {
                                    ...item,
                                    Status: "1"
                                }
                            }
                            return item
                        }),
                        showModal: false
                    })
                }
                else {
                    this.setState({isPress: false})
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false})
            })
        }
    }

    renderButton = (data) => {
        switch (data.Status) {
            case "0":
                return (
                    <Button
                        className='button-confirm-order-ticket bg-warning'
                        onClick={() => this.setState({
                            selectOrder: data,
                            showModal: true
                        })}
                    >
                        Duyệt
                    </Button>
                )
            case "1":
                return (
                    <Button
                        className='button-confirm-order-ticket bg-success'
                    >
                        Thành công
                    </Button>
                )
        }
    }

    componentDidMount() {
        this.getListOrder()
    }

    render() {
        const { listOrder, isLoading, status, inputSearch, endList, showModal, selectOrder, isPress } = this.state;
        return (
            <>
                <div className='row'>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <input
                            type='text'
                            className='form-control'
                            value={inputSearch}
                            onChange={this.changeInputSearch}
                            placeholder='Nhập họ tên hoặc số điện thoại'
                            style={{ fontSize: '2vh' }}
                        />
                    </div>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <select
                            className='form-control'
                            onChange={this.changeStatus}
                            value={status}
                        >
                            <option value="0">Đang chờ duyệt</option>
                            <option value="1">Đã duyệt</option>
                            <option value="2">Quá 24h</option>
                            <option value="3">Tất cả các lệnh</option>
                        </select>
                    </div>
                </div>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                            className='mgt-2'
                        >
                            {listOrder.map((item, key) =>
                                <div className='row box-primary relative' key={key}>
                                    <div className='col-md-4 col-sm-4 col-xs-12 flex align-center'>
                                        <img className='avatar-guest-small mgr' src={getAvatarFb(item.FacebookId)} />
                                        <div>
                                            <p className='mgb-0'>{item.FullName}<Checked data={item} /> - {item.PhoneNumber}</p>
                                            <p className='mgb-0 text-time'>Online: {item.UpdateDateTime}</p>
                                            <div className='flex align-center'>
                                                <p className='mgb-0 text-time'>Khởi tạo: {item.CrDateTime}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12 flex-column'>
                                        <p>Thời gian: {item.CreateDateTime}</p>
                                        <p>Mã đơn hàng: {item.Code}</p>
                                        <p>Mã lệnh đặt mua: {parseFloat(item.Ticket)} Gold[z]</p>
                                        <p>Thành tiền: {item.Balance} vnđ</p>
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12 flex align-center'>
                                        {this.renderButton(item)}
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    <TitleSuccess>Xác nhận</TitleSuccess>
                    <div className='item-center mgt mgb'>
                        <img className='avatar-guest-small mgr' src={getAvatarFb(selectOrder.FacebookId)} />
                        <p>{selectOrder.FullName}</p>
                    </div>
                    <p>Thời gian: {selectOrder.CreateDateTime}</p>
                    <p>Mã đơn hàng: {selectOrder.Code}</p>
                    <p>Mã lệnh đặt mua: {parseFloat(selectOrder.Ticket)} Gold[z]</p>
                    <p>Thành tiền: {selectOrder.Balance} vnđ</p>
                    <div className='item-center mgt-3'>
                        <Button
                            className='bg-success'
                            onClick={this.confirmOrder}
                            loading={isPress}
                        >
                            Duyệt
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default TicketConfirm