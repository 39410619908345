import React, { Component } from 'react';
import {addFoodToCartApi, addAssetToCartApi} from '../../api/charge/sell';
import {formatDecimal, replaceCost, getDecimalCount} from '../../utils/common';
import {URI_PATH, MESSAGE, TICKET, PROP} from '../../utils/constants';
import{ getUserItem, changePoint } from '../../actions/home/';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import {showNotification, showNotificationCenter} from '../../utils/notification';

class ShowModalAddItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            inputAmount: '',
            inputBalanceEach: '',
            isPress: false,
            balanceType: 3,
            balanceName: 'đ[z]',
        }
    }

    addFoodToCart = (userfoodId, amount, balance) =>{
        if(!this.state.isPress){
            let idNotification = '#notificationModalAddItem';
            let balanceType = this.state.balanceType;
            if(balance && balanceType == 1) balance = replaceCost(balance, false);
            else if (balance) balance = replaceCost(balance, true);
            else balance = 0;
            if(amount == '' || amount < 1) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            if(balance == '' || balance <= 0) return showNotification('Giá 1 sản phẩm phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            addFoodToCartApi(userfoodId, amount, balance, this.state.balanceType).then(res => {
                if(res.code == 1) {
                    this.props.getUserItem(0);  
                    this.props.hideModal();
                    showNotificationCenter(res.message, res.code);
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    addAssetToCart = (userassetId, amount, balance) =>{
        if(!this.state.isPress){
            let idNotification = '#notificationModalAddItem';
            let balanceType = this.state.balanceType;
            if(balance && balanceType == 1) balance = replaceCost(balance, false);
            else if (balance) balance = replaceCost(balance, true);
            else balance = 0;
            if(amount == '' || amount < 1) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            if(balance == '' || balance <= 0) return showNotification('Giá 1 sản phẩm phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            addAssetToCartApi(userassetId, amount, balance, this.state.balanceType).then(res => {
                if(res.code == 1) {
                    this.props.getUserItem(1);
                    this.props.hideModal();
                    showNotificationCenter(res.message, res.code);
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    balanceType = [
        { key: 1, label: 'Gold[z]' },
        { key: 2, label: '$[z]' },
        { key: 3, label: 'đ[z]' }
    ];

    changeBalanceType = e =>{
        this.setState({
            balanceType: e.target.value,
            inputBalanceEach: ''
        })
    }

    changeInputBalance = e =>{
        let value = e.target.value?.replace(/,|\./g, '');
        if(this.state.balanceType == 1 && getDecimalCount(value) > TICKET.DECIMAL_COUNT){
            this.setState({inputBalanceEach: ''});
            return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', 0, '#notificationModalSell');
        }
        this.setState({inputBalanceEach : formatDecimal(value),})
    }

    render() {
        let {modalType, itemInfo} = this.props;
        let {inputAmount, inputBalanceEach, balanceType, isPress} = this.state;
        return(
            <Modal
                {...PROP.MODAL}
                visible={this.props.isVisible}
                onCancel={this.props.hideModal}
                afterClose={() => this.setState({isPress: false, inputAmount: '', inputBalanceEach: ''})}
            >
                <div>
                    {modalType == 1 ? 
                        <div className="row item-center flex-column">
                            <img className='item-img' alt={itemInfo.FoodName} src={`${URI_PATH}${itemInfo.FoodImage}`} />
                            <p>{itemInfo.Amount}</p>
                            <p>{itemInfo.FoodName}</p>
                            <p>{itemInfo.Hour} giờ</p>
                        </div>
                    :
                        <div className="row item-center flex-column">
                            <img className='item-img' alt={itemInfo.AssetName} src={`${URI_PATH}${itemInfo.AssetImage}`} />
                            <p>{itemInfo.Amount}</p>
                            <p>{itemInfo.AssetName}</p>
                            <p>{itemInfo.Hour} giờ</p>
                        </div>
                    }
                    <div className="row flex-column mgt">
                        <p className='warning bold text-center text-large'>Số lượng</p>
                        <input
                            className='form-control border-warning text-center mgt'
                            placeholder='Nhập số lượng'
                            value={inputAmount}
                            onChange={e => this.setState({inputAmount: e.target.value?.replace(/,|\./g, '')})}
                        />
                        <div>
                            <p className='warning bold text-center mgt text-large'>Loại tiền</p>
                            <select
                                className='form-control border-warning mgt'
                                defaultValue={balanceType}
                                onChange={this.changeBalanceType}
                            >
                                <option value="1">Gold[z]</option>
                                <option value="2">$[z]</option>
                                <option value="3">đ[z]</option>
                            </select>
                            <p className='warning bold text-center mgt text-large'>Giá tiền 1 sản phẩm</p>
                            <input
                                className='form-control border-warning text-center mgt'
                                placeholder='Nhập giá 1 sản phẩm'
                                value={inputBalanceEach}
                                onChange={this.changeInputBalance}
                            />
                        </div>
                        <Button
                            className='bg-warning mgt-2'
                            loading={isPress}
                            onClick={() =>{
                                if(modalType == 1) this.addFoodToCart(itemInfo.UserfoodId, inputAmount, inputBalanceEach);
                                else if(modalType == 2)this.addAssetToCart(itemInfo.UserassetId, inputAmount, inputBalanceEach);
                            }}
                        >
                            Thêm vào giỏ
                        </Button>
                    </div>
                    <div id='notificationModalAddItem'></div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {getUserItem, changePoint})(ShowModalAddItem);