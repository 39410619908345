import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListLessonDoingOrPassApi } from '../../api/study/lesson';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import { Affix, Button } from 'antd';
import LessonShow from './componentChildren/LessonShow';
const ButtonGroup = Button.Group;

class LessonStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listLessonDoing: [],
            dayExipre: 0,
            listLessonPast: [],
            isLoadingListLessonDoing: true,
            isLoadingListLessonPast: true,
            typeSelect: 1
        }
    }

    getListLessonDoing = () => {
        this.setState({typeSelect: 1})
        getListLessonDoingOrPassApi(1).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLessonDoing: res.lessons,
                    dayExipre: res.dayExipre,
                    isLoadingListLessonDoing: false,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    getListLessonPast = () => {
        this.setState({typeSelect: 2})
        getListLessonDoingOrPassApi(2).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLessonPast: res.lessons,
                    isLoadingListLessonPast: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.typeSelect) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    seenChat = (StudyStudentId) =>{
        let listSelect = 'listLessonDoing';
        if(this.state.typeSelect == 2){
            listSelect = 'listLessonPast';
        }
        this.setState({
            [listSelect]: this.state[listSelect].map(item =>{
                if(item.StudyStudentId == StudyStudentId){
                    const ListUserIdReadChat = item.ListUserIdReadChat || [];
                    ListUserIdReadChat.push(this.props.dataUser.UserId);
                    return {
                        ...item,
                        ListUserIdReadChat
                    }
                }
                return item;
            })
        })
    }

    componentDidMount() {
        this.getListLessonDoing()
    }

    render() {
        const { listLessonDoing, listLessonPast,isLoadingListLessonDoing, isLoadingListLessonPast, typeSelect, dayExipre } = this.state;
        return (
            <>
                {/* <div className="row item-center mgb">
                    <ButtonGroup className='item-center flex' style={{width: '50%'}}>
                        <Button className={this.buttonGroupClass(1)} onClick={this.getListLessonDoing}>Đang học</Button>
                        <Button className={this.buttonGroupClass(2)} onClick={this.getListLessonPast}>Đã học</Button>
                    </ButtonGroup>
                </div> */}
                {typeSelect == 1?
                    <LessonShow
                        listLesson={listLessonDoing}
                        isLoading={isLoadingListLessonDoing}
                        onRefresh={this.getListLessonDoing}
                        onSeenChat={this.seenChat}
                        dayExipre={dayExipre}
                        type={1}
                        onNavigateStudySubject={this.props.onNavigateStudySubject}
                    />
                :
                    <LessonShow
                        listLesson={listLessonPast}
                        isLoading={isLoadingListLessonPast}
                        onRefresh={this.getListLessonPast}
                        onSeenChat={this.seenChat}
                        type={2}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(LessonStudent)