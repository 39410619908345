import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { updateCountMission } from '../../actions/menu';
import HeaderLottie from '../../components/HeaderLottie'
import { URI_PATH, MESSAGE, PROP } from '../../utils/constants';
import { randomNumber, getDataNotify } from '../../utils/common';
import { showNotification, showNotificationBonus, showNotificationProperty, showNotificationAsset } from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import Countdown, { zeroPad } from 'react-countdown-now';
import { Button, Tooltip, Modal } from 'antd';
import review from '../../assets/lottie/review.json';
import { getListBookApi, publishReviewApi, getBookReviewApi, diamondApi, getRankReviewApi } from '../../api/book/review';
import Rating from 'react-rating';
import { socket } from '../../components/realtime/ReadSocketEvent';
import TooltipUser from '../../components/TooltipUser';
import { showModalEat } from '../../actions/home/modalEat';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BarSelect from '../../components/frame/BarSelect';

class BookReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listBook: [],
            bookInfo: {},
            diamondRemain: false,
            isLoading: true,
            showModal: false,
            modalType: '',
            isPress: false,
            rating: 5,
            inputReview: '',
            review: {},
            dataRank: {},
            isEndIntervalDiamond: false,
            isLoadingRank: false
        };
        this.num = 0;
        this.timeIntervalDiamond = Date.now() + 10000;
    }

    getListBook = () => {
        getListBookApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listBook: res.listBook,
                    diamondRemain: res.diamondRemain
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    getBookReview = (bookId = this.state.bookInfo.BookId) => {
        getBookReviewApi(bookId, this.num).then(res => {
            if (res.code == 1) {
                //interval Diamond
                this.timeIntervalDiamond = Date.now() + 10000;
                //
                this.setState({
                    review: res.data,
                    dataRank: {},
                    isEndIntervalDiamond: false,
                    isLoadingRank: false
                });
            }
            else showNotification(res.message, res.code);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        });
    }

    showModalReview = bookInfo => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            let idNotification = '#notificationBook' + bookInfo.BookId;
            getBookReviewApi(bookInfo.BookId).then(res => {
                if (res.code == 1) {
                    //interval Diamond
                    this.timeIntervalDiamond = Date.now() + 10000;
                    //
                    this.setState({
                        bookInfo,
                        showModal: true,
                        modalType: 2,
                        isPress: false,
                        review: res.data,
                        dataRank: {},
                        isLoadingRank: false
                    });
                }
                else {
                    showNotification(res.message, res.code, idNotification);
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isPress: false });
            });
        }
    }

    review = () => {
        let { isPress, rating, inputReview, bookInfo, listBook } = this.state;
        if (!isPress) {
            let idNotification = '#notificationPublishReview';
            if (inputReview != '') {
                this.setState({ isPress: true });
                publishReviewApi(bookInfo.BookId, rating, inputReview).then(res => {
                    if (res.code == 1) {
                        this.setState({
                            showModal: false,
                            isPress: false,
                            inputReview: '',
                            rating: 5,
                            listBook: listBook.map(el => {
                                if (el.BookId == bookInfo.BookId) return Object.assign({}, el, {
                                    CountReview: parseInt(el.CountReview) + 1,
                                    Rating: Math.round((parseInt(el.CountReview) * el.Rating + rating) / (parseInt(el.CountReview) + 1) * 100) / 100,
                                    IsReview: 1
                                })
                                return el;
                            }),
                        });
                        showNotification(res.message, res.code, '#notificationBook' + bookInfo.BookId);
                        showNotificationAsset(res.assetInfo);
                        showNotificationProperty(res.property);
                        let bonus = res.bonus;
                        showNotificationBonus(bonus.BalanceZ, 1, 3);
                        showNotificationBonus(bonus.Achievement, 1, 4);
                        this.props.changePoint([null, null, bonus.BalanceZ, bonus.Achievement]);
                        this.props.updateCountMission('ReviewBookCount');
                    }
                    else {
                        if (res.code == -5) this.props.showModalEat(res.listFood);
                        else showNotification(res.message, res.code, idNotification);
                        this.setState({ isPress: false });
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                    this.setState({ isPress: false });
                })
            }
            else showNotification('Bạn chưa nhập nội dung review', -1, idNotification);
        }
    }

    diamond = () => {
        if (!this.state.isPress) {
            let dataReview = this.state.review.dataReview;
            this.setState({ isPress: true });
            diamondApi(dataReview.BookId, dataReview.UserId).then(res => {
                if (res.code == 1) {
                    showNotificationProperty(res.property);
                    showNotificationAsset(res.assetInfo);
                    showNotificationBonus(res.bonus.BalanceZ, 1, 3);
                    showNotificationBonus(res.bonus.Achievement, 1, 4);
                    this.props.changePoint([null, null, res.bonus.BalanceZ, res.bonus.Achievement]);
                    //socket
                    let dataUser = this.props.dataUser;
                    let datas = getDataNotify(dataReview.UserId, 0, 0, 0, res.bonus.Achievement, 0, res.propertyPa);
                    datas.message = {
                        fullName: dataUser.FullName,
                        facebookId: dataUser.FacebookId,
                        message: 'bình chọn review cuốn sách "' + this.state.bookInfo.BookName + '" của bạn',
                        isNotify: 2
                    }
                    socket.emit('send_notify', datas);
                    //play sound
                    new Audio(require('../../assets/audio/diamond.wav')).play();
                    //
                    this.setState({
                        isPress: false,
                        dataRank: {},
                        review: {
                            ...this.state.review,
                            isDiamond: 1
                        },
                        diamondRemain: false
                    });
                    showNotification(res.message, res.code);
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, '#notificationDiamondReview');
                    this.setState({ isPress: false });
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, -1, '#notificationDiamondReview');
                this.setState({ isPress: false });
            });
        }
    }

    loadMoreReview = (type) => {
        let nextMax = parseInt(this.state.review.countReview);
        switch (type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() => {
            this.getBookReview();
        }, 200)
    }

    getRankReview = () => {
        let { dataRank, isLoadingRank } = this.state
        if (Object.keys(dataRank).length === 0 && !isLoadingRank) {
            this.setState({ isLoadingRank: true })
            let dataReview = this.state.review.dataReview;
            getRankReviewApi(dataReview.BookId, dataReview.UserId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        dataRank: res.data,
                        isLoadingRank: false
                    });
                }
                else showNotification(res.message, res.code);
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    renderDiamond = () => {
        let { review, isEndIntervalDiamond, diamondRemain, isPress } = this.state;
        if (!review.isDiamond && diamondRemain > 0) {
            if (isEndIntervalDiamond) {
                return (
                    <Tooltip
                        overlayClassName='pv-tooltip-diamond'
                        title={
                            <div className='flex-column item-center'>
                                <img
                                    src={require('../../assets/image/diamond-white.gif')}
                                    style={{ width: '8vh' }}
                                />
                                <Button
                                    className='pv-confirm-diamond'
                                    onClick={this.diamond}
                                    loading={isPress}
                                >
                                    Bình chọn
                                </Button>
                                <div className='mgt' id='notificationDiamondReview'></div>
                            </div>
                        }
                    >
                        <img
                            src={require('../../assets/image/diamond-white.gif')}
                            className='pv-diamond'
                        />
                    </Tooltip>
                )
            }
            return (
                <div className='flex-column'>
                    <img
                        src={require('../../assets/image/diamond-disable.png')}
                        className='pv-diamond-disable'
                    />
                    <Countdown
                        date={this.timeIntervalDiamond}
                        renderer={({ days, hours, minutes, seconds }) => (<span className='text-small bold color-white'>{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                        onComplete={() => this.setState({ isEndIntervalDiamond: true })}
                    />
                </div>
            )
        }
        return (
            <img
                src={require('../../assets/image/diamond-disable.png')}
                className='pv-diamond-disable'
            />
        );
    }

    renderNavigate = () => (
        <BarSelect>
            <Icon
                name='caret-left'
                className='pv-navigate'
                onClick={() => this.loadMoreReview('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{ fontSize: '4vh' }}
                onClick={() => this.loadMoreReview('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                onClick={() => this.loadMoreReview('next')}
            />
            <Tooltip
                overlayClassName='pv-tooltip-rank'
                onVisibleChange={this.getRankReview}
                title={this.renderRank}
            >
                <Icon
                    name='flag'
                    className='pv-navigate'
                    style={{ fontSize: '5vh' }}
                />
            </Tooltip>
            {this.renderDiamond()}
        </BarSelect>
    )

    renderRank = () => {
        let { dataRank, isLoadingRank } = this.state;
        let dataReview = this.state.review.dataReview;
        if (isLoadingRank) return (<Loading small />);
        return (
            <div className='row mgt-3 item-center'>
                <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Hạng</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataReview.Diamond}</p>
                            <img
                                src={require('../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamond}/{dataRank.allReview}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderModal = () => {
        if (this.state.modalType == 1) {
            let { bookInfo, rating, inputReview, isPress } = this.state;
            return (
                <div className='flex-column item-center'>
                    <p className='text-large bold'>Review cuốn: {bookInfo.BookName}</p>
                    <img className='review-book-image' src={`${URI_PATH}${bookInfo.BookImage}`} />
                    <Rating
                        emptySymbol='fa fa-star-o fa-2x'
                        fullSymbol='fa fa-star fa-2x'
                        initialRating={rating}
                        onChange={value => this.setState({ rating: value })}
                    />
                    <div
                        className='review-book-input'
                        onClick={() => this.refs.inputReview.focus()}
                    >
                        <ReactQuill
                            ref='inputReview'
                            value={inputReview}
                            onChange={inputReview => this.setState({ inputReview })}
                            modules={{ toolbar: [['bold', 'italic', 'underline']] }}
                            placeholder='Nhập nội dung review'
                        />
                    </div>
                    {/* <p className='mgt'>Thử thách đọc và review 1 cuốn sách.</p>
                    <p className='mgt'>Review nhận được nhiều kim cương sẽ mang lại cho bạn nhiều thành tựu thụ động!</p> */}
                    <div className='mgt' id='notificationPublishReview'></div>
                    <Button
                        className='bg-success mgt'
                        loading={isPress}
                        onClick={this.review}
                    >
                        Đăng review
                    </Button>
                </div>
            )
        }
        else if (this.state.modalType == 2) {
            let { bookInfo, review } = this.state;
            let dataReview = review.dataReview;
            return (
                <div style={{ marginBottom: '2vh' }}>
                    <div className='review-book-info-container'>
                        <div className='flex-row mgt'>
                            <div className='flex-1 item-center'>
                                <img className='review-book-image' src={`${URI_PATH}${bookInfo.BookImage}`} />
                            </div>
                            <div className='flex-2 flex-column item-center'>
                                <p className='text-left'>Tên Tiếng Việt: {bookInfo.BookName}</p>
                                <p className='text-left'>Tác giả: {bookInfo.AuthorName}</p>
                                <p className='text-left'>Tên Quốc tế: {bookInfo.BookSubName}</p>
                                <p className='text-left'>Dịch giả: {bookInfo.TranslatorName}</p>
                            </div>
                        </div>
                    </div>
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        {review.isDiamond ?
                            <img
                                src={require('../../assets/image/diamond-white.gif')}
                                className='pv-diamond-case'
                            />
                            :
                            <img
                                src={require('../../assets/image/diamond-case.png')}
                                className='pv-diamond-case'
                            />
                        }
                        <div className='flex-row mgt-3 item-center'>
                            <TooltipUser userInfo={dataReview} avatarClass='avatar-guest' />
                            <div className='pv-user-container'>
                                <p className='bold'>{dataReview.FullName}</p>
                                <p className='placeholder black'>{dataReview.CrDateTime}</p>
                            </div>
                        </div>
                        <div className='flex-column'>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <p className='text-large text-center bold mgt mgb'>Review: {bookInfo.BookName}</p>
                            <p className='text-large text-center bold mgt mgb'>{dataReview.RateStar}<Icon name='star' /></p>
                            <div>
                                <p className='text-left' dangerouslySetInnerHTML={{ __html: dataReview.ReviewContent }} />
                            </div>
                            <p className='pv-license'>Bản quyền thuộc về {dataReview.FullName}</p>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }

    componentDidMount() {
        this.getListBook();
    }

    render() {
        let { isLoading, listBook, showModal, isPress } = this.state;
        if (isLoading) return (<Loading />);
        return (
            <div>
                <HeaderLottie source={review} />
                <div className='row'>
                    {listBook.map((item, key) =>
                        <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                            <div>
                                {item.IsReview == 0 &&
                                    <Button
                                        className='bg-success mgr'
                                        onClick={() => this.setState({ showModal: true, modalType: 1, bookInfo: item })}
                                    >
                                        Review
                                    </Button>
                                }
                                {/* <Button
                                    className='bg-black'
                                    onClick={() => this.showModalReview(item)}
                                    loading={isPress}
                                >
                                    Xem review
                                </Button> */}
                            </div>
                        }>
                            <div className='col-md-3 col-sm-4 col-xs-6 item-center item-block'>
                                <img className='review-book-image' src={`${URI_PATH}${item.BookImage}`} />
                                <p className='mgt'>{item.CountReview} review</p>
                                <p className='mgt'>{item.Rating} <Icon name='star' /></p>
                                <p className='mgt bold'>{item.BookName}</p>
                                <div className='mgt' id={'notificationBook' + item.BookId} />
                            </div>
                        </Tooltip>
                    )}
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered={true}
                    width={700}
                >
                    {this.renderModal()}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, updateCountMission, showModalEat })(BookReview);