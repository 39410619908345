import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ getUserData } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import profile from '../../assets/lottie/profile.json';
import { Button, Switch } from 'antd';
import {getUserProfileApi, updateProfilePublicApi, updateProfilePrivateApi, changePasswordApi} from '../../api/setting/profile';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';

class SettingProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            userProfile: {},
            isLoading: true,
            isPress: false,
            fullName: '',
            gender: '',
            idCard: '',
            isPrivate: '',
            dayBirth: '',
            monthBirth: '',
            yearBirth: '',
            passwordOld: '',
            passwordNew: '',
            rePassword: ''
        }
    }

    getUserProfile = () =>{
        getUserProfileApi().then(res =>{
            if(res.code == 1){
                let userProfile = res.userProfile;
                this.setState({
                    isLoading: false,
                    fullName: userProfile.FullName,
                    gender: userProfile.Gender,
                    idCard: userProfile.IDCardNumber,
                    isPrivate: userProfile.IsPrivate,
                    dayBirth: parseInt(userProfile.BirthDay.substring(8, 10)),
                    monthBirth: parseInt(userProfile.BirthDay.substring(5, 7)),
                    yearBirth: userProfile.BirthDay.substring(0, 4),
                });
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => console.log(e));
    }

    updateProfilePublic = () =>{
        let {isPress, fullName, dayBirth, monthBirth, yearBirth} = this.state;
        if(!isPress){
            let idNotification = '#notificationProfilePublic';
            if(fullName != ''){
                this.setState({isPress: 1});
                let birthDay = yearBirth + '-' + monthBirth + '-' + dayBirth;
                updateProfilePublicApi(fullName, birthDay).then(res =>{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                })
            }
            else return showNotification('Vui lòng điền họ tên', 0, idNotification);
        }
    }

    updateProfilePrivate = () =>{
        let {isPress, gender, idCard, isPrivate} = this.state;
        if(!isPress){
            let idNotification = '#notificationProfilePrivate';
            if(idCard.length == 4){
                this.setState({isPress: 2});
                updateProfilePrivateApi(gender, idCard, isPrivate).then(res =>{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                })
            }
            else return showNotification('4 số cuối giấy tờ tuỳ thân không đúng', 0, idNotification);
        }
    }

    changPassword = () =>{
        let {isPress, passwordOld, passwordNew, rePassword} = this.state;
        if(!isPress){
            let idNotification = '#notificationProfilePassword';
            if(passwordOld != '' && passwordNew != '' && rePassword != '' && passwordNew == rePassword){
                changePasswordApi(passwordOld, passwordNew).then(res =>{
                    if(res.code == 1){
                        this.setState({
                            passwordOld: '',
                            passwordNew: '',
                            rePassword: '',
                            isPress: false
                        });
                    }
                    else this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                })
            }
            else if(passwordOld == '') return showNotification('Bạn chưa nhập mật khẩu cũ', 0, idNotification);
            else if(passwordNew == '') return showNotification('Bạn chưa nhập mật khẩu mới', 0, idNotification);
            else if(rePassword == '') return showNotification('Bạn chưa nhập lại mật khẩu mới', 0, idNotification);
            else showNotification('Nhập lại mật khẩu mới phải trùng nhau', 0, idNotification);
        }
    }

    componentDidMount(){
        this.getUserProfile();
    }

    render(){
        let {isLoading, isPress, fullName, gender, idCard, isPrivate, dayBirth, monthBirth, yearBirth, passwordOld, passwordNew, rePassword} = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                <HeaderLottie source={profile} disableLoop />
                <div className='row'>
                    <div className='setting-profile-container col-md-6 col-sm-12 col-xs-12'>
                        <p className='text-large text-center'>Thông tin cá nhân</p>
                        <p className='mgt'>Họ tên:</p>
                        <input
                            className='form-control'
                            placeholder='Nhập họ tên'
                            value={fullName}
                            onChange={e => this.setState({fullName: e.target.value})}
                        />
                        <p className='mgt'>Ngày sinh:</p>
                        <div className='row' style={{flexDirection: 'row'}}>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    onChange={e => this.setState({dayBirth: e.target.value})}
                                    value={dayBirth}
                                >
                                    {new Array(31).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Ngày {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    onChange={e => this.setState({monthBirth: e.target.value})}
                                    value={monthBirth}
                                >
                                    {new Array(12).fill(undefined).map((item, key) =>(
                                        <option value={key+1} key={key+1}>Tháng {key+1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control'
                                    onChange={e => this.setState({yearBirth: e.target.value})}
                                    value={yearBirth}
                                >
                                    {new Array(70).fill(undefined).map((item, key) =>(
                                        <option value={key+1950} key={key+1950}>Năm {key+1950}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <p className='mgt'><i>Bạn được quyền đổi tên khi dưới 500 thành tựu.</i></p>
                        <div id='notificationProfilePublic' />
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt'
                                onClick={this.updateProfilePublic}
                                loading={isPress == 1}
                            >
                                Cập nhật
                            </Button>
                        </div>
                        <p className='text-large text-center mgt-2'>Thông tin riêng tư</p>
                        <p className='mgt'>Giới tính:</p>
                        <select
                            className='form-control'
                            style={{textAlignLast: 'auto'}}
                            value={gender}
                            onChange={e => this.setState({gender: e.target.value})}
                        >
                            <option value={0}>Khác</option>
                            <option value={1}>Nữ</option>
                            <option value={2}>Nam</option>
                        </select>
                        <p className='mgt'>4 số cuối giấy tờ tuỳ thân (CMT - Thẻ căn cước - Hộ chiếu)</p>
                        <input
                            className='form-control'
                            placeholder='Nhập 4 số cuối giấy tờ tuỳ thân'
                            value={idCard}
                            onChange={e => this.setState({idCard: e.target.value})}
                        />
                        <p className='mgt'>Bảo mật số điện thoại và email</p>
                        <Switch
                            checked={isPrivate == 2}
                            onChange={e => this.setState({isPrivate: e ? 2 : 0})}
                        />
                        <div id='notificationProfilePrivate' />
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt-2'
                                onClick={this.updateProfilePrivate}
                                loading={isPress == 2}
                            >
                                Cập nhật
                            </Button>
                        </div>
                    </div>
                    <div className='setting-profile-container col-md-6 col-sm-12 col-xs-12'>
                        <p className='text-large text-center'>Bảo mật</p>
                        <p className='mgt'>Mật khẩu cũ:</p>
                        <input
                            className='form-control'
                            value={passwordOld}
                            onChange={e => this.setState({passwordOld: e.target.value})}
                            type='password'
                            placeholder='Nhập mật khẩu cũ'
                        />
                        <p className='mgt'>Mật khẩu mới:</p>
                        <input
                            className='form-control'
                            value={passwordNew}
                            onChange={e => this.setState({passwordNew: e.target.value})}
                            type='password'
                            placeholder='Nhập mật khẩu mới'
                        />
                        <p className='mgt'>Gõ lại mật khẩu mới:</p>
                        <input
                            className='form-control'
                            value={rePassword}
                            onChange={e => this.setState({rePassword: e.target.value})}
                            type='password'
                            placeholder='Nhập lại mật khẩu mới'
                        />
                        <div id='notificationProfilePassword' />
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt-2'
                                onClick={this.changPassword}
                                loading={isPress == 3}
                            >
                                Đổi mật khẩu
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { getUserData })(SettingProfile);