import React, { Component } from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {updateCountNotify} from '../../actions/menu';
import {getListAskQuestionApi, actionVoteApi, getVoteRemainApi} from '../../api/askquestion/vote';
import {URI_PATH, MESSAGE} from '../../utils/constants';
import {randomNumber, isTouch} from '../../utils/common';
import {showNotification, showNotificationAsset, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import SearchUser from '../../components/SearchUser';
import {getCategory, getSubCategory} from '../../actions/category/';
import { Button, Tooltip } from 'antd';
import Countdown, { zeroPad } from 'react-countdown-now';
import { withRouter } from 'react-router'
import queryString from 'query-string';
import FsLightbox from 'fslightbox-react';
import TooltipUser from '../../components/TooltipUser';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {showModalEat} from '../../actions/home/modalEat';
import BarSelect from '../../components/frame/BarSelect';

class AskQuestionVote extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            data: {},
            isEmpty: false,
            voteRemain: 0,
            isEndIntervalVote: false,
            isPress: false,
            showPreviewImage: false,
            imagePreview: ''
        };
        //check url parameter
        this.directAskQuestion = '';
        let askQuestionId = queryString.parse(this.props.location.search).id;
        if(askQuestionId > 0) this.directAskQuestion = askQuestionId;
        //
        this.num = 0;
        this.categoryId = '';
        this.subCategoryId = '';
        this.searchUser = '';
        this.timeIntervalVote = '';
    }

    getAskQuestion = () =>{
        let {num, categoryId, subCategoryId, searchUser, directAskQuestion} = this;
        getListAskQuestionApi(num, categoryId, subCategoryId, searchUser, directAskQuestion).then((res) => {
            if(res.code == 1){
                //interval Diamond
                this.timeIntervalVote = Date.now() + 15000;
                //
                this.setState({
                    data: res.data,
                    isEmpty: false,
                    isLoading: false,
                    isEndIntervalVote: false
                });
                window.history.replaceState({}, '', '/menu/ask-question-vote?id=' + res.data.dataAskQuestion.AskQuestionQueueId);
            }
            else this.setState({isEmpty: true, isLoading: false})
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getVoteRemain = () =>{
        getVoteRemainApi().then(res =>{
            if(res.code == 1){
                this.setState({voteRemain: res.voteRemain});
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMoreAskQuestion = (type) =>{
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        let nextMax = parseInt(this.state.data.countAskQuestion);
        if(this.directAskQuestion > 0) this.directAskQuestion = '';
        switch(type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() =>{
            this.getAskQuestion();
        }, 200)
    }

    actionVote = (askQuestionQueueId, isVote) =>{
        if(!this.state.isPress){
            this.setState({isPress: true});
            actionVoteApi(askQuestionQueueId, isVote).then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code);
                    showNotificationAsset(res.assetInfo);
                    showNotificationBonus(res.balance, 1, 2);
                    this.props.updateCountNotify('AskQuestionVoteCount');
                    this.setState({voteRemain: this.state.voteRemain - 1});
                    this.props.changePoint([null, res.balance]);
                    this.num = this.num > 0 ? this.num - 1 : 0;
                    this.directAskQuestion = '';
                    this.getAskQuestion();
                }
                else {
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code);
                }
                this.setState({isPress: false});
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false});
            })
        }
    }

    changeCategory = (value, action) =>{
        if(this.directAskQuestion > 0) this.directAskQuestion = '';
        this.num = 0;
        if(action == 1){
            this.categoryId = value;
            if(this.categoryId) this.props.getSubCategory(this.categoryId);
            else this.subCategoryId = '';
            this.getAskQuestion();
        }
        else {
            this.subCategoryId = value;
            this.getAskQuestion();
        }
    }

    renderVote = () =>{
        let {data, isEndIntervalVote, voteRemain, isPress} = this.state;
        if(!data.isVote && voteRemain > 0){
            if(isEndIntervalVote){
                return(
                    <div className='flex item-center'>
                        <Tooltip
                            overlayClassName='pv-tooltip-diamond'
                            trigger={isTouch() ? 'click' : 'hover'}
                            title={
                                <div className='flex-column'>
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/check.png')}
                                            className='pv-vote-img'
                                        />
                                    </div>
                                    <div className='mgl'>
                                        <div className='flex-row align-center mgt'>
                                            <Icon name='check' className='mgr' />
                                            <p>Nội dung đăng tải có ích với cộng đồng khởi nghiệp.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Nội dung cần truyền đạt không vi phạm điều khoản, vi phạm pháp luật.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Ngôn từ sử dụng không xúc phạm, gây mất uy tín danh dự người khác.</p>
                                        </div>
                                    </div>
                                    <div className='item-center'>
                                        <Button
                                            className='bg-success mgt-2'
                                            onClick={() => this.actionVote(data.dataAskQuestion.AskQuestionQueueId, 2)}
                                            loading={isPress}
                                        >
                                            Bỏ phiếu
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <img
                                src={require('../../assets/image/check.png')}
                                className='pv-vote-img'
                            />
                        </Tooltip>
                        <Tooltip
                            overlayClassName='pv-tooltip-diamond'
                            trigger={isTouch() ? 'click' : 'hover'}
                            title={
                                <div className='flex-column'>
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/uncheck.png')}
                                            className='pv-vote-img'
                                        />
                                    </div>
                                    <div className='mgl'>
                                        <div className='flex-row align-center mgt'>
                                            <Icon name='times' className='mgr' />
                                            <p>Nội dung đăng tải không có ích với cộng đồng khởi nghiệp.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='times' className='mgr' />
                                            <p>Nội dung cần truyền đạt vi phạm điều khoản, vi phạm pháp luật.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='times' className='mgr' />
                                            <p>Ngôn từ sử dụng xúc phạm, gây mất uy tín danh dự người khác.</p>
                                        </div>
                                    </div>
                                    <div className='item-center'>
                                        <Button
                                            className='bg-danger mgt-2'
                                            onClick={() => this.actionVote(data.dataAskQuestion.AskQuestionQueueId, 1)}
                                            loading={isPress}
                                        >
                                            Bỏ phiếu
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <img
                                src={require('../../assets/image/uncheck.png')}
                                className='pv-vote-img'
                            />
                        </Tooltip>
                    </div>
                )
            }
            return (
                <div>
                    <Countdown
                        date={this.timeIntervalVote}
                        renderer={({ days, hours, minutes, seconds}) =>(
                            <span className='text-large bold mgl-2 color-white'>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
                        )}
                        onComplete={() => this.setState({isEndIntervalVote: true})}
                    />
                </div>
            )
        }
        return null;
    }

    renderNavigate = () =>(
        <BarSelect>
            <Icon
                name='caret-left'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMoreAskQuestion('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{fontSize: '4vh', margin: '0vh 1vh'}}
                onClick={() => this.loadMoreAskQuestion('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMoreAskQuestion('next')}
            />
            {this.renderVote()}
        </BarSelect>
    )

    componentDidMount(){
        this.getVoteRemain();
        this.getAskQuestion();
        if(this.props.categoryAskQuestion.length == 0) this.props.getCategory(4);
    }

    render(){
        let {isLoading, data, isEmpty, showPreviewImage, imagePreview} = this.state;
        let {categoryAskQuestion, subCategory} = this.props;
        let dataAskQuestion = data.dataAskQuestion;
        if(isLoading) return <Loading />;
        return(
            <div style={{paddingBottom: '8vh'}}>
                <div className='pv-search-container row'>
                    <div className='col-md-4 col-sm-4 col-xs-12'>
                        <SearchUser
                            ref='searchUser'
                            onChange={this.searchUserPost}
                            onKeyDown={this.deleteSearch}
                            placeholder='🔎 Họ tên / Số điện thoại'
                        />
                    </div>
                    <div className={(this.categoryId > 0 && subCategory.length > 0) ? 'col-md-4 col-sm-4 col-xs-6' : 'col-md-8 col-sm-8 col-xs-6'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value, 1)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryAskQuestion.map((item, key) =>(
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                    {this.categoryId > 0 && subCategory.length > 0 &&
                        <div className='col-md-4 col-sm-4 col-xs-6'>
                            <select
                                className='form-control'
                                onChange={e => this.changeCategory(e.target.value, 2)}
                            >
                                <option value=''>Tất cả chủ đề</option>
                                {subCategory.map((item, key) =>{
                                    return <option key={key} value={item.SubCategoryId}>{item.SubCategoryName}</option>
                                })}
                            </select>
                        </div>
                    }
                </div>
                {isEmpty ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không còn câu hỏi cần phê duyệt</p>
                    </div>
                :
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        <div className='flex-row mgt-2'>
                            <div className='flex-row align-center'>
                                <TooltipUser userInfo={dataAskQuestion} avatarClass='avatar-guest' key={dataAskQuestion.UserId}/>
                                <div className='pv-user-container'>
                                    <p className='bold'>{dataAskQuestion.FullName}</p>
                                    <p className='placeholder black'>{dataAskQuestion.CrDateTime}</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex-column'>
                            <p className='text-large bold text-center'>{dataAskQuestion.CategoryName}</p>
                            <p className='text-large bold text-center'>{dataAskQuestion.SubCategoryName}</p>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <p className='text-large text-center bold mgt mgb'>{dataAskQuestion.AskQuestionTitle}</p>
                            <div>
                                {dataAskQuestion.PostImage &&
                                    <img
                                        src={`${URI_PATH}${dataAskQuestion.PostImage}`}
                                        className='pv-post-image'
                                        onClick={() => this.setState({
                                            showPreviewImage: true,
                                            imagePreview: `${URI_PATH}${dataAskQuestion.AskQuestionImage}`
                                        })}
                                    />
                                }
                                <div className='text-left' dangerouslySetInnerHTML={{__html: dataAskQuestion.AskQuestionContent}} />
                            </div>
                            {dataAskQuestion.AskQuestionDesc &&
                                <div>
                                    <hr width="95%" align="center" className="hr-default"></hr>
                                    <p className='text-center text-large mgt bold'>Nội dung tham khảo trích dẫn</p>
                                    <div className='text-left mgt' dangerouslySetInnerHTML={{__html: dataAskQuestion.AskQuestionDesc}} />
                                </div>
                            }
                        </div>
                    </div>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryAskQuestion, subCategory } = state.getCategory;
    return { dataUser, categoryAskQuestion, subCategory };
}

export default connect(mapStateToProps, { changePoint, getCategory, getSubCategory, updateCountNotify, showModalEat })(withRouter(AskQuestionVote));