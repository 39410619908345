import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ListVestige from "../../components/home/ListVestige";


class Vestige extends Component {
    render() {
        return (
            <ListVestige />
        )
    }
}

export default connect()(withRouter(Vestige))