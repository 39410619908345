import React, { Component } from 'react';
import { Button } from 'antd';
import { getAvatarFb, priceFormat } from '../../../utils/common';
import { BALANCE_TYPE } from '../../../utils/constants';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class QuestionWin extends Component{
    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p>
                            <span className='bold'>{data.FullName}
                            <Checked data={data} />
                        {data.UserClubName && `(${data.UserClubRoleName} ${data.UserClubName})`}</span> hạ gục <span className='bold'>{data.UserLoseName} {data.UserLoseClubName && `(${data.UserLoseClubRoleName} ${data.UserLoseClubName})`}</span> nhận được chiến lợi phẩm {data.BalanceType == 1 ? data.Balance : priceFormat(data.Balance)} {BALANCE_TYPE[data.BalanceType]}
                        </p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-row item-center mgt'>
                    <img src={getAvatarFb(data.FacebookId)} className='avatar-guest-large mgr-2' />
                    <img src={require('../../../assets/image/sword-vs.png')} className='newfeed-img' />
                    <img src={getAvatarFb(data.UserLoseFacebookId)} className='avatar-guest-large mgl-2' />
                </div>
                {!data.Guest &&
                    <div className='item-center'>
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={() => this.props.history.push('/question-fight')}
                        >
                            Thách đấu
                        </Button>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(QuestionWin);