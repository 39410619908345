import React, { useRef, useEffect } from "react";
import TimeSlider from "react-input-slider";
import { connect } from 'react-redux';
import { logAudioApi } from '../../api/audio';
import { setAudioIndex, setAudioCurrentTime, setAudioDuration, setAudioPlay, setAudioRepeat, hideAudio } from '../../actions/audio';
import {isIOS, isMacOs} from "react-device-detect";

const BottomMp3 = (props) => {
    const audioRef = useRef();

    const handleLoadedData = () => {
        props.setAudioDuration(audioRef.current.duration);
        if (props.isPlay) audioRef.current.play();
        //logAudio
        if (props.audioIndex >= 0) {
            let audioId = parseInt(props.listAudio[props.audioIndex].AudioId);
            if (props.listAudio.length - audioId < 2) { // Audio chính
                audioId = props.listAudio.length - audioId == 1 ? 99999 : 10000
            }
            // logAudioApi(audioId)
        }
    };

    const handlePausePlayClick = () => {
        props.setAudioPlay(!props.isPlay);
    };

    const handleTimeSliderChange = ({ x }) => {
        audioRef.current.currentTime = x;
        props.setAudioCurrentTime(x);

        if (!props.isPlay) {
            props.setAudioPlay(true);
            audioRef.current.play();
        }
    };

    const endAudio = () => {
        if (props.isRepeat) {
            audioRef.current.currentTime = 0;
            props.setAudioCurrentTime(0);
            audioRef.current.play();
        }
        else props.setAudioIndex((props.audioIndex + 1) % props.listAudio.length)
    }

    const backForward15 = () => {
        let timeBacked = props.currentTime - 15;
        timeBacked = timeBacked > 0 ? timeBacked : 0;
        audioRef.current.currentTime = timeBacked;
        props.setAudioCurrentTime(timeBacked);
    }

    const checkDevice = () =>{
        if(isIOS || isMacOs) {
            props.setAudioPlay(false);
            setTimeout(() => {
                props.setAudioPlay(true);
            }, 50);
        }
    }

    useEffect(() => {
        if (props.isPlay) audioRef.current.play()
        else audioRef.current && audioRef.current.pause();
    }, [props.isPlay]);

    return (
        <>
            {!props.isLoadingAudio && props.listAudio.length > 0 &&
                <audio
                    ref={audioRef}
                    src={props.listAudio[props.audioIndex].AudioLink}
                    onLoadedData={handleLoadedData}
                    onTimeUpdate={() => props.setAudioCurrentTime(audioRef.current.currentTime)}
                    onEnded={endAudio}
                    autoPlay={false}
                />
            }
            {props.showAudio &&
                <div className='bottom-mp3-container'>
                    <div className='bottom-mp3 relative'>
                        <p className='mp3-title-bottom'>{props.listAudio[props.audioIndex].AudioTitle}</p>
                        <TimeSlider
                            axis="x"
                            xmax={props.duration}
                            x={props.currentTime}
                            onChange={handleTimeSliderChange}
                            styles={{
                                track: {
                                    backgroundColor: 'rgba(255,255,255,0.3)',
                                    height: "0.3vh",
                                    width: '100%'
                                },
                                active: {
                                    backgroundColor: "#fff",
                                    height: "0.3vh",
                                },
                                thumb: {
                                    marginLeft: '1.2vh',
                                    marginBottom: '0.2vh',
                                    width: "2vh",
                                    height: "2vh",
                                    backgroundColor: "transparent",
                                    borderRadius: "1vh",
                                },
                            }}
                        />
                        <div className='flex item-center mgt'>
                            <img
                                src={require('../../assets/image/player/back-forward.png')}
                                className='icon-play-bottom'
                                onClick={() => {
                                    const index = (props.audioIndex - 1) % props.listAudio.length;
                                    if (index >= 0) props.setAudioIndex(index)
                                    else props.setAudioIndex(props.listAudio.length - 1)
                                    checkDevice();
                                }}
                            />
                            <div onClick={handlePausePlayClick}>
                                {props.isPlay ?
                                    <img
                                        src={require('../../assets/image/player/pause.png')}
                                        className='icon-play-bottom'
                                    />
                                    :
                                    <img
                                        src={require('../../assets/image/player/play.png')}
                                        className='icon-play-bottom'
                                    />
                                }
                            </div>
                            <img
                                src={require('../../assets/image/player/fast-forward.png')}
                                className='icon-play-bottom'
                                onClick={() =>{
                                    props.setAudioIndex((props.audioIndex + 1) % props.listAudio.length)
                                    checkDevice();
                                }}
                            />
                            <img
                                src={require('../../assets/image/player/back-forward15.png')}
                                className='icon-play-bottom'
                                onClick={backForward15}
                            />
                            <img
                                src={props.isRepeat ? require('../../assets/image/player/repeat-active.png') : require('../../assets/image/player/repeat.png')}
                                className='icon-play-bottom'
                                onClick={() => props.setAudioRepeat(!props.isRepeat)}
                            />
                            <img
                                src={require('../../assets/image/delete.png')}
                                className='icon-play-bottom'
                                onClick={() => {
                                    audioRef.current.pause();
                                    setTimeout(() => {
                                        props.hideAudio()
                                    }, 50);
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => {
    const { listAudio, isLoadingAudio, audioIndex, currentTime, duration, isRepeat, isPlay, showAudio } = state.getAudio;
    return { listAudio, isLoadingAudio, audioIndex, currentTime, duration, isRepeat, isPlay, showAudio }
}

export default connect(mapStateToProps, { setAudioIndex, setAudioCurrentTime, setAudioDuration, setAudioPlay, setAudioRepeat, hideAudio })(BottomMp3)