import React, { Component } from 'react';
import { MESSAGE, PROP } from '../../utils/constants';
import { connect } from 'react-redux';
import { getQuestionTrainApi, getListQuestionTrainApi, saveAnswerTrainApi } from '../../api/question/train';
import { changePoint } from '../../actions/home/';
import { getQuestionTrainSelect } from '../../actions/question/train';
import { updateCountMission } from '../../actions/menu';
import { getMiniusSecond, priceFormat } from '../../utils/common';
import { showNotification, showNotificationBonus, showNotificationAsset } from '../../utils/notification';
import Loading from '../../components/Loading';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import InfiniteScroll from 'react-infinite-scroller';
import { Button } from 'antd';
import Lottie from 'react-lottie';
import firework from '../../assets/lottie/firework.json';
import questionTrain from '../../assets/lottie/questionTrain.json';
import { showModalEat } from '../../actions/home/modalEat';
import HeaderLottie from '../../components/HeaderLottie';

class QuestionTrain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questiontrainlistId: false,
            questiontrainlistName: '',
            textTime: '--:--',
            resultAnswer: false,
            selectAnswerKey: '',
            isTrain: false,
            isPress: false,
            isAnswer: false,
            questionList: [],
            isLoading: true,
            loadingFirstList: true,
            endList: false
        };
        this.pageList = 1;
        this.questionTrain = {};
        this.timeDiff = 5;
        this.correctAnswer = '';
        this.resultColor = '';
        this.questionInterval = '';
    }

    getQuestionTrain = (page = 1) => {
        getQuestionTrainApi(page).then(res => {
            this.setState({
                questionList: res.questionList,
                isLoading: false,
                loadingFirstList: false,
            })
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    changeBet = e => {
        this.setState({
            questiontrainlistId: e.target.value,
        })
    }

    train = () => {
        let { isPress, questiontrainlistId } = this.state;
        let { listTrain } = this.props;
        let idNotification = '#notificationStartQuestionTrain';
        if (isPress === false) {
            this.setState({ isPress: true });
            questiontrainlistId = questiontrainlistId > 0 ? questiontrainlistId : listTrain[0]['key'];
            getListQuestionTrainApi(questiontrainlistId).then(res => {
                if (res.code == 1) {
                    showNotificationBonus(res.balance, -1, 2);
                    showNotificationBonus(res.balanceZ, -1, 3);
                    this.props.changePoint([null, -res.balance, -res.balanceZ, null]);
                    this.questionTrain = res.questionList[0];
                    this.timeDiff = res.timeDiff;
                    this.setState({
                        isTrain: true,
                        isAnswer: false,
                        textTime: '--:--',
                        resultMessage: '',
                        selectAnswerKey: ''
                    });
                    this.questionInterval = setInterval(() => {
                        this.timeDiff -= 1;
                        this.setState({ textTime: getMiniusSecond(this.timeDiff) });
                        if (this.timeDiff <= 0) {
                            this.setState({
                                isTrain: false,
                                isPress: false,
                                isAnswer: false,
                                textTime: '--:--',
                                resultAnswer: false,
                                selectAnswerKey: ''
                            });
                            this.questionTrain = {};
                            this.correctAnswer = '';
                            this.timeDiff = 30;
                            clearInterval(this.questionInterval);
                        }
                    }, 1000);
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({
                        isTrain: false,
                        isPress: false,
                    });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({
                    isPress: false,
                    isTrain: false,
                });
            })
        }
    }

    saveAnswer = (answer, keySelect, questionId) => {
        if (this.state.isAnswer === false) {
            let idNotification = '#notificationQuestionTrain';
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            saveAnswerTrainApi(questionId, answer).then(res => {
                if (res.code == 1 || res.code == 0) {
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    this.setState({
                        resultAnswer: res.code,
                        questionList: [
                            {
                                QuestionLogicId: res.questionLogicId,
                                IsCorrect: res.code,
                                CrDateTime: 'Vừa xong',
                                Balance: res.balance > 0 ? res.balance : res.balanceZ,
                                BalanceType: res.betType
                            },
                            ...this.state.questionList
                        ]
                    });
                    if (res.code == 1) {
                        //assets bonus
                        showNotificationAsset(res.assetInfo);
                        //
                        showNotificationBonus(res.balanceZ, 1, 3);
                        showNotificationBonus(res.balance, 1, 2);
                        this.props.changePoint([null, res.balance, res.balanceZ]);
                        this.props.updateCountMission('GiftTrainCount');
                    }
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
            })
        }
    }

    loadMoreList = () => {
        let { loadingFirstList, endList, questionList } = this.state;
        if (!loadingFirstList && !endList) {
            this.pageList += 1;
            getQuestionTrainApi(this.pageList).then(res => {
                if (res.questionList.length > 0) {
                    this.setState({
                        questionList: [...questionList, ...res.questionList],
                    });
                }
                else this.setState({ endList: true });
            }).catch(error => showNotification(MESSAGE.ERROR, -1));
        }
    }

    componentDidMount() {
        this.props.getQuestionTrainSelect();
        this.getQuestionTrain();
    }

    componentWillUnmount() {
        clearInterval(this.questionInterval);
    }

    render() {
        let { isLoading, endList, isPress, selectAnswerKey, resultAnswer, isTrain, questionList, textTime } = this.state;
        let { isLoadingSelect, listTrain } = this.props;
        if (isLoading || isLoadingSelect) return (<Loading />);
        return (
            <div>
                <HeaderLottie
                    source={questionTrain}
                    disableLoop
                />
                {!isTrain ?
                    <div className='animated fadeIn fast'>
                        <div className='flex item-center'>
                            <p className='bold title-success mgb'>
                                {this.props.countMission.GiftTrainCount > 0 ?
                                    `Trả lời đúng ${this.props.countMission.GiftTrainCount} câu hỏi bạn sẽ vượt qua thử thách`
                                    :
                                    'Bạn đã hoàn thành thử thách ngày hôm nay'
                                }
                            </p>
                        </div>
                        <div className='row item-center'>
                            <div className='col-md-5 col-sm-8 col-xs-12'>
                                <select
                                    className='form-control'
                                    style={{ width: '99%' }}
                                    onChange={this.changeBet}
                                    defaultValue={this.state.questiontrainlistId}
                                >
                                    {listTrain.map((item, key) => (
                                        <option key={key} value={item.key}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt'
                                loading={isPress}
                                onClick={this.train}
                            >
                                Luyện ngay
                            </Button>
                        </div>
                        <div className='mgt' id='notificationStartQuestionTrain' />
                        <div className='item-center mgt-2 mgb-2'>
                            <i>Bạn có thể kém mọi thứ nhưng riêng với tính tiền là phải nhảy số nhanh</i>
                        </div>
                        <div className='item-center'>
                            <iframe
                                src="https://www.youtube.com/embed/OSMp5HfoYKU?start=1"
                                frameborder="0"
                                allow="encrypted-media"
                                allowFullScreen="allowFullScreen"
                                style={{ width: '100%', height: '50vh', borderRadius: '2vh' }}
                            />
                            <p className='text-center'><i>Hướng dẫn giải toán khởi nghiệp bằng Google Sheet / Excel</i></p>
                        </div>
                        <InfiniteScroll
                            loadMore={this.loadMoreList}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={10}
                        >
                            {questionList.map((item, key) => (
                                <div className='container-list-question mgt' key={key}>
                                    <div className='flex-row'>
                                        <div style={{ flex: 1 }}>
                                            <p className='text-center'>Mã câu hỏi</p>
                                            <p className='text-center'>{item.QuestionLogicId}</p>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <p className='text-center'>Kết quả</p>
                                            <p className='text-center'>{item.IsCorrect == 0 ? 'Sai' : 'Đúng'}</p>
                                        </div>
                                    </div>
                                    <div className='flex-column item-center mgt'>
                                        <p className={'answer-vote ' + (item.IsCorrect == 0 ? 'bg-danger' : 'bg-success')}>
                                            {`+ ${priceFormat(item.Balance)} ${item.BalanceType == 1 ? '$[z]' : 'đ[z]'}`}
                                        </p>
                                        <p className='placeholder'>{item.CrDateTime}</p>
                                    </div>
                                </div>
                            ))}
                        </InfiniteScroll>
                    </div>
                    :
                    <div className='animated fadeInUp faster'>
                        <p className='title-question'>{this.questionTrain.QuestionTitle}</p>
                        <div className='count-down-question-container'>
                            <p className={'count-down-question ' + (this.timeDiff <= 10 ? 'danger' : 'success')}>{textTime}</p>
                        </div>
                        {this.questionTrain.answers.map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    onClick={() => this.saveAnswer(value, key, this.questionTrain.QuestionTempId)}
                                >
                                    <TextAnswer
                                        answer={value}
                                        keySelect={key}
                                        selectAnswerKey={selectAnswerKey}
                                        correctAnswer={this.correctAnswer}
                                    />
                                </div>
                            )
                        })}
                        {resultAnswer !== false && (
                            <ResultMessage resultAnswer={resultAnswer} />
                        )}
                        <div className='notificationQuestionTrain'></div>
                        {this.state.resultAnswer == 1 &&
                            <Lottie
                                options={{ loop: true, autoplay: true, animationData: firework }}
                                width='50vh'
                                style={{ position: 'absolute', top: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0 }}
                            />
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { listTrain, isLoadingSelect } = state.getQuestionTrain;
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { listTrain, isLoadingSelect, dataUser, countMission };
}

export default connect(mapStateToProps, { changePoint, getQuestionTrainSelect, updateCountMission, showModalEat })(QuestionTrain);