import { URI_PATH } from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListUserAffApi = (SearchText) => {
    let url = `${URI_PATH}app/aff/action/getListUserAff`;
    let body = {
        SearchText
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const requestUpgradeAffApi = (UpgradeType, TargetUserId) => {
    let url = `${URI_PATH}app/aff/action/requestUpgrade`;
    let body = {
        UpgradeType,
        TargetUserId
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDataUserZvipApi = (UserInvite) => {
    let url = `${URI_PATH}app/aff/action/getDataUserZvip`;
    let body = {
        UserInvite
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const requestDepositWithdrawApi = ({
    Balance,
    BankId,
    BankStk,
    BankBranch,
    Type
}) => {
    let url = `${URI_PATH}app/aff/action/requestDepositWithdraw`;
    let body = {
        Balance,
        BankId,
        BankStk,
        BankBranch,
        Type
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListBankApi = () =>{
    let url = `${URI_PATH}app/aff/action/getListBank`;
    return axios.get(url).then((res) => res.data);
}

export const getListLogApi = () =>{
    let url = `${URI_PATH}app/aff/action/getListLog`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDataUserAffApi = () =>{
    let url = `${URI_PATH}app/aff/action/getDataUserAff`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListInviteApi = () =>{
    let url = `${URI_PATH}app/aff/action/getListInvite`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const removeRequestApi = (UpgradeType) =>{
    let url = `${URI_PATH}app/aff/action/removeRequest`;
    let body = {
        UpgradeType,
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const removeRequestDepositApi = () =>{
    let url = `${URI_PATH}app/aff/action/removeRequestDeposit`;
    let body = {}
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}