import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getCategoryApi = (categoryType) =>{
    let url = `${URI_PATH}app/category/action/getCategory`;
    let body = {
        CategoryType: categoryType
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getSubCategoryApi = (CategoryId, UserId) =>{
    let url = `${URI_PATH}app/category/action/getSubCategory`;
    let body = {
        CategoryId,
        UserId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateSubCategoryApi = (CategoryId, SubCategoryName, SubCategoryId) =>{
    let url = `${URI_PATH}app/category/action/updateSubCategory`;
    let body = {
        CategoryId,
        SubCategoryName,
        SubCategoryId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteSubCategoryApi = (SubCategoryId) =>{
    let url = `${URI_PATH}app/category/action/deleteSubCategory`;
    let body = {
        SubCategoryId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}