import iziToast from 'izitoast';
import {URI_PATH, MESSAGE, COLOR} from './constants';
import {priceFormat, getAvatarFb, formatDecimal} from './common';
import '../assets/css/iziToast.css';
import {joinRoomApi} from '../api/question/fight/lobby';

const getTypeNotification = (type) =>{
    let typeText = 'error';
    if (type == 1) typeText = 'success';
	if (type == 2) typeText = 'warning';
    if (type == 3) typeText = 'info';
    return typeText;
}

export const showNotification = (msg, type, id='#check') => {
	let toastId = document.querySelector(id) ? id : '';
    let typeNotification = getTypeNotification(type);
    iziToast[typeNotification]({
		timeout: 3000,
		title: '',
		message: msg,
		position: 'bottomRight',
		titleColor:'white',
        messageColor: 'white',
		target: toastId,
		zindex: 9999999999999,
		pauseOnHover: false
	});
}

export const showNotificationCenter = (msg, type) =>{
    let typeNotification = getTypeNotification(type);
    iziToast[typeNotification]({
		timeout: 1200,
		title: '',
		message: msg,
		position: 'center',
		titleColor:'white',
        messageColor: 'white',
		zindex: 99999,
		pauseOnHover: false
	});
}

export const showNotificationBonus = (msg, type, bonusType) => {
	if(msg <= 0) return;
    let typeText = 'warning';
	if(bonusType == 2 || bonusType == 3) msg = priceFormat(msg);
	else if(bonusType == 4) msg = Math.round(msg * 100) / 100;
	let bonus = {
        1 : ' Gold[z]',
        2 : ' $[z]',
        3 : ' đ[z] ',
        4 : ' điểm thành tựu',
        5 : ' điểm thương hiệu',
        6 : ' điểm lãnh đạo',
        7 : ' điểm quản trị',
        8 : ' điểm quan hệ',
        9 : ' điểm tài chính',
        10 : ' điểm ngoại ngữ',
        11 : ' điểm chuyên môn'
	}
	let stick = '+ ';
	if(type != 1) stick = '- ';
    if (type == 1) typeText = 'success';
	if (type == 2) typeText = 'warning';
	if (type == 3) typeText = 'info';
    iziToast[typeText]({
		title: '',
		timeout: 3000,
		message: stick + msg + bonus[bonusType],
		position: 'topRight',
		titleColor: 'white',
		messageColor: 'white',
		zindex: 99999,
		pauseOnHover: false
	});
}

export const showNotificationProperty = property =>{
	let i = 5;
	Object.keys(property).forEach(function(key) {
		i++;
		showNotificationBonus(property[key], 1, i);
	});
}

export const showNotificationAsset = assetInfo =>{
	if(Object.keys(assetInfo).length > 0){
		showNotificationAvatar({
			fullName: '+1',
			message: assetInfo.AssetName,
			assetImg: assetInfo.AssetImage
		});
	}
}

export const showNotificationAvatar = (data) =>{
	let fullName = data.fullName;
	let message = data.message;
	let avatar = URI_PATH + 'assets/imgZ/facebook-avatar.png';
	if(data.facebookId) avatar = getAvatarFb(data.facebookId);
	if(data.assetImg) avatar = URI_PATH + data.assetImg;
	// if(data.isNotify == 2) {
	// 	let countNotify = parseInt($('#spanNotificationHeaderCount').text()) + 1;
	// 	$('#spanNotificationHeaderCount').text(countNotify).show();
	// }
	iziToast.show({
		timeout: 3000,
		message: fullName + ' ' + message,
		messageColor: 'white',
		backgroundColor: '#4b5056',
		position: 'topRight',
		progressBarColor: '#06A65A',
		image: avatar,
		imageWidth: 50,
	});
}

export const showNotificationMessage = (data, action) =>{
	if(data.isImage != 0) data.message = "Đã gửi 1 ảnh";
	iziToast.show({
		class: 'notification-message-' + data.userId,
		title: data.fullName + ':',
		message: data.message,
		image: getAvatarFb(data.facebookId),
		position: 'topRight',
		backgroundColor: '#4b5056',
		titleColor: 'white',
		messageColor: 'white',
		timeout: 5000,
		progressBar: false,
		buttons: [
			['<button style="color: white; background: #06A65A">Trả lời</button>', action]
		],
	});
}

export const showNotificationFightMessage = (data) =>{
	iziToast.show({
		title: '',
		message: data.Message,
		image: getAvatarFb(data.FacebookId),
		position: 'topRight',
		backgroundColor: '#4b5056',
		titleColor: 'white',
		messageColor: 'white',
		timeout: 3000,
		progressBar: false,
	});
}

export const showNotificationFight = (data, history) =>{
	let questionLevelTitle = data.QuestionLevelTitle;
	let betValue = formatDecimal(data.BetValue);
	let betType = data.BetType;
	let betTitle;
	if(betType == 1) betTitle = ' Gold[z]';
	if(betType == 2) betTitle = ' $[z]';
	if(betType == 3) betTitle = ' đ[z]';
	iziToast.show({
		id: 'notificationInviteFight',
		timeout: 20000, //20s
		backgroundColor: '#4b5056',
		messageColor: 'white',
		message: data.FullName + ' mời bạn vào phòng thách đấu '+ questionLevelTitle +'. Cược ' + betValue + betTitle,
		position: 'bottomCenter',
		image: getAvatarFb(data.FacebookId),
		pauseOnHover: false,
		balloon: true,
		progressBarColor: 'green',
		buttons: [
			[
				'<button style="color: white; background: #06A65A">Đồng ý</button>',
				function (instance, toast) {
					joinRoomApi(data.QuestionFightRoomId).then(res =>{
						if(res.code == 1){
							history.push('/question-fight/room?id=' + data.QuestionFightRoomId);
						}
						else showNotification(res.message, res.code);
					}).catch(e =>{
						showNotification(MESSAGE.ERROR, 0);
					});
					instance.hide({
						transitionOut: 'fadeOutUp',
					}, toast, '');
				}
			],
			[
				'<button style="color: white; background: #DD4B38">Từ chối</button>',
				function (instance, toast) {
					instance.hide({
						transitionOut: 'fadeOutUp',
					}, toast, '');
				}
			]
		],
	});
}

export const hideToast = (id = '#check') =>{
	let toastId = document.querySelector(id);
	if(toastId){
		iziToast.hide({
			transitionOut: 'fadeOutUp'
		}, toastId);
	}
}

export const showErrorZcrazy = () =>{
	showNotification('Tính năng này chỉ dành cho học viên', 0)
}

export const showNoificationDameBoss = (user, dame, isCrit = false) =>{
	let avatar = URI_PATH + 'assets/imgZ/facebook-avatar.png';
	if(user.FacebookId) avatar = getAvatarFb(user.FacebookId);
	if(user.assetImg) avatar = URI_PATH + user.assetImg;
	iziToast.show({
		timeout: 5000,
		message: `- ${formatDecimal(`${dame}`)} HP`,
		messageColor:  isCrit ? '#00d3ff' : 'white',
		messageSize: isCrit ? 20 : '',
		backgroundColor: COLOR.COLOR_DANGER,
		position: 'topRight',
		progressBarColor: COLOR.COLOR_WARNING,
		image: avatar,
		imageWidth: 50,
	});
}

export const showNoificationWinBoss = (data) =>{
	if(data?.UserId > 0){
		let avatar = URI_PATH + 'assets/imgZ/facebook-avatar.png';
		if(data.FacebookId) avatar = getAvatarFb(data.FacebookId);
		if(data.assetImg) avatar = URI_PATH + data.assetImg;
		iziToast.show({
			timeout: 5000,
			message: `Chúc mừng ${data.FullName} đã giải cứu thành công ${data.FoodName}. Thật dũng mãnh!`,
			messageColor: 'white',
			backgroundColor: '#4b5056',
			position: 'bottomCenter',
			image: avatar,
			imageWidth: 50,
		});
	}
}