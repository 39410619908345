import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListLessonDoingOrPassApi, getListReferApi } from '../../api/study/lesson';
import { showNotification } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import { Button, Modal, Tooltip } from 'antd';
import LessonShow from '../../components/study/componentChildren/LessonShow';
import { getAvatarFb, isTouch } from '../../utils/common';
import { getDataSummaryApi } from '../../api/study/summary';
import StarRatings from 'react-star-ratings';
import Loading from '../../components/Loading';
import { getCategory } from '../../actions/category';
import { getListClassSelectApi } from '../../api/study/class';
import TooltipUser from '../../components/TooltipUser';
import Icon from 'react-fontawesome';
import { TitleSuccess, EmptyLottie } from '../../components/frame';
import { followApi } from '../../api/user';
const ButtonGroup = Button.Group;

class UserSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listLesson: [],
            listLessonShow: [],
            dataStudent: {},
            selectCategoryLesson: 1,
            isLoadingLesson: true,
            showModal: false,
            modalType: 1,
            studyStudentId: '',
            dataUser: {},
            listClassSelect: [],
            studyClassIdSelect: '',
            isLoadingSelectClass: false,
            listRefer: [],
            isLoadingRefer: true,
            isPreview: false,
            textSearch: '',
            studyAdminId: ''
        }
        this.userId = this.props.userId || this.props.dataUser.UserId;
    }

    buttonGroupClass = (selectCategoryLesson) => {
        if (selectCategoryLesson == this.state.selectCategoryLesson) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    getDataSummary = (categoryId = 1, studyClassId) => {
        this.setState({
            selectCategoryLesson: categoryId,
            listLesson: this.state.selectCategoryLesson == categoryId ? this.state.listLesson : []
        })
        getDataSummaryApi(categoryId, studyClassId, this.userId).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listLesson: res.data,
                    dataStudent: res.student,
                    dataUser: res.user,
                    isLoadingSelectClass: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0)
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    showModalLesson = (studyStudentId, userId, status) => {
        this.setState({
            showModal: true,
            modalType: 1,
            isLoadingLesson: true,
            studyStudentId,
            isPreview: userId > 0
        })
        let userIdSupport = '';
        if (this.props.isSupport || userId) userIdSupport = userId || this.userId
        const statusLesson = (status == '0' && this.props.isSupport) ? 1 : 2;
        getListLessonDoingOrPassApi(statusLesson, studyStudentId, userIdSupport).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoadingLesson: false,
                    listLessonShow: res.lessons
                })
            }
            else showNotification(MESSAGE.ERROR, 0)
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    getListClassSelect = () => {
        let userIdSupport = '';
        if (this.props.isSupport) userIdSupport = this.userId
        getListClassSelectApi(userIdSupport).then(res => {
            if (res.code == 1) {
                this.setState({
                    listClassSelect: res.data
                })
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    selectClass = (e) => {
        this.setState({
            studyClassIdSelect: e.target.value,
            isLoadingSelectClass: true
        })
        this.getDataSummary(1, e.target.value)
    }

    getListUserRefer = (studyAdminId) => {
        getListReferApi(studyAdminId, this.state.textSearch).then(res => {
            if (res.code == 1) {
                this.setState({
                    listRefer: res.data,
                    isLoadingRefer: false
                })
            }
            else showNotification(res.message, res.code)
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    referStudy = (e, studyAdminId) => {
        e && e.stopPropagation();
        this.getListUserRefer(studyAdminId);
        this.setState({
            showModal: true,
            modalType: 2,
            isLoadingRefer: e ? true : false,
            studyAdminId
        })
    }

    changeTextSearch = (e) =>{
        const textSearch = e.target.value
        this.setState({
            textSearch
        })
        clearTimeout(this.timeoutSearch)
        this.timeoutSearch = setTimeout(() => {
            this.referStudy(null, this.state.studyAdminId)
        }, 200);
    }

    follow = (event, userId, studyStudentId) =>{
        event.stopPropagation()
        followApi(userId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listRefer: this.state.listRefer.map(item =>{
                        if(item.StudyStudentId == studyStudentId){
                            return{
                                ...item,
                                FollowId: res.action
                            }
                        }
                        return item
                    })
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0))
    }

    renderModal = () => {
        const {
            isLoadingLesson,
            listLessonShow,
            modalType,
            studyStudentId,
            isLoadingRefer,
            listRefer,
            isPreview,
            textSearch
        } = this.state;
        switch (modalType) {
            case 1:
                return (
                    <div className='mgt-2'>
                        <LessonShow
                            listLesson={listLessonShow}
                            isLoading={isLoadingLesson}
                            onRefresh={() => {
                                this.setState({showModal: false})
                                setTimeout(() =>{
                                    window.location.reload()
                                }, 500)
                            }}
                            type={(this.props.isSupport || isPreview) ? 3 : 2}
                            isPreview={isPreview}
                            isSupport={this.props.isSupport}
                        />
                    </div>
                )
            case 2:
                return (
                    <>
                        {isLoadingRefer ?
                            <Loading small />
                            :
                            <>
                                <TitleSuccess>Chọn người tham khảo</TitleSuccess>
                                <input
                                    className='form-control text-center'
                                    placeholder='Nhập họ và tên'
                                    value={textSearch}
                                    onChange={this.changeTextSearch}
                                />
                                {listRefer.length > 0 ?
                                    <>
                                        {listRefer.map((item, key) =>
                                            <div
                                                className='box-primary flex align-center pointer'
                                                key={key}
                                                onClick={() => this.showModalLesson(item.StudyStudentId, item.UserId)}
                                            >
                                                <div className='flex-row flex-1'>
                                                    <div style={{ position: 'relative', alignItems: 'center', display: 'flex' }}>
                                                        <img 
                                                            src={item.FollowId ? require('../../assets/image/follow-on.png') : require('../../assets/image/follow-off.png')}
                                                            className='tooltip-user-icon pointer'
                                                            onClick={(e) => this.follow(e, item.UserId, item.StudyStudentId)}
                                                        />
                                                    </div>
                                                    <TooltipUser userInfo={item} />
                                                    <div className='mgl-2'>
                                                        <p className='mgb-0'>{item.FullName}</p>
                                                        <p className='placeholder mgb-0'>Ngày hoàn thành: {item.PublishDateTime}</p>
                                                    </div>
                                                </div>
                                                <p className='mgb-0'>
                                                    <StarRatings
                                                        rating={parseFloat(item.Point || 0)}
                                                        starRatedColor='white'
                                                        starEmptyColor='rgba(255,255,255,0.2)'
                                                        numberOfStars={5}
                                                        name='rating'
                                                        starDimension='2vh'
                                                        starSpacing='0.2px'
                                                    />
                                                </p>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <EmptyLottie title='Danh sách người tham khảo trống' />
                                }
                            </>
                        }
                    </>
                )
        }
        return null
    }

    renderStatus = (data) => {
        switch (data.Status) {
            case "0":
                return <p>Đang học</p>
            case "1":
                return <p>Đang chấm</p>
            case "2":
                return (
                    <p className='mgb-0'>
                        <StarRatings
                            rating={parseFloat(data.Point || 0)}
                            starRatedColor='white'
                            starEmptyColor='rgba(255,255,255,0.2)'
                            numberOfStars={5}
                            name='rating'
                            starDimension='2vh'
                            starSpacing='0.2px'
                        />
                    </p>
                )
        }
        return null
    }

    componentDidMount() {
        this.getListClassSelect();
        this.getDataSummary();
        if (this.props.categoryPost.length == 0) this.props.getCategory(1)
    }

    render() {
        const {
            listLesson,
            dataStudent,
            isLoading,
            showModal,
            dataUser,
            isLoadingSelectClass,
            listClassSelect,
            studyClassIdSelect,
            modalType,
            isPreview
        } = this.state;
        const { categoryPost, isGuest } = this.props;
        if (isLoading) return <Loading />
        return (
            <>
                <select
                    className='form-control mgt-2'
                    value={studyClassIdSelect}
                    onChange={this.selectClass}
                >
                    <option value=''>Tất cả môn học</option>
                    {listClassSelect.map((item, key) =>
                        <option key={key} value={item.StudyClassId}>{item.Subject}</option>
                    )}
                </select>
                {isLoadingSelectClass ?
                    <Loading small />
                    :
                    <>
                        <div className='item-center mgt'>
                            <TooltipUser userInfo={{ ...dataUser, UserId: this.userId }} avatarClass='avatar-guest-large' />
                            <p className='mgt'>{dataUser.FullName}</p>
                            <p>
                                <span>
                                    <StarRatings
                                        rating={parseFloat(dataStudent.AvgPoint || 0)}
                                        starRatedColor='white'
                                        starEmptyColor='rgba(255,255,255,0.2)'
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension='2vh'
                                        starSpacing='0.2px'
                                    />
                                    ({dataStudent.AllLesson || 0}/{dataStudent.AllLessonAdmin || 0})
                                </span>
                            </p>
                        </div>
                        <div className="row mgt">
                            <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                                {categoryPost.map((item, key) =>
                                    <Button
                                        className={this.buttonGroupClass(item.CategoryId)}
                                        onClick={() => this.getDataSummary(item.CategoryId, studyClassIdSelect)}
                                        key={key}
                                    >
                                        {item.CategoryName}
                                    </Button>
                                )}
                            </ButtonGroup>
                        </div>
                        <div className='row box-primary'>
                            <div className='col-md-3 col-sm-3 col-xs-3'>
                                <p className='mgb-0 text-center'>Chủ đề</p>
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-6'>
                                <p className='mgb-0 text-center'>Bài học</p>
                            </div>
                            <div className='col-md-3 col-sm-3 col-xs-3'>
                                <p className='mgb-0 text-center'>Điểm</p>
                            </div>
                        </div>
                        {listLesson.map((item, key) =>
                            <div
                                className='row box-primary pointer'
                                key={key}
                                onClick={() => ((!isGuest && item.Status == 2) || this.props.isSupport) && this.showModalLesson(item.StudyStudentId, '', item.Status)}
                            >
                                <div className='col-md-3 col-sm-3 col-xs-12 item-center'>
                                    <p className='mgb-0'>{item.SubCategoryName}</p>
                                </div>
                                <div className='col-md-6 col-sm-6 col-xs-12 item-center'>
                                    <p className='mgb-0'>{item.LessonTitle}</p>
                                </div>
                                <div className='col-md-3 col-sm-3 col-xs-12 item-center flex'>
                                    {this.renderStatus(item)}
                                    {!isGuest && ["1", "2"].includes(item.Status) &&
                                        <Tooltip
                                            overlayClassName='tooltip-user'
                                            placement="top"
                                            trigger={isTouch() ? 'click' : 'hover'}
                                            title={
                                                <p>Tham khảo bài của người khác</p>
                                            }
                                            onClick={e => this.referStudy(e, item.StudyAdminId)}
                                        >
                                            <Icon
                                                name='globe'
                                                style={{ marginLeft: '2vh', fontSize: '3.5vh' }}
                                            />
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        )}
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={modalType == 1 ? '120vh' : '80vh'}
                >
                    {isPreview &&
                        <Icon
                            name='chevron-left'
                            className='pointer'
                            style={{ fontSize: '3vh', position: 'absolute', top: '2vh' }}
                            onClick={() => this.setState({ modalType: 2, isPreview: false })}
                        />
                    }
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost } = state.getCategory;
    return { dataUser, categoryPost };
}

export default connect(mapStateToProps, { getCategory })(UserSummary);