import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Switch } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { TitleSuccess } from '../../components/frame';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getContentApi, getValueInvestApi, saveContentApi, saveDiscordLinkApi, saveValueInvestApi } from '../../api/admin/zcrazy';
import ButtonGroup from 'antd/lib/button/button-group';
import { getDiscordLinkApi } from '../../api/menu';
import ReactDatePicker from 'react-datepicker';
import { deleteAudioApi, editAudioApi, uploadAudioApi } from '../../api/admin/audio';
import moment from 'moment';
import { getListAudioApi } from '../../api/admin/audio';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            selectTab: 1,
            inputTime: '',
            inputTitle: '',
            inputFile: undefined,
            isQueue: true,
            isPress: false,
            listAudio: [],
            selectAudio: {},
            audioTitle: ''
        }
        this.audioRef = React.createRef();
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    changeAudioFile = (event) => {
        const inputFile = event.target.files[0];
        this.setState({ inputFile })
    }

    save = () => {
        const { inputTime, inputTitle, inputFile, isQueue, isPress } = this.state;
        if (!isPress) {
            if (!inputTitle || ((!inputFile || !inputTime) && isQueue)) {
                return showNotification('Các trường không được để trống', 0);
            }
            this.setState({ isPress: true });
            const timeQueue = isQueue ? moment(inputTime).format("YYYY-MM-DD HH:mm") : '';
            uploadAudioApi(inputTitle, isQueue ? 2 : 0, timeQueue, inputFile).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({
                        inputTime: '',
                        inputTitle: '',
                        inputFile: undefined,
                    })
                    this.refs.inputAudioFile.value = "";
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    deleteAudio = (item) => {
        if (window.confirm('Xác nhận xóa podcast này')) {
            deleteAudioApi(item.AudioId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.getListAudio();
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    edit = () => {
        const { selectAudio, audioTitle, isPress } = this.state;
        if (!isPress) {
            this.setState({ isPress: true });
            editAudioApi(selectAudio.AudioId, audioTitle).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({
                        showModal: false,
                        selectAudio: {},
                        audioTitle: ''
                    })
                    this.getListAudio();
                    this.audioRef.current.pause();
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    renderTab = () => {
        const { selectTab, inputTime, inputTitle, inputFile, isQueue, isPress, listAudio } = this.state;
        if (selectTab == 1) {
            return (
                <>
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2 flex flex-start align-center'>
                            <p className='text-left text-large mgr-2'>Đăng ngay: </p>
                            <Switch
                                checked={!isQueue}
                                onChange={() => this.setState({ isQueue: !isQueue })}
                            />
                        </div>
                    </div>
                    {isQueue &&
                        <div className='item-center row'>
                            <div className='col-md-4 col-sm-12 col-xs-12 mgt-2'>
                                <p className='text-left text-large'>Thời gian phát:</p>
                                <div className='flex align-center date-study-picker'>
                                    <ReactDatePicker
                                        selected={inputTime}
                                        onChange={inputTime => this.setState({ inputTime })}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="time"
                                        dateFormat="d-M-yyyy H:mm"
                                        showTimeSelect
                                        minDate={Date.now()}
                                        placeholderText='Chọn thời gian phát'
                                        className='text-left'
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2'>
                            <p className='text-left text-large'>Tên podcast:</p>
                            <input
                                className='form-control'
                                value={inputTitle}
                                onChange={e => this.setState({ inputTitle: e.target.value })}
                                placeholder='Nhập tên podcast'
                            />
                        </div>
                    </div>
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2 flex-column flex-start'>
                            <p className='text-left text-large'>{inputFile?.name}</p>
                            <Button className='bg-primary mgt'
                                onClick={() => this.refs.inputAudioFile.click()}
                            >
                                Chọn File
                            </Button>
                            <input
                                type='file'
                                accept="audio/*"
                                hidden='hidden'
                                ref='inputAudioFile'
                                onChange={this.changeAudioFile}
                            />
                        </div>
                    </div>
                    <div className='item-center mgt-3'>
                        <Button
                            className='bg-success mgt'
                            onClick={this.save}
                            loading={isPress}
                        >
                            Lưu
                        </Button>
                    </div>
                </>
            )
        }
        return (
            <>
                {listAudio.map((item, index) =>
                    <div className='box-primary flex space-between align-center' key={index}>
                        <div>
                            <p className='text-left text-large'>{item.AudioTitle}</p>
                            {selectTab == 2 &&
                                <p className='text-left text-large'>Công chiếu: {item.QueueTime}</p>
                            }
                        </div>
                        <div className='flex'>
                            <img
                                src={require('../../assets/image/pen.png')}
                                style={{ width: '6vh' }}
                                className='pointer mgl'
                                onClick={() => this.setState({
                                    selectAudio: item,
                                    showModal: true,
                                    audioTitle: item.AudioTitle
                                })}
                            />
                            <img
                                src={require('../../assets/image/delete.png')}
                                style={{ width: '6vh' }}
                                className='pointer mgl'
                                onClick={() => this.deleteAudio(item)}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }

    getListAudio = () => {
        this.setState({ listAudio: [] })
        getListAudioApi(this.state.selectTab == 2 ? 2 : 0).then(res => {
            this.setState({ listAudio: res.data });
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    render() {
        const { showModal, audioTitle, selectAudio, isPress } = this.state;
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({ selectTab: 1 })} className={this.buttonGroupClass(1)}>Đặt lịch podcast</Button>
                    <Button onClick={() => this.setState({ selectTab: 2 }, this.getListAudio)} className={this.buttonGroupClass(2)}>Danh sách chờ</Button>
                    <Button onClick={() => this.setState({ selectTab: 3 }, this.getListAudio)} className={this.buttonGroupClass(3)}>Danh sách podcast</Button>
                </ButtonGroup>
                {this.renderTab()}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => {
                        this.setState({
                            showModal: false,
                        })
                        this.audioRef.current.pause()
                    }}
                    centered
                >
                    <TitleSuccess>Sửa podcast</TitleSuccess>
                    <input
                        className='form-control mgt'
                        value={audioTitle}
                        onChange={e => this.setState({ audioTitle: e.target.value })}
                        placeholder='Nhập tên podcast'
                    />
                    <div className='item-center mgt-2'>
                        <audio
                            src={selectAudio.AudioLink}
                            autoPlay={false}
                            controls
                            className='full-width'
                            ref={this.audioRef}
                        />
                    </div>
                    <div className='item-center mgt-3'>
                        <Button
                            className='bg-success mgt'
                            onClick={this.edit}
                            loading={isPress}
                        >
                            Lưu
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)