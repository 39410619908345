import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BarSelect from '../../components/frame/BarSelect';
import Icon from 'react-fontawesome';
import Menu from '../../components/study/menu/Main';
import LessonStudent from '../../components/study/LessonStudent';
import { isMaster, isStudent, isTeacher } from '../../utils/common';
import LessonTeacher from '../../components/study/LessonTeacher';
import LessonMaster from '../../components/study/LessonMaster';
import StudyZAlpha from '../../components/study/StudyZAlpha';
import { updateStateNavigate } from '../../actions/menu';

class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelect: this.getTabSelect()
        }
    }

    getTabSelect = () =>{
        switch (true) {
            case isMaster():
                return 3;
            case isTeacher():
                return 2;
        }
        return 1;
    }

    renderTab = () => {
        switch (this.state.tabSelect) {
            case 1:
                return <LessonStudent />
            case 2:
                return <LessonTeacher />
            case 3:
                return <LessonMaster />
            // case 4:
            //     return <StudyZAlpha />
            // case 5:
            //     return <Menu />
        }
    }

    setTab = (tabSelect) =>{
        this.setState({ tabSelect })
        this.props.updateStateNavigate({studyTab: tabSelect})
    }

    renderNavigate = () => (
        <BarSelect>
            {isMaster() &&
                <Icon
                    name='pencil-square-o'
                    className={'pv-navigate ' + (this.state.tabSelect == 3 && 'success')}
                    style={{ fontSize: '6vh', margin: '0.3vh 1.5vh 0vh 1.5vh', bottom: '0.2vh' }}
                    onClick={() => this.setTab(3)}
                />
            }
            {isTeacher() &&
                <Icon
                    name='life-ring'
                    className={'pv-navigate ' + (this.state.tabSelect == 2 && 'success')}
                    style={{ fontSize: '5vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh' }}
                    onClick={() => this.setTab(2)}
                />
            }
            {isStudent() &&
                <Icon
                    name='leanpub'
                    className={'pv-navigate ' + (this.state.tabSelect == 1 && 'success')}
                    style={{ fontSize: '5vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh' }}
                    onClick={() => this.setTab(1)}
                />
            }
            <Icon
                name='square'
                className={'pv-navigate'}
                style={{ fontSize: '5vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh' }}
                onClick={() => this.props.history.push('study?menu=1')}
            />
        </BarSelect>
    )

    render() {
        return (
            <>
                {this.renderTab()}
                <div style={{height: '15vh'}} />
                {(isStudent() || isTeacher() || isMaster()) && this.renderNavigate()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { studyTab } = state.getStateNavigate;
    return { dataUser, studyTab };
}

export default connect(mapStateToProps, {updateStateNavigate})(withRouter(Root));