const INITAL_STATE = {
    showSearch: false,
    loadingSearch: true,
    listSearchResult: [],
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_HIDE_SEARCH':
            return {...state,
                showSearch: action.showSearch
            }
        default:
            return state;
    }
};