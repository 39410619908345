import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getAvatarFb, formatDecimal, priceFormat, getImage64, isNumber, getDecimalCount, replaceCost } from '../../../utils/common';
import { showNotification } from '../../../utils/notification';
import { MESSAGE, URI_PATH, KEY, PROP, COLOR, TICKET } from '../../../utils/constants';
import { updateStatusApi, removeAssetApi } from '../../../api/home';
import { updateDataUser, changePoint, getUserItem, getUserBar } from '../../../actions/home';
import { updateStatusNewfeed } from '../../../actions/newfeed';
import { Button, Modal, Tooltip, Slider } from 'antd';
import ClubVipLevel from '../../club/ClubVipLevel';
import ClubDeputy from '../../club/ClubDeputy';
import { toogleUserBar } from '../../../actions/userbar';
import VipProgress from '../../home/VipProgress';
import Cookies from 'js-cookie';
import { getTimeBuyTicketApi } from '../../../api/gift/ticket';
import Loading from '../../Loading';
import { TitleSuccess } from '../../frame';
import { uploadAvatarApi } from '../../../api/user';
import Checked from '../../frame/Checked';
import Cropper from 'react-easy-crop'
import { getTimeGuideAction } from '../../../actions/menu'
import Countdown, { zeroPad } from 'react-countdown-now';
import { getTimePresentApi } from '../../../api/present';
import moment from 'moment';
import { addOrderApi, getPricePayApi } from '../../../api/admin/study/ticket';
import TooltipUser from '../../TooltipUser';
import { Subscription } from "rxjs";
import EventBus, { EventBusName } from '../../../services/event-bus';
const axios = require('axios');

class UserBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            inputStatus: '',
            isPress: false,
            ticketData: {},
            showModal: false,
            modalType: 1,
            imageAvatar: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            croppedAreaPixels: null,
            isPress: false,
            inputZticket: "",
            calcBalance: "",
            balancePay: 0,
            codePay: ''
        }
        this.lastStatus = false
        this.subScription = new Subscription();
    }

    onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res) => {
            if (res.type === EventBusName.BUY_ZTICKET) {
                this.getPricePay();
                this.setState({ showModal: true, modalType: 5 })
            }
        });
        this.subScription.add(sub);
    };

    updateStatus = () => {
        if (!this.state.isPress) {
            let inputStatus = this.state.inputStatus.trim().replace(/(?:\r\n|\r|\n)/g, '');
            this.setState({ isPress: true });
            updateStatusApi(inputStatus).then((res) => {
                showNotification(res.message, res.code, '#notificationUserStatus');
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        inputStatus: ''
                    });
                    this.props.updateStatusNewfeed(res.newfeedId, this.props.dataUser, inputStatus);
                }
                else this.setState({ isPress: false });
            }).catch((e) => {
                showNotification(MESSAGE.ERROR, -1, '#notificationUserStatus');
                this.setState({ isPress: false, });
            });
        }
    }

    removeAsset = assetId => {
        if (!this.state.isPress) {
            this.setState({ isPress: true })
            removeAssetApi(assetId).then(res => {
                if (res.code == 1) {
                    let { dataUser, selectItem } = this.props;
                    this.props.changePoint([null, null, null, -(dataUser.Achievement - res.achievement)]);
                    this.props.updateDataUser('UserVip', res.userVip)
                    this.props.getUserBar();
                    if (selectItem == 1) this.props.getUserItem(1); // reload asset item
                }
                else showNotification(res.message, res.code);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    navigate = (link) => {
        this.props.history.push(link);
        this.props.toogleUserBar();
    }

    logOut = () => {
        Cookies.remove(KEY.TOKEN);
        axios.defaults.headers.common['Auth'] = '';
        window.location = '/';
    }

    getTicketData = () => {
        if (Object.keys(this.state.ticketData).length == 0) {
            getTimeBuyTicketApi().then(res => {
                if (res.code == 1) this.setState({
                    ticketData: res.ticketData
                });
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(e => showNotification(MESSAGE.ERROR, 0));
        }
    }

    _changeAvatar = (event) => {
        let file = event.target.files[0];
        getImage64(file, image64 => {
            this.setState({
                showModal: true,
                modalType: 1,
                imageAvatar: image64
            })
        });
        this.refs.inputAvatarUser.value = '';
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })

    getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180
    }

    rotateSize(width, height, rotation) {
        const rotRad = this.getRadianAngle(rotation)
        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    _uploadAvatar = async () => {
        const image = await this.createImage(this.state.imageAvatar)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return null
        }
        const rotRad = this.getRadianAngle(0)

        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(
            image.width,
            image.height,
            0
        )
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(1, 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        ctx.drawImage(image, 0, 0)

        const data = ctx.getImageData(
            this.state.croppedAreaPixels.x,
            this.state.croppedAreaPixels.y,
            this.state.croppedAreaPixels.width,
            this.state.croppedAreaPixels.height
        )

        canvas.width = this.state.croppedAreaPixels.width
        canvas.height = this.state.croppedAreaPixels.height

        ctx.putImageData(data, 0, 0)

        canvas.toBlob((file) => {
            getImage64(file, image64 => {
                if (!this.state.isPress) {
                    this.setState({ isPress: true })
                    uploadAvatarApi(image64).then(res => {
                        if (res.code == 1) {
                            this.setState({
                                isPress: false,
                                showModal: false
                            })
                            this.props.updateDataUser('FacebookId', res.data);
                        }
                        else this.setState({ isPress: false })
                        showNotification(res.message, res.code)
                    }).catch(e => {
                        this.setState({ isPress: false })
                        showNotification(MESSAGE.ERROR, 0)
                    })
                }
            }, 250);
        }, 'image/jpeg')
    }

    getPricePay = () => {
        getPricePayApi().then(res => {
            if (res.code == 1) {
                this.setState({ balancePay: res.ticketVnd })
            }
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    chooseItemAdd = (link) => {
        if (!link) {
            this.getPricePay();
            this.setState({ modalType: 5 })
        }
        else {
            this.setState({
                showModal: false
            })
            this.props.history.push(link)
            this.props.toogleUserBar()
        }
    }

    changeZticket = (event) => {
        console.log(event.target.value);
        let num = event.target.value?.replace(/,|\./g, '');
        let calcBalance = '';
        if (num && isNumber(num)) {
            calcBalance = priceFormat(this.state.balancePay * num) + ' vnđ'
        }
        this.setState({
            inputZticket: num,
            calcBalance
        })
    }

    payZticket = () => {
        const { inputZticket } = this.state;
        const idNotification = '#notificationPayTicket';
        if (inputZticket == '' || inputZticket <= 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', 0, idNotification);
        if (getDecimalCount(inputZticket) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', 0, idNotification);
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            addOrderApi(this.state.inputZticket).then(res => {
                if (res.code == 1) {
                    this.setState({
                        modalType: 6,
                        isPress: false,
                        codePay: res.data
                    })
                }
                else {
                    showNotification(res.message, res.code)
                    this.setState({ isPress: false })
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0)
                this.setState({ isPress: false })
            });
        }
    }

    renderModal = () => {
        let { modalType, inputZticket, calcBalance, isPress, codePay } = this.state;
        let { listGuide, dataUser } = this.props;
        let listDataAdd = [];
        switch (modalType) {
            case 1:
                return (
                    <>
                        <TitleSuccess>Thay avatar</TitleSuccess>
                        <div className='mgt-3' style={{ height: 300, position: 'relative' }}>
                            <Cropper
                                image={this.state.imageAvatar}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={16 / 9}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                                cropShape='round'
                                cropSize={{ width: 250, height: 250 }}
                            />
                        </div>
                        <Slider
                            defaultValue={this.state.zoom}
                            onChange={value => this.setState({ zoom: value })}
                            step={0.1}
                            min={0.5}
                            max={2}
                            className='mgt-3'
                            tooltipVisible={false}
                        />
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt-3'
                                onClick={this._uploadAvatar}
                            >
                                Thay đổi
                            </Button>
                        </div>
                    </>
                )
            case 2:
                listDataAdd = listGuide.filter(item =>
                    (item.bonus.includes('đ[z]') && !item.bonus.includes('vnđ')) ||
                    item.bonus.includes('&') ||
                    item.title.includes('Đấu trường khởi nghiệp'))
                listDataAdd = [
                    ...listDataAdd,
                    // { title: 'Vote Kim Cương Bài viết', range: '24/7', image: require('../../../assets/image/diamond-white.gif'), link: '/menu/post' },
                    // { title: 'Vote Kim Cương Video', range: '24/7', image: require('../../../assets/image/diamond-white.gif'), link: '/menu/video' },
                ]
                return (
                    <>
                        <TitleSuccess>HOẠT ĐỘNG KIẾM TIỀN THƯỞNG Đ[Z]</TitleSuccess>
                        <div className='row mgt-2'>
                            {listDataAdd?.map((item, key) =>
                                <div
                                    className='col-md-4 col-sm-4 col-xs-12 item-center item-add-user-bar'
                                    key={key}
                                    onClick={() => this.chooseItemAdd(item.link)}
                                >
                                    <img src={item.image} style={{ width: '10vh' }} />
                                    <p className='mgt-2'>{item.title}</p>
                                    <p>{item.time == 'giftDay' ? this.state.presentTime : item.range}</p>
                                </div>
                            )}
                        </div>
                    </>
                )
            case 3:
                listDataAdd = listGuide.filter(item =>
                    item.title.includes('Quà thần bí') ||
                    item.title.includes('Quà tăng trưởng') ||
                    item.title.includes('Quà dậy sớm') ||
                    item.title.includes('Thị trường chứng khoán') ||
                    item.title.includes('Đấu trường khởi nghiệp') ||
                    item.title.includes('Toán khởi nghiệp')
                )
                listDataAdd = [
                    ...listDataAdd,
                    // { title: 'Đăng bài viết', range: '24/7', image: require('../../../assets/image/menu/publish-post.png'), link: '/menu/post-publish' },
                    // { title: 'Đăng video', range: '24/7', image: require('../../../assets/image/menu/publish-video.png'), link: '/menu/video-publish' },
                    { title: 'Vòng tròn học tập', range: '24/7', image: require('../../../assets/image/menu/charge-affiliate.png'), link: '/menu/charge-affiliate' },
                ]
                return (
                    <>
                        <TitleSuccess>HOẠT ĐỘNG KIẾM TIỀN THƯỞNG $[Z]</TitleSuccess>
                        <div className='row mgt-2'>
                            {listDataAdd?.map((item, key) =>
                                <div
                                    className='col-md-4 col-sm-4 col-xs-12 item-center item-add-user-bar'
                                    key={key}
                                    onClick={() => this.chooseItemAdd(item.link)}
                                >
                                    <img src={item.image} style={{ width: '10vh' }} />
                                    <p className='mgt-2'>{item.title}</p>
                                    <p>{item.time == 'giftDay' ? this.state.presentTime : item.range}</p>
                                </div>
                            )}
                        </div>
                    </>
                )
            case 4:
                listDataAdd = listGuide.filter(item =>
                    item.title.includes('Phá phong ấn') ||
                    item.title.includes('Chợ Gold[z]') ||
                    item.title.includes('Đấu trường khởi nghiệp')
                )
                listDataAdd = [
                    ...listDataAdd,
                    { title: 'Đấu thầu', range: '24/7', image: require('../../../assets/image/like.png'), link: '/menu/brand-online' },
                    { title: 'Nạp tiền mặt', range: '24/7', image: require('../../../assets/image/zticket.png') },
                ]
                return (
                    <>
                        <TitleSuccess>HOẠT ĐỘNG KIẾM TIỀN THƯỞNG GOLD[Z]</TitleSuccess>
                        <p className='text-center'>Gold[z] được chấp nhận dùng trong thanh toán học phí và gia hạn deadline bài học với giá trị:</p>
                        <p className='text-center'>1 Gold[z] = {formatDecimal(this.state.balancePay)} vnđ</p>
                        <p className='text-center'>Thông tin môn học xem tại <a href='https://kienthucnen.com' target='_blank'>https://kienthucnen.com</a></p>
                        <div className='row mgt-2'>
                            {listDataAdd?.map((item, key) =>
                                <div
                                    className='col-md-4 col-sm-4 col-xs-12 item-center item-add-user-bar'
                                    key={key}
                                    onClick={() => this.chooseItemAdd(item.link)}
                                >
                                    <img src={item.image} style={{ width: '10vh' }} />
                                    <p className='mgt-2'>{item.title}</p>
                                    {item.title == 'Phá phong ấn' ?
                                        <p>
                                            <Countdown
                                                date={item.timeQueue * 1000}
                                                renderer={({ days, hours, minutes, seconds }) => (<span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                            />
                                        </p>
                                        :
                                        <p>{item.time == 'giftDay' ? this.state.presentTime : item.range}</p>
                                    }
                                </div>
                            )}
                        </div>
                    </>
                )
            case 5:
                return (
                    <>
                        <p className={'mgt'}>
                            Nhập số Gold[z] cần nạp:
                        </p>
                        <input
                            className={'form-control text-center'}
                            value={`${inputZticket}`}
                            placeholder="Nhập số lượng"
                            onChange={this.changeZticket}
                        />
                        <p className={'mgt-3'}>
                            Thành tiền:
                        </p>
                        <input
                            className={'form-control text-center'}
                            value={calcBalance}
                            disabled
                        />
                        <div className='mgt' id='notificationPayTicket'></div>
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt-3'
                                onClick={this.payZticket}
                                loading={isPress}
                            >
                                Thanh toán
                            </Button>
                        </div>
                    </>
                )
            case 6:
                return (
                    <>
                        <div className='box-user-info-pay-ticket'>
                            <p>Mã đơn hàng: "{codePay}"</p>
                            <p>Số tài khoản: {dataUser.PhoneNumber}</p>
                            <p>Chủ tài khoản: {dataUser.FullName}</p>
                            <p>Mã lệnh đặt mua: {inputZticket} Gold[z]</p>
                            <p>Thành tiền: {calcBalance}</p>
                        </div>
                        <div className='box-info-pay-ticket'>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12 col-xs-12'>
                                    <p className='black text-center'>Chuyển khoản để hoàn tất đơn hàng</p>
                                    <p className='black'>Ngân hàng: <span className='black bold'>Techcombank</span></p>
                                    <p className='black'>Chủ Tài khoản: <span className='black bold'>Nguyễn Minh Ngọc</span></p>
                                    <p className='black'>Số Tài khoản: <span className='black bold'>7871888888</span></p>
                                    <p className='black'>Chi Nhánh: <span className='black bold'>Hoàng Gia</span></p>
                                    <p className='black mgt-3'>Nội dung chuyển khoản: <span className='black bold'>"{codePay}"</span></p>
                                </div>
                                <div className='col-md-6 col-sm-12 col-xs-12 flex item-center'>
                                    <img
                                        src={`https://img.vietqr.io/image/970407-7871888888-compact2.png?amount=${replaceCost(calcBalance, false)}&addInfo=${codePay}&accountName=NGUYEN%20MINH%20NGOC`}
                                        className='aff-qr-img'
                                    />
                                </div>
                            </div>

                            <p className='text-center'><i className='black'>Vui lòng ghi nội dung là mã đơn hàng đúng theo hướng dẫn để quá trình xác minh nhanh nhất!</i></p>
                        </div>
                        <p className='text-center mgt'>Gold[z] sẽ được cộng vào tài khoản sau khi bạn chuyển khoản muộn nhất là 24 tiếng!</p>
                    </>
                )
        }
        return null
    }

    getTimePresent = () => {
        getTimePresentApi().then(res => {
            if (res.code == 1) {
                const presentTime = moment(res.time, "hh:mm").format('h[h]mm');
                const presentTimeEnd = moment(presentTime, "hh:mm").add(15, 'minutes').format('h[h]mm')
                this.setState({
                    presentTime: `${presentTime} - ${presentTimeEnd}`
                })
            }
        })
    }

    showModalAdd = (modalType) => {
        this.getTimePresent();
        this.setState({
            showModal: true,
            modalType
        })
        if (modalType == 4) this.props.getTimeGuideAction()
    }

    getTextCardDamage = (assetId) => {
        let mul = 4;
        if (assetId == 72) mul = 6;
        else if (assetId == 73) mul = 8;
        else if (assetId == 74) mul = 10;
        else if (assetId == 75) mul = 12;
        return `Tăng 10% Damage X${mul}`;
    }

    componentDidMount() {
        this.props.getUserBar();
        this.onRegisterEventBus();
    }

    componentWillUnmount() {
        if (this.subScription) this.subScription.unsubscribe();
    }

    render() {
        let { inputStatus, isPress, showModal, modalType } = this.state;
        let { dataUser, houseUse, crownUse, listCrest, cardUse } = this.props;
        return (
            <div
                className='user-bar'
                style={{ background: this.props.wallpaperData }}
            >
                <div className='row'>
                    <div className='col-xs-3 flex-column item-center'>
                        <div className='flex-row relative' onClick={() => this.refs.inputAvatarUser.click()}>
                            <TooltipUser
                                userInfo={dataUser}
                                avatarClass='avatar-guest-huge'
                            />
                            <img
                                src={require('../../../assets/image/upload_avatar.png')}
                                className='upload-avatar-img'
                            />
                        </div>
                        <Button
                            className='button-logout'
                            onClick={this.logOut}
                        >
                            Đăng xuất
                        </Button>
                    </div>
                    <div className='col-xs-6 flex-column item-center'>
                        <div className='flex-row item-center'>
                            <ClubDeputy clubRoleId={dataUser.ClubRoleId} />
                            <p className='text-large mgt'>{dataUser.ClubName}</p>
                        </div>
                        <div className='level-rank mgt'>
                            <ClubVipLevel className='star-rank' vipLevel={dataUser.ClubVipLevel} />
                        </div>
                        <p className='text-large mgt'>{dataUser.FullName}<Checked data={dataUser} /></p>
                        <p className='text-large'>{dataUser.ClubRoleRank}</p>
                    </div>
                    <div className='col-xs-3 item-center'>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/vip.png')} alt='vip-level' />
                            <span className='info-rank'>{dataUser.UserVip}</span>
                        </div>
                        {/* <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/follow-on.png')} alt='achievement-level' />
                            <span className='info-rank'>{dataUser.CountFollow}</span>
                        </div> */}
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/rank.png')} alt='rank' />
                            <span className='info-rank'>{dataUser.Rank}</span>
                        </div>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/like.png')} alt='like-level' />
                            <span className='info-rank'>{dataUser.UserLike}</span>
                        </div>
                        <div className='row-info-rank mgt'>
                            <img className='img-info-rank' src={require('../../../assets/image/achievement.png')} alt='achievement-level' />
                            <span className='info-rank'>{dataUser.Achievement}</span>
                        </div>
                    </div>
                </div>
                <VipProgress dataUser={dataUser} />
                <div className='row'>
                    <div className='col-xs-4 bar-cost'>
                        {formatDecimal(dataUser.BalanceZ)} đ[z]
                        <img
                            className='img-add-user-bar'
                            src={require('../../../assets/image/add.png')}
                            onClick={() => this.showModalAdd(2)}
                        />
                    </div>
                    <div className='col-xs-4 bar-cost'>
                        {formatDecimal(dataUser.Balance)} $[z]
                        <img
                            className='img-add-user-bar'
                            src={require('../../../assets/image/add.png')}
                            onClick={() => this.showModalAdd(3)}
                        />
                    </div>
                    <div className='col-xs-4 bar-cost'>
                        {parseFloat(dataUser.TicketCount)} Gold[z]
                        <img
                            className='img-add-user-bar'
                            src={require('../../../assets/image/add.png')}
                            onClick={() => {
                                this.getPricePay();
                                this.showModalAdd(4)
                            }}
                        />
                    </div>
                </div>
                <div id='notificationUserStatus' />
                <div className="row">
                    {/* <div className='col-md-12 col-sm-12 col-xs-12 item-center'>
                        <div
                            className='user-bar-status bg-primary pointer'
                            onClick={() => this.refs.inputStatus.focus()}
                        >
                            <p>Hôm nay bạn học được điều gì? 😇</p>
                            <textarea
                                className='text-center input-status'
                                placeholder='Nhập nội dung'
                                value={inputStatus}
                                onChange={e => this.setState({ inputStatus: e.target.value })}
                                onKeyDown={e => e.key == 'Enter' && e.preventDefault()}
                                ref='inputStatus'
                            />
                            {inputStatus.length > 0 &&
                                <div className='item-center'>
                                    <Button
                                        className='bg-success mgb'
                                        onClick={this.updateStatus}
                                        loading={isPress}
                                    >
                                        Đăng tâm trạng
                                    </Button>
                                </div>
                            }
                        </div>
                    </div> */}
                    <div className="col-md-12 col-sm-12 col-xs-12 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {cardUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{cardUse.AssetName}</p>
                                            <p className='mgb-0'>Tăng {cardUse.AssetBonus}% thành tựu</p>
                                            <p className='text-small mgb'>{this.getTextCardDamage(cardUse.AssetId)}</p>
                                            <div className='flex-row item-center'>
                                                <Button
                                                    className='btn-remove-house-crown'
                                                    onClick={() => this.removeAsset(cardUse.AssetId)}
                                                    loading={isPress}
                                                >
                                                    Tháo
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Nhà' src={`${URI_PATH}${cardUse.AssetImage}`} />
                                    <p>{cardUse.AssetName}</p>
                                </Tooltip>
                                :
                                <p className='silver'>Lệnh bài phong tước</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {houseUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{houseUse.AssetName}</p>
                                            <p className='mgb-0'>Tăng {houseUse.AssetBonus}% thành tựu</p>
                                            <p className='text-small mgb'>Tăng {+houseUse.AssetBonus / 2}% tỉ lệ damage x2</p>
                                            <div className='flex-row item-center'>
                                                <Button
                                                    className='btn-remove-house-crown'
                                                    onClick={() => this.removeAsset(houseUse.AssetId)}
                                                    loading={isPress}
                                                >
                                                    Tháo
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Nhà' src={`${URI_PATH}${houseUse.AssetImage}`} />
                                    <p>{houseUse.AssetName}</p>
                                </Tooltip>
                                :
                                <p className='silver'>Xây nhà</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {crownUse ?
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{crownUse.AssetName}</p>
                                            <p className='mgb-0'>Tăng {crownUse.AssetBonus}% thành tựu</p>
                                            <p className='text-small mgb'>Tăng {+crownUse.AssetBonus / 2}% tỉ lệ damage x2</p>
                                            <div className='flex-row item-center'>
                                                <Button
                                                    className='btn-remove-house-crown'
                                                    onClick={() => this.removeAsset(crownUse.AssetId)}
                                                    loading={isPress}
                                                >
                                                    Tháo
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    <img className='house-crown' alt='Vương miện' src={`${URI_PATH}${crownUse.AssetImage}`} />
                                    <p>{crownUse.AssetName}</p>
                                </Tooltip>
                                :
                                <p className='silver'>Đúc vương miện</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 item-center">
                        <div className="form-control user-bar-item flex item-center">
                            {listCrest.length > 0 ?
                                <div className='relative'>
                                    <img className='bonus-crest-img' src={require('../../../assets/image/crest.png')} />
                                    <p>Huy chương Z {`${(listCrest.length > 1 ? ('x' + listCrest.length) : "")}`}</p>
                                </div>
                                :
                                <p className='silver'>Huy chương Z</p>
                            }
                        </div>
                    </div>
                </div>
                <div
                    className='pointer flex item-center'
                    onClick={() => this.props.toogleUserBar()}
                >
                    <img
                        src={require('../../../assets/image/arrow-down.png')}
                        className='animated infinite pulse'
                        style={{width: '4vh'}}
                    />
                </div>
                <Modal
                    {...PROP.MODAL}
                    width={[5, 6].includes(modalType) ? '75vh' : '100vh'}
                    visible={showModal}
                    onCancel={() => this.setState({
                        showModal: false,
                        inputZticket: "",
                        calcBalance: ""
                    })}
                    maskClosable={modalType != 6}
                >
                    {this.renderModal()}
                </Modal>
                <input
                    type='file'
                    accept="image/png, image/jpeg"
                    hidden='hidden'
                    ref='inputAvatarUser'
                    onChange={this._changeAvatar}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser, houseUse, crownUse, cardUse, listCrest } = state.getUserData;
    const { selectItem } = state.getUserItem;
    const { wallpaperData } = state.getWallpaper;
    const { listGuide } = state.getListGuide;
    return { dataUser, houseUse, crownUse, cardUse, listCrest, selectItem, wallpaperData, listGuide };
}

export default connect(mapStateToProps, { changePoint, updateDataUser, getUserItem, getUserBar, updateStatusNewfeed, toogleUserBar, getTimeGuideAction })(withRouter(UserBar));