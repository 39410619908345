import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {updateCountMission} from '../../actions/menu';
import HeaderLottie from '../../components/HeaderLottie';
import feast from '../../assets/lottie/feast.json';
import empty from '../../assets/lottie/empty.json';
import {Button, Modal} from 'antd';
import {priceFormat, getDataNotify} from '../../utils/common';
import Loading from '../../components/Loading';
import {getDataFeastApi, inviteApi, getListFoodInviteApi} from '../../api/club/feast';
import { showNotification, showNotificationBonus, showNotificationAsset, showNotificationProperty } from '../../utils/notification';
import {MESSAGE, PROP, URI_PATH} from '../../utils/constants';
import Countdown, { zeroPad } from 'react-countdown-now';
import ListInviteFeast from '../../components/club/ListInviteFeast';
import {socket} from '../../components/realtime/ReadSocketEvent';
import TooltipUser from '../../components/TooltipUser';
import {showModalEat} from '../../actions/home/modalEat';

class ClubFeast extends Component{
    constructor(props){
        super(props);
        this.state={
            costInvite: '',
            feastRemain: '',
            listUser: [],
            isLoading: true,
            isPress: false,
            endList: false,
            isParty: new Date().getHours() == 20,
            showModal: false,
            userInfo: {},
            userFood: [],
            loadingUserFood: true,
            selectFood: 0,
            inputInvite: '',
        }
        this.isOverParty = new Date().getHours() > 20
    }

    getDataFeast = () =>{
        getDataFeastApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listUser: res.listUser,
                    costInvite: res.costInvite,
                    feastRemain: res.feastRemain
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e =>showNotification(MESSAGE.ERROR, 0));
    }

    completeCountDown = () =>{
        this.setState({isParty: true});
        this.getDataFeast();
    }

    showModalInvite = userInfo =>{
        if(this.state.loadingUserFood){
            getListFoodInviteApi().then(res =>{
                if(res.code == 1){
                    this.setState({
                        userFood: res.listItem,
                        loadingUserFood: false
                    });
                }
                else showNotification(res.message, res.code)
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
            });
        }
        this.setState({
            showModal: true,
            userInfo
        });
    }

    selectFood = foodId =>{
        if(this.state.selectFood != foodId) this.setState({selectFood: foodId})
    }

    invite = () =>{
        let {inputInvite, userInfo, selectFood, feastRemain, isPress} = this.state;
        if(!isPress){
            let idNotification = '#notificationInvite';
            if(inputInvite && selectFood){
                this.setState({isPress: true});
                inviteApi(userInfo.UserId, selectFood, inputInvite).then(res =>{
                    if(res.code == 1){
                        let bonus = res.bonus;
                        showNotification(res.message, res.code);
                        showNotificationAsset(res.assetInfo);
                        showNotificationProperty(res.property);
                        showNotificationBonus(bonus.Achievement, 1, 4);
                        showNotificationBonus(bonus.BalanceZ, -1, 3);
                        showNotificationBonus(bonus.Balance, -1, 2);
                        this.props.changePoint([null, -bonus.Balance, -bonus.BalanceZ, bonus.Achievement]);
                        this.props.updateCountMission('FeastClubCount');
                        this.setState({
                            showModal: false,
                            isPress: false,
                            selectFood: '',
                            feastRemain: parseInt(feastRemain) - 1
                        });
                        //socket
                            let dataUser = this.props.dataUser;
                            let datas = getDataNotify(userInfo.UserId);
                            datas.message = {
                                fullName: dataUser.FullName,
                                facebookId: dataUser.FacebookId,
                                message: 'mời bạn ăn tại tiệc nhóm',
                                isNotify : 2
                            }
                            socket.emit('send_notify', datas);
                        //
                    }
                    else{
                        if(res.code == -5) this.props.showModalEat(res.listFood);
                        else showNotification(res.message, res.code, idNotification);
                        this.setState({isPress: false});
                    }
                }).catch(e =>{
                    this.setState({isPress: false})
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
            else if(selectFood == '') return showNotification('Bạn chưa chọn món ăn để mời', 0, idNotification);
            else if(inputInvite == '') return showNotification('Bạn chưa nhập lời nhắn', 0, idNotification);
        }
    }

    renderModalInvite = () =>{
        let {userInfo, userFood, loadingUserFood, selectFood, inputInvite, isPress} = this.state;
        if(loadingUserFood) return (<Loading />)
        return(
            <div>
                <div className='item-center'>
					<TooltipUser userInfo={userInfo} avatarClass='avatar-guest'/>
                    <p className='text-large mgt'>{userInfo.FullName}</p>
                    {userFood.length > 0 ?
                        <div>
                            <p className='mgt'>Chọn đồ ăn để mời</p>
                            <div className="row mgt">
                                {userFood.map((item, key) =>
                                    <div
                                        className={'col-md-3 col-sm-3 col-xs-4 item-block item-center pointer ' + (selectFood == item.FoodId && 'bg-success')}
                                        key={key}
                                        onClick={() => this.selectFood(item.FoodId)}
                                    >
                                        <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                                        <p>{item.FoodName}</p>
                                    </div>
                                )}
                            </div>
                            <input
                                className='form-control text-center mgt-2'
                                placeholder='Lời nhắn ngắn gọn'
                                value={inputInvite}
                                onChange={e => this.setState({inputInvite: e.target.value})}
                            />
                            <div className='mgt' id='notificationInvite'></div>
                            <Button
                                className='bg-success mgt-2'
                                loading={isPress}
                                onClick={this.invite}
                            >
                                Mời ăn
                            </Button>
                        </div>
                    :
                        <div className='item-center'>
                            <HeaderLottie source={empty} disableLoop />
                            <p className='text-large mgt'>Mời ăn yêu cầu đồ ăn trên 4 giờ</p>
                        </div>
                    }
                </div>
            </div>
        )
    }

    componentDidMount(){
        this.state.isParty && this.getDataFeast();
    }

    render(){
        let {isLoading, listUser, costInvite, feastRemain, isParty, showModal} = this.state;
        if(!isParty){
            return(
                <div>
                    <HeaderLottie source={feast}/>
                    <div className='flex item-center'>
                        {this.isOverParty ?
                            <p className='title-event-question'>
                                Hoạt động giao lưu đã kết thúc
                            </p>
                        :
                            <p className='title-event-question'>
                                Tiệc mời ăn sẽ diễn ra sau
                                <Countdown 
                                    date={(new Date().toLocaleDateString() + ' 20:00:00').replace(/([+\-]\d\d)(\d\d)$/, "$1:$2")}
                                    renderer={({ days, hours, minutes, seconds}) =>(<span> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                    onComplete={this.completeCountDown}
                                />
                            </p>
                        }
                    </div>
                    <ListInviteFeast />
                </div>
            )
        }
        else if(isLoading) return(<Loading />);
        return(
            <div>
                <HeaderLottie source={feast}/>
                <div className='flex-column item-center'>
                    <p className='title-event-question'>
                        Bạn còn {feastRemain} lượt mời ăn trong tối nay ({priceFormat(costInvite)} đ[z] / lần)
                    </p>
                    <p className='bold text-title-gift mg'>Thành viên đang hoạt động</p>
                </div>
                <div className='row'>
                    {listUser.map((item, key) =>
                        <div className='col-md-3 col-sm-4 col-xs-6 item-block item-center' key={key}>
							<TooltipUser userInfo={item} avatarClass='avatar-guest mgt'/>
                            <p className='mgt'>{item.FullName}</p>
                            <img
                                src={require('../../assets/image/beer.png')}
                                className='club-feast-beer-icon'
                                onClick={() => this.showModalInvite(item)}
                            />
                        </div>
                    )}
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({showModal: false})}
                    centered
                >
                    {this.renderModalInvite()}
                </Modal>
                <ListInviteFeast />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, updateCountMission, showModalEat})(ClubFeast);