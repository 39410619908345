import { showNotification } from "../../utils/notification";
import {getTimeGuide} from '../../api/menu';

export const updateCountMission = missionType =>{
    return dispatch => dispatch({ type: 'UPDATE_COUNT_MISSION', mission: missionType });
}

export const updateCountNotify = (notifyType, point = -1) =>{
    return dispatch => dispatch({ type: 'UPDATE_COUNT_NOTIFY', notify: notifyType, point });
}

export const updateStateNavigate = (data) =>{
    return { type: 'UPDATE_STATE_NAVIGATE', payload: data };
}

export const getTimeGuideAction = () =>{
    return dispatch =>{
        getTimeGuide().then(res =>{
            dispatch({ type: 'STORE_TIME_GUIDE', timeQueue: res.data });
        }).catch(e =>{
            showNotification('Có lỗi xảy ra trong quá trình thực hiện')
        })
    }
}