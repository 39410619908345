import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { getCategory, getSubCategory } from '../../actions/category';
import { EmptyLottie, TitleSuccess } from '../../components/frame';
import { updateSubCategoryApi, deleteSubCategoryApi } from '../../api/category';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            selectCategory: 1,
            showModal: false,
            categoryIdAdd: 1,
            inputSubCategoryName: '',
            modalType: 1,
            subCategoryInfo: {}
        }
    }

    buttonGroupClass = (selectCategory) => {
        if (selectCategory == this.state.selectCategory) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    updateSubCategory = () => {
        if (!this.state.isPress) {
            const { categoryIdAdd, inputSubCategoryName, selectCategory, subCategoryInfo } = this.state;
            if (inputSubCategoryName.length > 0) {
                this.setState({ isPress: true })
                updateSubCategoryApi(categoryIdAdd, inputSubCategoryName, subCategoryInfo.SubCategoryId).then(res => {
                    showNotification(res.message, res.code);
                    if (res.code == 1) {
                        this.setState({
                            isPress: false,
                            showModal: false
                        })
                        if (categoryIdAdd == selectCategory) this.props.getSubCategory(categoryIdAdd, this.props.dataUser.UserId);
                    }
                    else this.setState({ isPress: false })
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({ isPress: false })
                })
            }
            else showNotification('Tên chủ đề không được để trống', 0)
        }
    }

    deleteSubCategory = () =>{
        if (!this.state.isPress) {
            const { subCategoryInfo, selectCategory } = this.state;
            this.setState({ isPress: true })
            deleteSubCategoryApi(subCategoryInfo.SubCategoryId).then(res =>{
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    })
                    this.props.getSubCategory(selectCategory, this.props.dataUser.UserId);
                }
                else this.setState({ isPress: false })
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    componentDidMount() {
        this.props.getCategory(1);
        setTimeout(() => {
            this.props.getSubCategory(1, this.props.dataUser.UserId);
        }, 300);
    }

    render() {
        const { isPress, showModal, categoryIdAdd, inputSubCategoryName, selectCategory, modalType, subCategoryInfo } = this.state;
        const { categoryPost, dataUser, subCategory, loadingSubCategory } = this.props;
        if (categoryPost.length == 0) return <Loading />
        return (
            <>
                <div className='item-center mgt-2'>
                    <Button
                        className='bg-success'
                        onClick={() => {
                            this.setState({
                                showModal: true,
                                modalType: 1,
                                subCategoryInfo: {}
                            })
                        }}
                    >
                        Thêm chủ đề
                    </Button>
                </div>
                <div className="row mgt">
                    <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                        {categoryPost.map((item, key) =>
                            <>
                                <Button
                                    key={key}
                                    className={this.buttonGroupClass(item.CategoryId)}
                                    onClick={() => {
                                        if (item.CategoryId != selectCategory) {
                                            this.setState({ selectCategory: item.CategoryId, categoryIdAdd: item.CategoryId })
                                            this.props.getSubCategory(item.CategoryId, dataUser.UserId)
                                        }
                                    }}
                                >
                                    {item.CategoryName}
                                </Button>
                            </>
                        )}
                    </ButtonGroup>
                </div>
                {loadingSubCategory ?
                    <Loading small />
                    :
                    <>
                        {subCategory.length == 0 ?
                            <EmptyLottie title='Chưa có chủ đề được thêm' />
                            :
                            <>
                                {subCategory.map((item, key) =>
                                    <div className='row box-primary relative' key={key} style={{ justifyContent: 'space-between' }}>
                                        <p className='mgb-0'>{item.SubCategoryName}</p>
                                        <div className='flex-row'>
                                            <img
                                                src={require('../../assets/image/pen.png')}
                                                style={{ width: '3vh' }}
                                                className='pointer mgr'
                                                onClick={() => this.setState({
                                                    subCategoryInfo: item,
                                                    showModal: true,
                                                    modalType: 1,
                                                    inputSubCategoryName: item.SubCategoryName
                                                })}
                                            />
                                            <img
                                                src={require('../../assets/image/delete-red.png')}
                                                style={{ width: '3vh' }}
                                                className='pointer mgl'
                                                onClick={() => this.setState({
                                                    subCategoryInfo: item,
                                                    showModal: true,
                                                    modalType: 2
                                                })}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    {modalType == 1 ?
                        <>
                            <TitleSuccess>{subCategoryInfo ? 'Sửa chủ đề' : 'Thêm chủ đề'}</TitleSuccess>
                            <div className='item-center row'>
                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                    <select
                                        className='form-control mgt'
                                        onChange={(e) => this.setState({ categoryIdAdd: e.target.value })}
                                        value={categoryIdAdd}
                                    >
                                        {categoryPost.map((item, key) => (
                                            <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <input
                                className='form-control mgt'
                                value={inputSubCategoryName}
                                onChange={e => this.setState({ inputSubCategoryName: e.target.value })}
                                placeholder='Nhập tên chủ đề'
                            />
                            <div className='item-center'>
                                <Button
                                    className='bg-success mgt-3'
                                    onClick={this.updateSubCategory}
                                    loading={isPress}
                                >
                                    Xác nhận
                                </Button>
                            </div>
                        </>
                    :
                        <div className='item-center'>
                            <p className='text-center text-large mgt-3'>Xác nhận xóa chủ đề ?</p>
                            <p className='text-center mgt-2'>{subCategoryInfo.SubCategoryName}</p>
                            <Button
                                className='bg-danger mgt-4'
                                loading={isPress}
                                onClick={this.deleteSubCategory}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    }
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost, subCategory, loadingSubCategory } = state.getCategory;
    return { dataUser, categoryPost, subCategory, loadingSubCategory }
}

export default connect(mapStateToProps, { getCategory, getSubCategory })(Main)