import React, { Component } from 'react';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import NavBar from './NavBar';
import {updateTimeOnlineApi} from '../../../api/home';
import {getUserData} from '../../../actions/home';
import {getCategory} from '../../../actions/category/';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import ReadEventSocket from '../../realtime/ReadSocketEvent';
import WidgetChat from '../../chat/Widget';
import WidgetNotification from '../../notification/Widget';
import ModalEatFood from '../../ModalEatFood';

class Admin extends Component{
    constructor(props){
        super(props);
    }

    render(){
        if(this.props.isLoading) return (
            <div className='nav-center'>
                <Loading />
            </div>
        );
        return(
            <>
                {/* <NavLeft /> */}
                <div className='nav-center'>
                    <NavBar />
                    <div className='main-content'>
                        {this.props.children}
                    </div>
                </div>
                {/* <NavRight /> */}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { isLoading } = state.getUserData;
    const { showModalEat } = state.eatModal;
    return { isLoading, showModalEat };
}

export default connect(mapStateToProps, { getUserData, getCategory })(Admin);