import React from 'react';
import {isIOS, isAndroid, isWindows, isMacOs, isElectron} from "react-device-detect";

const RenderTitle = (props) =>(
    <p className='text-center mgt-2'>{props.title}</p>
)

const makeItem = (type) =>{
    switch (true) {
        case isIOS:
            return type == 'node' ? <RenderTitle title='Tải Zcity iOS' /> : 'https://apps.apple.com/us/app/id1494495923';
        case isAndroid:
            return type == 'node' ? <RenderTitle title='Tải Zcity Android' /> : 'https://play.google.com/store/apps/details?id=com.zcity2';
        case isWindows:
            return type == 'node' ? <RenderTitle title='Tải Zcity Windows' /> : 'https://zcity.vn/backend/assets/Zcity_Setup_1.0.0.exe';
        case isMacOs:
            return type == 'node' ? <RenderTitle title='Tải Zcity MacOS' /> : 'https://zcity.vn/backend/assets/Zcity-1.0.0.dmg';
    }
}

const downloadApp = () =>{
    window.open(makeItem('link'));
}

export default () =>{
    if(!isElectron && (isIOS || isAndroid || isMacOs || isWindows)){
        return(
            <div
                className='grid-menu item-center'
                onClick={downloadApp}
            >
                <img
                    className='icon-menu'
                    src={require('../../assets/image/menu/config-download.png')}
                />
                {makeItem('node')}
            </div>
        )
    }
    return null;
}