import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getGuestDataApi = guestId =>{
    let url = `${URI_PATH}app/property/guest/getGuestData`;
    let body = {
        GuestId: guestId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const eatFoodPartyApi = userfoodpartyId =>{
    let url = `${URI_PATH}app/property/guest/eatFoodParty`;
    let body = {
        UserfoodpartyId: userfoodpartyId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}