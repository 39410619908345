import React, { Component } from 'react';
import {COLOR, MESSAGE, URI_PATH} from '../../utils/constants';
import {getQuestionGuestApi, saveAnswerGuestApi} from '../../api/question/lobby';
import {getListRankApi} from '../../api/question/lobby/rank';
import {getAvatarFb} from '../../utils/common';
import {showNotification} from '../../utils/notification';
import Loading from '../../components/Loading';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import Lottie from 'react-lottie';
import firework from '../../assets/lottie/firework.json';
import questionLobbyLottie from '../../assets/lottie/questionLobby';
import HeaderLottie from '../../components/HeaderLottie';
import { Button } from 'antd';
import ClubVipLevel from '../../components/club/ClubVipLevel';
import ClubDeputy from '../../components/club/ClubDeputy';
import Icon from 'react-fontawesome';
const ButtonGroup = Button.Group;

class QuestionTrain extends Component {
    constructor(props){
        super(props);
        this.state = {
            percentTime: '0%',
            resultAnswer: false,
            selectAnswerKey: '',
            isPress: false,
            isAnswer: false,
            questionList: [],
            isLoadingQuestion: true,
            isLoadingRank: true,
            countCorrect: 0,
            selectNavigate: 0,
            listRank: [],
        };
        this.timeDiff = 5;
        this.correctAnswer = '';
        this.resultColor = '';
        this.questionInterval = '';
    }

    getQuestion = () =>{
        let idNotification = '#notificationStartQuestionLobby';
        this.setState({isPress: true});
        getQuestionGuestApi().then(res =>{
            if(res.code == 1){
                this.questionLobby = res.questionList[0];
                this.timeDiff = res.timeDiff;
                this.setState({
                    isAnswer: false,
                    percentTime: '0%',
                    resultMessage: '',
                    selectAnswerKey: '',
                    isLoadingQuestion: false,
                });
                this.questionInterval = setInterval(() =>{
                    this.timeDiff -= 1;
                    this.setState({percentTime: `${(15 - this.timeDiff) / 15 * 100}%`});
                    if(this.timeDiff <= -1){
                        this.setState({
                            isAnswer: false,
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isLoadingQuestion: true
                        });
                        this.questionLobby = {};
                        this.correctAnswer = '';
                        this.timeDiff = 15;
                        clearInterval(this.questionInterval);
                        this.getQuestion()
                    }
                }, 1000);
            }
            else{
                if(res.code == -5) this.props.showModalEat(res.listFood);
                else showNotification(res.message, res.code, idNotification);
                this.setState({
                    isPress: false,
                });
            }
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, -1, idNotification);
            this.setState({
                isPress: false,
            });
        })
    }

    saveAnswer = (answer, keySelect, questionId) =>{
        if(this.state.isAnswer === false){
            let idNotification = '#notificationQuestionGuest';
            this.setState({selectAnswerKey: keySelect, isAnswer: true});
            saveAnswerGuestApi(questionId, answer).then(res =>{
                if(res.code == 1 || res.code == 0){
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    if(res.code == 1){
                        this.setState({
                            countCorrect: parseInt(this.state.countCorrect) + 1,
                            resultAnswer: res.code
                        })
                    }
                    else {
                        this.setState({countCorrect: 0})
                    }
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({resultAnswer: false, isAnswer: false, selectAnswerKey: ''});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({resultAnswer: false, isAnswer: false, selectAnswerKey: ''});
            })
        }
    }

    getListRank = () =>{
        if(this.state.listRank.length == 0) this.setState({isLoadingRank: true})
        getListRankApi(true).then(res =>{
            if(res.code == 1){
                this.setState({
                    listRank: res.listRank,
                    isLoadingRank: false
                })
            }
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    selectNavigate = (selectNavigate) =>{
        if(selectNavigate != this.state.selectNavigate){
            this.setState({selectNavigate});
            if(selectNavigate == 1) this.getListRank();
        }
    }

    buttonGroupClass= (selectNavigate) =>{
        if(selectNavigate === this.state.selectNavigate) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    fontSizeRank = (rank) =>{
        if(0 < rank && rank <= 9) return '6vh';
        else if(9 < rank && rank <= 99) return '5vh';
        else if(100 < rank && rank <= 999) return '4vh';
        else return '20';
    }

    componentDidMount(){
        this.getQuestion();
    }

    componentWillUnmount(){
        clearInterval(this.questionInterval);
    }

    render(){
        let {isLoadingQuestion, isLoadingRank, selectAnswerKey, resultAnswer, percentTime, countCorrect, listRank, selectNavigate} = this.state;
        return(
            <>
                <HeaderLottie
                    source={questionLobbyLottie}
                />
                <ButtonGroup className='item-center' style={{display: 'flex', width: '100%'}}>
                    <Button className={this.buttonGroupClass(0)} onClick={() => this.selectNavigate(0)}>Siêu trí nhớ</Button>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.selectNavigate(1)}>Bảng xếp hạng</Button>
                </ButtonGroup>
                <>
                    {selectNavigate == 0 ?
                        <>
                            <div className='flex-column item-center'>
                                <p className='bold title-success'>
                                    Đúng liên tiếp: {countCorrect}
                                </p>
                                <div className="progress-div mgb" style={{ width:'100%' }}>
                                    <div style={{ width: percentTime, background: this.timeDiff <= 3 ? COLOR.COLOR_DANGER : COLOR.COLOR_SUCCESS }} className="progress" />
                                </div>
                            </div>
                            {isLoadingQuestion ?
                                <Loading small />
                            :
                                <div className='animated fadeInUp faster'>
                                    <p className='title-question'>{this.questionLobby.QuestionTitle}</p>
                                    {this.questionLobby.answers.map((value, key) =>{
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => this.saveAnswer(value, key, this.questionLobby.QuestionLobbyId)}
                                            >
                                                <TextAnswer 
                                                    answer={value}
                                                    keySelect={key}
                                                    selectAnswerKey={selectAnswerKey}
                                                    correctAnswer={this.correctAnswer}
                                                />
                                            </div>
                                        )
                                    })}
                                    {resultAnswer !== false && (
                                        <ResultMessage resultAnswer={resultAnswer} />
                                    )}
                                    <div className='notificationQuestionGuest'></div>
                                    {this.state.resultAnswer == 1 &&
                                        <Lottie
                                            options={{loop: true, autoplay: true, animationData: firework}}
                                            width='50vh'
                                            style={{position: 'absolute', top: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0}}
                                        />
                                    }
                                </div>
                            }
                        </>
                    :
                        <>
                            {isLoadingRank ?
                                <Loading small />
                            :
                                <>
                                    {listRank.map((item, key) =>
                                        <div key={key} className='rank-container item-center'>
                                            <div className='col-rank-stt'>
                                                <p style={{fontSize: this.fontSizeRank(key + 1)}}>{key + 1}</p>
                                                <div>
                                                    <img
                                                        style={{width: '2vh', position: 'relative', bottom: '2vh'}}
                                                        src={require('../../assets/image/rank.png')}
                                                        alt='img-rank'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-rank-avatar'>
                                                <div style={{position: 'relative'}}>
                                                    <img className='avatar-rank' key={item.UserId} alt='avatar-user' src={getAvatarFb(item.FacebookId)} />
                                                    {item.AssetImage && (
                                                        <img className='avatar-asset-rank' alt='asset-user' src={`${URI_PATH}${item.AssetImage}`} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-rank-name'>
                                                <div className='row item-center'>
                                                    <ClubDeputy clubRoleId={item.ClubRoleId} />
                                                    <p>{item.ClubName}</p>
                                                </div>
                                                <div className='level-rank'>
                                                    <ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
                                                </div>
                                                <p className='bold'>{item.FullName}</p>
                                                <p>{item.ClubRank}</p>
                                            </div>
                                            <div className='col-rank-info'>
                                                <div className='row-info-rank item-center'>
                                                    <Icon name='flag-checkered' style={{fontSize: '3vh', marginTop: '1vh'}} />
                                                    <span className='info-rank-question'>{item.MaxCorrect}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    }
                </>
            </>
        )
    }
}

export default QuestionTrain;