import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const sendTokenApi = email =>{
    let url = `${URI_PATH}app/authentication/forgot/sendToken`;
    let body = {
        Email: email
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changePassApi = (token, inputPass, inputRePass) =>{
    let url = `${URI_PATH}app/authentication/forgot/changePass`;
    let body = {
        Token: token,
        InputPass: inputPass,
        InputRePass: inputRePass
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}