import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionTrainApi = (page = 1) =>{
    let url = `${URI_PATH}app/question/train/getQuestionTrain`;
    let body = {
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getQuestionTrainSelectApi = () =>{
    let url = `${URI_PATH}app/question/train/getQuestionTrainSelect`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListQuestionTrainApi = (questiontrainlistId)=>{
    let url = `${URI_PATH}app/question/train/getListQuestionTrain`;
    let body = {
        QuestiontrainlistId: questiontrainlistId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerTrainApi = (questionId, answer)=>{
    let url = `${URI_PATH}app/question/train/saveAnswerTrain`;
    let body = {
        QuestionTempId: questionId,
        Answer: answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}