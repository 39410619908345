import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataFeastApi = () =>{
    let url = `${URI_PATH}app/club/feast/getDataFeast`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const inviteApi = (userId, foodId, message) =>{
    let url = `${URI_PATH}app/club/feast/invite`;
    let body = {
        UserIdReceive: userId,
        FoodId: foodId,
        Message: message
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListFoodInviteApi = () =>{
    let url = `${URI_PATH}app/club/feast/getListFoodInvite`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListInviteApi = (pageId) =>{
    let url = `${URI_PATH}app/club/feast/getListInvite`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const receiveApi = (clubfeastId) =>{
    let url = `${URI_PATH}app/club/feast/receive`;
    let body = {
        ClubfeastId: clubfeastId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}