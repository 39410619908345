import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListRankApi = (rankAchievement = '', birthAchievement = '') =>{
    let url = `${URI_PATH}app/authentication/action/getListRanks`;
    let body = {
        RankAchievement: rankAchievement,
        BirthAchievement: birthAchievement
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const login = (phoneNumber, password, secretCode) =>{
    let url = `${URI_PATH}app/authentication/action/login`;
    let body = {
        PhoneNumber : phoneNumber,
        UserPass : password,
        SecretCode: secretCode
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}