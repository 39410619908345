import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isStudent} from '../../utils/common';
import { Affix, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import ZAlphaLevel from './componentChildren/ZAlphaLevel';
import ZAlphaRegister from './componentChildren/ZAlphaRegister';
const ButtonGroup = Button.Group;

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            loading: true
        }
    }

//     renderStep = () => {
//         const { step } = this.state;
//         switch (step) {
//             case 1:
//                 return (
//                     <ZAlphaLevel />
//                 )
//             case 2:
//                 return (
//                     <>
//                         <div className='content-study-register-container'>
//                             <div className='item-center'>
//                                 <img
//                                     src={require('../../assets/image/logo-z.png')}
//                                     style={{width: '10vh'}}
//                                 />
//                                 <p className='text-large bold mgt'>XÂY DỰNG CỘNG ĐỒNG DOANH NHÂN THẾ HỆ Z</p>
//                             </div>
//                             <div className='content-study-register'>
//                                 <div className='box-study-alpha-intro row'>
//                                     <div className='col-md-8 col-sm-7 col-xs-12'>
//                                         <p className='bold text-large text-center'><i>Giá trị cốt lõi:</i></p>
//                                         <p className='text-content-study-register'><i>{`1- Cộng đồng kinh doanh chân chính lấy “Thật thà - Trung thực” làm giá trị cốt lõi, với 4 khẩu hiệu: 
//     • Thật thà là bền vững - dối trá là suy tàn.
//     • Nguyên tắc bán hàng: có sao nói vậy.
//     • Phát triển dựa trên GIÁ TRỊ THỰC.
//     • Nói KHÔNG với các hoạt động đa cấp.
// 2- Khai trừ: Thành viên vi phạm giá trị cốt lõi của tổ chức, cố tình lừa dối khách hàng, sẽ bị công khai khai trừ.
// 3- Cam kết: Tổ chức có trách nhiệm bảo vệ khách hàng nếu thành viên vi phạm.`}</i>
//                                         </p>
//                                     </div>
//                                     <div className='col-md-4 col-sm-5 col-xs-12 flex-column item-center'>
//                                         <img src={require('../../assets/image/alpha/gtcl.png')} className='img-study-alpha-intro' />
//                                     </div>
//                                 </div>
//                                 <div className='box-study-alpha-intro row'>
//                                     <div className='col-md-4 col-sm-5 col-xs-12 item-center'>
//                                         <img src={require('../../assets/image/alpha/sm.png')} className='img-study-alpha-intro' />
//                                     </div>
//                                     <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
//                                         <p className='bold text-large text-center mgt-2'><i>Sứ mệnh:</i></p>
//                                         <p className='text-content-study-register'><i>{`"Đẩy lùi tệ nạn kinh doanh chộp giật ra khỏi thị trường, đem lại cho khách hàng một hệ sinh thái an tâm, tin tưởng."`}</i></p>
//                                     </div>
//                                 </div>
//                                 <div className='box-study-alpha-intro row'>
//                                     <div className='col-md-8 col-sm-7 col-xs-12'>
//                                         <p className='bold text-center text-large mgt-2'><i>Tầm nhìn:</i></p>
//                                         <p className='text-content-study-register'><i>{`    "Với lợi thế học tập, kết nối, sinh hoạt Online 100%, cộng đồng doanh nhân Z mục tiêu trở thành mạng lưới doanh nghiệp được đào tạo bài bản, chung hệ giá trị tư tưởng sống, liên kết chặt chẽ mật thiết trên nhiều vùng lãnh thổ Việt Nam, Nhật Bản, Đài Loan, Hàn Quốc,...mang lại lợi thế quan hệ, địa lý, khách hàng cho thành viên."`}</i></p>
//                                     </div>
//                                     <div className='col-md-4 col-sm-5 col-xs-12 flex-column item-center'>
//                                         <img src={require('../../assets/image/alpha/tn.png')} className='img-study-alpha-intro' />
//                                     </div>
//                                 </div>
//                                 <div className='box-study-alpha-intro row'>
//                                     <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                         <img src={require('../../assets/image/alpha/mt.png')} className='img-study-alpha-intro' />
//                                     </div>
//                                     <div className='col-md-8 col-sm-7 col-xs-12 flex-column align-center'>
//                                         <p className='bold text-center text-large mgt-2'><i>Mục tiêu:</i></p>
//                                         <p className='text-content-study-register'><i>{`• Mục tiêu số 1: Tuyển chọn & đào tạo những thành viên phù hợp triết lý của tổ chức.
// • Mục tiêu số 2: Xây dựng hệ thống khởi nghiệp kinh doanh bài bản cho từng thành viên.
// • Mục tiêu số 3: Truyền thông & thực thi cam kết về giá trị cốt lõi của tổ chức để khẳng định, định vị rõ nét trong tâm trí khách hàng.
// • Mục tiêu số 4: Xây dựng quy tắc & nền tảng phần mềm tiện ích đảm bảo quá trình trao đổi giá trị công bằng và bền vững giữa các thành viên.
// `}</i></p>
//                                     </div>
//                                 </div>
//                                 <div className='box-study-alpha-intro row'>
//                                     <div className='col-md-8 col-sm-7 col-xs-12 flex-column align-center'>
//                                         <p className='bold  text-large mgt-2'><i>Nhận diện:</i></p>
//                                         <p className='text-content-study-register'><i>{`• Thành viên chính thức được công khai thương hiệu cá nhân trên trang chủ ZCITY để khách hàng dễ dàng tra cứu.
// • Địa chỉ kinh doanh, phương tiện di chuyển, nhãn mác sản phẩm của thành viên, được đặt logo của tổ chức để tăng cường uy tín, tạo sự an tâm cho khách hàng.`}</i></p>
//                                     </div>
//                                     <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                         <img src={require('../../assets/image/alpha/z.png')} className='img-study-alpha-intro' style={{border: '1px solid #9c9c9c', borderRadius: 35}} />
//                                     </div>
//                                 </div>
//                                 <div className='item-center'>
//                                     <img
//                                         src={require('../../assets/image/line.png')}
//                                         className='line-img'
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </>
//                 )
//             case 3:
//                 return (
//                     <div className='content-study-register-container'>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
//                                 <p className='bold  text-large mgt-2'><i>Học tập:</i></p>
//                                 <p><i>"Tôi đứng trên vai người khổng lồ</i></p>
//                                 <p><i> còn bạn đứng trên vai tôi!"</i></p>
//                                 <p><i> Thầy Nguyễn Minh Ngọc ™</i></p>
//                             </div>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/ht.png')} className='img-study-alpha-intro' />
//                             </div>
//                         </div>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/cs.png')} className='img-study-alpha-intro' />
//                             </div>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
//                                 <p className='bold  text-large mgt-2'><i>Cuộc sống:</i></p>
//                                 <p><i>"Thành công của bạn chính là thành công của Z.</i></p>
//                                 <p><i>Thành công của Z chính là thành công của bạn!"</i></p>
//                             </div>
//                         </div>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
//                                 <p className='bold  text-large mgt-2'><i>Kinh doanh:</i></p>
//                                 <p><i>"Ngoài lợi nhuận ra, chúng ta có một lý tưởng sống</i></p>
//                                 <p><i> cần xây dựng & bảo vệ đó là sự</i></p>
//                                 <p><i> Thật thà - Trung thực của chính mình và tổ chức!”</i></p>
//                             </div>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/kd.png')} className='img-study-alpha-intro' />
//                             </div>
//                         </div>
//                     </div>
//                 )
//             case 4:
//                 return (
//                     <div className='content-study-register-container'>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column'>
//                                 <p className='bold text-large mgt-2 text-center'>Tuyển chọn:</p>
//                                 <p><i>Để thực hiện mục tiêu số 1 được đề ra, tổ chức cần tìm những bạn từ 18 tuổi trở lên có tố chất ham học hỏi, cầu tiến, có hoài bão làm doanh nhân, đồng điệu về giá trị cốt lõi, đào tạo trở thành Founder kiêm CEO khởi nghiệp bài bản.</i></p>
//                                 <p className='text-content-study-register mgt-2'><i>{`• Founder (người sáng lập) là người có khát vọng trong cuộc sống, không chấp nhận thực tại, có những trăn trở, hoài bão, có lý tưởng sống riêng, khác biệt, muốn hiện thực hóa lý tưởng sống của mình.
// • Phân biệt với CEO, CEO là người quản trị vận hành, có thể làm thuê cho lý tưởng sống của người khác, doanh nghiệp khác.
// • Founder kiêm CEO là người tự xây dựng nên hoài bão, ý tưởng của riêng mình và tự quản trị nó.`}</i></p>
//                             </div>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/tc.png')} className='img-study-alpha-intro' />
//                             </div>
//                         </div>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/dtb1.png')} className='img-study-alpha-intro' />
//                             </div>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column'>
//                                 <p className='bold text-center text-large mgt-2'>ĐÀO TẠO BƯỚC 1</p>
//                                 <p><i>Đào tạo Kiến Thức Nền Level 1 “Xây dựng bản kế hoạch cuộc đời Founder & CEO”.</i></p>
//                             <p className='text-content-study-register'><i>{`• Kiểm tra đầu vào: Vượt qua “Thử thách Z ALPHA level 1”.
// • Đồ án tốt nghiệp: biên tập bản kế hoạch cuộc đời thành một cuốn sách, biết cách xây dựng bản kế hoạch kinh doanh.
// • Hình thức học: Online.
// • Thời gian: 1 năm.
// • Học phí: 2 triệu/ tháng (đóng học phí theo tháng).`}</i></p>
//                             </div>
//                         </div>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column'>
//                                 <p className='bold text-center text-large mgt-2'>ĐÀO TẠO BƯỚC 2</p>
//                                 <p><i>Đào tạo Kiến Thức Nền Level 2 “Khởi nghiệp thông minh”</i></p>
//                                 <p className='text-content-study-register'><i>{`• Kiểm tra đầu vào: Điểm trung bình KTN-Level 1 đạt 4/5 sao.
// • Phí đăng ký: Phí gia nhập + 10% cổ phần không pha loãng của những doanh nghiệp do học viên sáng lập.
// • Thời gian: toàn bộ dự án.`}</i></p>
//                             </div>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/dtb2.png')} className='img-study-alpha-intro' />
//                             </div>
//                         </div>
//                         <div className='box-study-alpha-intro row'>
//                             <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
//                                 <img src={require('../../assets/image/alpha/ly.png')} className='img-study-alpha-intro' />
//                             </div>
//                             <div className='col-md-8 col-sm-7 col-xs-12 flex-column'>
//                                 <p className='bold text-center text-large mgt-2'>QUYỀN LỢI KHÁC & TRÁCH NHIỆM</p>
//                                 <p className='text-content-study-register'><i>{`• Học viên đạt điểm số 4 sao trở lên tại học phần giai đoạn 1, nhưng lựa chọn không theo khởi nghiệp, sẽ được giới thiệu việc làm thu nhập từ 10 - 50 triệu theo năng lực.
// • Học viên hoàn toàn có thể bị đình chỉ học giai đoạn 1 trong trường hợp thiếu nỗ lực, học phí chưa học sẽ được hoàn lại.`}</i></p>
//                             </div>
//                         </div>
//                         <div className='item-center'>
//                             <img
//                                 src={require('../../assets/image/line.png')}
//                                 className='line-img mgt mgb-2'
//                             />
//                         </div>
//                     </div>
//                 )
//             case 5:
//                 return (
//                     <ZAlphaRegister onClickZLevel={() => this.setState({ step: 1 })} />
//                 )
//         }
//         return null
//     }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.step) return 'button-group-study-register bg-success';
        return 'button-group-study-register';
    }

    setStep = (step) => {
        this.setState({ step })
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <ZAlphaLevel />
                {/* <Affix offsetTop={45}>
                    <div className='header-register-study'>
                        <div className='flex-column justify-center'>
                            <p className='title-register-study'>Z ALPHA</p>
                        </div>
                    </div>
                    <div className="row">
                        <ButtonGroup className='item-center flex' style={{ width: '100%' }}>
                            <Button className={this.buttonGroupClass(1)} onClick={() => this.setStep(1)}>Thử thách</Button>
                            <Button className={this.buttonGroupClass(2)} onClick={() => this.setStep(2)}>Lý do</Button>
                            <Button className={this.buttonGroupClass(3)} onClick={() => this.setStep(3)}>Nhận thức</Button>
                            <Button className={this.buttonGroupClass(4)} onClick={() => this.setStep(4)}>Giải pháp</Button>
                            <Button className={this.buttonGroupClass(5)} onClick={() => this.setStep(5)}>Hành động</Button>
                        </ButtonGroup>
                    </div>
                </Affix>
                {this.renderStep()} */}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(Register))