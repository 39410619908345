import React, {Component} from 'react';
import { withRouter } from 'react-router';
import { TitleSuccess } from '../../components/frame';
import {toogleUserBar} from '../../actions/userbar';
import { connect } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown-now';
import {getTimeGuideAction} from '../../actions/menu'
import { showNotification } from '../../utils/notification';
import { getTimePresentApi } from '../../api/present';
import moment from 'moment';

class Guide extends Component{
    constructor(props){
        super(props);
        this.state = {
            presentTime: '6h - 6h15'
        }
    }

    intervalRequest = '';

    getTimePresent = () => {
        getTimePresentApi().then(res => {
            if (res.code == 1) {
                const presentTime = moment(res.time, "hh:mm").format('h[h]mm');
                const presentTimeEnd = moment(presentTime, "hh:mm").add(15, 'minutes').format('h[h]mm')
                this.setState({
                    presentTime: `${presentTime} - ${presentTimeEnd}`
                })
            }
        })
    }

    componentDidMount(){
        this.props.getTimeGuideAction()
        this.intervalRequest = setInterval(() => {
            this.props.getTimeGuideAction()
        }, 10000);

        this.getTimePresent();
    }

    componentWillUnmount(){
        clearInterval(this.intervalRequest)
    }

    render(){
        return(
            <>
                <div className='guide-container'>
                <div className='row mgt-3'>
                    <div className='col-md-4 col-sm-4 col-xs-12 item-center'>
                        <p className='text-large bold'>Tên sự kiện</p>
                    </div>
                    <div className='col-md-4 col-sm-4 col-xs-12 item-center'>
                        <p className='text-large bold'>Thời gian</p>
                    </div>
                    <div className='col-md-4 col-sm-4 col-xs-12 item-center'>
                        <p className='text-large bold'>Phần thưởng</p>
                    </div>
                </div>
                {this.props.listGuide.map((item, key) =>
                    <div
                        className={`row col-guide pointer ${(!item.timeQueue && 'pulse-guide')}`}
                        key={key}
                        onClick={() => this.props.history.push(item.link)}
                    >
                        <div className='col-md-4 col-sm-4 col-xs-12 item-center'>
                            <img src={item.image} style={{width: '10vh'}}/>
                            <p className='mgt-2'>{item.title}</p>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12 item-center'>
                            <p>{item.time == 'giftDay' ? this.state.presentTime : item.range}</p>
                            {item.timeQueue ?
                                <p>
                                    <Countdown
                                        date={item.timeQueue * 1000}
                                        renderer={({ days, hours, minutes, seconds}) =>(<span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                    />
                                </p>
                            :
                                <p>{'Tham gia ngay'}</p>
                            }
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12 item-center'>
                            <p>{item.bonus}</p>
                        </div>
                    </div>
                )}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) =>{
    const {listGuide, isLoadingGuide} = state.getListGuide;
    return {listGuide, isLoadingGuide}
}

export default connect(mapStateToProps, {toogleUserBar, getTimeGuideAction})(withRouter(Guide));