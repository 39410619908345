import React, { Component } from "react";
import { changeLanguageApi } from "../../api/language";
import { connect } from "react-redux";
import { showNotification } from "../../utils/notification";
import { getCountMission, updateDataUser } from "../../actions/home";
import { MESSAGE } from "../../utils/constants";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import { addVoice } from "../../actions/voice";

const LANGUAGE_SELECT = {
    UK: 1,
    JA: 2,
    KO: 3
}

class ButtonLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false
        }
    }

    handleChangeLanguage = (type) =>{
        if(!this.state.isPress && type != this.props.dataUser.Language){
            this.props.updateDataUser('Language', type);
            this.setState({
                isPress: true
            })
            changeLanguageApi(type).then(res =>{
                if(res.code == 1){
                    onPushEventBus(EventBusName.CHANGE_LANGUAGE);
                    const voices = window.speechSynthesis.getVoices();
                    let voice = voices.find(i => i?.name === 'Karen' || i?.name === 'Google US English')
                    if(type == 2){
                        voice = voices.find(i => i?.name === 'Kyoko' || i?.name === 'Google 日本語')
                    }
                    else if(type == 3){
                        voice = voices.find(i => i?.name === 'Yuna' || i?.name === 'Google 한국의')
                    }
                    if(voice?.name){
                        this.props.addVoice(voice)
                    }
                    this.props.getCountMission();
                }
                showNotification(res.message, res.code);
                this.setState({
                    isPress: false
                })
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({
                    isPress: false
                })
            })
        }
    }

    render() {
        const {Language} = this.props.dataUser;
        return (
            <div className={this.props.widget ? 'flex flex-row mgt mgb' : 'flex flex-row relative'} style={{top: '0.5vh'}}>
                <div className=' flex flex-1 item-center'>
                    <div
                        className={`language-flag-container ${Language == LANGUAGE_SELECT.UK ? 'bg-success' : ''}`}
                        onClick={() => this.handleChangeLanguage(LANGUAGE_SELECT.UK)}
                    >
                        <img src={require('../../assets/image/uk.png')} className='language-flag' />
                    </div>
                </div>
                <div className=' flex flex-1 item-center'>
                    <div
                        className={`language-flag-container ${Language == LANGUAGE_SELECT.JA ? 'bg-success' : ''}`}
                        onClick={() => this.handleChangeLanguage(LANGUAGE_SELECT.JA)}
                    >
                        <img src={require('../../assets/image/ja.png')} className='language-flag' />
                    </div>
                </div>
                <div className=' flex flex-1 item-center'>
                    <div
                        className={`language-flag-container ${Language == LANGUAGE_SELECT.KO ? 'bg-success' : ''}`}
                        onClick={() => this.handleChangeLanguage(LANGUAGE_SELECT.KO)}
                    >
                        <img src={require('../../assets/image/ko.png')} className='language-flag' />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps, {updateDataUser, addVoice, getCountMission})(ButtonLanguage);