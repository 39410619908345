import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getListSubjectApi, getListTeacherApi, selectTeacherSubjectApi } from '../../api/study/subject';
import { showNotification } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../../components/Loading';
import { Modal, Button, Switch, Checkbox } from 'antd';
import HeaderLottie from '../../components/HeaderLottie';
import hotline from '../../assets/lottie/hotline.json'
import { TitleSuccess } from '../../components/frame';
import TooltipUser from '../../components/TooltipUser';

class StudySubject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSubject: [],
            pageId: 1,
            isLoading: true,
            endList: false,
            inputSearch: '',
            showModal: false,
            modalType: 1,
            selectSubject: {},
            isPress: false,
            listTeacher: [],
            loadingModal: true,
            subjectData: ''
        }
        this.timeoutSearch = ''
    }

    getListSubject = (pageId = 1) => {
        getListSubjectApi(pageId, this.state.inputSearch).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listSubject: pageId == 1 ? res.data : [...this.state.listSubject, ...res.data],
                    endList: res.data.length < 10,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    changeInputSearch = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.timeoutSearch = setTimeout(() => {
            this.getListSubject(1);
        }, 500);
    }

    getListTeacher = (studySubjectId) =>{
        getListTeacherApi(studySubjectId).then(res =>{
            if(res.code == 1){
                this.setState({
                    listTeacher: res.data,
                    isPress: false,
                    loadingModal: false
                })
            }
            else {
                showNotification(res.message, 0);
                this.setState({isPress: false})
            };
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
            this.setState({isPress: false})
        })
    }

    showModalJoinClass = (data) =>{
        if(!this.state.isPress){
            if(data.IsStudent){
                this.setState({
                    isPress: true,
                    loadingModal: true,
                    showModal: true,
                    modalType: 1,
                    selectSubject: data
                })
                this.getListTeacher(data.StudySubjectId);
            }
            else{
                this.setState({
                    showModal: true,
                    modalType: 2,
                    selectSubject: data
                })
            }
        }
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListSubject(this.state.pageId + 1);
        }
    }

    renderModal = () =>{
        const {selectSubject, loadingModal, listTeacher, subjectData, modalType} = this.state;
        switch (modalType) {
            case 1:
                return(
                    <div className='item-center'>
                        <TitleSuccess>Chọn giáo viên</TitleSuccess>
                        {loadingModal ?
                            <Loading small />
                        :
                            <>
                                {listTeacher.length > 0 &&
                                    <>
                                        {listTeacher.map((item, key) =>
                                            <div
                                                className='box-primary flex align-center pointer'
                                                key={key}
                                                onClick={() => this.selectTeacherSubject(item)}
                                            >
                                                <div className='flex-row flex-1 align-center'>
                                                    <TooltipUser userInfo={item} />
                                                    <p className='mgl-2'>{item.FullName} ({item.CurrentStudent}/{item.MaxStudent})</p>
                                                </div>
                                                <Checkbox
                                                    checked={item.StudyClassUserId > 0}
                                                />
                                            </div>
                                        )}
                                        <Button
                                            className='bg-success mgt-4'
                                            onClick={this.props.onNavigateStudyLearn}
                                        >
                                            → Đến bài học
                                        </Button>
                                    </>
                                }
                            </>
                        }
                    </div>
                )
            case 2:
                return(
                    <div className='item-center'>
                        <HeaderLottie
                            source={hotline}
                        />
                        <p className='text-large'>Vui lòng gọi hotline: {selectSubject.Hotline} để đăng ký học</p>
                    </div>
                )
            case 3:
                return(
                    <>
                        <p className='text-title-study-subject text-center mgt mgb'>{subjectData.Subject}</p>
                            <div className='box-info-subject' dangerouslySetInnerHTML={{__html: `<p>${subjectData.Info}</p>`}}>
                        </div>
                    </>
                )
        }
        return null
    }

    selectTeacherSubject = (data) =>{
        if(!this.state.isPress){
            this.setState({isPress: true})
            selectTeacherSubjectApi(data.StudyClassId).then(res =>{
                showNotification(res.message, res.code);
                this.setState({isPress: false});
                if(res.code == 1){
                    this.setState({
                        listTeacher: this.state.listTeacher.map(item =>{
                            if(item.StudyClassId == data.StudyClassId){
                                return{
                                    ...item,
                                    StudyClassUserId: res.selectTeacher
                                }
                            }
                            else return {
                                ...item,
                                StudyClassUserId: null
                            }
                        })
                    })
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false})
            })
        }
    }

    toogleInfoSubject = (studySubjectId) =>{
        this.setState({
            showInfoSubjectId: studySubjectId == this.state.showInfoSubjectId ? 0 : studySubjectId
        })
    }

    componentDidMount() {
        this.getListSubject()
    }

    render() {
        const { inputSearch, isLoading, listSubject, endList, showModal, showInfoSubjectId } = this.state;
        return (
            <>
                <p className='text-huge text-center bold mgt-2'>DANH SÁCH MÔN HỌC</p>
                <input
                    type='text'
                    className='form-control mgt radius-max'
                    value={inputSearch}
                    onChange={this.changeInputSearch}
                    placeholder='Nhập tên môn học'
                />
                {isLoading ?
                    <Loading small/>
                :
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={!endList}
                        loader={<Loading key={0} small />}
                        threshold={100}
                        className='mgt-2'
                    >
                        {listSubject.map((item, key) =>
                            <div className='box-subject' key={key}>
                                <div className='row align-center' style={{width: '100%'}}>
                                    <div className='col-md-8 col-sm-8 col-xs-12'>
                                        <p className='text-title-study-subject'>{item.Subject}</p>
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12 flex col-action-study-subject'>
                                        {item.Info &&
                                            <Button
                                                className='bg-warning radius-max mgr'
                                                onClick={() =>this.setState({
                                                    showModal: true,
                                                    modalType: 3,
                                                    subjectData: item
                                                })}
                                            >
                                                Thông tin
                                            </Button>
                                        }
                                        {/* <Button
                                            className={`radius-max ${(item.IsStudent ? 'bg-success' : 'bg-primary')}`}
                                            onClick={() => this.showModalJoinClass(item)}
                                        >
                                            Tham gia
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(StudySubject));