const INITAL_STATE = {
    dataUser: [],
    crownUse: false,
    assetUse: false,
    cardUse: false,
    listCrest: [],
    isRefreshing: false,
    isLoading: true,
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_USER_DATA':
            return { ...state, dataUser: action.dataUser, isLoading: false };
        case 'SUCCESS_GET_USER_BAR':
            return { ...state, houseUse: action.houseUse, crownUse: action.crownUse, cardUse: action.cardUse, listCrest: action.listCrest };
        case 'CHANGE_POINT':
            let ticketCount = action.point[0] || 0;
            let balance = action.point[1] || 0;
            let balanceZ = action.point[2] || 0;
            let achievement = action.point[3] || 0;
            let userLike = action.point[4] || 0;
            let diamondRemain = action.point[5] || 0;
            return { ...state,
                dataUser: {
                    ...state.dataUser,
                    TicketCount: parseFloat(state.dataUser.TicketCount) + ticketCount,
                    Balance: parseInt(state.dataUser.Balance) + parseInt(balance),
                    BalanceZ: parseInt(state.dataUser.BalanceZ) + parseInt(balanceZ),
                    Achievement: parseInt(state.dataUser.Achievement) + parseInt(achievement),
                    UserLike: parseInt(state.dataUser.UserLike) + parseInt(userLike),
                    DiamondRemain: parseInt(state.dataUser.DiamondRemain) + parseInt(diamondRemain)
                }
            }
        case 'UPDATE_DATA_USER':
            return { ...state,
                dataUser: {
                    ...state.dataUser,
                    [action.property]: action.value,
                }
            }
        case 'FALSE_GET_USER_DATA':
            return { ...state, isLoading: true };
        default:
            return state;
    }
};