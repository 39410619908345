import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListSearchVideoApi = (VideoId) =>{
    let url = `${URI_PATH}app/video/search/getListSearch`;
    let body = {
        VideoId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}