import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import Loading from '../../components/Loading';
import { Button, Modal, Checkbox, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { getListProgramApi, getProgramInfoApi, changeUnderstandProgramApi, deleteProgramApi, addProgramApi, getListUserUnderstandApi, editProgramApi } from '../../api/club/program';
import { EmptyLottie, TitleSuccess } from '../../components/frame';
import Comment from '../../components/newfeed/comment';
import ListComponent from '../../components/newfeed/item';
import { appendListNewfeed, clearNewfeedData } from '../../actions/newfeed';
import TooltipUser from '../TooltipUser';

class ClubInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            isLoadingProgram: true,
            listProgram: [],
            selectInfoProgram: '',
            isLoadingProgramInfo: true,
            showModal: false,
            modalType: 'delete',
            progamDetail: {},
            programTitleAdd: '',
            programTypeAdd: 1,
            programIdAdd: '',
            programLinkAdd: '',
            listUnderstand: []
        }
    }

    getInfoProgram = (clubProgramId) => {
        if (clubProgramId != this.state.selectInfoProgram) {
            this.setState({
                selectInfoProgram: clubProgramId,
                isLoadingProgramInfo: true
            })
            getProgramInfoApi(clubProgramId).then(res => {
                if (res.code == 1) {
                    this.props.appendListNewfeed(res.data);
                    this.setState({ isLoadingProgramInfo: false })
                }
                else {
                    showNotification(MESSAGE.ERROR, 0);
                }
            }).catch(e => showNotification(MESSAGE.ERROR, 0))
        }
    }

    changeUnderstandArr = (clubProgramId, isUnderstand) => {
        this.setState({
            listProgram: this.state.listProgram.map(item => {
                if (item.ClubProgramId == clubProgramId) return ({
                    ...item,
                    IsUnderstand: isUnderstand
                });
                return item;
            })
        })
    }

    changeUnderstandProgram = (clubProgramId, currentUnderstand) => {
        this.changeUnderstandArr(clubProgramId, currentUnderstand == 2 ? 0 : 2);
        changeUnderstandProgramApi(clubProgramId, currentUnderstand).then(res => {
            if (res.code != 1) {
                this.changeUnderstandArr(clubProgramId, currentUnderstand);
                showNotification(MESSAGE.ERROR, 0);
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getListProgram = () => {
        getListProgramApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listProgram: res.listProgram,
                    userInfo: res.userInfo,
                    isLoadingProgram: false
                })
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    deleteProgram = (clubProgramId) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true })
            deleteProgramApi(clubProgramId).then(res => {
                showNotification(res.message, res.code);
                if (res.code == 1) {
                    this.setState({
                        listProgram: this.state.listProgram.filter(item => item.ClubProgramId != clubProgramId),
                        isPress: false,
                        showModal: false
                    })
                }
                else {
                    this.setState({isPress: false})
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({
                    isPress: false
                })
            })
        }
    }

    showModalEditProgram = (progamDetail) =>{
        this.setState({
            progamDetail,
            showModal: true,
            modalType: 'edit'
        })
    }

    isPowerClub = () => ['1', '2'].includes(this.state.userInfo.ClubRoleId); // check chủ nhiệm & phó chủ nhiệm

    renderAction = (clubProgam) => {
        if (this.isPowerClub()) {
            return (
                <div className='flex'>
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <p>Thành viên đã hiểu bài học</p>
                        }
                    >
                        <img
                            src={require('../../assets/image/list-checked.png')}
                            className='club-program-icon-action'
                            onClick={(e) => {
                                this.getListUserUnderstand(clubProgam.ClubProgramId)
                                e.stopPropagation();
                            }}
                        />
                    </Tooltip>
                    {/* <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <p>Sắp xếp</p>
                        }
                    >
                        <img
                            src={require('../../assets/image/sort.png')}
                            className='club-program-icon-action'
                        />
                    </Tooltip> */}
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <p>Sửa</p>
                        }
                    >
                        <img
                            src={require('../../assets/image/pencil.png')}
                            className='club-program-icon-action'
                            onClick={(e) => {
                                this.showModalEditProgram(clubProgam)
                                e.stopPropagation();
                            }}
                        />
                    </Tooltip>
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <p>Xóa</p>
                        }
                    >
                        <img
                            src={require('../../assets/image/delete-red.png')}
                            className='club-program-icon-action'
                            onClick={(e) => {
                                this.setState({
                                    showModal: true,
                                    modalType: 'delete',
                                    progamDetail: clubProgam
                                });
                                e.stopPropagation();
                            }}
                        />
                    </Tooltip>
                </div>
            )
        }
        return (
            <>
                {clubProgam.Link &&
                    <Tooltip
                        {...PROP.TOOLTIP_HOME}
                        overlayClassName='black-tooltip'
                        title={
                            <p>Tải tài liệu đính kèm</p>
                        }
                    >
                        <img
                            src={require('../../assets/image/download.png')}
                            className='club-program-icon-action'
                            onClick={(e) => {
                                window.open(clubProgam.Link);
                                e.stopPropagation();
                            }}
                        />
                    </Tooltip>
                }
                <Tooltip
                    {...PROP.TOOLTIP_HOME}
                    overlayClassName='black-tooltip'
                    title={
                        <p>Đã hiểu</p>
                    }
                >
                    <div className='club-program-checkbox'>
                        <Checkbox
                            checked={clubProgam.IsUnderstand == 2}
                            style={{ margin: '0 2vh' }}
                            onClick={(e) => {
                                this.changeUnderstandProgram(clubProgam.ClubProgramId, clubProgam.IsUnderstand);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Tooltip>
            </>
        )
    }

    addProgram = () =>{
        if(!this.state.isPress){
            let idNotification = '#notificationAddProgram';
            let {programTitleAdd, programIdAdd, programTypeAdd, programLinkAdd } = this.state;
            if(programTitleAdd && programIdAdd){
                this.setState({isPress: true});
                addProgramApi(programTitleAdd, programIdAdd, programTypeAdd, programLinkAdd).then(res =>{
                    if(res.code == 1){
                        this.setState({
                            programTitleAdd: '',
                            programTypeAdd: 1,
                            programIdAdd: '',
                            programLinkAdd: '',
                            showModal: false,
                            isPress: false
                        })
                        this.getListProgram();
                        showNotification(res.message, res.code)
                    }
                    else{
                        this.setState({isPress: false})
                        showNotification(res.message, res.code, idNotification)
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({
                        isPress: false
                    })
                })
            }
            else if(!programTitleAdd) showNotification('Vui lòng nhập tiêu đề', 0, idNotification)
            else if(!programIdAdd) showNotification(`Vui lòng nhập mã số ${programTypeAdd == 1 ? "bài viết" : "video"}`, 0, idNotification)
        }
    }

    getListUserUnderstand = (clubProgramId) =>{
        if(!this.state.isPress){
            getListUserUnderstandApi(clubProgramId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        listUnderstand: res.listUnderstand,
                        showModal: true,
                        modalType: 'understand'
                    })
                }
                else{
                    this.setState({isPress: false})
                    showNotification(res.message, res.code)
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({
                    isPress: false
                })
            })
        }
    }

    editProgram = () =>{
        if(!this.state.isPress){
            let idNotification = '#notificationEditProgram';
            let {progamDetail} = this.state;
            if(progamDetail.ProgramTitle && progamDetail.ProgramId){
                this.setState({isPress: true});
                editProgramApi(progamDetail.ClubProgramId, progamDetail.ProgramTitle, progamDetail.ProgramId, progamDetail.ProgramType, progamDetail.Link).then(res =>{
                    if(res.code == 1){
                        this.setState({
                            isPress: false,
                            listProgram: this.state.listProgram.map(item =>{
                                if(item.ClubProgramId == progamDetail.ClubProgramId){
                                    return{
                                        ...item,
                                        ...progamDetail,
                                        PostId: progamDetail.ProgramType == 1 ? progamDetail.ProgramId : null,
                                        VideoId: progamDetail.ProgramType == 2 ? progamDetail.ProgramId : null,
                                        selectInfoProgram: ''
                                    }
                                }
                                return item;
                            }),
                            showModal: false,
                            selectInfoProgram: ''
                        })
                        showNotification(res.message, res.code)
                    }
                    else{
                        this.setState({isPress: false})
                        showNotification(res.message, res.code, idNotification)
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({
                        isPress: false
                    })
                })
            }
            else if(!progamDetail.ProgramTitle) showNotification('Vui lòng nhập tiêu đề', 0, idNotification)
            else if(!progamDetail.ProgramId) showNotification(`Vui lòng nhập mã số ${progamDetail.ProgramType == 1 ? "bài viết" : "video"}`, 0, idNotification)
        }
    }

    renderModal = () => {
        const { isPress, modalType, progamDetail, programTitleAdd, programIdAdd, programTypeAdd, programLinkAdd } = this.state;
        switch (modalType) {
            case 'delete':
                return (
                    <div className='mgt-2 item-center'>
                        <p className='text-huge danger bold'>Xác nhận xóa "{progamDetail.ProgramTitle}"</p>
                        <Button
                            className='bg-danger mgt-4'
                            onClick={() => this.deleteProgram(progamDetail.ClubProgramId)}
                            loading={isPress}
                        >
                            Đồng ý
                        </Button>
                    </div>
                )
            case 'create':
                return (
                    <div className='item-center'>
                        <TitleSuccess>Thêm nội dung mới</TitleSuccess>
                        <input
                            value={programTitleAdd}
                            onChange={e => this.setState({ programTitleAdd: e.target.value })}
                            className='form-control text-center mgt'
                            placeholder='Tiêu đề nội dung đào tạo'
                        />
                        <select
                            className='form-control mgt'
                            onChange={e => this.setState({
                                programTypeAdd: e.target.value,
                                programIdAdd: ''
                            })}
                            defaultValue={programTypeAdd}
                        >
                            <option value='1'>Bài viết</option>
                            <option value='2'>Video</option>
                        </select>
                        <input
                            value={programIdAdd}
                            onChange={e => this.setState({ programIdAdd: e.target.value })}
                            className='form-control text-center mgt'
                            placeholder={`Mã số ${programTypeAdd == 1 ? "Bài viết" : "Video"}`}
                        />
                        <input
                            value={programLinkAdd}
                            onChange={e => this.setState({ programLinkAdd: e.target.value })}
                            className='form-control text-center mgt'
                            placeholder='Link tài liệu đính kèm (nếu có)'
                        />
                        <div id='notificationAddProgram' />
                        <Button
                            className='bg-success mgt-2'
                            onClick={this.addProgram}
                            loading={isPress}
                        >
                            Thêm mới
                        </Button>
                    </div>
                )
            case 'understand':
                return(
                    <>
                        <TitleSuccess>Thành viên đã hiểu bài học</TitleSuccess>
                        {this.state.listUnderstand.map((item, key) =>
                            <div
                                key={key}
                                className='club-info-list-member'
                            >
                                <div className='flex-1 item-center'>
                                    <TooltipUser userInfo={item} avatarClass='avatar-guest'/>
                                </div>
                                <div className='flex-4 item-center'>
                                    <p className='mgl-2 bold text-large'>{item.FullName}</p>
                                </div>
                            </div>
                        )}
                    </>
                )
            case 'edit':
                return (
                    <div className='item-center'>
                        <TitleSuccess>Sửa nội dung</TitleSuccess>
                        <input
                            value={progamDetail.ProgramTitle}
                            onChange={e => this.setState({ progamDetail: {...progamDetail, ProgramTitle: e.target.value} })}
                            className='form-control text-center mgt'
                            placeholder='Tiêu đề nội dung đào tạo'
                        />
                        <select
                            className='form-control mgt'
                            onChange={e => this.setState({
                                progamDetail:{
                                    ...progamDetail,
                                    ProgramType: e.target.value,
                                    ProgramId: ''
                                }}
                            )}
                            defaultValue={progamDetail.ProgramType}
                        >
                            <option value='1'>Bài viết</option>
                            <option value='2'>Video</option>
                        </select>
                        <input
                            value={progamDetail.ProgramId}
                            onChange={e => this.setState({ progamDetail: {...progamDetail, ProgramId: e.target.value} })}
                            className='form-control text-center mgt'
                            placeholder={`Mã số ${progamDetail.ProgramType == 1 ? "Bài viết" : "Video"}`}
                        />
                        <input
                            value={progamDetail.Link}
                            onChange={e => this.setState({
                                progamDetail:{
                                    ...progamDetail,
                                    Link: e.target.value
                                }
                            })}
                            className='form-control text-center mgt'
                            placeholder='Link tài liệu đính kèm (nếu có)'
                        />
                        <div id='notificationEditProgram' />
                        <Button
                            className='bg-success mgt-2'
                            onClick={this.editProgram}
                            loading={isPress}
                        >
                            Sửa nội dung
                        </Button>
                    </div>
                )
        }
        return null;
    }

    componentDidMount() {
        this.getListProgram();
    }

    componentWillUnmount() {
        this.props.clearNewfeedData();
    }

    render() {
        const { listProgram, selectInfoProgram, isLoadingProgram, isLoadingProgramInfo, showModal } = this.state;
        const { listNewfeed, dataUser } = this.props;
        if (isLoadingProgram) return <Loading small />;
        return (
            <>
                <TitleSuccess>Chương trình đào tạo {dataUser.ClubName}</TitleSuccess>
                {this.isPowerClub() &&
                    <div className='item-center'>
                        <Button
                            className='bg-success'
                            onClick={() => this.setState({
                                showModal: true,
                                modalType: 'create'
                            })}
                        >
                            Thêm nội dung mới
                        </Button>
                    </div>
                }
                {listProgram.length == 0 &&
                    <EmptyLottie title='Chưa có chương trình nào' />
                }
                {listProgram.map((item, key) =>
                    <div
                        className='container-list'
                        key={key}
                        onClick={() => this.getInfoProgram(item.ClubProgramId)}
                    >
                        <div className='flex align-center'>
                            <div className='flex'>
                                <div className='stt-list'>
                                    <p className='mgb-0 black bold text-large'>{key + 1}</p>
                                </div>
                                <img
                                    src={item.PostId ? require('../../assets/image/menu/post.png') : require('../../assets/image/menu/video.png')}
                                    className='image-club-program-type'
                                />
                            </div>
                            <div className='title-club-program-container'>
                                <p>{item.ProgramTitle}</p>
                            </div>
                            {this.renderAction(item)}
                        </div>
                        {item.ClubProgramId == selectInfoProgram &&
                            <div className='mgt-2'>
                                {isLoadingProgramInfo ?
                                    <Loading small />
                                :
                                    <>
                                        {listNewfeed.map((newfeed, key) =>
                                            <React.Fragment key={key}>
                                                {item.PostId ? <ListComponent.Post data={newfeed} /> : <ListComponent.Video data={newfeed} />}
                                                <Comment data={newfeed} />
                                            </React.Fragment>
                                        )}
                                    </>
                                }
                            </div>
                        }
                    </div>
                )}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, { appendListNewfeed, clearNewfeedData })(withRouter(ClubInfo));