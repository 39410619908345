import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint, updatePresent } from '../../actions/home/';
import { updateCountMission } from '../../actions/menu';
import { changeTimePresentApi, checkPresentApi, getTimePresentApi } from '../../api/present';
import { Button, Progress, TimePicker, Tooltip } from 'antd';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { checkEnegy, getAvatarFb, isTouch } from '../../utils/common';
import { presentApi } from '../../api/present';
import { MESSAGE } from '../../utils/constants';
import CountUp from 'react-countup';
import QuestionGift from '../../components/gift/QuestionGift';
import moment from 'moment';
import BlackTooltip from '../frame/BlackTooltip';
import Loading from '../Loading';

class Present extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            qoute: '',
            author: '',
            cost: '',
            showPresent: false,
            showGiftQuestion: false,
            presentTime: '',
            isLoading: true
        };
    }

    checkPresent = () => {
        if (!this.state.isPress) {
            checkEnegy().then(res =>{
                this.setState({ isPress: true });
                let idNotification = '#notificationGiftPresentCheck';
                checkPresentApi().then((res) => {
                    if (res.code == 1) {
                        this.setState({
                            showGiftQuestion: true,
                            isPress: false
                        });
                    }
                    else {
                        this.setState({ isPress: false });
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch((e) => {
                    this.setState({ isPress: false })
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                });
            })
        }
    }

    present = () => {
        presentApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    qoute: res.qouteContent,
                    author: res.author,
                    cost: res.cost,
                    showPresent: true,
                });
                this.props.updatePresent(res.userPresentData);
                setTimeout(() => {
                    showNotificationBonus(res.cost, 1, 2);
                    this.props.changePoint([null, res.cost]);
                    this.props.updateCountMission('GiftDayCount');
                }, 4000);
            }
            else showNotification(res.message, res.code);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    getTimePresent = () => {
        getTimePresentApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    presentTime: res.time,
                    isLoading: false
                })
            }
        })
    }

    saveTime = (open) =>{
        if(!open){
            changeTimePresentApi(this.state.presentTime).then(res =>{
                showNotification(res.message, res.code)
                if(res.code == -1){
                    this.getTimePresent();
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1);
            })
        }
    }

    componentDidMount() {
        this.getTimePresent();
    }

    render() {
        let { isPresent, userPresentData, presentData } = this.props;
        let { FullName, FacebookId } = this.props.dataUser;
        let { isPress, showGiftQuestion, showPresent, qoute, author, cost, presentTime, isLoading } = this.state;
        const presentTimeEnd = moment(presentTime, "hh:mm").add(15, 'minutes').format('hh:mm')
        if (isPresent !== false) {
            return (
                <div>
                    {isLoading ? <Loading small /> :
                        <div className='mgt'>
                            <div className='flex-column item-center'>
                                <p className='bold text-title-gift'>Quà thử thách</p>
                            </div>
                            <div className='container-list-gift' style={{padding: '2vh'}}>
                                <div className='flex-column item-center'>
                                    <p className='mgt mgb'>Đặt mục tiêu dậy sớm sáng mai (04:00 ~ 08:00)</p>
                                    <TimePicker
                                        ref={e => this.timePicler = e}
                                        value={moment(presentTime, "HH:mm")}
                                        format={"HH:mm"}
                                        onChange={(time, timeString) => this.setState({presentTime: timeString})}
                                        onOpenChange={this.saveTime}
                                    />
                                </div>
                            </div>
                            <div className='container-list-gift'>
                                <div id='notificationGiftPresentCheck' />
                                <div className='flex-row'>
                                    <div className='flex-3 flex-column item-center mgt'>
                                        <Progress
                                            strokeColor={{ '0%': '#dd4b39', '100%': '#87d068' }}
                                            type="dashboard"
                                            percent={userPresentData.Percent}
                                            format={percent => <p>{+percent.toFixed(2) + '%'}</p>}
                                        />
                                        <p>Thử thách Level {userPresentData.Level}: {presentData.DayTotal} ngày dậy sớm liên tiếp</p>
                                        <p className='silver'>Từ {presentTime} đến {presentTimeEnd}</p>
                                    </div>
                                    <div className='flex-2 flex-column item-center mgt'>
                                        <Button
                                            className={'mgt-2 button-open-gift ' + (isPresent > 0 && 'bg-primary')}
                                            onClick={this.checkPresent}
                                            loading={isPress}
                                        >
                                            Nhận
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {showGiftQuestion &&
                        <QuestionGift
                            onClose={() => this.setState({
                                showGiftQuestion: false,
                                showPresent: false
                            })}
                            onSuccess={this.present}
                            showOpenGift={showPresent}
                        >
                            {showPresent &&
                                <div>
                                    <p className='text-center text-large bold'>Quà chào ngày mới</p>
                                    <div className='row mgt-3'>
                                        <div className='col-md-4 col-xs-12 item-center'>
                                            <img
                                                src={getAvatarFb(FacebookId)}
                                                className='avatar-gift'
                                            />
                                            <p className='mgt bold'>{FullName}</p>
                                        </div>
                                        <div className='col-md-8 col-xs-12 item-center'>
                                            <p className='mgt qoute-gift'>{qoute}</p>
                                            <p className='mgt qoute-gift mgt text-right'>{author}</p>
                                        </div>
                                    </div>
                                    <div className='mgt-4 text-center bold text-large'>
                                        Bạn nhận được món quà là <CountUp end={cost} duration={4} /> $[z]
                                    </div>
                                </div>
                            }
                        </QuestionGift>
                    }
                </div>
            )
        }
        return (<div />)
    }
}

const mapStateToProps = (state) => {
    const { isPresent, userPresentData, presentData } = state.getUserPresent;
    const { dataUser } = state.getUserData;
    return { isPresent, userPresentData, dataUser, presentData };
}

export default connect(mapStateToProps, { changePoint, updatePresent, updateCountMission })((Present));