import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListItemApi = (itemType) =>{
    let url = `${URI_PATH}app/charge/chargemarket/getListItem`;
    let body = {
        ItemType: itemType
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyApi = (chargemarketId, amountBuy) =>{
    let url = `${URI_PATH}app/charge/chargemarket/buy`;
    let body = {
        ChargemarketId: chargemarketId,
        AmountBuy: amountBuy
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}