import {getListApi, getListUserTransactionApi} from '../../api/charge/blackmarket';

export const getList = (selectItem = 0, page = 1) =>{
    return dispatch =>{
        dispatch({ type: 'INIT_GET_LIST_BLACK_MARKET', selectItem: selectItem });
        if(selectItem == 0 || selectItem == 1){
            let type = selectItem == 0 ? 2 : 1;
            getListApi(type, page).then(res => {
                if(res.code == 1){
                    if(type == 1) dispatch({ type: 'SUCCESS_GET_LIST_BUY_BLACK_MARKET', listBuy: res.listBuy, pageBuy: page, selectItem: selectItem});
                    else dispatch({ type: 'SUCCESS_GET_LIST_SELL_BLACK_MARKET', listSell: res.listSell, pageSell: page, selectItem: selectItem});
                }
                else dispatch({ type: 'FALSE_GET_LIST_BLACK_MARKET' });
            })
            .catch(error => dispatch({ type: 'FALSE_GET_LIST_BLACK_MARKET' }));
        }
        else{
            getListUserTransactionApi(page).then(res => {
                if(res.code == 1){
                    dispatch({ type: 'SUCCESS_GET_LIST_TRANSACTION_BLACK_MARKET', listTransaction: res.listTransaction, pageTransaction: page, selectItem: selectItem});
                }
                else dispatch({ type: 'FALSE_GET_LIST_BLACK_MARKET' });
            })
            .catch(error => dispatch({ type: 'FALSE_GET_LIST_BLACK_MARKET' }));
        }
    }
}

export const deleteTransaction = (blackmarketId) =>{
    return dispatch =>{
        dispatch({ type: 'DELETE_TRANSACTION_BLACK_MARKET', blackmarketId: blackmarketId });
    }
}