const INITAL_STATE = {
    dataItem0: [], // food
    dataItem1: [], // asset
    dataItem2: [], // invest đ[z]
    dataItem3: [], // đồ thiết yếu
    dataItem4: [], // đồ cao cấp
    dataItem5: [], // invest $[z]
    loadingItem0: true,
    loadingItem1: true,
    loadingItem2: true,
    loadingItem3: true,
    loadingItem4: true,
    loadingItem5: true,
    listFoodParty: [],
    balanceAll: 0,
    lifeExpire: 0
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_GET_USER_ITEM':
            return { ...state, selectItem: action.selectItem, balanceAll: 0};
        case 'SUCCESS_GET_USER_ITEM':
            let {selectItem, dataItem, balanceAll, lifeExpire} = action;
            if(selectItem == 0) return { ...state, dataItem0: dataItem, balanceAll: 0, selectItem, lifeExpire, loadingItem0: false };
            else if(selectItem == 1) return { ...state, dataItem1: dataItem, balanceAll: 0, selectItem, loadingItem1: false };
            else if(selectItem == 2) return { ...state, dataItem2: dataItem, balanceAll, selectItem, loadingItem2: false };
            else if(selectItem == 3) return { ...state, dataItem3: dataItem, balanceAll, selectItem, loadingItem3: false };
            else if(selectItem == 4) return { ...state, dataItem4: dataItem, balanceAll, selectItem, loadingItem4: false };
            else if(selectItem == 5) return { ...state, dataItem5: dataItem, balanceAll, selectItem, loadingItem5: false };
        case 'SUCCESS_GET_LIST_FOOD_PARTY':
            return { ...state, listFoodParty: action.listFoodParty };
        case 'FALSE_GET_USER_ITEM': case 'FALSE_GET_LIST_FOOD_PARTY':
            return { ...state, selectItem: action.selectItem };
        default:
            return state;
    }
};