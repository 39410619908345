import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const searchTextApi = (InputSearch) =>{
    let url = `${URI_PATH}app/search/Action/searchText`;
    let body = {
        InputSearch
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}