import { getListPackageApi } from '../../api/study/user';

export const getListPackage = () =>{
    return dispatch => {
        getListPackageApi().then((res) => {
            if(res.code == 1){
                // dispatch({ type: 'SUCCESS_GET_LIST_PACKAGE', payload: res.data, showModal: res.showModal})
                dispatch({ type: 'SUCCESS_GET_LIST_PACKAGE', payload: res.data, showModal: false})
            }
            else dispatch({ type: 'FALSE_GET_LIST_PACKAGE' });
        })
        .catch(error =>{
            dispatch({ type: 'FALSE_GET_LIST_PACKAGE' })
        })
    }
}

export const showHideModal = (showModal = false) =>{
    return { type: 'SHOW_HIDE_MODAL_PACKAGE', payload: showModal }
}