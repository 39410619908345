import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListAdminApi = () =>{
    let url = `${URI_PATH}app/admin/study/admin/getListAdmin`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addAdminApi = (Subject, Hotline, FullName, PhoneNumber, UserPass, UserId) =>{
    let url = `${URI_PATH}app/admin/study/admin/addAdmin`;
    let body = {
        Subject,
        Hotline,
        FullName,
        PhoneNumber,
        UserPass,
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteAdminApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/admin/deleteAdmin`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAdminInfoApi = () =>{
    let url = `${URI_PATH}app/admin/study/admin/getAdminInfo`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeSubjectAdminApi = (Subject, Hotline) =>{
    let url = `${URI_PATH}app/admin/study/admin/changeSubjectAdmin`;
    let body = {
        Subject,
        Hotline
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changePasswordAdminApi = (UserPass) =>{
    let url = `${URI_PATH}app/admin/study/admin/changePasswordAdmin`;
    let body = {
        UserPass
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}