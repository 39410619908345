import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const checkPresentApi = () =>{
    let url = `${URI_PATH}app/present/action/checkPresent`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const presentApi = () =>{
    let url = `${URI_PATH}app/present/action/present`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListQuestionApi = () =>{
    let url = `${URI_PATH}app/present/action/getListQuestion`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkAnswerApi = (questionId, answer) =>{
    let url = `${URI_PATH}app/present/action/checkAnswer`;
    let body = {
        QuestionId: questionId,
        Answer: answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getTimePresentApi = () =>{
    let url = `${URI_PATH}app/present/action/getTimePresent`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeTimePresentApi = (Time) =>{
    let url = `${URI_PATH}app/present/action/changeTimePresent`;
    let body = {
        Time
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}