import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ getUserItem } from '../../actions/home';
import Loading from '../../components/Loading';
import ListItem from '../../components/home/ListItem';
import HeaderLottie from '../../components/HeaderLottie';
import house from '../../assets/lottie/house.json';
import Icon from 'react-fontawesome';
import ListNewfeed from '../../components/newfeed';
import {getListNewfeedUser, clearNewfeedData} from '../../actions/newfeed';
import {TitleSuccess} from '../../components/frame';
import {getUserPrestigeApi} from '../../api/home';
import {MESSAGE} from '../../utils/constants';
import {showNotification} from '../../utils/notification';
import ListVestige from '../../components/home/ListVestige';
import BarSelect from '../../components/frame/BarSelect';

class Property extends Component {
    constructor(props){
        super(props);
        this.state={
            userVestige: {},
            isLoadingUserVestige: true,
        }
        this.typeNavigate = 1;
        this.userId = this.props.dataUser.UserId;
    }

    getUserVestige = () =>{
        getUserPrestigeApi(this.userId).then(res =>{
            if(res.code == 1){
                this.setState({
                    userVestige: res.userVestige,
                    isLoadingUserVestige: false
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    selectNavigate = type =>{
        if(this.typeNavigate != type){
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.typeNavigate = type;
            if(type == 2 && this.props.isLoadingNewfeed){ // check chưa load list newfeed
                this.props.getListNewfeedUser(1, this.userId);
            }
            else if(type == 3 && this.state.isLoadingUserVestige){
                this.getUserVestige();
            }
            else this.setState({});
        }
    }

    renderNavigate = () =>(
        <>
            <div style={{height: 40}} />
            <BarSelect>
                <Icon
                    name='home'
                    className={'pv-navigate ' + (this.typeNavigate == 1 && 'success')}
                    style={{fontSize: '6vh', margin: '0vh 1.5vh'}}
                    onClick={() => this.selectNavigate(1)}
                />
                <Icon
                    name='child'
                    className={'pv-navigate ' + (this.typeNavigate == 2 && 'success')}
                    style={{fontSize: '6vh', margin: '0vh 1.5vh', position: 'relative', bottom: '0.2vh'}}
                    onClick={() => this.selectNavigate(2)}
                />
                {/* <Icon
                    name='tasks'
                    className={'pv-navigate ' + (this.typeNavigate == 3 && 'success')}
                    style={{fontSize: '5vh', margin: '0vh 1.5vh', position: 'relative'}}
                    onClick={() => this.selectNavigate(3)}
                /> */}
            </BarSelect>
        </>
    )

    renderMainNavigate = () =>{
        switch (this.typeNavigate) {
            case 1:
                return <ListItem />
            case 2:
                return(
                    <>
                        <TitleSuccess>Hoạt động gần đây</TitleSuccess>
                        <ListNewfeed userId={this.userId} />
                    </>
                )
            case 3:
                let {userVestige, isLoadingUserVestige} = this.state;
                return(
                    <>
                        <TitleSuccess>Thành tích</TitleSuccess>
                        <ListVestige
                            userVestige={userVestige}
                            isLoadingUserVestige={isLoadingUserVestige}
                        />
                    </>
                )
        }
    }

    componentDidMount(){
        this.props.getUserItem(0, true);
    }

    // componentWillUnmount(){
    //     !this.props.isLoadingNewfeed && this.props.clearNewfeedData();
    // }

    render(){
        if (this.props.isLoading) return (<Loading />);
        return(
            <div>
                <HeaderLottie source={house} disableLoop />
                {this.renderMainNavigate()}
                {/* {this.renderNavigate()} */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser, isLoading } = state.getUserData;
    const isLoadingNewfeed = state.getListNewfeed.isLoading;
    return { dataUser, isLoading, isLoadingNewfeed };
}

export default connect(mapStateToProps, { getUserItem, getListNewfeedUser, clearNewfeedData })(Property);