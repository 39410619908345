import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const sendChatApi = (Message, IsImage, StudyStudentId) =>{
    let url = `${URI_PATH}app/study/chat/sendChat`;
    let body = {
        Message, 
        IsImage,
        StudyStudentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListChatApi = (StudyStudentId) =>{
    let url = `${URI_PATH}app/study/chat/getListChat`;
    let body = {
        StudyStudentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListStudyPeopleChatApi = () =>{
    let url = `${URI_PATH}app/study/chat/getListStudyPeopleChat`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}