import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification } from '../../../utils/notification';
import { MESSAGE } from '../../../utils/constants';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { updateDataUser } from '../../../actions/home'
import { getUserLevelApi } from '../../../api/study/alpha';

class ZAlphaRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            userLevel: {}
        }
    }

    getUserLevel = () =>{
        getUserLevelApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    userLevel: res.dataUser,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    componentDidMount(){
        this.getUserLevel();
    }

    render() {
        const {userLevel, isLoading} = this.state;
        if(isLoading) return <Loading small />
        if(userLevel.Level > 1){
            return(
                <>
                    <div className='study-register-test-container'>
                        <p className='text-large bold text-center mgt-2'><i>BẠN ĐÃ ĐỦ ĐIỀU KIỆN ĐĂNG KÝ CHƯƠNG TRÌNH</i></p>
                        <p className='text-center'><i>Kiến Thức Nền Level 1: Xây dựng bản kế hoạch cuộc đời.</i></p>
                        <p className='mgt-3 mgb-3'><i>Thanh toán học phí tháng đầu tiên bằng 2 cách:</i></p>
                        <p className='bold'><i><u>Cách 1: Thanh toán bằng Gold[z].</u></i></p>
                        <p className='text-content-study-register'><i>{`• Số lượng: 1 Gold[z]/tháng. 
• Chuyển 1 Gold[z] tới số 0978737871 nội dung: “KTN Level 1”.
`}</i></p>
                                <p className='bold mgt-2'><i><u>Cách 2: Thanh toán bằng tiền mặt.</u></i></p>
                                <p className='text-content-study-register'><i>{`• Học phí: 2.000.000 đ/tháng (giảm 10% khi đóng theo năm).
• Chuyển khoản với nội dung: “Số điện thoại, KTN Level 1”.

• Ngân hàng Techcombank
• Chủ tài khoản: Nguyễn Minh Ngọc
• Số tài khoản: 7871888888
• Chi nhánh: Hoàng Gia.`}</i></p>
                        <div className='item-center mgt-3'>
                            <Button
                                className='button-done-register-study bg-success'
                                onClick={() => this.props.history.push('menu/charge')}
                            >
                                Chuyển Gold[z]
                            </Button>
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <div className='study-register-test-container'>
                    <p className='text-large bold text-center mgt-2'><i>GHI DANH KIẾN THỨC NỀN LEVEL 1</i></p>
                    <p className='text-center'><i>Xây dựng bản kế hoạch cuộc đời của một Founder & CEO</i></p>
                    <p className='text-center' style={{marginTop: '10vh'}}><i>Bài kiểm tra năng lực đầu vào của môn học:</i></p>
                    <p className='text-center'><i>Chinh phục <span className='bold'>“Thử thách Z ALPHA Level 1”</span></i></p>
                    <p className='text-center'><i>Chúc bạn thành công!</i></p>
                    <div className='item-center mgt-3'>
                        <Button
                            className='button-done-register-study bg-success'
                            onClick={this.props.onClickZLevel}
                        >
                            Chinh phục
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { updateDataUser })(withRouter(ZAlphaRegister))