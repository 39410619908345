import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataSpinApi = () =>{
    let url = `${URI_PATH}app/gift/spin/getDataSpin`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getHintApi = (listFoodSelect, listAssetSelect) =>{
    let url = `${URI_PATH}app/gift/spin/getHint`;
    let body = {
        ListFoodSelect: listFoodSelect,
        ListAssetSelect: listAssetSelect,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionSpinApi = (listFoodSelect, listAssetSelect) =>{
    let url = `${URI_PATH}app/gift/spin/actionSpin`;
    let body = {
        ListFoodSelect: listFoodSelect,
        ListAssetSelect: listAssetSelect,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}