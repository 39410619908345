import React, { Component } from 'react';
import { getAvatarFb } from '../../../utils/common';
import {withRouter} from 'react-router-dom';
import {Button} from 'antd';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class QuestionAdd extends Component{
    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p>
                        <span className='bold'>
                        {data.FullName}
                        <Checked data={data} />
                        </span> đóng góp vào ngân hàng ngoại ngữ khởi nghiệp:</p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <p className='text-large text-center mgt-2'>{data.QuestionTitle}</p>
                <div className='row item-center mgt-2'>
                    <div className='col-md-6 col-sm-8 col-xs-12 box-primary bg-success'>
                        <p>{data.Answear1}</p>
                    </div>
                </div>
                <div className='row item-center mgt'>
                    <div className='col-md-6 col-sm-8 col-xs-12 box-primary'>
                        <p>{data.Answear2}</p>
                    </div>
                </div>
                <div className='row item-center mgt'>
                    <div className='col-md-6 col-sm-8 col-xs-12 box-primary'>
                        <p>{data.Answear3}</p>
                    </div>
                </div>
                <div className='row item-center mgt'>
                    <div className='col-md-6 col-sm-8 col-xs-12 box-primary'>
                        <p>{data.Answear4}</p>
                    </div>
                </div>
                {!data.Guest &&
                    <div className='item-center'>
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={() => this.props.history.push('/menu/question-vote')}
                        >
                            Bỏ phiếu
                        </Button>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(QuestionAdd);