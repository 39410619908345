import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListSearchPostApi = (PostId) =>{
    let url = `${URI_PATH}app/post/search/getListSearch`;
    let body = {
        PostId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}