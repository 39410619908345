import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ changePoint } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getListRegisterApi,refuseRequestApi} from '../../api/course/own';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import { Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

class CourseOwn extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            listRegister: [],
            endList: false
        }
        this.page = 1;
    }

    getListRegister = () =>{
        getListRegisterApi(this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listRegister: [...this.state.listRegister, ...res.listRegister],
                    endList: res.listRegister.length == 0
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    refuseRequest = courseRegisterId =>{
        let {isPress, listRegister} = this.state;
        if(!isPress){
            this.setState({isPress: courseRegisterId});
            let idNotification = '#notificationListRegister' + courseRegisterId;
            refuseRequestApi(courseRegisterId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        listRegister: listRegister.map(el => {
                            if(el.CourseRegisterId == courseRegisterId) return Object.assign({}, el, {
                                CourseRefuseId: el.CourseRefuseId === null ? 1 : null
                            })
                            return el;
                        })
                    })
                }
                else this.setState({isPress: false});
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    loadMore = () =>{
        this.page += 1;
        this.getListRegister();
    }

    componentDidMount(){
        this.getListRegister();
    }

    render(){
        let {isLoading, endList, listRegister, isPress} = this.state;
        if(isLoading) return <Loading />
        if(listRegister.length == 0){
            return(
                <div className='item-center'>
                    <HeaderLottie source={empty} disableLoop />
                    <p className='text-large mgt'>Bạn chưa đăng kí khoá học nào</p>
                </div>
            )
        }
        return(
            <InfiniteScroll
                loadMore={this.loadMore}
                hasMore={!endList}
                loader={<Loading key={0} small/>}
                threshold={50}
            >
                {listRegister.map((item, key) =>
                    <div className='box-primary' key={key}>
                        <div className='flex-column item-center mgb'>
                            <p className='bold title-success'>{item.CourseName}</p>
                        </div>
                        <p>Ngày đăng kí: {item.CrDateTime}</p>
                        <p>Họ và tên: {item.StudentName}</p>
                        <p>Ngày sinh: {item.BirthDay}</p>
                        <p>4 số cuối giấy tờ tuỳ thân: {item.IDCardNumber}</p>
                        <p>Số điện thoại: {item.PhoneNumber}</p>
                        {item.ByFullName &&
                            <p>Đăng kí bởi: {item.ByFullName}</p>
                        }
                        <div id={'notificationListRegister' + item.CourseRegisterId} />
                        <div className='flex-row mgt'>
                            <Button 
                                className={'mgr ' + (item.CourseRefuseId ? 'bg-warning' : 'bg-danger')}
                                onClick={() => this.refuseRequest(item.CourseRegisterId)}
                                loading={isPress == item.CourseRegisterId}
                            >
                                {item.CourseRefuseId ? 'Huỷ hoàn tiền' : 'Hoàn tiền'}
                            </Button>
                            {/* <Button 
                                className='bg-success'
                            >
                                Xem chuyên cần
                            </Button> */}
                        </div>
                    </div>
                )}
            </InfiniteScroll>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(CourseOwn);