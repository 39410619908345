import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListPeopleApi = () =>{
    let url = `${URI_PATH}app/chat/chatUser/getListPeople`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListChatPeopleApi = userId =>{
    let url = `${URI_PATH}app/chat/chatUser/getListChat`;
    let body = {
        UserTarget: userId,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sendChatPeopleApi = (message, userId, isImage = '', replyChatId = '') =>{
    let url = `${URI_PATH}app/chat/chatUser/save`;
    let body = {
        Message: message,
        UserReceive: userId,
        IsImage: isImage,
        ReplyChatId : replyChatId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}