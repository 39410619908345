import React, { Component } from 'react';
import {addFoodPartyApi, sellItemApi} from '../../api/home';
import {formatDecimal, getCostInvest} from '../../utils/common';
import {URI_PATH, MESSAGE, PROP} from '../../utils/constants';
import{ getUserItem, changePoint } from '../../actions/home/';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import {showNotification, showNotificationCenter, showNotificationBonus} from '../../utils/notification';
import Chart from 'react-apexcharts';
import Cook from './Cook';
import {showModalEat} from '../../actions/home/modalEat';

class ShowModalItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            inputOne: '',
            inputTwo: '',
            isPress: false,
            calculateBalance: '',
            dataChart: []
        }
    }

    addFoodParty = (userfoodId, amount) =>{
        if(!this.state.isPress){
            let idNotification = '#notificationModalItem123';
            if(amount == '' || amount <= 0) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            addFoodPartyApi(userfoodId, amount).then(res => {
                if(res.code == 1) {
                    this.props.getUserItem(0, true);
                    this.props.hideModal();
                    showNotificationCenter(res.message, res.code);
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    sellItem = (chargemarketId, amountSell, useritemId, itemType) =>{
        if(!this.state.isPress){
            let {modalType} = this.props;
            let idNotification = modalType == 4 ? '#notificationModalItem4' : '#notificationModalItem5';
            if(amountSell == '' || amountSell < 1) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            sellItemApi(chargemarketId, amountSell, useritemId).then(res => {
                if(res.code == 1){
                    if(modalType == 4){ // cổ phiếu
                        itemType == 3 ? this.props.getUserItem(2) : this.props.getUserItem(5);
                    }
                    else if(modalType == 5) this.props.getUserItem(4); // đồ dùng
                    this.props.hideModal();
                    if(itemType == 4){
                        showNotificationBonus(res.balanceZ, 1, 2);
                        this.props.changePoint([null, res.balanceZ]);
                    }
                    else{
                        showNotificationBonus(res.balanceZ, 1, 3);
                        this.props.changePoint([null, null, res.balanceZ]);
                    }
                    showNotificationCenter(res.message, res.code);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    optionChart = (itemType) => {
        return{
            chart: {
                type: 'area',
                foreColor: '#fff',
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [70, 100]
                }
            },
            title: {
                text: itemType == 3 ? 'đ[z]' : '$[z]',
                align: 'left'
            },
            colors:['#00A65A'],
            tooltip:{
                theme: 'dark'
            }
        }
    }

    render() {
        let {modalType, itemInfo, sellRemain} = this.props;
        if([1, 2, 3].includes(modalType)){ // thêm tiệc
            return(
                <Modal
                    {...PROP.MODAL}
                    visible={this.props.isVisible}
                    onCancel={this.props.hideModal}
                    afterClose={() => this.setState({isPress: false, inputOne: '', inputTwo: ''})}
                >
                    <div>
                        <div className="row item-center flex-column">
                            <img className='item-img' alt={itemInfo.FoodName} src={`${URI_PATH}${itemInfo.FoodImage}`} />
                            <p>{itemInfo.Amount}</p>
                            <p>{itemInfo.FoodName}</p>
                            <p>{itemInfo.Hour} giờ</p>
                        </div>
                        <div className="row flex-column mgt">
                            <p className='warning bold text-center text-large'>Số lượng</p>
                            <input
                                className='form-control border-warning text-center mgt'
                                placeholder='Nhập số lượng'
                                value={this.state.inputOne}
                                onChange={e => this.setState({inputOne: e.target.value})}
                            />
                            <Button
                                className='bg-warning mgt-2'
                                loading={this.state.isPress}
                                onClick={() =>this.addFoodParty(itemInfo.UserfoodId, this.state.inputOne)}
                            >
                                Thêm vào tiệc
                            </Button>
                        </div>
                        <div id='notificationModalItem123'></div>
                    </div>
                </Modal>
            );
        }
        else if(modalType == 4){ // sell cổ phiếu
            let balanceName = itemInfo.ItemType == 3 ? 'đ[z]' : '$[z]';
            return(
                <Modal
                    {...PROP.MODAL}
                    visible={this.props.isVisible}
                    onCancel={this.props.hideModal}
                    afterClose={() => this.setState({isPress: false, inputOne: '', inputTwo: '', calculateBalance: ''})}
                >
                    <div>
                        <p className='warning bold text-center text-huge'>Bán</p>
                        <div className="row item-center flex-column">
                            <p className='warning text-center text-large mgt'>{itemInfo.ItemName}</p>
                            <img className='item-img mgt' alt={itemInfo.ItemName} src={`${URI_PATH}${itemInfo.ItemImage}`} />
                            <p className='warning bold text-center text-huge mgt'>{formatDecimal(`${itemInfo.BalanceChange} ${balanceName}/đơn vị`)}</p>
                            <p className='warning text-center text-large mgt'>Hiện có: {itemInfo.Amount} đơn vị</p>
                            <p className='warning text-center text-large mgt'>Số lượng tối đa có thể bán ở phiên này: {sellRemain} đơn vị</p>
                        </div>
                        <div className="row item-center">
                            <div className='col-xs-6'>
                                <p className='warning text-center text-large mgt'>Giá mua vào</p>
                                <input
                                    className='form-control bg-warning text-center mgt'
                                    value={formatDecimal(getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange)) + ` ${balanceName}`}
                                    disabled
                                />
                            </div>
                            <div className='col-xs-6'>
                                <p className='warning text-center text-large mgt'>Giá bán ra</p>
                                <input
                                    className='form-control bg-warning text-center mgt'
                                    value={formatDecimal(getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange, 2)) + ` ${balanceName}`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row item-center">
                            <div className='col-xs-6'>
                                <p className='warning text-center text-large mgt'>Số lượng</p>
                                <input
                                    className='form-control border-warning text-center mgt'
                                    value={`${this.state.inputOne}`}
                                    placeholder="Nhập số lượng"
                                    onChange={e =>{
                                        let amount = e.target.value;
                                        this.setState({
                                            inputOne: amount,
                                            calculateBalance:  amount > 0 ? formatDecimal(amount * getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange, 2)) : ''
                                        });
                                    }}
                                    onKeyDown={e => e.key === 'Enter' && this.sellItem(itemInfo.ChargemarketId, this.state.inputOne, itemInfo.UserItemId, itemInfo.ItemType)}
                                />
                            </div>
                            <div className='col-xs-6'>
                                <p className='warning text-center text-large mgt'>Thành tiền</p>
                                <input
                                    className='form-control border-warning text-center mgt'
                                    value={this.state.calculateBalance ? `${this.state.calculateBalance} ${balanceName}` : ''}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='row mgt-2 item-center flex-column'>
                            <p className='warning text-center text-large'>Giá mua vào gần nhất: {formatDecimal(`${itemInfo.BalanceReal}`)} {balanceName}</p>
                            {this.state.calculateBalance &&
                                <p className='warning text-center text-large mgt'>Bạn sẽ nhận được: {this.state.calculateBalance} {balanceName}</p>
                            }
                            <Button
                                className='bg-warning mgt-2'
                                loading={this.state.isPress}
                                onClick={() => this.sellItem(itemInfo.ChargemarketId, this.state.inputOne, itemInfo.UserItemId, itemInfo.ItemType)}
                            >
                                Xác nhận
                            </Button>
                        </div>
                        <div className='mgt' id='notificationModalItem4'></div>
                    </div>
                </Modal>
            )
        }
        else if(modalType == 5){ // sell item cao cấp
            return(
                <Modal
                    {...PROP.MODAL}
                    visible={this.props.isVisible}
                    onCancel={this.props.hideModal}
                    afterClose={() => this.setState({isPress: false, inputOne: '', inputTwo: ''})}
                >
                    <div>
                        <p className='warning bold text-center text-large'>Bán</p>
                        <div className="row item-center flex-column">
                            <p className='warning text-center text-large mgt'>{itemInfo.ItemName}</p>
                            <img className='item-img mgt' alt={itemInfo.ItemName} src={`${URI_PATH}${itemInfo.ItemImage}`} />
                            <p className='warning bold text-center text-large mgt'>{formatDecimal(`${itemInfo.BalanceReal} đ[z]`)}</p>
                            <Button
                                className='bg-warning mgt-2'
                                loading={this.state.isPress}
                                onClick={() => this.sellItem(itemInfo.ChargemarketId, 1, itemInfo.UserItemId)}
                            >
                                Xác nhận
                            </Button>
                        </div>
                        <div id='notificationModalItem5'></div>
                    </div>
                </Modal>
            )
        }
        else if(modalType == 6){ // chart cổ phiếu
            return(
                <Modal
                    {...PROP.MODAL}
                    visible={this.props.isVisible}
                    onCancel={this.props.hideModal}
                    width={700}
                >
                    <div className='mgt-2'>
                        <Chart
                            options={Object.assign(this.optionChart(this.props.itemInfo.ItemType), this.props.timeData)}
                            series={this.props.balanceData}
                            type='area'
                            width='100%'
                            height='400px'
                        />
                    </div>
                </Modal>
            )
        }
        else if(modalType == 7){ // nấu ăn
            return(
                <Modal
                    {...PROP.MODAL}
                    visible={this.props.isVisible}
                    onCancel={this.props.hideModal}
                    destroyOnClose={true}
                    centered
                >
                    <Cook />
                </Modal>
            )
        }
        return(<div></div>)
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {getUserItem, changePoint, showModalEat})(ShowModalItem);