import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TooltipUser from '../../../components/TooltipUser';
import { changeDataNewfeed } from '../../../actions/newfeed';
import { changePoint } from '../../../actions/home/';
import Checked from '../../frame/Checked';
import { Button } from 'antd';

class Medal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { data } = this.props;
        return (
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest' />
                    </div>
                    <div className='flex-column mgl-2'>
                        <p>
                            <span className='bold'>{data.FullName}
                                <Checked data={data} />
                            </span> đã hoàn thành nhiệm vụ Huy chương Z ngày hôm nay.
                        </p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-column item-center'>
                    <img className='bonus-crest-img' src={require('../../../assets/image/crest.png')} />
                    <p>Nhận thưởng 1000 thành tựu</p>
                    <Button
                        className='newfeed-button mgt-2'
                        onClick={() => this.props.history.push('/menu/medal')}
                    >
                        Huy chương Z
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, { changePoint, changeDataNewfeed })(withRouter(Medal));