import MainMenu from './MainMenu';
import BrandTop from './BrandTop';
import BrandOnline from './BrandOnline';
import BrandFind from './BrandFind';
import BrandGroup from './BrandGroup';
import Post from './Post';
import PostMine from './PostMine';
import PostPublish from './PostPublish';
import PostVote from './PostVote';
import PostBonus from './PostBonus';
import PostSearch from './PostSearch';
import Video from './Video';
import VideoMine from './VideoMine';
import VideoPublish from './VideoPublish';
import VideoVote from './VideoVote';
import VideoBonus from './VideoBonus';
import VideoSearch from './VideoSearch';
import ChargeInvest from './ChargeInvest';
import ChargeMarket from './ChargeMarket';
import ChargeFood from './ChargeFood';
import ChargeAsset from './ChargeAsset';
import ChargeBlackMarket from './ChargeBlackMarket';
import Charge from './Charge';
import ChargeSell from './ChargeSell';
import ChargeAssetMarket from './ChargeAssetMarket';
import ChargeAffiliate from './ChargeAffiliate';
import QuestionEvent from './QuestionEvent';
import QuestionVote from './QuestionVote';
import QuestionOwn from './QuestionOwn';
import QuestionList from './QuestionList';
import QuestionTrain from './QuestionTrain';
import QuestionFight from './QuestionFight/index';
import GiftDay from './GiftDay';
import GiftSpin from './GiftSpin';
import GiftEffort from './GiftEffort';
import GiftOffer from './GiftOffer';
import GiftTicket from './GiftTicket';
import ClubInfo from './ClubInfo';
import ClubCreate from './ClubCreate';
import ClubList from './ClubList';
import ClubMember from './ClubMember';
import ClubFortune from './ClubFortune';
import ClubLevel from './ClubLevel';
import ClubFeast from './ClubFeast';
import CourseList from './CourseList';
import CourseOwn from './CourseOwn';
import CourseCreate from './CourseCreate';
import CoursePromotion from './CoursePromotion';
import CourseManage from './CourseManage';
import SettingProfile from './SettingProfile';
import SettingSecure from './SettingSecure';
import SettingHistory from './SettingHistory';
import ConfigWallpaper from './ConfigWallpaper';
import ConfigDownload from './ConfigDownload';
import AskQuestionPublish from './AskQuestionPublish';
import AskQuestionVote from './AskQuestionVote';
import AskQuestion from './AskQuestion';
import AskQuestionSearch from './AskQuestionSearch';
import AskQuestionBonus from './AskQuestionBonus';
import AskQuestionMine from './AskQuestionMine';
import BookReviewPublish from './BookReviewPublish';
import Guide from './Guide';
import Main from './Main';
import Theory from './Theory';
import Vestige from './Vestige';
import TargetUser from './TargetUser';
import Capacity from './Capacity';
import Medal from './Medal';

export default {
    Main,
    MainMenu,
    AskQuestionPublish,
    AskQuestionVote,
    AskQuestion,
    AskQuestionSearch,
    AskQuestionBonus,
    AskQuestionMine,
    BrandTop,
    BrandOnline,
    BrandFind,
    BrandGroup,
    Post,
    PostMine,
    PostPublish,
    PostVote,
    PostBonus,
    PostSearch,
    Video,
    VideoMine,
    VideoPublish,
    VideoVote,
    VideoBonus,
    VideoSearch,
    ChargeInvest,
    ChargeMarket,
    ChargeFood,
    ChargeAsset,
    ChargeBlackMarket,
    Charge,
    ChargeSell,
    ChargeAssetMarket,
    ChargeAffiliate,
    QuestionEvent,
    QuestionVote,
    QuestionOwn,
    QuestionList,
    QuestionTrain,
    QuestionFight,
    GiftDay,
    GiftSpin,
    GiftEffort,
    GiftOffer,
    GiftTicket,
    ClubInfo,
    ClubCreate,
    ClubList,
    ClubMember,
    ClubFortune,
    ClubLevel,
    ClubFeast,
    CourseList,
    CourseOwn,
    CourseCreate,
    CoursePromotion,
    CourseManage,
    SettingProfile,
    SettingSecure,
    SettingHistory,
    ConfigWallpaper,
    ConfigDownload,
    BookReviewPublish,
    Guide,
    Theory,
    Vestige,
    TargetUser,
    Capacity,
    Medal
}