import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const diamondAskQuestionApi = (askQuestionId, userPost) =>{
    let url = `${URI_PATH}app/askquestion/action/diamond`;
    let body = {
        AskQuestionIdDiamond: askQuestionId,
        UserPost: userPost
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getRankAskQuestionApi = (askQuestionId, userPost, categoryId, subCategoryId) =>{
    let url = `${URI_PATH}app/askquestion/action/getRankAskQuestion`;
    let body = {
        AskQuestionId: askQuestionId,
        UserPost: userPost,
        CategoryId: categoryId,
        SubCategoryId: subCategoryId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const donateAskQuestionApi = (askQuestionId, balance) =>{
    let url = `${URI_PATH}app/askquestion/action/donate`;
    let body = {
        AskQuestionId: askQuestionId,
        Balance: balance
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListBonusApi = (type, page) =>{
    let url = `${URI_PATH}app/askquestion/action/getListBonus`;
    let body = {
        Type: type,
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAskQuestionSearchApi = (AskQuestionId) =>{
    let url = `${URI_PATH}app/askquestion/action/getAskQuestionSearch`;
    let body = {
        AskQuestionId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAllAskQuestionApi = (PageId, OrderType, CategoryId, SubCategoryId, SearchUser, DirectId) =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeedByType`;
    let body = {
        Type: 16,
        OrderType,
        PageId,
        CategoryId,
        SubCategoryId,
        SearchUser,
        DirectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}