import {priceFormat} from '../../utils/common';
const INITAL_STATE = {
    isLoadingSelect: true,
    listTrain: [],
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_LIST_QUESTION_TRAIN_SELECT':
            return { ...state, isLoadingSelect: false, listTrain:
                action.listTrain.map((item, index) =>{
                    let betName = item.BetType == 1 ? '$[z]' : 'đ[z]';
                    return {
                        key: item.QuestiontrainlistId,
                        label: `Cược ${priceFormat(item.BetValue)} ${betName} ${item.Title} (${item.TimeLimit} giây) `
                    }
                })
            };
        case 'FALSE_GET_LIST_QUESTION_TRAIN_SELECT':
            return { ...state, isLoadingSelect: false };
        default:
            return state;
    }
};