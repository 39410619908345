const INITAL_STATE = {
    showModalEat: false,
    listFood: []
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_MODAL_EAT_FOOD':
            return { ...state, showModalEat: true, listFood: action.listFood };
        case 'CLOSE_MODAL_EAT_FOOD':
            return { ...state, showModalEat: false, listFood: [] };
        default:
            return state;
    }
};