import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getUserDataApi = () =>{
    let url = `${URI_PATH}app/property/home/getUserData`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateStatusApi = (status) =>{
    let url = `${URI_PATH}app/property/home/updateStatus`;
    let body = {
        Status: status
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getUserItemApi = (selectItem, guestId = '') =>{
    let url = `${URI_PATH}app/property/home/getUserItem`;
    let body = {
        SelectItem: selectItem,
        GuestId: guestId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const eatFoodApi = (userfoodId) =>{
    let url = `${URI_PATH}app/property/food/eatFood`;
    let body = {
        UserfoodId: userfoodId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addFoodPartyApi = (userfoodId, amount) =>{
    let url = `${URI_PATH}app/property/food/addFoodParty`;
    let body = {
        UserfoodId: userfoodId,
        Amount: amount
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getBuySellRemainApi = (chargemarketId, type) =>{
    let url = `${URI_PATH}app/charge/chargemarket/getBuySellRemain`;
    let body = {
        ChargemarketId: chargemarketId,
        Type: type
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getChartInvestApi = (chargemarketId) =>{
    let url = `${URI_PATH}app/charge/chargemarket/getChartInvestData`;
    let body = {
        ChargemarketId: chargemarketId,
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sellItemApi = (chargemarketId, amountSell, useritemId) =>{
    let url = `${URI_PATH}app/property/item/sell`;
    let body = {
        ChargemarketId: chargemarketId,
        AmountSell: amountSell,
        UseritemId: useritemId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListFoodPartyApi = () =>{
    let url = `${URI_PATH}app/property/food/getListFoodParty`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteFoodPartyApi = (userfoodpartyId) =>{
    let url = `${URI_PATH}app/property/food/deleteFoodParty`;
    let body = {
        UserfoodpartyId: userfoodpartyId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getUserPresentApi = () =>{
    let url = `${URI_PATH}app/present/action/getUserPresent`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListResourceApi = () =>{
    let url = `${URI_PATH}app/property/cook/getListResource`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const createFoodApi = (listResource, amountCook = 1) =>{
    let url = `${URI_PATH}app/property/cook/create`;
    let body = {
        ListResource: listResource,
        AmountCook: amountCook
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getUserBarApi = (userIdView = '') =>{
    let url = `${URI_PATH}app/property/home/getUserBar`;
    let body = {
        UserIdView: userIdView
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const useAssetApi = (assetId) =>{
    let url = `${URI_PATH}app/property/asset/useAsset`;
    let body = {
        AssetId: assetId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const removeAssetApi = (assetId) =>{
    let url = `${URI_PATH}app/property/asset/removeAsset`;
    let body = {
        AssetId: assetId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateTimeOnlineApi = () =>{
    let url = `${URI_PATH}app/property/home/updateTimeOnline`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getUserPrestigeApi = userId =>{
    let url = `${URI_PATH}app/property/home/getUserPrestige`;
    let body = {
        UserId: userId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getBonusEveryDayApi = () =>{
    let url = `${URI_PATH}app/property/home/getBonusEveryDay`;
    let body = {
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const receiveBonusApi = (BonusType) =>{
    let url = `${URI_PATH}app/property/home/receiveBonus`;
    let body = {
        BonusType
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListFoodApi = () =>{
    let url = `${URI_PATH}app/property/food/getListFood`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}