import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {updateCountMission} from '../../actions/menu';
import HeaderLottie from '../../components/HeaderLottie';
import level from '../../assets/lottie/level.json';
import {Button, Progress} from 'antd';
import {priceFormat} from '../../utils/common';
import Loading from '../../components/Loading';
import {getDataLevelApi, contributeApi, getListContributeApi} from '../../api/club/level'
import { showNotification, showNotificationBonus, showNotificationAsset, showNotificationProperty } from '../../utils/notification';
import {MESSAGE} from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';
import ClubVipLevel from '../../components/club/ClubVipLevel';
import TooltipUser from '../../components/TooltipUser';
import {showModalEat} from '../../actions/home/modalEat';

class ClubLevel extends Component{
    constructor(props){
        super(props);
        this.state={
            dataLevel: {},
            listContribute: [],
            isLoading: true,
            isPress: false,
            endList: false,
        }
        this.page = 1;
    }

    getDataLevel = () =>{
        getDataLevelApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    dataLevel: res.dataLevel,
                    isLoading: false
                });
            }
            else showNotification(res.message, res.code);
        }).catch(e =>showNotification(MESSAGE.ERROR, 0));
    }

    getListContribute = () =>{
        getListContributeApi(this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listContribute: [...this.state.listContribute, ...res.listContribute],
                    endList: res.listContribute.length == 0,
                    isLoading: false
                });
            }
            else{
                this.setState({endList: true})
                showNotification(res.message, res.code);
            }
        }).catch(e =>{
            this.setState({endList: true});
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    loadMore = () =>{
        this.page += 1;
        this.getListContribute();
    }

    contribute = () =>{
        let {isPress, dataLevel, listContribute} = this.state;
        if(!isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationContribute';
            contributeApi().then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code, idNotification);
                    let bonus = res.bonus;
                    showNotificationAsset(res.assetInfo);
                    showNotificationProperty(res.property);
                    showNotificationBonus(bonus.Achievement, 1, 4);
                    showNotificationBonus(bonus.BalanceZ, -1, 3);
                    showNotificationBonus(bonus.Balance, -1, 2);
                    this.props.changePoint([null, -bonus.Balance, -bonus.BalanceZ, bonus.Achievement]);
                    this.props.updateCountMission('ContributeClubCount');
                    let {UserId, FacebookId, ClubId, FullName} = this.props.dataUser;
                    this.setState({
                        isPress: false,
                        dataLevel:{
                            ...dataLevel,
                            BalanceLevel: res.balanceLevel,
                            ContributeRemain: parseInt(dataLevel.ContributeRemain) - 1,
                            ClubBalance: parseInt(dataLevel.ClubBalance) + parseInt(dataLevel.CostContribute),
                            ClubVipLevel: res.clubVipLevel
                        },
                        listContribute:[
                            {
                                UserId,
                                FacebookId,
                                ClubId,
                                Comment: FullName + ' đã cống hiến '+ priceFormat(dataLevel.CostContribute) +' đ[z]',
                                ContributeDateTime: 'Vừa xong'
                            },
                            ...listContribute
                        ]
                    });
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            });
        }
    }

    componentDidMount(){
        this.getDataLevel();
        this.getListContribute();
    }

    render(){
        let {isLoading, dataLevel, isPress, endList, listContribute} = this.state;
        if(isLoading) return(<Loading />);
        return(
            <div>
                <HeaderLottie source={level} disableLoop/>
                <div className='flex-column item-center'>
                    <p className='bold text-gigantic mg'>{dataLevel.ClubName}</p>
                    <ClubVipLevel vipLevel={dataLevel.ClubVipLevel} className='star-level' />
                    <Progress
                        strokeColor={{'0%': '#96D896','100%': '#0bb70b'}}
                        percent={100 * dataLevel.ClubBalance / dataLevel.BalanceLevel}
                        showInfo={false}
                        status='active'
                        strokeLinecap='square'
                        className='club-level-progress mgt-3'
                    />
                    <p className='text-huge mgt bold'>
                        {priceFormat(dataLevel.ClubBalance) + ' / ' + priceFormat(dataLevel.BalanceLevel)}
                    </p>
                    <p className='mgt'>
                        Bạn còn {dataLevel.ContributeRemain} lần cống hiến {priceFormat(dataLevel.CostContribute)} đ[z] để thăng hạng nhóm trong ngày hôm nay
                    </p>
                    <Button
                        className='bg-primary flash mgt-2'
                        onClick={this.contribute}
                        loading={isPress}
                    >
                        Cống hiến
                    </Button>
                    <div className='mgt' id='notificationContribute' />
                </div>
                <div className='flex-column item-center'>
                    <p className='bold text-title-gift mg'>Lịch sử cống hiến</p>
                    {listContribute.length > 0 &&
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small/>}
                            threshold={10}
                            className='row'
                        >
                            {listContribute.map((item, key) =>
                                <div className='col-md-4 col-sm-6 col-xs-12 club-fortune-container' key={key}>
                                    <div className='item-center'>
                                        <TooltipUser userInfo={item} avatarClass='avatar-guest'/>
                                        <p className='mgt'>{item.Comment}</p>
                                        <p className='silver text-small'>{item.ContributeDateTime}</p>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, updateCountMission, showModalEat})(ClubLevel);