var firebase = require('firebase/app');
require('firebase/auth');
var firebaseConfig = {
    apiKey: "AIzaSyBMXHxPSigkuJrQIP8vy1D92nO6DXDWK3A",
    authDomain: "zcity-423fa.firebaseapp.com",
    databaseURL: "https://zcity-423fa.firebaseio.com",
    projectId: "zcity-423fa",
    storageBucket: "zcity-423fa.appspot.com",
    messagingSenderId: "968363803158",
    appId: "1:968363803158:web:0d46fddf29708222e0eb37",
    measurementId: "G-2BMQRV3XVR"
};
firebase.initializeApp(firebaseConfig);
export default firebase;
