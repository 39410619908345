import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const uploadAudioApi = (AudioTitle, IsQueue, QueueTime, AudioFile) =>{
    let url = `${URI_PATH}app/admin/audio/action/uploadAudio`;
    let formData = new FormData();
    formData.append('AudioTitle', AudioTitle);
    formData.append('IsQueue', IsQueue);
    formData.append('QueueTime', QueueTime);
    formData.append('AudioFile', AudioFile);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
	return axios.post(url, formData, config).then((res) => res.data);
}

export const getListAudioApi = (IsQueue) =>{
    let url = `${URI_PATH}app/admin/audio/action/getListAudio`;
    let body = {
        IsQueue
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteAudioApi = (AudioId) =>{
    let url = `${URI_PATH}app/admin/audio/action/deleteAudio`;
    let body = {
        AudioId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const editAudioApi = (AudioId, AudioTitle) =>{
    let url = `${URI_PATH}app/admin/audio/action/editAudio`;
    let body = {
        AudioId,
        AudioTitle
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

