import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListGiftTicketApi = (pageId) =>{
    let url = `${URI_PATH}app/gift/ticket/getListGiftTicket`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getTimeBuyTicketApi = () =>{
    let url = `${URI_PATH}app/gift/ticket/getTimeBuyTicket`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListQuestionApi = () =>{
    let url = `${URI_PATH}app/gift/ticket/getListQuestion`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerApi = (QuestionTempId, Answer) =>{
    let url = `${URI_PATH}app/gift/ticket/saveAnswer`;
    let body = {
        QuestionTempId,
        Answer
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyTicketApi = (ticket) =>{
    let url = `${URI_PATH}app/gift/ticket/buyTicket`;
    let body = {
        TicketCount: ticket
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}