import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from 'antd';
import {URI_PATH} from '../../../utils/constants';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class BookReview extends Component{
    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                            <Checked data={data} />
                        </span> review cuốn sách:</p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='pv-title-container'>
                    <p className='text-large text-center bold mgt'>{data.BookName}</p>
                    <div className='mgt'>
                        <img
                            src={`${URI_PATH}${data.BookImage}`}
                            className='pv-post-image'
                            style={{width: '20vh'}}
                        />
                        <div
                            className='text-left'
                            dangerouslySetInnerHTML={{__html: data.ReviewContent}}
                        />
                    </div>
                    {!data.Guest &&
                        <div className='item-center mgt-2'>
                            <Button
                                className='newfeed-button'
                                onClick={() => this.props.history.push('/menu/book-review')}
                            >
                                Review sách
                            </Button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(BookReview);