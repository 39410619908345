import {getListChatAllApi} from '../../api/chat/chatAll';
import {getListChatClubApi} from '../../api/chat/chatClub';
import {getListChatPeopleApi, getListPeopleApi} from '../../api/chat/chatUser';

export const getListChatAll = () => {
    return dispatch => {
        dispatch({ type: 'INIT_GET_LIST_CHAT', selectItem: 0 });
        getListChatAllApi().then(res =>{
            if(res.code == 1){
                dispatch({ type: 'SUCCESS_GET_LIST_CHAT_ALL', listChat: res.listChat});
                dispatch({ type: 'UPDATE_DATA_USER', property: 'CountChatAll', value: 0});
            }
            else dispatch({ type: 'FALSE_GET_LIST_CHAT' });
        })
        .catch(e => dispatch({ type: 'FALSE_GET_LIST_CHAT' }));
    };
};

export const getListChatClub = () => {
    return dispatch => {
        dispatch({ type: 'INIT_GET_LIST_CHAT', selectItem: 1 });
        getListChatClubApi().then(res =>{
            if(res.code == 1){
                dispatch({ type: 'SUCCESS_GET_LIST_CHAT_CLUB', listChat: res.listChat});
                dispatch({ type: 'UPDATE_DATA_USER', property: 'CountChatClub', value: 0});
            }
            else dispatch({ type: 'FALSE_GET_LIST_CHAT' });
        })
        .catch(e => dispatch({ type: 'FALSE_GET_LIST_CHAT' }));
    };
};

export const getListPeople = () =>{
    return dispatch => {
        dispatch({ type: 'INIT_GET_LIST_CHAT', selectItem: 2 });
        getListPeopleApi().then(res =>{
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_LIST_CHAT_PEOPLE', listPeople: res.listPeople});
            else dispatch({ type: 'FALSE_GET_LIST_CHAT' });
        })
        .catch(e => dispatch({ type: 'FALSE_GET_LIST_CHAT' }));
    };
}

export const getListChatPeople = (userId) =>{
    return dispatch => {
        dispatch({ type: 'INIT_GET_LIST_CHAT', selectItem: 3 });
        getListChatPeopleApi(userId).then(res =>{
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_LIST_CHAT_PEOPLE_MESSAGE', listChat: res.listChat});
            else dispatch({ type: 'FALSE_GET_LIST_CHAT' });
        })
        .catch(e => dispatch({ type: 'FALSE_GET_LIST_CHAT' }));
    }
}

export const actionChatAll = (data) =>{
    return dispatch => {
        dispatch({ type: 'ACTION_CHAT_ALL', data: data });
    }
}

export const actionChatClub = (data) =>{
    return dispatch => {
        dispatch({ type: 'ACTION_CHAT_CLUB', data: data });
    }
}

export const actionChatPeople = (data) =>{
    return dispatch => {
        dispatch({ type: 'ACTION_CHAT_PEOPLE', data: data });
    }
}

export const actionChatStudy = (selectItem) =>{
    return dispatch => {
        dispatch({ type: 'ACTION_CHAT_STUDY', selectItem: selectItem });
    }
}

export const showHideChat = (showChat, showPeople, userChatInfo) =>{
    return dispatch => {
        dispatch({ type: 'SHOW_HIDE_CHAT', showChat: showChat, showPeople: showPeople, userChatInfo: userChatInfo });
    }
}