import React, { Component } from "react";
import { connect } from "react-redux";
import { getListTargetApi } from '../../api/menu/target';
import { MESSAGE, URI_PATH } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from '../../components/Loading';
import { Button } from "antd";
import { withRouter } from "react-router";
import {toogleUserBar} from '../../actions/userbar';

class TargetUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listTarget: [],
        }
    }

    getListTarget = () => {
        getListTargetApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listTarget: res.listTarget
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    navigate = (item) => {
        switch (item.link) {
            case 'PropertyUpgrade':
                this.props.history.push('/home/upgrade');
                break;
            case 'ChargeMarket':
                if (item.typeMarket == 1) {
                    this.props.history.push('/menu/charge-market');
                }
                else this.props.history.push('/menu/charge-market', { selectItem: 1 });
                break;
            case 'UserVip':
                this.props.toogleUserBar();
                break;
        }
    }

    renderTarget = () => {
        const { isLoading, listTarget } = this.state;
        if (isLoading) return <Loading small />
        return (
            <>
                {listTarget.map((item, index) =>
                    <div className='row box-primary align-center' key={index}>
                        <div className='col-md-3 col-sm-4 col-xs-4 item-center'>
                            <img
                                src={require(`../../assets/image/target/${item.image_target}`)}
                                className='img-target'
                            />
                            <p className='title-img-target'>{item.type_target}</p>
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-4 item-center'>
                            <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                        </div>
                        <div className='col-md-2 col-sm-4 col-xs-4 item-center'>
                            <img
                                src={`${URI_PATH}${item.image}`}
                                className='img-target'
                            />
                        </div>
                        <div className='col-md-2 col-sm-6 col-xs-6 item-center'>
                            <Button
                                className={`button-buy-target`}
                                style={{ width: '95%' }}
                                onClick={() => this.navigate(item)}
                            >
                                <p className='text-button-target'>{item.action}</p>
                            </Button>
                        </div>
                        <div className='col-md-2 col-sm-6 col-xs-6 item-center'>
                            <Button
                                className={`radius-max ${item.isDone ? 'bg-success' : 'bg-warning'}`}
                                style={{ width: '95%' }}
                            >
                                <p className='text-button-target'>{item.isDone ? 'Đã làm được' : 'Chưa làm được'}</p>
                            </Button>
                        </div>
                    </div>
                )}
            </>
        )
    }

    componentDidMount() {
        this.getListTarget();
    }

    render() {
        return (
            <>
                <div className='flex-column justify-center box-primary'>
                    <p className='title-register-study'>THỬ THÁCH TRIỆU PHÚ PHÁ ĐẢO ZCITY</p>
                    <p className='subtitle-register-study'>Thách thức sinh ra để phân loại con người</p>
                    <div className='box-primary mgt'>
                        <p className='subtitle-register-study'>Phần thưởng hiện vật: Kỷ niệm chương phá đảo Zcity</p>
                    </div>
                </div>
                {this.renderTarget()}
            </>
        )
    }
}

export default connect(undefined, {toogleUserBar})(withRouter(TargetUser))