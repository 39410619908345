import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListClubApi= (pageId = 1, searchClub = '') =>{
    let url = `${URI_PATH}app/club/lists/getListClub`;
    let body = {
        PageId: pageId,
        SearchText: searchClub
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const claimClubApi= (clubId) =>{
    let url = `${URI_PATH}app/club/lists/claimClub`;
    let body = {
        ClubId: clubId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListClaimApi = () =>{
    let url = `${URI_PATH}app/club/lists/getListClaim`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}