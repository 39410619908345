import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionListApi = (page = 1, questionId = '', isRandom = '') =>{
    let url = `${URI_PATH}app/question/lists/getQuestionList`;
    let body = {
        PageId: page,
        QuestionId: questionId,
        IsRandom: isRandom
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAppealQuestionApi = (comment, questionId) =>{
    let url = `${URI_PATH}app/question/lists/saveAppealQuestion`;
    let body = {
        comment: comment,
        questionId: questionId,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}