import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getRoomInfoApi = RoomId =>{
    let url = `${URI_PATH}app/question/fight/room/getRoomInfo`;
    let body = {
        RoomId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const leaveRoomApi = RoomId =>{
    let url = `${URI_PATH}app/question/fight/room/leaveRoom`;
    let body = {
        RoomId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const editRoomApi = (RoomId, RoomName, QuestionLevelId, BetType, BetValue, MaxPlayer, TimeLimit, FightType) =>{
    let url = `${URI_PATH}app/question/fight/room/editRoom`;
    let body = {
        RoomId,
        RoomName,
        QuestionLevelId,
        BetType,
        BetValue,
        MaxPlayer,
        TimeLimit,
        FightType
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const readyApi = () =>{
    let url = `${URI_PATH}app/question/fight/room/ready`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeTeamApi = () =>{
    let url = `${URI_PATH}app/question/fight/room/changeTeam`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const kickUserApi = UserId =>{
    let url = `${URI_PATH}app/question/fight/room/kickUser`;
    let body = {
        UserId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const chatApi = Message =>{
    let url = `${URI_PATH}app/question/fight/room/chat`;
    let body = {
        Message
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListInviteApi = () =>{
    let url = `${URI_PATH}app/question/fight/room/getListInvite`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sendInviteNotificationApi = ListUserInvite =>{
    let url = `${URI_PATH}app/question/fight/room/sendInviteNotification`;
    let body = {
        ListUserInvite
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleLookApi = (Type) =>{
    let url = `${URI_PATH}app/question/fight/room/toggleLook`;
    let body = {
        Type
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListRoomChatApi = (RoomId) =>{
    let url = `${URI_PATH}app/question/fight/room/getListRoomChat`;
    let body = {
        RoomId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListGiftFightApi = () =>{
    let url = `${URI_PATH}app/question/fight/room/getListGiftFight`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sendGiftFightApi = (QuestionFightGiftId, UserIdSend) =>{
    let url = `${URI_PATH}app/question/fight/room/sendGiftFight`;
    let body = {
        QuestionFightGiftId,
        UserIdSend
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListLookApi = (RoomId) =>{
    let url = `${URI_PATH}app/question/fight/room/getListLook`;
    let body = {
        RoomId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}