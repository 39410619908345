import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ getUserData } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import secure from '../../assets/lottie/secure.json';
import { Button, Switch } from 'antd';
import { getDataSecureApi, actionSecureApi } from '../../api/setting/secure';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';

class Property extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            qrCodeUrl: '',
            secretCode: '',
            isEnabled2FA: '',
            inputCode: ''
        }
    }

    getDataSecure = () =>{
        getDataSecureApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    qrCodeUrl: res.qrCodeUrl,
                    secretCode: res.secretCode,
                    isEnabled2FA: res.isEnabled2FA == 2 ? 2 : 1,
                    isLoading: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => console.log(e));
    }

    actionSecure = () =>{
        let {isPress, isEnabled2FA, inputCode, secretCode} = this.state;
        if(!isPress){
            let idNotification = '#notificationSecure';
            if(isEnabled2FA == 2 && inputCode == '') return showNotification('Bạn chưa nhập mã bảo mật', 0, idNotification);
            this.setState({isPress: true});
            actionSecureApi(isEnabled2FA, inputCode, secretCode).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        inputCode: ''
                    })
                }
                else this.setState({isPress: false});
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    componentDidMount(){
        this.getDataSecure();
    }

    render(){
        let {isLoading, isPress, isEnabled2FA, secretCode, qrCodeUrl, inputCode} = this.state;
        qrCodeUrl = qrCodeUrl.replace('chart.googleapis.com', 'quickchart.io')
        if(isLoading) return <Loading />
        return(
            <div>
                <HeaderLottie source={secure} disableLoop />
                <div className='row'>
                    <div className='setting-profile-container col-md-6 col-sm-12 col-xs-12'>
                        <p className='text-large text-center'>Thiết lập bảo mật hai lớp</p>
                        <p className='mgt'>Bước 1: Để thiết lập bảo mật 2 lớp trước tiên bạn cần tải xuống ứng dụng có tên Google Authenticator tại:</p>
                        <div className='flex-row item-center'>
                        <a href="https://itunes.apple.com/vn/app/google-authenticator/id388497605?mt=8" target="_blank" rel='noopener noreferrer'>
                            <img src={require('../../assets/image/ios-app.png')} style={{width: '18vh'}} className='mg' />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel='noopener noreferrer'>
                            <img src={require('../../assets/image/android-app.png')} style={{width: '18vh'}} className='mg' />
                        </a>
                        </div>
                        <p>Bước 2: Chuyển "Kích hoạt bảo mật hai lớp" thành “Bật” màn hình hiện 1 mã QR tương đương với “Khoá bảo mật”.</p>
                        <p>Bước 3: Mở ứng dụng chọn “+” sau đó bạn chọn Quét mã vạch (Scan barcode) bằng camera hoặc Điền “Khoá bảo mật” (menual entry) thủ công.</p>
                        <p className='text-large'>Khóa bảo mật: <span className='setting-secure-text'>{secretCode}</span></p>
                        <p className='danger'>(Mã chỉ cố định khi đang được thiết lập sử dụng)</p>
                        <p>Bước 4: Nhập mã gồm 6 chữ số được tạo bởi Google Authenticator vào ô “Nhập mã bảo mật (6 chữ số)” rồi bấm “Xác nhận”.</p>
                        <p>Quan trọng:  Hãy lưu giữ “Khoá bảo mật” hoặc chụp hình ảnh QR bạn đã dùng thiết lập ở một nơi nào đó, để dùng lại trong tương lai khi bạn bị mất điện thoại hoặc ai đó vô tình xoá nhầm, chạy lại hệ điều hành và mất ứng dụng Authenticator trên máy của bạn.</p>
                        <p><i>Lưu ý: Bạn không cần tài khoản Google để sử dụng Google Authenticator; vui lòng bỏ qua mọi thông tin yêu cầu đăng nhập Google.</i></p>
                        <p>Kết quả: Mỗi lần bạn đăng nhập, ngoài mật khẩu ra thì bạn cần nhập mã 6 số tạo ngẫu nhiên sau 30 giây từ ứng dụng Google Authenticator. Tài khoản của bạn được bảo vệ an toàn hơn.</p>
                    </div>
                    <div className='setting-profile-container col-md-6 col-sm-12 col-xs-12'>
                        <p className='text-large text-center'>Bảo mật tài khoản</p>
                        <div className='item-center'>
                            <Switch
                                className='mgt'
                                checked={isEnabled2FA == 2}
                                onChange={e => this.setState({isEnabled2FA: e ? 2 : 1})}
                            />
                            {isEnabled2FA == 2 &&
                                <div>
                                    <img className='mgt' src={qrCodeUrl} key='1' />
                                    <p className='text-left mgt'>Nhập mã bảo mật (6 chữ số):</p>
                                    <input
                                        className='form-control' 
                                        placeholder='Nhập mã bảo mật'
                                        value={inputCode}
                                        onChange={e => this.setState({inputCode: e.target.value})}
                                    />
                                </div>
                            }
                        </div>
                        <div className='item-center'>
                            <div className='mgt' id='notificationSecure' />
                            <Button
                                className='bg-success mgt-2'
                                onClick={this.actionSecure}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { getUserData })(Property);