import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListVideoApi = (num, categoryId, subCategoryId, searchUser, videoType, directVideo) =>{
    let url = `${URI_PATH}app/video/action/getListVideo`;
    let body = {
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        SearchUser: searchUser,
        VideoType: videoType,
        Num: num,
        DirectVideo: directVideo
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListCommentApi = (videoId, pageId = 1) =>{
    let url = `${URI_PATH}app/video/action/getComment`;
    let body = {
        VideoId: videoId,
        PageId: pageId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const commentVideoApi = (videoId, comment) =>{
    let url = `${URI_PATH}app/video/videocomment/update`;
    let body = {
        VideoId: videoId,
        Comment: comment
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const diamondVideoApi = (videoId, userPost) =>{
    let url = `${URI_PATH}app/video/action/diamond`;
    let body = {
        VideoIdDiamond: videoId,
        UserPost: userPost
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkActionApi = (videoId, isRead, isFavorite) =>{
    let url = `${URI_PATH}app/video/videocomment/checkAciton`;
    let body = {
        VideoId: videoId,
        IsRead: isRead,
        IsFavorite: isFavorite
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getRankVideoApi = (videoId, userPost, categoryId, subCategoryId) =>{
    let url = `${URI_PATH}app/video/action/getRankVideo`;
    let body = {
        VideoId: videoId,
        UserPost: userPost,
        CategoryId: categoryId,
        SubCategoryId: subCategoryId
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const donateVideoApi = (videoId, balance) =>{
    let url = `${URI_PATH}app/video/action/donate`;
    let body = {
        VideoId: videoId,
        Balance: balance
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListBonusApi = (type, page) =>{
    let url = `${URI_PATH}app/video/action/getListBonus`;
    let body = {
        Type: type,
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getVideoSearchApi = (VideoId) =>{
    let url = `${URI_PATH}app/video/action/getVideoSearch`;
    let body = {
        VideoId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getAllVideoApi = (PageId, OrderType, CategoryId, SubCategoryId, SearchUser, DirectId) =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeedByType`;
    let body = {
        Type: 12,
        OrderType,
        PageId,
        CategoryId,
        SubCategoryId,
        SearchUser,
        DirectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}