import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListGiftEffortApi = () =>{
    let url = `${URI_PATH}app/gift/effort/getListGiftEffort`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const openGiftApi = (gifteffortId) =>{
    let url = `${URI_PATH}app/gift/effort/openGift`;
    let body = {
        GifteffortId: gifteffortId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkOpenGiftApi = (gifteffortId) =>{
    let url = `${URI_PATH}app/gift/effort/checkOpenGift`;
    let body = {
        GifteffortId: gifteffortId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyGiftApi = (giftbuyId) =>{
    let url = `${URI_PATH}app/gift/effort/buyGift`;
    let body = {
        GiftBuy: giftbuyId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}