import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListChatClubApi = () =>{
    let url = `${URI_PATH}app/chat/chatClub/getListChatClub`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const sendChatClubApi = (message, isImage = '', replyChatId = '') =>{
    let url = `${URI_PATH}app/chat/chatClub/saveChatClub`;
    let body = {
        Message: message,
        IsImage: isImage,
        ReplyChatId: replyChatId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}