import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListSubjectApi = (PageId = 1, SearchText) =>{
    let url = `${URI_PATH}app/study/subject/action/getListSubject`;
    let body = {
        PageId,
        SearchText
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListTeacherApi = (StudySubjectId) =>{
    let url = `${URI_PATH}app/study/subject/action/getListTeacher`;
    let body = {
        StudySubjectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const selectTeacherSubjectApi = (StudyClassId) =>{
    let url = `${URI_PATH}app/study/subject/action/selectTeacherSubject`;
    let body = {
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}