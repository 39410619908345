import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListProgramApi= () =>{
    let url = `${URI_PATH}app/club/program/getListProgram`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getProgramInfoApi = (ClubProgramId) =>{
    let url = `${URI_PATH}app/club/program/getProgramInfo`;
    let body = {
        ClubProgramId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeUnderstandProgramApi = (ClubProgramId) =>{
    let url = `${URI_PATH}app/club/program/changeUnderstandProgram`;
    let body = {
        ClubProgramId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteProgramApi = (ClubProgramId) =>{
    let url = `${URI_PATH}app/club/program/deleteProgram`;
    let body = {
        ClubProgramId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addProgramApi = (ProgramTitle, ProgramId, ProgramType, Link) =>{
    let url = `${URI_PATH}app/club/program/addProgram`;
    let body = {
        ProgramTitle,
        ProgramId,
        ProgramType,
        Link
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const editProgramApi = (ClubProgramId, ProgramTitle, ProgramId, ProgramType, Link) =>{
    let url = `${URI_PATH}app/club/program/editProgram`;
    let body = {
        ClubProgramId,
        ProgramTitle,
        ProgramId,
        ProgramType,
        Link
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListUserUnderstandApi = (ClubProgramId) =>{
    let url = `${URI_PATH}app/club/program/getListUserUnderstand`;
    let body = {
        ClubProgramId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}