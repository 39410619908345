import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getUserLevelApi = (CategoryId, StudentId) =>{
    let url = `${URI_PATH}app/study/alpha/getUserLevel`;
    let body = {
        CategoryId,
        StudentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const upLevelApi = () =>{
    let url = `${URI_PATH}app/study/alpha/upLevel`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}