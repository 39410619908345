import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionVoteApi = (page = 1) =>{
    let url = `${URI_PATH}app/question/vote/getQuestionVote`;
    let body = {
        PageId: page
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const voteQuestionApi = (questionId, isApprove) =>{
    let url = `${URI_PATH}app/question/vote/action`;
    let body = {
        QuestionId: questionId,
        IsApprove: isApprove
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}