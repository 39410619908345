import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import 'react-quill/dist/quill.snow.css';
import User from '../../components/admin/study/User';
import Log from '../../components/admin/study/Log';
import AdminInfomation from '../../components/admin/study/AdminInfomation';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTab: 1
        }
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    renderTab = () =>{
        switch (this.state.selectTab) {
            case 1:
                return <User />
            case 2:
                return <Log />
            case 3:
                return <AdminInfomation />
        }
    }

    render() {
        const { selectTab } = this.state;
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({selectTab: 1})} className={this.buttonGroupClass(1)}>Tài khoản</Button>
                    <Button onClick={() => this.setState({selectTab: 2})} className={this.buttonGroupClass(2)}>Lịch sử</Button>
                    <Button onClick={() => this.setState({selectTab: 3})} className={this.buttonGroupClass(3)}>Đổi thông tin</Button>
                </ButtonGroup>
                {this.renderTab()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)