import React, { Component } from "react";
import { connect } from "react-redux";
import { getListTargetApi } from '../../api/menu/target';
import { MESSAGE, URI_PATH } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from '../../components/Loading';
import { Button } from "antd";
import { withRouter } from "react-router";
import ButtonGroup from "antd/lib/button/button-group";
import ListVestige from "../../components/home/ListVestige";
import StudyZAlpha from "../../components/study/StudyZAlpha";
import {toogleUserBar} from '../../actions/userbar';

class Target extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listTarget: [],
            tab: 1
        }
    }

    getListTarget = () => {
        getListTargetApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listTarget: [
                        ...res.listTarget,
                    ]
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    navigate = (item) => {
        switch (item.link) {
            case 'PropertyUpgrade':
                this.props.history.push('/home/upgrade');
                break;
            case 'ChargeMarket':
                if (item.typeMarket == 1) {
                    this.props.history.push('/menu/charge-market');
                }
                else this.props.history.push('/menu/charge-market', { selectItem: 1 });
                break;
            case 'UserVip':
                this.props.toogleUserBar();
            break;
        }
    }

    renderTarget = () => {
        const { isLoading, listTarget } = this.state;
        if (isLoading) return <Loading small />
        return (
            <>
                {listTarget.map((item, index) =>
                    <div className='row box-primary align-center' key={index}>
                        <div className='col-md-3 col-sm-4 col-xs-4 item-center'>
                            <img
                                src={require(`../../assets/image/target/${item.image_target}`)}
                                className='img-target'
                            />
                            <p className='title-img-target'>{item.type_target}</p>
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-4 item-center'>
                            <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                        </div>
                        <div className='col-md-2 col-sm-4 col-xs-4 item-center'>
                            <img
                                src={`${URI_PATH}${item.image}`}
                                className='img-target'
                            />
                        </div>
                        <div className='col-md-2 col-sm-6 col-xs-6 item-center'>
                            <Button
                                className={`button-buy-target`}
                                style={{ width: '95%' }}
                                onClick={() => this.navigate(item)}
                            >
                                <p className='text-button-target'>{item.action}</p>
                            </Button>
                        </div>
                        <div className='col-md-2 col-sm-6 col-xs-6 item-center'>
                            <Button
                                className={`radius-max ${item.isDone ? 'bg-success' : 'bg-warning'}`}
                                style={{ width: '95%' }}
                            >
                                <p className='text-button-target'>{item.isDone ? 'Đã làm được' : 'Chưa làm được'}</p>
                            </Button>
                        </div>
                    </div>
                )}
            </>
        )
    }

    setTab = (tab) => {
        this.setState({ tab })
    }

    renderTab = () => {
        const { tab } = this.state;
        switch (tab) {
            case 1 :
                return <ListVestige />
            case 2:
                return <>{this.renderTarget()}</>
            case 3:
                return <StudyZAlpha />
        }
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.tab) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    componentDidMount() {
        this.getListTarget();
    }

    render() {
        return (
            <>
                <div className='item-center'>
                    <ButtonGroup className='item-center flex mgb-2'>
                        <Button className={this.buttonGroupClass(1)} style={{ width: '25%' }} onClick={() => this.setTab(1)}>Tổng hợp</Button>
                        <Button className={this.buttonGroupClass(2)} style={{ width: '25%' }} onClick={() => this.setTab(2)}>Mục tiêu</Button>
                        <Button className={this.buttonGroupClass(3)} style={{ width: '25%' }} onClick={() => this.setTab(3)}>Năng lực</Button>
                    </ButtonGroup>
                </div>
                {this.renderTab()}
            </>
        )
    }
}

export default connect(undefined, {toogleUserBar})(withRouter(Target))