import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification, showNotificationBonus } from '../../../utils/notification';
import { MESSAGE } from '../../../utils/constants';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { changePoint, updateDataUser } from '../../../actions/home'
import { getUserLevelApi, upLevelApi } from '../../../api/study/alpha';
import { formatDecimal } from '../../../utils/common';

const listThread = [
    {
        title: 'Thử thách số 1: Siêu trí nhớ',
        data: {
            image: require('../../../assets/image/menu/question-lobby.png'),
            title: 'Siêu trí nhớ'
        },
        link: '/menu/question-lobby'
    },
    {
        title: 'Thử thách số 2: Ngủ sớm dậy sớm',
        data: {
            image: require('../../../assets/image/menu/gift-open.png'),
            title: 'Quà thử thách'
        },
        link: '/menu/gift-day'
    },
    {
        title: 'Thử thách số 3: Học tiếng anh mỗi ngày',
        data: {
            image: require('../../../assets/image/menu/question-event.png'),
            title: 'Sự kiện ngoại ngữ'
        },
        link: '/menu/gift-event'
    },
    {
        title: 'Thử thách số 4: Đấu trường khởi nghiệp',
        data: {
            image: require('../../../assets/image/menu/question-fight.png'),
            title: ' Đấu trường khởi nghiệp'
        },
        link: '/question-fight'
    },
]

class RegisterTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            userLevel: {},
            dataLevel: {},
            isPress: false,
            countGiftDay: 0
        }
    }

    getContentThreadByLevel = (dataLevel, key) => {
        switch (key) {
            case 0:
                return `Mục tiêu vượt qua: Kỉ lục ${dataLevel.Lesson1} câu`;
            case 1:
                return `Mục tiêu vượt qua: ${dataLevel.Lesson2} ngày (Đã đạt: ${this.state.countGiftDay} ngày)`;
            case 2:
                const lesson3Arr = dataLevel.Lesson3.split("|");
                return `Mục tiêu vượt qua: ${lesson3Arr[0]} câu đúng với tỷ lệ trên ${lesson3Arr[1]}%`;
            case 3:
                return `Mục tiêu vượt qua: chiến thắng liên tiếp ${dataLevel.Lesson4} lần đấu trường Level 3 - 30 giây với 10/10`
        }
        return null;
    }

    getUserLevel = () => {
        getUserLevelApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    userLevel: res.dataUser,
                    dataLevel: res.dataLevel,
                    countGiftDay: res.countGiftDay
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    uplevel = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            upLevelApi().then(res => {
                this.setState({ isPress: false })
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    showNotificationBonus(res.bonus, 1, 2);
                    this.props.changePoint([null, res.bonus]);
                    this.getUserLevel();
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    componentDidMount() {
        this.getUserLevel();
    }

    render() {
        const { isLoading, isPress, dataLevel, userLevel } = this.state;
        const isDoneAll = userLevel.IsDoneLesson1 > 0 && userLevel.IsDoneLesson2 > 0 && userLevel.IsDoneLesson3 > 0 && userLevel.IsDoneLesson4 > 0
        return (
            <>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <div className='flex-column justify-center box-primary'>
                            <p className='title-register-study'>THỬ THÁCH Z ALPHA LEVEL {userLevel.Level}</p>
                            <p className='subtitle-register-study'>Thách thức sinh ra để phân loại con người</p>
                            <div className='box-primary mgt'>
                                <p className='subtitle-register-study'>Phần thưởng {formatDecimal(+userLevel.Level * 500000)} $[z]</p>
                            </div>
                        </div>
                        {listThread.map((item, key) =>
                            <div
                                className='row study-register-test-container'
                                key={key}
                                onClick={() => this.props.history.push(item.link)}
                            >
                                <div className='col-md-7 col-sm-6 col-xs-12 flex-column item-center'>
                                    <p className='bold text-large'>{item.title}</p>
                                    <p>{this.getContentThreadByLevel(dataLevel, key)}</p>
                                </div>
                                <div className='col-md-3 col-sm-4 col-xs-12 flex-column item-center'>
                                    <img src={item.data.image} style={{ width: '8vh' }} />
                                    <p>{item.data.title}</p>
                                </div>
                                <div className='col-md-2 col-sm-2 col-xs-12 flex-column item-center'>
                                    {userLevel[`IsDoneLesson${key + 1}`] > 0 ?
                                        <div className='border-result-study-test'>
                                            <p className='text-bold text-large'>Đạt</p>
                                        </div>
                                        :
                                        <div className='border-result-study-test bg-warning'>
                                            <p className='text-bold text-large'>Chưa đạt</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className='item-center mgt-3'>
                            <Button
                                className={`button-done-register-study ${(isDoneAll ? 'bg-success' : 'bg-primary')}`}
                                onClick={this.uplevel}
                                loading={isPress === true}
                                disabled={!isDoneAll}
                            >
                                Nhận thưởng
                            </Button>
                        </div>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { updateDataUser, changePoint })(withRouter(RegisterTest))