import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const searchByNameOrPhoneApi = (searchText) =>{
    let url = `${URI_PATH}app/user/action/searchByNameOrPhone`;
    let body = {
        SearchText: searchText
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDataTooltipUserApi = guestId =>{
    let url = `${URI_PATH}app/user/action/getDataTooltipUser`;
    let body = {
        GuestId: guestId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const followApi = followUser =>{
    let url = `${URI_PATH}app/user/action/follow`;
    let body = {
        FollowUser: followUser
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const logoutFbApi = () =>{
    let url = `${URI_PATH}app/user/action/logoutFb`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const uploadAvatarApi = (Avatar) =>{
    let url = `${URI_PATH}app/user/action/uploadAvatar`;
    let body = {
        Avatar
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkEnegyApi = () =>{
    let url = `${URI_PATH}app/user/action/checkEnegy`;
    let body = {
        
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}