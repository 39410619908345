import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchUser from '../../../components/SearchUser';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import { showNotification } from '../../../utils/notification';
import { addUserSupportByManageApi } from '../../../api/admin/study/support';
import { MESSAGE } from '../../../utils/constants';

class SupportAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectPhone: [],
            isPress: false
        }
    }

    deleteSearch = e => {
        if (e.key == 'Backspace' && this.state.selectPhone.length > 0) {
            this.refs.searchUser.getInstance().clear();
            this.setState({ selectPhone: [] })
        }
    }

    addUserSupport = () => {
        const { isPress, selectPhone } = this.state;
        if (!isPress) {
            if (selectPhone.length == 0) return showNotification('Vui lòng chọn số điện thoại', -1);
            let userId = selectPhone[0].userId;
            this.setState({ isPress: true });
            addUserSupportByManageApi(userId).then(res => {
                this.setState({ isPress: false })
                showNotification(res.message, res.code);
            }).catch(error => {
                this.setState({ isPress: false })
                showNotification(MESSAGE.ERROR, 0);
            });
        }
    }

    render() {
        return (
            <div className='item-center'>
                <div className='row item-center'>
                    <div className='col-md-8 col-sm-8 col-xs-12'>
                        <SearchUser
                            ref='searchUser'
                            onChange={selectPhone => this.setState({ selectPhone })}
                            onKeyDown={this.deleteSearch}
                            placeholder='Nhập họ tên hoặc số điện thoại 🔎'
                        />
                    </div>
                </div>
                <Button
                    className='bg-success mgt-2'
                    onClick={this.addUserSupport}
                    loading={this.state.isPress}
                >
                    Xác nhận
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(SupportAdd));