import React from 'react';
import {URI_PATH} from '../../utils/constants';
import {formatDecimal, getAvatarFb} from '../../utils/common';

const formatPrice = (balance, balanceType) =>{
    if(balanceType == 1) return parseFloat(balance);
    return formatDecimal(`${balance.toString()}`);
}

export default (props) =>(
    <div className="col-md-3 col-sm-4 col-xs-6 item-block pointer" onClick={props.onClick}>
        <div className='flex-column item-center'>
            <img
                className='cart-image'
                src={`${URI_PATH}${props.item.CartImage}`}
            />
            <img
                className='avatar-guest mgt'
                src={getAvatarFb(props.item.FacebookId)}
            />
            <p className='text-center mgt'>{`Bán ${props.item.FoodName !== undefined ? props.item.FoodName : props.item.AssetName}`}</p>
            <p>{formatPrice(props.item.Balance, props.item.BalanceType)} {`${props.item.BalanceName}`}</p>
        </div>
    </div>
)