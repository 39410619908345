import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataGroupApi = () =>{
    let url = `${URI_PATH}app/brand/group/getDataGroup`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const inviteMemberApi = userInvite =>{
    let url = `${URI_PATH}app/brand/group/inviteMember`;
    let body = {
        UserInvite: userInvite
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const kickMemberApi = memberId =>{
    let url = `${URI_PATH}app/brand/group/kickMember`;
    let body = {
        MemberId: memberId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const acceptInviteApi = captainId =>{
    let url = `${URI_PATH}app/brand/group/acceptInvite`;
    let body = {
        CaptainId: captainId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}