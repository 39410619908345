import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListLessonMasterApi } from '../../api/study/lesson';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import LessonShow from './componentChildren/LessonShow';
import { Affix, Button } from 'antd';
const ButtonGroup = Button.Group;

class LessonMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listLesson: [],
            isLoading: true,
            dataTeacher: {},
            endList: false,
            pageId: 1,
            typeSelect: 1,
            isLoadingMarked: true,
            listLessonMarked: [],
            endListMarked: false,
            pageIdMarked: 1
        }
    }

    getListLessonMaster = (pageId = 1) => {
        this.setState({ typeSelect: 1 })
        getListLessonMasterApi(pageId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLesson: pageId == 1 ? res.lessons : [...this.state.listLesson, ...res.lessons],
                    endList: res.lessons.length < 10,
                    dataTeacher: res.teacher,
                    isLoading: false,
                    pageId,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    getListLessonMarked = (pageIdMarked = 1) => {
        this.setState({ typeSelect: 2 })
        getListLessonMasterApi(pageIdMarked, 2).then(res => {
            if (res.code == 1) {
                this.setState({
                    listLessonMarked: pageIdMarked == 1 ? res.lessons : [...this.state.listLessonMarked, ...res.lessons],
                    endListMarked: res.lessons.length < 10,
                    dataTeacher: res.teacher,
                    isLoadingMarked: false,
                    pageIdMarked,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () => {
        if (!this.state.isLoading && !this.state.endList) {
            this.getListLessonMaster(this.state.pageId + 1)
        }
    }

    loadMoreMarked = () => {
        if (!this.state.isLoadingMarked && !this.state.endListMarked) {
            this.getListLessonMarked(this.state.pageIdMarked + 1)
        }
    }

    seenChat = (StudyStudentId) => {
        this.setState({
            listLesson: this.state.listLesson.map(item => {
                if (item.StudyStudentId == StudyStudentId) {
                    return {
                        ...item,
                        ListUserIdReadChat: [
                            ...item.ListUserIdReadChat,
                            this.props.dataUser.UserId
                        ]
                    }
                }
                return item;
            })
        })
    }

    seenChatMarked = (StudyStudentId) => {
        this.setState({
            listLessonMarked: this.state.listLessonMarked.map(item => {
                if (item.StudyStudentId == StudyStudentId) {
                    const ListUserIdReadChat = item.ListUserIdReadChat || [];
                    ListUserIdReadChat.push(this.props.dataUser.UserId);
                    return {
                        ...item,
                        ListUserIdReadChat
                    }
                }
                return item;
            })
        })
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.typeSelect) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    componentDidMount() {
        this.getListLessonMaster();
    }

    render() {
        const { listLesson, isLoading, dataTeacher, endList, typeSelect, listLessonMarked, isLoadingMarked, endListMarked } = this.state;
        return (
            <>
                {/* <div className="row item-center mgb">
                    <ButtonGroup className='item-center flex' style={{ width: '50%' }}>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.getListLessonMaster()}>Đang chấm</Button>
                        <Button className={this.buttonGroupClass(2)} onClick={() => this.getListLessonMarked()}>Đã chấm</Button>
                    </ButtonGroup>
                </div> */}
                {typeSelect == 1 ?
                    <LessonShow
                        listLesson={listLesson}
                        isLoading={isLoading}
                        onRefresh={this.getListLessonMaster}
                        dataTeacher={dataTeacher}
                        type={4}
                        loadMore={this.loadMore}
                        endList={endList}
                        onSeenChat={this.seenChat}
                    />
                    :
                    <LessonShow
                        listLesson={listLessonMarked}
                        isLoading={isLoadingMarked}
                        onRefresh={this.getListLessonMarked}
                        dataTeacher={dataTeacher}
                        type={3}
                        loadMore={this.loadMoreMarked}
                        endList={endListMarked}
                        onSeenChat={this.seenChatMarked}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(LessonMaster)