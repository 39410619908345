import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getTimeBuyTicketApi = () =>{
    let url = `${URI_PATH}app/question/ticket/action/getTimeBuyTicket`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getBossTodayApi = () =>{
    let url = `${URI_PATH}app/question/ticket/action/getBossToday`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getBossHistoryApi = (PageId) =>{
    let url = `${URI_PATH}app/question/ticket/action/getBossHistory`;
    let body = {PageId};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getQuestionApi = () =>{
    let url = `${URI_PATH}app/question/ticket/action/getQuestion`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerApi = (QuestionLobbyId, Answer) =>{
    let url = `${URI_PATH}app/question/ticket/action/saveAnswer`;
    let body = {
        QuestionLobbyId,
        Answer
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyTicketApi = (ticket) =>{
    let url = `${URI_PATH}app/question/ticket/action/buyTicket`;
    let body = {
        TicketCount: ticket
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}