import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Switch, Checkbox } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { updateLessonApi, getListLessonApi, getListPropertyAndCategoryApi } from '../../../api/admin/study';
import { showNotification } from '../../../utils/notification';
import ReactQuill from 'react-quill';
import ModalAddLesson from '../../../components/admin/study/ModalAddLesson';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../components/Loading';
import HeaderLottie from '../../../components/HeaderLottie';
import empty from '../../../assets/lottie/empty.json';
import { getListTeacherApi, getListUserApi, toogleMasterApi, toogleStudentApi, toogleTeacherApi } from '../../../api/admin/study/user';
import { getAvatarFb } from '../../../utils/common';
import TooltipUser from '../../../components/TooltipUser';
import { TitleSuccess } from '../../../components/frame';
import Countdown, { zeroPad } from 'react-countdown-now';
import DatePicker from "react-datepicker";
import moment from 'moment';
import User from '../../../components/admin/study/User';
import { getListLogApi } from '../../../api/admin/study/log';
import { changePasswordAdminApi, changeSubjectAdminApi, getAdminInfoApi } from '../../../api/admin/study/admin';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSubject: '',
            inputHotline: '',
            isPress: false,
            isLoading: true,
            inputPassword: '',
            inputRepassword: ''
        }
    }

    getListAdminInfo = () => {
        getAdminInfoApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    inputSubject: res.data.Subject,
                    inputHotline: res.data.Hotline,
                    isLoading: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    changeSubjectAdmin = () => {
        if (!this.state.isPress) {
            const { inputHotline, inputSubject } = this.state;
            if (inputSubject && inputHotline) {
                this.setState({ isPress: true })
                changeSubjectAdminApi(inputSubject, inputHotline).then(res => {
                    showNotification(res.message, res.code);
                    this.setState({ isPress: false })
                }).catch(error => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({ isPress: false })
                })
            }
            else if (!inputSubject) showNotification('Tên môn học đang để trống', 0);
            else if (!inputHotline) showNotification('Hotline đang để trống', 0);
        }
    }

    changePasswordAdmin = () =>{
        if (!this.state.isPress) {
            const { inputRepassword, inputPassword } = this.state;
            if (inputPassword && inputPassword == inputRepassword) {
                this.setState({ isPress: true })
                changePasswordAdminApi(inputPassword).then(res => {
                    showNotification(res.message, res.code);
                    if(res.code == 1){
                        this.setState({
                            inputPassword: '',
                            inputRepassword: '',
                            isPress: false
                        })
                    }
                    else this.setState({ isPress: false })
                }).catch(error => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({ isPress: false })
                })
            }
            else if (!inputPassword) showNotification('Mật khẩu đang để trống', 0);
            else if (inputPassword != inputRepassword) showNotification('Mật khẩu không trùng nhau', 0);
        }
    }

    componentDidMount() {
        this.getListAdminInfo()
    }

    render() {
        const { inputSubject, inputHotline, isLoading, isPress, inputPassword, inputRepassword } = this.state;
        return (
            <>
                {isLoading ?
                    <Loading small />
                    :
                    <div className='flex'>
                        <div className='box-primary'>
                            <TitleSuccess>Môn học</TitleSuccess>
                            <div className='item-center'>
                                <input
                                    className='form-control mgt'
                                    value={inputSubject}
                                    onChange={e => this.setState({ inputSubject: e.target.value })}
                                    placeholder='Nhập tên môn học'
                                />
                                <input
                                    className='form-control mgt'
                                    value={inputHotline}
                                    onChange={e => this.setState({ inputHotline: e.target.value })}
                                    placeholder='Nhập số Hotline'
                                />
                                <Button
                                    loading={isPress}
                                    className='mgt-2 bg-success'
                                    onClick={this.changeSubjectAdmin}
                                >
                                    Thay đổi
                                </Button>
                            </div>
                        </div>
                        <div className='box-primary'>
                            <TitleSuccess>Mật khẩu</TitleSuccess>
                            <div className='item-center'>
                                <input
                                    className='form-control mgt'
                                    value={inputPassword}
                                    onChange={e => this.setState({ inputPassword: e.target.value })}
                                    placeholder='Nhập mật khẩu mới'
                                    type='password'
                                />
                                <input
                                    className='form-control mgt'
                                    value={inputRepassword}
                                    onChange={e => this.setState({ inputRepassword: e.target.value })}
                                    placeholder='Nhập lại mật khẩu'
                                    type='password'
                                />
                                <Button
                                    loading={isPress}
                                    className='mgt-2 bg-success'
                                    onClick={this.changePasswordAdmin}
                                >
                                    Thay đổi
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)