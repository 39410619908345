import {getListCartApi} from '../../api/charge/food';

export const getListCart = (page = 1) =>{
    return dispatch =>{
        getListCartApi(page).then((res) => {
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_LIST_CART_FOOD_MARKET', listCart: res.listCart, page: page });
            else dispatch({ type: 'FALSE_GET_LIST_CART_FOOD_MARKET' });
        })
        .catch(error => dispatch({ type: 'FALSE_GET_LIST_CART_FOOD_MARKET' }));
    }
}

export const deleteCartFood = foodcartId =>{
    return dispatch =>{
        dispatch({ type: 'DELETE_CART_FOOD', foodcartId });
    }
}