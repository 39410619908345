import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListAudioApi  = (PageId, CategoryId, SubCategoryId, SearchText) =>{
    let url = `${URI_PATH}app/audio/action/getList`;
    let body = {
        PageId,
        SearchText,
        CategoryId,
        SubCategoryId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const logAudioApi = (AudioId) =>{
    let url = `${URI_PATH}app/audio/action/logAudio`;
    let body = {
        AudioId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}