import React, { Component } from 'react';
import { connect } from 'react-redux';
import {URI_PATH, LIST_DONATE, MESSAGE} from '../../../utils/constants';
import {getDataNotify, formatDecimal, isTouch} from '../../../utils/common';
import {withRouter} from 'react-router-dom';
import { changePoint } from '../../../actions/home/';
import { Button, Tooltip } from 'antd';
import FsLightbox from 'fslightbox-react';
import TooltipUser from '../../../components/TooltipUser';
import Loading from '../../../components/Loading';
import {diamondAskQuestionApi, donateAskQuestionApi, getRankAskQuestionApi} from '../../../api/askquestion';
import {socket} from '../../../components/realtime/ReadSocketEvent';
import {showNotification, showNotificationBonus, showNotificationProperty, showNotificationAsset} from '../../../utils/notification';
import {showModalEat} from '../../../actions/home/modalEat';
import {changeDataNewfeed} from '../../../actions/newfeed';
import Checked from '../../frame/Checked';

class AskQuestion extends Component{
    constructor(props){
        super(props);
        this.state = {
            askQuestionContent: this.props.data.AskQuestionContent.slice(0, 450) + ' <b class="pointer"><u> xem thêm...</u></b>',
            isReadMore: false,
            balanceDonate: LIST_DONATE[0],
            isPress: false,
            isLoadingRank: false,
            dataRank: {}
        }
    }

    readMore = () =>{
        if(!this.state.isReadMore){
            this.setState({
                askQuestionContent: this.props.data.AskQuestionContent,
                isReadMore: true
            })
        }
    }

    renderDonate = () =>{
        let {data} = this.props;
        let {balanceDonate, isPress} = this.state;
        return(
            <Tooltip
                overlayClassName='pv-tooltip-diamond'
                trigger={isTouch() ? 'click' : 'hover'}
                title={
                    <div className='flex-column item-center'>
                        <img
                            src={require('../../../assets/image/donate.png')}
                            className='avatar-guest'
                        />
                        <select
                            className='form-control mgt'
                            style={{width: '75%'}}
                            defaultValue={balanceDonate}
                            onChange={e => this.setState({balanceDonate: e.target.value})}
                        >
                            {LIST_DONATE.map((item, key) =>(
                                <option key={key} value={item}>Tặng {formatDecimal(item)} $[z]</option>
                            ))}
                        </select>
                        <Button
                            className='pv-confirm-diamond'
                            style={{marginTop: '10vh'}}
                            onClick={() => this.donateAskQuestion(data.TargetId, balanceDonate, data.UserId, data.AskQuestionTitle, data.NewfeedId)}
                            loading={isPress}
                        >
                            Tặng
                        </Button>
                        <div className='mgt' id={'notificationDonateAskQuestion' + data.NewfeedId} />
                    </div>
                }
            >
                <img
                    src={require('../../../assets/image/donate.png')}
                    className='pv-donate-small'
                />
            </Tooltip>
        )
    }

    renderDiamond = () =>{
        let {data} = this.props;
        let {isPress} = this.state;
        if(!data.IsDiamond && data.DiamondQueue > 0){
            return(
                <Tooltip
                    overlayClassName='pv-tooltip-diamond'
                    trigger={isTouch() ? 'click' : 'hover'}
                    title={
                        <div className='flex-column item-center'>
                            <img
                                src={require('../../../assets/image/diamond-white.gif')}
                                style={{width: '8vh'}}
                            />
                            <Button
                                className='pv-confirm-diamond'
                                onClick={() => this.diamondAskQuestion(data.TargetId, data.UserId, data.AskQuestionTitle, data.NewfeedId)}
                                loading={isPress}
                            >
                                Bình chọn
                            </Button>
                            <div className='mgt' id={'notificationDiamondAskQuestion' + data.NewfeedId} />
                        </div>
                    }
                >
                    <img
                        src={require('../../../assets/image/diamond-white.gif')}
                        className='pv-diamond'
                    />
                </Tooltip>
            )
        }
        return (
            <img
                src={require('../../../assets/image/diamond-disable.png')}
                className='pv-diamond-disable'
            />
        );
    }

    renderRank = () =>{
        let {dataRank, isLoadingRank} = this.state;
        if(isLoadingRank) return (<Loading small />);
        return(
            <div className='row mgt-3'>
                <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Hôm nay</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataRank.diamondToday}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondToday}/{dataRank.allAskQuestionToday}</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Tuần</Button>
                        <div className='flex-row item-center mgt-3'>
                        <p>{dataRank.diamondWeek}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondWeek}/{dataRank.allAskQuestionWeek}</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Tháng</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataRank.diamondMonth}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondMonth}/{dataRank.allAskQuestionMonth}</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-xs-6 flex item-center'>
                    <div className='pv-rank-container'>
                        <Button className='button-pv-rank'>Trọn đời</Button>
                        <div className='flex-row item-center mgt-3'>
                            <p>{dataRank.diamondAll}</p>
                            <img
                                src={require('../../../assets/image/diamond-rank.png')}
                                className='pv-icon-rank'
                            />
                        </div>
                        <hr width="95%" align="center" className='hr-default' />
                        <div className='flex-row item-center'>
                            <p className='text-center'>{dataRank.rankDiamondAll}/{dataRank.allAskQuestion}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    donateAskQuestion = (askQuestionId, balanceDonate, userPost, askQuestionTitle, newfeedId) =>{
        if(!this.state.isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationDonateAskQuestion' + newfeedId;
            balanceDonate = parseInt(balanceDonate);
            donateAskQuestionApi(askQuestionId, balanceDonate).then(res =>{
                if(res.code == 1){
                    let dataUser = this.props.dataUser;
                    //socket
                    if(userPost != dataUser.UserId){
                        let datas = getDataNotify(userPost, 0, res.balanceBonus);
                        datas.message = {
                            fullName: dataUser.FullName,
                            facebookId: dataUser.FacebookId,
                            message: 'tặng '+ formatDecimal(balanceDonate) +' $[z] cho câu hỏi "'+ askQuestionTitle +'" của bạn',
                            isNotify : 2
                        }
                        socket.emit('send_notify', datas);
                    }
                    showNotificationBonus(balanceDonate, -1, 2);
                    showNotificationBonus(balanceDonate / 1000, 1, 5);
                    this.props.changePoint([null, -balanceDonate, null, null, balanceDonate / 1000]);
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if(el.NewfeedId == newfeedId) return Object.assign({}, el, {
                            Donate: parseInt(el.Donate) + balanceDonate
                        })
                        return el;
                    }));
                }
                showNotification(res.message, res.code, idNotification);
                this.setState({isPress: false});
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    diamondAskQuestion = (askQuestionId, userPost, askQuestionTitle, newfeedId) =>{
        if(!this.state.isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationDiamondAskQuestion' + newfeedId;
            diamondAskQuestionApi(askQuestionId, userPost).then((res) => {
                if(res.code == 1){
                    showNotificationProperty(res.property);
                    showNotificationAsset(res.assetInfo);
					showNotificationBonus(res.bonus.BalanceZ, 1, 3);
                    showNotificationBonus(res.bonus.Achievement, 1, 4);
                    this.props.changePoint([null, null, res.bonus.BalanceZ, res.bonus.Achievement]);
                    //socket
                    let dataUser = this.props.dataUser;
                    let dataSent = getDataNotify(userPost, 0, 0, 0, res.bonus.Achievement, 0, res.propertyPa);
                    dataSent.message = {
                        fullName: dataUser.FullName,
                        facebookId: dataUser.FacebookId,
                        message: 'bình chọn câu hỏi "'+ askQuestionTitle +'" của bạn',
                        isNotify : 2
                    }
                    socket.emit('send_notify', dataSent);
                    //play sound
                    new Audio(require('../../../assets/audio/diamond.wav')).play();
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if(el.NewfeedType == 16){
                            if(el.NewfeedId == newfeedId){
                                return Object.assign({}, el, {
                                    IsDiamond: askQuestionId,
                                    DiamondQueue: 0
                                });
                            }
                            return el;
                            // return Object.assign({}, el, {
                            //     DiamondQueue: 0
                            // });
                        }
                        return el;
                    }));
                    showNotification(res.message, res.code);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                }
                this.setState({isPress: false});
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    getRankAskQuestion = (askQuestionId, userPost) =>{
        let {dataRank, isLoadingRank} = this.state;
        if(Object.keys(dataRank).length === 0 && !isLoadingRank){
            this.setState({isLoadingRank: true});
            getRankAskQuestionApi(askQuestionId, userPost).then(res =>{
                this.setState({
                    dataRank: res.data,
                    isLoadingRank: false
                });
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
                this.setState({
                    dataRank: {}
                });
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.data.AskQuestionContent != this.props.data.AskQuestionContent){
            this.setState({askQuestionContent: this.props.data.AskQuestionContent.slice(0, 450) + ' <b class="pointer"><u> xem thêm...</u></b>'})
        }
    }

    render(){
        let {showPreviewImage, askQuestionContent, imagePreview} = this.state;
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                            <Checked data={data} />
                        </span> đăng câu hỏi chủ đề <span className='bold'>{data.CategoryName} - {data.SubCategoryName}</span></p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='pv-title-container ReactCollapse--collapse'>
                    {data.IsDiamond ?
                        <img
                            src={require('../../../assets/image/diamond-white.gif')}
                            className='pv-diamond-case'
                        />
                    :
                        <img
                            src={require('../../../assets/image/diamond-case.png')}
                            className='pv-diamond-case'
                        />
                    }
                    <div className='flex-row mgt-2'>
                        <div className='flex-row flex-3 align-center'>
                            <p className='pv-check-p bg-success mgb-0'>Mã số: {data.TargetId}</p>
                        </div>
                        <div className='flex-row flex-2 align-center' style={{justifyContent: 'flex-end'}}>
                            <div>
                                <img
                                    src={require('../../../assets/image/donate.png')}
                                    className='avatar-guest'
                                />
                            </div>
                            <div className='pv-donate-container'>
                                <p className='text-large bold'>{formatDecimal(data.Donate)} $[z]</p>
                            </div>
                        </div>
                    </div>
                    <p className='text-large text-center bold mgt-2 mgb-2'>{data.AskQuestionTitle}</p>
                    <div>
                        {data.AskQuestionImage &&
                            <img
                                src={`${URI_PATH}${data.AskQuestionImage}`}
                                className='pv-post-image'
                                onClick={() => this.setState({
                                    showPreviewImage: true,
                                    imagePreview: `${URI_PATH}${data.AskQuestionImage}`
                                })}
                            />
                        }
                        <div
                            className='text-left'
                            dangerouslySetInnerHTML={{__html: askQuestionContent}}
                            onClick={this.readMore}
                        />
                    </div>
                </div>
                {!data.Guest &&
                    <div className='flex-row mgt-2'>
                        <div className='flex-1 item-center'>
                            {this.renderDonate()}
                        </div>
                        <div className='flex-1 item-center'>
                            {this.renderDiamond()}
                        </div>
                        <div className='flex-1 item-center'>
                            <Tooltip
                                overlayClassName='pv-tooltip-rank'
                                trigger={isTouch() ? 'click' : 'hover'}
                                onVisibleChange={() => this.getRankAskQuestion(data.TargetId, data.UserId)}
                                title={this.renderRank}
                            >
                                <img
                                    src={require('../../../assets/image/flag-green.png')}
                                    className='pv-diamond'
                                />
                            </Tooltip>
                        </div>
                    </div>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, {changePoint, showModalEat, changeDataNewfeed})(withRouter(AskQuestion));