import React, { Component } from 'react';
import { Button } from 'antd';
import { priceFormat, getCostInvest } from '../../../utils/common';
import { URI_PATH } from '../../../utils/constants';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class Invest extends Component{
    render(){
        let data = this.props.data;
        let balanceName = data.ItemType == 3 ? 'đ[z]' : '$[z]';
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p>
                            <span className='bold'>{data.FullName}</span>
                            <Checked data={data} />
                        đã {data.BuySell == 1 ? 'mua' : 'bán'} {data.Amount} {data.ItemName} với giá {priceFormat(data.Balance)} {balanceName}/đơn vị
                        </p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-row justify-center mgt-2'>
                    <img src={`${URI_PATH}${data.ItemImage}`} style={{width: '10vh', height: '10vh'}} />
                    <div className='flex-column justify-center mgl-2'>
                        <p>Giá hiện tại:</p>
                        <p>Mua vào: {priceFormat(getCostInvest(data.BalanceChange, data.PercentChange))} {balanceName}</p>
                        <p>Bán ra: {priceFormat(getCostInvest(data.BalanceChange, data.PercentChange, 2))} {balanceName}</p>
                    </div>
                </div>
                {!data.Guest &&
                    <div className='item-center'>
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={() => this.props.history.push('/menu/charge-invest')}
                        >
                            Đầu tư
                        </Button>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(Invest);