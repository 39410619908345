import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListPropertyAndCategoryApi = () =>{
    let url = `${URI_PATH}app/admin/study/action/getListPropertyAndCategory`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateLessonApi = (Lesson, LessonTitle, LessonDeadline, CategoryId, SubCategoryId, ListDocument, StudyAdminId = '') =>{
    let url = `${URI_PATH}app/admin/study/action/updateLesson`;
    let body = {
        Lesson,
        LessonTitle,
        LessonDeadline,
        CategoryId,
        SubCategoryId,
        StudyAdminId,
        ListDocument
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListLessonApi = (CategoryId) =>{
    let url = `${URI_PATH}app/admin/study/action/getListLesson`;
    let body = {
        CategoryId,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}