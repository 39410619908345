import React, { Component } from 'react';
import { Button } from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class ClubCreate extends Component{
    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                            <Checked data={data} />
                        </span> thành lập câu lạc bộ và bắt đầu tuyển thành viên</p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='item-center'>
                    <p className='text-huge bold mgb-2'>{data.ClubName}</p>
                    <img src={require('../../../assets/image/club-level.png')} style={{width: '5vh'}} />
                </div>
                {!data.Guest &&
                    <>
                        {!this.props.dataUser.ClubId &&
                            <div className='flex-column item-center'>
                                <Button
                                    className='newfeed-button mgt-2'
                                    onClick={() => this.props.history.push('/menu/club-list')}
                                >
                                    Danh sách Club
                                </Button>
                            </div>
                        }
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(ClubCreate));