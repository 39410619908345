import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListClassSelectApi = (UserId) =>{
    let url = `${URI_PATH}app/study/class/action/getListClassSelect`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListClassSelectLessonApi = () =>{
    let url = `${URI_PATH}app/study/class/action/getListClassSelectLesson`;
    let body = {

    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListMemberApi = (StudyClassId, PageId) =>{
    let url = `${URI_PATH}app/study/class/action/getListMember`;
    let body = {
        StudyClassId,
        PageId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDeadlineSubjectApi = (StudyAdminId) =>{
    let url = `${URI_PATH}app/study/class/action/getDeadlineSubject`;
    let body = {
        StudyAdminId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}