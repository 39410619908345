const INITAL_STATE = {
    listCart: [],
    loading: true,
    page: 1,
    endList: false,
    isRefreshing: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_LIST_CART_FOOD_MARKET':
            return { ...state,
                listCart: action.page == 1 ? action.listCart : [ ...state.listCart, ...action.listCart],
                loading: false,
                page: action.page,
                endList: action.listCart.length > 0 ? false : true 
            }
        case 'DELETE_CART_FOOD':
            return{...state,
                listCart: state.listCart.filter(el => {
                    return el.FoodcartId != action.foodcartId;
                })
            }
        case 'FALSE_GET_LIST_CART_FOOD_MARKET':
            return { ...state };
        default:
            return state;
    }
};