import React, { Component } from 'react';
import { Button } from 'antd';
import { priceFormat } from '../../../utils/common';
import { URI_PATH } from '../../../utils/constants';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class GiftDay extends Component{
    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                        <Checked data={data} />
                        </span> mở {data.GiftName} nhận được {priceFormat(data.Content)} đ[z]</p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-column item-center'>
                    <img className='newfeed-img' src={`${URI_PATH}${data.GiftImage}`} />
                    {!data.Guest &&
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={() => this.props.history.push('/menu/gift-day')}
                        >
                            {data.GiftName}
                        </Button>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(GiftDay);