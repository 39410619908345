import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListPackageApi = () =>{
    let url = `${URI_PATH}app/study/user/getListPackage`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
