import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { getAvatarFb, isTouch } from '../utils/common';
import { URI_PATH, MESSAGE } from '../utils/constants';
import ClubDeputy from '../components/club/ClubDeputy';
import { showHideChat, getListChatPeople } from '../actions/chat';
import { withRouter } from 'react-router-dom';
import { getDataTooltipUserApi, followApi } from '../api/user';
import { showNotification } from '../utils/notification';
import Loading from '../components/Loading';
import { inviteMemberApi } from '../api/brand/group';
import { socket } from '../components/realtime/ReadSocketEvent';
import Checked from './frame/Checked';

class TooltipUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataToolTip: {},
        }
        this.isPress = false;
    }

    inviteMember = userId => {
        if (!this.isPress) {
            this.isPress = true;
            inviteMemberApi(userId).then(res => {
                showNotification(res.message, res.code);
                this.isPress = false;
                if (res.code == 1) {
                    //socket
                    let dataUser = this.props.dataUser;
                    let dataSent = {
                        userName: dataUser.FullName,
                        userAvatar: getAvatarFb(dataUser.FacebookId),
                        userId: dataUser.UserId,
                        userInivite: res.toUserId
                    };
                    socket.emit('invite_property', dataSent);
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.isPress = false;
            })
        }
    }

    loadData = () => {
        if (this.state.isLoading) {
            let guestId = this.props.userInfo.UserId;
            getDataTooltipUserApi(guestId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isLoading: false,
                        dataToolTip: res.data
                    })
                }
                else {
                    this.setState({ isLoading: false });
                    showNotification(MESSAGE.ERROR, 0);
                }
            }).catch(e => {
                this.setState({ isLoading: false });
                showNotification(MESSAGE.ERROR, 0);
            });
        }
    }

    follow = () => {
        followApi(this.props.userInfo.UserId).then(res => {
            if (res.code == 1) {
                let countFollow = parseInt(this.state.dataToolTip.CountFollow);
                this.setState({
                    dataToolTip: {
                        ...this.state.dataToolTip,
                        IsFollow: res.action,
                        CountFollow: res.action == 1 ? countFollow + 1 : countFollow - 1
                    }
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0))
    }

    renderTooltip = () => {
        if (this.state.isLoading) return <Loading small />
        let { dataToolTip } = this.state;
        return (
            <div className='flex-column item-center mgt-2'>
                <div className='col-rank-name'>
                    <div className='row item-center'>
                        <ClubDeputy clubRoleId={dataToolTip.ClubRoleId} />
                        <p>{dataToolTip.ClubName}</p>
                    </div>
                    <p className='bold'>{dataToolTip.FullName}
                    <Checked data={dataToolTip} />
                    </p>
                    <p>{dataToolTip.ClubRank}</p>
                </div>
                <div className='item-center mg'>
                    <p>{dataToolTip.Status}</p>
                </div>
                <div className={`flex-row item-center ${((dataToolTip.HouseUse || dataToolTip.CrownUse || dataToolTip.ListCrest?.length > 0) && 'hr-tooltip')}`}>
                    {dataToolTip.HouseUse &&
                        <div style={{margin: '0 1vh'}}>
                            <img className='house-crown' alt='Nhà' src={`${URI_PATH}${dataToolTip.HouseUse.AssetImage}`}/>
                            <p>{dataToolTip.HouseUse.AssetName}</p>
                        </div>
                    }
                    {dataToolTip.CrownUse &&
                        <div style={{margin: '0 1vh'}}>
                            <img className='house-crown' alt='Vương miện' src={`${URI_PATH}${dataToolTip.CrownUse.AssetImage}`}/>
                            <p>{dataToolTip.CrownUse.AssetName}</p>
                        </div>
                    }
                    {dataToolTip.ListCrest?.length > 0 &&
                        <div style={{margin: '0 1vh'}}>
                            <img className='bonus-crest-img' alt='Huy chương' src={require('../assets/image/crest.png')}/>
                            <p>Huy chương Z {`${(dataToolTip.ListCrest.length > 1 ? ('x' + dataToolTip.ListCrest.length) : "")}`}</p>
                        </div>
                    }
                </div>
                <div className='flex-row item-center mgt-2'>
                    <img
                        src={require('../assets/image/home.png')}
                        className='tooltip-user-icon'
                        onClick={() => {
                            if(this.props.location?.pathname == '/home/guest'){
                                this.props.history.push('/blank');
                                setTimeout(() => {
                                    this.props.history.push('/home/guest?id=' + dataToolTip.UserId)
                                });
                            }
                            else this.props.history.push('/home/guest?id=' + dataToolTip.UserId)
                        }}
                    />
                    <div style={{ position: 'relative' }}>
                        <img
                            src={require('../assets/image/' + (dataToolTip.IsFollow > 0 ? 'follow-on.png' : 'follow-off.png'))}
                            className='tooltip-user-icon'
                            onClick={this.follow}
                        />
                        <span className='count-follow'>{dataToolTip.CountFollow}</span>
                    </div>
                    <img
                        src={require('../assets/image/message.png')}
                        className='tooltip-user-icon'
                        onClick={() => {
                            this.props.getListChatPeople(dataToolTip.UserId);
                            this.props.showHideChat(true, false, dataToolTip);
                        }}
                    />
                    <img
                        src={require('../assets/image/create-group.png')}
                        className='tooltip-user-icon'
                        onClick={() => this.inviteMember(dataToolTip.UserId)}
                    />
                </div>
            </div>
        )
    }

    render() {
        let { userInfo, avatarClass, dataUser, customComponent } = this.props;
        if (!customComponent && dataUser.UserId == userInfo.UserId) return (
            <img
                className={(avatarClass ? avatarClass : 'avatar-guest')}
                key={userInfo.UserId}
                alt='avatar-user'
                src={getAvatarFb(userInfo.FacebookId)}
            />
        )
        return (
            <Tooltip
                overlayClassName='tooltip-user'
                onVisibleChange={this.loadData}
                placement="right"
                trigger={isTouch() ? 'click' : 'hover'}
                title={this.renderTooltip}
            >
                {customComponent ? customComponent :
                    <img
                        className={(avatarClass ? avatarClass : 'avatar-guest')}
                        key={userInfo.UserId}
                        alt='avatar-user'
                        src={getAvatarFb(userInfo.FacebookId)}
                    />
                }
            </Tooltip>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { showHideChat, getListChatPeople })(withRouter(TooltipUser));