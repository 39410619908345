import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {MESSAGE} from '../../utils/constants';
import {getListBonusApi} from '../../api/video';
import {showNotification} from '../../utils/notification';
import {Button} from 'antd';
import Loading from '../../components/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../components/TooltipUser';
const ButtonGroup = Button.Group;

class VideoBonus extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectItem: 1,
            bonusVideo: [],
            isLoading: true,
            endList: false
        };
        this.type = 1;
        this.page = 1;
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.type) return 'button-group-pv-bonus bg-success';
        return 'button-group-pv-bonus';
    }

    getListBonus = (changeType = false) =>{
        getListBonusApi(this.type, this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    bonusVideo: changeType ? res.data : [...this.state.bonusVideo, ...res.data],
                    isLoading: false,
                    endList: res.data.length == 0
                });
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1)
        })
    }

    selectItem = (type) =>{
        this.type = type;
        this.page = 1;
        this.getListBonus(true);
    }

    loadMore = () =>{
        this.page += 1;
        this.getListBonus();
    }

    componentDidMount(){
        this.getListBonus();
    }

    render(){
        let {isLoading, bonusVideo, endList} = this.state;
        if(isLoading) return <Loading />;
        return(
            <div>
                <div className="flex-column item-center mgt">
                    <img
                        src={require('../../assets/image/money.png')}
                        className='pv-bonus-img animated swing slow infinite'
                    />
                    <p className="pv-bonus-title">Thưởng top bình chọn video</p>
                    <p className='bold text-large' style={{padding: '2vh'}}>Video được bình chọn nhiều nhất trong ngày sẽ nhận được mức thưởng tương ứng vào lúc 23h50 cuối ngày</p>
                    <ButtonGroup className='item-center' style={{display: 'flex', width: '100%'}}>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.selectItem(1)}>Ngày</Button>
                    </ButtonGroup>
                </div>
                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {bonusVideo.map((item, key) =>(
                        <div
                            className='pv-bonus-container'
                            key={key}
                            onClick={() => this.props.history.push('/menu/video-search?id=' + item.PostvideoId)}
                        >
                            <img src={require('../../assets/image/logo-z.png')} style={{width: '20vh'}} />
                            <p className='text-huge bold mgt'>CHỨNG NHẬN</p>
                            <p className='text-huge bold mgt'>GIẢI NHẤT: {item.DayType} {item.CrDateTime}</p>
                            <p className='text-huge bold mgt'>{item.BonusZCost} đ[z] & {item.BonusCost} $[z]</p>
                            <TooltipUser userInfo={item} avatarClass='avatar-guest-large mgt'/>
                            <p className='text-large bold mgt'>{item.FullName}</p>
                            <p className='text-large bold mgt'>Video tựa đề: "{item.PostVideoName}"</p>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint})(withRouter(VideoBonus));