import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Button } from 'antd';
import { priceFormat } from '../../../utils/common';
import {withRouter} from 'react-router-dom';
import { showNotification } from '../../../utils/notification';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';

class GiftSpin extends Component{
    navigeClubFeast = () =>{
        if(!this.props.dataUser.ClubId) showNotification('Nhận phát lộc chỉ dành cho thành viên có nhóm', 0, '#notificationError');
        else this.props.history.push('/menu/club-fortune');
    }

    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                        <Checked data={data} />
                        </span> mở Quà thần bí nhận được {priceFormat(data.Content)} $[z]</p>
                        {data.ClubId > 0 &&
                            <p>Thành viên {data.ClubName} được phát lộc {priceFormat(parseInt(data.Content * 2))} đ[z]</p>
                        }
                        {data.BalanceAffiliate1 > 0 &&
                            <p>Quân sư cấp 1 <span className='bold'>{data.UserAffiliate1}</span> nhận được thưởng 10% ({priceFormat(data.BalanceAffiliate1)} $[z])</p>
                        }
                        {data.BalanceAffiliate2 > 0 &&
                            <p>Quân sư cấp 2 <span className='bold'>{data.UserAffiliate2}</span> nhận được thưởng 20% ({priceFormat(data.BalanceAffiliate2)} $[z])</p>
                        }
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div id='notificationError'></div>
                {!data.Guest &&
                    <div className='row item-center'>
                        <div className='col-md-4 col-sm-4 col-xs-6 item-center'>
                            <Button
                                className='newfeed-button mgt-2'
                                onClick={this.navigeClubFeast}
                            >
                                Nhận phát lộc
                            </Button>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 item-center'>
                            <Button
                                className='newfeed-button mgt-2'
                                onClick={() => this.props.history.push('/menu/gift-spin')}
                            >
                                Quà thần bí
                            </Button>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-6 item-center'>
                            <Button
                                className='newfeed-button mgt-2'
                                onClick={() => this.props.history.push('/menu/charge-affiliate')}
                            >
                                Tuyển quân
                            </Button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(GiftSpin));