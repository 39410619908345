import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import TooltipUser from '../../../components/TooltipUser';
import { priceFormat, formatDecimal, getDataNotify } from '../../../utils/common';
import { LIST_DONATE, PROP, MESSAGE } from '../../../utils/constants';
import { Button, Modal } from 'antd';
import {socket} from '../../../components/realtime/ReadSocketEvent';
import {showNotification, showNotificationBonus} from '../../../utils/notification';
import {deleteStatusApi, donateStatusApi} from '../../../api/newfeed/updateStatus';
import {changeDataNewfeed} from '../../../actions/newfeed';
import { changePoint } from '../../../actions/home/';
import Checked from '../../frame/Checked';

class UpdateStatus extends Component{
    constructor(props){
        super(props);
        this.state = {
            isPress: false,
            balanceDonate: LIST_DONATE[0],
            showModal: false
        }
    }

    donateStatus = (newfeedId, balanceDonate) =>{
        if(!this.state.isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationDonateStatusNewfeed' + newfeedId;
            donateStatusApi(newfeedId, balanceDonate).then(res =>{
                if(res.code == 1){
                    showNotificationBonus(balanceDonate, -1, 2);
                    showNotificationBonus(balanceDonate / 1000, 1, 5);
                    this.props.changePoint([null, -balanceDonate, null, null, balanceDonate / 1000]);
                    this.props.changeDataNewfeed(this.props.listNewfeed.map(el => {
                        if(el.NewfeedId == newfeedId) return Object.assign({}, el, {
                            TargetId: parseInt(el.TargetId) + parseInt(balanceDonate)
                        })
                        return el;
                    }));
                    //socket
                    let dataUser = this.props.dataUser;
                    let datas = getDataNotify(res.toUserId, 0, res.balanceBonus);
                    datas.message = {
                        fullName: dataUser.FullName,
                        facebookId: dataUser.FacebookId,
                        message: 'tặng '+ formatDecimal(balanceDonate) +' $[z] cho tâm trạng của bạn',
                        isNotify : 2
                    }
                    socket.emit('send_notify', datas);
                    //
                }
                this.setState({isPress: false});
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    deleteStatus = newfeedId =>{
        if(!this.state.isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationDeleteStatusNewfeed' + newfeedId;
            deleteStatusApi(newfeedId).then(res =>{
                if(res.code == 1){
                    this.props.changeDataNewfeed(this.props.listNewfeed.filter(el => {
                        return el.NewfeedId != newfeedId;
                    }));
                }
                this.setState({
                    isPress: false,
                    showModal: false
                });
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    render(){
        let {data} = this.props;
        let {isPress, showModal} = this.state;
        return(
            <div className='relative'>
                <div className='flex-row'>
                    <div className='flex-row flex-3 align-center'>
                        <div>
                            <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                        </div>
                        <div className='flex-column mgl-2'>
                            <p>
                                <span className='bold'>{data.FullName}</span>
                                <Checked data={data} />
                            </p>
                            <p className='silver'>{data.CrDateTime}</p>
                        </div>
                    </div>
                    <div className='flex-row flex-2 align-center' style={{justifyContent: 'flex-end'}}>
                        <div>
                            <img
                                src={require('../../../assets/image/donate.png')}
                                className='avatar-guest'
                            />
                        </div>
                        <div className='pv-donate-container'>
                            <p className='text-large bold'>{priceFormat(data.TargetId)} $[z]</p>
                        </div>
                    </div>
                </div>
                <div className='row item-center'>
                    <p className='col-md-8 col-sm-8 col-xs-12 newfeed-status'>{data.Status}</p>
                </div>
                {!data.Guest &&
                    <>
                        {this.props.dataUser.UserId != data.UserId ?
                            <div>
                                <div className='row item-center'>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                        <select
                                            className='form-control mgt'
                                            onChange={e => this.setState({balanceDonate: e.target.value})}
                                        >
                                            {LIST_DONATE.map((item, key) =>(
                                                <option key={key} value={item}>Tặng {priceFormat(item)} $[z]</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div id={'notificationDonateStatusNewfeed' + data.NewfeedId} />
                                <div className='flex-column item-center'>
                                    <Button
                                        className='bg-success mgt'
                                        onClick={() => this.donateStatus(data.NewfeedId, this.state.balanceDonate)}
                                        loading={isPress}
                                    >
                                        Tặng
                                    </Button>
                                </div>
                            </div>
                        :
                            <>
                                <div id={'notificationDeleteStatusNewfeed' + data.NewfeedId} />
                                <img
                                    src={require('../../../assets/image/delete.png')}
                                    className='delete-image'
                                    style={{position: 'absolute', right: '2vh', top: '6vh'}}
                                    onClick={() => this.setState({showModal: true})}
                                />
                            {/* <div className='flex-column item-center'>
                                
                                <Button
                                    className='bg-danger mgt'
                                    onClick={() => this.setState({showModal: true})}
                                    loading={isPress}
                                >
                                    Xoá
                                </Button>
                            </div> */}
                            </>
                        }
                    </>
                }
                {showModal &&
                    <Modal
                        {...PROP.MODAL}
                        visible={showModal}
                        onCancel={() => this.setState({showModal: false})}
                        centered
                    >
                        <div className='flex-column item-center'>
                            <p className='text-huge text-center mgt-2'>Xoá tâm trạng ?</p>
                            <p className='newfeed-status'>{data.Status}</p>
                            <Button
                                className='bg-danger mgt'
                                onClick={() => this.deleteStatus(data.NewfeedId)}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, {changePoint, changeDataNewfeed})(withRouter(UpdateStatus));