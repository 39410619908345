import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const publishPostApi = (CategoryId, PostReason, PostTitle, PostContent, PostImage) =>{
    let url = `${URI_PATH}app/post/publish/publishPost`;
    let body = {
        CategoryId,
        PostReason,
        PostTitle,
        PostContent,
        PostImage
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}