import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Logo from '../../components/lobby/Logo';
import Audio from './Audio';
import { checkManageSupportApi, getCountManageSupportApi } from '../../api/admin/study/support';
import SupportStudent from '../../components/admin/study/SupportStudent';
import SupportAdd from '../../components/admin/study/SupportAdd';
import SupportRank from '../../components/admin/study/SupportRank';
import SupportWait from '../../components/admin/study/SupportWait';

const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            loading: true,
            isManageSupport: false,
            listCountSupport: {}
        }
    }

    renderStep = () => {
        const { step } = this.state;
        switch (step) {
            case 1:
                return (
                    <Audio />
                )
            case 2:
                return (
                    <>
                        <div className='content-study-register-container'>
                            <div className='item-center'>
                                <p className='text-large bold mgt'>CỘNG ĐỒNG TRẺ THẾ HỆ Z</p>
                                <p className='text-large bold mgt'>HỌC HỎI TRÊN CON ĐƯỜNG THÀNH CÔNG</p>
                            </div>
                            <div className='content-study-register'>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Học tập thông minh</i></p>
                                        <p><i>"Thầy đứng trên vai người khổng lồ</i></p>
                                        <p><i> còn bạn hãy đứng trên vai thầy!"</i></p>
                                        <p><i> Nguyễn Minh Ngọc ™</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/ht.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/mt.png')} className='img-study-alpha-intro' />
                                    </div>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column'>
                                        <p className='bold text-center text-large mgt-2'><i>Bám chắc lộ trình</i></p>
                                        <p className='text-content-study-register'><i>{`• Bước 1: Học tập
• Bước 2: Kết nối
• Bước 3: Xây dựng thương hiệu cá nhân
• Bước 4: Định hướng cuộc sống
• Bước 5: Khởi nghiệp thông minh
• Bước 6: Làm chủ cuộc đời lớn.
`}</i></p>
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Phát huy đúng chỗ</i></p>
                                        <p><i>“Cá không thể leo cây, hãy chọn đúng môi trường.”</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/kd.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/z.png')} className='img-study-alpha-intro' style={{ border: '1px solid #9c9c9c', borderRadius: 35 }} />
                                    </div>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Uy tín bền lâu</i></p>
                                        <p className='text-content-study-register'><i>{`“Người uy tín, chọn làm sản phẩm uy tín.”`}</i></p>
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold text-large text-center mgt-2'><i>Sống thật nhẹ đầu</i></p>
                                        <p className='text-content-study-register'><i>{`“Có sao nói vậy, thật là nhất.”`}</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 item-center'>
                                        <img src={require('../../assets/image/alpha/sm.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/cs.png')} className='img-study-alpha-intro' />
                                    </div>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Cùng nhau lợi thế</i></p>
                                        <p><i>“Cộng đồng tốt, gia tăng uy tín cá nhân cực tốt.”</i></p>
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold text-center text-large mgt-2'><i>Chắc chắn thành công</i></p>
                                        <p className='text-content-study-register text-center'><i>{`“Thực sự có cầu, bắt đầu sản xuất.”`}</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex-column item-center'>
                                        <img src={require('../../assets/image/alpha/tn.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportAdd />
                    </>
                )
            case 4:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportWait isManage={true} />
                    </>
                )
            case 5:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportStudent isManage={true} />
                    </>
                )
            case 6:
                return (
                    <>
                        <div className='mgt-2'></div>
                        <SupportRank />
                    </>
                )
        }
        return null
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.step) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    setStep = (step) => {
        this.setState({ step })
    }

    checkManageSupport = () => {
        checkManageSupportApi().then(res => {
            if (res.code == 1){
                this.setState({ isManageSupport: res.isManageSupport })
                this.getCountManageSupport();
            }
        })
    }

    getCountManageSupport = () =>{
        getCountManageSupportApi().then(res =>{
            if(res.code == 1) this.setState({listCountSupport: res.data})
        })
    }

    componentDidMount() {
        this.checkManageSupport();
    }

    render() {
        const { isManageSupport, listCountSupport } = this.state;
        return (
            <>
                {/* <Logo />
                <div className="row mgt-2">
                    <ButtonGroup className='item-center flex' style={{ width: '100%' }}>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.setStep(1)}>Z-Audio</Button>
                        <Button className={this.buttonGroupClass(2)} onClick={() => this.setStep(2)}>Mục tiêu</Button>
                        {isManageSupport &&
                            <>
                                <Button className={this.buttonGroupClass(3)} onClick={() => this.setStep(3)}>Thêm HV</Button>
                                <Button className={this.buttonGroupClass(4)} onClick={() => this.setStep(4)}>Chờ CS{(listCountSupport.WaitCount && `(${listCountSupport.WaitCount})`)}</Button>
                                <Button className={this.buttonGroupClass(5)} onClick={() => this.setStep(5)}>Đang CS{(listCountSupport.CurrentCount && `(${listCountSupport.CurrentCount})`)}</Button>
                                <Button className={this.buttonGroupClass(6)} onClick={() => this.setStep(6)}>BXH CSHV</Button>
                            </>
                        }
                    </ButtonGroup>
                </div>
                {this.renderStep()} */}
                <Audio />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(Main))