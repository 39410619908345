import React from 'react';

const answerAlphabet = {
    0: 'A.',
    1: 'B.',
    2: 'C.',
    3: 'D.'
}

export default (props) =>{
    let {answer, correctAnswer, keySelect, selectAnswerKey} = props;
    let classAnswer = 'answer-question';
    if(answer === correctAnswer) classAnswer = 'answer-question bg-success';
    else if(selectAnswerKey === keySelect) classAnswer = 'answer-question check-answer';
    return(
        <p className={classAnswer}>{answerAlphabet[keySelect]} {answer}</p>
    )
}