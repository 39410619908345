import React, { Component } from 'react';
import { Button } from 'antd';
import { priceFormat } from '../../../utils/common';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';
import { connect } from 'react-redux';

class GiftPresent extends Component{
    render(){
        const {dataUser} = this.props;
        let contentLanguage = 'QouteContent';
        let ipaLanguage = 'Ipa';
        if(dataUser.Language == 2){
            contentLanguage = 'QouteContentJa';
            ipaLanguage = 'IpaJa';
        }
        else if(dataUser.Language == 3){
            contentLanguage = 'QouteContentKo';
            ipaLanguage = 'IpaKo';
        }
        const data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                        <Checked data={data} />
                        </span> mở quà thử thách level {data.Level}: {data.DayTotal} ngày dậy sớm liên tiếp nhận được {priceFormat(data.Cost)} $[z]</p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-column item-center'>
                    <p className='mgt qoute-gift'>{'"' + data[contentLanguage]?.replace(/。\//g, '。/\n') + '"'}</p>
                    <p className='mgt qoute-gift'>{'"' + data.QouteTranslate + '"'}</p>
                    <p className='qoute-ipa mgt-2'>{data[ipaLanguage]}</p>
                    <p
                        className='qoute-gift mgt-3'
                        style={{paddingRight: '4vh'}}
                    >
                        Tác giả: {data.Author}
                    </p>
                    {!data.Guest &&
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={() => this.props.history.push('/menu/gift-day')}
                        >
                            Quà thử thách
                        </Button>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}


export default connect(mapStateToProps)(withRouter(GiftPresent));