import { KEY, URI_PATH } from './constants';
import { showNotification } from './notification';
import Cookies from 'js-cookie';
import store from '../store';
import moment from 'moment';
import { checkEnegyApi } from '../api/user';
import { showModalEat } from '../actions/home/modalEat';

export const isStudent = () => {
  const dataUser = store.getState().getUserData.dataUser;
  return dataUser.IsStudent > 0
}

export const isMaster = () => {
  const dataUser = store.getState().getUserData.dataUser;
  return dataUser.IsMaster > 0
}

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

export const isTeacher = () => {
  const dataUser = store.getState().getUserData.dataUser;
  return dataUser.IsTeacher > 0
}

export const getAvatarFb = (avatar) => {
  if (/^(.+)\/([^\/]+)$/.test(avatar)) return URI_PATH + avatar;
  else if (avatar > 0) return 'https://graph.facebook.com/' + avatar + '/picture?type=large';
  return require('../assets/image/facebook-avatar.png');
}

export const isExistToken = () => {
  let token = Cookies.get(KEY.TOKEN);
  return token !== undefined;
}

export const getCostInvest = (balanceChange, percentChange, type = 1) => {
  if (type == 1) {// mua vào
    return Math.round(parseInt(replaceCost(balanceChange)) + parseInt(replaceCost(balanceChange)) * Math.abs(parseFloat(percentChange)) / 100);
  }
  return Math.round(parseInt(replaceCost(balanceChange)) - parseInt(replaceCost(balanceChange)) * Math.abs(parseFloat(percentChange)) / 100);
}

export const getDataNotify = (userId, ticket = 0, balance = 0, balanceZ = 0, achievement = 0, userLike = 0, property = []) => {
  return {
    UserId: parseInt(userId),
    TicketCount: parseFloat(ticket),
    Balance: parseInt(balance),
    BalanceZ: parseInt(balanceZ),
    Achievement: parseInt(achievement),
    UserLike: parseInt(userLike),
    LeaderPoint: parseInt(property.LeaderPoint),
    ManagePoint: parseInt(property.ManagePoint),
    RelationPoint: parseInt(property.RelationPoint),
    FundPoint: parseInt(property.FundPoint),
    LanguagePoint: parseInt(property.LanguagePoint),
    ExpertisePoint: parseInt(property.ExpertisePoint)
  };
}

export const isTouch = () => {
  return ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
}

export const priceFormat = (n, c, d, t) => {
  var c = isNaN(c = Math.abs(c)) ? 0 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export const replaceCost = (cost, isInt) => {
  cost = cost.toString().replace(/\,/g, '');
  if (cost == '') cost = 0;
  if (isInt) return parseInt(cost);
  else return parseFloat(cost);
}

export const formatDecimal = (value) => {
  value = value.toString();
  value = value.replace(/\,/g, '');
  while (value.length > 1 && value[0] == '0' && value[1] != '.') value = value.substring(1);
  if (value != '' && value != '0') {
    if (value[value.length - 1] != '.') {
      if (value.indexOf('.000') > 0) value = value.substring(0, value.length - 3);
      value = addCommas(value);
      return value;
    }
    else return value;
  }
  else return 0;
}

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getDecimalCount = (num) => {
  return (num.toString().split('.')[1] || []).length;
}

export const validatePhoneNumber = (input) => {
  if (input.indexOf(' ') >= 0) {
    return false;
  }
  if (input.length == 10 || input.length == 11) {
    var filter = /^[0-9-+]+$/;
    if (!filter.test(input)) {
      return false;
    }
  }
  else {
    return false;
  }
  return true;
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const timeDiamondPostVideo = () => {
  let time = new Date();
  let ddMMYY = time.toLocaleString("en-ZA").substring(0, 10).split("/").join("-");
  let countTime;
  switch (time.getHours()) {
    case 0: case 1:
      countTime = "02:00:00";
      break;
    case 2: case 3:
      countTime = "04:00:00";
      break;
    case 4: case 5:
      countTime = "06:00:00";
      break;
    case 6: case 7:
      countTime = "08:00:00";
      break;
    case 8: case 9:
      countTime = "10:00:00";
      break;
    case 10: case 11:
      countTime = "12:00:00";
      break;
    case 12: case 13:
      countTime = "14:00:00";
      break;
    case 14: case 15:
      countTime = "16:00:00";
      break;
    case 16: case 17:
      countTime = "18:00:00";
      break;
    case 18: case 19:
      countTime = "20:00:00";
      break;
    case 20: case 21:
      countTime = "22:00:00";
      break;
    case 22: case 23:
      ddMMYY = addDays(time, 1).toLocaleString("en-ZA").substring(0, 10).split("/").join("-");
      countTime = "00:00:00";
      break;
  }
  return ((ddMMYY + ' ' + countTime).replace(/-/g, '/'));
}

const timeAddZero = (value) => {
  return value >= 10 ? value : `0${value}`;
}

export const getTimeCountdown = (time, dayAdd = 90) => {
  let [dayInit, monthInit, yearInit] = time.split('/');
  let countDownDate = new Date(yearInit, monthInit - 1, dayInit).setDate(new Date(yearInit, monthInit - 1, dayInit).getDate() + dayAdd)
  let now = new Date().getTime();
  let distance = countDownDate - now;

  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    days,
    hours: timeAddZero(hours),
    minutes: timeAddZero(minutes),
    seconds: timeAddZero(seconds)
  }
}

export const getImage64 = (file, callBack, max_size = 1366) => {
  if (file) {
    let typeFile = file.type.replace('image/', '');
    let whiteList = ['jpeg', 'jpg', 'png', 'bmp'];
    if (whiteList.indexOf(typeFile) === -1) return showNotification('Tệp tin phải là ảnh có định dạng , jpeg/jpg/png/bmp', 0);
    let reader = new FileReader();
    reader.addEventListener("load", function (readerEvent) {
      let image = new Image();
      image.src = readerEvent.target.result;
      image.onload = function (imageEvent) {
        // Resize the image
        let canvas = document.createElement('canvas'),// TODO : pull max size from a site config
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        let dataUrl = canvas.toDataURL('image/jpeg');
        let image64 = dataUrl;
        if (image64.length > 0) callBack(image64);
      }
      //
    }, false);
    if (file) reader.readAsDataURL(file);
  }
}

export const getYoutubeId = (url) => {
  var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  var matches = url.match(p);
  if (matches) {
    return matches[1];
  }
  return false;
}

export const getTimeAgo = (dateSql) => {
  moment.updateLocale('en', {
    relativeTime: {
      future: "vào %s",
      past: "%s trước",
      s: 'vài giây',
      ss: '%d giây',
      m: "1 phút",
      mm: "%d phút",
      h: "1 giờ",
      hh: "%d giờ",
      d: "1 ngày",
      dd: "%d ngày",
      w: "1 tuần",
      //@ts-ignore
      ww: "%d tuần",
      M: "1 tháng",
      MM: "%d tháng",
      y: "1 năm",
      yy: "%d năm"
    }
  });
  return moment(dateSql, "YYYY-MM-DD HH:mm").fromNow();
}

export const getMiniusSecond = (time) => {
  var hr = ~~(time / 3600);
  var min = ~~((time % 3600) / 60);
  var sec = time % 60;
  var sec_min = "";
  if (hr > 0) {
    sec_min += "" + hr + ":" + (min < 10 ? "0" : "");
  }
  sec_min += "" + min + ":" + (sec < 10 ? "0" : "");
  sec_min += "" + sec;
  return sec_min;
}

const addCommas = (nStr) => {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

const addDays = (dateObj, numDays) => {
  dateObj.setDate(dateObj.getDate() + numDays);
  return dateObj;
}

export const getUrlGoogleSlide = (url) => {
  const first = url.substring("spreadsheets/d/e/");
  return first.substring(0, first.lastIndexOf("/pub?"));
}
export const removeUnicode = (str) => {
  if (typeof str != 'string') {
    return str;
  }
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|$|_/g, "");
  str = str.replace(/-+-/g, " ");
  str = str.replace(/^\-+|\-+$/g, " ");
  return str;
}

export const videoGuides = [
  {
    title: 'THẾ NÀO LÀ THẮNG?',
    video: 'AcN_3PrC7sc'
  },
  {
    title: 'HOẠT ĐỘNG MỞ QUÀ',
    video: 'oYbth5YInwc'
  },
  {
    title: 'HOẠT ĐỘNG SỰ KIỆN NGOẠI NGỮ',
    video: 'Ts_b0_tyltA'
  },
  {
    title: 'HOẠT ĐỘNG CHƠI CHỨNG KHOÁN',
    video: 'ZTj6PT4KwKI'
  },
  {
    title: 'HOẠT ĐỘNG SIÊU TRÍ NHỚ',
    video: 'A4KOEdVQwQA'
  },
  {
    title: 'HOẠT ĐỘNG TOÁN KHỞI NGHIỆP',
    video: '1e39EY3J_HA'
  },
  {
    title: 'HOẠT ĐỘNG SHOPPING',
    video: 'Q-QWxhEow_0'
  },
  {
    title: 'HOẠT ĐỘNG GHÉP NHÓM',
    video: 'vZ3gIVUB-Fo'
  },
  {
    title: 'HOẠT ĐỘNG QUÀ THẦN BÍ',
    video: 'LpbPDWz9mN8'
  },
  {
    title: 'HOẠT ĐỘNG PHÁ PHONG ẤN',
    video: 'KYeHMsCNVfA'
  },
  {
    title: 'HOẠT ĐỘNG HUY CHƯƠNG Z',
    video: '6UZKZpcITow'
  },
  {
    title: 'HOẠT ĐỘNG THƯƠNG HIỆU',
    video: 'AjYKue73IXM'
  },
  {
    title: 'HOẠT ĐỘNG CÂU LẠC BỘ',
    video: 'EKOcTJFcmbk'
  },
  {
    title: 'HOẠT ĐỘNG XÂY NHÀ',
    video: 'S2clij8litk'
  },
  {
    title: 'HOẠT ĐỘNG SĂN LINH THÚ',
    video: '5XiM9sZxxMo'
  },
  {
    title: 'HOẠT ĐỘNG MỞ CÔNG TY',
    video: 'JJN9VPvHmjs'
  },
  {
    title: 'HOẠT ĐỘNG MỞ SHOP',
    video: 'vqQ2IB6Rc4g'
  },
]

export const isNumber = (n) => {
  return isNaN(Number(n)) ? false : true
}

export const getTiktokId = (url) => {
  try {
    const matched = url.match(/(@[a-zA-z0-9]*|.*)(\/.*\/|trending.?shareId=)([\d]*)/g);
    if (matched.length > 0) {
      const urlTiktok = matched[0];
      return urlTiktok.match(/\d+$/)[0];
    }
  } catch (error) {
    return false
  }
}

export const checkEnegy = async () => {
  const res = await checkEnegyApi();
  if (res.code == -5) {
    store.dispatch(showModalEat(res.listFood));
    throw 'need eat'
  }
  else return true
}

export const convertNonUnicode = (str) => {
  var accentsMap = [
    "aàảãáạăằẳẵắặâầẩẫấậ",
    "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
    "dđ", "DĐ",
    "eèẻẽéẹêềểễếệ",
    "EÈẺẼÉẸÊỀỂỄẾỆ",
    "iìỉĩíị",
    "IÌỈĨÍỊ",
    "oòỏõóọôồổỗốộơờởỡớợ",
    "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
    "uùủũúụưừửữứự",
    "UÙỦŨÚỤƯỪỬỮỨỰ",
    "yỳỷỹýỵ",
    "YỲỶỸÝỴ"
  ];
  for (var i = 0; i < accentsMap.length; i++) {
    var re = new RegExp('[' + accentsMap[i].substr(1) + ']', 'g');
    var char = accentsMap[i][0];
    str = str.replace(re, char);
  }
  return str;
}

export const speakEng = (text, isSpeakEng = false) =>{
  try {
    const synth = window.speechSynthesis;
    const utterance = new window.SpeechSynthesisUtterance();
    let voice = store.getState().getVoice.voice;
    if(isSpeakEng){
      const voices = window.speechSynthesis.getVoices();
      voice = voices?.find(i => i?.name === 'Karen' || i?.name === 'Google US English')
    }
    utterance.voice = voice;
    utterance.volume = 1
    utterance.lang = "en-US";
    utterance.text = text?.replace(/\/(.*)/g, '')
    synth.cancel()
    synth.speak(utterance)
  } catch (error) {
    console.log({error});
  }
}