import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { updateCountMission } from '../../actions/menu';
import { getListGiftEffortApi, openGiftApi, checkOpenGiftApi, buyGiftApi } from '../../api/gift/effort';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import { showNotification, showNotificationBonus, showNotificationAsset, showNotificationProperty } from '../../utils/notification';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import gifteffort from '../../assets/lottie/gifteffort.json';
import { Button, Modal } from 'antd';
import { getAvatarFb, formatDecimal, checkEnegy } from '../../utils/common';
import CountUp from 'react-countup';
import QuestionGift from '../../components/gift/QuestionGift';
import { TitleSuccess } from '../../components/frame';

class GiftEffort extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            isLoading: true,
            qoute: '',
            author: '',
            cost: '',
            giftData: {},
            giftInfo: {},
            selectGiftbuyId: '',
            showGiftQuestion: false,
            showOpenGift: false,
            showModal: false
        }
    }

    checkQuestion = (giftInfo) => {
        if (!this.state.isPress) {
            checkEnegy().then(res =>{
                let gifteffortId = giftInfo.GifteffortId;
                this.setState({
                    isPress: gifteffortId,
                });
                let idNotificationGift = '#notificationGiftDay' + gifteffortId;
                checkOpenGiftApi(gifteffortId).then(res => {
                    if (res.code == 1) {
                        this.setState({
                            showGiftQuestion: true,
                            giftInfo: giftInfo
                        })
                    }
                    else if (res.code == -2) {
                        this.setState({ showModal: true })
                    }
                    else showNotification(res.message, res.code, idNotificationGift);
                    this.setState({ isPress: false });
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, 0, idNotificationGift);
                    this.setState({ isPress: false });
                })
            })
        }
    }

    openGift = () => {
        let { giftInfo, giftData } = this.state;
        let gifteffortId = giftInfo.GifteffortId;
        openGiftApi(gifteffortId).then(res => {
            if (res.code == 1) {
                this.setState({
                    cost: res.data,
                    qoute: res.qouteContent,
                    author: res.author,
                    isLoading: false,
                    showOpenGift: true,
                    giftData: {
                        ...giftData,
                        listGiftEffort: giftData.listGiftEffort.map(el => {
                            if (el.GifteffortId == gifteffortId) return Object.assign({}, el, { IsOpen: 0 })
                            return el
                        })
                    }
                });
                setTimeout(() => {
                    showNotificationAsset(res.assetInfo);
                    showNotificationProperty(res.property);
                    showNotificationBonus(res.data, 1, 2);
                    this.props.changePoint([null, res.data]);
                    this.props.updateCountMission('GiftEffortCount');
                }, 3200);
            }
            else showNotification(res.message, res.code);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    getListGiftEffort = () => {
        getListGiftEffortApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    giftData: res.data
                });
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    buyGift = () => {
        if (!this.state.isPress) {
            let { selectGiftbuyId } = this.state;
            let idNotification = '#notificationBuyGift';
            if (selectGiftbuyId > 0) {
                buyGiftApi(this.state.selectGiftbuyId).then(res => {
                    showNotification(res.message, res.code, idNotification);
                    if (res.code == 1) {
                        showNotificationBonus(res.data.GiftAmount, -1, 2);
                        this.props.changePoint([null, -res.data.GiftAmount]);
                        this.setState({
                            giftData: {
                                ...this.state.giftData,
                                isEndDateExpire: 2,
                                dateExpire: res.data.DateExpire,
                            },
                            showModal: false
                        })
                    }
                    else this.setState({ isPress: false });
                }).catch(error => {
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({ isPress: false });
                })
            }
            else showNotification('Vui lòng chọn gói quà cần mua', 0, idNotification);
        }
    }

    renderExtendGift = () => {
        const { giftData, isPress } = this.state;
        return (
            <div className='flex-column item-center mgt mgb'>
                <p className='bold text-title-gift'>Gia hạn quà tăng trưởng</p>
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ selectGiftbuyId: e.target.value })}
                >
                    <option
                        value=''
                    >
                        Chọn gói quà muốn mua
                    </option>
                    {giftData.buyGift.map((item, key) => (
                        <option
                            key={key}
                            value={item.GiftbuyId}
                        >
                            {item.GiftName} ({item.DayBounus} ngày) - {formatDecimal(item.GiftAmount)} $[z]
                        </option>
                    ))}
                </select>
                <div className='mgt' id='notificationBuyGift'></div>
                <Button
                    className='bg-primary flash mgt'
                    onClick={this.buyGift}
                    loading={isPress === true}
                >
                    Kích hoạt
                </Button>
            </div>
        )
    }

    componentDidMount() {
        this.getListGiftEffort();
    }

    render() {
        let { isLoading, giftData, showGiftQuestion, isPress, giftInfo, cost, author, qoute, showOpenGift, showModal } = this.state;
        let { FullName, FacebookId } = this.props.dataUser;
        if (isLoading) return (<Loading />);
        return (
            <div>
                <HeaderLottie source={gifteffort} disableLoop />
                <div className='flex item-center'>
                    <p className='bold title-success'>
                        {this.props.countMission.GiftEffortCount > 0 ?
                            'Mở thành công 3 hộp quà tăng trưởng bạn sẽ vượt qua thử thách'
                            :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div>
                <div>
                    <div className='flex-column item-center mgt'>
                        <p className='bold text-title-gift'>Quà tăng trưởng {giftData.isEndDateExpire == 2 ? `đến ${giftData.dateExpire}` : 'chưa được kích hoạt'}</p>
                    </div>
                    <div className='mgt'>
                        {giftData.listGiftEffort.map((item, key) => (
                            <div key={key} className='container-list-gift'>
                                <div className='flex-row'>
                                    <div className='flex-3 flex-column item-center mgt'>
                                        <img
                                            src={`${URI_PATH}${item.GiftImage}`}
                                            className='gift-img'
                                        />
                                        <p>{item.GiftName}</p>
                                        <p className='silver'>{item.GiftDesc}</p>
                                    </div>
                                    <div className='flex-2 flex-column item-center mgt'>
                                        <Button
                                            className={'button-open-gift ' + (item.IsOpen != 2 && 'bg-primary')}
                                            onClick={() => this.checkQuestion(item)}
                                            loading={isPress == item.GifteffortId}
                                        >
                                            Nhận
                                        </Button>
                                    </div>
                                </div>
                                <div id={'notificationGiftDay' + item.GifteffortId} />
                            </div>
                        ))}
                    </div>
                    {!showModal ?
                        <>
                            {this.renderExtendGift()}
                        </>
                        : null
                        }
                </div>
                {showGiftQuestion &&
                    <QuestionGift
                        onClose={() => this.setState({
                            showGiftQuestion: false,
                            showOpenGift: false
                        })}
                        onSuccess={this.openGift}
                        showOpenGift={showOpenGift}
                    >
                        {showOpenGift &&
                            <div>
                                <p className='text-center text-large bold'>{giftInfo.GiftName}</p>
                                <div className='row mgt-3'>
                                    <div className='col-md-4 col-sm-6 col-xs-12 item-center'>
                                        <img
                                            src={getAvatarFb(FacebookId)}
                                            className='avatar-gift'
                                        />
                                        <p className='mgt bold'>{FullName}</p>
                                    </div>
                                    <div className='col-md-8 col-sm-6 col-xs-12 item-center'>
                                        <p className='mgt qoute-gift'>{qoute}</p>
                                        <p className='mgt qoute-gift mgt text-right'>{author}</p>
                                    </div>
                                </div>
                                <div className='mgt-4 text-center bold text-large'>
                                    Bạn nhận được món quà là <CountUp end={cost} duration={4} /> $[z]
                                </div>
                            </div>
                        }
                    </QuestionGift>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({
                        showModal: false,
                    })}
                >
                    {this.renderExtendGift()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { dataUser, countMission };
}

export default connect(mapStateToProps, { changePoint, updateCountMission })(GiftEffort);