import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListUserApi = (PageId, SearchText, Type = 1) =>{
    let url = `${URI_PATH}app/admin/study/user/getListUser`;
    let body = {
        PageId,
        SearchText,
        Type
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toogleStudentApi = (UserId, EndStudentTime, StudyClassId) =>{
    let url = `${URI_PATH}app/admin/study/user/toogleStudent`;
    let body = {
        UserId,
        EndStudentTime,
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toogleTeacherApi = (UserId, MasterId) =>{
    let url = `${URI_PATH}app/admin/study/user/toogleTeacher`;
    let body = {
        UserId,
        MasterId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListMasterApi = () =>{
    let url = `${URI_PATH}app/admin/study/user/getListMaster`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toogleMasterApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/user/toogleMaster`;
    let body = {
        UserId,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeMaxStudentApi = (UserId, MaxStudent) =>{
    let url = `${URI_PATH}app/admin/study/user/changeMaxStudent`;
    let body = {
        UserId,
        MaxStudent
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const editStudentApi = (UserId, EndStudentTime, IsFinish, StudyClassSelect, PackageName, PackageStartDate, PackageEndDate, PackageTotalPrice, PackageSuggestPrice, PackagePaidPrice) =>{
    let url = `${URI_PATH}app/admin/study/user/editStudent`;
    let body = {
        UserId,
        EndStudentTime,
        IsFinish,
        StudyClassSelect,
        PackageName,
        PackageStartDate,
        PackageEndDate,
        PackageTotalPrice,
        PackageSuggestPrice,
        PackagePaidPrice
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListTeacherFromAdminApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/user/getListTeacherFromAdmin`;
    let body = {
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleZcrazyApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/user/toogleZcrazy`;
    let body = {
        UserId,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const resetPasswordApi = (UserId) =>{
    let url = `${URI_PATH}app/admin/study/user/resetPassword`;
    let body = {
        UserId,
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}