import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const donateStatusApi = (newfeedId, balance) =>{
    let url = `${URI_PATH}app/newfeed/UpdateStatus/donateStatus`;
    let body = {
        NewfeedId: newfeedId,
        Balance: balance
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteStatusApi = newfeedId =>{
    let url = `${URI_PATH}app/newfeed/UpdateStatus/deleteStatus`;
    let body = {
        NewfeedId: newfeedId,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}