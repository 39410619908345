import React from 'react';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import NavBar from './NavBar';

export default (props) => (
    <>
        <NavLeft />
        <div className='nav-center'>
            {/* <NavBar /> */}
            <div className='main-content' style={{paddingTop: '0.4vh'}}>
                {props.children}
            </div>
        </div>
        <NavRight />
    </>
)