import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListCourseApi = (searchText, courseTypeId, userId, provinceId, pageId) =>{
    let url = `${URI_PATH}app/course/lists/getListCourse`;
    let body = {
        SearchText: searchText,
        CourseTypeId: courseTypeId,
        UserId: userId,
        ProvinceId: provinceId,
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDataCourseApi = () =>{
    let url = `${URI_PATH}app/course/lists/getDataCourse`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListScheduleApi = courseId =>{
    let url = `${URI_PATH}app/course/lists/getListSchedule`;
    let body = {
        CourseId: courseId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getInfoUserRegisterApi = userId =>{
    let url = `${URI_PATH}app/course/lists/getInfoUserRegister`;
    let body = {
        UserId: userId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkPromotionCodeApi = (promotionCode, courseId) =>{
    let url = `${URI_PATH}app/course/lists/checkPromotionCode`;
    let body = {
        PromotionCode: promotionCode,
        CourseId: courseId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const registerCourseApi = (userId, courseId, ticketCount, balancePaid, promotionCode) =>{
    let url = `${URI_PATH}app/course/lists/registerCourse`;
    let body = {
        UserId: userId,
        CourseId: courseId,
        TicketCount: ticketCount,
        BalancePaid: balancePaid,
        PromotionCode: promotionCode
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}