import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const createRoomApi = (RoomName, QuestionLevelId, BetType, BetValue, MaxPlayer, TimeLimit, FightType) =>{
    let url = `${URI_PATH}app/question/fight/lobby/create`;
    let body = {
        RoomName,
        QuestionLevelId,
        BetType,
        BetValue,
        MaxPlayer,
        TimeLimit,
        FightType
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListRoomApi = PageId =>{
    let url = `${URI_PATH}app/question/fight/lobby/getListRoom`;
    let body = {
        PageId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const joinRoomApi = RoomId =>{
    let url = `${URI_PATH}app/question/fight/lobby/joinRoom`;
    let body = {
        RoomId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const joinLookRoomApi = RoomId =>{
    let url = `${URI_PATH}app/question/fight/lobby/joinLookRoom`;
    let body = {
        RoomId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkIsInRoomApi = () =>{
    let url = `${URI_PATH}app/question/fight/lobby/checkIsInRoom`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}