import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListMemberApi = () =>{
    let url = `${URI_PATH}app/club/member/getListMember`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const expelMemberApi = (userId, comment) =>{
    let url = `${URI_PATH}app/club/member/expelMember`;
    let body = {
        UserId: userId,
        Comment: comment
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListKickApi = () =>{
    let url = `${URI_PATH}app/club/member/getListKick`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const voteKickApi = (userId, voteStatus) =>{
    let url = `${URI_PATH}app/club/member/voteKick`;
    let body = {
        UserId: userId,
        VoteStatus: voteStatus
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListClaimApi = (pageId = 1, searchText = '') =>{
    let url = `${URI_PATH}app/club/member/getListClaim`;
    let body = {
        PageId: pageId,
        SearchText: searchText
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const actionClaimApi = (userId, action) =>{
    let url = `${URI_PATH}app/club/member/actionClaim`;
    let body = {
        UserId: userId,
        Action: action
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}