const INITAL_STATE = {
    categoryPost: [],
    categoryVideo: [],
    categoryQuestion: [],
    categoryAskQuestion: [],
    subCategory: [],
    loadingSubCategory: true
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_CATEGORY':
            if(action.categoryType == 1) return { ...state, subCategory: [], loadingSubCategory: true, categoryPost: action.listCategory};
            else if(action.categoryType == 2) return { ...state, subCategory: [], categoryVideo: action.listCategory };
            else if(action.categoryType == 3) return {
                ...state,
                categoryQuestion: [
                    {CategoryId: 0, CategoryName: 'Hỗn hợp'},
                    ...action.listCategory
                ],
                subCategory: []
            }
            else if(action.categoryType == 4) return { ...state, subCategory: [], categoryAskQuestion: action.listCategory};
        case 'SUCCESS_GET_SUB_CATEGORY':
            return { ...state, subCategory: action.listSubCategory, loadingSubCategory: false };
        case 'FALSE_GET_CATEGORY': case 'FALSE_GET_SUB_CATEGORY':
            return { ...state, subCategory: [] };
        default:
            return state;
    }
};