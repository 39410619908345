import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getPricePayApi = () =>{
    let url = `${URI_PATH}app/admin/ticket/action/getPricePay`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const addOrderApi = (Ticket) =>{
    let url = `${URI_PATH}app/admin/ticket/action/addOrder`;
    let body = {
        Ticket
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListOrderApi = (PageId, SearchText, Status) =>{
    let url = `${URI_PATH}app/admin/ticket/action/getListOrder`;
    let body = {
        PageId,
        SearchText,
        Status
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const confirmOrderApi = (OrderTicketId) =>{
    let url = `${URI_PATH}app/admin/ticket/action/confirmOrder`;
    let body = {
        OrderTicketId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changePriceApi = (BalancePay, Type) =>{
    let url = `${URI_PATH}app/admin/ticket/action/changePrice`;
    let body = {
        BalancePay,
        Type
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}