import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getClubInfoApi = (type) =>{
    let url = `${URI_PATH}app/club/info/getClubInfo`;
    let body = {
        Type: type
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateClubInfoApi = (input, type) =>{
    let url = `${URI_PATH}app/club/info/updateInfoClub`;
    let body = {
        InputData: input,
        UpdateType: type
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const leaveClubApi = () =>{
    let url = `${URI_PATH}app/club/info/leaveClub`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListMemberClubApi = () =>{
    let url = `${URI_PATH}app/club/info/getListMemberClub`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const selectDeputyClubApi = (userId) =>{
    let url = `${URI_PATH}app/club/info/selectDeputyClub`;
    let body = {
        DeputyUserId: userId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
