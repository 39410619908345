import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCountLessonApi } from '../../../api/study/lesson';
import { showNotification } from '../../../utils/notification';
import { MESSAGE, PROP, URI_PATH, KEY } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { changePoint } from '../../../actions/home/';

class GetLesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            countLesson: 0
        }
    }

    getCountLesson = () => {
        getCountLessonApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    countLesson: res.data
                })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidMount() {
        this.getCountLesson();
    }

    render() {
        const { isLoading, countLesson } = this.state
        const { dataUser } = this.props
        return (
            <>
                {isLoading ? <Loading small /> :
                    <div className='item-center flex-column'>
                        {countLesson > 0 && dataUser.IsZcrazy == 2 &&
                            <p className='mgt-3 text-large'>Bạn còn <span className='warning'>{countLesson}</span> bài chưa học</p>
                        }
                        <div
                            className={(countLesson == 0) ? 'button-study-get-lesson-disable' : 'button-study-get-lesson-new'}
                            onClick={() => (countLesson > 0) ? this.props.showModalGetLesson() : null}
                        >
                            <p className='mgb-0 text-large'>Nhận bài học mới</p>
                        </div>
                        {countLesson == 0 &&
                            <>
                                <p className='mgt-3 text-large'>
                                    Chúc mừng, bạn đã hoàn tất các bài học!
                                </p>
                                <p className='text-large'>Để có thêm bài học mới vui lòng liên hệ thầy Nguyễn Minh Ngọc</p>
                            </>
                        }
                    </div>
                }
            </>

        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(GetLesson)