import React from 'react';

export default ({data}) => {
    if(data?.IsZvip == 2){
        return(
            <img src={require('../../assets/image/check-aff.png')} className='checked-user' />
        )
    }
    if(data?.IsZcrazy == 2){
        return(
            <img src={require('../../assets/image/checked.png')} className='checked-user' />
        )
    }
    return ' '
}