import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import history from '../../assets/lottie/history.json';
import {getListHistoryApi} from '../../api/setting/history';
import { showNotification } from '../../utils/notification';
import { MESSAGE, PROP, TICKET } from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';

class History extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            listHistory: [],
            endList: false
        }
        this.logTypeId = '';
        this.page = 1;
    }

    getListHistory = () =>{
        getListHistoryApi(this.logTypeId, this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                    listHistory: this.page == 1 ? res.listHistory : [...this.state.listHistory, ...res.listHistory],
                    endList: res.listHistory.length == 0
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () =>{
        this.page += 1;
        this.getListHistory();
    }

    cssBackground = {
        true: 'bg-success',
        false: 'bg-danger'
    }

    changeLogType = e =>{
        this.logTypeId = e.target.value;
        this.page = 1;
        this.getListHistory();
    }

    componentDidMount(){
        this.getListHistory();
    }

    render(){
        let {isLoading, endList, listHistory, isPress} = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                <HeaderLottie source={history} size='20vh' disableLoop />
                <div className='row item-center'>
                    <div className='col-md-4 col-sm-6 col-xs-12'>
                        <select
                            className='form-control'
                            onChange={this.changeLogType}
                        >
                            <option value="">Tất cả</option>
                            <option value="1">Gold[z]</option>
                            <option value="2">$[z]</option>
                            <option value="3">đ[z]</option>
                            <option value="4">Thành tựu</option>
                            <option value="5">Thương hiệu</option>
                            <option value="6">Lãnh đạo</option>
                            <option value="7">Quản trị</option>
                            <option value="8">Quan hệ</option>
                            <option value="9">Tài chính</option>
                            <option value="10">Chuyên môn</option>
                            <option value="11">Ngoại ngữ</option>
                        </select>
                    </div>
                </div>
                {listHistory.length == 0 ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large mgt'>Không có lịch sử gần đây</p>
                    </div>
                :
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={!endList}
                        loader={<Loading key={0} small/>}
                        threshold={20}
                    >
                        {listHistory.map((item, key) =>
                            <div className='box-primary flex-row' key={key}>
                                <div className='flex item-center'>
                                    <p className={'history-cirle ' + this.cssBackground[item.IsRevice]}>{item.LogType}</p>
                                </div>
                                <div className='history-content'>
                                    <p>{item.Comment}</p>
                                    <p className='silver'>{item.CrDateTime}</p>
                                    <p className={'history-change-container ' + this.cssBackground[item.IsRevice]}>
                                        Thay đổi: {item.AmountTitle}
                                    </p>
                                    <p className={'history-change-container ' + this.cssBackground[item.IsRevice]}>
                                        Kết quả: {item.Result}
                                    </p>
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(History);