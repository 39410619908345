import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {getListItem, actionItem} from '../../actions/charge/market';
import {updateCountMission} from '../../actions/menu';
import {getBuySellRemainApi, getChartInvestApi} from '../../api/home';
import {buyApi} from '../../api/charge/market';
import {MESSAGE, URI_PATH, PROP} from '../../utils/constants';
import {formatDecimal, getCostInvest} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import money from '../../assets/lottie/money.json';
import {Button, Tooltip, Modal} from 'antd';
import Icon from 'react-fontawesome';
import Chart from 'react-apexcharts';
import {showModalEat} from '../../actions/home/modalEat';
import {sellItemApi} from '../../api/home';
const ButtonGroup = Button.Group;

class ChargeInvest extends Component{
    constructor(props){
        super(props);
        this.state = {
            itemInfo: [],
            amountBuySell: '',
            calculateBalance: '',
            buySellRemain: 0,
            isPress: false,
            showModalBuy: false,
            showModalChart: false,
            balanceData: [],
            timeData: {},
            isUpdateInvest: false,
            modalType: 1
        };
        this.intervalUpdate = '';
        this.timeoutUpdate = '';
    }

    showModalItem = (item, modalType) =>{
        let idNotification = `notificationInvest${item.ChargemarketId}`;
        getBuySellRemainApi(item.ChargemarketId, modalType).then(res => {
            if(res.code == 1){
                this.setState({
                    buySellRemain: res.data,
                    showModalBuy: true,
                    itemInfo: item,
                    modalType
                });
            }
            else showNotification(res.message, res.code, idNotification);
        }).catch((e) => { 
            showNotification(MESSAGE.ERROR, -1, idNotification)
        });
    }

    optionChart = (selectItem) =>{
        return{
            chart: {
                type: 'area',
                foreColor: '#fff',
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [70, 100]
                }
            },
            title: {
                text: selectItem == 2 ? 'đ[z]' : '$[z]',
                align: 'left'
            },
            colors:['#00A65A'],
            tooltip:{
                theme: 'dark'
            }
        }
    }

    showModalChart = (item) =>{
        let idNotification = `#notificationInvest${item.ChargemarketId}`;
        if(!this.state.isPress){
            getChartInvestApi(item.ChargemarketId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        showModalChart: true,
                        balanceData: [{
                            name: item.ItemName,
                            data: res.balanceData
                        }],
                        timeData: {
                            xaxis: {
                                categories: res.timeData
                            }
                        },
                        itemInfo: item
                    })
                }
                else{
                    showNotification(MESSAGE.ERROR, -1, idNotification);
                    this.setState({isPress: false});
                }
                
            }).catch((e) => { 
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({isPress: false});
            });
        }
    }

    changeAmount = e =>{
        let amountBuySell = e.target.value.replace(/,|\./g, '');
        let itemInfo = this.state.itemInfo;
        this.setState({
            amountBuySell: amountBuySell,
            calculateBalance:  amountBuySell > 0 ? formatDecimal(amountBuySell * getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange, this.state.modalType)) : ''
        });
    }

    buy = () =>{
        if(this.state.isPress === false){
            let idNotification = '#notificationModalInvest';
            let {itemInfo, amountBuySell} = this.state;
            let chargemarketId = itemInfo.ChargemarketId;
            if(amountBuySell == '' || amountBuySell <= 0) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            buyApi(chargemarketId, amountBuySell).then(res =>{
                if(res.code == 1){
                    let buyBalanceLast = getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange, 1);
                    if(this.props.selectItem == 2){
                        this.props.actionItem(chargemarketId, 2, amountBuySell, buyBalanceLast, res.useritemId);
                        showNotificationBonus(res.balance, -1, 2);
                        showNotificationBonus(res.balanceZ, -1, 3);
                        this.props.changePoint([null, -res.balance, -res.balanceZ]);
                    }
                    else {
                        this.props.actionItem(chargemarketId, 3, amountBuySell, buyBalanceLast, res.useritemId);
                        showNotificationBonus(res.balanceZ, -1, 2);
                        this.props.changePoint([null, -res.balanceZ]);
                    }
                    this.props.updateCountMission('BuyInvestCount');
                    this.setState({
                        showModalBuy: false,
                        isPress: false,
                        amountBuySell: '',
                        calculateBalance: ''
                    });
                    showNotification(res.message, res.code);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    sell = () =>{
        if(this.state.isPress === false){
            let idNotification = '#notificationModalInvest';
            let {itemInfo, amountBuySell} = this.state;
            let chargemarketId = itemInfo.ChargemarketId;
            let useritemId = itemInfo.UserItemId;
            if(amountBuySell == '' || amountBuySell <= 0) return showNotification('Số lượng phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPress: true});
            sellItemApi(chargemarketId, amountBuySell, useritemId).then(res =>{
                if(res.code == 1){
                    if(this.props.selectItem == 2){
                        this.props.actionItem(chargemarketId, 2, -amountBuySell);
                        showNotificationBonus(res.balanceZ, 1, 3);
                        this.props.changePoint([null, res.balance, res.balanceZ]);
                    }
                    else{
                        this.props.actionItem(chargemarketId, 3, -amountBuySell);
                        showNotificationBonus(res.balanceZ, 1, 2);
                        this.props.changePoint([null, res.balanceZ]);
                    }
                    this.setState({
                        showModalBuy: false,
                        isPress: false,
                        amountBuySell: '',
                        calculateBalance: ''
                    });
                    showNotification(res.message, res.code);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.props.selectItem) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    selectItem = (selectItem) =>{
        if(selectItem != this.props.selectItem) this.props.getListItem(selectItem);
    }

    renderItem = () =>{
        let {isUpdateInvest} = this.state;
        let {selectItem, dataItem2, dataItem3, loadingItem2, loadingItem3} = this.props;
        let listItem = selectItem == 2 ? dataItem2 : dataItem3;
        if(selectItem == 2 && loadingItem2 == true || selectItem == 3 && loadingItem3 == true){
            return <Loading small />
        }
        return(
            <div className="row mgt">
                {listItem.map((item, key) =>
                    <Tooltip {...PROP.TOOLTIP_HOME} key={key} title={
                        <div>
                            <Button
                                className='popover-item bg-success'
                                onClick={() => this.showModalItem(item, 1)}
                                loading={this.state.isPress}
                            >
                                Mua ngay
                            </Button>
                            {item.Amount > 0 &&
                                <Button
                                    className='popover-item bg-warning'
                                    onClick={() => this.showModalItem(item, 2)}
                                    loading={this.state.isPress}
                                >
                                    Bán ngay
                                </Button>
                            }
                            <Button
                                className='popover-item bg-primary'
                                onClick={() => this.showModalChart(item)}
                                loading={this.state.isPress}
                            >
                                Biểu đồ
                            </Button>
                        </div>
                    }>
                        <div
                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center"
                            style={isUpdateInvest ? {backgroundColor: item.BalanceColor == 1 ? 'rgba(0,166,90, 0.7)' : 'rgba(221,75,57, 0.7)'} : null}
                        >
                            <div className='flex-column'>
                                {item.UserId > 0 && (
                                    <Icon name='tag' className='icon-own-item' />
                                )}
                                <div className='item-center'>
                                    <img className='item-img mgb mgt' alt={item.ItemName} src={`${URI_PATH}${item.ItemImage}`}/>
                                </div>
                                <p>{item.ItemName}</p>
                                <p className={item.BalanceColor == 1 ? 'success' : 'danger'}>{item.BalanceChange} {selectItem == 2 ? 'đ[z]' : '$[z]'}</p>
                                {item.Amount > 0 &&
                                    <p className={item.PercentColor == 1 ? 'success' : 'danger'}>Đã mua: {item.Amount}</p>
                                }
                                <p className={item.PercentColor == 1 ? 'success' : 'danger'}>Cổ tức {item.PercentBonusNew}%/ngày</p>
                                {item.BankruptDateTime ? <p>Tái phát hành: {item.BankruptDateTime}</p> : null}
                                <div id={`notificationInvest${item.ChargemarketId}`}/>
                            </div>
                        </div>
                    </Tooltip>
                )}
            </div>
        )
    }

    componentDidMount(){
        this.props.getListItem(2);
        setInterval(() => {
            if ( new Date().getSeconds() === 2 ) this.props.getListItem(this.props.selectItem);
        }, 1000);
    }

    componentDidUpdate(prevProps){
        if(prevProps.dataItem2.length > 0 && prevProps.dataItem2[0]['UpdateDateTime'] != this.props.dataItem2[0]['UpdateDateTime']){
            this.setState({isUpdateInvest: true});
            this.timeoutUpdate = setTimeout(() => this.setState({isUpdateInvest: false}), 1000);
        }
    }

    componentWillUnmount(){
        clearInterval(this.intervalUpdate);
        clearTimeout(this.timeoutUpdate);
    }

    render(){
        let {itemInfo, showModalBuy, buySellRemain, showModalChart, timeData, balanceData, modalType} = this.state;
        let {selectItem} = this.props;
        let balanceName = selectItem == 2 ? 'đ[z]' : '$[z]';
        if(!selectItem)  return <Loading/>;
        let titleCss = modalType == 1 ? 'success' : 'warning';
        return(
            <div>
                <HeaderLottie source={money} />
                <div className='flex item-center'>
                    <p className='bold title-success'>
                        {this.props.countMission.BuyInvestCount > 0 ?
                            `Học đầu tư ${this.props.countMission.BuyInvestCount} mã chứng khoán để vượt qua thử thách`
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div>
                <ButtonGroup className='item-center' style={{display: 'flex', width: '100%'}}>
                    <Button className={this.buttonGroupClass(2)} onClick={() => this.selectItem(2)}>Chứng khoán đ[z]</Button>
                    <Button className={this.buttonGroupClass(3)} onClick={() => this.selectItem(3)}>Chứng khoán $[z]</Button>
                </ButtonGroup>
                {this.renderItem()}
                <Modal
                    {...PROP.MODAL}
                    visible={showModalBuy}
                    onCancel={() => this.setState({showModalBuy: false, isPress: false, amountBuySell: '', calculateBalance: ''})}
                >
                    {showModalBuy &&
                        <div>
                            <p className={`bold text-center text-huge ${titleCss}`}>
                                {modalType == 1 ? 'Mua' : 'Bán'}
                            </p>
                            <div className="row item-center flex-column">
                                <p className={`text-center text-large mgt ${titleCss}`}>
                                    {itemInfo.ItemName}
                                </p>
                                <img className='item-img mgt' alt={itemInfo.ItemName} src={`${URI_PATH}${itemInfo.ItemImage}`} />
                                <p className={`bold text-center text-huge mgt ${titleCss}`}>
                                    {formatDecimal(`${itemInfo.BalanceChange} đ[z]/đơn vị`)}
                                </p>
                                {itemInfo.Amount > 0 &&
                                    <p className={`text-center text-huge mgt ${titleCss}`}>
                                        Hiện có: {itemInfo.Amount} đơn vị
                                    </p>
                                }
                                <p className={`text-center text-large mgt ${titleCss}`}>
                                    Số lượng tối đa có thể {modalType == 1 ? 'mua' : 'bán'} ở phiên này: {buySellRemain} đơn vị
                                </p>
                            </div>
                            <div className="row item-center">
                                <div className='col-xs-6'>
                                    <p className={`text-center text-large mgt ${titleCss}`}>Giá mua vào</p>
                                    <input
                                        className={`form-control bg-${titleCss} text-center mgt`}
                                        value={formatDecimal(getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange)) + ` ${balanceName}`}
                                        disabled
                                    />
                                </div>
                                <div className='col-xs-6'>
                                    <p className={`text-center text-large mgt ${titleCss}`}>Giá bán ra</p>
                                    <input
                                        className={`form-control bg-${titleCss} text-center mgt`}
                                        value={formatDecimal(getCostInvest(itemInfo.BalanceChange, itemInfo.PercentChange, 2)) + ` ${balanceName}`}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row item-center">
                                <div className='col-xs-6'>
                                    <p className={`text-center text-large mgt ${titleCss}`}>Số lượng</p>
                                    <input
                                        className={`form-control border-${titleCss} text-center mgt`}
                                        value={`${this.state.amountBuySell}`}
                                        placeholder="Nhập số lượng"
                                        onChange={this.changeAmount}
                                        onKeyDown={e => e.key === 'Enter' && (modalType == 1 ? this.buy() : this.sell())}
                                    />
                                </div>
                                <div className='col-xs-6'>
                                    <p className={`text-center text-large mgt ${titleCss}`}>Thành tiền</p>
                                    <input
                                        className={`form-control border-${titleCss} text-center mgt`}
                                        value={this.state.calculateBalance ? `${this.state.calculateBalance} ${balanceName}` : ''}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='row mgt-2 item-center flex-column'>
                                {itemInfo.Amount > 0 &&
                                    <p className={`${titleCss} text-center text-large`}>
                                        Giá mua vào gần nhất: {formatDecimal(itemInfo.BalanceReal)} {balanceName}
                                    </p>
                                }
                                {this.state.calculateBalance &&
                                    <p className={`${titleCss} text-center text-large`}>
                                        Hệ thống sẽ {modalType == 1 ? 'trừ' : 'cộng'}: {this.state.calculateBalance} {balanceName}
                                    </p>
                                }
                                <Button
                                    className={`bg-${titleCss} mgt-2`}
                                    loading={this.state.isPress}
                                    onClick={modalType == 1 ? this.buy : this.sell}
                                >
                                    Xác nhận
                                </Button>
                            </div>
                            <div className='mgt' id='notificationModalInvest'></div>
                        </div>
                    }
                </Modal>
                <Modal
                    {...PROP.MODAL}
                    visible={showModalChart}
                    onCancel={() => this.setState({showModalChart: false})}
                    width={700}
                >
                    <div className='mgt-2'>
                        <Chart
                            options={Object.assign(this.optionChart(this.props.selectItem), timeData)}
                            series={balanceData}
                            type='area'
                            width='100%'
                            height='400px'
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { selectItem, dataItem2, loadingItem2, dataItem3, loadingItem3 } = state.getItemMarket;
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { selectItem, dataItem2, loadingItem2, dataItem3, loadingItem3, dataUser, countMission };
}

export default connect(mapStateToProps, {changePoint, getListItem, actionItem, updateCountMission, showModalEat})(ChargeInvest);