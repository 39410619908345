const INITAL_STATE = {
    listBuy: [],
    listSell: [],
    listTransaction: [],
    loadingBuy: true,
    loadingSell: true,
    loadingTransaction: true,
    pageBuy: 1,
    pageSell: 1,
    pageTransaction: 1,
    loadingFirstListBuy: true,
    loadingFirstListSell: true,
    loadingFirstListTransaction: true,
    endListBuy: false,
    endListSell: false,
    endListTransaction: false,
    selectItem: 0
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_GET_LIST_BLACK_MARKET':
            return { ...state, selectItem: action.selectItem};
        case 'SUCCESS_GET_LIST_BUY_BLACK_MARKET':
            return { ...state,
                listBuy: action.pageBuy == 1 ? action.listBuy : [ ...state.listBuy, ...action.listBuy],
                loadingBuy: false,
                loadingTransaction: true,
                pageBuy: action.pageBuy,
                endListBuy: action.listBuy.length > 0 ? false : true,
                selectItem: action.selectItem,
                loadingFirstListBuy: false
            }
        case 'SUCCESS_GET_LIST_SELL_BLACK_MARKET':
            return { ...state,
                listSell: action.pageSell == 1 ? action.listSell : [ ...state.listSell, ...action.listSell],
                loadingSell: false,
                pageSell: action.pageSell,
                endListSell: action.listSell.length > 0 ? false : true,
                selectItem: action.selectItem,
                loadingFirstListSell: false
            }
        case 'SUCCESS_GET_LIST_TRANSACTION_BLACK_MARKET':
            return { ...state,
                listTransaction: action.pageTransaction == 1 ? action.listTransaction : [ ...state.listTransaction, ...action.listTransaction],
                loadingTransaction: false,
                pageTransaction: action.pageTransaction,
                endListTransaction: action.listTransaction.length > 0 ? false : true,
                selectItem: action.selectItem,
                loadingFirstListTransaction: false
            }
        case 'DELETE_TRANSACTION_BLACK_MARKET':
            return { ...state,
                listTransaction: state.listTransaction.filter(listTransaction => listTransaction.BlackmarketId !== action.blackmarketId)
            }
        case 'FALSE_GET_LIST_BLACK_MARKET':
            return { ...state };
        default:
            return state;
    }
};