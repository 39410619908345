import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import WallpaperRoot from './components/WallpaperRoot';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import BottomMp3 from './components/audio/BottomMp3';
import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';
//config axios header token
import Cookies from 'js-cookie';
import{KEY} from './utils/constants';
import RouterContainer from './components/RouterContainer';
const axios = require('axios');
axios.defaults.headers.common['Auth'] = Cookies.get(KEY.TOKEN);
//

export default function App(){
    return(
        <>
            {/* <Online polling={{interval: 15000, timeout: 12000}}> */}
                <Provider store={store}>
                    <WallpaperRoot>
                        <RouterContainer />
                    </WallpaperRoot>
                    <SmartBanner title='Zcity' />
                    <BottomMp3 />
                </Provider>
            {/* </Online> */}
            {/* <Offline polling={{interval: 15000, timeout: 12000}}>
                <Provider store={store}>
                    <WallpaperRoot>
                        <div className='item-center flex flex-1 flex-column'>
                            <HeaderLottie source={error} />
                            <p className='text-large mgt'>Không có kết nối mạng. Kiểm tra và thử lại</p>
                        </div>
                    </WallpaperRoot>
                </Provider>
            </Offline> */}
        </>
    )
}