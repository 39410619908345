import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getCountMissionApi = () =>{
    let url = `${URI_PATH}app/menu/action/getCountMission`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getCrestApi = () =>{
    let url = `${URI_PATH}app/menu/action/getCrest`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getTimeGuide = () =>{
    let url = `${URI_PATH}app/menu/action/getTimeGuide`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDiscordLinkApi = () =>{
    let url = `${URI_PATH}app/menu/action/getDiscordLink`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}