import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getDataCreateApi = () =>{
    let url = `${URI_PATH}app/course/create/getDataCreate`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListDistrictApi = provinceId =>{
    let url = `${URI_PATH}app/course/create/getListDistrict`;
    let body = {
        ProvinceId: provinceId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateCourseApi = (CourseName, TeacherName, BeginDate, EndDate, StopRegisterDate, CourseNo, CourseDesc, PayType, FeeLess26, FeeGreate26, CourseTypeId, StudentLimit, RequireVIP, ProvinceId, DistrictId, Address, MapLink, CourseStatusId, CourseId) =>{
    let url = `${URI_PATH}app/course/create/updateCourse`;
    let body = {CourseName, TeacherName, BeginDate, EndDate, StopRegisterDate, CourseNo, CourseDesc, PayType, FeeLess26, FeeGreate26, CourseTypeId, StudentLimit, RequireVIP, ProvinceId, DistrictId, Address, MapLink, CourseStatusId, CourseId};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListCourseEditApi = (page) =>{
    let url = `${URI_PATH}app/course/create/getListCourseEdit`;
    let body = {
        PageId: page
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const editActionApi = courseId =>{
    let url = `${URI_PATH}app/course/create/editAction`;
    let body = {
        CourseId: courseId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}