import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { updateCountMission } from '../../actions/menu';
import { MESSAGE, PROP, TICKET } from '../../utils/constants';
import { getDecimalCount } from '../../utils/common';
import { showNotification, showNotificationAsset, showNotificationBonus, showNotificationProperty } from '../../utils/notification';
import { Modal, Button } from 'antd';
import { saveAnswerApi } from '../../api/gift/ticket';
import { formatDecimal } from '../../utils/common';
import { showModalEat } from '../../actions/home/modalEat';
import { buyTicketApi } from '../../api/question/ticket';

class ModalBuyTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketCost: 0,
            isAnswer: false,
            showModal: false,
            buyTicketMax: 0,
            ticketToday: 0,
            inputTicket: '',
            calculateBalance: '',
            isPress: false
        }
    }

    saveAnswer = (answer, keySelect, questionTempId) => {
        if (this.state.isAnswer === false) {
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            let idNotification = '#notificationQuestionCheck';
            saveAnswerApi(questionTempId, answer).then(res => {
                if (res.assetInfo) showNotificationAsset(res.assetInfo);
                if (res.code == 1 || res.code == 0) {
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    this.setState({
                        resultAnswer: res.code,
                    });
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.correctAnswer = '';
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    hideModal = () => {
        this.setState({
            isPress: false,
        });
        this.props.onClose();
    }

    buyMax = () => {
        const {
            ticketCost = 0,
            ticketToday,
            buyTicketMax,
            buyTicketMin
        } = this.props.ticketData;
        let userBalance = this.props.dataUser.Balance;
        let maxTicket = Math.floor(parseFloat(userBalance / ticketCost) * 1000) / 1000;
        if (ticketToday < buyTicketMin) maxTicket = 0;
        else if (maxTicket <= buyTicketMin) maxTicket = buyTicketMin;
        else if ((buyTicketMax <= ticketToday) && (buyTicketMax <= maxTicket)) maxTicket = buyTicketMax;
        else if (ticketToday <= maxTicket) maxTicket = ticketToday;
        this.setState({
            inputTicket: maxTicket,
            calculateBalance: formatDecimal(Math.floor(maxTicket * ticketCost))
        });
    }

    changeInputTicket = e => {
        const {
            ticketCost = 0,
        } = this.props.ticketData;
        let value = e.target.value?.replace(/,|\./g, '');
        if (value >= 0) {
            this.setState({
                inputTicket: value,
                calculateBalance: formatDecimal(Math.floor(value * parseInt(ticketCost)))
            });
        }
        else this.setState({
            calculateBalance: ''
        });
    }

    buy = () => {
        let { isPress, inputTicket } = this.state;
        let idNotification = '#notificationBuyTicket';
        if (!isPress) {
            if (inputTicket == '' || inputTicket <= 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', -1, idNotification);
            if (getDecimalCount(inputTicket) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1, idNotification);
            this.setState({ isPress: true });
            buyTicketApi(inputTicket).then(res => {
                if (res.code == 1) {
                    let bonus = res.bonus;
                    showNotificationBonus(bonus.Ticket, 1, 1);
                    showNotificationBonus(bonus.Balance, -1, 2);
                    this.props.changePoint([bonus.Ticket, -bonus.Balance]);
                    this.props.updateCountMission('GiftTicketCount');
                    showNotificationProperty(res.property);
                    showNotification(res.message, res.code);
                    this.hideModal();
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    renderContent = () => {
        const {
            ticketCost = 0,
            ticketToday,
            buyTicketMax,
            buyTicketMin
        } = this.props.ticketData;
        const {
            inputTicket,
            calculateBalance,
            isPress
        } = this.state;
        return (
            <div className='item-center'>
                <div className='item-center mgt-2'>
                    <p className='text-ticket-cost'>1 Gold[z] = {formatDecimal(ticketCost)} $[z]</p>
                    <p className='mgt-2 text-large'>Mở bán hôm nay còn {ticketToday} Gold[z]</p>
                </div>
                <div className='row item-center'>
                    <div className='mgt-3 col-sm-6 col-xs-12'>
                        <p className='text-center mgt'>Số Gold[z]</p>
                        <input
                            className='form-control border-success text-center'
                            placeholder='Nhập số Gold[z]'
                            value={inputTicket}
                            onChange={this.changeInputTicket}
                            type='number'
                        />
                        <p className='text-center mgt'>Thành tiền ($[z])</p>
                        <input
                            className='form-control border-success text-center'
                            disabled={true}
                            value={calculateBalance}
                        />
                    </div>
                </div>
                <div className='mgt' id='notificationBuyTicket'></div>
                <div className='flex-row item-center'>
                    <Button
                        className='mgt-2 bg-warning mg-3'
                        onClick={this.buyMax}
                    >
                        Mua tối đa
                    </Button>
                    <Button
                        className='mgt-2 bg-success mg-3'
                        onClick={this.buy}
                        loading={isPress}
                    >
                        Xác nhận
                    </Button>
                </div>
                <p className='mgt-2'><i>Số Gold[z] được mua tối thiểu là {buyTicketMin} Gold[z] / lần</i></p>
                <p className='mgt'><i>Số Gold[z] được mua tối đa là {buyTicketMax} Gold[z] / lần</i></p>
            </div>
        )
    }

    render() {
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.props.showModal}
                onCancel={this.hideModal}
                afterClose={this.hideModal}
                destroyOnClose={true}
                width='750px'
                centered
            >
                {this.renderContent()}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, updateCountMission, showModalEat })(ModalBuyTicket);