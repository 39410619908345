import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListLessonDoingOrPassApi = (Type, StudyStudentId, UserId) =>{
    let url = `${URI_PATH}app/study/Lesson/getListLessonDoingOrPass`;
    let body = {
        Type,
        StudyStudentId,
        UserId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const publishAnswerLessonApi = (StudyStudentId, Content) =>{
    let url = `${URI_PATH}app/study/Lesson/publishAnswerLesson`;
    let body = {
        StudyStudentId,
        Content
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const publishDocumentLessonApi = (StudyStudentId, Content, Link, Image, IsYoutute, IsTiktok) =>{
    let url = `${URI_PATH}app/study/Lesson/publishDocumentLesson`;
    let body = {
        StudyStudentId,
        Content,
        Link,
        Image,
        Type: IsYoutute ? 2 : (IsTiktok ? 3 : 1)
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const editDocumentLessonApi = (StudyStudentId, Content, Link, Image, IsYoutute, StudyDocumentId, IsTiktok) =>{
    let url = `${URI_PATH}app/study/Lesson/editDocumentLesson`;
    let body = {
        StudyStudentId,
        Content,
        Link,
        Image,
        Type: IsYoutute ? 2 : (IsTiktok ? 3 : 1),
        StudyDocumentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteDocumentLessonApi = (StudyStudentId, StudyDocumentId) =>{
    let url = `${URI_PATH}app/study/Lesson/deleteDocumentLesson`;
    let body = {
        StudyStudentId,
        StudyDocumentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const submitLessonApi = (StudyStudentId) =>{
    let url = `${URI_PATH}app/study/Lesson/submitLesson`;
    let body = {
        StudyStudentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListLessonTeacherApi = (PageId) =>{
    let url = `${URI_PATH}app/study/Lesson/getListLessonTeacher`;
    let body = {
        PageId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListLessonMasterApi = (PageId, Type = 1, StudyStudentId) =>{
    let url = `${URI_PATH}app/study/Lesson/getListLessonMaster`;
    let body = {
        PageId,
        Type,
        StudyStudentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const markLessonApi = (StudyStudentId, Point) =>{
    let url = `${URI_PATH}app/study/Lesson/markLesson`;
    let body = {
        StudyStudentId,
        Point
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDataExtendLessonApi = (StudySubjectId) =>{
    let url = `${URI_PATH}app/study/Lesson/getDataExtendLesson`;
    let body = {
        StudySubjectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const extendLessonApi = (StudyStudentId, StudySubjectId) =>{
    let url = `${URI_PATH}app/study/Lesson/extendLesson`;
    let body = {
        StudyStudentId,
        StudySubjectId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getLessonByStudentApi = (StudyClassId) =>{
    let url = `${URI_PATH}app/study/Lesson/getLessonByStudent`;
    let body = {
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListReferApi = (StudyAdminId, TextSearch) =>{
    let url = `${URI_PATH}app/study/Lesson/getListRefer`;
    let body = {
        StudyAdminId,
        TextSearch
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changePrivateApi = (StudyStudentId) =>{
    let url = `${URI_PATH}app/study/Lesson/changePrivate`;
    let body = {
        StudyStudentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getCountLessonApi = () =>{
    let url = `${URI_PATH}app/study/Lesson/getCountLesson`;
    let body = {
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getDayExpireKTNApi = () =>{
    let url = `${URI_PATH}app/study/Lesson/getDayExpireKTN`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}