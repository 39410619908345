import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ changePoint } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getListCourseApi, getDataCourseApi, getListScheduleApi, getInfoUserRegisterApi, checkPromotionCodeApi, registerCourseApi} from '../../api/course/list';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { MESSAGE, PROP, TICKET } from '../../utils/constants';
import { getAvatarFb, priceFormat, getDecimalCount } from '../../utils/common';
import { Button, Modal } from 'antd';
import {Collapse} from 'react-collapse';
import SearchUser from '../../components/SearchUser';
import InfiniteScroll from 'react-infinite-scroller';
import TooltipUser from '../../components/TooltipUser';

class CourseList extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            endListCourse: false,
            listCourse: [],
            listCourseType: [],
            listProvince: [],
            listSchedule: [],
            ticketCost: '',
            openCourseId: '',
            showModal: false,
            courseRegInfo: {},
            selectPhone: [],
            dataUserReg: false,
            inputTicketReg: '',
            inputBalanceReg: '',
            inputPromotionCode: '',
            yearOld: '',
            yearOldReg: '',
            dataPromotionCode:{},
        }
        this.page = 1;
        this.searchText = '';
        this.courseTypeId = ''; 
        this.userId = '';
        this.provinceId = '';
    }

    getListCourse = () =>{
        getListCourseApi(this.searchText, this.courseTypeId, this.userId, this.provinceId, this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listCourse: this.page == 1 ? res.listCourse : [...this.state.listCourse, ...res.listCourse],
                    endListCourse: res.listCourse.length == 0,
                    isLoading: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    getSelectSearch = () =>{
        getDataCourseApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    listCourseType: res.listCourseType,
                    listProvince: res.listProvince,
                    ticketCost: res.ticketCost,
                    yearOld: res.yearOld,
                    yearOldReg: res.yearOld
                })
            }
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMoreCourse = () =>{
        this.page += 1;
        this.getListCourse();
    }

    changeCourseType = e =>{
        this.courseTypeId = e.target.value;
        this.page = 1;
        this.getListCourse();
    }

    changeProvince = e =>{
        this.provinceId = e.target.value;
        this.page = 1;
        this.getListCourse();
    }

    changeSearchCourse = e =>{
        this.searchText = e.target.value;
        this.page = 1;
        this.getListCourse();
    }

    getListSchedule = courseId =>{
        getListScheduleApi(courseId).then(res =>{
            if(res.code == 1){
                this.setState({
                    listSchedule: res.listSchedule
                });
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    showCourseInfo = courseId =>{
        let openCourseId = this.state.openCourseId;
        if(!openCourseId || courseId != openCourseId){
            // this.getListSchedule(courseId);
            this.setState({openCourseId: courseId});
        }
    }

    getInputCost = (courseInfo, yearOld, discountCost = 0) =>{
        let dataUser = this.props.dataUser;
        let {ticketCost} = this.state;
        let courseBalance = yearOld <= 26 ? courseInfo.BalanceLess26 : courseInfo.BalanceGreate26;
        courseBalance -= discountCost;
        if(courseBalance <=  dataUser.TicketCount * ticketCost){
            let ticketPay = courseBalance / ticketCost;
            return{
                ticket: Math.round(ticketPay * 1000) / 1000,
                balance: 0
            }
        }
        else{
            let retVnd = Math.round(courseBalance - (dataUser.TicketCount * ticketCost));
            return{
                ticket: dataUser.TicketCount,
                balance: retVnd
            }
        }
    }

    showModalRegister = courseInfo =>{
        let dataCost = this.getInputCost(courseInfo);
        this.setState({
            showModal: true,
            courseRegInfo: courseInfo,
            selectPhone: [],
            dataUserReg: false,
            inputPromotionCode: '',
            inputTicketReg: dataCost.ticket,
            inputBalanceReg: dataCost.balance
        })
    }

    deleteSearch = e =>{ //xoá sđt
        if(e.key == 'Backspace' && this.state.selectPhone.length > 0){
            this.refs.searchUser.getInstance().clear();
            let {courseRegInfo, yearOld} = this.state;
            let dataCost = this.getInputCost(courseRegInfo, yearOld);
            this.setState({
                selectPhone: [],
                dataUserReg: false,
                yearOldReg: yearOld,
                inputTicketReg: dataCost.ticket,
                inputBalanceReg: dataCost.balance
            });
        }
    }

    selectPhoneRegister = selectPhone =>{ // chọn sđt
        this.setState({selectPhone: selectPhone});
        if(selectPhone[0]){
            let userId = selectPhone[0].userId;
            getInfoUserRegisterApi(userId).then(res =>{
                if(res.code == 1){
                    let dataCost = this.getInputCost(this.state.courseRegInfo, res.yearOld);
                    this.setState({
                        dataUserReg: res.userInfo,
                        yearOldReg: res.yearOld,
                        inputTicketReg: dataCost.ticket,
                        inputBalanceReg: dataCost.balance
                    });
                    if(this.state.inputPromotionCode.length == 13){ // check mã giảm giá
                        let {inputPromotionCode, courseRegInfo, yearOldReg} = this.state;
                        checkPromotionCodeApi(inputPromotionCode, courseRegInfo.CourseId).then(res =>{
                            if(res.code == 1){
                                let discountCost = parseInt(res.data.DiscountCost);
                                let dataCost = this.getInputCost(courseRegInfo, yearOldReg, discountCost);
                                this.setState({
                                    inputTicketReg: dataCost.ticket,
                                    inputBalanceReg: dataCost.balance
                                })
                            }
                        });
                    }
                }
                else showNotification(res.message, res.code);
            }).catch(e => showNotification(MESSAGE.ERROR, 0));
        }
    }

    changeTicketReg = e =>{ //thay đổi input ztiket
        let inputTicket = e.target.value;
        let {ticketCost, yearOldReg, courseRegInfo} = this.state;
        let courseTicket = yearOldReg < 26 ? courseRegInfo.TicketLess26 : courseRegInfo.TicketGreate26;
        let courseBalance = yearOldReg < 26 ? courseRegInfo.BalanceLess26 : courseRegInfo.BalanceGreate26;
        if(getDecimalCount(inputTicket) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1);
		if(courseTicket < inputTicket) return showNotification('Số Gold[z] bạn chọn phải nhỏ hơn số Gold[z] của khoá học', 0);
        let retVnd = Math.round(courseBalance - (inputTicket * ticketCost));
        this.setState({
            inputTicketReg: inputTicket,
            inputBalanceReg: retVnd
        });
    }

    changePromotionCode = e =>{  // check mã giảm giá
        let inputPromotionCode = e.target.value;
        let courseId = this.state.courseRegInfo.CourseId;
        this.setState({inputPromotionCode});
        let {courseRegInfo, yearOldReg} = this.state;
        if(inputPromotionCode.length == 13){
            checkPromotionCodeApi(inputPromotionCode, courseId).then(res =>{
                if(res.code == 1){
                    let discountCost = parseInt(res.data.DiscountCost);
                    let dataCost = this.getInputCost(courseRegInfo, yearOldReg, discountCost);
                    this.setState({
                        inputTicketReg: dataCost.ticket,
                        inputBalanceReg: dataCost.balance
                    })
                }
            });
        }
        else {
            let dataCost = this.getInputCost(courseRegInfo, yearOldReg);
            this.setState({
                inputTicketReg: dataCost.ticket,
                inputBalanceReg: dataCost.balance
            });
        }
    }

    registerCourse = () =>{
        if(!this.state.isPress){
            let {selectPhone, courseRegInfo, inputTicketReg, inputBalanceReg, inputPromotionCode} = this.state;
            let userId = selectPhone[0].userId;
            let idNotification = '#notificationRegisterCourse';
            this.setState({isPress: true});
            registerCourseApi(userId, courseRegInfo.CourseId, inputTicketReg, inputBalanceReg, inputPromotionCode).then(res =>{
                if(res.code == 1){
                    let bonus = res.bonus;
                    showNotificationBonus(bonus.Ticket, -1, 1);
                    showNotificationBonus(bonus.Balance, -1, 2);
                    this.props.changePoint([-bonus.Ticket, -bonus.Balance]);
                    this.setState({
                        selectPhone: [],
                        inputPromotionCode: '',
                        dataUserReg: false,
                        isPress: false
                    })
                    this.refs.searchUser.getInstance().clear();
                }
                else this.setState({isPress: false});
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    componentDidMount(){
        this.getListCourse();
        this.getSelectSearch();
    }

    render(){
        let {
            isLoading,
            endListCourse,
            listCourse,
            listCourseType,
            listProvince,
            openCourseId,
            showModal,
            courseRegInfo,
            dataUserReg,
            inputTicketReg,
            inputBalanceReg,
            inputPromotionCode,
            yearOld,
            isPress
        } = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                <div className='box-primary row'>
                    <div className='col-md-4 col-sm-4 col-xs-12'>
                        <input
                            className='form-control'
                            placeholder='Gõ tên khoá học hoặc tên giảng viên'
                            onBlur={this.changeSearchCourse}
                        />
                    </div>
                    <div className='col-md-4 col-sm-4 col-xs-6'>
                        <select className='form-control' onChange={this.changeCourseType}>
                            <option value=''>Loại khóa học</option>
                            {listCourseType.map((item, key) =>
                                <option key={key} value={item.CourseTypeId}>{item.CourseTypeName}</option>
                            )}
                        </select>
                    </div>
                    <div className='col-md-4 col-sm-4 col-xs-6'>
                        <select className='form-control' onChange={this.changeProvince}>
                            <option value=''>Tỉnh/thành phố</option>
                            {listProvince.map((item, key) =>
                                <option key={key} value={item.ProvinceId}>{item.ProvinceName}</option>
                            )}
                        </select>
                    </div>
                </div>
                {listCourse.length > 0 ?
                    <InfiniteScroll
                        loadMore={this.loadMoreCourse}
                        hasMore={!endListCourse}
                        loader={<Loading key={0} small/>}
                        threshold={50}
                        className='mgt'
                    >
                        {listCourse.map((item, key) =>
                            <div
                                className='box-primary'
                                key={key}
                                onClick={() => this.showCourseInfo(item.CourseId)}
                            >
                                <p className='text-large bold'>{item.CourseName} - Tổ chức lần thứ {item.CourseNo}</p>
                                <div className='flex-row mgt'>
                                    <TooltipUser userInfo={item} avatarClass='avatar-guest'/>
                                    <p className='mg'>{item.FullName}</p>
                                </div>
                                <p className='mgt'>Thời gian học: {item.BeginDate} đến {item.EndDate}</p>
                                <p>Thời gian ngừng nhận ghi danh: {item.StopRegisterDate}</p>
                                <p>Yêu cầu trình độ từ: VIP {item.RequireVIP}</p>
                                <p>
                                    Học phí: {priceFormat(yearOld <= 26 ? item.BalanceLess26 : item.BalanceGreate26)} $[z] ~ {yearOld <= 26 ? item.TicketLess26 : item.TicketGreate26} Gold[z]
                                </p>
                                <p>
                                    Địa điểm: {item.Address} - {item.DistrictName} - {item.ProvinceName}
                                    {item.MapLink && <a href={item.MapLink} target="_blank" rel='noopener noreferrer'><span className='success'> (Xem bản đồ)</span></a>}
                                </p>
                                {openCourseId != item.CourseId &&
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/arrow-down.png')} 
                                            className='arrow-course-collapse animated zoomIn fastest'
                                        />
                                    </div>
                                }
                                <Collapse isOpened={openCourseId == item.CourseId}>
                                    <div className='box-primary'>
                                        <p className='text-large text-center'>Giới thiệu về môn học</p>
                                        <div className='text-left' dangerouslySetInnerHTML={{__html: item.CourseDesc}} />
                                    </div>
                                    <div className='flex-column item-center'>
                                        <Button
                                            className='mgt bg-success'
                                            onClick={() => this.showModalRegister(item)}
                                        >
                                            Ghi danh
                                        </Button>
                                        <img
                                            src={require('../../assets/image/arrow-up.png')} 
                                            className='arrow-course-collapse animated zoomIn fastest'
                                            onClick={() => this.setState({openCourseId: false})}
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        )}
                    </InfiniteScroll>
                :
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large mgt'>Không tìm thấy khoá học</p>
                    </div>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({showModal: false})}
                    width='72vh'
                >
                    <div>
                        <p className='text-center text-large'>Ghi danh: {courseRegInfo.CourseName}</p>
                        <div className='flex-column item-center mgt'>
                            <p className='bold title-success'>
                                {priceFormat(yearOld <= 26 ? courseRegInfo.BalanceLess26 : courseRegInfo.BalanceGreate26)} $[z] ~ {yearOld <= 26 ? courseRegInfo.TicketLess26 : courseRegInfo.TicketGreate26} Gold[z]
                            </p>
                        </div>
                        <div className='mgt-2'>
                            <p>Bạn sẽ thanh toán bằng :</p>
                            <div className='flex-row'>
                                <div className='flex-3'>
                                    <input
                                        className='form-control'
                                        placeholder='Số Gold[z]'
                                        value={inputTicketReg}
                                        onChange={this.changeTicketReg}
                                        type='number'
                                    />
                                </div>
                                <div className='flex-1'>
                                    <input className='form-control text-center' value='Gold[z]' disabled/>
                                </div>
                            </div>
                            <div className='flex-row'>
                                <div className='flex-3'>
                                    <input
                                        className='form-control'
                                        value={priceFormat(inputBalanceReg)}
                                        disabled
                                    />
                                </div>
                                <div className='flex-1'>
                                    <input className='form-control text-center' value='$[z]' disabled/>
                                </div>
                            </div>
                            <p className='mgt'>Mã ưu đãi (nếu có) :</p>
                            <input 
                                className='form-control' 
                                placeholder='Nhập mã ưu đãi'
                                value={inputPromotionCode}
                                onChange={this.changePromotionCode}
                            />
                            <p className='mgt'>Họ tên / Số điện thoại</p>
                            <SearchUser
                                ref='searchUser'
                                onChange={this.selectPhoneRegister}
                                onKeyDown={this.deleteSearch}
                                placeholder='Nhập họ tên / Số điện thoại'
                                style={{textAlign: 'left'}}
                            />
                            {dataUserReg &&
                                <div>
                                    <p>Ngày sinh:</p>
                                    <input className='form-control' value={dataUserReg.BirthDay} disabled />
                                    <p>4 số cuối giấy tờ tuỳ thân:</p>
                                    <input className='form-control' value={dataUserReg.IDCardNumber} disabled />
                                    <div className='item-center'>
                                        <Button
                                            className='mgt-2 bg-success'
                                            onClick={this.registerCourse}
                                            loading={isPress}
                                        >
                                            Xác nhận ghi danh
                                        </Button>
                                    </div>
                                </div>
                            }
                            <div className='mgt' id='notificationRegisterCourse' />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(CourseList);