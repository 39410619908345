import React, { Component } from 'react';
import Loading from '../../components/Loading';
import {getListRankApi} from '../../api/authentication';
import ListRank from '../../components/listRank/ListRank';
import HeaderLottie from '../../components/HeaderLottie';
import cup from '../../assets/lottie/cup.json';

export default class Rank extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            rankAchievement: '',
            birthAchievement: ''
        }
    }

    getData = (rankAchievement = '', birthAchievement = '') =>{
        if(this.state.isLoading === false){
            this.setState({isLoading: true});
            getListRankApi(rankAchievement, birthAchievement).then((res) => {
                if(res.code == 1){
                    this.setState({
                        data: res.data,
                        isLoading: false,
                    })
                }
            }).catch((e) => {
                this.setState({
                    data: [],
                    isLoading: true
                });
            });
        }
    }

    componentDidMount(){
        this.getData(1);
    }

    render(){
        return(
            <div>
                <HeaderLottie source={cup} size='25vh' />
                {/* <div className="row">
                    <div className='col-xs-6'>
                        <select
                            className='form-control text-center'
                            onChange={e => this.getData(e.target.value)}
                        >
                            <option value="">Xếp hạng chung</option>
                            <option value="1">Xếp hạng thành tựu</option>
                            <option value="2">Xếp hạng thương hiệu</option>
                            <option value="3">Xếp hạng thành quả</option>
                            <option value="4">Xếp hạng đấu trường</option>
                        </select>
                    </div>
                    <div className='col-xs-6'>
                        <input className='form-control text-center' placeholder='🔎 Năm sinh' onBlur={e => this.getData('', e.target.value)} />
                    </div>
                </div> */}
                {this.state.isLoading === true ? <Loading small /> : <ListRank dataRank={this.state.data} />}
            </div>
        )
    }
}