const INITAL_STATE = {
    isPresent: false,
    countPresent: false,
    userPresentData: {},
    presentData: {}
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_USER_PRESENT':
            return { ...state, isPresent: action.isPresent, userPresentData: action.userPresentData, presentData: action.presentData };
        case 'UPDATE_PRESENT':
            return { ...state, isPresent: 1, userPresentData: action.userPresentData }
        case 'FALSE_GET_USER_PRESENT':
            return { ...state };
        default:
            return state;
    }
};