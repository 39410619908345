import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { bidApi } from '../../api/brand/top';
import { Button, Modal } from 'antd';
import { getAvatarFb, getDecimalCount, formatDecimal, convertNonUnicode } from '../../utils/common';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { MESSAGE, PROP, URI_PATH, TICKET, KEY } from '../../utils/constants';
import hammer from '../../assets/lottie/hammer.json';
import Lottie from 'react-lottie';
import TooltipUser from '../../components/brand/TooltipUser';
import TooltipUserDefault from '../../components/TooltipUser';
import { showModalEat } from '../../actions/home/modalEat';
import { getDataUserZvipApi, getListUserAffApi, getUserBalanceEarnAffApi, removeRequestApi, requestUpgradeAffApi } from '../../api/aff';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment';

const STEP_TYPE = {
    SHOW_MODAL: 1,
    BUY_ZCRAZY: 2,
    BUY_AFF: 3
}

class ButtonAff extends Component {
    constructor(props) {
        super(props);
        this.refInput = React.createRef();
        this.state = {
            showModal: false,
            stepType: STEP_TYPE.SHOW_MODAL,
            listUserSearch: [],
            selectUser: {},
            isRequestedZcrazy: false,
            isRequestedZvip: false,
            cost: {},
            isFocus: false,
            isInviteForever: false
        }
    }

    getDataUserZvip = () => {
        getDataUserZvipApi(this.userInvite).then(res => {
            if (res.code == 1) {
                this.setState({
                    isRequestedZcrazy: res.isRequestedZcrazy == '1',
                    isRequestedZvip: res.isRequestedZvip == '1',
                    cost: res.cost,
                    selectUser: res.userInvite || {},
                    isInviteForever: Boolean(res.userInvite)
                })
            }
        })
    }

    showModal = () => {
        this.setState({
            showModal: true,
            stepType: STEP_TYPE.SHOW_MODAL
        })
    }

    searchUserAff = (text) => {
        getListUserAffApi(text).then(res => {
            if (Array.isArray(res)) {
                this.setState({ listUserSearch: res })
            }
            else this.setState({ listUserSearch: [] })
        })
    }

    handleSelected = (selected) => {
        this.setState({ selectUser: selected[0] })
        this.refInput.current.getInstance().clear();
    }

    requestUpgrade = (typeUpgrage) => {
        const { stepType, selectUser, isPress } = this.state;
        if (!isPress) {
            this.setState({ isPress: true })
            requestUpgradeAffApi(typeUpgrage, selectUser?.UserId).then(res => {
                this.setState({ isPress: false })
                if (res.code === 1) {
                    if (typeUpgrage === 2) {
                        this.setState({
                            isRequestedZvip: true,
                            stepType: STEP_TYPE.BUY_AFF,
                            selectUser,
                            isInviteForever: true
                        })
                    }
                    else this.setState({
                        isRequestedZcrazy: true,
                        stepType: STEP_TYPE.BUY_ZCRAZY,
                        selectUser,
                        isInviteForever: true
                    })
                }
                else showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    removeRequest = (typeUpgrage) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true })
            removeRequestApi(typeUpgrage).then(res => {
                this.setState({ isPress: false })
                if (res.code === 1) {
                    this.setState({
                        isRequestedZvip: false,
                        isRequestedZcrazy: false,
                        stepType: STEP_TYPE.SHOW_MODAL,
                    })
                    this.getDataUserZvip();
                }
                else showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    renderModal = () => {
        const { stepType, selectUser, isRequestedZcrazy, isRequestedZvip, cost, isInviteForever, isPress } = this.state;
        const { dataUser } = this.props;
        const isSelectUser = Boolean(selectUser.id)
        const isZcrazy = dataUser.IsZcrazy == 2;
        let balanceAff = isSelectUser ? +cost.BaseBalanceAff - +cost.BonusAff : +cost.BaseBalanceAff;
        let balanceAffView = +cost.BaseBalanceAff;
        if (isZcrazy) {
            balanceAff = +cost.BaseBalanceZcrazyToAff;
            balanceAffView = +cost.BaseBalanceZcrazyToAff;
        }
        const balanceZcrazy = isSelectUser ? +cost.BaseBalanceZcrazy - +cost.Bonus : +cost.BaseBalanceZcrazy;
        const percentBonus = 45 + (+dataUser.UserVip)
        switch (stepType) {
            case STEP_TYPE.SHOW_MODAL:
                return (
                    <>
                        <div className='box-support mgt-2'>
                            <p className='text-center bold'>NÂNG CẤP VIP ĐỂ ỦNG HỘ ĐỘI NGŨ PHÁT TRIỂN</p>
                            <p className='mgb-0 text-center bold'>NÂNG CẤP AFFILIATE ĐỂ KIẾM TIỀN ONLINE CÙNG ZCITY</p>
                        </div>
                        {/* <video width="100%" controls style={{ borderRadius: '1vh' }} autoPlay>
                            <source src={`${URI_PATH}assets/zcity-aff.mp4#t=0.001`} type="video/mp4" />
                        </video> */}
                        {!(isRequestedZcrazy || isRequestedZvip) &&
                            <div className='mgt-2 row item-center'>
                                <div className='col-md-4 col-sm-12 col-xs-12'>
                                    <p className='mgb-0 text-large'>Nhập mã giảm giá:</p>
                                </div>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    {selectUser.id ?
                                        <>
                                            <div className='select-user-aff-container relative'>
                                                <TooltipUser userInfo={selectUser} avatarClass='avatar-select-user-aff' />
                                                <p className='mgb-0 mgl-2'>{selectUser.name}</p>
                                                {!isInviteForever &&
                                                    <img
                                                        src={require('../../assets/image/delete.png')}
                                                        className='delete-select-user-aff'
                                                        onClick={() => this.setState({ selectUser: {} })}
                                                    />
                                                }
                                            </div>
                                        </>
                                        :
                                        <AsyncTypeahead
                                            {...PROP.TYPE_AHEAD}
                                            minLength={9}
                                            ref={this.refInput}
                                            onSearch={this.searchUserAff}
                                            options={this.state.listUserSearch}
                                            renderMenuItemChildren={option =>
                                                <div className='flex-row item-center'>
                                                    <div className='flex-row search-user'>
                                                        <img alt={option.name} className='avatar-guest-small' src={getAvatarFb(option.facebookId)} />
                                                        <p className='search-user-text'>{option.name}</p>
                                                    </div>
                                                </div>
                                            }
                                            emptyLabel={
                                                <div className='flex item-center'>
                                                    <div className='flex-row search-user'>
                                                        <p className='search-user-text'>Mã giảm giá không đúng</p>
                                                    </div>
                                                </div>
                                            }
                                            onChange={this.handleSelected}
                                            placeholder='Nhập mã giảm giá'
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {isSelectUser && <p className='mgt-2 text-center'>Bạn được giảm <b>51%</b> từ sự giới thiệu của {selectUser.FullName}</p>}
                        <div className='box-modal-aff mgt-2 relative'>
                            <img src={require('../../assets/image/deal.png')} className='aff-deal-png'/>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge'>Thành viên Z-VIP</p>
                                <img
                                    src={require('../../assets/image/checked.png')}
                                    className='checked-btn-aff'
                                />
                            </div>
                            <div className='mgt-2'>
                                <p>• Mở khoá 100% tính năng <b><u>trọn đời Zcity trị giá 3.750.000 đ.</u></b></p>
                                <hr width='75%' />
                                <p className='text-center bold'>Khuyến Mại: Tri Ân Thành Viên Đã Ủng Hộ Đội Ngũ Phát Triển Zcity</p>
                                <p className='text-center'>Nâng cấp Z-VIP trong ngày hôm nay {moment().format('DD/M/YYYY')}</p>
                                <p className='text-center'><u>Bạn được tặng thêm</u>: Khoá học “Life Planning - Xây dựng kế hoạch sự nghiệp dài hạn” do <b>Nhà sáng lập Zcity - thầy Nguyễn Minh Ngọc</b> hướng dẫn và “60 phút trực tiếp tư vấn” trị giá <del>1.490.000 đ.</del></p>
                            </div>
                            {(dataUser.IsZcrazy != '2') && (
                                <div className='mgt-2 row'>
                                    <div className='col-md-4 col-sm-4 col-xs-12 mgt'>
                                        {isSelectUser &&
                                            <div className='button-money-aff transparent'>
                                                <p className='text-large bold mgb-0'><del>{formatDecimal(cost.BaseBalanceZcrazy)} đ</del></p>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12 mgt'>
                                        <div className='button-money-aff'>
                                            <p className='text-large bold mgb-0'>{isRequestedZcrazy ? 'Đã gửi yêu cầu' : `${formatDecimal(balanceZcrazy)} đ`}</p>
                                        </div>
                                    </div>
                                    {!isRequestedZcrazy &&
                                        <div className='col-md-4 col-sm-4 col-xs-12 mgt flex flex-end'>
                                            <Button
                                                className='button-upgrade-aff'
                                                onClick={() => this.requestUpgrade(1)}
                                                loading={isPress}
                                            >
                                                <p className='text-large bold mgb-0'>Nâng cấp</p>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                        <div className='box-modal-aff mgt'>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge'>Kiếm tiền cùng Z-Affiliate</p>
                                <img
                                    src={require('../../assets/image/check-aff.png')}
                                    className='checked-btn-aff'
                                />
                            </div>
                            <div className='mgt-2'>
                                <p>• Mở khoá 100% tính năng Zcity.</p>
                                <p>• Hưởng {percentBonus}% phí nâng cấp những tài khoản Z-VIP do bạn giới thiệu (~818.000 đ/tài khoản).</p>
                                <p>• Hưởng {percentBonus}% phí hợp tác những tài khoản Z-Affiliate do bạn giới thiệu (~23.000 đ/tháng/tài khoản).</p>
                                <p>• Phí hợp tác: 50.000 đ/tháng</p>
                            </div>
                            {dataUser.IsZvip != '2' &&
                                <div className='mgt-2 row'>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                        {/* {isSelectUser &&
                                            <div className='button-money-aff transparent'>
                                                <p className='text-large bold mgb-0'><del>{formatDecimal(cost.BaseBalanceAff)} đ</del></p>
                                            </div>
                                        } */}
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12 mgt'>
                                        <div className='button-money-aff'>
                                            <p className='text-large bold mgb-0'>{isRequestedZvip ? 'Đã gửi yêu cầu' : `${formatDecimal(balanceAff)} đ`}</p>
                                        </div>
                                    </div>
                                    {!isRequestedZvip &&
                                        <div className='col-md-4 col-sm-4 col-xs-12 mgt flex flex-end'>
                                            <Button
                                                className='button-upgrade-aff'
                                                onClick={() => this.requestUpgrade(2)}
                                                loading={isPress}
                                            >
                                                <p className='text-large bold mgb-0'>Nâng cấp</p>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </>
                )
            case STEP_TYPE.BUY_ZCRAZY:
                return (
                    <>
                        <p
                            className='mgb-0 back-modal-aff'
                            onClick={() => this.removeRequest(1)}
                        >
                            ← <u>Chọn gói nâng cấp khác</u>
                        </p>
                        <div className='box-modal-aff mgt-2'>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge'>Thành viên Z-VIP</p>
                                <img
                                    src={require('../../assets/image/checked.png')}
                                    className='checked-btn-aff'
                                />
                            </div>
                            <div className='flex item-center mgt-2'>
                                <div className='button-money-aff'>
                                    <p className='text-large bold mgb-0'>{formatDecimal(balanceZcrazy)} đ</p>
                                </div>
                            </div>
                        </div>
                        <div className='box-modal-aff mgt'>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge text-center'>Chuyển khoản để hoàn tất nâng cấp</p>
                            </div>
                            <div className='row align-center mgt-2'>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <p>Ngân hàng: Techcombank</p>
                                    <p>Chủ Tài khoản: Nguyễn Minh Ngọc</p>
                                    <p>Số Tài khoản: 7871888888</p>
                                    <p>Chi Nhánh: Hoàng Gia</p>
                                    <p>Nội dung chuyển khoản: “{dataUser.FullName} {dataUser.PhoneNumber.slice(-4)}“</p>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12 item-center'>
                                    <img
                                        src={`https://img.vietqr.io/image/970407-7871888888-compact2.png?amount=${balanceZcrazy}&addInfo=${`${convertNonUnicode(dataUser.FullName)} ${dataUser.PhoneNumber.slice(-4)}`}&accountName=NGUYEN%20MINH%20NGOC`}
                                        className='aff-qr-img'
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex item-center mgt-3'>
                            <div className='button-confirm-aff shadow-pulse pointer' onClick={this.requestUpgrade}>
                                <p className='text-large bold mgb-0'>Xác nhận đã chuyển khoản</p>
                            </div>
                        </div> */}
                        <p className='mgt-3 text-center'>Quá trình nâng cấp tài khoản sẽ được phê duyệt trong 24h từ lúc đăng ký!</p>
                    </>
                )
            case STEP_TYPE.BUY_AFF:
                return (
                    <>
                        <p
                            className='mgb-0 back-modal-aff'
                            onClick={() => this.removeRequest(2)}
                        >
                            ← <u>Chọn gói nâng cấp khác</u>
                        </p>
                        <div className='box-modal-aff mgt-2'>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge'>Kiếm tiền cùng Z Affiliate</p>
                                <img
                                    src={require('../../assets/image/check-aff.png')}
                                    className='checked-btn-aff'
                                />
                            </div>
                            <div className='flex item-center mgt-2'>
                                <div className='button-money-aff'>
                                    <p className='text-large bold mgb-0'>{formatDecimal(balanceAff)} đ</p>
                                </div>
                            </div>
                        </div>
                        <div className='box-modal-aff mgt'>
                            <div className='flex item-center'>
                                <p className='mgb-0 text-huge text-center'>Chuyển khoản để hoàn tất nâng cấp</p>
                            </div>
                            <div className='row align-center mgt-2'>
                                <div className='col-md-8 col-sm-12 col-xs-12'>
                                    <p>Ngân hàng: Techcombank</p>
                                    <p>Chủ Tài khoản: Nguyễn Minh Ngọc</p>
                                    <p>Số Tài khoản: 7871888888</p>
                                    <p>Chi Nhánh: Hoàng Gia</p>
                                    <p>Nội dung chuyển khoản: “{dataUser.FullName} {dataUser.PhoneNumber.slice(-4)}“</p>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12 item-center'>
                                    <img
                                        src={`https://img.vietqr.io/image/970407-7871888888-compact2.png?amount=${balanceAff}&addInfo=${`${convertNonUnicode(dataUser.FullName)} ${dataUser.PhoneNumber.slice(-4)}`}&accountName=NGUYEN%20MINH%20NGOC`}
                                        className='aff-qr-img'
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex item-center mgt-3'>
                            <div className='button-confirm-aff shadow-pulse pointer' onClick={this.requestUpgrade}>
                                <p className='text-large bold mgb-0'>Xác nhận đã chuyển khoản</p>
                            </div>
                        </div> */}
                        <p className='mgt-3 text-center'>Quá trình nâng cấp tài khoản sẽ được phê duyệt trong 24h từ lúc đăng ký!</p>
                    </>
                )
        }
        return null
    }

    renderButtonAff = () => {
        const { dataUser } = this.props;
        if (dataUser.IsZvip == 2) {
            return (
                <>
                    {dataUser.IsZcrazy != 2 &&
                        <div className='button-aff-zvip-container'>
                            <div
                                className={`button-aff bg-warning`}
                                onClick={this.showModal}
                            >
                                <p className='mgb-0'>Nâng cấp Z-VIP</p>
                                <img
                                    src={require('../../assets/image/checked.png')}
                                    className='checked-btn-aff'
                                />
                            </div>
                        </div>
                    }
                    <div className='button-aff-container'>
                        <div
                            className={`button-aff ${this.state.isFocus ? 'bg-success' : 'bg-primary'}`}
                            onClick={() => this.props.history.push('/affiliate')}
                        >
                            <p className='mgb-0'>Doanh thu</p>
                            <img
                                src={require('../../assets/image/check-aff.png')}
                                className='checked-btn-aff'
                            />
                        </div>
                    </div>
                </>
            )
        }
        if (dataUser.IsZcrazy == 2) {
            return (
                <div className='button-aff-container'>
                    <div
                        className='button-aff bg-success'
                        onClick={this.showModal}
                    >
                        <p className='mgb-0'>Đăng ký Affiliate</p>
                        <img
                            src={require('../../assets/image/check-aff.png')}
                            className='checked-btn-aff'
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className='button-aff-container'>
                <div
                    className='button-aff'
                    onClick={this.showModal}
                >
                    <p className='mgb-0'>Nâng cấp Z-VIP</p>
                    <img
                        src={require('../../assets/image/checked.png')}
                        className='checked-btn-aff'
                    />
                </div>
            </div>
        )
    }

    checkPathname = (pathname) => {
        if (pathname === '/affiliate') {
            this.setState({ isFocus: true })
        }
        else this.setState({ isFocus: false })
    }

    componentDidMount() {
        this.getDataUserZvip()
        this.checkPathname(this.props.history?.location?.pathname)
        this.unlisten = this.props.history.listen((location) => {
            this.checkPathname(location.pathname)
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <>
                {this.renderButtonAff()}
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    destroyOnClose={true}
                    centered
                    width='90vh'
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, showModalEat })(withRouter(ButtonAff));