import React from "react";
import { useState } from "react";
import Private from "../routes/Private";
import Lobby from "../routes/Lobby";
import { isExistToken } from "../utils/common";
import EventBus, { EventBusName } from "../services/event-bus";
import { Subscription } from "rxjs";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";

const RouterContainer = () => {
    const [isLogined, setIsLogined] = useState(isExistToken())

    const subScription = new Subscription();

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res) => {
            if (res.type === EventBusName.LOGIN_SUCCESS) {
                setIsLogined(true)
            }
        });
        subScription.add(sub);
    };

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            if (subScription) subScription.unsubscribe();
        };
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route
                    path="/"
                    render={() => isLogined ? <Private /> : <Lobby />}
                />
            </Switch>
        </Router>
    )
}

export default RouterContainer;