import React from 'react';
import {Icon} from 'antd';

export default props =>{
    return(
        <div className='col-md-3 col-sm-4 col-xs-6 mgt-2'>
            <div
                className={'button-action-fight-room ' + (props.isActive ? 'bg-success' : 'bg-placeholder')}
                onClick={props.onClick}
            >
                <div className='flex item-center'>
                    {props.loading && <Icon type="loading" style={{ fontSize: '2vh', marginRight: '1vh' }} spin />}
                    <p className='mgb-0'>{props.title}</p>
                </div>
            </div>
        </div>
    )
}