import React, { Component } from 'react';
import {createFoodApi} from '../../api/home';
import {formatDecimal} from '../../utils/common';
import {URI_PATH, MESSAGE} from '../../utils/constants';
import{ changePoint, getUserItem } from '../../actions/home/';
import { connect } from 'react-redux';
import { Button } from 'antd';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../Loading';
import Lottie from 'react-lottie';
import cookAnimation from '../../assets/lottie/cook.json';
import { Checkbox } from 'antd';

class Cook extends Component{
    constructor(props){
        super(props);
        this.state = {
            listResource: [],
            listResourceSelect: [],
            amountCook: 1,
            balance: 0,
            percent: 0,
            isPress: false,
            resultCode: false,
            isAutoCook: false
        }
    }

    actionResource = (resourceId, balance, percent) =>{
        let {listResourceSelect, isPress} = this.state;
        if(isPress === false){
            if(listResourceSelect.includes(resourceId)){
                this.setState({
                    listResourceSelect: listResourceSelect.filter(e => e != resourceId),
                    balance: this.state.balance - parseInt(balance),
                    percent: this.state.percent - parseInt(percent)
                })
            }
            else{
                this.setState({
                    listResourceSelect: [...listResourceSelect, resourceId],
                    balance: this.state.balance + parseInt(balance),
                    percent: this.state.percent + parseInt(percent)
                });
            }
        }
    }

    createFood = () =>{
        if(this.state.isPress === false){
            let idNotification = '#notificationCook';
            let {listResourceSelect, amountCook} = this.state;
            if(listResourceSelect.length > 0){
                this.setState({isPress: true});
                createFoodApi(listResourceSelect, amountCook).then((res) => {
                    if(res.code == 1){
                        this.audio.play();
                        this.setState({animation: true, resultFood: false, resultCode: false});
                        this.props.changePoint([null, -res.balance, -res.balanceZ]);
                        showNotificationBonus(res.balance, -1, 2);
                        showNotificationBonus(res.balanceZ, -1, 3);
                        setTimeout(() => { 
                            this.setState({resultCode: res.result, resultFood: res.foodInfo, isPress: false, animation: false});
                            this.audio.pause();
                            if(res.result == 1) this.props.getUserItem(0, false);
                            if(this.state.isAutoCook) this.createFood();
						}, 3000);
                    }
                    else{
                        showNotification(res.message, res.code, idNotification);
                        this.setState({isPress: false});
                    }
                }).catch(error =>{
                    showNotification(MESSAGE.ERROR, -1,idNotification);
                    this.setState({isPress: false});
                });
            }
            else showNotification('Vui lòng chọn nguyên liệu để kết hợp', -1, idNotification);
        }
    }

    renderResult = ()=>{
        let {resultFood, resultCode} = this.state;
        if(resultCode !== false){
            if(resultCode == 1){
                return(
                    <div className='item-center mgt-2'>
                        <img
                            className='item-img'
                            src={`${URI_PATH}${resultFood.FoodImage}`}
                            alt={resultFood.FoodName}
                        />
                        <p>{resultFood.Amount}</p>
                        <p>{resultFood.FoodName}</p>
                        <p>{resultFood.Hour} giờ</p>
                        <p className='text-large'>Kết hợp thành công, xin chúc mừng !</p>
                    </div>
                )
            }
            return(
                <div className='item-center' style={{marginTop: '5.8vh', marginBottom: '5.8vh'}}>
                    <p className='text-large danger'>Kết hợp thất bại</p>
                </div>
            )
        }
        else if(this.state.animation){
            return(
                <div>
                    <Lottie options={{loop: true, autoplay: true, animationData: cookAnimation}}
                        height='18vh'
                        width='18vh'
                        speed={0.5}
                    />
                </div>
            )
        }
        return(
            <div style={{height: '15vh'}}></div>
        )
    }

    chooseAll = () =>{
        const listResource = this.props.listResource;
        const listResourceSelect = this.state.listResourceSelect;
        if(listResource.length == listResourceSelect.length){
            this.setState({
                listResourceSelect: [],
                balance: 0,
                percent: 0
            })
        }
        else this.setState({
            listResourceSelect: listResource.map(item => item.ResourceId),
            balance: listResource.reduce((total, num) => total + parseInt(num.Balance), 0),
            percent: listResource.reduce((total, num) => total + parseInt(num.Percent), 0)
        })
    }

    componentDidMount(){
        this.audio = new Audio(require('../../assets/audio/cook.mp3'));
    }

    render() {
        let {listResource} = this.props;
        let {balance, percent, amountCook, isAutoCook} = this.state;
        if (listResource.length > 0) {
            return (
                <div>
                    <div className='row mgt flex-row'>
                        {listResource.map((item, key) =>
                            <div key={key} className='resource-container'>
                                <img
                                    className={`resource-img ${this.state.listResourceSelect.includes(item.ResourceId) && 'bg-success'}`}
                                    alt={item.ResourceName}
                                    src={`${URI_PATH}${item.ResourceImage}`}
                                    onClick={() => this.actionResource(item.ResourceId, item.Balance, item.Percent)}
                                />
                            </div>
                        )}
                        <div className='resource-container' onClick={this.chooseAll}>
                            <div className='resource-img item-center flex' style={{height: '10vh'}}>
                                <p className='text-large' style={{userSelect: 'none'}}>Tất cả</p>
                            </div>
                        </div>
                    </div>
                    <div className='row mgt-2 item-center'>
                        <div id='notificationCook'></div>
                        <div className="col-sm-8 col-xs-12">
                            <select
                                className='form-control border-success mgt'
                                defaultValue={this.state.amountCook}
                                onChange={e => this.setState({amountCook: e.target.value})}
                            >
                                <option value="1">Kết hợp x 1 (xuất hiện linh thú)</option>
                                <option value="10">Kết hợp x 10</option>
                                <option value="100">Kết hợp x 100</option>
                                <option value="1000">Kết hợp x 1000</option>
                            </select>
                        </div>
                    </div>
                    <div className='item-center mgt'>
                        <Checkbox
                            checked={isAutoCook}
                            onChange={() => this.setState({isAutoCook: !isAutoCook})}
                        >
                            Tự động kết hợp
                        </Checkbox>
                    </div>
                    <p className='text-center text-large bold mgt-2'>Chi phí: {formatDecimal(`${amountCook * balance}`)} đ[z]</p>
                    <p className='text-center text-large bold mgt-2'>Tỉ lệ thành công: {percent}%</p>
                    {this.renderResult()}
                    {this.state.isPress === false &&(
                        <div className='item-center mgt-2'>
                            <Button
                                className='button-cook'
                                type="primary"
                                onClick={this.createFood}
                            >
                                Kết hợp
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <Loading small />
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listResource } = state.getResourceCook;
    return { dataUser, listResource };
}

export default connect(mapStateToProps, { changePoint, getUserItem })(Cook);