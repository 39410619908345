import React from 'react';
import { Component } from 'react';
import {getAllAskQuestionApi} from '../../api/askquestion';
import { MESSAGE } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import find from '../../assets/lottie/find.json';
import Comment from '../../components/newfeed/comment';
import ListComponent from '../../components/newfeed/item';
import {getCategory, getSubCategory} from '../../actions/category/';
import {connect} from 'react-redux';
import {appendListNewfeed, clearNewfeedData} from '../../actions/newfeed';
import queryString from 'query-string';
import SearchAskQuestion from '../../components/postVideo/Search';
import { withRouter } from 'react-router-dom';

class AskQuestionSearch extends Component {
    constructor(props){
        super(props);
        this.state = {
            askQuestionId: queryString.parse(this.props.location.search).id,
            isLoading: true,
            isPress: false,
        }
    }

    selectAskQuestion = dataAskQuestion =>{
        if(dataAskQuestion.length > 0){
            let askQuestionId = dataAskQuestion[0].askQuestionId;
            this.setState({askQuestionId});
        }
    }

    deleteSearch = e =>{
        if(e.key == 'Backspace' && this.state.askQuestionId){
            this.searchAskQuestion.getInstance().clear();
        }
    }

    getAskQuestion = () =>{
        getAllAskQuestionApi(1, 'time', '', '', '', this.state.askQuestionId).then(res => {
            if(res.code == 1){
                this.setState({
                    isLoading: false,
                })
                this.props.appendListNewfeed(res.listResult);
            }
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidUpdate(_prevProps, prevState){
        if(prevState.askQuestionId != this.state.askQuestionId){
            this.getAskQuestion();
        }
    }

    componentDidMount(){
        if(this.state.askQuestionId > 0) this.getAskQuestion();
    }

    componentWillUnmount(){
        this.props.clearNewfeedData();
    }

    render(){
        let {isLoading, askQuestionId} = this.state;
        let {listNewfeed} = this.props;
        return(
            <div>
                <HeaderLottie source={find} />
                <div className='row item-center'>
                    <div className='col-md-6 col-sm-8 col-xs-12'>
                        <SearchAskQuestion
                            ref={e => this.searchAskQuestion = e}
                            onChange={this.selectAskQuestion}
                            placeholder='Nhập mã câu hỏi 🔎'
                            type='ask-question'
                            onKeyDown={this.deleteSearch}
                        />
                    </div>
                </div>
                {askQuestionId > 0 &&
                    <>
                        {isLoading ?
                            <Loading />
                        :
                            <>
                                {listNewfeed.length == 0 ?
                                    <div className='item-center'>
                                        <HeaderLottie source={empty} disableLoop />
                                        <p className='text-large text-center mgt'>Không có câu hỏi cần tìm</p>
                                    </div>
                                :
                                    <>
                                        {listNewfeed.map((item, key) =>
                                            <div className='box-primary mgb' key={key}>
                                                <ListComponent.AskQuestion data={item} />
                                                <Comment data={item} />
                                            </div>
                                        )}
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, listNewfeed };
}

export default connect(mapStateToProps, {getCategory, getSubCategory, appendListNewfeed, clearNewfeedData})(withRouter(AskQuestionSearch));