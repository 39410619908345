import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import {showNotification} from '../../utils/notification';
import {validateEmail} from '../../utils/common';
import {sendTokenApi, changePassApi} from '../../api/authentication/forgot';
import {MESSAGE, PROP} from '../../utils/constants';

export default class ModalForgotPass extends Component{
    constructor(props){
        super(props);
        this.state = {
            isPress: false,
            step: 1,
            inputEmail: '',
            inputToken: '',
            inputPass: '',
            inputRePass: ''
        }
    }

    sendEmail = () =>{
        let {isPress, inputEmail} = this.state;
        if(!isPress){
            let idNotification = '#notifitcationSendEmailForgot';
            inputEmail = inputEmail.trim();
            if(validateEmail(inputEmail)) {
                this.setState({isPress: true});
                sendTokenApi(inputEmail).then(res =>{
                    if(res.code == 1){
                        showNotification(res.message, res.code);
                        this.setState({
                            isPress: false,
                            step: 2
                        });
                    }
                    else{
                        this.setState({isPress: false});
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
            else return showNotification('Địa chỉ email không đúng', 0, idNotification);
        }
    }

    changePassForgot = () =>{
        let {inputToken, inputPass, inputRePass, isPress} = this.state;
        if(!isPress){
            let idNotification = '#notifitcationChangePassForgot';
            inputToken = inputToken.trim();
            inputPass = inputPass.trim();
            inputRePass = inputRePass.trim();
            if(inputToken > 0 && inputPass.length >= 6 && inputPass == inputRePass){
                this.setState({isPress: true});
                changePassApi(inputToken, inputPass, inputRePass).then(res =>{
                    if(res.code == 1){
                        showNotification(res.message, res.code);
                        this.props.onHideModal();
                        this.setState({
                            isPress: false,
                            step: 1,
                            inputEmail: '',
                            inputToken: '',
                            inputPass: '',
                            inputRePass: ''
                        });
                    }
                    else{
                        this.setState({isPress: false});
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch(e =>{
                    this.setState({isPress: false});
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
            else if(!(inputToken > 0)) showNotification('Mã xác thực không đúng', 0, idNotification);
            else if(inputPass.length < 6) showNotification('Mật khẩu phải tối thiểu 6 kí tự', 0, idNotification);
            else if(inputPass != inputRePass) showNotification('Nhập lại mật khẩu không chính xác', 0, idNotification);
        }
    }

    render(){
        let {isPress, step, inputEmail, inputToken, inputPass, inputRePass} = this.state;
        return(
            <Modal
                {...PROP.MODAL}
                visible={this.props.showModal}
                onCancel={this.props.onHideModal}
            >
                <p className='text-center text-large bold mgt'>Quên mật khẩu</p>
                {step == 1 ? 
                    <div>
                        <p className='mgt'>Email đăng ký:</p>
                        <input
                            className='form-control'
                            placeholder='Nhập địa chỉ email đăng ký'
                            value={inputEmail}
                            onChange={e => this.setState({inputEmail: e.target.value})}
                        />
                        <div className='mgt' id='notifitcationSendEmailForgot'/>
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt'
                                loading={isPress}
                                onClick={this.sendEmail}
                            >
                                Gửi mã xác thực
                            </Button>
                        </div>
                    </div>
                :
                    <div>
                        <p className='mgt'>Mã xác thực</p>
                        <input
                            className='form-control'
                            placeholder='Nhập mã xác thực'
                            value={inputToken}
                            onChange={e => this.setState({inputToken: e.target.value})}
                        />
                        <p className='mgt'>Mật khẩu mới</p>
                        <input
                            className='form-control'
                            placeholder='Nhập mật khẩu mới'
                            value={inputPass}
                            type='password'
                            onChange={e => this.setState({inputPass: e.target.value})}
                        />
                        <p className='mgt'>Nhập lại mật khẩu mới</p>
                        <input
                            className='form-control'
                            placeholder='Nhập mật khẩu mới'
                            value={inputRePass}
                            type='password'
                            onChange={e => this.setState({inputRePass: e.target.value})}
                        />
                        <div className='mgt' id='notifitcationChangePassForgot'/>
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt'
                                loading={isPress}
                                onClick={this.changePassForgot}
                            >
                                Đổi mật khẩu
                            </Button>
                            <p
                                className='mgt-2'
                                onClick={() => this.setState({
                                    step: 1,
                                    inputToken: '',
                                    inputPass: '',
                                    inputRePass: ''
                                })}
                            >
                                <u>Quay lại</u>
                            </p>
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}