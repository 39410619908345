import React, { Component } from 'react';
import { connect } from 'react-redux';
import{ changePoint } from '../../actions/home';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getListStudentApi, changeIsFinishApi, ignoreCourseApi, refuseCourseApi, getListCourseApi} from '../../api/course/manage';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import { Button, Modal, Switch } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { getAvatarFb } from '../../utils/common';
import SearchUser from '../../components/SearchUser';

class CourseList extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isPress: false,
            listCourse: [],
            listStudent: [],
            studentInfo: {},
            showModal: false,
            modalType: '',
            endList: false
        }
        this.searchText = '';
        this.courseId = '';
        this.page = 1;
    }

    getListCourse = ()=>{
        getListCourseApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    listCourse: res.listCourse
                })
            }
        })
    }

    getListStudent = () =>{
        getListStudentApi(this.searchText, this.courseId, this.page).then(res =>{
            if(res.code == 1){
                this.setState({
                    listStudent: this.page == 1 ? res.listStudent : [...this.state.listStudent, ...res.listStudent],
                    endList: res.listStudent.length == 0,
                    isLoading: false
                });
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    loadMore = () =>{
        this.page += 1;
        this.getListStudent();
    }

    changeIsFinish = (courseRegisterId, e) =>{
        let isFinish = e ? 2: 1;
        let idNotification = '#notificationCourseManage' + courseRegisterId;
        changeIsFinishApi(courseRegisterId, isFinish).then(res =>{
            if(res.code == 1){
                this.setState({
                    listStudent: this.state.listStudent.map(el => {
                        if(el.CourseRegisterId == courseRegisterId) return Object.assign({}, el, {
                            IsFinish: isFinish
                        })
                        return el;
                    })
                })
            }
            showNotification(res.message, res.code, idNotification);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    ignoreCourse = () =>{
        let {isPress, studentInfo, listStudent} = this.state;
        if(!isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationActionCourse';
            ignoreCourseApi(studentInfo.CourseRegisterId).then(res =>{
                if(res.code == 1){
                    let bonus = res.bonus;
                    showNotificationBonus(bonus.Balance, -1, 2);
                    showNotificationBonus(bonus.TicketCount, -1, 1);
                    this.props.changePoint([-bonus.TicketCount, -bonus.Balance]);
                    showNotification(res.message, res.code);
                    this.setState({
                        listStudent: listStudent.filter(el =>
                            { return el.CourseRegisterId != studentInfo.CourseRegisterId; }
                        ),
                        isPress: false,
                        showModal: false
                    })
                }
                else {
                    this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    refuseCourse = () =>{
        let {isPress, studentInfo, listStudent} = this.state;
        if(!isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationActionCourse';
            refuseCourseApi(studentInfo.CourseRefuseId).then(res =>{
                if(res.code == 1){
                    let bonus = res.bonus;
                    showNotificationBonus(bonus.Balance, -1, 2);
                    showNotificationBonus(bonus.TicketCount, -1, 1);
                    this.props.changePoint([-bonus.TicketCount, -bonus.Balance]);
                    showNotification(res.message, res.code);
                    this.setState({
                        listStudent: listStudent.filter(el =>
                            { return el.CourseRegisterId != studentInfo.CourseRegisterId; }
                        ),
                        isPress: false,
                        showModal: false
                    })
                }
                else {
                    this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    searchStudent = selectPhone =>{
        this.searchText = selectPhone[0].name;
        this.page = 1;
        this.getListStudent();
    }

    deleteSearchStudent = e =>{
        if(e.key == 'Backspace'){
            this.refs.searchStudent.getInstance().clear();
            this.searchText = '';
            this.page = 1;
            this.getListStudent();
        }
    }

    changeCourseId = e =>{
        this.courseId = e.target.value;
        this.page = 1;
        this.getListStudent();
    }

    componentDidMount(){
        this.getListStudent();
        this.getListCourse();
    }

    render(){
        let {isLoading, listCourse, listStudent, studentInfo, endList, showModal, modalType, isPress} = this.state;
        if(isLoading) return <Loading />
        return(
            <div>
                <div className='flex item-center mgb'>
                    <p className='bold title-success'>Danh sách học viên đã đăng kí</p>
                </div>
                <div className='box-primary row'>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <select className='form-control' onChange={this.changeCourseId}>
                            <option>Chọn khoá học</option>
                            {listCourse.map((item, key) =>
                                <option value={item.CourseId} key={key}>{item.CourseName} (khoá {item.CourseNo})</option>
                            )}
                        </select>
                    </div>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <SearchUser
                            ref='searchStudent'
                            onChange={this.searchStudent}
                            onKeyDown={this.deleteSearchStudent}
                            placeholder='🔎 Học viên'
                        />
                    </div>
                </div>
                {listStudent.length == 0 ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large mgt'>Không tìm thấy học viên đăng kí</p>
                    </div>
                :
                    <div>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small/>}
                            threshold={50}
                        >
                            {listStudent.map((item, key) =>
                                <div className='box-primary' key={key}>
                                    <p className='text-large'>{item.CourseName}</p>
                                    <div className='flex-row'>
                                        <img src={getAvatarFb(item.FacebookId)} className='avatar-guest' key={item.StudentId} />
                                        <p className='mg'>{item.StudentName}</p>
                                    </div>
                                    <p>Số điện thoại: {item.PhoneNumber}</p>
                                    <p>Số lần yêu cầu hoàn tiền: {item.RefundCourseTime}</p>
                                    <p>Ngày sinh: {item.BirthDay}</p>
                                    <p>4 số cuối giấy tờ tuỳ thân: {item.IDCardNumber}</p>
                                    {item.ByUserName &&
                                        <p>Đăng kí bởi: {item.ByUserName}</p>
                                    }
                                    <div className='flex-row'>
                                        <p className='mgr'>Tốt nghiệp:</p>
                                        <Switch
                                            checked={item.IsFinish == 2}
                                            onChange={e => this.changeIsFinish(item.CourseRegisterId, e)}
                                        />
                                    </div>
                                    <div id={'notificationCourseManage' + item.CourseRegisterId} />
                                    <div className='flex-row mgt'>
                                        <Button
                                            className='bg-danger'
                                            onClick={() => this.setState({showModal: true, studentInfo: item, modalType: 1})}
                                        >
                                            Từ chối dạy
                                        </Button>
                                        {item.CourseRefuseId > 0 &&
                                            <Button
                                                className='bg-warning mgl'
                                                onClick={() => this.setState({showModal: true, studentInfo: item, modalType: 2})}
                                            >
                                                Hoàn tiền
                                            </Button>
                                        }
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                        <Modal
                            {...PROP.MODAL}
                            visible={showModal}
                            onCancel={() => this.setState({showModal: false})}
                        >
                            <div className='flex-column item-center'>
                                <p className='text-large mgt-2'>Xác nhận {modalType == 1 ? 'từ chối dạy' : 'hoàn tiền cho'}: {studentInfo.StudentName}</p>
                                <img src={getAvatarFb(studentInfo.FacebookId)} className='avatar-guest-large mgt' />
                                <div className='mgt' id='notificationActionCourse' />
                                <Button
                                    className='bg-danger mgt-3'
                                    loading={isPress}
                                    onClick={modalType == 1 ? this.ignoreCourse : this.refuseCourse}
                                >
                                    Xác nhận
                                </Button>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint })(CourseList);