import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListRegisterApi = (pageId) =>{
    let url = `${URI_PATH}app/course/own/getListRegister`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const refuseRequestApi = courseRegisterId =>{
    let url = `${URI_PATH}app/course/own/refuseRequest`;
    let body = {
        CourseRegisterId: courseRegisterId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}