import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListCartApi = (page) =>{
    let url = `${URI_PATH}app/charge/chargeasset/getListCart`;
    let body = {
        PageId: page
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListAssetApi = (userSell) =>{
    let url = `${URI_PATH}app/charge/chargeasset/getListAsset`;
    let body = {
        UserSell: userSell
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyAssetApi = (amount, userassetmarketId) =>{
    let url = `${URI_PATH}app/charge/chargeasset/buyAsset`;
    let body = {
        Amount: amount,
        UserassetmarketId: userassetmarketId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}