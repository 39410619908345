import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListStudentApi = (searchText, courseId, pageId) =>{
    let url = `${URI_PATH}app/course/manage/getListStudent`;
    let body = {
        SearchText: searchText,
        CourseId: courseId,
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const changeIsFinishApi = (courseRegisterId, isFinish) =>{
    let url = `${URI_PATH}app/course/manage/changeIsFinish`;
    let body = {
        CourseRegisterId: courseRegisterId,
        IsFinish: isFinish
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const ignoreCourseApi = courseRegisterId =>{
    let url = `${URI_PATH}app/course/manage/ignoreCourse`;
    let body = {
        CourseRegisterId: courseRegisterId,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const refuseCourseApi = courseRefuseId =>{
    let url = `${URI_PATH}app/course/manage/refuseCourse`;
    let body = {
        CourseRefuseId: courseRefuseId,
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListCourseApi = () =>{
    let url = `${URI_PATH}app/course/manage/getListCourse`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}