import React, { Component } from 'react';
import {MESSAGE} from '../../utils/constants';
import {Button} from 'antd';
import {connect} from 'react-redux';
import {getQuestionVoteApi, voteQuestionApi} from '../../api/question/vote';
import { changePoint } from '../../actions/home/';
import {updateCountNotify} from '../../actions/menu';
import {showNotification, showNotificationBonus, showNotificationAsset} from '../../utils/notification';
import Loading from '../../components/Loading';
import checklist from '../../assets/lottie/checklist.json';
import HeaderLottie from '../../components/HeaderLottie';
import InfiniteScroll from 'react-infinite-scroller';
import {showModalEat} from '../../actions/home/modalEat';
import empty from '../../assets/lottie/empty.json';

class QuestionVote extends Component {
    constructor(props){
        super(props);
        this.state = {
            questionList: [],
            approveBonus: 0,
            voteRemain: 0,
            isLoading: true,
            endList: false
        };
        this.isPress = false;
        this.pageList = 1;
    }

    getQuestionVote = () =>{
        getQuestionVoteApi(this.pageList).then(res =>{
            this.setState({
                questionList: this.pageList == 1 ? res.data.questionList : [...this.state.questionList, ...res.data.questionList],
                endList: res.data.questionList.length == 0,
                approveBonus:  res.data.approveBonus,
                voteRemain:  res.data.voteRemain,
                isLoading: false,
            })
        }).catch(e => showNotification(MESSAGE.ERROR, -1));
    }

    voteQuestion = (questionId, isApprove) =>{
        if(this.isPress === false){
            this.isPress = true;
            let {questionList, voteRemain} = this.state;
            voteQuestionApi(questionId, isApprove).then(res =>{
                if(res.code == 1){
					//assets bonus
					showNotificationAsset(res.assetInfo);
					//
                    showNotificationBonus(res.bonus.aBalanceZ, 1, 3);
                    this.props.changePoint([null, null, res.bonus.aBalanceZ, null]);
                    this.props.updateCountNotify('QuestionVoteCount');
                    this.setState({
                        voteRemain: voteRemain - 1,
                        questionList: questionList.filter( el => el.QuestionId !== questionId )
                    });
                    showNotification(res.message, res.code);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code);
                }
                this.isPress = false;
            }).catch(e => {
                this.isPress = false;
                showNotification(MESSAGE.ERROR, -1);
            })
        }
    }

    loadMoreList = () => {
        let {endList} = this.state;
        if(!endList){
            this.pageList += 1;
            this.getQuestionVote();
        }
    }

    componentDidMount(){
        this.getQuestionVote();
    }

    render(){
        if(this.state.isLoading) return (<Loading />);
        let {endList, questionList, approveBonus} = this.state;
        return(
            <div>
                <HeaderLottie source={checklist} size='20vh' disableLoop />
                {/* <div className='flex-column item-center'>
                    <p className='bold title-success mgt'>Mỗi phiếu xét duyệt bạn được thưởng {priceFormat(approveBonus)} đ[z]</p>
                </div> */}
                <InfiniteScroll
                    loadMore={this.loadMoreList}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {questionList.length == 0 &&
                        <div className='item-center'>
                            <HeaderLottie source={empty} disableLoop />
                            <p className='text-large text-center mgt'>Không có câu hỏi cần duyệt</p>
                        </div>
                    }
                    {questionList.map((item, key) =>(
                        <div className='container-list-question' key={key}>
                            <div className='flex-row'>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Mã câu hỏi</p>
                                    <p className='text-center'>{item.QuestionId}</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Thời gian</p>
                                    <p className='text-center'>{item.CrDateTime}</p>
                                </div>
                            </div>
                            <p className='text-center success bold text-large mgt'>{item.QuestionTitle}</p>
                            <div className='flex-row mgt'>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote bg-success'>{item.Answear1}</p>
                                </div>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote'>{item.Answear2}</p>
                                </div>
                            </div>
                            <div className='flex-row mgt'>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote'>{item.Answear3}</p>
                                </div>
                                <div className='flex-column flex-1 item-center'>
                                    <p className='answer-vote'>{item.Answear4}</p>
                                </div>
                            </div>
                            <div className='flex-row mgt-2'>
                                <div className='flex-column flex-1 item-center'>
                                    <Button
                                        className='vote-button bg-success'
                                        onClick={() => this.voteQuestion(item.QuestionId, 1)}
                                    >
                                        Đồng ý
                                    </Button>
                                </div>
                                <div className='flex-column flex-1 item-center'>
                                    <Button
                                        className='vote-button bg-danger'
                                        onClick={() => this.voteQuestion(item.QuestionId, 1)}
                                    >
                                        Không đồng ý
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, updateCountNotify, showModalEat})(QuestionVote);