import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const sendApi = (userId, message, balanceType, balance, listAssetSelect, listFoodSelect) =>{
    let url = `${URI_PATH}app/gift/offer/send`;
    let body = {
        UserId: userId,
        Message: message,
        BalanceType: balanceType,
        Balance: balance,
        ListAssetSelect: listAssetSelect,
        ListFoodSelect: listFoodSelect
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListOfferApi = (pageId) =>{
    let url = `${URI_PATH}app/gift/offer/getListOffer`;
    let body = {
        PageId: pageId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const openApi = (usergiftofferId) =>{
    let url = `${URI_PATH}app/gift/offer/openOffer`;
    let body = {
        UsergiftofferId: usergiftofferId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListNewfeedOfferApi = (PageId, UserId) =>{
    let url = `${URI_PATH}app/newfeed/main/getListNewfeedByType`;
    let body = {
        Type: 7,
        PageId,
        UserId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}