const INITAL_STATE = {
    listAssetInCart: [],
    listFoodInCart: [],
    listCart: [],
    sellData: [],
    onCartFood: false,
    onCartAsset: false,
    selectCart: false,
    selectItem: false
};

export default (state = INITAL_STATE, action) => {
    let selectCart = '';
    switch (action.type) {
        case 'SUCCESS_GET_LIST_CART':
            action.listCart.map((item, key) =>{
                if(item.Expire) selectCart = item;
            });
            return { ...state, listCart: action.listCart, selectCart: selectCart };
        case 'SUCCESS_GET_FOOD_IN_CART':
            return { ...state, listFoodInCart: action.listFoodInCart, selectItem: action.selectItem, onCartFood: action.sellData.Count > 0};
        case 'SUCCESS_GET_ASSET_IN_CART':
            return { ...state, listAssetInCart: action.listAssetInCart, selectItem: action.selectItem, onCartAsset: action.sellData.Count > 0 };
        case 'UPDATE_ITEM_IN_CART':
            if(action.typeUpdate == 1) return { ...state, listFoodInCart: action.listItem };
            else return { ...state, listAssetInCart: action.listItem };
        case 'TOGGLE_CART':
            if(action.typeCart == 1) return { ...state, onCartFood: action.value };
            else if(action.typeCart == 2) return { ...state, onCartAsset: action.value };
        case 'SELECT_CART':
            return { ...state, selectCart: action.selectCart };
        case 'UPDATE_LIST_CART':
            return { ...state, listCart: action.listCart, sellData: action.sellData};
        case 'FALSE_GET_FOOD_IN_CART': case 'FALSE_GET_ASSET_IN_CART': case 'FALSE_GET_LIST_CART_FOOD': case 'FALSE_GET_LIST_CART_ASSET':
            return { ...state };
        default:
            return state;
    }
};