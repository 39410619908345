import React, { Component } from 'react';
import { getUserData } from '../../actions/home';
import { connect } from 'react-redux';
import { getListPackage } from '../../actions/study';
import { showHideModal } from '../../actions/study';
import { Modal, Progress } from 'antd';
import { COLOR, PROP } from '../../utils/constants';
import { TitleSuccess } from '../../components/frame';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { formatDecimal } from '../../utils/common';

class StudyPackage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getListPackage();
    }

    render() {
        const { listPackage, showModal, dataUser } = this.props;
        return (
            <>
                <Modal
                    {...PROP.MODAL}
                    width={'120vh'}
                    visible={showModal}
                    onCancel={() => this.props.showHideModal(false)}
                >
                    <p className='package-text-modal'>{`${'Hãy trân trọng thời gian & tiền bạc của bạn!'.toUpperCase()}`}</p>
                    {listPackage.map((item, key) => {
                        const percentDay = parseInt(item.TotalCurrentDay) / parseInt(item.AllDay) * 100
                        const percentPair = parseInt(item.PackagePaidPrice) / parseInt(item.PackageTotalPrice) * 100
                        return (
                            <div className='box-package mgt-2' key={key}>
                                <p className='text-large text-center bold'>{item.Subject}</p>
                                <p className='text-large text-center bold mgt-2'></p>
                                <div className='package-progress-container'>
                                    <div className='flex-row mgb'>
                                        <div className='flex flex-1' style={{ justifyContent: 'flex-start' }}>
                                            <p className='mgl mgb-0'>{moment(item?.PackageStartDate).format("DD/MM/YYYY")}</p>
                                        </div>
                                        <div className='flex flex-1 item-center'>
                                            <p className='mgb-0 text-center bold'>{item.PackageName}</p>
                                        </div>
                                        <div className='flex flex-1' style={{ justifyContent: 'flex-end' }}>
                                            <p className='mgl mgb-0'>{moment(item?.PackageEndDate).format("DD/MM/YYYY")}</p>
                                        </div>
                                    </div>
                                    <Progress
                                        className='package-progress'
                                        strokeColor={{ '0%': '#96D896', '100%': '#0bb70b' }}
                                        percent={percentDay}
                                        status="active"
                                        format={() => null}
                                    />
                                    <div className='flex-row mgt item-center'>
                                        <p className='mgb-0 text-center'>{item.IsOverEndDate ? 'Đã hoàn thành' : `Đã học: ${item?.TotalCurrentDay} ngày, còn ${item?.DateRemainAll} ngày`}</p>
                                    </div>
                                </div>
                                <div className='row mgt-2' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className='col-md-4' style={{width: '100%'}}>
                                        <div className='chart-progress flex-2 flex-column'>
                                            <p className='text-center'>Đã đóng: {formatDecimal(item?.PackagePaidPrice || 0)} vnđ</p>
                                            <ReactApexChart
                                                options={{
                                                    chart: {
                                                        width: 380,
                                                        type: 'pie',
                                                    },
                                                    labels: ['Đã đóng', 'Chưa đóng'],
                                                    colors: [COLOR.COLOR_SUCCESS, '#171819'],
                                                    tooltip: {
                                                        enabled: false
                                                    },
                                                    dataLabels:{
                                                        enabled: false
                                                    }
                                                }}
                                                series={[percentPair, 100 - percentPair]}
                                                type="pie"
                                                width={240}
                                            />
                                            <p className='text-center'>Học phí: {formatDecimal(item?.PackageTotalPrice || 0)} vnđ</p>
                                        </div>
                                    </div>
                                    <div className='col-md-8' style={{width: '100%'}}>
                                        <div className='box-package-pair row'>
                                            {item.IsDone ?
                                                <div className='flex item-center' style={{width: '100%', height: '30vh'}}>
                                                <p className='text-center text-large'>ĐÃ HOÀN THÀNH HỌC PHÍ</p>
                                                </div>
                                            :
                                            <>
                                            <div className='package-title'>
                                                <p className='text-center'>Ngày đóng tiếp theo: {moment(item?.EndStudentTime).format("DD/MM/YYYY")}</p>
                                                {item?.IsExpire ? 
                                                    <p>Quá hạn <span className='danger bold'>{item?.DateRemain}</span> ngày</p>
                                                :
                                                    <p className='text-center'>(còn <span className={item?.DateRemain < 3 && ('danger bold')}>{item?.DateRemain}</span> ngày nữa)</p>
                                                }
                                                <p className='text-center mgt-4'>Học phí trả góp</p>
                                                <div className='flex item-center mgb'>
                                                    <div className='package-price'>
                                                        <p className='mgb-0'>{formatDecimal(item?.PackageSuggestPrice || 0)} vnđ</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                src={`https://img.vietqr.io/image/970407-7871888888-compact2.png?amount=${item?.PackageSuggestPrice}&addInfo=${dataUser.FullName}%20ck&accountName=NGUYEN%20MINH%20NGOC`}
                                                className='package-qr-img'
                                            />
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { listPackage, showModal } = state.getDataPackage;
    return { listPackage, showModal, dataUser };
}

export default connect(mapStateToProps, { getUserData, getListPackage, showHideModal })(StudyPackage);