import React from 'react';
import { connect } from 'react-redux';

const BarSelect = ({isLoadingAudio, children, dispatch, ...rest}) =>{
    return(
        <div className='pv-bar-container'>
            <div
                className={'pv-bar flex-row item-center ' + (!isLoadingAudio && 'space-navigate-audio')}
                {...rest}
            >
                {children}
            </div>
        </div>
    )
}

const mapStateToProps = state =>{
    const { isLoadingAudio } = state.getAudio;
    return {isLoadingAudio};
}

export default connect(mapStateToProps, null)(BarSelect);