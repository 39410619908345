import React, {useState} from 'react';
import {searchByNameOrPhoneApi} from '../api/user';
import {PROP} from '../utils/constants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default React.forwardRef((props, ref) =>{
    const [itemSearch, setItemSearch] = useState([]);

    const searchByNameOrPhone = (searchText) =>{
        searchByNameOrPhoneApi(searchText).then(res => {
            setItemSearch(res);
        })
    }

    return(
        <AsyncTypeahead
            {...props}
            {...PROP.TYPE_AHEAD}
            ref={ref}
            onSearch={searchByNameOrPhone}
            options={itemSearch}
        />
    )
})