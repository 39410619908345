import { getQuestionTrainSelectApi } from '../../api/question/train';

export const getQuestionTrainSelect = () =>{
    return dispatch => {
        getQuestionTrainSelectApi().then((res) => {
            if(res.code == 1){
                dispatch({ type: 'SUCCESS_GET_LIST_QUESTION_TRAIN_SELECT', listTrain: res.listTrain});
            }
            else dispatch({ type: 'FALSE_GET_LIST_QUESTION_TRAIN_SELECT' });
        })
        .catch(error =>{
            dispatch({ type: 'FALSE_GET_LIST_QUESTION_TRAIN_SELECT' })
        })
    }
}