import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const publishAskQuestionApi = (CategoryId, SubCategoryId, AskQuestionTitle, AskQuestionContent, AskQuestionImage) =>{
    let url = `${URI_PATH}app/askquestion/publish/publishAskQuestion`;
    let body = {
        CategoryId,
        SubCategoryId,
        AskQuestionTitle,
        AskQuestionContent,
        AskQuestionImage
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}