import React, { Component } from 'react';
import { Modal, Button, Switch } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../components/Loading';
import { getAvatarFb } from '../../../utils/common';
import { getListStudentSupportApi } from '../../../api/admin/study/support';
import StudySummary from '../../../screens/study/StudySummary';
import SupportDetail from './SupportDetail';
import Checked from '../../frame/Checked';
import TooltipUser from '../../TooltipUser';

class SupportWait extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            pageId: 1,
            isLoading: true,
            listStudent: [],
            endList: false,
            inputSearch: '',
            inputLevel: '',
            showModal: false,
            modalType: 1,
            userSelect: {},
            inputStudyStudent: 1,
            inputTimeSupport: '',
            isFilterOnline: true
        }
    }

    getListStudent = (pageId = 1) => {
        const { inputSearch, inputLevel, inputStudyStudent, inputTimeSupport, isFilterOnline } = this.state;
        if (pageId == 1) this.setState({ isLoading: true })
        getListStudentSupportApi(pageId, inputSearch, inputLevel, inputStudyStudent, inputTimeSupport, isFilterOnline).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listStudent: pageId == 1 ? res.data : [...this.state.listStudent, ...res.data],
                    endList: res.data.length < 20,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListStudent(this.state.pageId + 1);
        }
    }

    changeLevel = (e) => {
        const inputLevel = e.target.value;
        this.setState({ inputLevel }, () => this.getListStudent(1));
    }

    changeInputSearch = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.timeoutSearch = setTimeout(() => {
            this.getListStudent(1);
        }, 500);
    }

    changeInputTimeSupport = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputTimeSupport = e.target.value;
        this.setState({ inputTimeSupport });
        this.timeoutSearch = setTimeout(() => {
            this.getListStudent(1);
        }, 500);
    }

    renderModal = () => {
        const { modalType, userSelect } = this.state;
        switch (modalType) {
            case 1:
                return <StudySummary userId={userSelect.UserId} isSupport={true} />
            case 2:
                return <SupportDetail userInfo={userSelect} isManage={this.props.isManage} onUpdate={this.getListStudent} />
            default:
                break;
        }
    }

    changeStudyStudent = (e) => {
        const inputStudyStudent = e.target.value;
        this.setState({ inputStudyStudent }, () => this.getListStudent(1, inputStudyStudent));
    }

    changeFilterOnline = (isFilterOnline) =>{
        this.setState({isFilterOnline}, () => this.getListStudent(1))
    }

    componentDidMount() {
        this.getListStudent()
    }

    render() {
        const { isLoading, listStudent, inputSearch, endList, showModal, inputLevel, inputStudyStudent, inputTimeSupport, isFilterOnline } = this.state;
        return (
            <>
                <div className='row'>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <input
                            type='text'
                            className='form-control'
                            value={inputSearch}
                            onChange={this.changeInputSearch}
                            placeholder='Nhập họ tên hoặc số điện thoại'
                            style={{ fontSize: '2vh' }}
                        />
                    </div>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <select
                            className='form-control'
                            onChange={this.changeStudyStudent}
                            value={inputStudyStudent}
                        >
                            <option value="1">Đã từng học</option>
                            <option value="2">Chưa từng học</option>
                        </select>
                    </div>
                </div>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                            className='mgt-2'
                        >
                            {listStudent.map((item, key) =>
                                <div className='row box-primary relative' key={key}>
                                    <div className='col-md-5 col-sm-6 col-xs-12 flex align-center'>
                                        <TooltipUser userInfo={item} avatarClass='avatar-guest-small mgr' />
                                        <div>
                                            <p className='mgb-0'>{item.FullName}<Checked data={item} /> - {item.PhoneNumber}</p>
                                            <p className='mgb-0 text-time'>Online: {item.UpdateDateTime}</p>
                                            <div className='flex align-center'>
                                                <p className='mgb-0 text-time'>Khởi tạo: {item.CreateDateTime}</p>
                                                {item.IsAlert == 2 &&
                                                    <img
                                                        src={require('../../../assets/image/watch-clock-active.png')}
                                                        className='watch-clock'
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6 col-xs-12 flex'>
                                        <div className='flex-3 item-center'>
                                            <Button
                                                className='bg-primary mgr'
                                                onClick={() => this.setState({
                                                    userSelect: item,
                                                    showModal: true,
                                                    modalType: 1
                                                })}
                                            >
                                                Bảng điểm
                                            </Button>
                                        </div>
                                        <div className='flex-3 item-center'>
                                            <Button
                                                className='bg-primary'
                                                onClick={() => this.setState({
                                                    userSelect: item,
                                                    showModal: true,
                                                    modalType: 2
                                                })}
                                            >
                                                Chăm sóc
                                            </Button>
                                        </div>
                                        <div className='flex-2 item-center'>
                                            <div className='count-day-support'>
                                                <p className='mgb-0 text-large'>{item.CountNoUpdate}</p>
                                            </div>
                                        </div>
                                        <div className='flex-2 item-center'>
                                            {item.ListUserManage.length > 0 &&
                                                <>
                                                    {item.ListUserManage.map((i, k) =>
                                                        <>
                                                            {/* <TooltipUser
                                                            userInfo={i}
                                                            avatarClass={`avatar-manage-support ${(k > 0 ? 'avatar-manage-multiple' : '')}`}
                                                        /> */}
                                                            <img
                                                                src={getAvatarFb(i.FacebookId)}
                                                                className={`avatar-manage-support ${(k > 0 ? 'avatar-manage-multiple' : '')}`}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={'90%'}
                    destroyOnClose={true}
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

export default SupportWait