import React, { useState } from "react";
import Loading from "../../components/Loading";

export default function KTN() {
    const [loading, setLoading] = useState(true)

    return (
        <div className='ktn-container'>
            {loading ?
                <Loading small />
            : null}
            <iframe
                src="https://kienthucnen.com"
                title="Kiến Thức Nền"
                className='ktn-iframe animation'
                onLoad={() =>setLoading(false)}
                scrolling="no"
            />
        </div>
    )
}