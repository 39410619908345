import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import StudyZAlpha from "../../components/study/StudyZAlpha";


class Capacity extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StudyZAlpha />
        )
    }
}

export default connect()(withRouter(Capacity))