import { Modal } from "antd"
import { PROP } from "../../../utils/constants"
import React, { useState } from "react"
import moment from "moment"

const HappyBirthday = () => {
    const [open, setIsOpen] = useState(true)
    if(["2806", "2906", "3006"].includes(moment().format("DDMM"))){
        return (
            <Modal
                {...PROP.MODAL}
                bodyStyle= {{
                    ...PROP.MODAL.bodyStyle,
                    background: 'transparent',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                }}
                visible={open}
                onCancel={() => setIsOpen(false)}
            >
                <div className="birthdayCard">
                    <div className="cardFront">
                        <h3 className="happy">Chúc mừng sinh nhật Zcity!</h3>
                        <div className="balloons">
                            <div className="balloonOne" />
                            <div className="balloonTwo" />
                            <div className="balloonThree" />
                            <div className="balloonFour" />
                        </div>
                    </div>
                    <div className="cardInside">
                        <h3 className="birthdayCardBack">Zcity {+moment().format('YYYY') - 2019} tuổi</h3>
                        <p>Chào toàn thể thành phố Zcity</p>
                        <p>
                            Mừng sinh nhật Zcity tròn {+moment().format('YYYY') - 2019} tuổi, cảm ơn bạn đã gắn bó và đồng hành
                            trong quãng thời gian vừa qua. Chúc cho thành phố Zcity luôn phát triển
                            và là nơi học tập, giải trí trong quá trình phát triển bản thân của
                            chúng ta.
                        </p>
                        <p className="birthdayCardDate">28-6-{moment().format('YYYY')}</p>
                        <p className="birthdayCardName">Đội ngũ phát triển</p>
                    </div>
                </div>
            </Modal>
        )
    }
    return null
}

export default HappyBirthday