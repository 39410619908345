import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListBookApi = () =>{
    let url = `${URI_PATH}app/book/review/getListBook`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const publishReviewApi = (bookId, rateStar, reviewContent) =>{
    let url = `${URI_PATH}app/book/review/publishReview`;
    let body = {
        BookId: bookId,
        RateStar: rateStar,
        ReviewContent: reviewContent
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getBookReviewApi = (bookId, num = 0) =>{
    let url = `${URI_PATH}app/book/review/getBookReview`;
    let body = {
        BookId: bookId,
        Num: num
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const diamondApi = (bookIdReview, userReview) =>{
    let url = `${URI_PATH}app/book/review/diamond`;
    let body = {
        BookIdReview: bookIdReview,
        UserReview: userReview
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getRankReviewApi = (bookIdReview, userReview) =>{
    let url = `${URI_PATH}app/book/review/getRankReview`;
    let body = {
        BookIdReview: bookIdReview,
        UserReview: userReview
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}