import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { updateCountMission } from '../../actions/menu';
import HeaderLottie from '../../components/HeaderLottie';
import cup from '../../assets/lottie/cup.json';
import { getTopRankApi, likeApi, getLikeRemainApi, getListBidApi, getLikeTimeRemainApi } from '../../api/brand/top';
import { Modal } from 'antd';
import { getAvatarFb, priceFormat, getDataNotify } from '../../utils/common';
import Loading from '../../components/Loading';
import { showNotification, showNotificationBonus, showNotificationAsset, showNotificationProperty } from '../../utils/notification';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import ClubVipLevel from '../../components/club/ClubVipLevel';
import ClubDeputy from '../../components/club/ClubDeputy';
import like from '../../assets/lottie/like.json';
import Lottie from 'react-lottie';
import TooltipUser from '../../components/TooltipUser';
import Bid from '../../components/brand/Bid';
import { socket } from '../../components/realtime/ReadSocketEvent';
import { showModalEat } from '../../actions/home/modalEat';
import { Button } from 'antd';
import Checked from '../../components/frame/Checked';
import InfiniteScroll from 'react-infinite-scroller';
const ButtonGroup = Button.Group;

class BrandTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listRank: [],
            listBid: [],
            isLoading: true,
            isPress: false,
            showModal: false,
            userInfo: {},
            likeRemain: 0,
            likeTimeRemain: 999999999,
            userIdRemain: '',
            tabSelect: 1,
            endList: false,
            pageId: 1
        }
    }

    getListRank = (tabSelect, pageId = 1) => {
        getTopRankApi(tabSelect, pageId).then((res) => {
            if (res.code == 1) {
                this.setState({
                    listRank: pageId == 1 ? res.listRank : [...this.state.listRank, ...res.listRank],
                    isLoading: false,
                    endList: res.listRank.length < 20,
                    pageId,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch((e) => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    getListBid = () => {
        getListBidApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listBid: res.listBid
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch((e) => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    getLikeRemain = () => {
        getLikeRemainApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    likeRemain: res.likeRemain
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch((e) => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    fontSizeRank = (rank) => {
        if (0 < rank && rank <= 9) return '6vh';
        else if (9 < rank && rank <= 99) return '5vh';
        else if (100 <= rank && rank <= 999) return '4vh';
        else return '20';
    }

    renderLikeRemain = () => {
        let ele = [];
        for (let i = 0; i < this.state.likeRemain; i++) {
            ele.push(
                <p className='text-large' key={i}>❤️</p>
            )
        }
        return (
            <div className='item-center'>
                <div className='flex-row item-center'>{ele}</div>
                <p className='text-large'>🏹</p>
            </div>
        )
    }

    getLikeTimeRemain = (userId) => {
        if (this.state.userIdRemain != userId) {
            getLikeTimeRemainApi(userId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        userIdRemain: userId,
                        likeTimeRemain: res.likeTimeRemain
                    });
                }
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(e => showNotification(MESSAGE.ERROR, 0))
        }
    }

    like = userInfo => {
        let { isPress, listRank, listBid, likeRemain } = this.state;
        if (!isPress) {
            let idNotification = '#notificationLike' + userInfo.UserId;
            this.setState({ isPress: true });
            likeApi(userInfo.UserId).then(res => {
                if (res.code == 1) {
                    userInfo.BirthDay = res.birthDay;
                    this.setState({
                        isPress: false,
                        userInfo: userInfo,
                        showModal: true,
                        likeRemain: likeRemain - 1,
                        listRank: listRank.map(el => {
                            if (el.UserId == userInfo.UserId) return Object.assign({}, el, {
                                IsLiked: 2
                            })
                            return el;
                        }),
                        listBid: listBid.map(el => {
                            if (el.UserId == userInfo.UserId) return Object.assign({}, el, {
                                IsLiked: 2
                            })
                            return el;
                        }),
                    });
                    let bonus = res.bonus;
                    this.props.changePoint([null, null, bonus.BalanceZ, bonus.AchievementPr]);
                    this.props.updateCountMission('LikeCount');
                    showNotificationBonus(bonus.BalanceZ, 1, 3);
                    showNotificationBonus(bonus.AchievementPr, 1, 4);
                    showNotificationProperty(res.property);
                    showNotificationAsset(res.assetInfo);
                    //socket
                    let dataUser = this.props.dataUser;
                    let datas = getDataNotify(userInfo.UserId, 0, 0, 0, bonus.AchievementPa, bonus.LikeUser, res.propertyPa);
                    datas.message = {
                        fullName: dataUser.FullName,
                        facebookId: dataUser.FacebookId,
                        message: 'yêu thích thương hiệu của bạn',
                        isNotify: 2
                    };
                    socket.emit('send_notify', datas);
                    //
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.tabSelect) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    renderTypeTop = (data) => {
        switch (this.state.tabSelect) {
            case 1:
                return (
                    <>
                        <img src={`${URI_PATH}${data.HouseImage}`} style={{ width: '10vh' }} />
                        <p className='text-large'>{data.HouseName}</p>
                    </>
                )
            case 2:
                return (
                    <>
                        <img src={`${URI_PATH}${data.AssetImage}`} style={{ width: '10vh' }} />
                        <p className='text-large'>{data.AssetName}</p>
                    </>
                )
            case 3:
                return (
                    <>
                        <p className='text-large'>{priceFormat(data.TotalBalance)} đ[z]</p>
                    </>
                )
            case 4:
                return (
                    <>
                        <p className='text-large'>{data.TicketCount} Gold[z]</p>
                    </>
                )
        }
        return null
    }

    loadMore = () =>{
        if (!this.state.endList) {
            this.getListRank(this.state.tabSelect, this.state.pageId + 1);
        }
    }

    selectTab = (tabSelect) =>{
        this.setState({tabSelect})
        this.getListRank(tabSelect)
        this.setState({isLoading: true})
    }

    componentDidMount() {
        this.getListRank(1);
        this.getListBid();
        this.getLikeRemain();
    }

    render() {
        let { listRank, isLoading, showModal, userInfo, likeRemain, listBid, likeTimeRemain, endList } = this.state;
        return (
            <div>
                <HeaderLottie source={cup} size='25vh' />
                {/* <div className='flex-column item-center mgb'>
                    <p className='bold title-success'>
                        {likeRemain > 0 ?
                            `Yêu thích ${likeRemain} người bạn sẽ vượt qua thử thách`
                            :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div> */}
                {/* {likeRemain > 0 && this.renderLikeRemain()} */}
                <div className="row">
                    <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                        <Button className={this.buttonGroupClass(1)} onClick={() => this.selectTab(1)}>Nhà</Button>
                        <Button className={this.buttonGroupClass(2)} onClick={() => this.selectTab(2)}>Vương miện</Button>
                        <Button className={this.buttonGroupClass(3)} onClick={() => this.selectTab(3)}>Phòng khách</Button>
                        <Button className={this.buttonGroupClass(4)} onClick={() => this.selectTab(4)}>Gold[z]</Button>
                    </ButtonGroup>
                </div>
                <Bid
                    listBid={listBid}
                    likeTimeRemain={likeTimeRemain}
                    onLikeTimeRemain={this.getLikeTimeRemain}
                    onLike={this.like}
                    disabled
                />
                {isLoading ? <Loading small /> :
                    <div>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                        >
                            {listRank.map((item, key) =>
                                <div key={key} className='rank-container item-center'>
                                    <div className='col-rank-stt'>
                                        <div>
                                            <p style={{ fontSize: this.fontSizeRank(key + 1) }}>{key + 1}</p>
                                        </div>
                                        <div>
                                            <img
                                                style={{ width: '2vh', position: 'relative', bottom: '2vh' }}
                                                src={require('../../assets/image/rank.png')}
                                                alt='img-rank'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-rank-avatar'>
                                        <div style={{ position: 'relative' }}>
                                            <TooltipUser
                                                userInfo={item}
                                            />
                                            {item.AssetImage && (
                                                <img className='avatar-asset-rank' alt='asset-user' src={`${URI_PATH}${item.AssetImage}`} />
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-rank-name'>
                                        <div className='row item-center'>
                                            <ClubDeputy clubRoleId={item.ClubRoleId} />
                                            <p>{item.ClubName}</p>
                                        </div>
                                        <div className='level-rank'>
                                            <ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
                                        </div>
                                        <p className='bold'>{item.FullName}
                                            <Checked data={item} />
                                        </p>
                                        <p>{item.ClubRank}</p>
                                    </div>
                                    <div className='col-rank-info'>
                                        {this.renderTypeTop(item)}
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                }
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    <div className='item-center'>
                        <img src={getAvatarFb(userInfo.FacebookId)} className='avatar-guest-huge' />
                        <p className='text-large mgt'>{userInfo.FullName}</p>
                        <p className='text-large mgt'>{userInfo.BirthDay}</p>
                        <Lottie
                            options={{ loop: true, autoplay: true, animationData: like }}
                            width='30vh'
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, updateCountMission, showModalEat })(BrandTop);