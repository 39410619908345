const INITAL_STATE = {
    showUserBar: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'TOOGLE_USER_BAR':
            return { ...state, showUserBar: !state.showUserBar };
        default:
            return state;
    }
};