import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListCartApi = (page) =>{
    let url = `${URI_PATH}app/charge/chargefood/getListCart`;
    let body = {
        PageId: page
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListFoodApi = (userSell) =>{
    let url = `${URI_PATH}app/charge/chargefood/getListFood`;
    let body = {
        UserSell: userSell
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const buyFoodApi = (amount, userfoodmarketId) =>{
    let url = `${URI_PATH}app/charge/chargefood/buyFood`;
    let body = {
        Amount: amount,
        UserfoodmarketId: userfoodmarketId
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}