import React, { Component } from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {updateCountNotify} from '../../actions/menu';
import {getListPostApi, actionVoteApi, getVoteRemainApi} from '../../api/post/vote';
import {URI_PATH, MESSAGE} from '../../utils/constants';
import {randomNumber, isTouch} from '../../utils/common';
import {showNotification, showNotificationAsset, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import Icon from 'react-fontawesome';
import SearchUser from '../../components/SearchUser';
import {getCategory, getSubCategory} from '../../actions/category/';
import { Button, Tooltip } from 'antd';
import Countdown, { zeroPad } from 'react-countdown-now';
import { withRouter } from 'react-router'
import queryString from 'query-string';
import FsLightbox from 'fslightbox-react';
import TooltipUser from '../../components/TooltipUser';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {showModalEat} from '../../actions/home/modalEat';
import BarSelect from '../../components/frame/BarSelect';

class PostVote extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            data: {},
            isEmpty: false,
            voteRemain: 0,
            isEndIntervalVote: false,
            isPress: false,
            showPreviewImage: false,
            imagePreview: ''
        };
        //check url parameter
        this.directPost = '';
        let postId = queryString.parse(this.props.location.search).id;
        if(postId > 0) this.directPost = postId;
        //
        this.num = 0;
        this.categoryId = '';
        this.subCategoryId = '';
        this.searchUser = '';
        this.timeIntervalVote = '';
    }

    getPost = () =>{
        let {num, categoryId, subCategoryId, searchUser, directPost} = this;
        getListPostApi(num, categoryId, subCategoryId, searchUser, directPost).then((res) => {
            if(res.code == 1){
                //interval Diamond
                this.timeIntervalVote = Date.now() + 15000;
                //
                this.setState({
                    data: res.data,
                    isEmpty: false,
                    isLoading: false,
                    isEndIntervalVote: false
                });
                window.history.replaceState({}, '', '/menu/post-vote?id=' + res.data.dataPost.PostqueueId);
            }
            else this.setState({isEmpty: true, isLoading: false})
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getVoteRemain = () =>{
        getVoteRemainApi().then(res =>{
            if(res.code == 1){
                this.setState({voteRemain: res.voteRemain});
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMorePost = (type) =>{
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        let nextMax = parseInt(this.state.data.countPost);
        if(this.directPost > 0) this.directPost = '';
        switch(type) {
            case 'next':
                this.num = (this.num + 1) % nextMax;
                break;
            case 'prev':
                let prevNum = this.num - 1;
                this.num = prevNum >= 0 ? prevNum : nextMax - 1;
                break;
            case 'random':
                this.num = randomNumber(0, nextMax - 1);
                break;
        }
        setTimeout(() =>{
            this.getPost();
        }, 200)
    }

    actionVote = (postqueueId, isVote) =>{
        if(!this.state.isPress){
            this.setState({isPress: true});
            actionVoteApi(postqueueId, isVote).then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code);
                    showNotificationAsset(res.assetInfo);
                    showNotificationBonus(res.balance, 1, 2);
                    this.setState({voteRemain: this.state.voteRemain - 1});
                    this.props.changePoint([null, res.balance]);
                    this.props.updateCountNotify('PostVoteCount');
                    this.num = this.num > 0 ? this.num - 1 : 0;
                    this.directPost = '';
                    this.getPost();
                }
                else {
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code);
                }
                this.setState({isPress: false});
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isPress: false});
            })
        }
    }

    changeCategory = (value, action) =>{
        if(this.directPost > 0) this.directPost = '';
        this.num = 0;
        this.categoryId = value;
        if(this.categoryId) this.props.getSubCategory(this.categoryId);
        else this.subCategoryId = '';
        this.getPost();
    }

    renderVote = () =>{
        let {data, isEndIntervalVote, voteRemain, isPress} = this.state;
        if(!data.isVote && voteRemain > 0){
            if(isEndIntervalVote){
                return(
                    <div className='flex item-center'>
                        <Tooltip
                            overlayClassName='pv-tooltip-diamond'
                            trigger={isTouch() ? 'click' : 'hover'}
                            title={
                                <div className='flex-column'>
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/check.png')}
                                            className='pv-vote-img'
                                        />
                                    </div>
                                    <div className='mgl'>
                                        <div className='flex-row align-center mgt'>
                                            <Icon name='check' className='mgr' />
                                            <p>Nội dung đăng tải có ích với cộng đồng khởi nghiệp.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Hãy kiểm tra kỹ chính tả, dấu câu để bài viết chất lượng nhất.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Nội dung cần truyền đạt không vi phạm điều khoản, vi phạm pháp luật.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Ngôn từ sử dụng không xúc phạm, gây mất uy tín danh dự người khác.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='check' className='mgr' />
                                            <p>Các bài viết sưu tầm hãy ghi tên tác giả để thể hiện sự tôn trọng.</p>
                                        </div>
                                    </div>
                                    <div className='item-center'>
                                        <Button
                                            className='bg-success mgt-2'
                                            onClick={() => this.actionVote(data.dataPost.PostqueueId, 2)}
                                            loading={isPress}
                                        >
                                            Bỏ phiếu
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <img
                                src={require('../../assets/image/check.png')}
                                className='pv-vote-img'
                            />
                        </Tooltip>
                        <Tooltip
                            overlayClassName='pv-tooltip-diamond'
                            trigger={isTouch() ? 'click' : 'hover'}
                            title={
                                <div className='flex-column'>
                                    <div className='item-center'>
                                        <img
                                            src={require('../../assets/image/uncheck.png')}
                                            className='pv-vote-img'
                                        />
                                    </div>
                                    <div className='mgl'>
                                        <div className='flex-row align-center mgt'>
                                            <Icon name='times' className='mgr' />
                                            <p>Nội dung đăng tải không có ích với cộng đồng khởi nghiệp.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='times' className='mgr' />
                                            <p>Nội dung cần truyền đạt vi phạm điều khoản, vi phạm pháp luật.</p>
                                        </div>
                                        <div className='flex-row align-center'>
                                            <Icon name='times' className='mgr' />
                                            <p>Ngôn từ sử dụng xúc phạm, gây mất uy tín danh dự người khác.</p>
                                        </div>
                                    </div>
                                    <div className='item-center'>
                                        <Button
                                            className='bg-danger mgt-2'
                                            onClick={() => this.actionVote(data.dataPost.PostqueueId, 1)}
                                            loading={isPress}
                                        >
                                            Bỏ phiếu
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <img
                                src={require('../../assets/image/uncheck.png')}
                                className='pv-vote-img'
                            />
                        </Tooltip>
                    </div>
                )
            }
            return (
                <div>
                    <Countdown
                        date={this.timeIntervalVote}
                        renderer={({ days, hours, minutes, seconds}) =>(
                            <span className='text-large bold mgl-2 color-white'>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
                        )}
                        onComplete={() => this.setState({isEndIntervalVote: true})}
                    />
                </div>
            )
        }
        return null;
    }

    renderNavigate = () =>(
        <BarSelect>
            <Icon
                name='caret-left'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMorePost('prev')}
            />
            <Icon
                name='random'
                className='pv-navigate'
                style={{fontSize: '4vh', margin: '0vh 1vh'}}
                onClick={() => this.loadMorePost('random')}
            />
            <Icon
                name='caret-right'
                className='pv-navigate'
                style={{margin: '0vh 1vh'}}
                onClick={() => this.loadMorePost('next')}
            />
            {this.renderVote()}
        </BarSelect>
    )

    componentDidMount(){
        this.getVoteRemain();
        this.getPost();
        if(this.props.categoryPost.length == 0) this.props.getCategory(1);
    }

    render(){
        let {isLoading, data, isEmpty, showPreviewImage, imagePreview} = this.state;
        let {categoryPost, subCategory} = this.props;
        let dataPost = data.dataPost;
        if(isLoading) return <Loading />;
        return(
            <div style={{paddingBottom: '8vh'}}>
                {/* <div className='flex item-center'>
                    <p className='bold title-success'>
                        {this.state.voteRemain > 0 ?
                            `Bỏ phiếu ${this.state.voteRemain} bài viết bạn sẽ vượt qua thử thách`
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div> */}
                <div className='pv-search-container row'>
                    <div className='col-md-4 col-sm-4 col-xs-12'>
                        <SearchUser
                            ref='searchUser'
                            onChange={this.searchUserPost}
                            onKeyDown={this.deleteSearch}
                            placeholder='🔎 Họ tên / Số điện thoại'
                        />
                    </div>
                    <div className={'col-md-8 col-sm-8 col-xs-6'}>
                        <select
                            className='form-control'
                            onChange={e => this.changeCategory(e.target.value)}
                        >
                            <option value=''>Tất cả thể loại</option>
                            {categoryPost.map((item, key) =>(
                                <option key={key} value={item.CategoryId}>{item.CategoryName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {isEmpty ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không còn bài viết cần phê duyệt</p>
                    </div>
                :
                    <div className='pv-title-container'>
                        {this.renderNavigate()}
                        <div className='flex-row mgt-2'>
                            <div className='flex-row align-center'>
                                <TooltipUser userInfo={dataPost} avatarClass='avatar-guest' key={dataPost.UserId}/>
                                <div className='pv-user-container'>
                                    <p className='bold'>{dataPost.FullName}</p>
                                    <p className='placeholder black'>{dataPost.CrDateTime}</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex-column'>
                            <p className='text-large bold text-center'>{dataPost.CategoryName}</p>
                            <p className='text-large text-center bold mgt mgb'>{dataPost.PostTitle}</p>
                            <div className='item-center'>
                                <img
                                    src={require('../../assets/image/line.png')}
                                    className='line-img mgt'
                                />
                            </div>
                            <div className='flex-row flex-3 item-center mgt'>
                                <p className='pv-check-p mgb-0' style={{background: 'rgba(0,0,0,0.85)'}}>Tôi chia sẻ bài viết này vì: {dataPost.PostReason}</p>
                            </div>
                            <div>
                                {dataPost.PostImage &&
                                    <img
                                        src={`${URI_PATH}${dataPost.PostImage}`}
                                        className='pv-post-image'
                                        onClick={() => this.setState({
                                            showPreviewImage: true,
                                            imagePreview: `${URI_PATH}${dataPost.PostImage}`
                                        })}
                                    />
                                }
                                <div className='text-left' dangerouslySetInnerHTML={{__html: dataPost.PostContent}} />
                            </div>
                            {dataPost.PostDesc &&
                                <div>
                                    <hr width="95%" align="center" className="hr-default"></hr>
                                    <p className='text-center text-large mgt bold'>Nội dung tham khảo trích dẫn</p>
                                    <div className='text-left mgt' dangerouslySetInnerHTML={{__html: dataPost.PostDesc}} />
                                </div>
                            }
                        </div>
                    </div>
                }
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryPost } = state.getCategory;
    return { dataUser, categoryPost };
}

export default connect(mapStateToProps, { changePoint, getCategory, getSubCategory, updateCountNotify, showModalEat })(withRouter(PostVote));