import { getListAudioApi } from '../../api/audio';

export const getAudio = (page = 1, categoryId, subCategoryId) =>{
    return dispatch =>{
        getListAudioApi(page, categoryId, subCategoryId).then(res =>{
            if(res.code == 1) dispatch({ type: 'SUCCESS_GET_LIST_AUDIO', page,  listAudio: res.listAudio});
            else dispatch({ type: 'FALSE_GET_LIST_AUDIO' });
        })
        .catch(error => dispatch({ type: 'FALSE_GET_LIST_AUDIO' }));
    }
}

export const setAudioIndex = (audioIndex) =>{
    return { type: 'CHANGE_AUDIO_INDEX', audioIndex }
}

export const setAudioCurrentTime = (currentTime) =>{
    return { type: 'CHANGE_AUDIO_CURRENT_TIME', currentTime }
}

export const setAudioDuration = (duration) =>{
    return { type: 'CHANGE_AUDIO_DURATION', duration }
}

export const setAudioPlay = (isPlay) =>{
    return { type: 'CHANGE_AUDIO_PLAY', isPlay }
}

export const setAudioRepeat = (isRepeat) =>{
    return { type: 'CHANGE_AUDIO_REPEAT', isRepeat }
}

export const hideAudio = () =>{
    return { type: 'HIDE_AUDIO' }
}