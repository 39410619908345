import React, { Component } from 'react';
import { Button } from 'antd';
import TicketConfirm from '../../components/admin/study/TicketConfirm';
import TicketConfig from '../../components/admin/study/TicketConfig';
const ButtonGroup = Button.Group;

class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTab: 1,
        }
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-newfeed bg-success';
        return 'button-group-newfeed';
    }

    renderTab = () =>{
        switch (this.state.selectTab) {
            case 1:
                return <TicketConfirm />
            case 2:
                return <TicketConfig />
        }
    }

    render() {
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({selectTab: 1})} className={this.buttonGroupClass(1)}>Duyệt lệnh mua Gold[z]</Button>
                    <Button onClick={() => this.setState({selectTab: 2})} className={this.buttonGroupClass(2)}>Cài đặt giá mua Gold[z]</Button>
                </ButtonGroup>
                {this.renderTab()}
            </>
        )
    }
}

export default Ticket;