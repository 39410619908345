import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListLessonAssignApi = (CategoryId, StudentId, StudyClassId) =>{
    let url = `${URI_PATH}app/study/assign/getListLessonAssign`;
    let body = {
        CategoryId,
        StudentId,
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const assignLessonApi = (StudentId, StudyAdminId, Deadline, StudyClassId) =>{
    let url = `${URI_PATH}app/study/assign/assignLesson`;
    let body = {
        StudentId,
        StudyAdminId,
        Deadline,
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getCountLessonApi = (StudentId, StudyClassId) =>{
    let url = `${URI_PATH}app/study/assign/getCountLesson`;
    let body = {
        StudentId,
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteAssignApi = (StudentId, StudyStudentId, StudyClassId) =>{
    let url = `${URI_PATH}app/study/assign/deleteAssignLesson`;
    let body = {
        StudentId,
        StudyStudentId,
        StudyClassId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}