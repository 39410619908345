import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import Loading from '../../components/Loading';
import {Button} from 'antd';
import { showNotification } from '../../utils/notification';
import {MESSAGE} from '../../utils/constants';
import {socket} from '../../components/realtime/ReadSocketEvent';
import Chart from 'react-apexcharts';
import {getDataGroupApi, inviteMemberApi, kickMemberApi} from '../../api/brand/group';
import SearchUser from '../../components/SearchUser';
import TooltipUser from '../../components/TooltipUser';
import {BlackTooltip} from '../../components/frame';
import PointProperty from '../../components/brand/PointProperty';

class BrandGroup extends Component{
    constructor(props){
        super(props);
        this.state={
            userProperty: {},
            bonusProperty: 0,
            isCaptain: '',
            listMember: [],
            chartData: [{
                name: '',
                data: [0, 0, 0, 0, 0, 0],
            }],
            selectPhone: [],
            isPress: false,
            isLoading: true,
        }
        this.isPressKick = false;
    }

    getDataGroup = () =>{
        getDataGroupApi().then(res =>{
            if(res.code == 1){
                let {userProperty, bonusProperty, isCaptain, listMember} = res;
                this.setState({
                    userProperty,
                    bonusProperty,
                    isCaptain,
                    listMember,
                    isLoading: false
                });
                if(listMember.length > 0){
                    this.setState({
                        chartData:
                            listMember.map(item =>{
                                return{
                                    name: item.FullName,
                                    data:[item.LeaderPoint, item.ManagePoint, item.RelationPoint, item.FundPoint, item.ExpertisePoint, item.LanguagePoint]
                                }
                            })
                        
                    });
                }
                else{
                    this.setState({
                        chartData:[{
                            name: userProperty.FullName,
                            data: [userProperty.LeaderPoint, userProperty.ManagePoint, userProperty.RelationPoint, userProperty.FundPoint, userProperty.ExpertisePoint, userProperty.LanguagePoint]
                        }]
                    });
                }
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    deleteSearch = e =>{
        if(e.key == 'Backspace' && this.state.selectPhone.length > 0){
            this.refs.searchUser.getInstance().clear();
            this.setState({selectPhone: []});
        }
    }

    renderPropertyCup = () =>{
        let {bonusProperty} = this.state;
        let cupImage;
        if(bonusProperty >= 0 && bonusProperty <= 20) cupImage = '3rd.png';
        else if(bonusProperty >= 21 && bonusProperty <= 40) cupImage = '2rd.png';
        else if(bonusProperty >= 40 && bonusProperty < 100) cupImage = '1rd.png';
        else cupImage = 'cup.png';
        return(
            <div className='item-center mgt'>
                <img src={require('../../assets/image/' + cupImage)} className='property-cup-icon' />
                <p className='bold mgt'>Nhận được thêm {bonusProperty}% thành tựu từ tất cả các hoạt động</p>
            </div>
        )
    }

    renderListMember = () =>{
        let {listMember} = this.state;
        let dataUser = this.props.dataUser;
        return(
            <div className='row item-center'>
                {listMember.map((item, key) =>
                    <div className='list-member-property' key={key}>
                        {key == 0 &&
                            <BlackTooltip
                                titleTooltip='Nhóm trưởng'
                            >
                                <img className="captain-property-image" src={require('../../assets/image/crown.png')} />
                            </BlackTooltip>
                        }
						<TooltipUser userInfo={item} avatarClass='avatar-guest mg-3'/>
                        {(item.UserIdMember == dataUser.UserId || item.UserIdCreate == dataUser.UserId) &&
                            <BlackTooltip
                                titleTooltip='Rời khỏi nhóm'
                            >
                                <img
                                    className="kick-member-property-image"
                                    src={require('../../assets/image/quit.png')}
                                    onClick={() => this.kickMember(item.UserIdMember)}
                                />
                            </BlackTooltip>
                        }
                    </div>
                )}
            </div>
        )
    }

    renderCreateGroup(){
        if(this.state.isCaptain || this.state.listMember.length == 0){
            return(
                <div className='brand-group-container'>
                    <p className='text-large'>Ghép nhóm thành tựu</p>
                    <div className='row item-center mgt'>
                        <div className='col-md-6 col-sm-8 col-xs-12'>
                            <SearchUser
                                ref='searchUser'
                                onChange={selectPhone => this.setState({selectPhone})}
                                onKeyDown={this.deleteSearch}
                                placeholder='Nhập họ tên hoặc số điện thoại'
                            />
                        </div>
                    </div>
                    <div className='mgt' id='notificationInviteGroup' />
                    <Button
                        className='bg-success mgt'
                        loading={this.state.isPress}
                        onClick={this.inviteMember}
                    >
                        Mời vào nhóm
                    </Button>
                </div>
            )
        }
        return null
    }

    inviteMember = () =>{
        let {isPress, selectPhone} = this.state;
        if(!isPress){
            let idNotification = '#notificationInviteGroup';
            if(selectPhone.length == 0) return showNotification('Bạn chưa chọn thành viên để mời', -1, idNotification);
            this.setState({isPress: true});
            let userId = selectPhone[0].userId;
            inviteMemberApi(userId).then(res =>{
                showNotification(res.message, res.code, idNotification);
                this.setState({isPress: false});
                if(res.code == 1){
                    this.refs.searchUser.getInstance().clear();
                    this.setState({selectPhone: []});
                    //socket
                    let dataUser = this.props.dataUser;
                    let dataSent = {
						userName : dataUser.FullName,
						userAvatar : dataUser.FacebookId,
						userId : dataUser.UserId,
						userInivite: res.toUserId
					};
					socket.emit('invite_property', dataSent);
                    //
                    if(res.isCreate == 1) this.getDataGroup();
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            })
        }
    }

    kickMember = memberId =>{
        if(!this.isPressKick){
            this.isPressKick = true;
            kickMemberApi(memberId).then(res =>{
                this.isPressKick = false;
                showNotification(res.message, res.code);
                if(res.code == 1) this.getDataGroup();
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
                this.isPressKick = false;
            })
        }
    }

    componentDidMount(){
        this.getDataGroup();
    }

    render(){
        let {isLoading, userProperty, chartData} = this.state;
        if(isLoading) return (<Loading />);
        return(
            <div>
                <PointProperty userProperty={userProperty} chartData={chartData}/>
                {this.renderCreateGroup()}
                {this.state.listMember.length > 0 ?
                    <div className='brand-group-container mgt'>
                        <p className='text-large'>Nhóm của tôi</p>
                        {this.renderListMember()}
                        {this.renderPropertyCup()}
                    </div>
                : null}
                <div className='brand-group-container mgt'>
                    <p className='text-large'>Lợi ích khi ghép nhóm thành tựu</p>
                    <p className='mgt'>Ghép nhóm thành tựu trước khi tham gia các hoạt động hằng ngày giúp bạn được thưởng thêm % thành tựu.</p>
                    <p className='mgt'>% thành tựu được thưởng bằng tổng hạng VIP của nhóm.</p>
                    <p className='mgt'>Nhóm tối đa 6 thành viên.</p>
                    <p className='mgt'>Ví dụ: Nhóm 3 thành viên gồm VIP 1, VIP 2, VIP 4 thì nhóm được thưởng 7% thành tựu khi hoạt động.</p>
                    <div className='brand-group-container'>
                        <div className='flex-row mgt'>
                            <div className='flex-1 item-center'>
                                <img src={require('../../assets/image/3rd.png')} className='property-cup-icon' />
                            </div>
                            <div className='flex-2 flex-column'>
                                <p className='text-left'>Huy chương đồng hạng 3</p>
                                <p className='text-left'>Tổng VIP nhóm từ 0 - 20</p>
                            </div>
                        </div>
                        <div className='flex-row mgt'>
                            <div className='flex-1 item-center'>
                                <img src={require('../../assets/image/2rd.png')} className='property-cup-icon' />
                            </div>
                            <div className='flex-2 flex-column'>
                                <p className='text-left'>Huy chương bạc hạng 2</p>
                                <p className='text-left'>Tổng VIP nhóm từ 21 - 40</p>
                            </div>
                        </div>
                        <div className='flex-row mgt'>
                            <div className='flex-1 item-center'>
                                <img src={require('../../assets/image/1rd.png')} className='property-cup-icon' />
                            </div>
                            <div className='flex-2 flex-column'>
                                <p className='text-left'>Huy chương vàng hạng 1</p>
                                <p className='text-left'>Tổng VIP nhóm từ 41 - 60</p>
                            </div>
                        </div>
                        <div className='flex-row mgt'>
                            <div className='flex-1 item-center'>
                                <img src={require('../../assets/image/cup.png')} className='property-cup-icon' />
                            </div>
                            <div className='flex-2 flex-column'>
                                <p className='text-left'>Cúp nhóm hoàn hảo</p>
                                <p className='text-left'>Nhóm 6 người VIP 5 trở lên, mỗi người mạnh 1 thuộc tính.</p>
                                <p className='text-left'>Nhóm được x2 thành tựu các hoạt động trong thời gian liên kết nhóm.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint})(BrandGroup);